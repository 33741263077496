import { TokenCustomClaimKeysEnum } from 'features/common/auth/SitecoreUser';
import { useAuthWithClaims } from 'features/common/auth/useAuthWithClaims';
import { useEffect } from 'react';

export const useGainsightSetGlobalContext = () => {
	const global = window as any;
	const { identifyInitialized, aptrinsic } = global;
	const { user } = useAuthWithClaims();

	useEffect(() => {
		if (!user || !identifyInitialized || !aptrinsic) {
			return;
		}

		const orgId = user[TokenCustomClaimKeysEnum.ORG_ID];
		const orgRoles = user[TokenCustomClaimKeysEnum.ROLES]
			.filter((role) => role.includes('Organization'))
			.map((orgRole) => orgRole.split('\\')[1]);

		if (!orgId) {
			return;
		}

		aptrinsic?.('set', 'globalContext', {
			OrganizationID: orgId,
			'Organization DisplayName': user[TokenCustomClaimKeysEnum.ORG_DISPLAY_NAME],
			'Organization Role': orgRoles.join(', '),
			'Organization Type': user[TokenCustomClaimKeysEnum.ORG_TYPE] ?? '',
			'App Hostname': window.location.hostname,
		});
	}, [user, identifyInitialized, aptrinsic]);
};

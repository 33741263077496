import { Box } from '@chakra-ui/react';
import { Form, FormikProps } from 'formik';
import { useLanguageSectionForm } from 'pages/languages/hooks/use-language-section-form';
import { SiteLanguagesViewModel } from 'pages/languages/models';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { MultiLanguagesField } from 'shared/languages/ui';
import { onSiteTouched } from 'widgets/site-settings-update-form/models';

export const LanguageSectionForm: FC<FormikProps<SiteLanguagesViewModel>> = ({ resetForm, submitForm, setFieldTouched, values }) => {
	const { languageAccess } = useLanguageSectionForm(submitForm, resetForm);

	return (
		<Box maxWidth="xl">
			<Form>
				<MultiLanguagesField
					selectedLanguages={values.languages}
					setFieldTouched={setFieldTouched}
					onLanguageChange={() => onSiteTouched(true)}
					shouldShowSupportedLanguages={languageAccess?.canCreate || false}
					helper={DICTIONARY.SUPPORT_LANGUAGES_HINT}
					useBasicStyles
				/>
			</Form>
		</Box>
	);
};

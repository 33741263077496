import { Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { RenameSiteDefinitionModalProps } from 'features/site-definition-actions-menu/models';
import { RenameSiteDefinitionModalBody } from 'features/site-definition-actions-menu/ui';
import { FC, PropsWithChildren } from 'react';
import { DICTIONARY } from 'shared/constants';
import { useModalWrapper } from 'shared/modal-wrapper/hooks';
import type { ModalWrapperProps } from 'shared/modal-wrapper/models';

export const RenameSiteDefinitionModal: FC<PropsWithChildren<RenameSiteDefinitionModalProps & ModalWrapperProps>> = (props) => {
	const { isOpen, onClose, children } = useModalWrapper(props);

	return (
		<>
			{children}
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						<T _str={DICTIONARY.RENAME_SITE} siteName={props.name} />
					</ModalHeader>
					<ModalCloseButton />
					<RenameSiteDefinitionModalBody onClose={onClose} {...props} />
				</ModalContent>
			</Modal>
		</>
	);
};

import { useCallback, useRef, useState } from 'react';
import { SITE_DEFINITION_SAVED, SITE_DEFINITION_TOUCHED, SITE_IS_SUBMITTING } from 'shared/constants';
import { useEventListener } from 'usehooks-ts';
import { IsSubmittingEvent, SiteIsDirtyEvent, SiteSavedEvent, onButtonsClick } from 'widgets/site-definition-update-form/models';

export const useSubmitResetButtons = (onIsDirtyChange: (value: boolean) => void = () => {}) => {
	const [isDirty, setIsDirty] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const documentRef = useRef<Document>(document);
	const onSubmitClick = useCallback(() => {
		onButtonsClick('submit');
	}, []);

	const onResetClick = useCallback(() => {
		onButtonsClick('reset');
		setIsDirty(false);
		onIsDirtyChange(false);
	}, [onIsDirtyChange]);

	const onSiteSaved = useCallback(
		(event: CustomEvent<SiteSavedEvent>) => {
			if (event.detail.saved) {
				setIsDirty(false);
				onIsDirtyChange(false);
			}
		},
		[onIsDirtyChange]
	);

	const onIsSubmittingChange = useCallback((event: CustomEvent<IsSubmittingEvent>) => {
		setIsSubmitting(event.detail.state);
	}, []);

	const onSiteTouched = useCallback(
		(event: CustomEvent<SiteIsDirtyEvent>) => {
			setIsDirty(event.detail.isDirty);
			onIsDirtyChange(event.detail.isDirty);
		},
		[onIsDirtyChange]
	);

	useEventListener(SITE_DEFINITION_TOUCHED, onSiteTouched, documentRef);

	useEventListener(SITE_DEFINITION_SAVED, onSiteSaved, documentRef);

	useEventListener(SITE_IS_SUBMITTING, onIsSubmittingChange, documentRef);

	return {
		isDirty,
		isSubmitting,
		onSubmitClick,
		onResetClick,
	};
};

import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { format } from 'date-fns';
import { PageBodyContentSection, SectionDataRecord } from 'entities/page-layout/ui';
import { SitePageOutlet } from 'pages/site/models';
import { useOutletContext } from 'react-router';
import { DATE_FORMAT, DICTIONARY, EMPTY_VALUE } from 'shared/constants';

export const JssSiteGeneralPage = () => {
	const { site } = useOutletContext<SitePageOutlet>();
	const createdOn = site?.created ? format(new Date(site.created), DATE_FORMAT) : EMPTY_VALUE;

	return (
		<>
			<Alert status="info" overflow="unset">
				<AlertIcon />
				<AlertDescription>
					<T _str={DICTIONARY.JSS_SITE_GENERAL_PAGE_ALERT} siteName={site?.displayName || site?.name} />
				</AlertDescription>
			</Alert>
			<PageBodyContentSection title={DICTIONARY.DETAILS}>
				<SectionDataRecord header={DICTIONARY.CREATED_BY} value={site?.createdBy ?? EMPTY_VALUE} />
				<SectionDataRecord header={DICTIONARY.CREATED_ON} value={createdOn} />
				<SectionDataRecord header={DICTIONARY.ITEM_PATH} value={(site?.properties?.rootPath || site?.itemPath) ?? EMPTY_VALUE} />
			</PageBodyContentSection>
		</>
	);
};

import { useEffect, useRef } from 'react';
import { useGetSite } from '../../sites/utils/useGetSite';

export const useSiteExistingCheck = (navigateBack: () => void) => {
	const { site, loading } = useGetSite();
	const siteVerified = useRef(false);

	useEffect(() => {
		if (loading || siteVerified.current) {
			return;
		}

		if (!site) {
			navigateBack();
		}

		siteVerified.current = true;
	}, [navigateBack, site, loading]);
};

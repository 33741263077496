import { Button, Center, Heading, Image, Stack, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { useTranslate } from 'features/common/utils/translateUtils';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { GenericErrorTemplateProps } from 'shared/generic-error/models';

export const GenericErrorTemplate: FC<GenericErrorTemplateProps> = ({ title, imageSrc, subtext, text, links }) => {
	const t = useTranslate();

	return (
		<Center layerStyle="section.main" h="full" backgroundColor="inherit">
			<Stack align="center" textAlign="center" spacing="6" maxW="sm">
				<Image boxSize="32" src={imageSrc} alt={t(DICTIONARY.GENERIC_ERROR_IMG_ALT)} />
				<Stack>
					<Heading as="h1">
						<T _str={title} />
					</Heading>
					{subtext && (
						<Text variant="small">
							<T _str={subtext} />
						</Text>
					)}
				</Stack>
				{typeof text === 'string' ? (
					<Text>
						<T _str={text} />
					</Text>
				) : (
					text
				)}
				<Stack>
					{links?.map((link, index) => (
						<Button variant="link" key={index} onClick={link.onClick}>
							<T _str={link.label} />
						</Button>
					))}
				</Stack>
			</Stack>
		</Center>
	);
};

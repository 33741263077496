import { TYPE_FILTER_NAME, clearQueryParamsOnFilterTypeAll } from 'features/common/utils/сlearNonRelevantQueryParams';
import { DICTIONARY } from 'shared/constants';
import { useCheckNonSXASitesExist } from 'features/sites/utils/useCheckNonSXASitesExist';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export type TypeFilterUrlParams = 'none' | 'sxa' | 'nonsxa';

export const useTypeFilter = () => {
	const [searchParams, setUrlSearchParams] = useSearchParams();
	const isNonSXASitesExist = useCheckNonSXASitesExist();

	const onTypeFilterChange = useCallback(
		(type: TypeFilterUrlParams) => {
			const currentType = searchParams.get(TYPE_FILTER_NAME);

			if (currentType === type || (currentType === null && type === 'none')) {
				return;
			}

			clearQueryParamsOnFilterTypeAll(searchParams);

			if (type === 'none') {
				searchParams.delete(TYPE_FILTER_NAME);
			} else {
				searchParams.set(TYPE_FILTER_NAME, type);
			}

			setUrlSearchParams(searchParams);
		},
		[searchParams, setUrlSearchParams]
	);

	const selectedFilter: { type: TypeFilterUrlParams; text: string } = useMemo(() => {
		const value = searchParams.get(TYPE_FILTER_NAME);

		switch (value) {
			case 'sxa':
				return { type: 'sxa', text: DICTIONARY.TYPE_FILTER.SXA_MANAGED };

			case 'nonsxa':
				return { type: 'nonsxa', text: DICTIONARY.TYPE_FILTER.NON_SXA_MANAGED };

			default:
				return { type: 'none', text: DICTIONARY.TYPE_FILTER.ALL };
		}
	}, [searchParams]);

	return {
		selectedFilter,
		onTypeFilterChange,
		isNonSXASitesExist,
	};
};

import { useToast } from '@chakra-ui/react';
import { t } from '@transifex/native';
import { isAxiosError, isError, isErrorMessageResponse, isProblemDetails } from '../utils/errorUtils';
import { defaultResponseErrorMap, matchError } from './errorsMap';

export type Toast = ReturnType<typeof useToast>;

export const defaultErrorHandler = (err: unknown, toast: Toast) => {
	if (isAxiosError(err)) {
		const axiousError = err;

		if (axiousError.response) {
			// The request was made and the server responded with an error status code
			const customErrorMessage = matchError(defaultResponseErrorMap, {
				code: axiousError.response?.status,
				method: axiousError.request.method,
				path: axiousError.request.url,
			});

			const responseData = axiousError.response.data;
			let generatedMessage = axiousError.message;

			if (isProblemDetails(responseData)) {
				generatedMessage = responseData.detail;
			} else if (isErrorMessageResponse(responseData)) {
				generatedMessage = responseData.message;
			}

			return toast({
				status: 'error',
				description: customErrorMessage ?? generatedMessage,
			});
		}

		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
		if ((err as any).request) {
			// The request was made but no response was received
			if (navigator.onLine === true) {
				return toast({
					status: 'error',
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
					description: t('Server failed to respond. You appear offline and might be the issue.'),
				});
			}

			return toast({
				status: 'error',
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
				description: t('Server failed to respond or taking too long. Can also be issue with firewalls etc. Trying again might help.'),
			});
		}

		// Something happened in setting up the request that triggered an Error
		// Error already logging to console and should be handled elsewhere.
		return;
	}

	if (isError(err)) {
		return toast({
			status: 'error',
			title: err.name,
			description: err.message,
		});
	}

	if (typeof err === 'string') {
		return toast({ status: 'error', description: err });
	}
};

import { useCheckMetaNameUniqueness } from 'features/site-actions-menu/hooks';
import { Formik } from 'formik';
import { FC } from 'react';
import { LanguageItem } from 'shared/languages/models';
import { SiteResponse } from 'shared/models';
import { siteSettingsValidationSchema } from 'shared/validations';
import { useGeneralSectionForm } from 'widgets/site-settings-update-form/hooks/use-general-section-form';
import { SiteSettingsViewModel } from 'widgets/site-settings-update-form/models';
import { GeneralSectionBody } from 'widgets/site-settings-update-form/ui';

/**
 * @deprecated Update is blocked for EA
 */
export type SiteSettingsFormProps = SiteResponse & { xmAppsLanguages: LanguageItem[] };

/**
 * @deprecated Update is blocked for EA
 */
export const GeneralSectionForm: FC<SiteSettingsFormProps> = (props) => {
	const { initialValues, submit } = useGeneralSectionForm(props);
	const validateSiteUniqueness = useCheckMetaNameUniqueness({
		originalValue: props.displayName,
		formKey: 'displayName',
		collectionId: props.collectionId,
	});

	return (
		<Formik<SiteSettingsViewModel>
			initialValues={initialValues}
			onSubmit={submit}
			validationSchema={siteSettingsValidationSchema}
			validate={(values) => validateSiteUniqueness(values.displayName)}
			enableReinitialize
		>
			{(formikProps) => <GeneralSectionBody {...formikProps} {...props} />}
		</Formik>
	);
};

import { portalBaseUrl } from 'features/common/config/envConfig';
import { FC } from 'react';
import { DICTIONARY, SC_ICONS_SRC } from 'shared/constants';
import { GenericErrorTemplateLink } from 'shared/generic-error/models';
import { GenericErrorTemplate } from 'shared/generic-error/ui';

export const AccessDenied: FC = () => {
	const links: GenericErrorTemplateLink[] = [{ label: DICTIONARY.GO_TO_HOMEPAGE, onClick: () => window.open(portalBaseUrl, '_blank') }];

	return (
		<GenericErrorTemplate
			text={DICTIONARY.PAGE_ACCESS_RESTRICTED}
			subtext={DICTIONARY.ERROR_403}
			title={DICTIONARY.ACCESS_DENIED.DEFAULT}
			imageSrc={SC_ICONS_SRC.FORBIDDEN}
			links={links}
		/>
	);
};

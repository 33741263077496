import { Button } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { RemoveSiteProps } from 'features/remove-site/models';
import { RemoveSiteModal } from 'features/remove-site/ui';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';

export const RemoveSiteButton: FC<RemoveSiteProps> = (props) => {
	return (
		<RemoveSiteModal {...props}>
			<Button width="fit-content" colorScheme="danger" data-testid="site-general-delete">
				<T _str={DICTIONARY.DELETE_SITE} />
			</Button>
		</RemoveSiteModal>
	);
};

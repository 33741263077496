export const ENDPOINTS = {
	SITE_PERMISSIONS: '/api/v1/sites/permissions',
	JOB_STATUS: '/api/v1/jobs/{jobHandle}/status',
	COLLECTIONS: '/api/v1/collections',
	COLLECTION: '/api/v1/collections/{collectionId}',
	COLLECTION_RENAME: '/api/v1/collections/{collectionId}/rename',
	COLLECTION_SITES: '/api/v1/collections/{collectionId}/sites',
	SITES: '/api/v1/sites',
	SITE: '/api/v1/sites/{id}',
	SITE_HOST: '/api/v1/sites/{siteId}/hosts/{hostId}',
	SITE_UPLOAD_THUMBNAIL: '/api/v1/sites/{siteId}/upload-thumbnail',
	SITE_HOST_UPLOAD_THUMBNAIL: '/api/v1/sites/{siteId}/hosts/{hostId}/upload-thumbnail',
	SITE_REGENERATE_THUMBNAIL: '/api/v1/sites/{siteId}/regenerate-thumbnail',
	SITE_HOST_REGENERATE_THUMBNAIL: '/api/v1/sites/{siteId}/hosts/{hostId}/regenerate-thumbnail',
	SITE_RENAME: '/api/v1/sites/{siteId}/rename',
	SITE_COPY: 'api/v1/sites/{siteId}/copy',
	HIERARCHY: '/api/v1/sites/{siteId}/hierarchy',
	HIERARCHY_PAGE: '/api/v1/sites/{siteId}/hierarchy/{pageId}',
	HIERARCHY_ANCESTORS: '/api/v1/sites/{siteId}/hierarchy/{pageId}/ancestors',
	LANGUAGES: '/api/v1/languages',
	LANGUAGE: '/api/v1/languages/{isoCode}',
	LANGUAGES_SUPPORTED: '/api/v1/languages/supported',
	TEMPLATES: '/api/v1/sites/templates',
	POS_DETACH: '/api/v1/sites/analytics-identifiers/{identifier}/detach',
	JOBS: '/api/v1/jobs',
	POS_SITES_OLD: 'api/v1/sites/points-of-sale/{pos}',
	POS_SITES: 'api/v1/sites/analytics-identifiers/{identifier}',
	HOSTS: 'api/v1/sites/{siteId}/hosts',
	HOST: 'api/v1/sites/{siteId}/hosts/{hostId}',
	RENDERING_HOSTS: '/api/v1/sites/{siteId}/renderinghosts',
	PAGES: '/api/v1/pages',
	GET_PAGE: '/api/v1/pages/{pageId}?site={siteName}&language={language}',
	PAGE_INSERT_OPTIONS: '/api/v1/pages/{pageId}/insertOptions?site={siteName}&language={language}&insertOptionKind={insertOptionKind}',
	PAGE_VERSIONS: '/api/v1/pages/{pageId}/versions?site={siteId}&language={language}',
	PAGE_VERSION: '/api/v1/pages/{pageId}/version',
	DELETE_HOST: '/api/v1/sites/{siteId}/hosts/{hostId}?force={force}',
	DELETE_SITE: '/api/v1/sites/{id}?force={force}',
	PAGE: '/api/v1/pages/{pageId}',
	DUPLICATE_PAGE: '/api/v1/pages/{id}/duplicate',
	RENAME_PAGE: '/api/v1/pages/{pageId}/rename',
	MOVE_PAGE: '/api/v1/pages/{pageId}/move',
	LANGUAGE_PERMISSIONS: '/api/v1/languages/permissions',
	FEATURE_FLAGS: 'api/v1/admin/featureflags',
};

import { MenuItem } from '@chakra-ui/react';
import { mdiCogOutline } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { FC, useCallback } from 'react';
import { DICTIONARY } from 'shared/constants';
import { useNavigateToRoute } from 'shared/navigation/hooks';
import { ROUTES } from 'shared/routes';

type OpenSiteSettingsMenuItemProps = {
	siteId: string;
};

export const OpenSiteSettingsMenuItem: FC<OpenSiteSettingsMenuItemProps> = ({ siteId }) => {
	const navigateToRoute = useNavigateToRoute();
	const navigate = useCallback(() => {
		navigateToRoute(`/${ROUTES.SITE.NAME}/${siteId}/${ROUTES.SITE.GENERAL}`, { state: { origin: window.location.pathname } });
	}, [siteId, navigateToRoute]);

	return (
		<MenuItem icon={<Icon path={mdiCogOutline} />} onClick={navigate} data-testid="siteSettingsMenuItem">
			<T _str={DICTIONARY.SETTINGS} />
		</MenuItem>
	);
};

import { useMutation } from '@tanstack/react-query';
import { useReactQueryWrappersProvider } from 'app/react-query-wrappers-provider/hooks';
import { useQueryKey } from 'shared/react-query-wrappers/hooks/use-query-key';
import { useRefetchQueriesByKey } from 'shared/react-query-wrappers/hooks/use-refetch-queries-by-key';
import { QueryProps } from 'shared/react-query-wrappers/models';
import { disableGlobalErrorHandler } from 'shared/react-query-wrappers/utils/disable-global-error-handler';

/**
 * Make PUT request to the server.
 */
export const usePut = <TData, TInput>(props: QueryProps) => {
	const { queryKey, ignoreGlobalErrorHandler, refetchQueriesByKey } = props;
	const { axios, queryClient } = useReactQueryWrappersProvider();
	const { key, calculateKey } = useQueryKey(queryKey);
	const onSuccess = useRefetchQueriesByKey(refetchQueriesByKey, queryClient, calculateKey);

	const queryResult = useMutation({
		mutationKey: key.queryKey,
		mutationFn: async (input: TInput) => {
			return disableGlobalErrorHandler<TData>(axios.put<TData>(key.url, input), ignoreGlobalErrorHandler);
		},
		onSuccess,
	});

	return { ...queryResult, data: queryResult.data?.data };
};

import { Button, Flex, Link, Stack, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { MissingPosNotificationPropsEA } from 'features/point-of-sale/models';
import { FC, useCallback, useState } from 'react';
import { DICTIONARY } from 'shared/constants';

type Status = 'initial' | 'pending' | 'done';

/** @deprecated */
export const MissingPosNotificationToastEA: FC<MissingPosNotificationPropsEA> = ({ sites, onSiteClick, onPosCreate, onCloseToast }) => {
	const [status, setStatus] = useState<Status>('initial');

	const handlePosCreate = useCallback(async () => {
		setStatus('pending');
		await onPosCreate();
		setStatus('done');
		onCloseToast();
	}, [onPosCreate, onCloseToast]);

	if (status === 'done') {
		return <></>;
	}

	return (
		<Stack>
			<Stack gap="0">
				<Text>
					<T _str={DICTIONARY.MISSING_POS_MESSAGE} />
				</Text>
				<Flex gap="1" flexWrap="wrap">
					{sites.map((site, index) => (
						<Flex data-testid={`missing-pos-for-site-${site.name}`} key={`missing-pos-for-site-${site.name}`}>
							<Text as={Link} onClick={() => onSiteClick(site.id)}>
								{site.name}
							</Text>
							<Text as="span">
								<T _str={index !== sites.length - 1 ? DICTIONARY.COMMA : DICTIONARY.DOT} />
							</Text>
						</Flex>
					))}
				</Flex>
				<Text>
					<T _str={DICTIONARY.MISSING_POS_CREATE_POS_MESSAGE} />
				</Text>
			</Stack>
			<Button
				data-testid="create-missing-pos-identifiers"
				variant="outline"
				size="sm"
				width="fit-content"
				onClick={() => void handlePosCreate()}
				isDisabled={status === 'pending'}
				isLoading={status === 'pending'}
				loadingText={<T _str={DICTIONARY.POS_CREATING} />}
			>
				<T _str={DICTIONARY.CREATE_MISSING_POS_IDENTIFIERS} />
			</Button>
		</Stack>
	);
};

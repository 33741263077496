import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, Flex, IconButton, Text, VStack } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { useTranslate } from 'features/common/utils/translateUtils';
import { DICTIONARY } from 'shared/constants';
import { FC } from 'react';
import { SynchonizationState } from './SynchronizationBox';

export const MissingPosBox: FC<{ state: SynchonizationState; onClick: () => void; disabled: boolean }> = ({ state, onClick, disabled }) => {
	const t = useTranslate();

	return (
		<Alert>
			<AlertIcon />
			<VStack alignItems="flex-start">
				<AlertTitle data-testid="headingMessagePos">
					<T _str={DICTIONARY.RESTORE_PERSONALIZE_AND_ANALYZE} />
				</AlertTitle>
				<AlertDescription data-testid="additionalMessagePos">
					<T _str={DICTIONARY.CLICK_TO_RESTORE_CONNECTION_TO_PERSONALIZE_AND_ANALYZE} />
				</AlertDescription>
				{state === 'idle' && (
					<>
						<Button size="sm" variant="solid" onClick={onClick} isDisabled={disabled}>
							<T _str={DICTIONARY.RESTORE} />
						</Button>
						<Flex alignItems="center" display={disabled ? 'flex' : 'none'}>
							<Text size="sm">
								<T _str={DICTIONARY.IF_THE_BUTTON_IS_DISABLED_CONTACT_SITECORE_SUPPORT} />
							</Text>
						</Flex>
					</>
				)}
				{state === 'synchronizing' && (
					<Box>
						<IconButton size="sm" isLoading={true} aria-label={t(DICTIONARY.SYNCHRONIZING)} data-testid="spinnerButton" />
					</Box>
				)}
			</VStack>
		</Alert>
	);
};

import { TokenCustomClaimKeysEnum } from 'features/common/auth/SitecoreUser';
import { useAuthWithClaims } from 'features/common/auth/useAuthWithClaims';
import { explorer, pages } from 'features/common/config/config';
import { useXMTenant } from 'features/common/tenants/xmTenantContext';

/**
 * @author Panagiotis Dalakouras
 * @deprecated remove after showSitesGrouping is stable and permanent
 */
export const useRedirectSite = () => {
	const { tenant } = useXMTenant();
	const { name: tenantName } = tenant;
	const { user } = useAuthWithClaims();
	const organizationId = user?.[TokenCustomClaimKeysEnum.ORG_ID];

	const redirectHandler =
		(getUrl: (...args: (string | undefined)[]) => string, siteName: string) =>
		(event: React.MouseEvent<HTMLButtonElement>): void => {
			const url = getUrl(organizationId, tenantName, siteName);

			event.preventDefault();
			window.open(url, '_blank');
		};

	return {
		pageRedirectHandler: (event: React.MouseEvent<HTMLButtonElement>, siteName: string) => redirectHandler(pages.getUrl, siteName)(event),
		explorerRedirectHandler: (event: React.MouseEvent<HTMLButtonElement>, siteName: string) => redirectHandler(explorer.getUrl, siteName)(event),
	};
};

import { useAuthWithClaims } from 'features/common/auth/useAuthWithClaims';
import { explorer } from 'features/common/config/config';
import { useXMTenant } from 'features/common/tenants/xmTenantContext';
import { useCallback } from 'react';

export const useOpenExplorer = (siteName: string): (() => void) => {
	const { tenant } = useXMTenant();
	const { user } = useAuthWithClaims();
	const organizationId = user?.['https://auth.sitecorecloud.io/claims/org_id'];
	const { getUrl } = explorer;
	const url = getUrl(organizationId, tenant.name, siteName);

	return useCallback(() => {
		window.open(url);
	}, [url]);
};

import { PageBodyContentSection, SectionDataRecord } from 'entities/page-layout/ui';
import { useFeatures } from 'features/common/featureFlags/featureFlags';
import { RemoveSiteDefinitionButton } from 'features/remove-site-definition/ui';
import { SiteDefinitionPageOutlet } from 'pages/site-definition/models/site-definition-props';
import { useOutletContext } from 'react-router';
import { DICTIONARY, EMPTY_VALUE } from 'shared/constants';
import { GeneralSectionForm, SettingsSectionForm, SiteLanguageForm } from 'widgets/site-definition-update-form/ui';

export const SiteDefinitionPageGeneral = () => {
	const { siteDefinition, languages, allLanguages, site } = useOutletContext<SiteDefinitionPageOutlet>();
	const { showManagedLanguages } = useFeatures();

	return (
		<>
			<PageBodyContentSection title={DICTIONARY.GENERAL}>
				{siteDefinition && allLanguages && <GeneralSectionForm {...siteDefinition} xmAppsLanguages={allLanguages} />}
			</PageBodyContentSection>
			<PageBodyContentSection title={DICTIONARY.LANGUAGES}>
				{siteDefinition && allLanguages && (
					<SiteLanguageForm languages={showManagedLanguages ? languages || [] : allLanguages} siteDefinition={siteDefinition} />
				)}
			</PageBodyContentSection>
			<PageBodyContentSection title={DICTIONARY.SETTINGS}>
				{siteDefinition && <SettingsSectionForm {...siteDefinition} siteId={site?.id || ''} />}
			</PageBodyContentSection>
			<PageBodyContentSection title={DICTIONARY.DANGER_ZONE}>
				{siteDefinition && (
					<RemoveSiteDefinitionButton
						hostId={siteDefinition.id}
						hostName={siteDefinition.name}
						siteId={site?.id || ''}
						siteName={site?.name || ''}
						hosts={site?.hosts}
						collectionId={site?.collectionId || ''}
					/>
				)}
			</PageBodyContentSection>
			<PageBodyContentSection title={DICTIONARY.DETAILS}>
				<SectionDataRecord header={DICTIONARY.CREATED_BY} value={siteDefinition?.createdBy || EMPTY_VALUE} />
				<SectionDataRecord header={DICTIONARY.CREATED_ON} value={siteDefinition?.created || EMPTY_VALUE} />
				<SectionDataRecord header={DICTIONARY.TEMPLATE} value={site?.siteTemplateInfo?.name || EMPTY_VALUE} />
				<SectionDataRecord
					header={DICTIONARY.ITEM_PATH}
					//TODO: SITES-1504 - remove the siteDefinition?.settings?.siteDefinitionPath when Sites API will remove SettingsSplit feature flag, also check the siteDefinition?.itemPath prop
					// eslint-disable-next-line prettier/prettier
					value={
						siteDefinition?.settings?.siteDefinitionPath ||
						siteDefinition?.properties?.siteDefinitionPath ||
						siteDefinition?.itemPath ||
						EMPTY_VALUE
					}
				/>
			</PageBodyContentSection>
		</>
	);
};

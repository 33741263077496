import { SiteCreationProgressCard } from 'entities/site-card/ui';
import { FC } from 'react';
import { useCheckJobStatus } from 'shared/check-job-status';

export const SitesCreationInProgress: FC<{ collectionId: string }> = ({ collectionId }) => {
	const { getRunningSiteCreationsWithinCollection } = useCheckJobStatus();

	return (
		<>
			{getRunningSiteCreationsWithinCollection(collectionId)?.map(
				(job) => !job.done && <SiteCreationProgressCard key={job.handle} siteName={job.site || ''} />
			)}
		</>
	);
};

import {
	Badge,
	Box,
	Button,
	CircularProgress,
	Flex,
	Heading,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Spacer,
	Text,
	Tooltip,
	useDisclosure,
} from '@chakra-ui/react';
import { mdiDotsHorizontal } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { useCdpTenant } from 'features/common/tenants/CdpTenantProvider';
import { useTranslate } from 'features/common/utils/translateUtils';
import { CreateModal } from 'features/pos/components/createPOS/CreateModal';
import { DataTable, DataTableColumn } from 'features/temporary/DataTable';
import { FC, useMemo, useState } from 'react';
import { DICTIONARY } from 'shared/constants';
import { useAssignPos } from '../utils/useAssignPos';
import { PosBySite, useGetSitePos } from '../utils/useGetSitePos';
import { usePosMappingProcessing } from '../utils/usePosMappingProcessing';
import { useUnassignPos } from '../utils/useUnassignPos';
import { AssignPosModal } from './SiteIdentifiersSettings/AssignPosModal';

export const SiteIdentifiersSettings: FC = () => {
	const [selectedLanguage, setSelectedLanguage] = useState<string>();
	const createModalDisclosure = useDisclosure();
	const assignModalDisclosure = useDisclosure();
	const t = useTranslate();
	const { posList, isLoading } = useGetSitePos();
	const { isEnabled } = useCdpTenant();
	const unassignPos = useUnassignPos();
	const assignPos = useAssignPos();
	const notAssignedLangs = useMemo(() => posList.filter((x) => x.state != 'ok' && x.siteLanguage != '*').map((x) => x.siteLanguage), [posList]);
	const { processMapping, processingLang } = usePosMappingProcessing();

	const columns: DataTableColumn<PosBySite>[] = useMemo(() => {
		return [
			{
				Header: t(DICTIONARY.SITE_LANGUAGE),
				accessor: (pos: PosBySite) => (
					<>
						<Text display={pos.siteLanguage !== '*' ? 'inherit' : 'none'}>{pos.siteLanguage.toUpperCase()}</Text>
						<Badge display={pos.siteLanguage === '*' ? 'inherit' : 'none'}>
							<T _str={DICTIONARY.FALLBACK} />
						</Badge>
					</>
				),
				disableSortBy: true,
			},
			{
				Header: t(DICTIONARY.IDENTIFIER_NAME),
				accessor: (pos: PosBySite) => (
					<>
						{pos.siteLanguage === processingLang ? (
							<CircularProgress size={6} isIndeterminate capIsRound color="primary" trackColor="neutral-color.200"></CircularProgress>
						) : (
							<>
								<Text title={pos.name} noOfLines={1} maxWidth="28" data-testid="posName" display={pos.name ? 'inherit' : 'none'}>
									{pos.name}
								</Text>

								<Badge display={pos.state === 'no identifier' ? 'inherit' : 'none'}>
									<T _str={DICTIONARY.NO_IDENTIFIER} />
								</Badge>
								<Tooltip label={t(DICTIONARY.IDENTIFIER_IS_BROKEN_AND_FAILED_TO_RUN)}>
									<Badge colorScheme="danger" display={pos.state === 'failed' ? 'inherit' : 'none'}>
										<T _str={DICTIONARY.FAILED} />
									</Badge>
								</Tooltip>
							</>
						)}
					</>
				),
				disableSortBy: true,
			},
			{
				Header: t(DICTIONARY.LANGUAGE),
				accessor: (pos: PosBySite) => pos.language.toUpperCase(),
				disableSortBy: true,
			},
			{
				Header: t(DICTIONARY.MARKET),
				accessor: (pos: PosBySite) => (
					<Text title={pos.market} noOfLines={1} maxWidth="28">
						{pos.market}
					</Text>
				),
				disableSortBy: true,
			},
			{
				Header: t(DICTIONARY.BRAND),
				accessor: (pos: PosBySite) => (
					<Text title={pos.brand} noOfLines={1} maxWidth="28" data-testid="lineMarker">
						{pos.brand}
					</Text>
				),
				disableSortBy: true,
			},
			{
				Header: t(DICTIONARY.TIMEOUT_IN_MINUTES),
				accessor: (pos: PosBySite) => <Text visibility={pos.timeout <= 0 ? 'hidden' : 'inherit'}>{pos.timeout}</Text>,
				disableSortBy: true,
			},
			{
				Header: '  ',
				accessor: (pos: PosBySite) => (
					<Menu>
						<MenuButton
							as={IconButton}
							variant="ghost"
							size="sm"
							data-testid={`posmenu-${pos.siteLanguage}`}
							onClick={() => setSelectedLanguage(pos.siteLanguage)}
						>
							<Icon path={mdiDotsHorizontal} />
						</MenuButton>
						<MenuList>
							{pos.state !== 'ok' && pos.siteLanguage !== '*' && (
								<MenuItem onClick={assignModalDisclosure.onOpen}>
									<T _str={DICTIONARY.ASSIGN_IDENTIFIER_TO_SITE} />
								</MenuItem>
							)}
							{pos.state !== 'no identifier' && (
								<MenuItem onClick={() => void processMapping(unassignPos(pos.siteLanguage), selectedLanguage)}>
									<T _str={DICTIONARY.UNASSIGN} />
								</MenuItem>
							)}
							{pos.state !== 'ok' && pos.siteLanguage !== '*' && (
								<MenuItem
									onClick={() => {
										setSelectedLanguage(pos.siteLanguage);
										createModalDisclosure.onOpen();
									}}
								>
									<T _str={DICTIONARY.CREATE_AND_ASSIGN_ANALYTICS_IDENTIFIER} />
								</MenuItem>
							)}
						</MenuList>
					</Menu>
				),
				disableSortBy: true,
				width: '7%',
			},
		];
	}, [t, createModalDisclosure, selectedLanguage, assignModalDisclosure.onOpen, unassignPos, processingLang, processMapping]);

	return (
		<Flex flexDirection="column" gap="6">
			<Flex flexDirection="row" width="full" mt="6">
				<Flex direction="column" gap="2" pr="8">
					<Heading size="lg">
						<T _str={DICTIONARY.ANALYTICS_IDENTIFIERS} />
					</Heading>
					<Text>
						<T _str={DICTIONARY.ANALYTICS_IDENTIFIER_TOOLTIP} />
					</Text>
				</Flex>
				<Spacer />
				<Button
					minW="fit-content"
					isLoading={isLoading}
					onClick={() => {
						setSelectedLanguage(undefined);
						createModalDisclosure.onOpen();
					}}
					data-testid="pos-create"
					isDisabled={notAssignedLangs.length == 0}
				>
					<T _str={DICTIONARY.CREATE} />
				</Button>
			</Flex>
			<Box
				backgroundColor="chakra-body-bg"
				borderColor="chakra-border-color"
				borderWidth="1px"
				borderRadius="md"
				borderStyle="solid"
				visibility={isEnabled ? 'visible' : 'hidden'}
			>
				<DataTable
					width="100%"
					isLoading={isLoading}
					data={posList}
					totalItems={posList.length}
					onChange={() => {}}
					columns={columns}
					page={0}
					pagination="hidden"
					variant="unstyled"
				/>
			</Box>
			<CreateModal
				{...createModalDisclosure}
				onCreated={(newPos) => void processMapping(assignPos(newPos, newPos.language), selectedLanguage)}
				initialLanguage={selectedLanguage}
				predefinedLanguages={notAssignedLangs}
				lockLanguage={selectedLanguage != undefined}
				customHeader="Create and assign new site identifier"
			/>
			<AssignPosModal
				disclosure={assignModalDisclosure}
				onSave={(pos) => void processMapping(assignPos(pos, selectedLanguage), selectedLanguage)}
			/>
		</Flex>
	);
};

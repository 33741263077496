import { Badge, Card, CardBody, HStack, LinkBox, LinkOverlay, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { ScLaunchpadItemData as Item } from '../data/types';

export const ScLaunchpadItem: FC<Item> = ({ name, icon, url, comingSoon, colorScheme }) => {
	return (
		<LinkBox as={Card} layerStyle="interactive.raise" w="72" data-testid={`${name.toLowerCase().replaceAll(' ', '-')}-button`}>
			<CardBody>
				<LinkOverlay href={!comingSoon ? url : undefined}>
					<HStack alignItems="center" gap="3">
						<Icon color={colorScheme} layerStyle="icon.subtle" boxSize="icon.xl" path={icon} />
						<Text variant="strong" noOfLines={1}>
							<T _str={name} />
						</Text>
						{comingSoon && (
							<Badge colorScheme="blue" fontSize="3xs" position="absolute" right="1.5" bottom="1.5">
								<T _str={DICTIONARY.COMING_SOON} />
							</Badge>
						)}
					</HStack>
				</LinkOverlay>
			</CardBody>
		</LinkBox>
	);
};

import { Button, ButtonGroup, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { T } from '@transifex/react';
import {} from 'features/sites/utils/useGetSite';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { SiteNameField } from '../rename/SiteNameField';
import { DuplicateFormHeader } from './DuplicateForm';

export type RenameForm = {
	newName: string;
};

export type DuplicateSiteModalProps = {
	siteName: string;
	isOpen: boolean;
	onClose: () => void;
	onSubmit: (cloneName: string) => Promise<boolean>;
};

export const DuplicateSiteModal: FC<DuplicateSiteModalProps> = ({ siteName, isOpen, onClose, onSubmit }) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose} size="md" orientation="vertical" blockScrollOnMount={false}>
			<ModalOverlay>
				<ModalContent>
					<DuplicateFormHeader siteName={siteName} initialNewName={`Copy of ${siteName}`} onDuplicate={onSubmit}>
						{({ submitForm, isSubmitting }) => (
							<>
								<ModalHeader>
									<T _str={DICTIONARY.DUPLICATE_SITE} name={siteName} />
								</ModalHeader>
								<ModalCloseButton />
								<ModalBody minHeight="28">
									<SiteNameField label="New name" autoSelect={true} />
								</ModalBody>
								<ModalFooter>
									<ButtonGroup>
										<Button variant="ghost" onClick={onClose}>
											<T _str={DICTIONARY.CANCEL} />
										</Button>
										<Button type="submit" onClick={() => void submitForm()} isLoading={isSubmitting}>
											<T _str={DICTIONARY.DUPLICATE} />
										</Button>
									</ButtonGroup>
								</ModalFooter>
							</>
						)}
					</DuplicateFormHeader>
				</ModalContent>
			</ModalOverlay>
		</Modal>
	);
};

import { UseMediaLightbox } from 'entities/media-lightbox/models';
import { useCallback, useState } from 'react';

export const useMediaLightbox: UseMediaLightbox = (imageUrl) => {
	const [hover, setHover] = useState(false);
	const [openLightbox, setOpenLightbox] = useState(false);

	const handleOverlayClick = useCallback(
		(e: React.MouseEvent<HTMLDivElement>) => {
			e.stopPropagation();
			setHover(false);

			if (imageUrl) {
				setOpenLightbox(true);
			}
		},
		[setHover, setOpenLightbox, imageUrl]
	);

	const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
		if (e.key === 'Escape') {
			e.stopPropagation();
		}
	}, []);

	return {
		handleOverlayClick,
		handleKeyDown,
		hover,
		setHover,
		openLightbox,
		setOpenLightbox,
	};
};

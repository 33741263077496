import { clearQueryParamsOnColumnsSwith, clearQueryParamsOnTilesSwitch, VIEW_PARAM_NAME } from 'features/common/utils/сlearNonRelevantQueryParams';
import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { viewModes, ViewSwitcherModes } from '../components/ViewSwitcher';

export const useViewSwitcherModes = (): [ViewSwitcherModes, (value: ViewSwitcherModes) => void] => {
	const [searchParams, setUrlSearchParams] = useSearchParams();

	const viewMode: ViewSwitcherModes = useMemo(() => {
		const viewModeParam = searchParams.get(VIEW_PARAM_NAME);

		switch (viewModeParam) {
			case viewModes.tiles:
			case viewModes.columns:
				return viewModeParam;
			default:
				return viewModes.tiles;
		}
	}, [searchParams]);

	const setViewMode = useCallback(
		(value: ViewSwitcherModes) => {
			switch (value) {
				case viewModes.tiles:
					searchParams.set(VIEW_PARAM_NAME, viewModes.tiles);
					clearQueryParamsOnTilesSwitch(searchParams);
					break;
				case viewModes.columns:
					searchParams.set(VIEW_PARAM_NAME, viewModes.columns);
					clearQueryParamsOnColumnsSwith(searchParams);
					break;
				default:
					break;
			}

			setUrlSearchParams(searchParams);
		},
		[searchParams, setUrlSearchParams]
	);

	useEffect(() => {
		const viewParamValue = searchParams.get(VIEW_PARAM_NAME);

		if (viewParamValue !== viewModes.tiles && viewParamValue !== viewModes.columns) {
			searchParams.set(VIEW_PARAM_NAME, viewModes.tiles);
			setUrlSearchParams(searchParams, { replace: true });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return [viewMode, setViewMode];
};

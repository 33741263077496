import { Button } from '@chakra-ui/react';
import { mdiPencilOutline } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { DetailedItemBase } from 'features/millerColumns/utils';
import { useOpenSiteEditor } from 'features/sites/utils/useOpenSiteEditor';
import { FC, useCallback } from 'react';
import { DICTIONARY } from 'shared/constants';
import { VariousButton } from './VariousButton';

export const ButtonGroup: FC<DetailedItemBase> = ({ currentItem, itemType, currentSite }) => {
	const openSiteEditor = useOpenSiteEditor();
	const openPagesDependOnType = useCallback(() => {
		if (!currentItem) {
			return;
		}

		openSiteEditor(currentSite?.name, currentItem.itemId);
	}, [currentItem, currentSite, openSiteEditor]);

	return (
		<>
			{itemType === 'item' && (
				<Button variant="outline" rightIcon={<Icon path={mdiPencilOutline} />} size="sm" onClick={openPagesDependOnType}>
					<T _str={DICTIONARY.DESIGN} />
				</Button>
			)}
			<VariousButton type={itemType} name={currentSite?.name} siteType={currentSite?.siteTypeByCollection} />
		</>
	);
};

import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import { DuplicateKey, DuplicateSiteContextType } from '../components/duplicate/DuplicateSiteProvider';
import { CloneSiteInput, CloneSitePayload } from '../data/createSiteTypes';
import { duplicateSiteQuery } from '../queries';

export type UseDuplicateSite = (
	onOpen: () => void,
	onClose: () => void
) => {
	siteName: string;
	duplicateSite: (cloneName: string) => Promise<boolean>;
} & DuplicateSiteContextType;

export const useDuplicateSite: UseDuplicateSite = (onOpen: () => void, onClose: () => void) => {
	const [currentSiteName, setCurrentSiteName] = useState('');
	const [sitesInProgress, setSitesInProgress] = useState<DuplicateKey[]>([]);
	const [cloneSiteMutation] = useMutation<CloneSitePayload, CloneSiteInput>(duplicateSiteQuery);

	const openDuplicateSiteModal = useCallback(
		(siteName: string) => {
			setCurrentSiteName(siteName);
			onOpen();
		},
		[onOpen]
	);

	const duplicateSite = useCallback(
		async (cloneName: string) => {
			const { data, errors } = await cloneSiteMutation({
				variables: {
					cloneName: cloneName,
					siteName: currentSiteName,
				},
				refetchQueries: ['GetSitesAndJobsList'],
			});

			if (!data || (errors && errors.length)) {
				return false;
			}

			setSitesInProgress([
				...sitesInProgress,
				{
					cloneName,
					siteName: currentSiteName,
				},
			]);

			onClose();

			return true;
		},
		[cloneSiteMutation, currentSiteName, onClose, sitesInProgress]
	);

	const removeSitesInProgress = useCallback(
		(cloneNames: string[]) => {
			const activeCloningSites = sitesInProgress.filter((site) => cloneNames.includes(site.cloneName));

			if (activeCloningSites.length !== sitesInProgress.length) {
				setSitesInProgress(activeCloningSites);
			}
		},
		[sitesInProgress]
	);

	return {
		siteName: currentSiteName,
		sitesInProgress,
		removeSitesInProgress,
		openDuplicateSiteModal,
		duplicateSite,
	};
};

import { MenuItem } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { CreateSiteMenuItemProps } from 'features/create-site/models';
import { CreateSiteModalWrapper } from 'features/create-site/ui/create-site-modal-wrapper';
import { FC, PropsWithChildren } from 'react';
import { DICTIONARY } from 'shared/constants';

export const CreateSiteMenuItem: FC<PropsWithChildren<CreateSiteMenuItemProps>> = ({ text = DICTIONARY.SITE }) => {
	return (
		<CreateSiteModalWrapper>
			<MenuItem data-testid="sc-create-site-menu-item">
				<T _str={text} />
			</MenuItem>
		</CreateSiteModalWrapper>
	);
};

import { Button, ButtonGroup, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC, ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { DICTIONARY } from 'shared/constants';

export type PreventDiscardChangesContextType = {
	tryLeave: (onLeave: () => void) => void;
	pendingChanges: boolean;
	setPendingChanges: (value: boolean) => void;
	registerOnDiscard: (onDiscard: () => void) => void;
	unRegisterOnDiscard: (onDiscard: () => void) => void;
};

export const PreventDiscardChangesContext = createContext<PreventDiscardChangesContextType>({
	tryLeave: () => {},
	setPendingChanges: () => {},
	pendingChanges: false,
	registerOnDiscard: () => {},
	unRegisterOnDiscard: () => {},
});

export type PreventDiscardChangesProps = {
	children: (props: PreventDiscardChangesContextType) => ReactNode;
};

export type DiscardChangesModalState = {
	onLeave: () => void;
};

export const DiscardChangesModal: FC<{ isOpen: boolean; onClose: () => void; onDiscard: () => void }> = ({ isOpen, onClose, onDiscard }) => {
	const handleClick = useCallback(() => {
		onClose();
		onDiscard();
	}, [onClose, onDiscard]);

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="md" blockScrollOnMount={false}>
			<ModalOverlay>
				<ModalContent>
					<ModalHeader>
						<T _str={DICTIONARY.CHANGE_NOT_SAVED} />
					</ModalHeader>
					<ModalBody />
					<ModalFooter>
						<ButtonGroup>
							<Button variant="ghost" type="submit" onClick={handleClick}>
								<T _str={DICTIONARY.DISCARD_CHANGES} />
							</Button>
							<Button onClick={onClose}>
								<T _str={DICTIONARY.GO_BACK} />
							</Button>
						</ButtonGroup>
					</ModalFooter>
				</ModalContent>
			</ModalOverlay>
		</Modal>
	);
};

export const PreventDiscardChanges = ({ children }: PreventDiscardChangesProps) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [modalState, setModalState] = useState<DiscardChangesModalState>({
		onLeave: () => {},
	});
	const discardCallbacks = useRef<Array<() => void>>([]);
	const [pendingChanges, setPendingChanges] = useState(false);

	const registerOnDiscard = useCallback((onDiscard: () => void) => {
		discardCallbacks.current.push(onDiscard);
	}, []);

	const unRegisterOnDiscard = useCallback((onDiscard: () => void) => {
		discardCallbacks.current = discardCallbacks.current.filter((item) => item !== onDiscard);
	}, []);

	const callDiscardChanges = useCallback(() => {
		modalState.onLeave();

		for (const onDiscard of discardCallbacks.current) {
			onDiscard();
		}
	}, [modalState]);

	const value = useMemo<PreventDiscardChangesContextType>(() => {
		return {
			tryLeave: (onLeave) => {
				if (!pendingChanges) {
					onLeave();
				} else {
					setModalState({ onLeave });
					onOpen();
				}
			},
			setPendingChanges,
			pendingChanges,
			registerOnDiscard,
			unRegisterOnDiscard,
		};
	}, [onOpen, pendingChanges, registerOnDiscard, unRegisterOnDiscard]);

	return (
		<>
			<DiscardChangesModal isOpen={isOpen} onClose={onClose} onDiscard={callDiscardChanges}></DiscardChangesModal>
			<PreventDiscardChangesContext.Provider value={value}>{children(value)}</PreventDiscardChangesContext.Provider>
		</>
	);
};

export const usePreventDiscardChanges = (onDiscard?: () => void) => {
	const { registerOnDiscard, unRegisterOnDiscard, ...rest } = useContext(PreventDiscardChangesContext);

	useEffect(() => {
		if (!onDiscard) {
			return;
		}

		registerOnDiscard(onDiscard);

		return () => unRegisterOnDiscard(onDiscard);
	}, [registerOnDiscard, onDiscard, unRegisterOnDiscard]);

	return rest;
};

export type SiteSettingsViewModel = {
	displayName: string;
	description: string;
	homePageId: string;
	language: string;
	languageEmbedding: boolean;
	itemLanguageFallback: boolean;
	fieldLanguageFallback: boolean;
	linkableSite: boolean;
	hostname: string;
	targetHostname?: string;
};

export type EditSiteInput = {
	displayName: string;
	description: string;
	homePageId: string;
	language: string;
	languageEmbedding: boolean;
	itemLanguageFallback: boolean;
	fieldLanguageFallback: boolean;
	linkableSite: boolean;
	hostname: string;
	targetHostname?: string;
};

export const mapToBoolean = (value: string | boolean): boolean => (typeof value === 'boolean' ? value : value === 'true');

export const mapSiteSettingsViewModelToEditSiteInput = (viewModel: SiteSettingsViewModel): EditSiteInput => {
	return {
		displayName: viewModel.displayName,
		description: viewModel.description,
		homePageId: viewModel.homePageId,
		language: viewModel.language,
		languageEmbedding: mapToBoolean(viewModel.languageEmbedding),
		itemLanguageFallback: mapToBoolean(viewModel.itemLanguageFallback),
		fieldLanguageFallback: mapToBoolean(viewModel.fieldLanguageFallback),
		hostname: viewModel.hostname,
		linkableSite: mapToBoolean(viewModel.linkableSite),
		targetHostname: viewModel.targetHostname,
	};
};

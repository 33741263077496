import { Button, ButtonGroup, Flex, ModalBody, ModalFooter, Textarea, UseDisclosureReturn } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FormFieldProps } from 'entities/form-field-control/models';
import { FormFieldControl } from 'entities/form-field-control/ui';
import {
	useCheckCollectionMetaNameUniqueness,
	useCheckCollectionSystemNameUniqueness,
	useCreateCollectionModalWrapper,
} from 'features/collections/hooks';
import { Field, Form, Formik } from 'formik';
import { FC } from 'react';
import { COLLECTION_DESCRIPTION_VALIDATION_NUMBER, COLLECTION_NAME_VALIDATION_NUMBER, DICTIONARY } from 'shared/constants';
import { NonPredictiveInput, sanitizeSiteName } from 'shared/utils';
import { createCollectionValidationSchema } from 'shared/validations';

export const CreateCollectionModalBody: FC<Pick<UseDisclosureReturn, 'onClose'>> = ({ onClose }) => {
	const { onFormSubmit } = useCreateCollectionModalWrapper(onClose);
	const validateCollectionMetaNameUniqueness = useCheckCollectionMetaNameUniqueness({ collectionName: '', formKey: 'displayName' });
	const validateCollectionSystemNameUniqueness = useCheckCollectionSystemNameUniqueness({ collectionName: '', formKey: 'name' });

	const validateCollection = async (values: { name: string; displayName: string }): Promise<Record<string, string>> => {
		const validateMetaName = await validateCollectionMetaNameUniqueness(values.displayName);
		const validateSystemName = await validateCollectionSystemNameUniqueness(values.name);

		return {
			...validateMetaName,
			...validateSystemName,
		} as Record<string, string>;
	};

	return (
		<Formik
			initialValues={{ name: '', displayName: '', description: '' }}
			validationSchema={createCollectionValidationSchema}
			onSubmit={onFormSubmit}
			validate={validateCollection}
			validateOnBlur={false}
			validateOnChange={false}
			validateOnMount={false}
		>
			{({ handleChange, touched, setFieldValue, isSubmitting }) => (
				<Form noValidate>
					<ModalBody>
						<Flex gap="6" direction="column">
							<Field name="displayName" autoFocus>
								{({ field, form }: FormFieldProps) => (
									<FormFieldControl
										form={form}
										field={field}
										name={field.name}
										isRequired
										maxLength={COLLECTION_NAME_VALIDATION_NUMBER}
										label={DICTIONARY.NAME}
										helper={DICTIONARY.COLLECTION_NAME_XM_CLOUD_INFO_MESSAGE}
									>
										<NonPredictiveInput
											{...field}
											maxLength={COLLECTION_NAME_VALIDATION_NUMBER}
											onChange={(e) => {
												handleChange(e);

												if (!touched.name) {
													setFieldValue('name', e?.target?.value ? sanitizeSiteName(e?.target.value) : '');
												}
											}}
											autoFocus
										/>
									</FormFieldControl>
								)}
							</Field>
							<Field name="name">
								{({ field, form }: FormFieldProps) => (
									<FormFieldControl
										form={form}
										field={field}
										name={field.name}
										isRequired
										maxLength={COLLECTION_NAME_VALIDATION_NUMBER}
										label={DICTIONARY.COLLECTION_LABEL_SYSTEM_NAME}
										helper={DICTIONARY.COLLECTION_SYSTEM_NAME_INFO_MESSAGE}
									>
										<NonPredictiveInput {...field} maxLength={COLLECTION_NAME_VALIDATION_NUMBER} />
									</FormFieldControl>
								)}
							</Field>
							<Field name="description">
								{({ field, form }: FormFieldProps) => (
									<FormFieldControl
										form={form}
										field={field}
										name={field.name}
										maxLength={COLLECTION_DESCRIPTION_VALIDATION_NUMBER}
										label={DICTIONARY.DESCRIPTION}
									>
										<Textarea {...field} maxLength={COLLECTION_DESCRIPTION_VALIDATION_NUMBER} />
									</FormFieldControl>
								)}
							</Field>
						</Flex>
					</ModalBody>
					<ModalFooter>
						<ButtonGroup>
							<Button variant="ghost" data-testid="cancel-btn" isDisabled={isSubmitting} onClick={onClose}>
								<T _str={DICTIONARY.CANCEL} />
							</Button>
							<Button data-testid="submit-btn" isLoading={isSubmitting} loadingText={DICTIONARY.CREATING_TEXT} type="submit">
								<T _str={DICTIONARY.CREATE} />
							</Button>
						</ButtonGroup>
					</ModalFooter>
				</Form>
			)}
		</Formik>
	);
};

import { Box, Text, TextProps, Tooltip } from '@chakra-ui/react';
import { mdiContentCopy } from '@mdi/js';
import { Icon } from 'entities/icon/ui';
import { FC, useCallback, useState } from 'react';
import { useCopyToClipboard } from 'react-use';
import { DICTIONARY } from 'shared/constants';

export type TextWithCopyProps = {
	text?: string;
	isSecret?: boolean;
} & TextProps;

export const TextWithCopy: FC<TextWithCopyProps> = (props) => {
	const { isSecret, text, display, ...textProps } = props;
	const [, copy] = useCopyToClipboard();
	const [isCopied, setIsCopied] = useState(false);
	const [isCopyButtonShown, setIsCopyButtonShown] = useState(false);

	const onMouseLeave = useCallback(() => {
		setIsCopyButtonShown(false);
		setIsCopied(false);
	}, []);
	const onCopy = useCallback(() => {
		if (text) {
			copy(text);
			setIsCopied(true);
		}
	}, [copy, text]);

	return (
		<Box display={display} onMouseEnter={() => setIsCopyButtonShown(true)} onMouseLeave={onMouseLeave}>
			<Text {...textProps} display="inline-block">
				{isSecret ? '************************' : text}
			</Text>
			<Tooltip label={DICTIONARY.COPIED} isOpen={isCopied}>
				<Box display="inline-block" width={4} ml={2}>
					<button onClick={onCopy} data-testid="copyButton" hidden={!isCopyButtonShown || !text}>
						<Icon path={mdiContentCopy} boxSize={4} color="neutral-fg" />
					</button>
				</Box>
			</Tooltip>
		</Box>
	);
};

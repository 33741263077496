import { useToast } from '@chakra-ui/react';
import { QueryCache, QueryClient, QueryFunction, QueryKey } from '@tanstack/react-query';
import { defaultErrorHandler } from 'features/common/apiUtils/queryHelper';
import { useCallback, useMemo } from 'react';
import { DEFAULT_STALE_TIME, IGNORE_GLOBAL_ERROR_HANDLER_FLAG } from 'shared/constants';
import { useObservability } from 'shared/observability/ui';

export const useQueryClient = (defaultQueryFn: QueryFunction<unknown, QueryKey, never> | undefined) => {
	const { apm } = useObservability();
	const toast = useToast();

	const onError = useCallback(
		(error: Error) => {
			apm?.captureError(error);

			if (error.name !== IGNORE_GLOBAL_ERROR_HANDLER_FLAG) {
				defaultErrorHandler(error, toast);
			}
		},
		[toast, apm]
	);

	return useMemo(
		() =>
			new QueryClient({
				queryCache: new QueryCache({
					onError,
				}),
				defaultOptions: {
					queries: {
						retry: false,
						refetchOnWindowFocus: false,
						refetchOnMount: false,
						queryFn: defaultQueryFn,
						staleTime: DEFAULT_STALE_TIME,
					},
					mutations: {
						retry: false,
						onError,
					},
				},
			}),
		[defaultQueryFn, onError]
	);
};

import { Th, Thead, Tr } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { DICTIONARY } from 'shared/constants';

export const SiteDefinitionsTableHeader = () => {
	return (
		<Thead>
			<Tr>
				<Th>
					<T _str={DICTIONARY.NAME} />
				</Th>
				<Th>
					<T _str={DICTIONARY.DEFAULT_LANGUAGE} />
				</Th>
				<Th>
					<T _str={DICTIONARY.HOST_NAME} />
				</Th>
				<Th>
					<T _str={DICTIONARY.TARGET_HOST_NAME} />
				</Th>
				<Th>
					<T _str={DICTIONARY.RENDERING_HOST} />
				</Th>
				<Th />
			</Tr>
		</Thead>
	);
};

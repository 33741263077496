import { ThumbnailImage } from 'entities/thumbnail/models';
import { srcFormatter } from 'shared/utils/base64-src';

export const parseFile = (file: string | undefined = '', base64: string | undefined = undefined, id: string | undefined = ''): ThumbnailImage => {
	let fileExt;
	let fileName;

	if (file?.includes('data:image')) {
		fileExt = file.split(';')[0].split('/')[1];
		fileName = `thumbnail_${id}`;
	} else {
		fileExt = file?.split(/[#?]/)[0]?.split('.')?.pop()?.trim();
		fileName = file?.split(/[#?]/)[0]?.split('/')?.pop()?.split('.')[0];
	}

	return { extension: fileExt, name: fileName, url: srcFormatter(base64 || file) || '' };
};

// Function to convert base64 string to File object
export const base64ToFile = (base64String: string, filename = 'image'): File => {
	// Split the base64 string to get the content type and the actual base64 data
	const parts = base64String.split(';base64,');
	const contentType = parts[0].split(':')[1];
	const extension = contentType.split('/')[1];
	const base64Data = parts[1];

	// Convert base64 to binary string
	const binaryStr = atob(base64Data);

	// Create an array of bytes
	const len = binaryStr.length;
	const bytes = new Uint8Array(len);

	for (let i = 0; i < len; i++) {
		bytes[i] = binaryStr.charCodeAt(i);
	}

	// Create a Blob with the bytes and the content type
	const blob = new Blob([bytes], { type: contentType });

	// Create and return a File object
	return new File([blob], `${filename}.${extension}`, { type: contentType });
};

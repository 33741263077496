import { Box, Flex } from '@chakra-ui/react';
import { NoSitesFound } from 'features/sites/components/NoSitesFound';
import { FC } from 'react';
import { useGetMillerData } from '../utils/useGetMillerData';
import { DetailsSection } from './detailsSection/DetailsSection';
import { MillerColumns } from './MillerColumns';
import { MillerItem } from './MillerItem';

export const MillerContainer: FC = () => {
	const { millerColumns, loadItems, selectedSite, activeIds, currentItem, itemType, loading, resetFilters } = useGetMillerData();

	if (!millerColumns.length || !millerColumns[0].length) {
		return (
			<Box>
				<NoSitesFound clearFilter={resetFilters} />
			</Box>
		);
	}

	return (
		<Box w="full" h="full">
			<Flex h="full">
				<Box h="full" flexGrow={1} minW="0">
					<MillerColumns
						columns={millerColumns}
						activeIds={activeIds}
						onClick={loadItems}
						millerItemFactory={(props) => <MillerItem {...props} />}
					/>
				</Box>
				<Box flexShrink={0}>
					<DetailsSection currentItem={currentItem} itemType={itemType} loading={loading} currentSite={selectedSite} />
				</Box>
			</Flex>
		</Box>
	);
};

import { MenuItem, Tooltip } from '@chakra-ui/react';
import { mdiFormTextbox } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { useTranslate } from 'features/common/utils/translateUtils';
import { RenameSiteDefinitionModal } from 'features/site-definition-actions-menu/ui';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { Access } from 'shared/models';

type RenameSiteDefinitionMenuItemProps = {
	collectionId: string;
	name: string;
	siteId: string;
	hostId: string;
	siteAccess: Access;
};

export const RenameSiteDefinitionMenuItem: FC<RenameSiteDefinitionMenuItemProps> = ({ collectionId, name, siteId, hostId, siteAccess }) => {
	const t = useTranslate();

	return (
		<Tooltip label={!siteAccess.canRename ? t(DICTIONARY.ACCESS_DENIED_DEFINITIONS.SITE_RENAME) : ''}>
			<RenameSiteDefinitionModal siteId={siteId} hostId={hostId} name={name} collectionId={collectionId}>
				<MenuItem icon={<Icon path={mdiFormTextbox} />} isDisabled={!siteAccess.canRename} data-testid="renameSiteDefinitionMenuItem">
					<T _str={DICTIONARY.RENAME} />
				</MenuItem>
			</RenameSiteDefinitionModal>
		</Tooltip>
	);
};

import { useCallback } from 'react';
import { ENDPOINTS } from 'shared/endpoints';
import { usePatch } from 'shared/react-query-wrappers/hooks/use-patch';
import { ThumbnailGeneralHookModel } from '../models';

export const useRemoveGeneralThumbnail = ({ siteId }: ThumbnailGeneralHookModel) => {
	const { mutateAsync } = usePatch({
		queryKey: {
			url: ENDPOINTS.SITE,
			params: { id: siteId ?? '' },
		},
	});

	return useCallback(async () => {
		await mutateAsync({ thumbnail: '' });
	}, [mutateAsync]);
};

import { useCallback, useMemo } from 'react';
import { ItemViewModel, MillerItemType } from '../models';
import { formatDetailedItem, formatItems, formatJobs, formatSites } from './utils';
import { useColumnsURLState } from './useColumnsURLState';
import { useGetItemQuery } from './useGetItemQuery';
import { useSitesAndJobsListWithSiteTypeWrapper } from 'features/sites/utils/useSitesAndJobsListWithSiteTypeWrapper';

export type ItemType = 'item' | 'site';

export type DetailedItemBase = {
	currentItem: ItemViewModel | undefined;
	currentSite?: MillerItemType;
	itemType: ItemType;
	loading?: boolean;
};

export type MillerData = {
	millerColumns: MillerItemType[][];
	loadItems: (item: MillerItemType) => void;
	selectedSite?: MillerItemType;
	activeIds: (string | undefined)[];
	resetFilters: () => void;
} & DetailedItemBase;

export const useGetMillerData = (): MillerData => {
	const { itemId, siteName, language, setSiteName, setItemId, resetFilters } = useColumnsURLState();
	const { jobs, sites, loading } = useSitesAndJobsListWithSiteTypeWrapper({ sortSitesBy: 'created_date' });

	// get selected site by name
	const selectedSite = useMemo(() => {
		if (loading) {
			return;
		}

		const millerSites = formatSites(sites);

		return millerSites.find((s) => s.name === siteName);
	}, [loading, siteName, sites]);

	// get selected item hook
	const { item, itemLoading } = useGetItemQuery(
		itemId || selectedSite?.id,
		selectedSite?.id,
		language,
		(!itemId && !selectedSite) || language === undefined
	);

	// find all ancestors to site root item
	const activeAncestors = useMemo(() => {
		const ancestors = item?.ancestors;

		if (!ancestors) {
			return [];
		}

		const siteAncestor = ancestors.find((an) => an.itemId === selectedSite?.id);

		if (!siteAncestor) {
			return [];
		}

		return ancestors.slice(ancestors.indexOf(siteAncestor), ancestors.length);
	}, [item, selectedSite]);

	// get activeIds
	const activeIds = useMemo(() => {
		let activeIdsArray: string[] = [];

		if (activeAncestors.length) {
			const activeAncestorsArray = activeAncestors.map((an) => an.itemId);

			activeIdsArray = activeIdsArray.concat(activeAncestorsArray);
		} else if (selectedSite) {
			activeIdsArray.push(selectedSite.id);
		}

		if (itemId) {
			activeIdsArray.push(itemId);
		}

		return activeIdsArray;
	}, [activeAncestors, itemId, selectedSite]);

	// build miller columns
	const millerColumns = useMemo(() => {
		const jobSites = formatJobs(jobs);
		const newSites = formatSites(sites);

		const newMillerColumns = [jobSites.concat(newSites)].concat(activeAncestors.map((an) => formatItems(an)));

		if (item) {
			const children = formatItems(item);

			if (children.length) {
				newMillerColumns.push(children);
			}
		}

		return newMillerColumns;
	}, [activeAncestors, item, jobs, sites]);

	// get deteild item to show data in detailes panel
	const { currentItem, itemType } = useMemo(() => {
		const type: ItemType = itemId ? 'item' : 'site';
		const itemModel = item && formatDetailedItem(item);

		if (itemModel && type == 'site') {
			itemModel.thumbnailUrl = selectedSite?.thumbnailUrl;
		}

		return {
			currentItem: itemModel,
			itemType: type,
		};
	}, [item, itemId, selectedSite?.thumbnailUrl]);

	const loadItems = useCallback(
		(selectedItem: MillerItemType) => {
			if (selectedItem.type == 'site') {
				setSiteName(selectedItem.name);
			} else {
				setItemId(selectedItem.id);
			}
		},
		[setItemId, setSiteName]
	);

	return { millerColumns, loadItems, selectedSite, activeIds, currentItem, itemType, loading: loading || itemLoading, resetFilters };
};

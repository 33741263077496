import { useToast } from '@chakra-ui/react';
import { FormikWithCallbacks } from 'features/common/forms/FormikWithCallbacks';
import { useTranslate } from 'features/common/utils/translateUtils';
import { DICTIONARY } from 'shared/constants';
import { RenameForm } from 'features/sites/components/rename/RenameSiteModal';
import { renameValidationSchema } from 'features/sites/utils/siteValidationSchema';
import { useGetSitesWithPos } from 'features/sites/utils/useGetSitesWithPos';
import { useRenameSite } from 'features/sites/utils/useRenameSite';
import { FormikHelpers, FormikProps } from 'formik';
import { ChangeEvent, useCallback } from 'react';

export type RenameFormProps = {
	siteName?: string;
	initialNewName?: string;
	onRename?: (newName: string) => void;
	onReset?: (values: RenameForm, formikHelpers: FormikHelpers<RenameForm>) => void;
	onHandleChange?: (e: ChangeEvent) => void;
	children?: ((props: FormikProps<RenameForm>) => React.ReactNode) | React.ReactNode;
};

export const RenameFormHeader = ({ children, siteName = '', initialNewName = '', onRename, onHandleChange, onReset }: RenameFormProps) => {
	const { renameSite } = useRenameSite();
	const toast = useToast();
	const t = useTranslate();
	const { sites } = useGetSitesWithPos();

	const validateSiteUniqueness = (values: RenameForm) => {
		if (sites.findIndex((x) => x.name.toLowerCase() == values.newName.toLowerCase()) != -1) {
			return { newName: t(DICTIONARY.WEBSITE_NAME_SHOULD_BE_UNIQUE) };
		}
	};

	const onSubmit = useCallback(
		async (formValues: RenameForm) => {
			const { success } = await renameSite(siteName ?? '', formValues.newName);

			if (!success) {
				toast({
					status: 'error',
					title: t(`${DICTIONARY.RENAME_SITE_ERROR[0]} "${siteName ?? ''}" ${DICTIONARY.RENAME_SITE_ERROR[1]}`),
				});

				return;
			}

			if (onRename) {
				onRename(formValues.newName);
			}
		},
		[renameSite, siteName, t, onRename, toast]
	);

	return (
		<FormikWithCallbacks<RenameForm>
			initialValues={{
				newName: initialNewName,
			}}
			enableReinitialize={true}
			onSubmit={onSubmit}
			validateOnChange={false}
			validateOnBlur={true}
			validateOnMount={false}
			validationSchema={renameValidationSchema}
			validate={validateSiteUniqueness}
			onHandleChange={onHandleChange}
			onReset={onReset}
		>
			{children}
		</FormikWithCallbacks>
	);
};

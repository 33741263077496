import { format } from 'date-fns';
import { DATE_FORMAT, EMPTY_VALUE, HOSTNAME_DELIMITER } from 'shared/constants';
import { Host } from 'shared/models/host';
import { SiteDefinitionPermissions } from 'shared/models/permissions';
import { RenderingHost } from 'shared/models/rendering-host';
import { SiteResponse } from './site-response';

export type SiteDefinitionResponseViewModel = {
	id: string;
	name: string;
	created: string;
	createdBy: string;
	hostname: string;
	homePageId: string;
	targetHostname: string;
	renderingHost: RenderingHost;
	permissions: SiteDefinitionPermissions;
	thumbnailUrl: string;
	thumbnailBase64?: string;
	thumbnailRootPath: string;
	thumbnailAutogenerated: boolean;
	settings: Record<string, string>;
	properties?: Record<string, string>; //TODO: SITES-1504 - make it required when SITES API will remove SettingsSplit feature flag
	defaultLanguage: string;
	languageEmbedding: boolean;
	itemLanguageFallback: boolean;
	fieldLanguageFallback: boolean;
	linkableSite?: boolean;
	itemPath?: string; // to be deprecated
	// linkProviderName?: string;
	analyticsIdentifiers?: Record<string, string>;
	collectionId: string;
	siteId: string;
};

export const mapDefinitionResponseToViewModel = (response?: Host, site?: SiteResponse): SiteDefinitionResponseViewModel | undefined => {
	if (!response || !site) return undefined;

	return {
		id: response.id,
		created: response.created ? format(new Date(response.created), DATE_FORMAT) : EMPTY_VALUE,
		createdBy: response.createdBy,
		name: response.name,
		hostname: response.hostnames.join(HOSTNAME_DELIMITER),
		homePageId: response.homePageId,
		targetHostname: response.targetHostname,
		renderingHost: response.renderingHost,
		permissions: response.permissions,
		thumbnailUrl: response.thumbnail.url,
		thumbnailBase64: response.thumbnail.base64,
		thumbnailRootPath: response.thumbnail.rootPath,
		thumbnailAutogenerated: response.thumbnail.autogenerated,
		settings: response.settings,
		properties: response.properties,
		defaultLanguage: response.languageSettings.defaultLanguage,
		languageEmbedding: response.languageSettings.languageEmbedding,
		itemLanguageFallback: response.languageSettings.itemLanguageFallback,
		fieldLanguageFallback: response.languageSettings.fieldLanguageFallback,
		linkableSite: response.settings.sxaLinkable === 'true',
		itemPath: response.settings.siteDefinitionPath, //to be deprecated
		// linkProviderName: response.settings.linkProvider,
		analyticsIdentifiers: response.analyticsIdentifiers,
		siteId: site.id,
		collectionId: site.collectionId,
	};
};

import { QueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { CalculateReactQueryKey, RefetchQueryKey } from 'shared/react-query-wrappers/models';

export const useRefetchQueriesByKey = (
	refetchQueriesByKey: RefetchQueryKey[] | undefined,
	queryClient: QueryClient,
	calculateKey: CalculateReactQueryKey
) => {
	return useCallback(async () => {
		if (!refetchQueriesByKey) {
			return;
		}

		const waitRequests = refetchQueriesByKey.filter((refetchKey) => refetchKey.shouldWait) || [];
		const nonWaitRequests = refetchQueriesByKey.filter((refetchKey) => !refetchKey.shouldWait) || [];

		for (const refetchKey of waitRequests) {
			const calculatedKey = calculateKey(refetchKey.url, refetchKey.params);

			await queryClient.invalidateQueries({ queryKey: calculatedKey.queryKey, exact: true });
		}

		for (const refetchKey of nonWaitRequests) {
			const calculatedKey = calculateKey(refetchKey.url, refetchKey.params);

			void queryClient.invalidateQueries({ queryKey: calculatedKey.queryKey, exact: true });
		}
	}, [calculateKey, queryClient, refetchQueriesByKey]);
};

import { ENV } from 'shared/constants';

export const environmentUpdater = (env: string) => {
	switch (env.toLowerCase()) {
		case ENV.STAGING:
		case ENV.DEV:
			return 'staging';

		case ENV.PRODUCTION:
			return 'production';

		default:
			return env;
	}
};

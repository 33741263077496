import { Alert, AlertDescription, AlertIcon, Button, ButtonGroup, ModalBody, ModalFooter, Stack } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FormFieldControl } from 'entities/form-field-control/ui';
import { InfoPopover } from 'entities/info-popover/ui';
import { useDuplicatePage } from 'features/page-actions-menu/hooks/use-duplicate-page';
import { DuplicatePageModalBodyProps } from 'features/page-actions-menu/models';
import { Field, Form, Formik } from 'formik';
import { FC } from 'react';
import { DICTIONARY, VALIDATIONS } from 'shared/constants';
import type { FormikFieldProps } from 'shared/models';
import { NonPredictiveInput } from 'shared/utils';

export const DuplicatePageModalBody: FC<DuplicatePageModalBodyProps> = ({ onClose, id, name, siblingNames, siteId, hasChildren }) => {
	const { sitePageItemValidationSchema, validatePageNameUniqueness, submitHandler, initialName } = useDuplicatePage(
		id,
		siblingNames,
		name,
		siteId,
		onClose
	);

	return (
		<Formik
			initialValues={{ name: initialName, displayName: initialName }}
			validationSchema={sitePageItemValidationSchema}
			validate={(values) => validatePageNameUniqueness(values.name)}
			onSubmit={submitHandler}
			validateOnBlur={false}
			validateOnChange={false}
		>
			{({ isSubmitting, setFieldValue }) => (
				<Form noValidate>
					<ModalBody>
						<Stack gap="6">
							{hasChildren && (
								<Alert>
									<AlertIcon />
									<AlertDescription>
										<T _str={DICTIONARY.DUPLICATE_SITE_ALERT_DESCRIPTION} />
									</AlertDescription>
								</Alert>
							)}
							<Field name="name" validate>
								{({ field, form }: FormikFieldProps<string>) => (
									<FormFieldControl
										form={form}
										field={field}
										name="name"
										isRequired
										label={DICTIONARY.ITEM_NAME}
										helper={DICTIONARY.USE_TO_BUILD_URL}
										maxLength={VALIDATIONS.PAGE_NAME_LENGTH}
									>
										<NonPredictiveInput
											{...field}
											autoFocus
											data-testid="duplicate-page-name"
											maxLength={VALIDATIONS.PAGE_NAME_LENGTH}
											onChange={(e) => {
												if (!form.touched.displayName) {
													setFieldValue('displayName', e.target.value);
												}
											}}
										/>
									</FormFieldControl>
								)}
							</Field>
							<Field name="displayName" validate>
								{({ field, form }: FormikFieldProps<string>) => (
									<FormFieldControl
										form={form}
										field={field}
										name="displayName"
										label={DICTIONARY.DISPLAY_NAME_ENGLISH}
										helper={DICTIONARY.PAGE_NAME_WITHIN_XMCLOUD}
										maxLength={VALIDATIONS.PAGE_NAME_LENGTH}
										labelHint={<InfoPopover body={DICTIONARY.DISPLAY_NAME_ENGLISH_HINT} placement="bottom" />}
									>
										<NonPredictiveInput
											{...field}
											data-testid="duplicate-page-display-name"
											maxLength={VALIDATIONS.PAGE_NAME_LENGTH}
										/>
									</FormFieldControl>
								)}
							</Field>
						</Stack>
					</ModalBody>
					<ModalFooter>
						<ButtonGroup>
							<Button variant="ghost" onClick={onClose} isDisabled={isSubmitting}>
								<T _str={DICTIONARY.CANCEL} />
							</Button>
							<Button isLoading={isSubmitting} type="submit">
								<T _str={DICTIONARY.DUPLICATE} />
							</Button>
						</ButtonGroup>
					</ModalFooter>
				</Form>
			)}
		</Formik>
	);
};

import { useTranslate } from 'features/common/utils/translateUtils';
import { DICTIONARY } from 'shared/constants';
import { useCallback } from 'react';
import { RenameForm } from '../components/rename/RenameSiteModal';
import { useGetSitesWithPos } from './useGetSitesWithPos';

export const useCheckSiteUniqueness = () => {
	const t = useTranslate();
	const { fetchSites } = useGetSitesWithPos();

	return useCallback(
		async (values: RenameForm) => {
			const sites = await fetchSites();

			if (sites.findIndex((x) => x.name.toLowerCase() == values.newName.toLowerCase()) != -1) {
				return { newName: t(DICTIONARY.WEBSITE_NAME_SHOULD_BE_UNIQUE) };
			}
		},
		[fetchSites, t]
	);
};

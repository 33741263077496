import { FC } from 'react';
import { ENDPOINTS } from 'shared/endpoints';
import { usePrefetchData } from 'shared/react-query-wrappers/hooks';

export const PrefetchData: FC = () => {
	const prefetch = usePrefetchData();

	prefetch([
		{ url: ENDPOINTS.COLLECTIONS },
		{ url: ENDPOINTS.LANGUAGES },
		{ url: ENDPOINTS.LANGUAGES_SUPPORTED },
		{ url: ENDPOINTS.SITES },
		{ url: ENDPOINTS.TEMPLATES },
		{ url: ENDPOINTS.LANGUAGE_PERMISSIONS },
		{ url: ENDPOINTS.SITE_PERMISSIONS },
	]);

	return <></>;
};

import { useCallback } from 'react';
import { ENDPOINTS } from 'shared/endpoints';
import { usePostForm } from 'shared/react-query-wrappers/hooks';
import { ThumbnailGeneralHookModel } from '../models';

export const useUploadGeneralThumbnail = ({ siteId }: ThumbnailGeneralHookModel) => {
	const { mutateAsync } = usePostForm({
		queryKey: {
			url: ENDPOINTS.SITE_UPLOAD_THUMBNAIL,
			params: { siteId: siteId ?? '' },
		},
	});

	return useCallback(
		async (formData?: FormData) => {
			await mutateAsync(formData);
		},
		[mutateAsync]
	);
};

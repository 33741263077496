import { Alert, Button, Stack, Text } from '@chakra-ui/react';
import { mdiAlert } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { SiteWithMissingPos } from 'features/point-of-sale/models';
import { FC, useCallback } from 'react';
import { useCreatePoSAction } from 'shared/api';
import { DICTIONARY, POS_DEFAULT_BRAND, POS_DEFAULT_MARKET, POS_DEFAULT_TIMEOUT } from 'shared/constants';
import { PoSCreateInput } from 'shared/models';

type SiteMissingPosNotificationProps = {
	site: SiteWithMissingPos | undefined;
};

/** @deprecated */
export const SiteMissingPosNotification: FC<SiteMissingPosNotificationProps> = ({ site }) => {
	const { createPosIdentifiers, isPending } = useCreatePoSAction();

	const handlePosCreate = useCallback(async () => {
		const inputs = site?.missingPos.map<PoSCreateInput>((name) => ({
			name,
			market: POS_DEFAULT_MARKET,
			brand: POS_DEFAULT_BRAND,
			timeout: POS_DEFAULT_TIMEOUT,
		}));

		if (inputs) {
			await createPosIdentifiers(inputs);
		}
	}, [site, createPosIdentifiers]);

	if (!site || site?.missingPos?.length === 0) {
		return <></>;
	}

	return (
		<Alert status="warning" overflow="unset">
			<Icon path={mdiAlert} color="warning" />
			<Stack gap="2">
				<Stack gap="0">
					<Text>
						<T _str={DICTIONARY.SITES_MISSING_POS_NOTIFICATION[0]} />
					</Text>
					<Text>
						<T _str={DICTIONARY.SITES_MISSING_POS_NOTIFICATION[1]} />
					</Text>
				</Stack>
				<Button
					data-testid="site-create-missing-pos-identifiers"
					variant="outline"
					size="sm"
					width="fit-content"
					isLoading={isPending}
					isDisabled={isPending}
					loadingText={<T _str={DICTIONARY.POS_CREATING} />}
					onClick={() => void handlePosCreate()}
				>
					<T _str={DICTIONARY.CREATE_MISSING_POS_IDENTIFIERS} />
				</Button>
			</Stack>
		</Alert>
	);
};

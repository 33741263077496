import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import { JobPayload } from '../data/createSiteTypes';
import { getJobQuery } from '../queries';

function timeout(ms: number) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

export type JobPollOptions = { interval: number; iddleOnStart: number; maxPollCount: number };
export type JobResult = {
	success: boolean;
	errors: string[];
	name?: string;
	handle: string;
};
export type WaitJobResult = {
	waitJobResult: (name: string, handle: string) => Promise<JobResult>;
};

export const useWaitJobResult: (pollOptions: JobPollOptions) => WaitJobResult = ({ interval, iddleOnStart, maxPollCount }) => {
	const [getJob] = useLazyQuery<JobPayload, { handle: string }>(getJobQuery);
	const waitJobResult = useCallback(
		async (name: string, handle: string) => {
			await timeout(iddleOnStart);

			for (let i = 0; i < maxPollCount; i++) {
				const { data } = await getJob({ variables: { handle } });
				const job = data?.job;

				// if not job found it either doesn't exist or finished and cleard from sitecore, thus no need to wait
				if (!job) {
					return {
						name,
						handle,
						success: true,
						errors: [],
					};
				}

				if (job.done) {
					const errors = job.status?.exceptions ?? [];

					return {
						handle,
						success: errors.length == 0,
						errors,
						name,
					};
				}

				await timeout(interval);
			}

			return {
				name,
				handle,
				success: false,
				errors: ['Job timeout'],
			};
		},
		[getJob, interval, iddleOnStart, maxPollCount]
	);

	return {
		waitJobResult,
	};
};

import { Formik } from 'formik';
import { FC, useMemo } from 'react';
import { SiteSharingSectionFormProps } from 'widgets/site-sharing/models';
import { SiteSharingSectionFormBody } from 'widgets/site-sharing/ui/site-sharing-section-form-body';

export const SiteSharingSectionForm: FC<SiteSharingSectionFormProps> = ({ isShared }) => {
	const initialValues = useMemo(() => ({ shared: isShared }), [isShared]);

	return (
		<Formik initialValues={initialValues} onSubmit={() => {}} enableReinitialize>
			{({ resetForm }) => <SiteSharingSectionFormBody resetForm={resetForm} />}
		</Formik>
	);
};

import { useMemo } from 'react';
import { Host, SiteResponse } from 'shared/models';
import { HomePage } from 'widgets/site-structure/models/props';

export const useHomePage = (site: SiteResponse | undefined, hosts: Host[] | undefined) => {
	const homePageIdsAndPath: HomePage[] = useMemo(() => {
		if (!site || !hosts) return [];

		const hostHomes =
			hosts?.map((host) => ({
				id: host.id,
				hostName: host.name,
				homePageId: host.homePageId.replace(/-/g, ''),
			})) || [];
		const siteHomes = site?.hosts.map((host) => ({
			id: host.id,
			homePagePath: (host.properties?.rootPath || host.settings.rootPath) + (host.settings.startItem || ''),
		}));

		return hostHomes.map((home) => ({
			id: home.homePageId,
			path: siteHomes.find((siteHome) => siteHome.id === home.id)?.homePagePath || '',
			hostName: home.hostName,
		}));
	}, [hosts, site]);

	return homePageIdsAndPath;
};

import { useCallback, useDeferredValue, useMemo } from 'react';
import { useCheckJobStatus } from 'shared/check-job-status';
import { ENDPOINTS } from 'shared/endpoints';
import { useGet } from 'shared/react-query-wrappers/hooks';
import { SiteResponse, UsePortfolio, mapSiteResponseToViewModel } from 'widgets/portfolio/models';

export const usePortfolio: UsePortfolio = (collectionId: string) => {
	const { getAllJobs } = useCheckJobStatus();
	const { data, isLoading } = useGet<SiteResponse[]>({
		queryKey: {
			url: ENDPOINTS.COLLECTION_SITES,
			params: { collectionId: collectionId },
		},
		enabled: !!collectionId,
	});

	const sites = useMemo(() => {
		return data?.map((site) => ({ ...mapSiteResponseToViewModel(site) }));
	}, [data]);

	const isRecentlyCreated = useCallback(
		(collectionName: string) => !!getAllJobs().collectionCreations?.some((c) => c.done && c.siteCollection === collectionName),
		[getAllJobs]
	);

	return {
		sites: useDeferredValue(sites),
		isLoading,
		isRecentlyCreated,
	};
};

export const srcFormatter = (src: string | undefined) => {
	if (!src) {
		return;
	}

	//if it is deprecated url keep it as it is
	if (src?.includes('http')) {
		return src;
	}

	return `data:image/png;base64,${src}`;
};

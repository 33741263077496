import { Box, Flex, Spacer, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { useGetThumbnail } from 'features/common/tenants/useGetThumbnail';
import { SiteCollectionName } from 'features/sites/components/SiteCollectionName';
import { SiteTypeBadge } from 'features/sites/components/SiteTypeBadge';
import { Card, CardBottomPanel, CardImage } from 'features/temporary/Card';
import { FC, Fragment, useMemo } from 'react';
import { DICTIONARY } from 'shared/constants';
import { SiteInfo } from '../data/createSiteTypes';
import { ContextualMenu } from './ContextualMenu';

type SiteCardProps = SiteInfo & {
	openSiteEditor: (siteName: string) => void;
};

export const SiteCard: FC<SiteCardProps> = ({ id, name, imageSrc, languages, openSiteEditor, siteCollectionName, siteTypeByCollection }) => {
	const cardLanguages = useMemo(() => {
		return languages.map((language) => language.toLocaleUpperCase()).join(', ');
	}, [languages]);
	const thumbnail = useGetThumbnail(imageSrc);

	return (
		<Box position="relative">
			{siteTypeByCollection === 'nonsxa' && <SiteTypeBadge siteType={siteTypeByCollection} />}
			<Card
				key={id}
				size="md"
				style={{
					background: 'white',
					minWidth: '18.75rem',
					width: '18.75rem',
				}}
				onClick={() => openSiteEditor(name)}
			>
				<Fragment key=".0">
					<CardImage src={thumbnail} objectFit="cover" cursor="pointer" data-testid="cardImage" />
					<CardBottomPanel showSeparator={true} onClick={(e) => e.stopPropagation()}>
						<Flex direction="row">
							<Flex direction="column" paddingBottom="2.5">
								<Text maxWidth="48" noOfLines={1} title={name} variant="strong" data-testid="siteName">
									{name}
								</Text>
								<Text maxWidth="48">{cardLanguages}</Text>
								<Text maxWidth="48" noOfLines={1} title={siteCollectionName} variant="subtle">
									<Text as="strong">
										<T _str={DICTIONARY.COLLECTION_WITH_COLON} />
									</Text>
									&nbsp;
									<SiteCollectionName name={siteCollectionName} />
								</Text>
							</Flex>
							<Spacer />
							<ContextualMenu openSiteEditor={openSiteEditor} siteName={name} siteType={siteTypeByCollection} />
						</Flex>
					</CardBottomPanel>
				</Fragment>
			</Card>
		</Box>
	);
};

import { SiteInfo } from 'features/sites/data/createSiteTypes';
import { TypeFilterUrlParams } from 'features/sites/utils/useTypeFilter';

export const filterSiteByType = (sites: SiteInfo[], siteType: TypeFilterUrlParams) => {
	if (siteType !== 'none') {
		return sites.filter((site) => siteType === site.siteTypeByCollection);
	}

	return sites;
};

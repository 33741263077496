import { t } from '@transifex/native';
import { CreatableSelect } from 'chakra-react-select';
import { FormFieldProps } from 'entities/form-field-control/models';
import { FormFieldControl } from 'entities/form-field-control/ui';
import { useCollectionField, useGetCollections } from 'features/create-site/hooks';
import { CollectionFieldProps } from 'features/create-site/models';
import { Field } from 'formik';
import { DICTIONARY } from 'shared/constants';

export const CollectionField: React.FC<CollectionFieldProps> = (props) => {
	const { collection, useBasicStyles = false } = props;
	const name = 'collection';

	const collections = useGetCollections();
	const { onBlur, onChange, onInputChange } = useCollectionField({ ...props, name });

	return (
		<Field name={name}>
			{({ field, form }: FormFieldProps) => (
				<FormFieldControl form={form} field={field} name={name} label={DICTIONARY.COLLECTION} helper={DICTIONARY.COLLECTION_HINT} isRequired>
					<CreatableSelect
						placeholder={t(DICTIONARY.SELECT_OR_CREATE_COLLECTION)}
						noOptionsMessage={() => t(DICTIONARY.SELECT_COLLECTION_NO_OPTIONS)}
						selectedOptionStyle="check"
						isInvalid={!!form.errors?.collection && !!form.touched?.collection}
						options={collections}
						isClearable
						name={name}
						onBlur={onBlur}
						value={collection}
						inputId="collection-field"
						onChange={onChange}
						onInputChange={onInputChange}
						useBasicStyles={useBasicStyles}
					/>
				</FormFieldControl>
			)}
		</Field>
	);
};

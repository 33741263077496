import { Flex, Skeleton, Stack } from '@chakra-ui/react';
import { useCheckPermissions } from 'features/site-permissions/hooks';
import { useSitesPageData } from 'pages/sites/hooks';
import { useRef } from 'react';
import { Outlet } from 'react-router';
import { ScrollToTopButton } from 'shared/scroll-top-button/ui';
import { MissingPortfolio } from 'widgets/missing-portfolio/ui';
import { useJssPortfolio } from 'widgets/portfolio/hooks';
import { JssPortfolio, Portfolio } from 'widgets/portfolio/ui';
import { CollectionsCreationInProgress } from 'widgets/portfolio/ui/collections-creation-in-progress';
import { SiteAndCollectionCreationInProgress } from 'widgets/portfolio/ui/site-and-collection-creation-in-progress';
import { SitesHeader } from 'widgets/sites-header/ui';

export const SitesPage = () => {
	const { canCreate, isLoading: isCheckPermissionLoading } = useCheckPermissions();
	const { getFilteredCollections, isPortfolioLoading, hasRunningCollections } = useSitesPageData();
	const { sites, isLoading } = useJssPortfolio();

	const ref = useRef<HTMLDivElement>(null);

	return (
		<Flex direction="column" height="full" overflow="auto" ref={ref}>
			<SitesHeader />
			<Stack gap="6" px="8" pb="10" ref={ref}>
				<CollectionsCreationInProgress />
				<SiteAndCollectionCreationInProgress />
				{!isPortfolioLoading && getFilteredCollections?.map((portfolio) => <Portfolio key={portfolio.id} {...portfolio} />)}
				{isPortfolioLoading && !hasRunningCollections && <Skeleton height="224" width="full" borderRadius="6" />}
				{!hasRunningCollections && !isPortfolioLoading && !getFilteredCollections?.length && !isLoading && !sites?.length && (
					<MissingPortfolio canCreate={canCreate} isLoading={isCheckPermissionLoading} />
				)}
				{!isLoading && <JssPortfolio sites={sites} isLoading={false} />}
				<ScrollToTopButton boxRef={ref} />
			</Stack>
			<Outlet />
		</Flex>
	);
};

import { Card, Flex, FlexProps, Table, TableContainer } from '@chakra-ui/react';
import { NoSearchResult } from 'entities/no-search-result/ui';
import { TableLoading } from 'entities/table/ui';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { Access } from 'shared/models';
import { useSimpleGet } from 'shared/react-query-wrappers/hooks';
import { SearchInput } from 'shared/search-input/ui';
import { useLanguagesTable } from 'widgets/languages-table/hooks/use-languages-table';
import { LanguagesTableBody } from 'widgets/languages-table/ui/languages-table-body';
import { LanguagesTableHead } from 'widgets/languages-table/ui/languages-table-head';

export const LanguagesTable: FC<FlexProps> = (props) => {
	const { systemLanguages, isLoading, languagesData, ref, search, searchFor } = useLanguagesTable();
	const { data: access } = useSimpleGet<Access>(ENDPOINTS.LANGUAGE_PERMISSIONS);

	return (
		<Flex direction="column" gap="6" {...props}>
			<SearchInput
				containerStyle={{ maxWidth: 96 }}
				defaultValue={search}
				placeholder={DICTIONARY.SEARCH}
				onClear={searchFor}
				onSearch={searchFor}
				inputRef={ref}
			/>
			{!languagesData.length && !isLoading ? (
				<NoSearchResult onReset={() => searchFor()} />
			) : (
				<Flex direction="column" gap="4" alignItems="end">
					<TableContainer as={Card} variant="flat" size="lg" whiteSpace="normal" boxSize="full" overflowY="unset" overflowX="unset">
						<Table>
							<LanguagesTableHead />
							{!isLoading ? (
								<LanguagesTableBody languagesData={languagesData} systemLanguages={systemLanguages} access={access} />
							) : (
								<TableLoading columns={3} rows={4} />
							)}
						</Table>
					</TableContainer>
				</Flex>
			)}
		</Flex>
	);
};

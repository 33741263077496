import { useFeatures } from 'features/common/featureFlags/featureFlags';
import { useSiteDetailsForm } from 'features/create-site/hooks';
import { SiteDetailsFormProps } from 'features/create-site/models';
import { SiteDetailsFormBody } from 'features/create-site/ui/site-details-form-body';
import { Formik } from 'formik';
import { FC } from 'react';
import { siteDetailsValidationSchema } from 'shared/validations';

export const SiteDetailsForm: FC<SiteDetailsFormProps> = ({
	collectionId,
	onFormChange,
	onSiteDetailsFormDataChange,
	siteDetailsFormData,
	...props
}) => {
	const { initialErrors, initialValues, siteNames, collectionSystemNames } = useSiteDetailsForm(collectionId, siteDetailsFormData);
	const { showManagedLanguages } = useFeatures();

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={siteDetailsValidationSchema(
				siteNames,
				collectionSystemNames,
				siteDetailsFormData.collection,
				collectionId,
				showManagedLanguages
			)}
			initialErrors={initialErrors}
			onSubmit={() => {}}
			validateOnChange
			validateOnBlur
		>
			{({ handleChange, setFieldTouched }) => (
				<SiteDetailsFormBody
					collectionId={collectionId}
					onFormChange={onFormChange}
					onSiteDetailsFormDataChange={onSiteDetailsFormDataChange}
					siteDetailsFormData={siteDetailsFormData}
					handleChange={handleChange}
					setFieldTouched={setFieldTouched}
					{...props}
				/>
			)}
		</Formik>
	);
};

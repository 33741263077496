/* eslint-disable */

import * as React from 'react';

/**
 * Chakra UI components
 */
import { Box as ChakraBox, BoxProps as ChakraBoxProps, Wrap as ChakraWrap, WrapItem as ChakraWrapItem, forwardRef } from '@chakra-ui/react';

/**
 * Interfaces
 */
import type { LayoutProps, WrapItemProps, WrapProps } from '../models';

import { LAYOUT_MAIN_REQUIRED_ERR } from '../config';
import { convertChildrenArrayToObj, flattenChildren } from '../lib';

/**
 * Sitecore UI Layout components
 */

export const Wrap: React.FC<WrapProps> = forwardRef((props, ref) => <ChakraWrap ref={ref} {...props} />);
export const WrapItem: React.FC<WrapItemProps> = forwardRef((props, ref) => <ChakraWrapItem ref={ref} {...props} />);

/**
 * Layout Components
 * */
export const Layout: React.FC<React.PropsWithChildren<LayoutProps>> = forwardRef(({ children, ...props }, ref) => {
	const childrenKeyValue = convertChildrenArrayToObj(flattenChildren(children));

	/**
	 * Require LayoutMain on all Layouts
	 */
	if (!childrenKeyValue['LayoutMain']) {
		throw new Error(LAYOUT_MAIN_REQUIRED_ERR);
	}

	/**
	 * Layout.height & Layout.width can be used to optionally change the overall size of the layout.
	 * It defaults to 100 viewport (100vh, 100vw) to fill the entire screen.
	 */

	return (
		<ChakraBox ref={ref} display="flex" flexDirection="column" w={props.width} h={props.height} overflow="hidden" {...props}>
			{childrenKeyValue['LayoutHeader']}
			<ChakraBox display="flex" flexDirection="row" flexGrow={1} flexShrink={1} overflow="hidden">
				{childrenKeyValue['LayoutMain']}
				{childrenKeyValue['LayoutLeft']}
				{childrenKeyValue['LayoutRight']}
			</ChakraBox>
			{childrenKeyValue['LayoutFooter']}
		</ChakraBox>
	);
});

export const LayoutHeader: React.FC<ChakraBoxProps> = ({ children, ...rest }) => {
	return (
		<ChakraBox as="header" backgroundColor="white" shadow="base" overflow="hidden" flexShrink={0} height={14} p={3} zIndex={3} {...rest}>
			{children}
		</ChakraBox>
	);
};

/**
 * Sitecore UI Layout component default props
 */

Wrap.defaultProps = {};
WrapItem.defaultProps = {};

Layout.defaultProps = {
	height: '100vh',
	width: '100vw',
};

/**
 * Sitecore UI Layout components display names
 */

Wrap.displayName = 'Wrap';
WrapItem.displayName = 'WrapItem';

Layout.displayName = 'Layout';
LayoutHeader.displayName = 'LayoutHeader';

export { LayoutFooter } from './layout-footer';
export { LayoutLeft } from './layout-left';
export { LayoutMain } from './layout-main';
export { LayoutRight } from './layout-right';

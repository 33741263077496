import { createFeatureFlags } from '@sitecore/featureflags';

// eslint-disable-next-line @typescript-eslint/unbound-method
export const { Feature, FlagsProvider, useFeature, useFeatures, withFeature, behindFeature } = createFeatureFlags({
	testFeature: false,
	showFormsRedirect: false,
	showAnalyticsRedirect: false,
	showNewSitesPage: false,
	showSitesGrouping: false,
	showManagedLanguages: false,
	showSharedSite: false,
	showTreeDragNDrop: false,
});

export type FeatureFlags = ReturnType<typeof useFeatures>;
export type FeatureName = keyof FeatureFlags;

import { FormikProps } from 'formik';
import { useCallback, useRef } from 'react';
import { SITE_SETTINGS_FIELDS_EVENT, SITE_SETTINGS_FORM_EVENT_NAME } from 'shared/constants';
import { useEventListener } from 'usehooks-ts';
import {
	GeneralSectionEvent,
	SiteSettingsFieldsEvent,
	SiteSettingsViewModel,
	mapToBoolean,
	onSiteTouched,
} from 'widgets/site-settings-update-form/models';

/**
 * @deprecated Update is blocked for EA
 */
export const useGeneralSectionBody = (
	props: Pick<FormikProps<SiteSettingsViewModel>, 'handleChange' | 'submitForm' | 'resetForm' | 'initialValues' | 'values'>
) => {
	const { handleChange, submitForm, resetForm, initialValues, values } = props;
	const documentRef = useRef<Document>(document);

	const onButtonsClick = useCallback(
		(event: CustomEvent<GeneralSectionEvent>) => {
			const { type } = event.detail;

			if (type === 'submit') {
				void submitForm();
			} else if (type === 'reset') {
				resetForm();
			}
		},
		[resetForm, submitForm]
	);

	const onChangeProxy: typeof handleChange = useCallback(
		(event: React.ChangeEvent<SiteSettingsFieldsEvent>) => {
			handleChange(event);

			const touchedFieldName = event.target.name;
			const initialValue = initialValues[touchedFieldName];
			const newValue = event.target.value;
			const isDirty = initialValue !== newValue && initialValue !== mapToBoolean(newValue);

			if (isDirty) {
				onSiteTouched(isDirty);

				return;
			}

			const keys = Object.keys(initialValues) as Array<keyof SiteSettingsViewModel>;

			for (const key of keys) {
				if (key === touchedFieldName) continue;

				const initialFieldValue = initialValues[key];
				const fieldValue = values[key] || '';
				const isFieldDirty = initialFieldValue !== fieldValue && initialFieldValue !== mapToBoolean(fieldValue);

				if (isFieldDirty) {
					onSiteTouched(true);

					return;
				}
			}

			onSiteTouched(false);
		},
		[handleChange, initialValues, values]
	);

	const onLanguageSectionFieldsEvent = useCallback(
		(event: CustomEvent<SiteSettingsFieldsEvent>) => {
			onChangeProxy({
				target: {
					name: event.detail.name,
					value: event.detail.value,
				},
			} as React.ChangeEvent<any>);
		},
		[onChangeProxy]
	);

	useEventListener(SITE_SETTINGS_FORM_EVENT_NAME, onButtonsClick, documentRef);

	useEventListener(SITE_SETTINGS_FIELDS_EVENT, onLanguageSectionFieldsEvent, documentRef);

	return {
		onChangeProxy,
	};
};

import { Textarea } from '@chakra-ui/react';
import { FormFieldProps } from 'entities/form-field-control/models';
import { FormFieldControl } from 'entities/form-field-control/ui';
import { TreeMenu } from 'entities/homepage-menu/ui';
import { ThumbnailEA } from 'features/thumbnail/ui/thumbnail-ea';
import { Field, FormikProps } from 'formik';
import { SitePageOutlet } from 'pages/site/models';
import { FC } from 'react';
import { useOutletContext } from 'react-router';
import { DEFAULT_SITE_DESCRIPTION_LENGTH, DEFAULT_SITE_NAME_LENGTH, DICTIONARY } from 'shared/constants';
import { SiteResponse } from 'shared/models';
import { NonPredictiveInput } from 'shared/utils';
import { useGeneralSectionBody } from 'widgets/site-settings-update-form/hooks/use-general-section-body';
import { SiteSettingsViewModel } from 'widgets/site-settings-update-form/models';
import { ControlFieldSystemName } from './control-field-system-name';

/**
 * @deprecated Update is blocked for EA
 */
export const GeneralSectionBody: FC<FormikProps<SiteSettingsViewModel> & Pick<SiteResponse, 'id' | 'name'>> = ({
	values,
	handleChange,
	submitForm,
	resetForm,
	initialValues,
	id,
	name,
}) => {
	const { onChangeProxy } = useGeneralSectionBody({ handleChange, submitForm, resetForm, initialValues, values });
	const { site } = useOutletContext<SitePageOutlet>();

	return (
		<>
			<Field name="displayName" validate>
				{({ field, form }: FormFieldProps) => (
					<FormFieldControl
						form={form}
						field={field}
						name={field.name}
						maxLength={DEFAULT_SITE_NAME_LENGTH}
						label={DICTIONARY.NAME}
						helper={DICTIONARY.SITE_NAME_XM_CLOUD_INFO_MESSAGE}
						minW="xs"
						maxW="xl"
						isRequired
					>
						<NonPredictiveInput {...field} value={values.displayName} onChange={onChangeProxy} maxLength={DEFAULT_SITE_NAME_LENGTH} />
					</FormFieldControl>
				)}
			</Field>
			{!!name && <ControlFieldSystemName value={name} siteId={id} collectionId={site?.collectionId ?? ''} />}
			<Field name="description">
				{({ field, form }: FormFieldProps) => (
					<FormFieldControl
						form={form}
						field={field}
						name={field.name}
						maxLength={DEFAULT_SITE_DESCRIPTION_LENGTH}
						label={DICTIONARY.DESCRIPTION}
						minW="xs"
						maxW="xl"
					>
						<Textarea {...field} value={values.description} onChange={onChangeProxy} maxLength={DEFAULT_SITE_DESCRIPTION_LENGTH} />
					</FormFieldControl>
				)}
			</Field>
			<Field name="homePageId">
				{({ field, form }: FormFieldProps) => (
					<FormFieldControl
						form={form}
						field={field}
						name={field.name}
						label={DICTIONARY.HOMEPAGE}
						minW="xs"
						maxW="xl"
						helper={DICTIONARY.HOMEPAGE_HELPER}
					>
						{!!name && <TreeMenu {...form} siteId={id} homePageId={values.homePageId} />}
					</FormFieldControl>
				)}
			</Field>
			{site && <ThumbnailEA site={site} />}
		</>
	);
};

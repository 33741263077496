import { Input } from '@chakra-ui/react';
import { FormFieldProps } from 'entities/form-field-control/models';
import { FormFieldControl } from 'entities/form-field-control/ui';
import { Thumbnail } from 'features/thumbnail/ui';
import { Field, FormikProps } from 'formik';
import { SitePageOutlet } from 'pages/site/models';
import { FC } from 'react';
import { useOutletContext } from 'react-router';
import { DEFAULT_SITE_NAME_LENGTH, DICTIONARY } from 'shared/constants';
import { SiteResponse } from 'shared/models';
import { useGeneralSectionBody } from 'widgets/site-definition-update-form/hooks/use-general-section-body';
import { SiteDefinitionViewModel } from 'widgets/site-definition-update-form/models';

export const GeneralSectionBody: FC<FormikProps<SiteDefinitionViewModel> & Pick<SiteResponse, 'id' | 'name'>> = ({
	values,
	handleChange,
	submitForm,
	validateForm,
	resetForm,
	initialValues,
}) => {
	const { onChangeProxy, onChangeThumbnailProxy } = useGeneralSectionBody({
		handleChange,
		submitForm,
		validateForm,
		resetForm,
		initialValues,
		values,
	});
	const { siteDefinition } = useOutletContext<SitePageOutlet>();

	return (
		<>
			<Field name="name" validate>
				{({ field, form }: FormFieldProps) => (
					<FormFieldControl
						form={form}
						field={field}
						name="name"
						maxLength={DEFAULT_SITE_NAME_LENGTH}
						label={DICTIONARY.NAME}
						minW="xs"
						maxW="xl"
						isRequired
					>
						<Input type="text" {...field} value={values.name} onChange={onChangeProxy} maxLength={DEFAULT_SITE_NAME_LENGTH} />
					</FormFieldControl>
				)}
			</Field>
			{siteDefinition && <Thumbnail siteDefinition={siteDefinition} eventHandler={onChangeThumbnailProxy} />}
		</>
	);
};

import { useReactQueryWrappersProvider } from 'app/react-query-wrappers-provider/hooks';
import { FC, PropsWithChildren, createContext, useCallback, useContext, useDeferredValue, useState } from 'react';
import { CheckJobStatusContextModel, JobStatus, JobStatusResponse } from 'shared/check-job-status/models';
import { JOB_STATUS, JOB_STATUS_REFETCH_INTERVAL, NORMALIZE_ID_WITHOUT_DASH_REGEX } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { useGet } from 'shared/react-query-wrappers/hooks';

export const CheckJobStatusContext = createContext<CheckJobStatusContextModel>({} as CheckJobStatusContextModel);

export const CheckJobStatusProvider: FC<PropsWithChildren> = ({ children }) => {
	const [completedHandles, setCompletedHandles] = useState<string[]>([]);

	const { queryClient } = useReactQueryWrappersProvider();

	const isCollectionCreation = useCallback((job: JobStatusResponse) => job.name.startsWith(JOB_STATUS.CREATE_SITE_COLLECTION), []);

	const isSiteCreation = useCallback(
		(job: JobStatusResponse) => job.name.startsWith(JOB_STATUS.CREATE_SITE) || job.name.startsWith(JOB_STATUS.CLONE_SITE),
		[]
	);

	const isSiteAndCollectionCreation = useCallback((job: JobStatusResponse) => job.name.startsWith(JOB_STATUS.SCAFFOLD_SITE), []);

	const isSiteRemoval = useCallback((job: JobStatusResponse) => job.name.startsWith(JOB_STATUS.REMOVE_SITE), []);

	const refetchInterval = useCallback(
		(running: JobStatus) => {
			running?.filter(async (job: JobStatusResponse) => {
				if (job.done && !completedHandles.includes(job.handle)) {
					if (isCollectionCreation(job) || isSiteAndCollectionCreation(job) || isSiteRemoval(job)) {
						await queryClient.invalidateQueries({ queryKey: [ENDPOINTS.COLLECTIONS], exact: true });
						await queryClient.invalidateQueries({ queryKey: [ENDPOINTS.LANGUAGES], exact: true, refetchType: 'all' });
					} else if (isSiteCreation(job)) {
						await queryClient.invalidateQueries({
							queryKey: [
								ENDPOINTS.COLLECTION_SITES,
								{ collectionId: job.siteCollection?.replace(NORMALIZE_ID_WITHOUT_DASH_REGEX, '').toLocaleLowerCase() },
							],
						});
						await queryClient.invalidateQueries({ queryKey: [ENDPOINTS.SITES], exact: true });
						await queryClient.invalidateQueries({ queryKey: [ENDPOINTS.LANGUAGES], exact: true, refetchType: 'all' });
					}

					setCompletedHandles([...completedHandles, job.handle]);
				}
			});

			return JOB_STATUS_REFETCH_INTERVAL;
		},
		[completedHandles, isCollectionCreation, isSiteAndCollectionCreation, isSiteCreation, isSiteRemoval, queryClient]
	);

	const response = useGet<JobStatusResponse[]>({ queryKey: { url: ENDPOINTS.JOBS }, ignoreGlobalErrorHandler: true, refetchInterval });

	const deferredResponse = useDeferredValue(response);

	const sortByDate = useCallback(
		(jobs: JobStatusResponse[]) => jobs.sort((a, b) => new Date(b.queueTime).getTime() - new Date(a.queueTime).getTime()),
		[]
	);

	const getAllJobs = useCallback(() => {
		const { data, error } = deferredResponse;
		const result = data
			? {
					siteAndCollectionCreations: sortByDate(data.filter((job: JobStatusResponse) => isSiteAndCollectionCreation(job))),
					siteCreations: sortByDate(data.filter((job: JobStatusResponse) => isSiteCreation(job))),
					collectionCreations: sortByDate(data.filter((job) => isCollectionCreation(job))),
					siteRemovals: sortByDate(data.filter((job) => isSiteRemoval(job))),
			  }
			: { done: true, name: '', errorMessage: error?.response?.data };

		return result;
	}, [deferredResponse, isCollectionCreation, isSiteAndCollectionCreation, isSiteCreation, isSiteRemoval, sortByDate]);

	const getRunningSiteCreations = useCallback(() => getAllJobs().siteCreations?.filter((job) => !job.done), [getAllJobs]);

	const getRunningSiteAndCollectionCreations = useCallback(() => getAllJobs().siteAndCollectionCreations?.filter((job) => !job.done), [getAllJobs]);

	const getRunningSiteCreationsWithinCollection = useCallback(
		(collectionId: string) =>
			getAllJobs()
				? getRunningSiteCreations()?.filter((job) => {
						const collectionIdFromJob = job.siteCollection
							?.replace(/\[|\]/g, '')
							.replace(NORMALIZE_ID_WITHOUT_DASH_REGEX, '')
							.toLocaleLowerCase();

						return collectionId === collectionIdFromJob && !job.done;
				  })
				: undefined,
		[getAllJobs, getRunningSiteCreations]
	);

	const getRunningCollectionCreations = useCallback(() => getAllJobs().collectionCreations?.filter((job) => !job.done), [getAllJobs]);

	const getRunningSiteRemovals = useCallback(() => getAllJobs().siteRemovals?.filter((job) => !job.done), [getAllJobs]);

	const hasRunningCollectionCreations = useCallback(
		(): boolean => (getRunningCollectionCreations()?.length ?? 0) > 0,
		[getRunningCollectionCreations]
	);

	const hasRunningSiteAndCollectionCreations = useCallback(
		(): boolean => (getRunningSiteAndCollectionCreations()?.length ?? 0) > 0,
		[getRunningSiteAndCollectionCreations]
	);

	const hasRunningSiteCreationsWithinCollection = useCallback(
		(collectionId: string): boolean => (getRunningSiteCreationsWithinCollection(collectionId)?.length ?? 0) > 0,
		[getRunningSiteCreationsWithinCollection]
	);

	const hasRunningSiteRemovals = useCallback((): boolean => (getRunningSiteRemovals()?.length ?? 0) > 0, [getRunningSiteRemovals]);

	return (
		<CheckJobStatusContext.Provider
			value={{
				getAllJobs,
				getRunningSiteCreations,
				getRunningSiteAndCollectionCreations,
				getRunningSiteCreationsWithinCollection,
				getRunningCollectionCreations,
				getRunningSiteRemovals,
				hasRunningCollectionCreations,
				hasRunningSiteAndCollectionCreations,
				hasRunningSiteCreationsWithinCollection,
				hasRunningSiteRemovals,
				isSuccess: deferredResponse.isSuccess,
			}}
		>
			{children}
		</CheckJobStatusContext.Provider>
	);
};

export const useCheckJobStatus = () => useContext(CheckJobStatusContext);

import { Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { RemoveSitePropsEA } from 'features/remove-site/models';
import { RemoveSiteModalBodyEA } from 'features/remove-site/ui/remove-site-modal-body-ea';
import { FC, PropsWithChildren, useState } from 'react';
import { DICTIONARY } from 'shared/constants';
import { useModalWrapper } from 'shared/modal-wrapper/hooks';

/**
 * @deprecated Update is blocked for EA
 */
export const RemoveSiteModalEA: FC<PropsWithChildren<RemoveSitePropsEA>> = (props) => {
	const { children, isOpen, onClose } = useModalWrapper({ children: props.children });
	const [isLoading, setIsLoading] = useState(false);

	return (
		<>
			{children}
			<Modal isOpen={isOpen} onClose={onClose} closeOnEsc={!isLoading} closeOnOverlayClick={!isLoading}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						<T _str={DICTIONARY.DELETE_SITE} />
					</ModalHeader>
					<ModalCloseButton />
					<RemoveSiteModalBodyEA onClose={onClose} {...props} setIsLoading={setIsLoading} />
				</ModalContent>
			</Modal>
		</>
	);
};

import { Button, Flex, Heading, Spacer, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { TextWithCopy } from 'features/common/components/TextWithCopy';
import { GenaralTabFieldDetails } from 'features/siteSettings/components/GeneralTab/FieldDetails';
import { GeneralTabForm } from 'features/siteSettings/components/GeneralTab/GeneralTabForm';
import { SaveCancelChanges } from 'features/siteSettings/components/SaveCancelChanges';
import { useRemoveSiteContext } from 'features/sites/components/RemoveSiteContext';
import { SiteNameField } from 'features/sites/components/rename/SiteNameField';
import { SiteWithPos } from 'features/sites/data/createSiteTypes';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';

export const SxaSiteGeneralTab: FC<{ site: SiteWithPos | undefined }> = ({ site }) => {
	const { removeSite } = useRemoveSiteContext();

	return (
		<GeneralTabForm siteName={site?.name}>
			<>
				<Flex direction="column" gap="6">
					<Flex flexDirection="row" width="full" alignItems="flex-start" mt="6" height="10">
						<Heading size="lg">
							<T _str={DICTIONARY.GENERAL} />
						</Heading>
						<Spacer />
						<SaveCancelChanges />
					</Flex>
					<Flex direction="row" gap="6" wrap="wrap" justifyContent="space-between">
						<Flex direction="column" gap="5" alignItems="start">
							<SiteNameField showHelper={true} inputWidth="md" />
							{site?.name && (
								<Button colorScheme="danger" onClick={() => removeSite(site?.name)}>
									<T _str={DICTIONARY.DELETE} />
								</Button>
							)}
						</Flex>
						<Flex backgroundColor="neutral.50" width="md" borderRadius="md" direction="column" p="6" gap="6">
							<Heading size="md" pb="2">
								<T _str={DICTIONARY.DETAILS} />
							</Heading>
							<Flex direction="column">
								<Text fontSize="sm" fontWeight="medium" color="neutral-fg">
									<T _str={DICTIONARY.ROOT_ITEM_ID} />
								</Text>
								<TextWithCopy textTransform="uppercase" text={site?.rootItemId} />
							</Flex>
							<GenaralTabFieldDetails name={DICTIONARY.SITE_COLLECTION} value={site?.siteCollectionName} />
							<GenaralTabFieldDetails name={DICTIONARY.ITEM_NAME} value={site?.rootItemName} />
							<GenaralTabFieldDetails name={DICTIONARY.ITEM_PATH} value={site?.rootItemPath} />
							<GenaralTabFieldDetails
								name={DICTIONARY.TEMPLATE}
								value={`${site?.rootItemTemplateName || ''} - \n${site?.rootItemTemplateId?.toUpperCase() || ''}`}
							/>
							<GenaralTabFieldDetails name={DICTIONARY.SITE_TYPE} value={DICTIONARY.TYPE_FILTER.SXA_MANAGED} />
							<GenaralTabFieldDetails name={DICTIONARY.CREATED_BY} value={site?.rootItemCreatedBy} />
						</Flex>
					</Flex>
				</Flex>
			</>
		</GeneralTabForm>
	);
};

import { WatchQueryFetchPolicy } from '@apollo/client';
import { useCompatibleQuery } from 'features/common/apolloProvider/useCompatibleQuery';
import { useIsFeatureAvailable } from 'features/common/tenantVersionPrivider/components/TenantVersionProvider';
import { defaultSitesInput } from 'shared/constants';
import { getSitesAndJobsQuery } from 'features/sites/queries';
import { useCallback, useMemo } from 'react';
import { SiteWithPos, SiteWithPosPayload, SolutionSites } from '../data/createSiteTypes';

const mapPayloadToSites = (data: SolutionSites<SiteWithPosPayload> | undefined): SiteWithPos[] =>
	(data?.solutionSites ?? []).map<SiteWithPos>(({ id, name, language, posMappings, settings, rootItem, siteCollection }) => ({
		id,
		name,
		posMappings: posMappings?.map((pos) => ({ name: pos.name, language: pos.language })) ?? [],
		languageIso: language.iso,
		appName: settings?.appName?.value,
		rootItemId: rootItem?.itemId,
		rootItemName: rootItem?.name,
		rootItemPath: rootItem?.path,
		rootItemCreatedBy: rootItem?.createdBy?.value,
		rootItemTemplateName: rootItem?.template.fullName,
		rootItemTemplateId: rootItem?.template.templateId,
		siteCollectionName: siteCollection?.displayName,
		siteTypeByCollection: siteCollection ? 'sxa' : 'nonsxa',
	}));

export const useGetSitesWithPos = (options?: { fetchPolicy?: WatchQueryFetchPolicy }) => {
	const { isAvaialable } = useIsFeatureAvailable('NonSXASites');
	const { refetch, loading, data } = useCompatibleQuery<SolutionSites<SiteWithPosPayload>>(getSitesAndJobsQuery, {
		variables: {
			includeSite: true,
			inludeJobs: false,
			includeLanguage: true,
			includePos: true,
			includeSettings: true,
			includeRootItem: true,
			includeSiteCollections: true,
			solutionInput: isAvaialable ? { database: 'master', includeNonSxaSites: true } : defaultSitesInput,
		},
		fetchPolicy: options?.fetchPolicy,
	});

	const sites: SiteWithPos[] = useMemo(() => mapPayloadToSites(data), [data]);
	const reFetchSites = useCallback(async () => {
		const { data: refetchedData } = await refetch();

		return mapPayloadToSites(refetchedData);
	}, [refetch]);

	return { sites, fetchSites: reFetchSites, loading: loading };
};

import * as Yup from 'yup';

const siteNameValidation = Yup.string()
	.required('This field can not be empty.')
	.trim('The site name cannot include leading or trailing spaces')
	.strict()
	.matches(/^[\w][\w\s-]*$/, 'Invalid characters. Characters allowed: A-Z, a-z, 0-9, -, . Spaces are also allowed')
	.max(100, 'Site name cannot be longer than 100 characters');

export const siteValidationSchema = Yup.object({
	siteName: siteNameValidation,
});

export const renameValidationSchema = Yup.object({
	newName: siteNameValidation,
});

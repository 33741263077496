import { SiteWithPos } from 'features/sites/data/createSiteTypes';
import _ from 'lodash';
import { PosPayload, CDPPosInput } from './types';

export const getMissingPos = (sites: SiteWithPos[], posCollection: PosPayload[]) => {
	const missingPosFromCdp: CDPPosInput[] = [];

	for (const site of sites) {
		const posMappings = _.cloneDeep(site.posMappings);

		for (let i = 0; i < posMappings.length; i++) {
			const posMapping = posMappings[i];

			if (posCollection.findIndex((pos) => pos.name.toLowerCase() == posMapping.name.toLowerCase()) == -1) {
				missingPosFromCdp.push({
					posName: posMapping.name,
					posLanguage: posMapping.language == '*' ? site.languageIso : posMapping.language,
				});
			}
		}
	}

	return missingPosFromCdp;
};

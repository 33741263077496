import { Flex, FormControl, FormErrorIcon, FormErrorMessage, IconButton, Input, Stack, Text, Tooltip } from '@chakra-ui/react';
import { mdiPlus } from '@mdi/js';
import { t } from '@transifex/native';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { ThumbnailCropModal } from 'entities/thumbnail/ui';
import { ChangeEvent, FC, useRef } from 'react';
import { DICTIONARY } from 'shared/constants';
import { useThumbnailDropzone } from '../hooks';
import { ThumbnailDropzoneProps, ThumbnailUploadStatus } from '../models';
import { ThumbnailUploading } from './thumbnail-uploading';

export const ThumbnailDropzone: FC<ThumbnailDropzoneProps> = ({ eventHandler, setUnsavedUrl, setToBeDeleted }) => {
	const fileRef = useRef<HTMLInputElement>(null);
	const {
		errorMessage,
		uploadStatus,
		onSelectFile,
		onUploadFile,
		onDragEnd,
		onDragOver,
		onDrop,
		onClose,
		isOpen,
		setCroppedAreaPixels,
		uploadCroppedImage,
		imgBase64,
	} = useThumbnailDropzone({
		fileRef,
		eventHandler,
		setUnsavedUrl,
		setToBeDeleted,
	});

	if (uploadStatus === ThumbnailUploadStatus.UPLOAD) {
		return <ThumbnailUploading />;
	}

	return (
		<>
			<Input type="file" accept="image/*" ref={fileRef} onChange={(event: ChangeEvent<HTMLInputElement>) => onUploadFile(event)} hidden />
			<Flex
				alignItems="center"
				backgroundColor={uploadStatus === ThumbnailUploadStatus.DROP ? 'primary.50' : 'chakra-body-bg'}
				border="1px"
				borderColor="primary-fg"
				borderRadius="md"
				borderStyle="dashed"
				gap="3"
				flexDir="column"
				minW="sm"
				maxW="xl"
				minH="28"
				py="4"
				onDrop={onDrop}
				onDragOver={onDragOver}
				onDragLeave={onDragEnd}
			>
				<Tooltip label={t(DICTIONARY.UPLOAD)}>
					<IconButton
						aria-label={t(DICTIONARY.UPLOAD)}
						border="1px"
						borderColor="chakra-border-color"
						variant="ghost"
						shadow="md"
						icon={<Icon path={mdiPlus} />}
						onClick={onSelectFile}
						data-testid="thumbnail-manual-upload"
					/>
				</Tooltip>
				<Stack gap="0.5" alignItems="center" w="72">
					<Text variant="strong" textAlign="center">
						<T _str={DICTIONARY.THUMBNAIL.ADD_CUSTOM_THUMBNAIL} />
					</Text>
					<Text variant="subtle" textAlign="center">
						<T _str={DICTIONARY.THUMBNAIL.UPLOAD_FILE_LIMITS} />
					</Text>
					<Text variant="subtle" textAlign="center">
						<T _str={DICTIONARY.THUMBNAIL.UPLOAD_FILE_RATIO} />
					</Text>
				</Stack>
			</Flex>
			{errorMessage && (
				<FormControl isInvalid>
					<FormErrorMessage>
						<FormErrorIcon />
						<Text>{errorMessage}</Text>
					</FormErrorMessage>
				</FormControl>
			)}
			<ThumbnailCropModal
				isOpen={isOpen}
				onClose={onClose}
				imgBase64={imgBase64}
				setCroppedAreaPixels={setCroppedAreaPixels}
				uploadCroppedImage={uploadCroppedImage}
			/>
		</>
	);
};

import { ButtonGroup, IconButton, Menu, MenuButton, MenuList, Tag, TagLabel, Tooltip } from '@chakra-ui/react';
import { mdiDotsHorizontal } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { useNavigationItems } from 'entities/page-layout/hooks';
import { Page, PageBody, PageBodyContent, PageBodyLeftBar, PageHeader } from 'entities/page-layout/ui';
import { PageLeftNavigation } from 'entities/page-layout/ui/page-left-navigation';
import { useFeatures } from 'features/common/featureFlags/featureFlags';
import { useTranslate } from 'features/common/utils/translateUtils';
import { ExplorerRedirectMenuItem, PageRedirectMenuItem } from 'features/site-actions-menu/ui';
import { CommonSitePageProps, SitePageOutlet } from 'pages/site/models';
import { FC, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router';
import { DICTIONARY } from 'shared/constants';
import { OpenInPagesButton } from 'shared/open-in-pages/ui';
import { LEFT_BAR_NAVIGATION } from 'shared/pageNavigation';

export const JssSitePage: FC<CommonSitePageProps> = ({ site, isLoading, environment, environmentType, goBack }) => {
	const links = useNavigationItems(LEFT_BAR_NAVIGATION.JSS_SITE_PAGE);
	const t = useTranslate();
	const location = useLocation();
	const { showSitesGrouping } = useFeatures();

	const outletContext = useMemo<Omit<SitePageOutlet, 'systemName'>>(
		() => ({
			site,
			languages: undefined,
			siteWithMissingPos: undefined,
			isLoading,
			isLanguagesLoading: false,
			locationOrigin: location.state?.origin ?? '/',
		}),
		[site, isLoading, location.state]
	);

	const siteName = site?.displayName || site?.name || '';

	return (
		<Page data-testid="site-settings-page">
			<PageHeader backAction={goBack} title={siteName} alignItems="center" justifyContent="space-between" w="full">
				<Tag size="sm" height="fit-content">
					<TagLabel>
						<T _str={DICTIONARY.SITE_ENVIRONMENT} envName={environment} envType={environmentType} />
					</TagLabel>
				</Tag>
				<ButtonGroup>
					{showSitesGrouping ? (
						<OpenInPagesButton hosts={site?.hosts} />
					) : (
						<Menu isLazy lazyBehavior="keepMounted">
							<Tooltip label={<T _str={DICTIONARY.OPTIONS} />}>
								<MenuButton
									onFocus={(e) => e.preventDefault()}
									as={IconButton}
									aria-label={t(DICTIONARY.OPTIONS)}
									icon={<Icon path={mdiDotsHorizontal} />}
									variant="outline"
									data-testid="site-settings-menu"
								/>
							</Tooltip>
							<MenuList data-testid="contextMenuList">
								<PageRedirectMenuItem siteName={site?.name || ''} />
								<ExplorerRedirectMenuItem siteName={site?.name || ''} />
							</MenuList>
						</Menu>
					)}
				</ButtonGroup>
			</PageHeader>
			<PageBody>
				<PageBodyLeftBar>
					<PageLeftNavigation links={links} />
				</PageBodyLeftBar>
				<PageBodyContent>
					<Outlet context={outletContext} />
				</PageBodyContent>
			</PageBody>
		</Page>
	);
};

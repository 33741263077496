import { useDisclosure, useToast } from '@chakra-ui/react';
import { getCroppedImageBase64 } from 'entities/thumbnail/utils/convert-to-blob';
import { ChangeEvent, DragEvent, useCallback, useRef, useState } from 'react';
import { Area } from 'react-easy-crop/types';
import { DICTIONARY, SITE_DEFINITION_FORM_EVENT_NAME, SITE_SETTINGS_FORM_EVENT_NAME } from 'shared/constants';
import { useEventListener } from 'usehooks-ts';
import { SiteGeneralSectionEvent } from 'widgets/site-settings-update-form/models';
import { ThumbnailUploadStatus } from '../models';
import { ThumbnailDropzoneModel } from '../models/use-thumbnail-dropzone-model';
import { isValidExtension } from '../utils/validataion-thumbnails';

export const useThumbnailDropzone = ({ fileRef, eventHandler, setUnsavedUrl, setToBeDeleted }: ThumbnailDropzoneModel) => {
	const documentRef = useRef<Document>(document);
	const toast = useToast();
	const [errorMessage, setErrorMessage] = useState<string>('');
	const [uploadStatus, setUploadStatus] = useState<ThumbnailUploadStatus>(ThumbnailUploadStatus.NONE);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [imgBase64, setImgBas64] = useState('');
	const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();

	const uploadFile = useCallback(
		(uploadedFile: File) => {
			setErrorMessage('');

			if (!isValidExtension(uploadedFile)) {
				setErrorMessage(DICTIONARY.THUMBNAIL.ERROR_MESSAGE.UNSUPPORTED_FILE_TYPE);
				setUploadStatus(ThumbnailUploadStatus.NONE);

				return;
			}

			const fileReader = new FileReader();

			fileReader.readAsDataURL(uploadedFile);

			fileReader.onloadend = async () => {
				setImgBas64(fileReader.result?.toString() || '');
				onOpen();
			};
		},
		[onOpen]
	);

	const onSelectFile = useCallback(() => {
		fileRef?.current?.click();
	}, [fileRef]);

	const onUploadFile = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			e.preventDefault();

			if (e.target.files?.length && typeof eventHandler === 'function') {
				uploadFile(e.target.files[0]);
			}
		},
		[eventHandler, uploadFile]
	);

	const onDrop = useCallback(
		(e: DragEvent<HTMLDivElement>) => {
			e.preventDefault();
			uploadFile(e.dataTransfer.files[0]);
		},
		[uploadFile]
	);

	const onDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
		e.stopPropagation();
		e.preventDefault();
	}, []);

	const onDragEnd = useCallback((e: DragEvent<HTMLDivElement>) => {
		e.stopPropagation();
		e.preventDefault();
	}, []);

	const uploadCroppedImage = useCallback(async () => {
		onClose();

		try {
			setUploadStatus(ThumbnailUploadStatus.UPLOAD);

			const croppedImage = await getCroppedImageBase64(imgBase64 || '', croppedAreaPixels, 0);

			if (setUnsavedUrl) {
				setUnsavedUrl(croppedImage as string);
			}

			if (eventHandler) {
				eventHandler('false', 'toBeDeleted');
				eventHandler(croppedImage as string, 'thumbnailBase64Url');
			}
		} catch (err: unknown) {
			if (err instanceof Error) {
				toast({
					status: 'error',
					description: err.message,
				});
			}
		}

		setUploadStatus(ThumbnailUploadStatus.NONE);
	}, [croppedAreaPixels, eventHandler, imgBase64, onClose, setUnsavedUrl, toast]);

	const onButtonsClick = useCallback(
		(event: CustomEvent<SiteGeneralSectionEvent>) => {
			const { type } = event.detail;

			if (type === 'reset') {
				if (setUnsavedUrl) {
					setUnsavedUrl('');
				}

				if (setToBeDeleted) {
					setToBeDeleted(false);
				}
			}
		},
		[setToBeDeleted, setUnsavedUrl]
	);

	useEventListener(SITE_SETTINGS_FORM_EVENT_NAME, onButtonsClick, documentRef);
	useEventListener(SITE_DEFINITION_FORM_EVENT_NAME, onButtonsClick, documentRef);

	return {
		errorMessage,
		uploadStatus,
		setErrorMessage,
		setUploadStatus,
		uploadFile,
		onSelectFile,
		onUploadFile,
		onDrop,
		onDragOver,
		onDragEnd,
		onClose,
		isOpen,
		setCroppedAreaPixels,
		uploadCroppedImage,
		imgBase64,
	};
};

import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	ButtonGroup,
	FormControl,
	FormLabel,
	Input,
	Stack,
	Text,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { useRemoveSiteDefinitionModal } from 'features/remove-site-definition/hooks/use-remove-site-definition-modal';
import { RemoveSiteDefinitionProps } from 'features/remove-site-definition/ui/remove-site-definition-button';
import { FC, PropsWithChildren, useRef, useState } from 'react';
import { DICTIONARY } from 'shared/constants';
import { useModalWrapper } from 'shared/modal-wrapper/hooks';

export const RemoveSiteDefinitionModal: FC<PropsWithChildren<RemoveSiteDefinitionProps>> = (props) => {
	const [name, setName] = useState('');
	const cancelRef = useRef<HTMLButtonElement>(null);
	const { children, isOpen, onClose } = useModalWrapper(props);
	const { hostName, siteName } = props;
	const handleRemove = useRemoveSiteDefinitionModal({ ...props, onClose });

	return (
		<>
			{children}
			<AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader>
							<T _str={DICTIONARY.DELETE_SITE_DEFINITION} />
						</AlertDialogHeader>
						<AlertDialogBody>
							<Stack spacing="6">
								<Text>
									<T
										_str={DICTIONARY.DELETE_SITE_DEFINITION_CONFIRMATION}
										defName={<strong>{hostName}</strong>}
										siteName={<strong>{siteName}</strong>}
									/>
								</Text>
								<FormControl isRequired>
									<FormLabel>
										<T _str={DICTIONARY.DELETE_SITE_CONFIRMATION_MESSAGE} name={hostName} />
									</FormLabel>
									<Input
										data-testid="delete-site-definition-input-name"
										value={name}
										onChange={(e) => setName(e.target.value)}
										isRequired
									/>
								</FormControl>
							</Stack>
						</AlertDialogBody>
						<AlertDialogFooter>
							<ButtonGroup>
								<Button ref={cancelRef} variant="ghost" onClick={onClose}>
									<T _str={DICTIONARY.CANCEL} />
								</Button>
								<Button
									data-testid="delete-site-definition-btn"
									colorScheme="danger"
									onClick={() => void handleRemove()}
									isDisabled={name !== hostName}
								>
									<T _str={DICTIONARY.DELETE} />
								</Button>
							</ButtonGroup>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
};

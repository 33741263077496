import { useUpdateSitePos } from 'features/sites/utils/useUpdateSitePos';
import { useCallback } from 'react';
import { useGetSite } from '../../sites/utils/useGetSite';

export const useUnassignPos = (): ((language: string) => Promise<void>) => {
	const [updateSitesPos] = useUpdateSitePos();
	const { site, fetchSite } = useGetSite();

	return useCallback(
		async (language: string) => {
			if (!site) {
				return;
			}

			const sitesPos = site.posMappings;
			const newPosList = sitesPos.filter((s) => s.language !== language);

			await updateSitesPos({
				variables: {
					posMappingsInput: [
						{
							id: site.id,
							newValue: newPosList,
						},
					],
				},
			});

			await fetchSite();
		},
		[site, updateSitesPos, fetchSite]
	);
};

import { Button, ButtonGroup, ModalBody, ModalFooter } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FormFieldProps } from 'entities/form-field-control/models';
import { FormFieldControl } from 'entities/form-field-control/ui';
import { FormSelectField } from 'entities/form-fields/ui';
import { TreeMenu } from 'entities/homepage-menu/ui';
import { InfoPopover } from 'entities/info-popover/ui';
import { useFeatures } from 'features/common/featureFlags/featureFlags';
import { useCreateSiteDefinitionAction, useCreateSiteDefinitionData } from 'features/site-definitions/hooks';
import { CreateSiteDefinitionFormProps } from 'features/site-definitions/models';
import { Field, Form, Formik } from 'formik';
import { FC } from 'react';
import { DICTIONARY, VALIDATIONS } from 'shared/constants';
import type { FormikFieldProps } from 'shared/models';
import { NonPredictiveInput } from 'shared/utils';
import { createSiteDefinitionValidationSchema } from 'shared/validations';

export const CreateSiteDefinitionForm: FC<CreateSiteDefinitionFormProps> = ({
	siteId,
	siteName,
	collectionId,
	onClose,
	host,
	languages,
	...props
}) => {
	const { defaultValues, validation, languages: allLanguages, renderingHosts } = useCreateSiteDefinitionData({ siteId, host });
	const handleSubmit = useCreateSiteDefinitionAction({ siteId, onClose, host, collectionId, ...props });
	const { showManagedLanguages } = useFeatures();

	return (
		<Formik
			initialValues={defaultValues}
			validationSchema={createSiteDefinitionValidationSchema(validation.names, validation.hostnames)}
			onSubmit={handleSubmit}
			validateOnBlur={false}
			validateOnChange={false}
			enableReinitialize
		>
			{({ handleChange, setFieldTouched }) => (
				<>
					<ModalBody display="flex" flexDir="column" gap="6">
						<Field name="name" validate>
							{({ field, form }: FormikFieldProps<string>) => (
								<FormFieldControl
									form={form}
									field={field}
									name="name"
									label={DICTIONARY.NAME}
									maxLength={VALIDATIONS.NAME_VALIDATION_NUMBER}
									isRequired
								>
									<NonPredictiveInput
										{...field}
										autoFocus
										data-testid="name-input"
										maxLength={VALIDATIONS.NAME_VALIDATION_NUMBER}
									/>
								</FormFieldControl>
							)}
						</Field>
						<FormSelectField
							name="language"
							label={DICTIONARY.DEFAULT_LANGUAGE}
							options={showManagedLanguages ? languages : allLanguages}
							handleChange={handleChange}
							setFieldTouched={setFieldTouched}
							data-testid="create-site-definition-default-language"
							isRequired
						/>
						<Field name="homePageId">
							{({ field, form }: FormFieldProps) => (
								<FormFieldControl
									form={form}
									field={field}
									name={field.name}
									label={DICTIONARY.HOMEPAGE}
									maxW="xl"
									helper={DICTIONARY.HOMEPAGE_HELPER}
									data-testid="create-site-definition-homepage"
								>
									<TreeMenu {...form} siteId={siteId} homePageId={field.value} />
								</FormFieldControl>
							)}
						</Field>
						<Field name="hostname" validate>
							{({ field, form }: FormikFieldProps<string>) => (
								<FormFieldControl
									form={form}
									field={field}
									name="hostname"
									label={DICTIONARY.HOST_NAME}
									helper={DICTIONARY.HOSTNAME_HELPER}
									isRequired
									labelHint={<InfoPopover header={DICTIONARY.HOST_NAME_POPOVER_HEADER} body={DICTIONARY.HOST_NAME_POPOVER_BODY} />}
									data-testid="create-site-definition-hostname"
								>
									<NonPredictiveInput {...field} data-testid="hostname-input" />
								</FormFieldControl>
							)}
						</Field>
						<FormSelectField
							name="renderingHost"
							label={DICTIONARY.RENDERING_HOST}
							helper={DICTIONARY.RENDERING_HOST_HINT}
							options={renderingHosts}
							handleChange={handleChange}
							setFieldTouched={setFieldTouched}
							useBasicStyles
							isClearable={false}
							data-testid="create-site-definition-renderinghost"
						/>
					</ModalBody>
					<Form noValidate>
						<ModalFooter>
							<ButtonGroup>
								<Button variant="ghost" onClick={onClose} data-testid="sc-create-site-definition-cancel">
									<T _str={DICTIONARY.CANCEL} />
								</Button>
								<Button type="submit" data-testid="sc-create-site-definition">
									<T _str={host ? DICTIONARY.DUPLICATE : DICTIONARY.CREATE} />
								</Button>
							</ButtonGroup>
						</ModalFooter>
					</Form>
				</>
			)}
		</Formik>
	);
};

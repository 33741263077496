import { ToggleSide } from 'entities/toggle-switcher/ui';
import { PosModalPropsEA } from 'features/point-of-sale/models';
import { mapSiteResponseToInputEA } from 'features/point-of-sale/models/save-pos-input';
import { useCallback, useState } from 'react';
import { ENDPOINTS } from 'shared/endpoints';
import { EditPOSInput, SiteResponse } from 'shared/models';
import { usePatch } from 'shared/react-query-wrappers/hooks';

/** @deprecated */
export const useAssignPosModalEA = (props: PosModalPropsEA) => {
	const { onClose, id, siteLanguage } = props;
	const [side, setSide] = useState<ToggleSide>('left');

	const { mutateAsync, isPending } = usePatch<SiteResponse, EditPOSInput>({
		queryKey: { url: ENDPOINTS.SITE, params: { id } },
		refetchQueriesByKey: [{ url: ENDPOINTS.SITE, params: { id } }, { url: ENDPOINTS.SITES }],
	});

	const assign = useCallback(
		async (posName: string) => {
			const input = mapSiteResponseToInputEA({ ...props });
			const existingPos = input.posMappings.find((pos) => pos.language === siteLanguage);

			if (existingPos) {
				existingPos.name = posName;
			} else {
				input.posMappings.push({ language: siteLanguage, name: posName });
			}

			await mutateAsync(input);
			onClose();
		},
		[mutateAsync, onClose, props, siteLanguage]
	);

	const onCloseModal = useCallback(() => {
		onClose();
		setSide('left');
	}, [onClose]);

	return { assign, side, setSide, isPending, onCloseModal };
};

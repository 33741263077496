import { useReactQueryWrappersProvider } from 'app/react-query-wrappers-provider/hooks';
import { useCallback } from 'react';
import { THUMBNAIL_REGENERATE_TIMEOUT } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { usePost } from 'shared/react-query-wrappers/hooks';
import { ThumbnailGeneralHookModelEA } from '../models';

export const useRegenerateThumbnailEA = ({ siteId, collectionId }: ThumbnailGeneralHookModelEA) => {
	const { queryClient } = useReactQueryWrappersProvider();
	const { mutateAsync } = usePost({
		queryKey: {
			url: ENDPOINTS.SITE_REGENERATE_THUMBNAIL,
			params: { siteId: siteId ?? '' },
		},
	});

	return useCallback(async () => {
		await mutateAsync({});
		setTimeout(() => {
			void queryClient.invalidateQueries({
				queryKey: [ENDPOINTS.COLLECTION_SITES, { collectionId: collectionId ?? '' }],
				exact: true,
			});
			void queryClient.invalidateQueries({
				queryKey: [ENDPOINTS.SITE, { id: siteId ?? '' }],
				exact: true,
			});
			void queryClient.invalidateQueries({
				queryKey: [ENDPOINTS.JOBS],
				exact: true,
			});
		}, THUMBNAIL_REGENERATE_TIMEOUT);
	}, [collectionId, mutateAsync, queryClient, siteId]);
};

import { useFeatures } from 'features/common/featureFlags/featureFlags';
import { FC } from 'react';
import { useGetLanguages } from 'shared/languages/hooks';
import { LanguageFieldProps } from '../models';
import { LanguageControlForm } from './language-control-form';

export const LanguageSupportedField: FC<LanguageFieldProps> = (props) => {
	const { showManagedLanguages } = useFeatures();
	const { data: languages } = useGetLanguages();

	return <LanguageControlForm name="language" {...props} languages={showManagedLanguages && props.languages ? props.languages : languages} />;
};

import { AlertDialog, AlertDialogContent, AlertDialogHeader, AlertDialogOverlay } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { DeletePageAlertProps } from 'features/page-actions-menu/models';
import { DeletePageAlertBody } from 'features/page-actions-menu/ui';
import { FC, PropsWithChildren, useRef } from 'react';
import { DICTIONARY } from 'shared/constants';
import { useModalWrapper } from 'shared/modal-wrapper/hooks';
import { ModalWrapperProps } from 'shared/modal-wrapper/models';

export const DeletePageAlert: FC<PropsWithChildren<DeletePageAlertProps & ModalWrapperProps>> = (props) => {
	const { isOpen, onClose, children } = useModalWrapper(props);
	const cancelRef = useRef<HTMLButtonElement>(null);

	return (
		<>
			{children}
			<AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef}>
				<AlertDialogOverlay />
				<AlertDialogContent>
					<AlertDialogHeader>
						<T _str={DICTIONARY.DELETE_PAGE} />
					</AlertDialogHeader>
					<DeletePageAlertBody onClose={onClose} cancelRef={cancelRef} {...props} />
				</AlertDialogContent>
			</AlertDialog>
		</>
	);
};

import { Flex, Heading, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { organizationUrl } from 'features/common/config/config';
import { FC, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DICTIONARY } from 'shared/constants';
import { OrganizationResponse } from 'shared/models';
import { useSimpleGet } from 'shared/react-query-wrappers/hooks';
import { PosTable } from 'widgets/pos-table/ui';

export const SettingsIdentifiersPage: FC = () => {
	const [params] = useSearchParams();
	const { data } = useSimpleGet<OrganizationResponse>(organizationUrl);

	const organizationName = useMemo(() => {
		const organization = params.get('organization');

		if (!organization) {
			return '';
		}

		const organizationObj = data?.data?.find((org) => org.id === organization);

		return organizationObj?.displayName || organizationObj?.name || '';
	}, [params, data]);

	return (
		<Flex direction="column" alignItems="flex-start" gap="6">
			<Flex direction="column" alignItems="flex-start" gap="1">
				<Heading size="xl">
					<T _str={DICTIONARY.ANALYTICS_IDENTIFIERS} />
				</Heading>
				<Text variant="subtle">
					<T _str={DICTIONARY.ANALYTICS_IDENTIFIERS_SETTINGS_TITLE} organizationName={organizationName} />
				</Text>
			</Flex>
			<PosTable boxSize="full" />
		</Flex>
	);
};

import { useTranslate } from 'features/common/utils/translateUtils';
import { useMemo } from 'react';
import { DICTIONARY } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { HierarchyPage } from 'shared/item-tree/models';
import { useGet } from 'shared/react-query-wrappers/hooks';
import { TreeMenuProps } from '../models';

export const useTreeMenu = ({ siteId, homePageId }: TreeMenuProps) => {
	const { data: ancestors, isLoading } = useGet<HierarchyPage>({
		queryKey: {
			url: ENDPOINTS.HIERARCHY_PAGE,
			params: { siteId, pageId: Array.isArray(homePageId) ? homePageId[0] : homePageId },
		},
	});
	const t = useTranslate();

	const label = useMemo(() => {
		if (isLoading || !ancestors) {
			return t(DICTIONARY.LOADING);
		}

		const page = ancestors.page || ancestors.children[0];
		const currentPageName: string = page.displayName || page.name;
		const names: string[] = ancestors.ancestors.map((m) => m.name);
		const newLabel = `${names.length ? names.join('/').concat('/') : ''}${currentPageName}`;

		return newLabel;
	}, [isLoading, ancestors, t]);

	return {
		label,
		isLoading,
		ancestors: ancestors?.ancestors,
	};
};

import { BoxProps } from '@chakra-ui/react';
import { DEFAULT_ANIMATION_TIME } from 'widgets/site-structure/constants';

export const getDropComponentStyle = (height: number, beforeHeight: number, marginLeft: string): BoxProps => ({
	height,
	transition: `height ${DEFAULT_ANIMATION_TIME}ms linear`,
	_before: {
		height: beforeHeight,
		content: '""',
		marginLeft,
		marginTop: 2,
		display: 'block',
		borderRadius: 'md',
		backgroundColor: 'neutral.100',
		boxShadow: 'base',
		transition: `height ${DEFAULT_ANIMATION_TIME}ms linear`,
	},
});

export const defaultDropHeight = 16;
export const defaultDropHeightClosed = 2;
export const defaultPseudoHeight = 12;

import { SiteDetailsFormData, UseSiteDetailsFormBody } from 'features/create-site/models';
import { useFormikContext } from 'formik';
import { useCallback, useEffect } from 'react';
import { ENDPOINTS } from 'shared/endpoints';
import { MultiLanguageItem } from 'shared/languages/models';
import { Access } from 'shared/models';
import { useSimpleGet } from 'shared/react-query-wrappers/hooks';

export const useSiteDetailsFormBody: UseSiteDetailsFormBody = (
	onSiteDetailsFormDataChange,
	siteDetailsFormData,
	onFormChange,
	setFieldTouched,
	handleChange
) => {
	const { isValid } = useFormikContext();

	const { data: languageAccess } = useSimpleGet<Access>(ENDPOINTS.LANGUAGE_PERMISSIONS);

	useEffect(() => onFormChange(isValid), [isValid, onFormChange]);

	const handleFieldChange = useCallback(
		(field: Partial<SiteDetailsFormData>) => {
			onSiteDetailsFormDataChange({ ...siteDetailsFormData, ...field });
		},
		[onSiteDetailsFormDataChange, siteDetailsFormData]
	);

	const onMultiLanguagesFieldChange = useCallback(
		(languages: MultiLanguageItem | null) => {
			const newFields = { languages } as Pick<SiteDetailsFormData, 'language' | 'languages'>;

			if (siteDetailsFormData.language && !languages?.includes(siteDetailsFormData.language)) {
				handleFieldChange({ language: null });
				setFieldTouched('language', false, false);
				handleChange('language')('');
				siteDetailsFormData.language = null;
			}

			handleFieldChange(newFields);
		},
		[siteDetailsFormData, handleFieldChange, setFieldTouched, handleChange]
	);

	return { handleFieldChange, onMultiLanguagesFieldChange, canCreateLanguage: languageAccess?.canCreate || false };
};

import { useTranslate } from 'features/common/utils/translateUtils';
import { useCallback } from 'react';
import { useCheckJobStatus } from 'shared/check-job-status';
import { JobStatusResponse } from 'shared/check-job-status/models';
import { DICTIONARY } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { Host, SiteResponse } from 'shared/models';
import { useGet } from 'shared/react-query-wrappers/hooks';

export type CheckerOptions = { originalValue: string; formKey: string; collectionId?: string };

/**
 * It checks if the site name is being created
 * @param value The value to check
 * @returns A method that checks if the site name is being created
 */
const useCheckSiteNameIsBeingCreated: () => (value: string) => JobStatusResponse | undefined = () => {
	const { getRunningSiteCreations, getRunningSiteAndCollectionCreations } = useCheckJobStatus();

	return useCallback(
		(value: string) => {
			return (
				getRunningSiteCreations()?.find((obj) => obj.site?.toLowerCase() === value.toLowerCase()) ||
				getRunningSiteAndCollectionCreations()?.find((obj) => obj.site?.toLowerCase() === value.toLowerCase())
			);
		},
		[getRunningSiteAndCollectionCreations, getRunningSiteCreations]
	);
};

export const useCheckSiteSystemNameUniqueness = ({ originalValue, formKey }: CheckerOptions) => {
	const t = useTranslate();
	const { data, isLoading } = useGet<SiteResponse[]>({ queryKey: { url: ENDPOINTS.SITES } });

	return useCallback(
		async (value: string) => {
			if (
				!isLoading &&
				data?.length &&
				data.findIndex((x: SiteResponse) => x.name.toLowerCase() === value.toLowerCase()) !== -1 &&
				value.toLowerCase() !== originalValue.toLowerCase()
			) {
				return { [formKey]: t(DICTIONARY.SITE_EXISTS_ERROR) };
			}
		},
		[data, formKey, isLoading, originalValue, t]
	);
};

export const useCheckSiteDefinitionNameUniqueness = ({ originalValue, formKey }: CheckerOptions) => {
	const t = useTranslate();
	const { data, isLoading } = useGet<SiteResponse[]>({ queryKey: { url: ENDPOINTS.SITES } });

	return useCallback(
		async (value: string) => {
			if (!isLoading && data?.length) {
				const siteDefinitions: Host[] = data.flatMap((site: SiteResponse) => site.hosts);

				if (siteDefinitions.findIndex((x: Host) => x.name.toLowerCase() === value.toLowerCase()) !== -1 && value !== originalValue) {
					return { [formKey]: t(DICTIONARY.SITE_DEFINITION_EXISTS_ERROR) };
				}
			}
		},
		[data, formKey, isLoading, originalValue, t]
	);
};

export const useCheckMetaNameUniqueness = ({ originalValue, formKey, collectionId = '' }: CheckerOptions) => {
	const t = useTranslate();
	const { data, isLoading } = useGet<SiteResponse[]>({
		queryKey: { url: ENDPOINTS.COLLECTION_SITES, params: { collectionId } },
		enabled: !!collectionId,
	});
	const checkSiteNameIsBeingCreated = useCheckSiteNameIsBeingCreated();

	return useCallback(
		async (value: string) => {
			if (
				!isLoading &&
				data?.length &&
				(data.findIndex((x: SiteResponse) => x.displayName.toLowerCase() === value.toLowerCase()) !== -1 ||
					checkSiteNameIsBeingCreated(value)) &&
				value !== originalValue &&
				value !== ''
			) {
				return { [formKey]: t(DICTIONARY.SITE_EXISTS_IN_COLLECTION_ERROR) };
			}
		},
		[checkSiteNameIsBeingCreated, data, formKey, isLoading, originalValue, t]
	);
};

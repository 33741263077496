import { Box } from '@chakra-ui/react';
import * as React from 'react';

import { LayoutAsideProps } from '../models';

export const LayoutLeft: React.FC<LayoutAsideProps> = ({ children, inset, background, ...rest }) => {
	return (
		<Box
			as="aside"
			order={1}
			overflow="auto"
			background={inset ? 'gray.50' : background}
			shadow={inset ? 'inner' : 'stroke'}
			zIndex={2}
			{...rest}
		>
			{children}
		</Box>
	);
};

/**

 * Sitecore UI LayoutLeft component default props

 */

LayoutLeft.defaultProps = {
	inset: false,
	background: 'white',
	w: 320,
	p: 6,
};

/**

 * Sitecore UI LayoutLeft components display names

 */

LayoutLeft.displayName = 'LayoutLeft';

import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	ButtonGroup,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FormikHelpers, FormikState } from 'formik';
import { useRef } from 'react';
import { DICTIONARY } from 'shared/constants';

type DiscardUnsavedAlertProps = {
	isOpen: boolean;
	onClose: () => void;
	dirty: React.MutableRefObject<boolean>;
	actionCallback: React.MutableRefObject<() => void>;
	formiks: (FormikHelpers<unknown> & FormikState<unknown>)[];
};

export const DiscardUnsavedAlert = ({ isOpen, onClose, dirty, actionCallback, formiks }: DiscardUnsavedAlertProps) => {
	const cancelRef = useRef(null);

	const handleDiscard = () => {
		if (actionCallback.current) {
			formiks.map((formik) => formik.resetForm());
			dirty.current = false;
			actionCallback.current();
		}

		onClose();
	};

	if (formiks.some((formik) => formik.isSubmitting)) {
		return <></>;
	}

	return (
		<AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader>
						<T _str={DICTIONARY.DISCARD_CHANGES} />
					</AlertDialogHeader>
					<AlertDialogBody>
						<T _str={DICTIONARY.DISCARD_CHANGES_AND_LEAVE} />
					</AlertDialogBody>
					<AlertDialogFooter gap="2">
						<ButtonGroup>
							<Button variant="ghost" ref={cancelRef} onClick={onClose}>
								<T _str={DICTIONARY.KEEP_EDITING} />
							</Button>
							<Button colorScheme="danger" onClick={handleDiscard}>
								<T _str={DICTIONARY.DISCARD} />
							</Button>
						</ButtonGroup>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};

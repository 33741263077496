import { XMTenantVersionHandler } from 'features/main/components/XMTenantVersionHandler';
import { FC } from 'react';
import { SitesLayout } from './SitesLayout';

export const XMTenantSaveSiteLayout: FC = () => {
	return (
		<XMTenantVersionHandler>
			<SitesLayout />
		</XMTenantVersionHandler>
	);
};

import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	ButtonGroup,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC, useCallback, useRef } from 'react';
import { useBlocker } from 'react-router-dom';
import { DICTIONARY } from 'shared/constants';

type DiscardUnsavedAlertProps = {
	isDirty: boolean;
	isSubmitting: boolean;
	onResetClick: () => void;
};

export const DiscardUnsavedAlert: FC<DiscardUnsavedAlertProps> = ({ isDirty, isSubmitting, onResetClick }) => {
	const cancelRef = useRef(null);
	const blocker = useBlocker(() => isDirty && !isSubmitting);

	const onProceedClick = useCallback(() => {
		if (blocker?.proceed) {
			blocker.proceed();
		}

		onResetClick();
	}, [blocker, onResetClick]);

	if (!blocker) {
		return <></>;
	}

	return (
		<AlertDialog isOpen={blocker.state === 'blocked'} leastDestructiveRef={cancelRef} onClose={() => blocker.reset?.()}>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader>
						<T _str={DICTIONARY.DISCARD_CHANGES} />
					</AlertDialogHeader>
					<AlertDialogBody>
						<T _str={DICTIONARY.DISCARD_CHANGES_AND_LEAVE} />
					</AlertDialogBody>
					<AlertDialogFooter gap="2">
						<ButtonGroup>
							<Button variant="ghost" ref={cancelRef} onClick={blocker.reset} data-testid="blocker-reset">
								<T _str={DICTIONARY.KEEP_EDITING} />
							</Button>
							<Button colorScheme="danger" onClick={onProceedClick} data-testid="blocker-proceed">
								<T _str={DICTIONARY.DISCARD} />
							</Button>
						</ButtonGroup>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};

import { useToast } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { RemoveSiteDefinitionProps } from 'features/remove-site-definition/ui/remove-site-definition-button';
import { useCallback } from 'react';
import { DICTIONARY, SITE_DEFINITION_REMOVING_LOCAL_STORAGE_KEY } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { useNavigateToRoute } from 'shared/navigation/hooks';
import { useDelete } from 'shared/react-query-wrappers/hooks';
import { ROUTES } from 'shared/routes';
import { useLocalStorage } from 'usehooks-ts';

export const useRemoveSiteDefinitionModal = ({
	hostId,
	hostName,
	onClose,
	siteId,
	collectionId,
}: RemoveSiteDefinitionProps & { onClose: () => void }) => {
	const navigate = useNavigateToRoute();
	const toast = useToast();
	const [, setRemoveValue] = useLocalStorage<string | undefined>(SITE_DEFINITION_REMOVING_LOCAL_STORAGE_KEY, undefined);

	const { mutateAsync } = useDelete({
		queryKey: { url: ENDPOINTS.DELETE_HOST, params: { siteId, hostId, force: 'true' } },
		refetchQueriesByKey: [
			{ url: ENDPOINTS.SITE, params: { id: siteId }, shouldWait: true },
			{ url: ENDPOINTS.COLLECTION_SITES, params: { collectionId } },
			{ url: ENDPOINTS.SITES },
			{ url: ENDPOINTS.JOBS },
		],
		ignoreGlobalErrorHandler: true,
	});

	const handleRemove = useCallback(async () => {
		setRemoveValue(hostId);
		onClose();
		navigate(`../../${ROUTES.SITE.DEFINITIONS.NAME}`);
		const promise = mutateAsync();

		toast.promise(promise, {
			loading: { description: <T _str={DICTIONARY.DELETE_PROMISE_TOAST.LOADING} defName={hostName} /> },
			success: { description: <T _str={DICTIONARY.DELETE_PROMISE_TOAST.SUCCESS} /> },
			error: { description: <T _str={DICTIONARY.DELETE_PROMISE_TOAST.ERROR} /> },
		});

		await promise
			.catch(() => {
				// Do nothing
			})
			.finally(() => {
				setRemoveValue(undefined);
			});
	}, [hostId, hostName, mutateAsync, navigate, onClose, setRemoveValue, toast]);

	return handleRemove;
};

import { LanguageResponse } from 'shared/languages/models/languages';
import { Access } from 'shared/models';

export type LanguageData = LanguageResponse & {
	fallbackLanguage: LanguageResponse | undefined;
	isFallbackRemoved: boolean;
};

export type LanguagesTableProps = {
	languagesData: LanguageData[];
	systemLanguages: LanguageResponse[];
	access?: Access;
};

export const LANGUAGES_SEARCH_PARAM = 'lang';

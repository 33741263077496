import { useToast } from '@chakra-ui/react';
import { useTranslate } from 'features/common/utils/translateUtils';
import { RenamePayload, UseRenameSiteDefinitionProps } from 'features/site-definition-actions-menu/models';
import { useCallback } from 'react';
import { DICTIONARY } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { SiteResponse } from 'shared/models';
import { usePatch } from 'shared/react-query-wrappers/hooks';
import { siteDefinitionNameValidationSchema } from 'shared/validations';

export const useRenameSiteDefinition = ({ siteId, hostId, collectionId }: UseRenameSiteDefinitionProps) => {
	const toast = useToast();
	const t = useTranslate();

	const { mutateAsync } = usePatch<SiteResponse, RenamePayload>({
		queryKey: { url: ENDPOINTS.SITE_HOST, params: { siteId, hostId } },
		refetchQueriesByKey: [
			{ url: ENDPOINTS.SITE, params: { id: siteId }, shouldWait: true },
			{ url: ENDPOINTS.COLLECTION_SITES, params: { collectionId } },
			{ url: ENDPOINTS.SITES },
			{ url: ENDPOINTS.JOBS },
		],
		ignoreGlobalErrorHandler: true,
	});

	const handleRename = useCallback(
		async (values: { name: string }, closeCallback: () => void) => {
			const resolve = mutateAsync({ name: values.name });

			toast.promise(resolve, {
				success: { description: t(DICTIONARY.SITE_DEFINITION_RENAME_MESSAGE.SUCCESS) },
				error: { description: t(DICTIONARY.SITE_DEFINITION_RENAME_MESSAGE.ERROR) },
				loading: { description: t(DICTIONARY.SITE_DEFINITION_RENAME_MESSAGE.LOADING) },
			});

			closeCallback();
		},
		[mutateAsync, t, toast]
	);

	return {
		siteDefinitionNameValidationSchema,
		handleRename,
	};
};

import { Table, TableContainer } from '@chakra-ui/react';
import { TableLoading } from 'entities/table/ui';
import { SitePageOutlet } from 'pages/site/models';
import { FC } from 'react';
import { useOutletContext } from 'react-router';
import { useGetLanguages } from 'shared/languages/hooks';
import { SiteDefinitionsTableProps } from 'widgets/site-definitions-table/models';
import { SiteDefinitionsTableBody, SiteDefinitionsTableHeader } from 'widgets/site-definitions-table/ui';

export const SiteDefinitionsTable: FC<SiteDefinitionsTableProps> = (props) => {
	const { data: allLanguages, isLoading: isLanguagesLoading } = useGetLanguages();
	const { data: systemLanguages, isLoading: isSystemLanguagesLOading } = useGetLanguages(true, { supportedLanguages: false });
	const { isLoading } = useOutletContext<SitePageOutlet>();

	return (
		<TableContainer whiteSpace="normal" width="full" backgroundColor="chakra-body-bg" borderRadius="lg" boxShadow="sm">
			<Table boxSize="full">
				<SiteDefinitionsTableHeader />
				{isLanguagesLoading || isSystemLanguagesLOading || isLoading ? (
					<TableLoading columns={5} rows={3} />
				) : (
					<SiteDefinitionsTableBody {...props} allLanguages={allLanguages} systemLanguages={systemLanguages} />
				)}
			</Table>
		</TableContainer>
	);
};

import { Button, Flex, Heading, SkeletonText, Text } from '@chakra-ui/react';
import { mdiArrowRight } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { FC, useCallback } from 'react';
import { DICTIONARY } from 'shared/constants';
import { useNavigateToRoute } from 'shared/navigation/hooks';
import { ROUTES } from 'shared/routes';
import { PortfolioHeaderProps } from '../models';

export const PortfolioHeader: FC<PortfolioHeaderProps> = ({
	id,
	name,
	displayName,
	description,
	isLoading,
	isRecentlyCreated,
	showAction = true,
	sitesCount = 0,
}) => {
	const navigateToRoute = useNavigateToRoute();
	const navigate = useCallback(() => {
		navigateToRoute(`/${ROUTES.PORTFOLIO.NAME}/${id}/${ROUTES.PORTFOLIO.GENERAL}`);
	}, [id, navigateToRoute]);

	return (
		<Flex justifyContent="space-between" gap="2">
			<Flex direction="column" gap="1">
				<Flex alignItems="baseline" gap="2">
					<Heading size="lg" noOfLines={1} title={displayName || name} data-testid="portfolio-title">
						{displayName || name}
					</Heading>
					{!isRecentlyCreated && (
						<SkeletonText size="sm" noOfLines={1} skeletonHeight="4" overflow="hidden" borderRadius="6" isLoaded={!isLoading}>
							<Text variant="small" minWidth="fit-content" data-testid="portfolio-sites-count" hidden={!isLoading && !sitesCount}>
								<T _str={DICTIONARY.SITE_CARD_COUNT} count={sitesCount} />
							</Text>
						</SkeletonText>
					)}
				</Flex>
				<Text variant="small" noOfLines={2} title={description}>
					{description}
				</Text>
			</Flex>
			{showAction && (
				<Button
					data-testid={`GoToCollection-${name}`}
					variant="ghost"
					rightIcon={<Icon path={mdiArrowRight} boxSize="5" />}
					onClick={navigate}
				>
					<T _str={DICTIONARY.GO_TO_COLLECTION} />
				</Button>
			)}
		</Flex>
	);
};

import { useCallback } from 'react';
import { RENAME_PAGE_END_EVENT_NAME, RenamePageEndEvent } from 'shared/site-structure';
import { isSameGuid } from 'shared/utils';
import { useEventListener } from 'usehooks-ts';
import { PageStructureEventsAllProps } from 'widgets/site-structure/hooks/use-page-structure-events';

export const useRenameEvents = ({
	documentRef,
	refetchIfNoParent,
	refetchIfParent,
	setAnimationState,
	page,
	parentId,
}: PageStructureEventsAllProps) => {
	const onRenamePageEnd = useCallback(
		(event: CustomEvent<RenamePageEndEvent>) => {
			if (isSameGuid(page?.id, event.detail.pageId)) {
				setAnimationState((animationState) => ({ ...animationState, isRenaming: false }));

				if (parentId) {
					void refetchIfParent();
				} else {
					void refetchIfNoParent();
				}
			}
		},
		[page?.id, parentId, refetchIfNoParent, refetchIfParent, setAnimationState]
	);

	useEventListener(RENAME_PAGE_END_EVENT_NAME, onRenamePageEnd, documentRef);
};

import { useDeferredValue, useMemo } from 'react';
import { ENDPOINTS } from 'shared/endpoints';
import { useSimpleGet } from 'shared/react-query-wrappers/hooks';
import { SiteResponse, UseJssPortfolio, mapSiteResponseToViewModel } from 'widgets/portfolio/models';

export const useJssPortfolio: UseJssPortfolio = () => {
	const { data, isLoading } = useSimpleGet<SiteResponse[]>(ENDPOINTS.SITES, true);

	const sites = useMemo(() => {
		return data
			?.filter((site) => !site.collectionId)
			?.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())
			.map((site) => ({ ...mapSiteResponseToViewModel(site) }));
	}, [data]);

	return {
		sites: useDeferredValue(sites),
		isLoading: useDeferredValue(isLoading),
	};
};

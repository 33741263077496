/* eslint-disable */

import { Button, ButtonGroup, IconButton, Skeleton, Stack, Text } from '@chakra-ui/react';
import { mdiChevronLeft, mdiChevronRight, mdiDotsHorizontal } from '@mdi/js';
import { Select, SingleValue } from 'chakra-react-select';
import { Icon } from 'entities/icon/ui';
import React from 'react';
import { PaginationProps } from './model';

type SingleSelect = {
	value: number;
	label: number;
};

export const Pagination: React.FC<PaginationProps> = ({
	variant = 'default',
	page = 1,
	pageSize = 20,
	totalItems,
	onChange,
	'data-behavior-analytics-feature': dataBehaviorAnalyticsFeature,
}) => {
	const totalPages = React.useMemo(() => {
		if (!totalItems) return 1;
		return Math.ceil(totalItems / pageSize);
	}, [totalItems, pageSize]);

	const canPreviousPage = React.useMemo(() => {
		return page > 1;
	}, [page]);

	const canNextPage = React.useMemo(() => {
		return page < totalPages;
	}, [page, totalPages]);

	const nextPage = React.useCallback(() => {
		if (page >= totalPages) return;
		return onChange(page + 1);
	}, [page, onChange]);

	const previousPage = React.useCallback(() => {
		if (page <= 1) return;
		return onChange(page - 1);
	}, [page, onChange]);

	const goToPage = React.useCallback(
		(newPage: number) => {
			if (newPage <= 0 || newPage > totalPages) return;
			onChange(newPage);
			const el = document.getElementById('CURRENT_PAGE');
			if (el) {
				el.focus();
			}
		},
		[page, pageSize, onChange]
	);

	const pageOptions = React.useMemo(() => {
		const result = [];
		for (let i = 1; i <= totalPages; i++) {
			result.push({
				value: i,
				label: i,
			});
		}
		return result;
	}, [totalPages]);

	const showPages = React.useMemo(() => {
		if (variant === 'default' || variant === 'minimal') {
			return 0;
		}
		if (variant === 'complex') {
			return 6;
		}
		return 2;
	}, [variant]);

	const nextPages = React.useMemo(() => {
		const d = totalPages - page;
		return (
			<>
				{d >= 1 && (
					<Button data-testid="pagination_goToPagePlus1" variant="ghost" onClick={() => goToPage(page + 1)}>
						{page + 1}
					</Button>
				)}
				{d >= 2 && (
					<Button data-testid="pagination_goToPagePlus2" variant="ghost" onClick={() => goToPage(page + 2)}>
						{page + 2}
					</Button>
				)}
				{d >= 3 && (
					<IconButton
						data-testid="pagination_goToLastPage"
						icon={<Icon path={mdiDotsHorizontal} />}
						aria-label={`Jump to last page (${totalPages})`}
						variant="ghost"
						onClick={() => goToPage(totalPages)}
					/>
				)}
			</>
		);
	}, [totalPages, page]);

	const previousPages = React.useMemo(() => {
		return (
			<>
				{page > 3 && (
					<IconButton
						data-testid="pagination_goToFirstPage"
						icon={<Icon path={mdiDotsHorizontal} />}
						variant="ghost"
						onClick={() => goToPage(1)}
						aria-label="Jump to first page"
					/>
				)}
				{page > 2 && (
					<Button data-testid="pagination_goToPageMinus2" variant="ghost" onClick={() => goToPage(page - 2)}>
						{page - 2}
					</Button>
				)}
				{page > 1 && (
					<Button data-testid="pagination_goToPageMinus1" variant="ghost" onClick={() => goToPage(page - 1)}>
						{page - 1}
					</Button>
				)}
			</>
		);
	}, [totalPages, page]);

	return (
		<Skeleton data-testid="pagination" isLoaded={typeof totalItems === 'number'} data-behavior-analytics-feature={dataBehaviorAnalyticsFeature}>
			<Stack direction="row" align="center">
				{variant !== 'minimal' && totalItems && (
					<Text css={{ marginRight: '0.75rem' }}>
						{page * pageSize - (pageSize - 1)}&nbsp;-&nbsp;
						{page * pageSize < totalItems ? page * pageSize : totalItems}
						&nbsp;of&nbsp;{Math.ceil(totalItems)}
					</Text>
				)}
				{(variant === 'minimal' || variant === 'default') && (
					<>
						<Select
							data-testid="pagination_pageSelect"
							options={pageOptions}
							value={{
								value: Number(page),
								label: page,
							}}
							onChange={(selected: SingleValue<SingleSelect>) => {
								goToPage(Number(selected?.value));
							}}
						/>
						<Text>&nbsp;of&nbsp;{totalPages}</Text>
					</>
				)}

				<ButtonGroup alignItems="center">
					<IconButton
						data-testid="pagination_previousPage"
						onClick={() => previousPage()}
						isDisabled={!canPreviousPage}
						aria-label="Previous page"
						variant="ghost"
						icon={<Icon path={mdiChevronLeft} />}
					/>
					{showPages > 0 && (
						<>
							{variant === 'complex' && page > 1 && previousPages}

							<Button data-testid="pagination_currentPage" variant="outline" aria-readonly id="CURRENT_PAGE">
								{page}
							</Button>

							{variant === 'complex' && page < totalPages && nextPages}
						</>
					)}
					<IconButton
						data-testid="pagination_nextPage"
						onClick={() => nextPage()}
						isDisabled={!canNextPage}
						aria-label="Next page"
						variant="ghost"
						icon={<Icon path={mdiChevronRight} />}
					/>
				</ButtonGroup>
			</Stack>
		</Skeleton>
	);
};

Pagination.displayName = 'Pagination';

import { useToast } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { useReactQueryWrappersProvider } from 'app/react-query-wrappers-provider/hooks';
import { useXMTenant } from 'features/common/tenants/xmTenantContext';
import type { DefinitionLanguagesMap, UseRemoveLanguageProps } from 'features/remove-language/models';
import { useCallback, useMemo } from 'react';
import { DICTIONARY } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import type { Host, SiteResponse } from 'shared/models';
import { useDelete, useGet } from 'shared/react-query-wrappers/hooks';

export const useRemoveLanguage: UseRemoveLanguageProps = (name, englishName, onClose) => {
	const { mutateAsync } = useDelete<string>({
		queryKey: { url: ENDPOINTS.LANGUAGE, params: { isoCode: name } },
		refetchQueriesByKey: [{ url: ENDPOINTS.SITES }, { url: ENDPOINTS.LANGUAGES, shouldWait: true }],
	});
	const toast = useToast();
	const { tenant } = useXMTenant();
	const { queryClient } = useReactQueryWrappersProvider();

	const { data, isLoading } = useGet<SiteResponse[]>({ queryKey: { url: ENDPOINTS.SITES } });
	const definitionDefaultLanguages = useMemo<DefinitionLanguagesMap[]>(() => {
		if (isLoading || !data) {
			return [];
		}

		return data.flatMap((site: SiteResponse) =>
			site.hosts.map((host: Host) => ({
				name: host.name,
				defaultLanguage: host.languageSettings.defaultLanguage,
			}))
		);
	}, [data, isLoading]);

	const isDefaultLanguageOf = useMemo<string[]>(() => {
		return definitionDefaultLanguages.filter((f) => f.defaultLanguage === name).map((m) => m.name);
	}, [definitionDefaultLanguages, name]);

	const submitHandler = useCallback(async () => {
		onClose();

		const promise = mutateAsync();

		toast.promise(promise, {
			loading: { description: <T _str={DICTIONARY.DELETE_LANGUAGE_TOAST.LOADING} englishName={englishName} /> },
			error: { description: <T _str={DICTIONARY.DELETE_LANGUAGE_TOAST.ERROR} /> },
			success: { description: <T _str={DICTIONARY.DELETE_LANGUAGE_TOAST.SUCCESS} /> },
		});

		void promise.then(() => {
			const siteIds = data?.map((site) => site.id) || [];

			siteIds.forEach((id) => void queryClient.removeQueries({ queryKey: [ENDPOINTS.SITE, { id }] }));
		});
	}, [data, englishName, mutateAsync, onClose, queryClient, toast]);

	return {
		submitHandler,
		environmentType: tenant.environmentType?.toUpperCase() || DICTIONARY.THIS,
		isDefaultLanguageOf,
	};
};

import { Box, Stack } from '@chakra-ui/react';
import { SkeletonRepeater } from 'entities/skeleton-repeater/ui';
import { TemplateCard } from 'entities/template-card/ui/template-card';
import { TemplateListProps } from 'features/create-site/models';
import { FC } from 'react';
import { useGetTemplates } from 'shared/templates/hooks';

export const TemplateList: FC<TemplateListProps> = ({ onTemplateSelect, selectedTemplateId }) => {
	const { filteredData } = useGetTemplates();

	return (
		<Stack gap="3" width="full">
			{filteredData &&
				filteredData.map((template) => (
					<Box data-testid="site-template" key={template.id} onClick={() => onTemplateSelect(template.id)}>
						<TemplateCard
							thumbnail={template.thumbnail}
							name={template.name}
							description={template.description}
							image={template.image}
							isSelected={selectedTemplateId === template.id}
						/>
					</Box>
				))}
			{!filteredData && <SkeletonRepeater height="32" />}
		</Stack>
	);
};

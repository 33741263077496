import { useCallback } from 'react';
import { ENDPOINTS } from 'shared/endpoints';
import { usePostForm } from 'shared/react-query-wrappers/hooks';
import { ThumbnailHookModel } from '../models';

export const useUploadThumbnail = ({ siteId, hostId }: ThumbnailHookModel) => {
	const { mutateAsync } = usePostForm({
		queryKey: {
			url: ENDPOINTS.SITE_HOST_UPLOAD_THUMBNAIL,
			params: { siteId: siteId ?? '', hostId: hostId ?? '' },
		},
	});

	return useCallback(
		async (formData?: FormData) => {
			await mutateAsync(formData);
		},
		[mutateAsync]
	);
};

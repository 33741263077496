import { DocumentNode, OperationVariables, QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { CompatibilityFeatures, useTenantVersionFeaturesProvider } from '../tenantVersionPrivider/components/TenantVersionProvider';
import { excludeNotSupportedFields } from './queryUtils';

export const useBuildCompatibleQuery = (query: DocumentNode) => {
	const { loading, features } = useTenantVersionFeaturesProvider();
	const modifiedQuery = useMemo(() => (loading ? query : excludeNotSupportedFields(features, query)), [loading, features, query]);

	return { loading, query: modifiedQuery, features };
};

export function useCompatibleQuery<TData, TVariables extends OperationVariables = OperationVariables>(
	query: DocumentNode,
	options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> & { features: CompatibilityFeatures } {
	const { loading: loadingFeatures, query: modifiedQuery, features } = useBuildCompatibleQuery(query);
	const result = useQuery(modifiedQuery, { ...options, skip: options?.skip || loadingFeatures });

	return {
		...result,
		loading: loadingFeatures || result.loading,
		features,
	};
}

import { Checkbox, Flex, FormControl, Link, Text } from '@chakra-ui/react';
import { mdiInformationOutline } from '@mdi/js';
import { T } from '@transifex/react';
import { Select as ReactSelect } from 'chakra-react-select';
import { FormFieldProps } from 'entities/form-field-control/models';
import { FormFieldControl } from 'entities/form-field-control/ui';
import { Icon } from 'entities/icon/ui';
import { Field, Form } from 'formik';
import { FC } from 'react';
import { DICTIONARY, LANGUAGE_FALLBACK_INFO_LINK } from 'shared/constants';
import { LanguageItem } from 'shared/languages/models';
import { useLanguageSectionBody } from 'widgets/site-definition-update-form/hooks/use-language-section-body';
import { Option, SingleValue } from 'widgets/site-definition-update-form/models';
import { setSiteDefinitionFieldValue } from 'widgets/site-definition-update-form/models/event';

export type SiteLanguageSectionProps = {
	languages: LanguageItem[];
	resetForm: () => void;
};

export const SiteLanguageSection: FC<SiteLanguageSectionProps> = ({ languages, resetForm }) => {
	const { defaultValue } = useLanguageSectionBody(resetForm);

	return (
		<Form>
			<Flex direction="column" gap="6" maxWidth="xl">
				<Field name="language">
					{({ field, form }: FormFieldProps) => (
						<FormFieldControl
							isRequired
							isInvalid={!!form.errors[field.name]}
							field={field}
							form={form}
							name="language"
							label={DICTIONARY.DEFAULT_LANGUAGE}
							data-testid="site-definition-general-default-language"
						>
							<ReactSelect
								{...field}
								defaultValue={defaultValue(languages, field.value)}
								selectedOptionStyle="check"
								options={languages}
								onChange={(newValue: SingleValue) => {
									form.setFieldValue(field.name, newValue);
									setSiteDefinitionFieldValue(field.name, (newValue as Option).value);
								}}
								onBlur={() => {
									form.setFieldTouched(field.name, true);
								}}
							/>
						</FormFieldControl>
					)}
				</Field>
				<Field name="languageEmbedding">
					{({ field }: FormFieldProps<boolean>) => (
						<FormControl data-testid="site-definition-general-language-embedding">
							<Checkbox
								name={field.name}
								isChecked={field.value}
								onChange={(e) => {
									field.onChange(e);
									setSiteDefinitionFieldValue(field.name, e.target.checked.toString());
								}}
							>
								<T _str={DICTIONARY.LANGUAGE_EMBEDDING} />
							</Checkbox>
						</FormControl>
					)}
				</Field>
				<Field name="itemLanguageFallback">
					{({ field }: FormFieldProps<boolean>) => (
						<FormControl data-testid="site-definition-general-language-fallback">
							<Checkbox
								name={field.name}
								isChecked={field.value}
								onChange={(e) => {
									field.onChange(e);
									setSiteDefinitionFieldValue(field.name, e.target.checked.toString());
								}}
							>
								<T _str={DICTIONARY.LANGUAGE_FALLBACK} />
							</Checkbox>
						</FormControl>
					)}
				</Field>
				<Field name="fieldLanguageFallback">
					{({ field }: FormFieldProps) => (
						<FormControl data-testid="site-definition-general-language-fallback-for-fields">
							<Checkbox
								name={field.name}
								isChecked={field.value}
								onChange={(e) => {
									field.onChange(e);
									setSiteDefinitionFieldValue(field.name, e.target.checked.toString());
								}}
							>
								<T _str={DICTIONARY.LANGUAGE_FALLBACK_FOR_FIELDS} />
							</Checkbox>
						</FormControl>
					)}
				</Field>
				<Flex gap="1">
					<Icon color="neutral" path={mdiInformationOutline} />
					<Link href={LANGUAGE_FALLBACK_INFO_LINK} target="_blank">
						<T _str={DICTIONARY.LEARN_MORE} />
					</Link>
					<Text variant="subtle">
						<T _str={DICTIONARY.ABOUT_LANGUAGE_FALLBACK} />
					</Text>
				</Flex>
			</Flex>
		</Form>
	);
};

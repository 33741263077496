import { ThumbnailType, UseThumbnail } from 'features/thumbnail/models';
import { useCallback, useState } from 'react';
import { useRemoveGeneralThumbnailEA } from './use-remove-general-thumbnail-ea';
import { useThumbnailAutoEA } from './use-thumbnail-auto-ea';

/**
 * @deprecated Update is blocked for EA
 */
export const useThumbnailEA: UseThumbnail = ({ site }) => {
	const { isRegenerating, onClickRegenerate } = useThumbnailAutoEA(site);
	const [typeThumbnail, setTypeThumbnail] = useState<ThumbnailType>(site?.thumbnail.autogenerated ? ThumbnailType.AUTO : ThumbnailType.MANUAL);
	const handleRemoveThumbnail = useRemoveGeneralThumbnailEA({ siteId: site?.id, collectionId: site?.collectionId });

	const onAutoClickHandler = useCallback(() => {
		setTypeThumbnail(ThumbnailType.AUTO);

		if (!site?.thumbnail?.autogenerated) {
			void handleRemoveThumbnail();
			void onClickRegenerate();
		}
	}, [handleRemoveThumbnail, onClickRegenerate, site?.thumbnail?.autogenerated]);

	const onManualClickHandler = useCallback(() => setTypeThumbnail(ThumbnailType.MANUAL), []);

	return { onAutoClickHandler, onManualClickHandler, typeThumbnail, isRegenerating };
};

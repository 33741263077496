import { Tbody, Td, Text, Tr } from '@chakra-ui/react';
import { FC } from 'react';
import { LanguagesTableProps } from 'widgets/languages-table/models/languages';
import { LanguageFallback } from 'widgets/languages-table/ui/language-fallback';
import { LanguageActionsButton } from 'widgets/languages-table/ui/languages-action-button';

export const LanguagesTableBody: FC<LanguagesTableProps> = ({ languagesData, systemLanguages, access }) => (
	<Tbody>
		{languagesData.map((language) => {
			return (
				<Tr key={language.name}>
					<Td>
						<Text noOfLines={1} title={language.englishName} fontWeight="semibold">
							{language.englishName}
						</Text>
					</Td>
					<Td>
						<LanguageFallback {...language} />
					</Td>
					<Td>{language.name}</Td>
					<Td display="flex" justifyContent="flex-end">
						<LanguageActionsButton language={language} systemLanguages={systemLanguages} access={access} />
					</Td>
				</Tr>
			);
		})}
	</Tbody>
);

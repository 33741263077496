import { useGainsighSetIdentifyFields, useGainsightSetGlobalContext } from 'features/common/gainsight/hooks';
import { FC, memo } from 'react';

const GainsightMetricsRegistration: FC = () => {
	useGainsighSetIdentifyFields();
	useGainsightSetGlobalContext();

	return <></>;
};

export default memo(GainsightMetricsRegistration);

import {
	Alert,
	AlertDescription,
	AlertIcon,
	Button,
	ButtonGroup,
	Flex,
	Input,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Stack,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FormFieldProps } from 'entities/form-field-control/models';
import { FormFieldControl } from 'entities/form-field-control/ui';
import { useCreatePosForm } from 'features/point-of-sale/hooks/use-create-pos-form';
import { AssignPosProps } from 'features/point-of-sale/models';
import { CreatePOSFormModel } from 'features/point-of-sale/models/create-assign-pos';
import { Field, Formik } from 'formik';
import { FC } from 'react';
import { DICTIONARY, POS_DEFAULT_TIMEOUT, POS_NAME_MAX_LENGTH } from 'shared/constants';
import { createPosValidationSchema } from 'shared/validations';

export const CreatePOSForm: FC<AssignPosProps> = (props) => {
	const { siteName, onClose, assign, isPending: isAssignPending } = props;
	const { initialPosName, onNameChange, submit, isReady, isPending: isPosCreationPending } = useCreatePosForm(siteName, assign, onClose);
	const isPending = isAssignPending || isPosCreationPending;

	if (!isReady) {
		return <></>;
	}

	return (
		<Formik<CreatePOSFormModel>
			initialValues={{
				name: initialPosName,
				timeout: POS_DEFAULT_TIMEOUT,
			}}
			onSubmit={submit}
			validateOnChange={false}
			validateOnMount={false}
			validateOnBlur={false}
			validationSchema={createPosValidationSchema}
		>
			{({ submitForm }) => (
				<Flex direction="column" gap="6" flexGrow="1">
					<Alert>
						<AlertIcon />
						<AlertDescription>
							<T _str={DICTIONARY.CREATE_POS_ALERT} />
						</AlertDescription>
					</Alert>
					<Stack flexGrow="1" gap="6">
						<Field name="name" isRequired>
							{({ field, form }: FormFieldProps) => (
								<FormFieldControl
									form={form}
									field={field}
									name="name"
									label={DICTIONARY.NAME}
									helper={DICTIONARY.POS_NAME_HELPER}
									minW="xs"
									maxW="xl"
									isRequired
									maxLength={POS_NAME_MAX_LENGTH}
								>
									<Input
										type="text"
										{...field}
										onChange={(e) => onNameChange(e, field)}
										maxLength={POS_NAME_MAX_LENGTH}
										autoFocus
									/>
								</FormFieldControl>
							)}
						</Field>
						<Field name="timeout" isRequired>
							{({ field, form }: FormFieldProps) => (
								<FormFieldControl
									form={form}
									field={field}
									name="timeout"
									label={DICTIONARY.TIMEOUT_IN_MINUTES}
									helper={DICTIONARY.POS_TIMEOUT_HELPER}
									minW="xs"
									maxW="xl"
									isRequired
								>
									<NumberInput min={1} value={field.value} onChange={(value) => form.setFieldValue('timeout', value)}>
										<NumberInputField />
										<NumberInputStepper>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput>
								</FormFieldControl>
							)}
						</Field>
					</Stack>
					<ButtonGroup justifyContent="end" paddingY="3">
						<Button variant="ghost" onClick={onClose} isDisabled={isPending}>
							<T _str={DICTIONARY.CANCEL} />
						</Button>
						<Button
							type="submit"
							colorScheme="primary"
							isLoading={isPending}
							loadingText={<T _str={DICTIONARY.ASSIGNING} />}
							onClick={() => void submitForm()}
							data-testid="assign-just-created-pos"
						>
							<T _str={DICTIONARY.ASSIGN} />
						</Button>
					</ButtonGroup>
				</Flex>
			)}
		</Formik>
	);
};

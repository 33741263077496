import { Box, Container } from '@chakra-ui/react';
import { FC } from 'react';
import { SiteInfo, SiteJobViewModel } from '../data/createSiteTypes';
import { useSiteFilter } from '../utils/useSiteFilter';
import { SiteAnalyticsAlert } from './SiteAnalyticsAlert';
import { SitesList } from './SitesList';

type SitesViewProps = {
	sites: SiteInfo[];
	jobs: SiteJobViewModel[];
};

export const SitesCardView: FC<SitesViewProps> = ({ sites, jobs }) => {
	const { filteredSites, isFiltering, resetFilters } = useSiteFilter(sites);

	return (
		<Box height="full" overflow="auto" paddingTop="2" pb="8" px="8">
			<Container maxWidth="container.xl">
				<SiteAnalyticsAlert />
				<SitesList sites={filteredSites} jobs={jobs} resetFilters={resetFilters} isFiltering={isFiltering} />
			</Container>
		</Box>
	);
};

import { CircularProgress, Stack, Text } from '@chakra-ui/react';
import { CollectionCreationProgressProps } from 'entities/collection-creation-progress/models';
import { FC } from 'react';

export const CollectionCreationProgress: FC<CollectionCreationProgressProps> = ({ text }) => {
	return (
		<Stack padding="5" background="neutral.50" borderRadius="md" gap="4" alignItems="center" data-testid="collection-creation-progress">
			<CircularProgress isIndeterminate capIsRound color="primary" trackColor="neutral-color.200" size="8" />
			<Text variant="strong">{text}</Text>
		</Stack>
	);
};

import { Flex, Text, Tooltip } from '@chakra-ui/react';
import { mdiAlertCircleOutline } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { LanguageResponse } from 'shared/languages/models';

type LanguageFallbackProps = {
	fallbackLanguage: LanguageResponse | undefined;
	isFallbackRemoved: boolean;
};

export const LanguageFallback: FC<LanguageFallbackProps> = ({ fallbackLanguage, isFallbackRemoved }) => {
	if (isFallbackRemoved) {
		return (
			<Flex gap="1" color="danger" alignItems="center">
				<Tooltip label={<T _str={DICTIONARY.DELETED_FALLBACK_LANGUAGE_LABEL} />}>
					<span>
						<Icon data-testid="fallback-language-error-icon" path={mdiAlertCircleOutline} boxSize="5" />
					</span>
				</Tooltip>
				<Text noOfLines={1} title={fallbackLanguage?.englishName}>
					{fallbackLanguage?.englishName}
				</Text>
			</Flex>
		);
	}

	return (
		<Text noOfLines={1} title={fallbackLanguage?.englishName}>
			{fallbackLanguage?.englishName}
		</Text>
	);
};

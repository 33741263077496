import { Heading, Stack } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { PageBodyContent, PageBodyContentSection } from 'entities/page-layout/ui';
import { Formik } from 'formik';
import { useSiteLanguagesPage } from 'pages/languages/hooks';
import { SiteLanguagesViewModel } from 'pages/languages/models';
import { LanguageSectionForm } from 'pages/languages/ui';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { siteLanguagesSectionValidationSchema } from 'shared/validations';

export const SiteLanguagesPage: FC = () => {
	const { onSubmit, initialValues } = useSiteLanguagesPage();

	return (
		<PageBodyContent>
			<Stack gap="6">
				<Heading size="lg">
					<T _str={DICTIONARY.LANGUAGES} />
				</Heading>
			</Stack>
			<PageBodyContentSection>
				<Formik<SiteLanguagesViewModel>
					initialValues={initialValues}
					onSubmit={onSubmit}
					validationSchema={siteLanguagesSectionValidationSchema}
					validateOnChange
					validateOnBlur
					enableReinitialize
					validateOnMount
				>
					{(formikProps) => <LanguageSectionForm {...formikProps} />}
				</Formik>
			</PageBodyContentSection>
		</PageBodyContent>
	);
};

import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import React, { FC, useCallback, useMemo } from 'react';
import { SitecoreUser } from '../auth/SitecoreUser';
import { Loading } from '../components/Loading';
import { useResolveTenant } from './tenantResolver';
import { ITenant } from './tenantsModel';
import { XMTenantContext, XMTenantModelWrapper } from './xmTenantContext';

export const XMTenantProvider: FC<React.PropsWithChildren> = withAuthenticationRequired(
	({ children }) => {
		const { user, getAccessTokenSilently } = useAuth0<SitecoreUser>();
		const tenantNameClaim = user?.['https://auth.sitecorecloud.io/claims/tenant_name'];

		const authenticateToTenant = useCallback(
			async (tenant: ITenant | undefined) => {
				if (tenantNameClaim || !tenant) {
					return;
				}

				await getAccessTokenSilently({
					authorizationParams: {
						tenant_name: tenant.name,
					},
					cacheMode: 'off',
				});
			},
			[tenantNameClaim, getAccessTokenSilently]
		);

		const { tenant, error, loading } = useResolveTenant((resolvedTenant) => {
			void authenticateToTenant(resolvedTenant);
		});

		const xmTenant = useMemo<XMTenantModelWrapper>(() => {
			return tenant
				? {
						resolved: true,
						model: {
							id: tenant.id,
							name: tenant.name,
							url: tenant.annotations.URL && new URL(tenant.annotations.URL).origin,
							cdpId: tenant.annotations['XMCloud.CDPEmbeddedTenantID'],
							environemntId: tenant.annotations['XMCloud.EnvironmentId'],
							projectName: tenant.annotations['XMCloud.ProjectName'],
							environmentName: tenant.annotations['XMCloud.EnvironmentName'],
							environmentType: tenant.annotations['XMCloud.CustomerEnvironmentType'],
							regionCode: tenant.labels.RegionCode,
						},
						error: error,
				  }
				: {
						resolved: false,
						error: error,
				  };
		}, [error, tenant]);

		if (error) {
			return <XMTenantContext.Provider value={xmTenant}>{children}</XMTenantContext.Provider>;
		}

		if (loading) {
			return <Loading />;
		}

		// Tenant is retrieved but login into tenant hasn't happened yet
		if (tenant && !tenantNameClaim) {
			return <Loading />;
		}

		return <XMTenantContext.Provider value={xmTenant}>{children}</XMTenantContext.Provider>;
	},
	{
		onRedirecting: () => <Loading />,
	}
);

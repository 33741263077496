import { Flex, FormLabel, Switch } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FormFieldProps } from 'entities/form-field-control/models';
import { Field } from 'formik';
import { ChangeEvent, FC, useCallback, useRef } from 'react';
import { DICTIONARY, SITE_SETTINGS_FORM_EVENT_NAME } from 'shared/constants';
import { useEventListener } from 'usehooks-ts';
import { SiteGeneralSectionEvent, setSiteGeneralFieldValue } from 'widgets/site-settings-update-form/models';
import { SiteSharingSectionFormBodyProps } from 'widgets/site-sharing/models';

export const SiteSharingSectionFormBody: FC<SiteSharingSectionFormBodyProps> = ({ resetForm }) => {
	const documentRef = useRef<Document>(document);

	const onButtonsClick = useCallback(
		(event: CustomEvent<SiteGeneralSectionEvent>) => {
			if (event.detail.type === 'reset') {
				resetForm();
			}
		},
		[resetForm]
	);

	useEventListener(SITE_SETTINGS_FORM_EVENT_NAME, onButtonsClick, documentRef);

	return (
		<Field name="shared">
			{({ field, form }: FormFieldProps) => (
				<Flex>
					<FormLabel mb="unset">
						<T _str={DICTIONARY.SHARE_SITE} />
					</FormLabel>
					<Switch
						data-testid="site-sharing-switch"
						isChecked={field.value}
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							form.setFieldValue(field.name, e.target.checked);
							setSiteGeneralFieldValue(field.name, e.target.checked ? 'true' : 'false');
						}}
					/>
				</Flex>
			)}
		</Field>
	);
};

import { Table } from '@chakra-ui/react';
import { TableLoading } from 'entities/table/ui';
import { FC } from 'react';
import { useGetAllPoS } from 'shared/api';
import { SitePosTableProps } from 'widgets/site-pos-table/models';
import { SitePosTableBody } from 'widgets/site-pos-table/ui/site-pos-table-body';
import { SitePosTableHeader } from 'widgets/site-pos-table/ui/site-pos-table-header';

export const SitePosTable: FC<SitePosTableProps> = ({ siteData, languages, isLoading }) => {
	const { posList, isLoading: isPosLoading } = useGetAllPoS();
	const isDataLoading = isLoading || isPosLoading;

	return (
		<Table width="full">
			<SitePosTableHeader />
			{isDataLoading ? <TableLoading columns={3} rows={3} /> : <SitePosTableBody siteData={siteData} languages={languages} posList={posList} />}
		</Table>
	);
};

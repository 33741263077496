import { useFeatures } from 'features/common/featureFlags/featureFlags';
import { Formik } from 'formik';
import { FC, useMemo } from 'react';
import { useGetLanguages } from 'shared/languages/hooks';
import { LanguageItem } from 'shared/languages/models';
import { SiteDefinitionResponseViewModel } from 'shared/models';
import { siteDefinitionLanguageValidationSchema } from 'shared/validations';
import { useCalculateDefaultLanguage } from 'widgets/site-definition-update-form/hooks/use-calculate-default-language';
import { SiteLanguageSection } from 'widgets/site-definition-update-form/ui/language-section-body';

export type LanguageFormProps = {
	languages: LanguageItem[];
	siteDefinition: SiteDefinitionResponseViewModel;
};

export const SiteLanguageForm: FC<LanguageFormProps> = (props) => {
	const { languages, siteDefinition } = props;
	const { data: systemLanguages } = useGetLanguages(true, { supportedLanguages: false });
	const { data: allLanguages } = useGetLanguages();
	const { showManagedLanguages } = useFeatures();
	const defaultLanguage = useCalculateDefaultLanguage(allLanguages, siteDefinition.defaultLanguage);

	const initialValues = useMemo(() => {
		return {
			language: defaultLanguage,
			languageEmbedding: siteDefinition.languageEmbedding || false,
			itemLanguageFallback: siteDefinition.itemLanguageFallback || false,
			fieldLanguageFallback: siteDefinition.fieldLanguageFallback || false,
		};
	}, [defaultLanguage, siteDefinition.fieldLanguageFallback, siteDefinition.itemLanguageFallback, siteDefinition.languageEmbedding]);

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={() => {}}
			validationSchema={showManagedLanguages ? siteDefinitionLanguageValidationSchema(languages, systemLanguages) : undefined}
			validateOnMount
			validateOnChange
			validateOnBlur
			enableReinitialize
		>
			{({ resetForm, validateForm, initialValues: values }) => (
				<SiteLanguageSection
					languages={languages}
					resetForm={() => {
						resetForm();
						void validateForm(values);
					}}
				/>
			)}
		</Formik>
	);
};

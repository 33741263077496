import { useMemo } from 'react';
import { SiteInfo, SiteJobViewModel } from '../data/createSiteTypes';
import { SitesAndJobsOptions, useSitesAndJobsList } from './useSitesAndJobsList';
import { useSiteFilterContext } from '../components/SiteFilterContext';
import { filterSiteByType } from 'features/sites/utils/filterSiteByType';

export const useSitesAndJobsListWithSiteTypeWrapper = (
	options: SitesAndJobsOptions = { sortSitesBy: 'name' }
): {
	jobs: SiteJobViewModel[];
	sites: SiteInfo[];
	refetch: () => void;
	loading: boolean;
} => {
	const { siteType } = useSiteFilterContext();
	const { jobs, sites, loading, refetch } = useSitesAndJobsList(options);

	const filteredSites = useMemo(() => {
		return filterSiteByType(sites, siteType);
	}, [siteType, sites]);

	return {
		jobs,
		sites: filteredSites,
		refetch,
		loading,
	};
};

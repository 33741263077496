import { useToast } from '@chakra-ui/react';
import { useTranslate } from 'features/common/utils/translateUtils';
import { useCallback, useMemo } from 'react';
import { DICTIONARY } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { usePatch } from 'shared/react-query-wrappers/hooks';
import { SiteResponse } from 'widgets/portfolio/models';
import { useCalculateDefaultLanguage } from 'widgets/site-settings-update-form/hooks/use-calculate-default-language';
import {
	EditSiteInput,
	SiteSettingsViewModel,
	mapSiteSettingsViewModelToEditSiteInput,
	onIsSubmittingChange,
	onSiteSaved,
} from 'widgets/site-settings-update-form/models';
import { SiteSettingsFormProps } from 'widgets/site-settings-update-form/ui';

/**
 * @deprecated Update is blocked for EA
 */
export const useGeneralSectionForm = ({
	id,
	name,
	displayName,
	defaultLanguage,
	description,
	homePageId,
	languageEmbedding,
	itemLanguageFallback,
	fieldLanguageFallback,
	collectionId,
	xmAppsLanguages,
	hostname,
	targetHostname,
	linkableSite,
}: SiteSettingsFormProps) => {
	const toast = useToast();
	const t = useTranslate();
	const xmAppsDefaultLanguage = useCalculateDefaultLanguage(xmAppsLanguages, defaultLanguage);
	const initialValues: SiteSettingsViewModel = useMemo(() => {
		return {
			displayName: displayName || name,
			description,
			homePageId,
			language: xmAppsDefaultLanguage?.value,
			languageEmbedding: languageEmbedding ?? false,
			itemLanguageFallback: itemLanguageFallback ?? false,
			fieldLanguageFallback: fieldLanguageFallback ?? false,
			hostname,
			targetHostname,
			linkableSite,
		};
	}, [
		name,
		displayName,
		description,
		fieldLanguageFallback,
		homePageId,
		hostname,
		itemLanguageFallback,
		languageEmbedding,
		linkableSite,
		targetHostname,
		xmAppsDefaultLanguage?.value,
	]);

	const { mutateAsync } = usePatch<SiteResponse, EditSiteInput>({
		queryKey: { url: ENDPOINTS.SITE, params: { id } },
		refetchQueriesByKey: [
			{ url: ENDPOINTS.COLLECTION_SITES, params: { collectionId } },
			{ url: ENDPOINTS.SITE, params: { id } },
			{ url: ENDPOINTS.JOBS },
		],
	});

	const submit = useCallback(
		async (values: SiteSettingsViewModel) => {
			const input: EditSiteInput = { ...mapSiteSettingsViewModelToEditSiteInput(values) };

			try {
				onIsSubmittingChange(true);
				await mutateAsync(input);
				onSiteSaved(true);
				onIsSubmittingChange(false);
				toast({ description: t(DICTIONARY.SITE_UPDATED_MESSAGE), status: 'success' });
			} catch (error) {
				onSiteSaved(false);
				onIsSubmittingChange(false);
			}
		},
		[mutateAsync, t, toast]
	);

	return {
		initialValues,
		submit,
	};
};

import { GetTokenSilentlyOptions, useAuth0 } from '@auth0/auth0-react';
import { Button, Text, useToast } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { DICTIONARY } from 'shared/constants';
import { useCallback, useMemo } from 'react';
import { isAuth0Error } from '../utils/errorUtils';
import { useTranslate } from '../utils/translateUtils';
import { SitecoreUser } from './SitecoreUser';

/**
 * Ensures that getAccessTokenSilently always includes organization and tenant_id parameters (if user contains one or both of those claims).
 */
export function useAuthWithClaims() {
	const auth0 = useAuth0<SitecoreUser>();
	const toast = useToast();
	const t = useTranslate();
	const getAccessTokenSilently = useCallback(
		(options?: GetTokenSilentlyOptions): Promise<string> => {
			return auth0
				.getAccessTokenSilently({
					authorizationParams: {
						tenant_name: auth0.user?.['https://auth.sitecorecloud.io/claims/tenant_name'],
						organization_id: auth0.user?.['https://auth.sitecorecloud.io/claims/org_id'],
					},
					...options,
				})
				.catch(async (error) => {
					// Likely something wrong with org or tenant id.
					// Either organization/tenant doesn't exist, or tenant doesn't belong to specified org.
					// Can also be that user doesn't have access to org/tenant.
					// There is also a few other reasons this can trigger like audience and scope.

					if (error.error === 'missing_refresh_token' || error.error === 'invalid_grant') {
						const loginSilently = await auth0.getAccessTokenSilently();

						return new Promise(() => {
							return loginSilently;
						});
					}

					return new Promise(() => {
						toast({
							status: 'error',
							title: t(DICTIONARY.FAILED_TO_AUTHORIZE),
							description: (
								<>
									<Text wordBreak="break-word">
										{/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */}
										{isAuth0Error(error) ? error.error_description : error?.message ?? error?.toString()}
									</Text>
									<Button
										variant="link"
										onClick={() => {
											void auth0.loginWithRedirect();
										}}
									>
										<T _str={DICTIONARY.LOGIN_AGAIN} />
									</Button>
								</>
							),
						});
					});
				});
		},
		[auth0, t, toast]
	);

	const authWithClaims = useMemo(
		() => ({
			...auth0,
			getAccessTokenSilently: getAccessTokenSilently,
		}),
		[auth0, getAccessTokenSilently]
	);

	return authWithClaims;
}

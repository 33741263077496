/* eslint-disable */
import { Checkbox, Flex, IconButton, Menu, MenuButton, MenuItem, MenuList, Skeleton, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { mdiDotsHorizontal, mdiTriangle, mdiTriangleSmallDown } from '@mdi/js';
import { Icon } from 'entities/icon/ui';
import React from 'react';
import { Hooks, usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import { DataTableRowAction } from '.';
import { Pagination } from '../Pagination';
import { DataTableColumn, DataTableProps, DataTableRowActionCallback } from './model';

/**
 * Internal function for generating the rowAction menu column
 */
const buildRowActionHook = (rowActions: DataTableRowAction[] | DataTableRowActionCallback) => {
	return (hooks: Hooks) => {
		hooks.visibleColumns.push((columns) => [
			...columns,
			{
				id: 'rowActions',
				width: '1%',
				disableSortBy: true,
				Cell: ({ row }) => {
					const realRowActions = typeof rowActions === 'function' ? rowActions(row) : rowActions;
					return (
						<Menu placement="bottom-end">
							<MenuButton as={IconButton} size="sm" icon={<Icon path={mdiDotsHorizontal} />}></MenuButton>
							<MenuList>
								{realRowActions.map((ra) => (
									<MenuItem
										key={ra.id}
										onClick={(e) => ra.onClick(e, row)}
										{...(ra['data-behavior-analytics-id'] && {
											'data-behavior-analytics-id': ra['data-behavior-analytics-id'],
										})}
									>
										{ra.label}
									</MenuItem>
								))}
							</MenuList>
						</Menu>
					);
				},
			},
		]);
	};
};

/**
 * Internal function for generating the checkbox selection column
 */
const selectableColumnHook = (hooks: Hooks) => {
	hooks.visibleColumns.push((columns) => [
		{
			id: 'selection',
			width: '1%',
			// The header can use the table's getToggleAllRowsSelectedProps method
			// to render a checkbox
			Header: ({ getToggleAllRowsSelectedProps }) => {
				const checkboxProps = getToggleAllRowsSelectedProps();
				return <Checkbox isChecked={checkboxProps.checked} isIndeterminate={checkboxProps.indeterminate} {...checkboxProps} />;
			},
			// The cell can use the individual row's getToggleRowSelectedProps method
			// to the render a checkbox
			Cell: ({ row }) => {
				const checkboxProps = row.getToggleRowSelectedProps();
				return <Checkbox isChecked={checkboxProps.checked} isIndeterminate={checkboxProps.indeterminate} {...checkboxProps} />;
			},
		} as DataTableColumn,
		...columns,
	]);
};

const emptyHook = () => {
	return;
};

/**
 * Sitecore UI DataTable component
 */
export const DataTable: React.FC<DataTableProps> = ({
	data,
	height,
	width,
	minWidth,
	maxWidth,
	stickyHeader,
	columns,
	isLoading,
	totalItems = 0,
	variant,
	selectable,
	pagination,
	page,
	pageSize = 10,
	sortBy,
	rowActions,
	onChange,
	autoSort = true,
	theaderProps,
	paginationProps,
	'data-behavior-analytics-feature': dataBehaviorAnalyticsFeature,
}) => {
	const totalPages = React.useMemo(() => {
		return Math.ceil(totalItems / pageSize);
	}, [totalItems, pageSize]);

	const initialState = React.useMemo(() => {
		if (!page) return;
		return {
			pageIndex: page - 1,
			sortBy,
		};
	}, [page, sortBy]);

	const {
		state: { pageIndex, sortBy: sb },
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page: p,
		gotoPage,
	} = useTable(
		{
			columns,
			data,
			initialState,
			manualSortBy: autoSort,
			manualPagination: true,
			pageCount: totalPages,
		},
		useSortBy,
		usePagination,
		selectable ? useRowSelect : emptyHook,
		selectable ? selectableColumnHook : emptyHook,
		rowActions ? buildRowActionHook(rowActions) : emptyHook
	);

	React.useEffect(() => {
		if (!onChange || typeof onChange !== 'function') return;

		const newPage = pageIndex + 1;

		if ((!page || page === newPage) && (!sortBy || sb === sortBy)) return;
		onChange({
			page: newPage,
			sortBy: sb,
		});
	}, [pageIndex, page, sb, sortBy, onChange]);

	const loadingRows = React.useMemo(() => {
		return Array.apply(null, Array(pageSize)).map((r, i) => (
			<Tr key={i}>
				{headerGroups[0].headers.map((c, ci) => (
					<Td key={`${i}_${ci}`} style={{ width: c.width }}>
						<Skeleton
							css={{
								width: '100%',
								height: rowActions && rowActions.length ? '32px' : '20px',
							}}
						/>
					</Td>
				))}
			</Tr>
		));
	}, [pageSize, headerGroups, rowActions]);

	const tHeadProps = React.useMemo(() => {
		return stickyHeader
			? {
					zIndex: 2,
					position: 'sticky' as any,
					top: 0,
					backgroundColor: 'chakra-body-bg',
					...theaderProps,
			  }
			: { ...theaderProps };
	}, [stickyHeader]);

	return (
		<React.Fragment>
			<Table
				{...getTableProps()}
				size="md"
				variant={variant}
				mb={3}
				height={height}
				width={width}
				minWidth={minWidth}
				maxWidth={maxWidth}
				data-behavior-analytics-feature={dataBehaviorAnalyticsFeature}
			>
				<Thead {...tHeadProps}>
					{headerGroups.map((headerGroup) => (
						<Tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => {
								return (
									<Th
										fontSize="sm"
										style={{ width: column.width }}
										{...column.getHeaderProps(
											column.getSortByToggleProps({
												title: column.disableSortBy ? `${column.Header}` : `Sort by ${column.Header}`,
											})
										)}
										isNumeric={column.isNumeric}
									>
										<Flex align="center" justify={column.isNumeric ? 'end' : 'start'}>
											{column.render('Header')}
											{column.isSorted ? (
												column.isSortedDesc ? (
													<Icon path={mdiTriangleSmallDown} boxSize="17" aria-label="sorted descending" />
												) : (
													<Icon path={mdiTriangle} boxSize="17" aria-label="sorted ascending" />
												)
											) : null}
										</Flex>
									</Th>
								);
							})}
						</Tr>
					))}
				</Thead>
				<Tbody {...getTableBodyProps()}>
					{isLoading
						? loadingRows
						: p.map((row) => {
								prepareRow(row);
								return (
									<Tr {...row.getRowProps()}>
										{row.cells.map((cell) => (
											<Td {...cell.getCellProps()} style={{ width: cell.column.width }} isNumeric={cell.column.isNumeric}>
												{cell.render('Cell')}
											</Td>
										))}
									</Tr>
								);
						  })}
				</Tbody>
			</Table>

			{pagination !== 'hidden' && (
				<Flex justify="right" data-testid="dataTable_pagination">
					<Pagination
						variant={pagination}
						page={pageIndex + 1}
						totalItems={totalItems}
						pageSize={pageSize}
						onChange={(p) => {
							gotoPage(p - 1);
						}}
						{...paginationProps}
					></Pagination>
				</Flex>
			)}
		</React.Fragment>
	);
};

DataTable.displayName = 'DataTable';

export default DataTable;

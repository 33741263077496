import { useDisclosure, useToast } from '@chakra-ui/react';
import { useTranslate } from 'features/common/utils/translateUtils';
import { useCallback, useRef } from 'react';
import { COMMON_REFETCH_INTERVAL, DICTIONARY } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { usePostWithJob } from 'shared/react-query-wrappers/hooks';
import { siteRenameMetaNameValidationSchema } from 'shared/validations';
import { RenamePayload, UseRenameSiteProps } from 'widgets/site-settings-update-form/models';

export const useRenameSystemName = ({ value, siteId, collectionId }: UseRenameSiteProps) => {
	const textValue = useRef<string>(value);
	const toast = useToast();
	const t = useTranslate();
	const { isOpen, onOpen, onClose } = useDisclosure();

	const { mutateAsync, isPending } = usePostWithJob<RenamePayload>(
		{
			queryKey: { url: ENDPOINTS.SITE_RENAME, params: { siteId } },
			refetchQueriesByKey: [
				{ url: ENDPOINTS.SITE, params: { id: siteId }, shouldWait: true },
				{ url: ENDPOINTS.COLLECTION_SITES, params: { collectionId } },
				{ url: ENDPOINTS.SITES },
				{ url: ENDPOINTS.JOBS },
			],
		},
		COMMON_REFETCH_INTERVAL
	);

	const handleRename = useCallback(
		(values: { name: string }) => {
			if (values.name.toLowerCase() === value.toLowerCase()) {
				onClose();

				return;
			}

			textValue.current = values.name;

			try {
				void mutateAsync({ name: values.name });
			} catch (error) {
				toast({
					status: 'error',
					description: t(DICTIONARY.SITE_ERROR_SYSTEM_NAME),
				});
			}

			onClose();
		},
		[mutateAsync, onClose, t, toast, value]
	);

	return {
		textValue,
		isOpen,
		onOpen,
		onClose,
		mutateAsync,
		isPending,
		siteRenameMetaNameValidationSchema,
		handleRename,
	};
};

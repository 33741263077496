import { useToast } from '@chakra-ui/react';
import { useTranslate } from 'features/common/utils/translateUtils';
import { useCallback, useRef } from 'react';
import { COMMON_REFETCH_INTERVAL, DICTIONARY } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { usePostWithJob } from 'shared/react-query-wrappers/hooks';
import { collectionRenameSystemNameValidationSchema } from 'shared/validations';
import { UseRenameSystemNamePayload, UseRenameSystemNameProps } from '../models/use-rename-system-name-model';

export const useRenameCollectionSystemName = ({ collectionId, value }: UseRenameSystemNameProps) => {
	const t = useTranslate();
	const toast = useToast();
	const newSystemNameRef = useRef<string>(value);
	const { mutateAsync, isPending } = usePostWithJob<UseRenameSystemNamePayload>(
		{
			queryKey: { url: ENDPOINTS.COLLECTION_RENAME, params: { collectionId } },
			refetchQueriesByKey: [
				{ url: ENDPOINTS.COLLECTION, params: { collectionId }, shouldWait: true },
				{ url: ENDPOINTS.COLLECTIONS },
				{ url: ENDPOINTS.JOBS },
			],
		},
		COMMON_REFETCH_INTERVAL
	);

	const handleRename = useCallback(
		async (values: { name: string }, closeCallback: () => void) => {
			if (values.name.toLowerCase() === value.toLowerCase()) {
				closeCallback();

				return;
			}

			newSystemNameRef.current = values.name;

			try {
				void mutateAsync({ name: values.name });
			} catch (error) {
				toast({
					status: 'error',
					description: t(DICTIONARY.COLLECTION_SYSTEM_NAME_ERROR),
				});
			}

			closeCallback();
		},
		[mutateAsync, t, toast, value]
	);

	return {
		newSystemNameRef,
		isPending,
		handleRename,
		collectionRenameSystemNameValidationSchema,
	};
};

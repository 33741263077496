import { Flex, FlexProps, Skeleton, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';

type SectionDataRecordProps = {
	header: string;
	value: string;
	isLoading?: boolean;
};

export const SectionDataRecord: FC<SectionDataRecordProps & FlexProps> = ({ header, value, isLoading = false, ...props }) => {
	return (
		<Flex gap="1" direction="column" {...props} data-testid={`details-property-${header}`}>
			<Text className="section-data-heading" fontWeight="medium" fontSize="sm" color="neutral-fg">
				<T _str={header} />
			</Text>
			<Skeleton height="5" isLoaded={!isLoading}>
				<Text className="section-data-value">{value}</Text>
			</Skeleton>
		</Flex>
	);
};

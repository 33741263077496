import { OnChangeThumbnailProxyProps } from 'features/thumbnail/models';
import { FormikProps } from 'formik';
import { useCallback, useRef } from 'react';
import { SITE_GENERAL_SETTINGS_FIELDS_EVENT, SITE_SETTINGS_FORM_EVENT_NAME } from 'shared/constants';
import { useEventListener } from 'usehooks-ts';
import {
	onSiteGeneralSettingsTouched,
	setSiteGeneralFieldValue,
	SiteGeneralFieldsEvent,
	SiteGeneralSectionEvent,
	SiteGeneralViewModel,
} from 'widgets/site-settings-update-form/models';

export const useSiteSettingsGeneralSectionBody = (
	props: Pick<FormikProps<SiteGeneralViewModel>, 'handleChange' | 'submitForm' | 'resetForm' | 'initialValues' | 'values'>
) => {
	const { handleChange, submitForm, resetForm, initialValues, values } = props;
	const documentRef = useRef<Document>(document);

	const onButtonsClick = useCallback(
		(event: CustomEvent<SiteGeneralSectionEvent>) => {
			const { type } = event.detail;

			if (type === 'submit') {
				void submitForm();
			} else if (type === 'reset') {
				resetForm();
			}
		},
		[resetForm, submitForm]
	);

	const onChangeProxy: typeof handleChange = useCallback(
		(event: React.ChangeEvent<SiteGeneralFieldsEvent>) => {
			handleChange(event);

			const touchedFieldName = event.target.name;
			const initialValue = initialValues[touchedFieldName];
			const newValue = event.target.value;
			const isDirty = initialValue !== newValue;

			if (isDirty) {
				onSiteGeneralSettingsTouched(isDirty);

				return;
			}

			const keys = Object.keys(initialValues) as Array<keyof SiteGeneralViewModel>;

			for (const key of keys) {
				if (key === touchedFieldName) continue;

				const initialFieldValue = initialValues[key];
				const fieldValue = values[key] || '';
				const isFieldDirty = initialFieldValue !== fieldValue;

				if (isFieldDirty) {
					onSiteGeneralSettingsTouched(true);

					return;
				}
			}

			onSiteGeneralSettingsTouched(false);
		},
		[handleChange, initialValues, values]
	);

	const onHostThumbnailFieldsEvent = useCallback(
		(event: CustomEvent<SiteGeneralFieldsEvent>) => {
			onChangeProxy({
				target: {
					name: event.detail.name,
					value: event.detail.value,
				},
			} as React.ChangeEvent<any>);
		},
		[onChangeProxy]
	);

	const onChangeThumbnailProxy: OnChangeThumbnailProxyProps = (value, fieldName) => {
		setSiteGeneralFieldValue(fieldName, typeof value === 'string' ? value : value.value);
	};

	useEventListener(SITE_SETTINGS_FORM_EVENT_NAME, onButtonsClick, documentRef);

	useEventListener(SITE_GENERAL_SETTINGS_FIELDS_EVENT, onHostThumbnailFieldsEvent, documentRef);

	return {
		onChangeProxy,
		onChangeThumbnailProxy,
	};
};

import { MenuItem, Tooltip } from '@chakra-ui/react';
import { mdiFormTextbox } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { useTranslate } from 'features/common/utils/translateUtils';
import { RenameSiteModal } from 'features/site-actions-menu/ui';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { Access } from 'shared/models';

type RenameSiteMenuItemProps = {
	collectionId: string;
	siteName: string;
	siteId: string;
	siteAccess: Access;
};

export const RenameSiteMenuItem: FC<RenameSiteMenuItemProps> = ({ collectionId, siteName, siteId, siteAccess }) => {
	const t = useTranslate();

	return (
		<Tooltip label={!siteAccess.canRename ? t(DICTIONARY.ACCESS_DENIED.SITE_RENAME) : ''} shouldWrapChildren>
			<RenameSiteModal siteId={siteId} siteName={siteName} collectionId={collectionId}>
				<MenuItem icon={<Icon path={mdiFormTextbox} />} isDisabled={!siteAccess.canRename} data-testid="renameSiteMenuItem">
					<T _str={DICTIONARY.RENAME} />
				</MenuItem>
			</RenameSiteModal>
		</Tooltip>
	);
};

import {
	Button,
	ButtonGroup,
	Flex,
	FormControl,
	FormLabel,
	Input,
	ListItem,
	ModalBody,
	ModalFooter,
	Stack,
	Text,
	UnorderedList,
	UseDisclosureReturn,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { useRemoveSiteDataEA } from 'features/remove-site/hooks';
import { RemoveSitePropsEA } from 'features/remove-site/models';
import { FC, PropsWithChildren } from 'react';
import { DICTIONARY } from 'shared/constants';

/**
 * @deprecated Update is blocked for EA
 */
export const RemoveSiteModalBodyEA: FC<
	PropsWithChildren<RemoveSitePropsEA> & Pick<UseDisclosureReturn, 'onClose'> & { setIsLoading: React.Dispatch<React.SetStateAction<boolean>> }
> = ({ setIsLoading, onClose, ...props }) => {
	const { isPending, isRemoveDisabled, setText, handleRemove } = useRemoveSiteDataEA(props, setIsLoading);
	const { name, hasMultiple } = props;

	return (
		<>
			<ModalBody>
				<Flex direction="column" gap="6">
					<Text style={{ wordWrap: 'normal' }}>
						<T
							_str={DICTIONARY.DELETE_SITE_MESSAGE}
							name={
								<Text as="span" variant="strong">
									{name}
								</Text>
							}
						/>
					</Text>
					<Stack gap={1}>
						<Text variant="strong">
							<T _str={DICTIONARY.DELETE_SITE_BODY_TEXT.TITLE} />
						</Text>
						<UnorderedList pl={3}>
							{!hasMultiple && (
								<ListItem>
									<Text>
										<T _str={DICTIONARY.DELETE_SITE_BODY_TEXT.DELETE_EVERYTHING} />
									</Text>
								</ListItem>
							)}
							<ListItem>
								<Text>
									<T _str={DICTIONARY.DELETE_SITE_BODY_TEXT.DELETE_DEFINITION} />
								</Text>
							</ListItem>
						</UnorderedList>
					</Stack>
					<FormControl isRequired>
						<FormLabel>
							<T _str={DICTIONARY.DELETE_SITE_CONFIRMATION_MESSAGE} name={name} />
						</FormLabel>
						<Input onChange={(e) => setText(e.target.value)} data-testid="remove-site-input-name" />
					</FormControl>
				</Flex>
			</ModalBody>
			<ModalFooter>
				<ButtonGroup>
					<Button data-testid="close-btn" variant="ghost" onClick={onClose} isDisabled={isPending}>
						<T _str={DICTIONARY.CANCEL} />
					</Button>
					<Button
						data-testid="remove-btn"
						colorScheme="danger"
						isLoading={isPending}
						loadingText={<T _str={DICTIONARY.DELETING} />}
						isDisabled={isRemoveDisabled || isPending}
						onClick={() => void handleRemove()}
					>
						<T _str={DICTIONARY.DELETE} />
					</Button>
				</ButtonGroup>
			</ModalFooter>
		</>
	);
};

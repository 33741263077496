/* eslint-disable */

import { isStyleProp } from '@chakra-ui/styled-system';
import * as React from 'react';
import { ReactElement } from 'react';

export type ReactChildArray = ReturnType<typeof React.Children.toArray>;
export const flattenChildren = (children: React.ReactNode): ReactChildArray => {
	const childrenArray = React.Children.toArray(children);
	return childrenArray.reduce((flatChildren: ReactChildArray, child) => {
		if ((child as React.ReactElement<any>).type === React.Fragment) {
			return flatChildren.concat(flattenChildren((child as React.ReactElement<any>).props.children));
		}
		flatChildren.push(child);
		return flatChildren;
	}, []);
};

export const convertChildrenArrayToObj = (childrenArray: any[]) => {
	const childrenKeyValueOnj = {} as { [key: string]: React.ReactElement };
	childrenArray.forEach((child) => {
		const displayName = child.type.displayName;
		if (displayName) {
			childrenKeyValueOnj[displayName] = child as React.ReactElement;
		}
	});
	return childrenKeyValueOnj;
};

export const overrideComponentProps = (component: React.ReactElement, propsToOverride: any): React.ReactElement => {
	return React.cloneElement(component, {
		...component.props,
		...propsToOverride,
	});
};

export const recursiveChildrenMap = (children: ReactElement[], fn: (child: ReactElement) => ReactElement) => {
	return React.Children.map(children, (child: ReactElement) => {
		if (!React.isValidElement(child)) {
			return child;
		}

		child = child as ReactElement;
		if (child.props && child.props.children) {
			child = React.cloneElement(child, {
				children: recursiveChildrenMap(child.props.children, fn),
			});
		}

		return fn(child);
	});
};

export const separateAndMergeProps = (props: { [key: string]: any }, styles: { [key: string]: any }) => {
	const initialObject: {
		styleProps: { [key: string]: any };
		nonStyleProps: { [key: string]: any };
	} = {
		styleProps: { ...styles },
		nonStyleProps: {},
	};

	return Object.keys(props).reduce(
		(current, propName: string) =>
			isStyleProp(propName)
				? {
						...current,
						styleProps: { ...current.styleProps, [propName]: props[propName] },
				  }
				: {
						...current,
						nonStyleProps: {
							...current.nonStyleProps,
							[propName]: props[propName],
						},
				  },
		initialObject
	);
};

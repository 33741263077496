import {
	Button,
	ButtonGroup,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC, Fragment, createContext, useContext, useMemo } from 'react';
import { DICTIONARY } from 'shared/constants';
import { useRemoveSite } from '../utils/useRemoveSite';

export type RemoveSiteContextType = {
	removeSite: (siteName: string) => void;
};

export const removeSiteDefaultValue: RemoveSiteContextType = {
	removeSite: () => {},
};

export const RemoveSiteContext = createContext<RemoveSiteContextType>(removeSiteDefaultValue);

export const RemoveSiteProvider: FC<React.PropsWithChildren> = ({ children }) => {
	const { isOpen, onClose, onOpen } = useDisclosure();

	const { siteName, checkSiteName, handleChange, isAbleToRemove, openRemoveSiteModal, removeSite } = useRemoveSite(onClose, onOpen);

	const value = useMemo(() => {
		return {
			removeSite: openRemoveSiteModal,
		};
	}, [openRemoveSiteModal]);

	return (
		<Fragment>
			<RemoveSiteContext.Provider value={value}>{children}</RemoveSiteContext.Provider>
			<Modal isOpen={isOpen} onClose={onClose} size="md" blockScrollOnMount={false}>
				<ModalOverlay>
					<ModalContent>
						<ModalHeader>
							<T _str={DICTIONARY.DELETE} />
						</ModalHeader>
						<ModalCloseButton />
						<ModalBody alignItems="left" display="flex" flexDirection="column" gap="4">
							<Text>
								<T _str={DICTIONARY.REMOVE_SITE_CONFIRMATION_MeSSAGE} />
							</Text>
							<Text fontWeight="medium">
								<T _str={`${DICTIONARY.REMOVE_SITE_CONFIRMATION_ACTION_TEXT[0]} `} />
								<Text as="span" color="danger">
									{siteName}
								</Text>
								<T _str={` ${DICTIONARY.REMOVE_SITE_CONFIRMATION_ACTION_TEXT[1]}`} />
							</Text>
							<Input value={checkSiteName} onChange={handleChange} />
						</ModalBody>
						<ModalFooter>
							<ButtonGroup>
								<Button onClick={onClose} variant="ghost">
									<T _str={DICTIONARY.CANCEL} />
								</Button>
								<Button id="deleteBtn" colorScheme="danger" onClick={() => void removeSite()} isDisabled={!isAbleToRemove}>
									<T _str={DICTIONARY.DELETE} />
								</Button>
							</ButtonGroup>
						</ModalFooter>
					</ModalContent>
				</ModalOverlay>
			</Modal>
		</Fragment>
	);
};

export const useRemoveSiteContext = () => {
	return useContext(RemoveSiteContext);
};

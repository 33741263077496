import { useMemo } from 'react';
import { ENDPOINTS } from 'shared/endpoints';
import { LanguageItem, LanguageResponse } from 'shared/languages/models';
import { useSimpleGet } from 'shared/react-query-wrappers/hooks';

export const useCreateLanguageData = () => {
	const { data: systemLanguages, isLoading: isSystemLanguagesLoading } = useSimpleGet<LanguageResponse[]>(ENDPOINTS.LANGUAGES);
	const { data: languages, isLoading: isLanguagesLoading } = useSimpleGet<LanguageResponse[]>(ENDPOINTS.LANGUAGES_SUPPORTED);

	const createLanguagesOptions = useMemo<LanguageItem[]>(() => {
		if (isLanguagesLoading || isSystemLanguagesLoading || !languages || !systemLanguages) {
			return [];
		}

		const lagsList = [...languages];

		systemLanguages.forEach((language) => {
			const lang = lagsList.find((item) => item.name === language.name);

			if (lang) {
				lagsList.splice(lagsList.indexOf(lang), 1);
			}
		});

		return lagsList.map((language) => ({ label: language.englishName, value: language.name }));
	}, [languages, systemLanguages, isLanguagesLoading, isSystemLanguagesLoading]);

	const fallbackLanguagesOptions = useMemo<LanguageItem[]>(() => {
		return systemLanguages?.map((language) => ({ label: language.englishName, value: language.name })) ?? [];
	}, [systemLanguages]);

	return { createLanguagesOptions, fallbackLanguagesOptions };
};

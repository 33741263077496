import { Formik } from 'formik';
import { FC, useMemo } from 'react';
import { SiteResponse } from 'shared/models';
import { siteSettingsValidationSchema } from 'shared/validations';
import { SettingsSectionBody } from 'widgets/site-settings-update-form/ui/settings-section-body';

/**
 * @deprecated Update is blocked for EA
 */
export const SettingsSectionForm: FC<SiteResponse> = ({ hostname, targetHostname, linkableSite }) => {
	const initialValues = useMemo(() => {
		return {
			linkableSite,
			hostname,
			targetHostname,
		};
	}, [hostname, linkableSite, targetHostname]);

	return (
		<Formik initialValues={initialValues} onSubmit={() => {}} validationSchema={siteSettingsValidationSchema} enableReinitialize>
			{({ resetForm, submitForm }) => <SettingsSectionBody resetForm={resetForm} submitForm={submitForm} />}
		</Formik>
	);
};

import { Box } from '@chakra-ui/react';
import * as React from 'react';

import { LayoutMainProps } from '../models';

export const LayoutMain: React.FC<LayoutMainProps> = ({ children, disablePadding, background, inset, p, ...rest }) => {
	return (
		<Box
			as="main"
			order={2}
			p={disablePadding ? 0 : p}
			background={inset ? 'gray.50' : background}
			shadow={inset ? 'inner' : 'stroke'}
			flexGrow={1}
			flexShrink={1}
			overflow="auto"
			{...rest}
		>
			{children}
		</Box>
	);
};

/**

 * Sitecore UI LayoutMain component default props

 */

LayoutMain.defaultProps = {
	disablePadding: false,
	background: 'white',
	inset: false,
	p: 6,
};

/**

 * Sitecore UI LayoutMain components display names

 */

LayoutMain.displayName = 'LayoutMain';

import { useTranslate } from 'features/common/utils/translateUtils';
import { useCallback } from 'react';
import { VALIDATIONS } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { useGet } from 'shared/react-query-wrappers/hooks';
import { CollectionResponse, ValidateOptions, ValidationErrorUniquiness } from '../models';

export const useCheckCollectionSystemNameUniqueness = ({ collectionName, formKey }: ValidateOptions): ValidationErrorUniquiness => {
	const t = useTranslate();
	const { data, isLoading } = useGet<CollectionResponse[]>({ queryKey: { url: ENDPOINTS.COLLECTIONS } });

	return useCallback(
		async (value: string) => {
			if (
				!isLoading &&
				data?.length &&
				data.findIndex((collection: CollectionResponse) => collection.name.toLowerCase() === value.toLowerCase()) !== -1 &&
				value !== collectionName
			) {
				return { [formKey]: t(VALIDATIONS.COLLECTION_SYSTEM_NAME_ALREADY_EXISTS) };
			}
		},
		[data, formKey, isLoading, collectionName, t]
	);
};

export const useCheckCollectionMetaNameUniqueness = ({ collectionName, formKey }: ValidateOptions): ValidationErrorUniquiness => {
	const t = useTranslate();
	const { data, isLoading } = useGet<CollectionResponse[]>({ queryKey: { url: ENDPOINTS.COLLECTIONS } });

	return useCallback(
		async (value: string) => {
			if (
				!isLoading &&
				data?.length &&
				data.findIndex((collection: CollectionResponse) => collection.displayName.toLowerCase() === value.toLowerCase()) !== -1 &&
				value !== collectionName &&
				value !== ''
			) {
				return { [formKey]: t(VALIDATIONS.COLLECTION_META_NAME_ALREADY_EXISTS) };
			}
		},
		[data, formKey, isLoading, collectionName, t]
	);
};

import { useMutation } from '@tanstack/react-query';
import { useReactQueryWrappersProvider } from 'app/react-query-wrappers-provider/hooks';
import { ENDPOINTS } from 'shared/endpoints';
import { useQueryKey } from 'shared/react-query-wrappers/hooks/use-query-key';
import { useRefetchQueriesByKey } from 'shared/react-query-wrappers/hooks/use-refetch-queries-by-key';
import { CommonQueryKeyParams, JobStatus, QueryProps } from 'shared/react-query-wrappers/models';
import { disableGlobalErrorHandler } from 'shared/react-query-wrappers/utils/disable-global-error-handler';

/**
 * Make POST request to the server.
 */
export const usePostWithJob = <TInput>(props: QueryProps, jobCheckInterval: number) => {
	const { queryKey, ignoreGlobalErrorHandler, refetchQueriesByKey } = props;
	const { axios, queryClient } = useReactQueryWrappersProvider();
	const { key, calculateKey } = useQueryKey(queryKey);
	const onSuccess = useRefetchQueriesByKey(refetchQueriesByKey, queryClient, calculateKey);

	const queryResult = useMutation({
		mutationKey: key.queryKey,
		mutationFn: async (input: TInput) => {
			let shouldCheck = true;
			const result = await disableGlobalErrorHandler(axios.post(key.url, input), ignoreGlobalErrorHandler);

			if (!result || !result.data) {
				return new Promise((resolve) => resolve(false));
			}

			const jobQueryParams = { jobHandle: result.data } as CommonQueryKeyParams;
			const jobStatusKey = calculateKey(ENDPOINTS.JOB_STATUS, jobQueryParams);

			while (shouldCheck) {
				try {
					const jobResult = await disableGlobalErrorHandler<JobStatus>(axios.get<JobStatus>(jobStatusKey.url), true);

					if (jobResult?.data?.done) {
						shouldCheck = false;
					} else {
						await new Promise((resolve) => setTimeout(resolve, jobCheckInterval));
					}
				} catch {
					shouldCheck = false;
				}
			}

			return new Promise((resolve) => resolve(true));
		},
		onSuccess,
	});

	return { ...queryResult };
};

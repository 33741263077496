import { CreateCollectionValues, UseCreateCollectionModalWrapper } from 'features/collections/models';
import { FormikHelpers } from 'formik';
import { useCallback } from 'react';
import { ENDPOINTS } from 'shared/endpoints';
import { usePost } from 'shared/react-query-wrappers/hooks';

export const useCreateCollectionModalWrapper: UseCreateCollectionModalWrapper = (onClose) => {
	const { mutateAsync: createCollection } = usePost({ queryKey: { url: ENDPOINTS.COLLECTIONS }, refetchQueriesByKey: [{ url: ENDPOINTS.JOBS }] });

	const onFormSubmit = useCallback(
		async (values: CreateCollectionValues, { setSubmitting }: FormikHelpers<CreateCollectionValues>) => {
			await createCollection(values);
			setSubmitting(false);
			onClose();
		},
		[createCollection, onClose]
	);

	return { onFormSubmit };
};

import { useToast } from '@chakra-ui/react';
import { QueryClient, useMutation } from '@tanstack/react-query';
import { useReactQueryWrappersProvider } from 'app/react-query-wrappers-provider/hooks';
import config from 'features/common/config/config';
import { useCdpTenant } from 'features/common/tenants/CdpTenantProvider';
import { useTranslate } from 'features/common/utils/translateUtils';
import { PoSCreateResponse } from 'features/point-of-sale/models';
import { useCallback } from 'react';
import { useGetAllPoS } from 'shared/api';
import { DICTIONARY } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { PoSCreateInput } from 'shared/models';

export const useCreatePoSAction = (callback?: (queryClient: QueryClient) => Promise<void>) => {
	const { axios, queryClient } = useReactQueryWrappersProvider();
	const { state, tenant: cdpTenant } = useCdpTenant();
	const { posList } = useGetAllPoS();
	const toast = useToast();
	const t = useTranslate();

	const createPosUrl = cdpTenant ? config.cdp.create_pos.url(cdpTenant?.apiUrl) : '';
	const getAllPosUrl = cdpTenant ? config.cdp.get_all_pos.url(cdpTenant?.apiUrl) : '';

	const handleMutationCall = useCallback(
		async (inputs: PoSCreateInput[]) => {
			if (state !== 'resolved') {
				return;
			}

			try {
				for (const input of inputs) {
					if (posList.findIndex((x) => x.name.toLowerCase() == input.name.toLowerCase()) === -1) {
						const result = await axios.post<PoSCreateResponse>(createPosUrl, input);

						if (result.status !== 200 || result?.data?.result !== 'success') {
							await queryClient.invalidateQueries({ queryKey: [getAllPosUrl] });
							throw new Error();
						}
					}
				}
			} catch {
				toast({ description: t(DICTIONARY.SOMETHING_WENT_WRONG), status: 'error' });
			}

			await queryClient.invalidateQueries({ queryKey: [getAllPosUrl] });
			await queryClient.invalidateQueries({ queryKey: [ENDPOINTS.SITES] });
			await callback?.(queryClient);
		},
		[state, queryClient, getAllPosUrl, posList, axios, createPosUrl, toast, t, callback]
	);

	const { mutateAsync, isPending } = useMutation<void, unknown, PoSCreateInput[]>({
		mutationKey: [createPosUrl],
		mutationFn: handleMutationCall,
	});

	return { createPosIdentifiers: mutateAsync, isPending };
};

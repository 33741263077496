import { Box, Button, Flex, Menu, MenuButton, MenuItemOption, MenuList, MenuOptionGroup, Text, Tooltip } from '@chakra-ui/react';
import { mdiChevronDown } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { useTranslate } from 'features/common/utils/translateUtils';
import { useTypeFilter } from 'features/sites/utils/useTypeFilter';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { useSiteFilterContext } from './SiteFilterContext';

export const TypeFilter: FC = () => {
	const { selectedFilter, onTypeFilterChange, isNonSXASitesExist } = useTypeFilter();
	const { siteCollection } = useSiteFilterContext();
	const t = useTranslate();

	return (
		<Menu closeOnSelect={true}>
			<MenuButton
				data-testid="typeFilter"
				as={Button}
				size="md"
				borderRadius="md"
				variant="outline"
				rightIcon={<Icon path={mdiChevronDown} layerStyle="menuButtonIcon" />}
			>
				<Flex direction="row">
					<Text marginRight={1}>
						<T _str={DICTIONARY.TYPE_COLON} />
					</Text>
					<Text fontWeight="normal">
						<T _str={selectedFilter.text} />
					</Text>
				</Flex>
			</MenuButton>
			<MenuList>
				<MenuOptionGroup type="radio" defaultValue={selectedFilter.type}>
					<MenuItemOption
						data-testid="filter-all"
						aria-selected={selectedFilter.type === 'none'}
						onClick={() => onTypeFilterChange('none')}
						value="none"
					>
						<T _str={DICTIONARY.TYPE_FILTER.ALL} />
					</MenuItemOption>
					<MenuItemOption
						data-testid="filter-sxa"
						aria-selected={selectedFilter.type === 'sxa'}
						onClick={() => onTypeFilterChange('sxa')}
						value="sxa"
					>
						<T _str={DICTIONARY.TYPE_FILTER.SXA_MANAGED} />
					</MenuItemOption>
					<MenuItemOption
						isDisabled={!isNonSXASitesExist || !!siteCollection?.id}
						data-testid="filter-nonsxa"
						aria-selected={selectedFilter.type === 'nonsxa'}
						onClick={() => onTypeFilterChange('nonsxa')}
						value="nonsxa"
					>
						<Tooltip label={t(DICTIONARY.DISABLED_TYPE_FILTER_TOOLTIP)} isDisabled={isNonSXASitesExist && !siteCollection?.id}>
							<Box>
								<T _str={DICTIONARY.TYPE_FILTER.NON_SXA_MANAGED} />
							</Box>
						</Tooltip>
					</MenuItemOption>
				</MenuOptionGroup>
			</MenuList>
		</Menu>
	);
};

import { AxiosError } from 'axios';
import { RefetchIntervalError, RefetchIntervalQuery, RefetchIntervalType } from 'shared/react-query-wrappers/models';

const mapError = (error: AxiosError<unknown, any> | null): RefetchIntervalError | undefined => {
	if (!error) {
		return undefined;
	}

	return {
		code: error?.code,
		reason: error?.message,
		name: error?.name,
		data: error?.response?.data,
		status: error?.response?.status,
		statusText: error?.response?.statusText,
	};
};

export const intervalWrapper = <TData>(query: RefetchIntervalQuery<TData>, interval: RefetchIntervalType<TData>): number | false | undefined => {
	if (typeof interval === 'function') {
		const { data, error } = query.state;

		return interval(data?.data, mapError(error)) ?? false;
	}

	return interval ?? false;
};

import { Flex, MenuDivider, Tag } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { SkeletonRepeater } from 'entities/skeleton-repeater/ui';
import { ClickableSiteCard } from 'features/clickable-site-card/ui';
import { useFeatures } from 'features/common/featureFlags/featureFlags';
import { ExplorerRedirectMenuItem, OpenSiteSettingsMenuItem, PageRedirectMenuItem } from 'features/site-actions-menu/ui';
import { FC } from 'react';
import { DICTIONARY, SITE_CARD_SIZE } from 'shared/constants';
import { OpenInPagesMenuItem } from 'shared/open-in-pages/ui';
import { JssPortfolioBodyProps, SiteViewModel } from '../models';

const JssTag: FC = () => (
	<Tag variant="solid">
		<T _str={DICTIONARY.JSS_SITE_CARD_BADGE} />
	</Tag>
);

export const JssPortfolioBody: FC<JssPortfolioBodyProps> = ({ items, isLoading }) => {
	const { showSitesGrouping } = useFeatures();

	return (
		<Flex minHeight={SITE_CARD_SIZE.HEIGHT} flexWrap="wrap" justify="flex-start" data-testid="portfolio-site-list" gap="4">
			{isLoading ? (
				<SkeletonRepeater borderRadius="6" count={2} width={SITE_CARD_SIZE.WIDTH} height="full" isLoaded={false} />
			) : (
				<>
					{items?.map((site: SiteViewModel) => (
						<ClickableSiteCard {...site} key={site.id} badge={<JssTag />}>
							<OpenSiteSettingsMenuItem siteId={site.id} />
							<MenuDivider />
							{showSitesGrouping ? (
								<OpenInPagesMenuItem hosts={site.hosts} />
							) : (
								<>
									<PageRedirectMenuItem siteName={site.name} />
									<ExplorerRedirectMenuItem siteName={site.name} />
								</>
							)}
						</ClickableSiteCard>
					))}
				</>
			)}
		</Flex>
	);
};

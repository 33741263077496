import { useCallback, useMemo, useRef } from 'react';
import { SITE_DEFINITION_FORM_EVENT_NAME } from 'shared/constants';
import { useEventListener } from 'usehooks-ts';
import { GeneralSectionEvent } from 'widgets/site-definition-update-form/models';
import { SettingsSectionBodyProps } from 'widgets/site-definition-update-form/ui/settings-section-body';

type Option = { label: string; value: string };

export const useSettingsSectionBody = ({ resetForm, submitForm, existingTargetHostnames, allRenderingHosts }: SettingsSectionBodyProps) => {
	const documentRef = useRef<Document>(document);
	const onEventDispatch = useCallback(
		(event: CustomEvent<GeneralSectionEvent>) => {
			const { type } = event.detail;

			if (type === 'reset') {
				resetForm();
			} else if (type === 'submit') {
				void submitForm();
			}
		},
		[resetForm, submitForm]
	);

	useEventListener(SITE_DEFINITION_FORM_EVENT_NAME, onEventDispatch, documentRef);

	const isTargetHostNameAlreadyExists = useCallback(
		(value: string) => existingTargetHostnames.find((siteDef) => siteDef.targetHostName === value),
		[existingTargetHostnames]
	);

	const renderingHostOptions = useMemo(() => {
		let renderings: Option[] = [];

		if (allRenderingHosts) {
			renderings = allRenderingHosts.map((host) => ({
				label: host.name,
				value: host.name,
			}));
		}

		return renderings;
	}, [allRenderingHosts]);

	return {
		isTargetHostNameAlreadyExists,
		renderingHostOptions,
	};
};

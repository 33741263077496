/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { ITranslateParams, t } from '@transifex/native';
import { useCallback } from 'react';

// Transiflex library define all functions with type any. To avoid linting errors, this hook was added.
export const useTranslate = (): ((text: string, params?: ITranslateParams) => string) => {
	return useCallback((text: string, params?: ITranslateParams) => {
		return t(text, params) as string;
	}, []);
};

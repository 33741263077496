import { Button } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { CreateSiteDefinitionButtonProps } from 'features/site-definitions/models';
import { CreateSiteDefinitionModal } from 'features/site-definitions/ui';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';

export const CreateSiteDefinitionButton: FC<CreateSiteDefinitionButtonProps> = ({ canCreate, ...props }) => {
	return (
		<CreateSiteDefinitionModal {...props}>
			<Button width="fit-content" isDisabled={!canCreate} data-testid="site-definition-create-button">
				<T _str={DICTIONARY.CREATE_SITE_DEFINITION} />
			</Button>
		</CreateSiteDefinitionModal>
	);
};

import {
	mdiAccountLockOutline,
	mdiAccountTieOutline,
	mdiChartBoxOutline,
	mdiChartDonut,
	mdiCogOutline,
	mdiFeather,
	mdiImageOutline,
	mdiInboxFullOutline,
	mdiLockOutline,
	mdiPencilRuler,
	mdiPictureInPictureBottomRightOutline,
	mdiPowershell,
	mdiRecycleVariant,
	mdiRoutes,
	mdiShieldLockOutline,
} from '@mdi/js';
import { iconComponents, iconExplorer, iconForms, iconPages } from '@sitecore/blok-theme';
import { useAuthWithClaims } from 'features/common/auth/useAuthWithClaims';
import { analytics, components, explorer, forms, pages } from 'features/common/config/config';
import { useXMTenant } from 'features/common/tenants/xmTenantContext';
import { useMemo } from 'react';
import { ScLaunchpadGrid, ScLaunchpadGroup } from './types';

export const useGrid = (): ScLaunchpadGrid => {
	const { tenant } = useXMTenant();
	const { url, name: tenantName, regionCode, id: tenantId } = tenant;
	const { user } = useAuthWithClaims();
	const organizationId = user?.['https://auth.sitecorecloud.io/claims/org_id'];

	return useMemo(() => {
		return {
			groups: [
				{
					name: 'Build',
					colorScheme: 'purple',
					groupItems: [
						{
							name: 'Pages',
							icon: iconPages,
							url: pages.getUrl(organizationId, tenantName),
						},
						{
							name: 'Components',
							icon: iconComponents,
							url: components.getUrl(organizationId, tenantName),
						},
						{
							name: 'Explorer',
							icon: iconExplorer,
							url: explorer.getUrl(organizationId, tenantName),
						},
						{
							name: 'Experience Editor',
							icon: mdiPencilRuler,
							url: url + '/?sc_mode=edit&sc_resolvelanguage=1',
						},
						{
							name: 'Forms',
							icon: iconForms,
							url: forms.getUrl(organizationId, tenantName),
						},
						{
							name: 'Analytics',
							icon: mdiChartBoxOutline,
							url: analytics.getUrl(organizationId, tenantId, regionCode),
						},
					],
				},
				{
					name: 'Content',
					colorScheme: 'purple',
					groupItems: [
						{
							name: 'Content Editor',
							icon: mdiFeather,
							url: url + '/sitecore/shell/Applications/Content%20Editor.aspx?sc_bw=1',
						},
						{
							name: 'Media Library',
							icon: mdiImageOutline,
							url: url + '/sitecore/shell/Applications/Media/MediaShop.aspx?sc_bw=1',
						},
						{
							name: 'Workbox',
							icon: mdiInboxFullOutline,
							url: url + '/sitecore/shell/Applications/Workbox.aspx?sc_bw=1',
						},
					],
				},
				{
					name: 'Utilities',
					colorScheme: 'neutral',
					groupItems: [
						{
							name: 'Control Panel',
							icon: mdiCogOutline,
							url: url + '/sitecore/client/Applications/ControlPanel.aspx?sc_bw=1',
						},
						{
							name: 'Desktop',
							icon: mdiPictureInPictureBottomRightOutline,
							url: url + '/sitecore/shell/default.aspx',
						},
						{
							name: 'Recycle Bin',
							icon: mdiRecycleVariant,
							url: url + '/sitecore/shell/Applications/Archives/Recycle%20Bin.aspx?sc_bw=1',
						},
						{
							name: 'PowerShell ISE',
							icon: mdiPowershell,
							url: url + '/sitecore/shell/Applications/PowerShell/PowerShellIse?sc_bw=1',
						},
						{
							name: 'PowerShell Reports',
							icon: mdiChartDonut,
							url: url + '/sitecore/shell/Applications/PowerShell/PowerShellReports?sc_bw=1',
						},
					],
				},
				{
					name: 'Access Management',
					colorScheme: 'teal',
					groupItems: [
						{
							name: 'User Manager',
							icon: mdiAccountTieOutline,
							url: url + '/sitecore/shell/Applications/Security/User%20Manager.aspx?sc_bw=1',
						},
						{
							name: 'Security Editor',
							icon: mdiShieldLockOutline,
							url: url + '/sitecore/shell/Applications/Security/Security%20Editor.aspx?sc_bw=1',
						},
						{
							name: 'Access Viewer',
							icon: mdiLockOutline,
							url: url + '/sitecore/shell/Applications/Security/Access%20Viewer.aspx?sc_bw=1',
						},
						{
							name: 'Domain Manager',
							icon: mdiRoutes,
							url: url + '/sitecore/shell/Applications/Security/Domain%20Manager.aspx?sc_bw=1',
						},
						{
							name: 'Role Manager',
							icon: mdiAccountLockOutline,
							url: url + '/sitecore/shell/Applications/Security/Role%20Manager.aspx?sc_bw=1',
						},
					],
				},
			] as ScLaunchpadGroup[],
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tenantName, organizationId, url]);
};

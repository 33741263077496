import { useCallback, useMemo } from 'react';
import type { ItemTreeProps, ItemViewProps } from 'shared/item-tree/models';
import { isSameGuid } from 'shared/utils';

export const useItemView = (props: ItemViewProps & ItemTreeProps) => {
	const { item, selectedIds, setSelectedIds, selectionVariant } = props;

	const color = useMemo(() => {
		if (!item) {
			return undefined;
		}

		if (selectedIds.find((id) => isSameGuid(id, item.id))) {
			return 'primary.100';
		}
	}, [item, selectedIds]);

	const onSelect = useCallback(() => {
		if (!item) {
			return;
		}

		const index = selectedIds.indexOf(item.id);

		if (selectionVariant === 'multiple') {
			if (index >= 0) {
				selectedIds.splice(index, 1);
				setSelectedIds([...selectedIds]);
			} else {
				setSelectedIds([...selectedIds, item.id]);
			}
		} else if (index < 0) {
			setSelectedIds([item.id]);
		}
	}, [item, selectedIds, selectionVariant, setSelectedIds]);

	return {
		color,
		onSelect,
	};
};

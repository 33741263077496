import { Link } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC, Fragment } from 'react';
import { DICTIONARY } from 'shared/constants';
import { SiteResponse } from 'shared/models';
import { ROUTES } from 'shared/routes';

/** @deprecated */
export const PosSitesListEA: FC<{ sitesList: SiteResponse[] }> = ({ sitesList: data }) => {
	return (
		<>
			{data.map((site, index) => (
				<Fragment key={site.id}>
					<Link href={`/${ROUTES.SITE.NAME}/${site.id}/${ROUTES.SITE.ANALYTICS_AND_PERSONALIZATION}`} target="_blank">
						{site.displayName || site.name}
					</Link>
					{index < data.length - 1 && <T _str={`${DICTIONARY.COMMA} `} />}
				</Fragment>
			))}
		</>
	);
};

import {
	Box,
	Button,
	ButtonGroup,
	Divider,
	Flex,
	FormControl,
	FormHelperText,
	FormLabel,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { Select } from 'chakra-react-select';
import { PosPayload } from 'features/pos/utils/types';
import { useSelectPos } from 'features/siteSettings/utils/useSelectPos';
import { FC, useCallback } from 'react';
import { DICTIONARY } from 'shared/constants';

export type Disclosure = {
	isOpen: boolean;
	onOpen: () => void;
	onClose: () => void;
};

export const AssignPosModal: FC<{ disclosure: Disclosure; onSave: (posInfo: PosPayload | undefined) => void }> = ({ disclosure, onSave }) => {
	const { isOpen, onClose } = disclosure;
	const { selectedPos, setSelectedPos, posSelectList, posInfo } = useSelectPos();
	const onSaveClick = useCallback(() => {
		setSelectedPos(null);
		onSave(posInfo);
		onClose();
	}, [onClose, onSave, posInfo, setSelectedPos]);

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} size="lg" blockScrollOnMount={false}>
				<ModalOverlay>
					<ModalContent>
						<ModalHeader>
							<T _str={DICTIONARY.ASSIGN_IDENTIFIER_TO_SITE} />
						</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							<Flex direction="column" gap="6">
								<FormControl>
									<FormLabel htmlFor="pos">
										<T _str={DICTIONARY.ANALYTICS_IDENTIFIER} />
									</FormLabel>
									<Select id="pos" options={posSelectList} value={selectedPos} onChange={(newValue) => setSelectedPos(newValue)} />
									<FormHelperText>
										<T _str={DICTIONARY.SELECT_ANALYTICS_IDENTIFIER} />
									</FormHelperText>
								</FormControl>
								<Divider orientation="horizontal" />
								<Text variant="strong">
									<T _str={DICTIONARY.IDENTIFIER_DETAILS} />
								</Text>
								<Flex gap="4" direction="column" visibility={posInfo ? 'visible' : 'hidden'}>
									<Box>
										<Text fontSize="sm" fontWeight="medium" color="neutral-fg">
											<T _str={DICTIONARY.IDENTIFIER_NAME} />
										</Text>
										<Text>{posInfo?.name}</Text>
									</Box>
									<Box>
										<Text fontSize="sm" fontWeight="medium" color="neutral-fg">
											<T _str={DICTIONARY.LANGUAGE} />
										</Text>
										<Text>{posInfo?.language.toUpperCase()}</Text>
									</Box>
									<Box>
										<Text fontSize="sm" fontWeight="medium" color="neutral-fg">
											<T _str={DICTIONARY.MARKET} />
										</Text>
										<Text>{posInfo?.market}</Text>
									</Box>
									<Box>
										<Text fontSize="sm" fontWeight="medium" color="neutral-fg">
											<T _str={DICTIONARY.BRAND} />
										</Text>
										<Text>{posInfo?.brand}</Text>
									</Box>
									<Box>
										<Text fontSize="sm" fontWeight="medium" color="neutral-fg">
											<T _str={DICTIONARY.TIMEOUT} />
										</Text>
										<Text>{posInfo?.timeout}</Text>
									</Box>
								</Flex>
							</Flex>
						</ModalBody>
						<ModalFooter>
							<ButtonGroup>
								<Button variant="ghost" onClick={onClose}>
									<T _str={DICTIONARY.CANCEL} />
								</Button>
								<Button id="createPOSBtn" onClick={onSaveClick} isDisabled={selectedPos == undefined}>
									<T _str={DICTIONARY.SAVE} />
								</Button>
							</ButtonGroup>
						</ModalFooter>
					</ModalContent>
				</ModalOverlay>
			</Modal>
		</>
	);
};

import { Box, Text, Tooltip } from '@chakra-ui/react';
import { mdiAlertCircleOutline } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { FC } from 'react';
import { TOOLTIP_SHOW_DELAY } from 'shared/constants';

export type TableTextProps = {
	name: string;
	tooltip: string;
	isError?: boolean;
};

export const TableText: FC<TableTextProps> = ({ name, tooltip, isError }) => {
	return (
		<Tooltip label={<T _str={tooltip} />} openDelay={TOOLTIP_SHOW_DELAY}>
			<Text as={Box} noOfLines={2} {...(isError ? { color: 'error' } : {})}>
				{isError && <Icon data-testid="default-language-error-icon" path={mdiAlertCircleOutline} mr="1" />}
				<Text as="span" verticalAlign="middle" display="inline-block">
					{name || ''}
				</Text>
			</Text>
		</Tooltip>
	);
};

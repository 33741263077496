import { Button, ButtonGroup } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';

export const SaveCancelChanges: FC = () => {
	const { dirty, submitForm, resetForm, isSubmitting } = useFormikContext();

	if (!dirty) {
		return <></>;
	}

	return (
		<>
			<ButtonGroup>
				<Button variant="outline" onClick={() => void resetForm()} isDisabled={isSubmitting}>
					<T _str={DICTIONARY.CANCEL} />
				</Button>
				<Button variant="solid" onClick={() => void submitForm()} isLoading={isSubmitting}>
					<T _str={DICTIONARY.SAVE} />
				</Button>
			</ButtonGroup>
		</>
	);
};

import { Button } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { CreateSiteButtonProps } from 'features/create-site/models';
import { CreateSiteModalWrapper } from 'features/create-site/ui/create-site-modal-wrapper';
import { PropsWithChildren } from 'react';
import { DICTIONARY } from 'shared/constants';

export const CreateSiteButton = ({
	canCreate = true,
	collectionId,
	buttonProps = {},
	text = DICTIONARY.CREATE_WEBSITE,
}: PropsWithChildren<CreateSiteButtonProps>) => {
	return (
		<CreateSiteModalWrapper collectionId={collectionId}>
			<Button variant="outline" {...buttonProps} isDisabled={!canCreate} data-testid="sc-create-site-button">
				<T _str={text} />
			</Button>
		</CreateSiteModalWrapper>
	);
};

import {
	SITE_DEFINITION_FIELDS_EVENT,
	SITE_DEFINITION_FORM_EVENT_NAME,
	SITE_DEFINITION_SAVED,
	SITE_DEFINITION_TOUCHED,
	SITE_IS_SUBMITTING,
} from 'shared/constants';
import { SiteDefinitionViewModel } from 'widgets/site-definition-update-form/models/general-section';

export type GeneralSectionEventType = 'submit' | 'reset';

export type GeneralSectionEvent = {
	type: GeneralSectionEventType;
};

export type SiteDefinitionFieldsEvent = {
	name: keyof SiteDefinitionViewModel;
	value: string;
};

export type SiteSavedEvent = {
	saved: boolean;
};

export type IsSubmittingEvent = {
	state: boolean;
};

export type SiteIsDirtyEvent = {
	isDirty: boolean;
};

declare global {
	interface DocumentEventMap {
		[SITE_DEFINITION_FORM_EVENT_NAME]: CustomEvent<GeneralSectionEvent>;
		[SITE_DEFINITION_TOUCHED]: CustomEvent<SiteIsDirtyEvent>;
		[SITE_DEFINITION_SAVED]: CustomEvent<SiteSavedEvent>;
		[SITE_DEFINITION_FIELDS_EVENT]: CustomEvent<SiteDefinitionFieldsEvent>;
		[SITE_IS_SUBMITTING]: CustomEvent<IsSubmittingEvent>;
	}
}

export const onSiteSaved = (saved: boolean) => {
	document.dispatchEvent(new CustomEvent<SiteSavedEvent>(SITE_DEFINITION_SAVED, { detail: { saved } }));
};

export const onIsSubmittingChange = (state: boolean) => {
	document.dispatchEvent(new CustomEvent<IsSubmittingEvent>(SITE_IS_SUBMITTING, { detail: { state } }));
};

export const onSiteTouched = (isDirty: boolean) => {
	document.dispatchEvent(new CustomEvent<SiteIsDirtyEvent>(SITE_DEFINITION_TOUCHED, { detail: { isDirty } }));
};

export const setSiteDefinitionFieldValue = (field: string, value: string) => {
	document.dispatchEvent(
		new CustomEvent(SITE_DEFINITION_FIELDS_EVENT, {
			detail: {
				name: field,
				value,
			},
		})
	);
};

export const onButtonsClick = (type: GeneralSectionEventType) => {
	document.dispatchEvent(new CustomEvent<GeneralSectionEvent>(SITE_DEFINITION_FORM_EVENT_NAME, { detail: { type } }));
};

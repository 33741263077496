import { PoS } from 'features/point-of-sale/models';
import { POS_NAME_ALLOWED_CHARACTERS_REGEX } from 'shared/constants';

export const createPosName = (siteName: string, posList: PoS[]) => {
	const name = siteName.toLowerCase().replace(/ /g, '_').replace(POS_NAME_ALLOWED_CHARACTERS_REGEX, '');

	let numberName = name;

	if (posList.some((pos) => pos.name === name)) {
		numberName = `${name}_1`;

		for (let i = 1; posList.some((pos) => pos.name === `${name}_${i}`); ) {
			numberName = `${name}_${++i}`;
		}
	}

	return numberName;
};

import * as Yup from 'yup';
import {
	ASTERISK_REGEX,
	DEFAULT_SITE_NAME_LENGTH,
	DICTIONARY,
	HOSTNAME_DELIMITER,
	HOSTNAME_REGEX,
	ITEM_CREATION_REGEX,
	ITEM_SYSTEM_NAME_REGEX,
	ITEM_SYSTEM_NAME_REGEX_EXPANSION,
	POS_CREATION_REGEX,
	SITE_PAGE_NAME_REGEX,
	SPACE_REGEX,
	URL_VALIDATE_REGEX,
	VALIDATIONS,
} from './constants';

import type { NewStatus } from 'features/create-site/models';
import { LanguageItem } from 'shared/languages/models';
import { sanitizeSiteName } from './utils';

export type SiteNames = { names: string[]; displayNames: string[] };

export type CollectionNames = Array<{ name: string; displayName: string }>;

type CollectionType = { value: string } & NewStatus;

type CustomNameValidatorProps = { siteNames: SiteNames; originalName?: string };

const siteNameValidation = Yup.string()
	.required(VALIDATIONS.SYSTEM_NAME_REQUIRED)
	.strict()
	.matches(SPACE_REGEX, VALIDATIONS.SPACE_IS_NOT_ALLOWED)
	.matches(ITEM_SYSTEM_NAME_REGEX, VALIDATIONS.NAME_VALIDATION_MATCHES)
	.matches(ITEM_SYSTEM_NAME_REGEX_EXPANSION, VALIDATIONS.SYSTEM_NAME_VALIDATION_HYPHEN_START);

const siteDefinitionNameValidation = Yup.string()
	.required(VALIDATIONS.NAME_REQUIRED)
	.strict()
	.matches(SPACE_REGEX, VALIDATIONS.SPACE_IS_NOT_ALLOWED)
	.matches(ITEM_SYSTEM_NAME_REGEX, VALIDATIONS.NAME_VALIDATION_MATCHES)
	.matches(ITEM_SYSTEM_NAME_REGEX_EXPANSION, VALIDATIONS.NAME_VALIDATION_HYPHEN_START);

const siteComplexNameValidation = ({ siteNames, originalName }: CustomNameValidatorProps) =>
	Yup.string()
		.required(VALIDATIONS.NAME_REQUIRED)
		.trim(VALIDATIONS.NAME_VALIDATION_TRIM)
		.max(DEFAULT_SITE_NAME_LENGTH, DICTIONARY.SITE_NAME_MAX_LENGTH)
		.strict()
		.test('displayName', DICTIONARY.SITE_EXISTS_IN_COLLECTION_ERROR, (value) => {
			if (value === originalName) return true;

			const metaNameCheck = !siteNames.displayNames.includes(value || '');

			return metaNameCheck;
		})
		.test('displayName', DICTIONARY.SITE_EXISTS_ERROR, (value) => {
			if (value === originalName) return true;

			const sanitizedSiteName = sanitizeSiteName(value);
			const systemNameCheck = sanitizedSiteName ? !siteNames.names.includes(sanitizedSiteName) : true;

			return systemNameCheck;
		});

const siteMetaNameValidation = ({ siteNames, originalName }: CustomNameValidatorProps) =>
	Yup.string()
		.required(VALIDATIONS.NAME_REQUIRED)
		.trim(VALIDATIONS.NAME_VALIDATION_TRIM)
		.max(DEFAULT_SITE_NAME_LENGTH, DICTIONARY.SITE_NAME_MAX_LENGTH)
		.strict()
		.test('displayName', DICTIONARY.SITE_EXISTS_IN_COLLECTION_ERROR, (value = '') => {
			if (value === originalName) return true;

			const metaNameCheck = !siteNames.displayNames.includes(value);

			return metaNameCheck;
		});

const siteSimpleMetaNameValidation = Yup.string().required(VALIDATIONS.NAME_REQUIRED).trim(VALIDATIONS.NAME_VALIDATION_TRIM).strict();

const sitePageNameValidation = Yup.string()
	.required(VALIDATIONS.ITEM_NAME_REQUIRED)
	.trim(VALIDATIONS.ITEM_NAME_VALIDATION_TRIM)
	.max(VALIDATIONS.PAGE_NAME_LENGTH, VALIDATIONS.PAGE_NAME_VALIDATION_MAX)
	.strict()
	.matches(SITE_PAGE_NAME_REGEX, VALIDATIONS.SITE_PAGE_VALIDATION_MATCHES)
	.matches(ITEM_SYSTEM_NAME_REGEX_EXPANSION, VALIDATIONS.NAME_VALIDATION_HYPHEN_START);

const sitePageDisplayNameValidation = Yup.string().trim(VALIDATIONS.DISPLAY_NAME_VALIDATION_TRIM).strict();

const collectionNameValidation = Yup.string()
	.required(VALIDATIONS.COLLECTION_SYSTEM_NAME_REQUIRED)
	.strict()
	.matches(SPACE_REGEX, VALIDATIONS.SPACE_IS_NOT_ALLOWED)
	.matches(ITEM_SYSTEM_NAME_REGEX, VALIDATIONS.NAME_VALIDATION_MATCHES)
	.matches(ITEM_SYSTEM_NAME_REGEX_EXPANSION, VALIDATIONS.SYSTEM_NAME_VALIDATION_HYPHEN_START);

const collectionMetaNameValidation = Yup.string().required(VALIDATIONS.NAME_REQUIRED).trim(VALIDATIONS.NAME_VALIDATION_TRIM).strict();

const siteCreateCollectionMetaNameValidation = (
	collectionNames: CollectionNames,
	collection: CollectionType | null,
	collectionId: string | undefined
) => {
	return !collectionId
		? Yup.string()
				.required(VALIDATIONS.COLLECTION_REQUIRED)
				.trim(VALIDATIONS.NAME_VALIDATION_TRIM)
				.strict()
				.test('value', '', () => !!collection?.value)
				.test('value', VALIDATIONS.COLLECTION_META_NAME_ALREADY_EXISTS, (value) => {
					if (!collection?.__isNew__) return true;
					const sanitizedCollectionName = sanitizeSiteName(value);
					const systemNameCheck = sanitizedCollectionName ? !collectionNames.some(({ name }) => name === sanitizedCollectionName) : true;
					const metaNameCheck = !collectionNames.some(({ displayName }) => displayName === value);

					return systemNameCheck && metaNameCheck;
				})
		: Yup.string().notRequired();
};
const languageValidation = Yup.string().required(VALIDATIONS.DEFAULT_LANGUAGE_REQUIRED);
const languagesValidation = Yup.array().min(1, VALIDATIONS.ONE_LANGUAGE_IS_REQUIRED).nullable();
const hostnameValidation = Yup.string().required(VALIDATIONS.HOSTNAME_REQUIRED);

export const siteNameValidationSchema = Yup.object({
	name: siteNameValidation,
});

export const siteMetaNameValidationSchema = (siteNames: SiteNames) =>
	Yup.object({
		displayName: siteMetaNameValidation({ siteNames }),
	});

export const siteDefinitionNameValidationSchema = Yup.object({
	name: siteDefinitionNameValidation,
});

export const siteRenameMetaNameValidationSchema = Yup.object({
	siteName: siteSimpleMetaNameValidation,
});

export const sitePageItemValidationSchema = Yup.object({
	name: sitePageNameValidation,
	displayName: sitePageDisplayNameValidation,
});

export const collectionValidationSchema = Yup.object({
	displayName: collectionMetaNameValidation,
});

export const createCollectionValidationSchema = Yup.object({
	name: collectionNameValidation,
	displayName: collectionMetaNameValidation,
});

export const collectionRenameSystemNameValidationSchema = Yup.object({
	name: collectionNameValidation,
});

export const siteDetailsValidationSchema = (
	siteNames: SiteNames,
	collectionNames: CollectionNames,
	collection: CollectionType | null,
	collectionId: string | undefined,
	showManagedLanguages: boolean
) => {
	return Yup.object({
		displayName: siteComplexNameValidation({ siteNames }),
		collection: siteCreateCollectionMetaNameValidation(collectionNames, collection, collectionId),
		language: languageValidation,
		languages: showManagedLanguages ? languagesValidation : Yup.array().notRequired(),
	});
};

export const createPosValidationSchema = Yup.object({
	name: Yup.string()
		.required(VALIDATIONS.NAME_REQUIRED)
		.trim(VALIDATIONS.SPACE_IS_NOT_ALLOWED)
		.strict()
		.matches(POS_CREATION_REGEX, VALIDATIONS.SPACE_IS_NOT_ALLOWED),
	timeout: Yup.number().required(DICTIONARY.POS_TIMEOUT_REQUIRED),
});

export const siteSettingsValidationSchema = Yup.object({
	displayName: siteSimpleMetaNameValidation,
	hostname: hostnameValidation,
});

export const createSiteDefinitionValidationSchema = (names: string[], hostnames: string[]) =>
	Yup.object({
		name: Yup.string()
			.required(VALIDATIONS.NAME_REQUIRED)
			.strict()
			.matches(SPACE_REGEX, VALIDATIONS.SPACE_IS_NOT_ALLOWED)
			.matches(ITEM_SYSTEM_NAME_REGEX, VALIDATIONS.SITE_NAME_VALIDATION)
			.matches(ITEM_SYSTEM_NAME_REGEX_EXPANSION, VALIDATIONS.NAME_VALIDATION_HYPHEN_START)
			.test('name', VALIDATIONS.SITE_DEFINITION_EXISTS, (value) => {
				return !names.find((s) => s.toLocaleLowerCase() === value?.toLocaleLowerCase());
			}),
		language: languageValidation,
		hostname: hostnameValidation.matches(HOSTNAME_REGEX, VALIDATIONS.INVALID_URI).test('hostname', VALIDATIONS.HOSTNAME_EXISTS, (value) => {
			return !hostnames.find((s) => s.toLocaleLowerCase() === value?.toLocaleLowerCase());
		}),
	});

export const siteDefinitionValidationSchema = (names: string[], usedHostNames: string[]) =>
	Yup.object({
		name: Yup.string()
			.required(VALIDATIONS.NAME_REQUIRED)
			.strict()
			.matches(SPACE_REGEX, VALIDATIONS.SPACE_IS_NOT_ALLOWED)
			.matches(ITEM_SYSTEM_NAME_REGEX, VALIDATIONS.DEFINITION_NAME_VALIDATION_MATCHES)
			.matches(ITEM_SYSTEM_NAME_REGEX_EXPANSION, VALIDATIONS.NAME_VALIDATION_HYPHEN_START)
			.test('name', DICTIONARY.SITE_DEFINITION_EXISTS_ERROR, (value) => {
				return !names.some((name) => name === value);
			}),
		hostname: Yup.string().test('hostname', '', (value) => {
			if (!value) {
				return false;
			}

			const hostnames = value.split(HOSTNAME_DELIMITER);
			const isNotEmpty = hostnames.every((hostname) => hostname.length > 0);
			const isValid = hostnames.every((hostname) => hostname.match(URL_VALIDATE_REGEX) || hostname.match(ASTERISK_REGEX));
			const isNotDuplicate = usedHostNames.filter((usedHostname) => hostnames.includes(usedHostname)).length === 0;

			return isNotEmpty && isValid && isNotDuplicate;
		}),
		targetHostname: Yup.string().test('targetHostname', DICTIONARY.HOST_NAME_INVALID_URL_TYPE, (value) => {
			if (!value) return true;

			return !!(value.match(URL_VALIDATE_REGEX) || value.match(ASTERISK_REGEX));
		}),
		renderingHost: Yup.mixed().required(DICTIONARY.RENDERING_HOST_REQUIRED),
	});

export const sitePageNameValidationSchema = Yup.object({
	name: Yup.string()
		.required(VALIDATIONS.PAGE_NAME_REQUIRED)
		.trim(VALIDATIONS.PAGE_NAME_TRIM)
		.strict()
		.matches(ITEM_SYSTEM_NAME_REGEX_EXPANSION, VALIDATIONS.SYSTEM_NAME_VALIDATION_HYPHEN_START)
		.matches(ITEM_CREATION_REGEX, VALIDATIONS.PAGE_NAME_MATCHES),
	displayName: Yup.string().trim(VALIDATIONS.PAGE_DISPLAY_NAME_TRIM).strict(),
	templateId: Yup.mixed().required(VALIDATIONS.PAGE_TEMPLATE_REQUIRED),
});

export const siteSettingsGeneralSectionValidationSchema = (siteNames: SiteNames, originalName: Partial<string>) =>
	Yup.object({ displayName: siteMetaNameValidation({ siteNames, originalName }) });

export const siteDefinitionLanguageValidationSchema = (siteLanguages: LanguageItem[], systemLanguages: LanguageItem[]) =>
	Yup.object({
		language: Yup.mixed()
			.test('language', VALIDATIONS.SYSTEM_MISSING_LANGUAGE, (option: LanguageItem) => {
				return !!systemLanguages.find((lang) => lang.value === option.value);
			})
			.test('language', VALIDATIONS.SITE_MISSING_LANGUAGE, (option: LanguageItem) => {
				return !!siteLanguages.find((lang) => lang.value === option.value);
			}),
	});

export const siteLanguagesSectionValidationSchema = Yup.object({ languages: Yup.array().min(1, VALIDATIONS.ONE_LANGUAGE_IS_REQUIRED).nullable() });

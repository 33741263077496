import { Button, ButtonGroup, Flex, Text } from '@chakra-ui/react';
import { mdiViewColumnOutline, mdiViewModuleOutline } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { useTranslate } from 'features/common/utils/translateUtils';
import { FC, useCallback } from 'react';
import { DICTIONARY } from 'shared/constants';
import './ViewSwitcher.scss';

export type ViewSwitcherModes = 'columns' | 'tiles';

export const viewModes: Record<ViewSwitcherModes, ViewSwitcherModes> = {
	columns: 'columns',
	tiles: 'tiles',
};

export type ViewSwitcherProps = {
	mode: ViewSwitcherModes;
	onModeChange: (newMode: ViewSwitcherModes) => void;
};

const buttonProps = {
	active: {
		isActive: true,
		variant: 'ghost',
		colorScheme: 'primary',
		background: 'primary.100 !important',
		paddingX: '3',
	},
	inactive: {
		colorScheme: 'neutral',
		paddingX: '3',
	},
};

function getButtonProps(currentMode: ViewSwitcherModes, expectedMode: ViewSwitcherModes) {
	if (currentMode == expectedMode) {
		return buttonProps.active;
	}

	return buttonProps.inactive;
}

export const ViewSwitcher: FC<ViewSwitcherProps> = ({ mode, onModeChange }) => {
	const t = useTranslate();
	const handleClick = useCallback(
		(newMode: ViewSwitcherModes) => {
			if (mode != newMode) {
				onModeChange(newMode);
			}
		},
		[mode, onModeChange]
	);

	return (
		<ButtonGroup variant="ghost" borderRadius="full" border="1px" borderColor="chakra-border-color" background="chakra-body-bg" p="1" spacing="0">
			<Button
				{...getButtonProps(mode, 'tiles')}
				onClick={() => handleClick('tiles')}
				aria-label={t(DICTIONARY.CARDS_VIEW)}
				size="sm"
				borderRadius="full"
				data-testid="cards-button"
				className={`switch-button ${mode === 'tiles' ? 'active' : ''}`}
			>
				<Flex direction="row" gap="2" align="center" zIndex="1">
					<Icon boxSize="5" path={mdiViewModuleOutline} />
					<Text variant="strong">
						<T _str={DICTIONARY.TILES} />
					</Text>
				</Flex>
			</Button>
			<Button
				{...getButtonProps(mode, 'columns')}
				onClick={() => handleClick('columns')}
				aria-label={t(DICTIONARY.MILLER_COLUMN_VIEW)}
				size="sm"
				borderRadius="full"
				data-testid="columns-button"
				className={`switch-button ${mode === 'columns' ? 'active' : ''}`}
			>
				<Flex direction="row" gap="2" align="center" zIndex="1">
					<Icon boxSize="5" path={mdiViewColumnOutline} />
					<Text variant="strong">
						<T _str={DICTIONARY.COLUMNS} />
					</Text>
				</Flex>
			</Button>
		</ButtonGroup>
	);
};

ViewSwitcher.defaultProps = {
	mode: 'tiles',
	onModeChange: () => {},
};

import { Button } from '@chakra-ui/react';
import { mdiOpenInNew } from '@mdi/js';
import { Icon } from 'entities/icon/ui';
import { MediaCardProps } from 'entities/media-card/models';
import { MediaBody, MediaIcon } from 'entities/media-card/ui';
import { FC } from 'react';

export const MediaCard: FC<MediaCardProps> = ({ icon, title, description, showOpenInNewIcon = true, onClick, ...props }) => {
	return (
		<Button
			gap="4"
			padding="2"
			_hover={{ backgroundColor: 'neutral.100', '&>.media-open-in-new': { opacity: 1 } }}
			whiteSpace="unset"
			display="flex"
			justifyContent="flex-start"
			borderRadius="md"
			bg="transparent"
			textAlign="left"
			height="fit-content"
			color="unset"
			fontWeight="unset"
			transition="background .1s ease-in"
			_active={{ background: 'neutral.100' }}
			onClick={onClick}
			{...props}
		>
			<MediaIcon path={icon} />
			<MediaBody title={title} description={description} />
			{showOpenInNewIcon && (
				<Icon
					transition="opacity .1s ease-in"
					className="media-open-in-new"
					opacity="0"
					color="blackAlpha.400"
					path={mdiOpenInNew}
					boxSize="9"
					padding="1.5"
				/>
			)}
		</Button>
	);
};

import { Button, ButtonProps, Flex, IconButton, Modal, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure } from '@chakra-ui/react';
import { mdiArrowLeft } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { useTranslate } from 'features/common/utils/translateUtils';
import React, { FC, useCallback } from 'react';
import { DICTIONARY } from 'shared/constants';
import { CreateWebsiteForm } from './CreateWebsiteForm';

type CreateWebsiteButtonProps = {
	buttonProps: ButtonProps;
	refetchJobsList: () => void;
};

export const CreateWebsiteButton: FC<React.PropsWithChildren<CreateWebsiteButtonProps>> = ({ buttonProps, refetchJobsList, children }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const t = useTranslate();
	const siteSaved = useCallback(() => {
		onClose();
		refetchJobsList();
	}, [onClose, refetchJobsList]);

	return (
		<>
			<Button minWidth="fit-content" {...buttonProps} onClick={onOpen}>
				{children}
			</Button>

			<Modal isOpen={isOpen} onClose={onClose} size="full" orientation="horizontal">
				<ModalOverlay>
					<ModalContent height="fit-content" borderRadius="0" background="chakra-subtle-bg">
						<ModalHeader fontSize="3xl">
							<Flex align="center">
								<IconButton
									aria-label={t(DICTIONARY.CLOSE_CREATE_SITE_DIALOG)}
									size="md"
									mr="3"
									variant="ghost"
									icon={<Icon path={mdiArrowLeft} />}
									onClick={onClose}
								></IconButton>
								<Text as="span">
									<T _str={DICTIONARY.NEW_SITE} />
								</Text>
							</Flex>
						</ModalHeader>

						<CreateWebsiteForm closeModal={siteSaved} />
					</ModalContent>
				</ModalOverlay>
			</Modal>
		</>
	);
};

import Resizer from 'react-image-file-resizer';
import { THUMBNAIL_MAX_PIXEL_SIDE_SIZE, THUMBNAIL_MIN_HEIGHT, THUMBNAIL_MIN_WIDTH } from 'shared/constants';

export const resizeFile = (file: File, imageType: string): Promise<string> =>
	new Promise((resolve, reject) => {
		Resizer.imageFileResizer(
			file,
			THUMBNAIL_MAX_PIXEL_SIDE_SIZE,
			THUMBNAIL_MAX_PIXEL_SIDE_SIZE,
			imageType.toUpperCase(),
			100,
			0,
			(uri: unknown | string) => {
				if (uri) {
					resolve(uri as string);
				} else {
					reject(new Error('Failed to resize image'));
				}
			},
			'base64',
			THUMBNAIL_MIN_WIDTH,
			THUMBNAIL_MIN_HEIGHT
		);
	});

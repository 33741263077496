import { Button, Menu, MenuButton, MenuDivider, MenuItem, MenuList } from '@chakra-ui/react';
import { mdiChevronDown } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { useCdpTenant } from 'features/common/tenants/CdpTenantProvider';
import { useSaveNavigate } from 'features/common/utils/navigationUtils';
import { useRemoveSiteContext } from 'features/sites/components/RemoveSiteContext';
import { DuplicateSiteButton } from 'features/sites/components/duplicate/DuplicateSiteButton';
import { RenameContextMenuItem } from 'features/sites/components/rename/RenameContextMenuItem';
import { useOpenExplorer } from 'features/sites/utils/useOpenExplorer';
import { useOpenSiteEditor } from 'features/sites/utils/useOpenSiteEditor';
import { TypeFilterUrlParams } from 'features/sites/utils/useTypeFilter';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';

export const SiteMenu: FC<{ siteName: string; siteType?: TypeFilterUrlParams }> = ({ siteName, siteType }) => {
	const openExplorer = useOpenExplorer(siteName);
	const openSiteEditor = useOpenSiteEditor();
	const { removeSite } = useRemoveSiteContext();
	const navigate = useSaveNavigate();
	const { isEnabled } = useCdpTenant();

	return (
		<Menu>
			<MenuButton
				as={Button}
				variant="outline"
				rightIcon={<Icon path={mdiChevronDown} layerStyle="menuButtonIcon" />}
				size="sm"
				data-testid="siteMenu"
			>
				<T _str={DICTIONARY.MORE} />
			</MenuButton>
			<MenuList>
				<MenuItem onClick={() => openSiteEditor(siteName)}>
					<T _str={DICTIONARY.OPEN_IN_PAGES} />
				</MenuItem>

				<MenuItem onClick={openExplorer}>
					<T _str={DICTIONARY.OPEN_IN_EXPLORER} />
				</MenuItem>

				{isEnabled && (
					<>
						<MenuDivider />
						<MenuItem onClick={() => navigate(`/sites/${siteName}/general`)}>
							<T _str={DICTIONARY.SETTINGS} />
						</MenuItem>
					</>
				)}

				{siteType && siteType === 'sxa' && (
					<>
						<MenuDivider />
						<RenameContextMenuItem siteName={siteName}></RenameContextMenuItem>
						<DuplicateSiteButton siteName={siteName}></DuplicateSiteButton>
						<MenuItem onClick={() => removeSite(siteName)}>
							<T _str={DICTIONARY.DELETE} />
						</MenuItem>
					</>
				)}
			</MenuList>
		</Menu>
	);
};

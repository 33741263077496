import { AppContainer } from 'app/app-container';
import AppProviders from './AppProviders';

const App = () => {
	return (
		<AppProviders>
			<AppContainer />
		</AppProviders>
	);
};

export default App;

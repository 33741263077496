import { useCallback, useMemo } from 'react';
import { POS_FIELD_ENABLE_ANALYTICS } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { EditPOSInput, SiteResponse } from 'shared/models';
import { usePatch } from 'shared/react-query-wrappers/hooks';

/**
 * @deprecated Update is blocked for EA
 */
export const useUpdatePOSAnalyticsEA = (site: SiteResponse | undefined) => {
	const { mutateAsync, isPending } = usePatch({
		queryKey: {
			url: ENDPOINTS.SITE,
			params: { id: site?.id ?? '' },
		},
		refetchQueriesByKey: [{ url: ENDPOINTS.SITE, params: { id: site?.id ?? '' } }],
	});

	const newValue = useMemo(
		() =>
			site?.settings && (site?.settings[POS_FIELD_ENABLE_ANALYTICS] === 'false' || !site?.settings[POS_FIELD_ENABLE_ANALYTICS])
				? 'true'
				: 'false',
		[site?.settings]
	);

	const handlerSwitcher = useCallback(async () => {
		let params: Partial<SiteResponse> & Partial<EditPOSInput> = {
			settings: { [POS_FIELD_ENABLE_ANALYTICS]: newValue },
		};

		if (newValue === 'false') {
			params = { ...params, posMappings: [] };
		}

		await mutateAsync(params);
	}, [mutateAsync, newValue]);

	return {
		handlerSwitcher,
		isPending,
	};
};

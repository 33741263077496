import { Box, Stack, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { PageBodyContentSection } from 'entities/page-layout/ui';
import { useTranslate } from 'features/common/utils/translateUtils';
import { PosEnablementSwitcherEA, SiteMissingPosNotification } from 'features/point-of-sale/ui';
import { SitePageOutlet } from 'pages/site/models';
import { FC } from 'react';
import { useOutletContext } from 'react-router';
import { DICTIONARY, POS_FIELD_ENABLE_ANALYTICS } from 'shared/constants';
import { SitePosTableEA } from 'widgets/site-pos-table/ui';

/**
 * @deprecated Update is blocked for EA
 */
export const SitePagePos: FC = () => {
	const { site, languages, siteWithMissingPos, isLoading } = useOutletContext<SitePageOutlet>();
	const t = useTranslate();
	const isEnableAnalytics = !!(site?.settings[POS_FIELD_ENABLE_ANALYTICS] && site?.settings[POS_FIELD_ENABLE_ANALYTICS] === 'true');

	return (
		<>
			<SiteMissingPosNotification site={siteWithMissingPos} />
			<PageBodyContentSection title={t(DICTIONARY.ANALYTICS_AND_PERSONALIZATION)} headerNode={<PosEnablementSwitcherEA site={site} />}>
				<Stack gap={6}>
					<Box>
						<Text>
							<T _str={DICTIONARY.ANALYTICS_AND_PERSONALIZATION_DESCRIPTION} />
						</Text>
						<Text hidden={!isEnableAnalytics}>
							<T _str={DICTIONARY.ANALYTICS_AND_PERSONALIZATION_DESCRIPTION_POS_ENABLE} />
						</Text>
					</Box>
					{isEnableAnalytics && <SitePosTableEA languages={languages} site={site} isLoading={isLoading} />}
				</Stack>
			</PageBodyContentSection>
		</>
	);
};

import { useToast } from '@chakra-ui/react';
import { useTranslate } from 'features/common/utils/translateUtils';
import { useCheckPageCreationUniqueness } from 'features/create-site-page/hooks';
import {
	CreateSitePagePayload,
	CreateSitePageViewModel,
	CreatedPageResponse,
	InsertOption,
	Option,
	UseCreateSitePageProps,
} from 'features/create-site-page/models';
import { SitePageOutlet } from 'pages/site/models';
import { useCallback, useMemo } from 'react';
import { useOutletContext } from 'react-router';
import { DICTIONARY, DISPLAY_NAME_ITEM_ID } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { MoveSitePagePayload, MoveSitePageResponse, MoveSitePageUrlParams } from 'shared/models';
import { usePost, usePostWithUrlInput } from 'shared/react-query-wrappers/hooks';
import { blinkPage, dispatchCreatePageErrorEvent, dispatchCreatePageStartEvent, dispatchCreatePageSuccessEvent } from 'shared/site-structure';

export type MovePosition = {
	position: 'Before' | 'Into' | 'After';
};

export const useCreateSitePage: UseCreateSitePageProps = (currentId, parentId, language, options, isSameLevel, actionType) => {
	const { site } = useOutletContext<SitePageOutlet>();
	const t = useTranslate();
	const toast = useToast();

	const siteId = site?.id || '';

	const { mutateAsync: createPage } = usePost<CreatedPageResponse, CreateSitePagePayload>({ queryKey: { url: ENDPOINTS.PAGES } });
	const { mutateAsync: movePage } = usePostWithUrlInput<MoveSitePageResponse, MoveSitePagePayload, MoveSitePageUrlParams>({
		queryKey: { url: ENDPOINTS.MOVE_PAGE },
	});
	const validatePageNameUniqueness = useCheckPageCreationUniqueness({ siteId, pageId: currentId, actionType });

	const submitHandler = useCallback(
		async (values: CreateSitePageViewModel) => {
			const payload: CreateSitePagePayload = {
				parentId,
				pageName: values.name,
				templateId: values.templateId,
				language,
				fields: [{ name: DISPLAY_NAME_ITEM_ID, value: values.displayName }],
			};

			dispatchCreatePageStartEvent(currentId, actionType || 'inside', values.name);

			try {
				const createResult = await createPage(payload);

				if (createResult) {
					if (actionType === 'inside') {
						dispatchCreatePageSuccessEvent(currentId, actionType || 'inside');
						await blinkPage(createResult.data.pageId);
					} else {
						const moveResult = await movePage({
							input: {
								site: site?.id || '',
								itemToMoveId: createResult.data.pageId,
								targetId: currentId,
								position: actionType === 'after' ? 'After' : 'Before',
							},
							additionalVariables: { pageId: createResult.data.pageId },
						});

						if (moveResult) {
							dispatchCreatePageSuccessEvent(currentId, actionType || 'inside');
							await blinkPage(createResult.data.pageId);
						}
					}
				}
			} catch (error) {
				toast({
					status: 'error',
					description: t(DICTIONARY.PAGE_CREATION_FAILED),
				});
				dispatchCreatePageErrorEvent(currentId);
			}
		},
		[actionType, createPage, currentId, language, movePage, parentId, site?.id, t, toast]
	);

	const initialValues: CreateSitePageViewModel = useMemo(() => {
		return {
			name: '',
			displayName: '',
			templateId: '',
		};
	}, []);

	// eslint-disable-next-line @typescript-eslint/no-unsafe-return
	const mappedOptions = useMemo<Option[]>(() => options.map((opt: InsertOption) => ({ value: opt.id, label: opt.displayName })), [options]);

	return {
		submitHandler,
		validatePageNameUniqueness,
		mappedOptions,
		initialValues,
	};
};

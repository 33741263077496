import { IconButton, Menu, MenuButton, MenuDivider, MenuItem, MenuList } from '@chakra-ui/react';
import { mdiDotsHorizontal } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { useCdpTenant } from 'features/common/tenants/CdpTenantProvider';
import { useSaveNavigate } from 'features/common/utils/navigationUtils';
import { TypeFilterUrlParams } from 'features/sites/utils/useTypeFilter';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { useOpenExplorer } from '../utils/useOpenExplorer';
import { useRemoveSiteContext } from './RemoveSiteContext';
import { DuplicateSiteButton } from './duplicate/DuplicateSiteButton';
import { RenameContextMenuItem } from './rename/RenameContextMenuItem';

type ContextualMenuProps = {
	siteName: string;
	openSiteEditor: (siteName: string) => void;
	siteType: TypeFilterUrlParams | undefined;
};

export const ContextualMenu: FC<ContextualMenuProps> = ({ openSiteEditor, siteName, siteType }) => {
	const navigate = useSaveNavigate();
	const { isEnabled } = useCdpTenant();
	const { removeSite } = useRemoveSiteContext();

	const openExplorer = useOpenExplorer(siteName);

	return (
		<Menu>
			<MenuButton as={IconButton} variant="ghost" data-testid={`ContextualMenu-${siteName}`} alignSelf="end">
				<Icon path={mdiDotsHorizontal} />
			</MenuButton>
			<MenuList data-testit="contextMenuList">
				<MenuItem onClick={() => openSiteEditor(siteName)}>
					<T _str={DICTIONARY.OPEN_IN_PAGES} />
				</MenuItem>
				<MenuItem onClick={openExplorer} data-testid="openInExplorerButton">
					<T _str={DICTIONARY.OPEN_IN_EXPLORER} />
				</MenuItem>

				{isEnabled && (
					<>
						<MenuDivider />
						<MenuItem onClick={() => navigate(`/sites/${siteName}/general`)} data-testid="openSiteSettingsButton">
							<T _str={DICTIONARY.SETTINGS} />
						</MenuItem>
					</>
				)}
				{siteType === 'sxa' && (
					<>
						<MenuDivider />
						<RenameContextMenuItem siteName={siteName}></RenameContextMenuItem>
						<DuplicateSiteButton siteName={siteName}></DuplicateSiteButton>
						<MenuItem onClick={() => removeSite(siteName)} data-testid="deleteSiteButton">
							<T _str={DICTIONARY.DELETE} />
						</MenuItem>
					</>
				)}
			</MenuList>
		</Menu>
	);
};

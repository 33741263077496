import { Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { useEditLanguage } from 'features/languages/hooks/use-edit-language';
import { EditLanguageModalProps } from 'features/languages/models';
import { LanguageModalBody } from 'features/languages/ui/language-modal-body';
import { FC, PropsWithChildren } from 'react';
import { DICTIONARY } from 'shared/constants';
import { useModalWrapper } from 'shared/modal-wrapper/hooks';

export const EditLanguageModal: FC<PropsWithChildren<EditLanguageModalProps>> = (props) => {
	const { children, isOpen, onClose } = useModalWrapper({ children: props.children });
	const handleSubmit = useEditLanguage({ onClose, ...props });

	return (
		<>
			{children}
			<Modal isOpen={isOpen} onClose={onClose} size="md">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						<T _str={DICTIONARY.EDIT_LANGUAGE} />
					</ModalHeader>
					<ModalCloseButton />
					<LanguageModalBody onClose={onClose} handleSubmit={handleSubmit} submitText={DICTIONARY.SAVE} isLanguageLocked {...props} />
				</ModalContent>
			</Modal>
		</>
	);
};

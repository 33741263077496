import { useCallback } from 'react';
import { ENDPOINTS } from 'shared/endpoints';
import { usePatch } from 'shared/react-query-wrappers/hooks/use-patch';
import { ThumbnailGeneralHookModelEA } from '../models';

/**
 * @deprecated Update is blocked for EA
 */
export const useRemoveGeneralThumbnailEA = ({ siteId, collectionId }: ThumbnailGeneralHookModelEA) => {
	const { mutateAsync } = usePatch({
		queryKey: {
			url: ENDPOINTS.SITE,
			params: { id: siteId ?? '' },
		},
		refetchQueriesByKey: [
			{ url: ENDPOINTS.COLLECTION_SITES, params: { collectionId: collectionId ?? '' } },
			{ url: ENDPOINTS.SITE, params: { id: siteId ?? '' } },
			{ url: ENDPOINTS.JOBS },
		],
	});

	return useCallback(async () => {
		await mutateAsync({ thumbnail: '' });
	}, [mutateAsync]);
};

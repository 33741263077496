import { useGetAllPOS } from 'features/pos/utils/posService';
import { PosPayload } from 'features/pos/utils/types';
import { useCallback, useMemo, useState } from 'react';

export type SelectPosData = {
	selectedPos: PosSelectItem | null;
	setSelectedPos: (posItem: PosSelectItem | null) => void;
	posInfo: PosPayload | undefined;
	posSelectList: PosSelectItem[];
	dropSelection: () => void;
};

export type PosSelectItem = {
	value: string;
	label: string;
};

export const useSelectPos = (): SelectPosData => {
	const [selectedPos, setSelectedPos] = useState<PosSelectItem | null>(null);
	const [posInfo, setPosInfo] = useState<PosPayload>();
	const { posList } = useGetAllPOS();

	const posSelectList = useMemo(() => {
		return posList.map<PosSelectItem>((pos) => ({
			label: `${pos.name} (${pos.language.toUpperCase()})`,
			value: pos.name,
		}));
	}, [posList]);

	const selectPos = useCallback(
		(value: PosSelectItem | null) => {
			setSelectedPos(value);
			setPosInfo(posList.find((p) => p.name === value?.value));
		},
		[posList]
	);

	const dropSelection = useCallback(() => {
		setSelectedPos(null);
		setPosInfo(undefined);
	}, [setSelectedPos, setPosInfo]);

	return {
		selectedPos: selectedPos,
		posSelectList: posSelectList,
		posInfo: posInfo,
		setSelectedPos: selectPos,
		dropSelection: dropSelection,
	};
};

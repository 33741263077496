import { FC } from 'react';
import { Text, Badge } from '@chakra-ui/react';

type WorkflowBadgeProps = {
	isFinal?: boolean;
	style: 'fill' | 'empty';
	stateName?: string;
};

export const WorkflowBadge: FC<WorkflowBadgeProps> = ({ isFinal, style, stateName }) => {
	if (isFinal === undefined) {
		return <></>;
	}

	if (isFinal && style === 'empty') {
		return (
			<Text color="success-fg" fontSize="sm">
				{stateName}
			</Text>
		);
	}

	if (isFinal && style === 'fill' && stateName) {
		return <Badge colorScheme="success">{stateName}</Badge>;
	}

	if (!isFinal && style === 'empty') {
		return (
			<Text color="info-fg" fontSize="sm">
				{stateName}
			</Text>
		);
	}

	if (!isFinal && style === 'fill' && stateName) {
		return <Badge colorScheme="purple">{stateName}</Badge>;
	}

	return <></>;
};

import { filterSiteByType } from 'features/sites/utils/filterSiteByType';
import { useCallback, useMemo } from 'react';
import { defalutSiteCollectionValue, useSiteFilterContext } from '../components/SiteFilterContext';
import { SiteInfo } from '../data/createSiteTypes';

export type FilterResult = {
	filter: string;
	setFilter: (value: string) => void;
	filteredSites: SiteInfo[];
	isFiltering: boolean;
	resetFilters: () => void;
};

const filteringInputMinLength = 1;

export const useSiteFilter = (sites: SiteInfo[]): FilterResult => {
	const { filter, setFilter, siteCollection, setCollection, siteType, resetSiteType } = useSiteFilterContext();
	const isFiltering = filter.length > filteringInputMinLength || siteCollection !== defalutSiteCollectionValue || siteType !== 'none';

	const result = useMemo(() => {
		if (!sites.length) {
			return sites;
		}

		let filteredSites: SiteInfo[] = sites;

		if (isFiltering) {
			filteredSites = sites.filter((site) => site.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()));
		}

		filteredSites = filterSiteByType(filteredSites, siteType);

		if (siteCollection && siteCollection.id && siteType !== 'nonsxa') {
			filteredSites = filteredSites.filter((site) => site.siteCollectionName === siteCollection.name);
		}

		return filteredSites;
	}, [filter, isFiltering, siteCollection, siteType, sites]);

	const resetFilters = useCallback(() => {
		setFilter('');
		setCollection(undefined);
		resetSiteType();
	}, [resetSiteType, setCollection, setFilter]);

	return {
		filter,
		setFilter,
		filteredSites: result,
		isFiltering,
		resetFilters,
	};
};

import { buildSearchParams } from '../utils/buildSearchParams';
import { ITEM_ID_PARAM_NAME, SITE_NAME_PARAM_NAME } from '../utils/сlearNonRelevantQueryParams';
import {
	analyticsBaseUrl,
	componentsBaseUrl,
	environment,
	explorerBaseUrl,
	featureFlagsBaseUrl,
	formsBaseUrl,
	identityBaseUrl,
	inventoryBaseUrl,
	inventoryXmCloudSystemId,
	pagesUri,
	portalBaseUrl,
	tenantRegionsMapper,
} from './envConfig';

const identityUrl = `${identityBaseUrl}/api/identity/v1`;
const inventoryUrl = `${inventoryBaseUrl}/api/inventory/v1`;
const explorerUrl = explorerBaseUrl;
const formsUrl = formsBaseUrl;
const analyticsUrl = analyticsBaseUrl;
const featureFlagsUrl = featureFlagsBaseUrl + '/api/featureflags/v1';

export const organizationUrl = `${identityBaseUrl}/api/identity/v1/user/organizations?pagenumber=1&pagesize=100`;

const identity = {
	get_user: {
		scope: 'identity.user:read',
		url: `${identityUrl}/user`,
	},
};

const portal = {
	profile_manage: `${portalBaseUrl}/profile`,
};

const inventory = {
	xmSystemId: inventoryXmCloudSystemId,
	get_tenants: {
		scope: 'inventory.tenants:read',
		url: (organizationId: string, systemId: string, name: string | undefined, pagenumber = 1, pagesize = 20) => {
			return `${inventoryUrl}/tenants?pagenumber=${pagenumber}&pagesize=${pagesize}&state=Active&organizationId=${organizationId}&systemId=${systemId}&name=${
				name || ''
			}`;
		},
		xmCloudSystemId: inventoryXmCloudSystemId,
	},
	get_tenantById: {
		scope: 'inventory.tenants:read',
		url: (tenantId: string) => {
			return `${inventoryUrl}/tenants/${tenantId}`;
		},
	},
};

const cdp = {
	create_pos: {
		url: (cdpUrl: string) => `${cdpUrl}/v2/tenants/points-of-sale`,
	},
	get_all_pos: {
		url: (cdpUrl: string) => `${cdpUrl}/v2/tenants/points-of-sale`,
	},
	delete_pos: {
		url: (cdpUrl: string, identifier: string) => `${cdpUrl}/v2/tenants/points-of-sale/${identifier}`,
	},
};

export const pages = {
	getUrl: (organization?: string, tenantName?: string, siteName?: string, itemId?: string): string => {
		const params = buildSearchParams({ organization, tenantName, [SITE_NAME_PARAM_NAME]: siteName, [ITEM_ID_PARAM_NAME]: itemId });

		return pagesUri + '/' + params;
	},
};

export const explorer = {
	getUrl: (organization?: string, tenantName?: string, siteName?: string): string => {
		const params = buildSearchParams({ organization, tenantName, [SITE_NAME_PARAM_NAME]: siteName });

		return explorerUrl + '/' + params;
	},
};

export const forms = {
	getUrl: (organization?: string, tenantName?: string): string => {
		const params = buildSearchParams({ organization, tenantName });

		return formsUrl + '/' + params;
	},
};

export const analytics = {
	getUrl: (organization?: string, tenantId?: string, regionCode?: string): string => {
		if (!analyticsUrl || !tenantRegionsMapper) {
			return '';
		}

		const params = buildSearchParams({ organization, tenantId });
		const regionKey = tenantRegionsMapper[regionCode || 'Default'] ?? tenantRegionsMapper.Default;
		const analyticsHost = analyticsUrl.replace('{region}', regionKey);

		return analyticsHost + '/' + params;
	},
};

export const components = {
	getUrl: (organization?: string, tenantName?: string): string => {
		const params = buildSearchParams({ organization, tenantName });

		return componentsBaseUrl + '/' + params;
	},
};

const featureFlags = {
	scope: 'featureflags',
	url: `${featureFlagsUrl}/flags?key=dashboard&labelfilter=${environment}`,
};

export const deploy = {
	generateApiKeyUrl: '{xmCloudDeployAPIBaseUrl}/api/environments/v1/{environmentId}/obtain-edge-token',
};

export default {
	identity,
	inventory,
	portal,
	explorer,
	cdp,
	featureFlags,
	deploy,
};

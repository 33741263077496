import { useFeatures } from 'features/common/featureFlags/featureFlags';
import { useMemo } from 'react';
import { useCheckJobStatus } from 'shared/check-job-status';

export const useClickableSiteCard = (id: string) => {
	const { showSitesGrouping } = useFeatures();
	const { hasRunningSiteRemovals, getRunningSiteRemovals } = useCheckJobStatus();

	const isDisabled = useMemo(
		() => showSitesGrouping && hasRunningSiteRemovals() && getRunningSiteRemovals()?.some((job) => job.site?.toLowerCase().includes(id)),
		[getRunningSiteRemovals, hasRunningSiteRemovals, id, showSitesGrouping]
	);

	return { isDisabled };
};

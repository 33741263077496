import { useCallback } from 'react';
import { useCreatePoSAction, useGetAllPoS } from 'shared/api';
import { POS_DEFAULT_BRAND, POS_DEFAULT_MARKET, POS_DEFAULT_TIMEOUT } from 'shared/constants';
import { PoSCreateInput } from 'shared/models';
import { createPosName } from 'shared/point-of-sale';

export const useCreateSinglePoSAction = () => {
	const { posList } = useGetAllPoS();
	const { createPosIdentifiers } = useCreatePoSAction();

	const initialPosName = useCallback((siteName: string) => createPosName(siteName, posList), [posList]);

	const createPoS = useCallback(
		async (input: Partial<PoSCreateInput> & Pick<PoSCreateInput, 'name'>) => {
			const uniqueName = initialPosName(input.name);

			await createPosIdentifiers([
				{
					name: uniqueName,
					market: input.market ?? POS_DEFAULT_MARKET,
					timeout: input.timeout ?? POS_DEFAULT_TIMEOUT,
					brand: input.brand ?? POS_DEFAULT_BRAND,
				},
			]);

			return uniqueName;
		},
		[initialPosName, createPosIdentifiers]
	);

	return { createPoS, isReady: !!posList };
};

import {
	Alert,
	AlertDescription,
	AlertDialogBody,
	AlertDialogFooter,
	AlertIcon,
	AlertTitle,
	Box,
	Button,
	ListItem,
	Text,
	UnorderedList,
	VStack,
	Wrap,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { useRemoveLanguage } from 'features/remove-language/hooks';
import { RemoveLanguageModalBodyProps } from 'features/remove-language/models';
import { FC, useMemo } from 'react';
import { DICTIONARY } from 'shared/constants';

export const RemoveLanguageAlertBody: FC<RemoveLanguageModalBodyProps> = (props) => {
	const { onClose, cancelRef, language, allLanguages } = props;
	const { name, englishName } = language;
	const { submitHandler, environmentType, isDefaultLanguageOf } = useRemoveLanguage(name, englishName, onClose);

	const isFallbackOf = useMemo(() => {
		return allLanguages.filter((f) => f.fallbackLanguageIso === language.name).map((m) => m.englishName);
	}, [allLanguages, language.name]);

	const generateNames = (names: string[]): string => {
		return names.join(', ');
	};

	return (
		<>
			<AlertDialogBody>
				<VStack gap="6">
					<Box>
						<T
							_str={DICTIONARY.DELETE_LANGUAGE_MAIN_TEXT}
							englishName={
								<Text as="span" variant="strong">
									{englishName}
								</Text>
							}
							environmentType={
								<Text as="span" variant="strong">
									{environmentType}
								</Text>
							}
						/>
					</Box>

					{(!!isFallbackOf.length || !!isDefaultLanguageOf.length) && (
						<Alert status="warning">
							<AlertIcon />
							<Wrap>
								<AlertTitle>
									<T _str={DICTIONARY.WARNING} />
								</AlertTitle>
								<AlertDescription>
									<UnorderedList marginInlineStart="6">
										{!!isFallbackOf.length && (
											<ListItem>
												<T
													_str={DICTIONARY.IS_FALLBACK_LANGUAGE_OF}
													englishName={language.englishName}
													isFallbackOf={generateNames(isFallbackOf)}
												/>
											</ListItem>
										)}
										{!!isDefaultLanguageOf.length && (
											<ListItem>
												<T
													_str={DICTIONARY.IS_DEFAULT_LANGUAGE_OF}
													englishName={language.englishName}
													isDefaultLanguageOf={generateNames(isDefaultLanguageOf)}
													noOfLanguages={isDefaultLanguageOf.length}
												/>
											</ListItem>
										)}
									</UnorderedList>
								</AlertDescription>
							</Wrap>
						</Alert>
					)}
				</VStack>
			</AlertDialogBody>
			<AlertDialogFooter gap="2">
				<Button variant="ghost" ref={cancelRef} onClick={onClose}>
					<T _str={DICTIONARY.CANCEL} />
				</Button>
				<Button data-testid="remove-button" colorScheme="danger" onClick={() => void submitHandler()}>
					<T _str={DICTIONARY.DELETE} />
				</Button>
			</AlertDialogFooter>
		</>
	);
};

import { Button, Skeleton } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { CreateLanguageModal } from 'features/languages/ui';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { Access } from 'shared/models';
import { useSimpleGet } from 'shared/react-query-wrappers/hooks';

export const CreateLanguageButton: FC = () => {
	const { data: access, isLoading } = useSimpleGet<Access>(ENDPOINTS.LANGUAGE_PERMISSIONS);

	return (
		<CreateLanguageModal>
			<Skeleton isLoaded={!isLoading} borderRadius="full">
				<Button width="fit-content" isDisabled={!access?.canCreate ?? false}>
					<T _str={DICTIONARY.ADD_LANGUAGE} />
				</Button>
			</Skeleton>
		</CreateLanguageModal>
	);
};

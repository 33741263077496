import { FC, Fragment, useMemo } from 'react';
import { ENDPOINTS } from 'shared/endpoints';
import { PageResponse, mapPage } from 'shared/models';
import { useGet } from 'shared/react-query-wrappers/hooks';
import { usePageStructureEvents } from 'widgets/site-structure/hooks/use-page-structure-events';
import { SiteStructureProps, pageAnimationStateDefault } from 'widgets/site-structure/models/props';
import { CreatingPage } from 'widgets/site-structure/ui/creating-page';
import { PageBranch } from 'widgets/site-structure/ui/page-branch';
import { SiteStructureLoading } from 'widgets/site-structure/ui/page-loading';

export const SiteStructure: FC<SiteStructureProps> = ({ siteId, siteName, homePageIdsAndPath, isLoading }) => {
	const {
		data,
		isLoading: isHierarchyLoading,
		refetch,
	} = useGet<PageResponse>({
		queryKey: { url: ENDPOINTS.HIERARCHY, params: { siteId } },
		enabled: !isLoading,
		refetchOnMount: 'always',
	});

	const children = useMemo(() => data?.children.map((pageData) => mapPage(pageData)) || [], [data]);
	const { creating } = usePageStructureEvents({ siteId, children, refetchCurrentBranch: refetch });

	const siblingNames = useMemo(() => children.map((child) => child.name), [children]);

	return (
		<>
			{children.map((page, index) => (
				<Fragment key={page.id}>
					{creating?.direction === 'before' && <CreatingPage creating={creating} pageId={page.id} lvl={0} />}
					<PageBranch
						key={page.id}
						siteId={siteId}
						siblingNames={siblingNames}
						siteName={siteName}
						lvl={0}
						opened
						homePageIdsAndPath={homePageIdsAndPath}
						parentCanCreate={page.canCreate}
						{...page}
						{...pageAnimationStateDefault}
						indexInBranch={index}
					/>
					{creating?.direction === 'after' && <CreatingPage creating={creating} pageId={page.id} lvl={0} />}
				</Fragment>
			))}
			{(isLoading || isHierarchyLoading) && <SiteStructureLoading />}
		</>
	);
};

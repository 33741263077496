import { UseSitesPageData } from 'pages/sites/models';
import { useMemo } from 'react';
import { useCheckJobStatus } from 'shared/check-job-status';
import { ENDPOINTS } from 'shared/endpoints';
import { useGet } from 'shared/react-query-wrappers/hooks';
import { PortfolioResponse } from 'widgets/portfolio/models';

export const useSitesPageData: UseSitesPageData = () => {
	const { data: getPortfolios = [], isLoading: isPortfolioLoading } = useGet<PortfolioResponse[]>({ queryKey: { url: ENDPOINTS.COLLECTIONS } });
	const { hasRunningCollectionCreations, hasRunningSiteAndCollectionCreations, getRunningSiteAndCollectionCreations, isSuccess } =
		useCheckJobStatus();

	const getFilteredCollections = useMemo(() => {
		if (!isSuccess) {
			return [];
		}

		const runningCollectionNames = getRunningSiteAndCollectionCreations()?.map((el) => el.siteCollection);

		return runningCollectionNames ? getPortfolios.filter((portfolio) => !runningCollectionNames.includes(portfolio.name)) : getPortfolios;
	}, [getPortfolios, getRunningSiteAndCollectionCreations, isSuccess]);

	const hasRunningCollections = useMemo(
		() => hasRunningCollectionCreations() || hasRunningSiteAndCollectionCreations(),
		[hasRunningCollectionCreations, hasRunningSiteAndCollectionCreations]
	);

	return {
		isPortfolioLoading,
		getFilteredCollections,
		hasRunningCollections,
	};
};

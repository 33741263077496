import { Box } from '@chakra-ui/react';
import { VirtualizedMenuListProps } from 'entities/virtualized-menu-list/models/virtualized-menu-list-props';
import 'entities/virtualized-menu-list/styles/virtualized-menu-list.scss';
import { FC, PropsWithChildren } from 'react';
import { FixedSizeList as List } from 'react-window';
import { MENU_ITEM_HEIGHT } from 'shared/constants';

export const VirtualizedMenuList: FC<PropsWithChildren<VirtualizedMenuListProps>> = ({ children, maxHeight }) => {
	return (
		<Box className="virtualized-box" overflow="hidden" borderRadius="md" background="white" boxShadow="md" borderWidth="1px">
			<List height={maxHeight} width="full" itemCount={Array.isArray(children) ? children.length : 0} itemSize={MENU_ITEM_HEIGHT}>
				{({ index, style }) => (
					<Box style={style} className="virtualized-item">
						{Array.isArray(children) && children[index]}
					</Box>
				)}
			</List>
		</Box>
	);
};

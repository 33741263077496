// eslint-disable-next-line import/no-extraneous-dependencies
import { Flex, FlexProps, Tag, TagLabel, TagLeftIcon, Tooltip } from '@chakra-ui/react';
import { mdiDatabaseOutline, mdiInboxFullOutline } from '@mdi/js';
import { Icon } from 'entities/icon/ui';
import { useXMTenant } from 'features/common/tenants/xmTenantContext';
import { useTranslate } from 'features/common/utils/translateUtils';
import { memo, useMemo } from 'react';
import { DICTIONARY } from 'shared/constants';

export const AnnotationDetails = memo((flexProps: FlexProps) => {
	const t = useTranslate();
	const { tenant } = useXMTenant();
	const { projectName, environmentName } = tenant;
	const isVisible = useMemo(() => projectName && environmentName, [projectName, environmentName]);

	return (
		<Flex data-testid="annotation-container" {...flexProps} height="fit-content" visibility={isVisible ? 'visible' : 'hidden'}>
			<Tooltip label={t(DICTIONARY.PROJECT_NAME)}>
				<Tag size="sm">
					<TagLeftIcon>
						<Icon path={mdiInboxFullOutline} />
					</TagLeftIcon>
					<TagLabel data-testid="annotation-projectName">{projectName}</TagLabel>
				</Tag>
			</Tooltip>
			<Flex pr="2" />
			<Tooltip label={t(DICTIONARY.ENVIRONMENT_NAME)}>
				<Tag size="sm">
					<TagLeftIcon>
						<Icon path={mdiDatabaseOutline} />
					</TagLeftIcon>
					<TagLabel data-testid="annotation-environmentName">{environmentName}</TagLabel>
				</Tag>
			</Tooltip>
		</Flex>
	);
});

AnnotationDetails.displayName = AnnotationDetails.name;

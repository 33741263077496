import { SitesPermissionsResponse } from 'features/site-permissions/models';
import { Host } from 'shared/models';

export type SiteResponse = {
	id: string;
	name: string;
	description: string;
	displayName: string;
	thumbnail: {
		url: string;
		rootPath: string;
		autogenerated: boolean;
	};
	collectionId: string;
	rootPageId: string;
	homePageId: string;
	defaultLanguage: string;
	created: string;
	access: SitesPermissionsResponse;
	languages: string[];
	hosts: Host[];
	pageDesigns: Record<string, string>;
	settings: Record<string, string | object>;
	languageEmbedding?: boolean;
	itemLanguageFallback?: boolean;
	fieldLanguageFallback?: boolean;
	linkableSite: boolean;
	targetHostname: string;
	pointsOfSale: Record<string, object>;
	properties?: Record<string, string>;
};

export type SiteViewModel = {
	id: string;
	name: string;
	description: string;
	displayName: string;
	thumbnail: {
		url: string;
		rootPath: string;
		autogenerated: boolean;
	};
	hosts: Host[];
	collectionId: string;
	rootPageId: string;
	homePageId: string;
	defaultLanguage: string;
	languageEmbedding: boolean;
	itemLanguageFallback: boolean;
	fieldLanguageFallback: boolean;
	languages: string[];
	created: string;
	access: SitesPermissionsResponse;
	settings: Record<string, string | object>;
	properties?: Record<string, string>;
};

export const mapSiteResponseToViewModel = (site: SiteResponse): SiteViewModel => {
	return {
		...site,
		languageEmbedding: site.languageEmbedding ?? false,
		itemLanguageFallback: site.itemLanguageFallback ?? false,
		fieldLanguageFallback: site.fieldLanguageFallback ?? false,
	};
};

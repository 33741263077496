import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { PosPayload } from 'features/pos/utils/types';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { CreateForm } from './CreateForm';

export const CreateModal: FC<{
	isOpen: boolean;
	onClose: () => void;
	onCreated?: (newPos: PosPayload) => void;
	initialLanguage?: string;
	predefinedLanguages?: string[];
	lockLanguage?: boolean;
	customHeader?: string;
}> = ({ isOpen, onClose, onCreated, initialLanguage, predefinedLanguages, lockLanguage, customHeader }) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose} size="lg" orientation="vertical" blockScrollOnMount={false}>
			<ModalOverlay>
				<ModalContent height="fit-content">
					<ModalHeader>
						<Text as="span">
							<T _str={customHeader ?? DICTIONARY.CREATE_NEW_ANALYTICS_IDENTIFIER} />
						</Text>
					</ModalHeader>
					<ModalCloseButton />

					<ModalBody>
						<CreateForm
							onCancel={onClose}
							onCreated={(newPos) => {
								onClose();

								if (onCreated) {
									onCreated(newPos);
								}
							}}
							initialLanguage={initialLanguage}
							predefinedLanguages={predefinedLanguages}
							lockLanguage={lockLanguage}
						/>
					</ModalBody>
				</ModalContent>
			</ModalOverlay>
		</Modal>
	);
};

import { useFeatures } from 'features/common/featureFlags/featureFlags';
import { useXMTenant } from 'features/common/tenants/xmTenantContext';
import { CollectionItemResponse } from 'features/create-site/models';
import { useCallback, useMemo } from 'react';
import { useLocation, useParams } from 'react-router';
import { ENDPOINTS } from 'shared/endpoints';
import { useGetLanguages } from 'shared/languages/hooks';
import { LanguageItem } from 'shared/languages/models';
import { SiteResponse } from 'shared/models';
import { useNavigateToRoute } from 'shared/navigation/hooks';
import { useGet, useSimpleGet } from 'shared/react-query-wrappers/hooks';
import { useGetTemplates } from 'shared/templates/hooks';

export const useSitePageData = (fetchSite = true, fetchLanguages = true) => {
	const params = useParams();
	const navigate = useNavigateToRoute();
	const { tenant } = useXMTenant();
	const location = useLocation();
	const { getTemplateById } = useGetTemplates();
	const { data: systemLanguages, isLoading: isLanguageLoading } = useGetLanguages(fetchLanguages, { supportedLanguages: false });
	const { data: allLanguages, isLoading: isAllLanguagesLoading } = useGetLanguages(fetchLanguages);
	const { data: collections } = useSimpleGet<CollectionItemResponse[]>(ENDPOINTS.COLLECTIONS, true);
	const { showManagedLanguages } = useFeatures();
	const { data, isLoading: isSiteLoading } = useGet<SiteResponse>({
		queryKey: { url: ENDPOINTS.SITE, params: { id: params?.id ?? '' } },
		enabled: fetchSite,
	});

	const goBack = useCallback(() => navigate(location.state?.origin ?? '/'), [location, navigate]);

	const siteInfo = useMemo(
		() => ({
			...data,
			...(data?.settings?.siteTemplate || data?.properties?.siteTemplate
				? { siteTemplateInfo: getTemplateById((data?.settings?.siteTemplate || data?.properties?.siteTemplate || '') as string) }
				: {}),
			collectionName: collections?.find((collection) => collection.id === data?.collectionId)?.displayName,
		}),
		[collections, data, getTemplateById]
	) as SiteResponse;

	const languages = useMemo(() => {
		if (!showManagedLanguages) {
			return systemLanguages;
		}

		return siteInfo?.supportedLanguages?.flatMap<LanguageItem>((language) => {
			const languageItem = systemLanguages?.find((lang) => lang.value === language) || allLanguages.find((lang) => lang.value === language);

			return languageItem ? [languageItem] : [];
		});
	}, [showManagedLanguages, siteInfo.supportedLanguages, systemLanguages, allLanguages]);

	const hosts = useMemo(() => siteInfo?.hosts || [], [siteInfo?.hosts]);

	return {
		environmentType: tenant.environmentType,
		environment: tenant.environmentName,
		site: siteInfo,
		languages,
		hosts,
		goBack,
		isLoading: isSiteLoading,
		isLanguagesLoading: isLanguageLoading || isAllLanguagesLoading,
	};
};

import { useCallback, useEffect, useState } from 'react';
import { SMOOTH_SCROLL_TIMEOUT } from '../constants';

export const useColumnScroll = (
	dataColumnCount: number,
	columnWidth: number,
	columnsRef: React.MutableRefObject<HTMLDivElement | null | undefined>
) => {
	const [prevColumnCount, setPrevColumnCount] = useState(dataColumnCount);
	const toScroll = useCallback(
		(totalColumnCount: number) => {
			if (columnsRef.current) {
				const totalViewSize = totalColumnCount * columnWidth;

				columnsRef.current.scrollLeft = totalViewSize - columnsRef.current.clientWidth;
			}
		},
		[columnWidth, columnsRef]
	);

	useEffect(() => {
		toScroll(dataColumnCount);
	}, [dataColumnCount, toScroll]);

	useEffect(() => {
		if (dataColumnCount === prevColumnCount) {
			return;
		}

		if (prevColumnCount > dataColumnCount) {
			setTimeout(() => {
				setPrevColumnCount(dataColumnCount);
			}, SMOOTH_SCROLL_TIMEOUT);
		} else {
			setPrevColumnCount(dataColumnCount);
		}
	}, [dataColumnCount, prevColumnCount, setPrevColumnCount]);

	const millerColumnsSize = Math.max(dataColumnCount, prevColumnCount);

	return {
		millerColumnsSize,
	};
};

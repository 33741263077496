import { HelpDrawerWrapperProps } from 'features/help-drawer/models';
import { HelpDrawer } from 'features/help-drawer/ui/help-drawer';
import { FC } from 'react';

export const HelpDrawerWrapper: FC<HelpDrawerWrapperProps> = ({ isHelpDrawerAvailable, setIsHelpDrawerAvailable, isOpen, onClose }) => {
	return (
		<>
			{isHelpDrawerAvailable && <HelpDrawer isOpen={isOpen} onClose={onClose} onCreateTicketClick={() => setIsHelpDrawerAvailable(false)} />}
			<create-support-case drawer-is-open={!isHelpDrawerAvailable} />
		</>
	);
};

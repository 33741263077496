export const ORGANIZATION = 'organization';
export const TENANT_NAME = 'tenantName';
export const orgIdStorageKey = 'xmapps-dashboard:org_id';
export const tenantNameStorageKey = 'xmapps-dashboard:tenant_name';

export const getAuthProtocolParameters = () => {
	const query = new URLSearchParams(window.location.search);
	const orgIdFromURL = query.get(ORGANIZATION);
	const tenantNameFromURL = query.get(TENANT_NAME);
	const orgIdFromSessingStorage = sessionStorage.getItem(orgIdStorageKey);
	const tenantNameFromSessingStorage = sessionStorage.getItem(tenantNameStorageKey);
	const orgIdFromLocalStorage = localStorage.getItem(orgIdStorageKey);
	const tenantNameFromLocalStorage = localStorage.getItem(tenantNameStorageKey);

	if (orgIdFromURL && tenantNameFromURL) {
		return {
			organization: orgIdFromURL,
			tenantName: tenantNameFromURL,
		};
	}

	if (tenantNameFromURL) {
		return {
			organization: undefined,
			tenantName: tenantNameFromURL,
		};
	}

	if (orgIdFromURL) {
		if (tenantNameFromSessingStorage && orgIdFromURL == orgIdFromSessingStorage) {
			return {
				organization: orgIdFromURL,
				tenantName: tenantNameFromSessingStorage,
			};
		}

		if (tenantNameFromLocalStorage && orgIdFromURL == orgIdFromLocalStorage) {
			return {
				organization: orgIdFromURL,
				tenantName: tenantNameFromLocalStorage,
			};
		}

		return {
			organization: orgIdFromURL,
			tenantName: undefined,
		};
	}

	if (orgIdFromSessingStorage && tenantNameFromSessingStorage) {
		return {
			organization: orgIdFromSessingStorage,
			tenantName: tenantNameFromSessingStorage,
		};
	}

	if (orgIdFromLocalStorage && tenantNameFromLocalStorage) {
		return {
			organization: orgIdFromLocalStorage,
			tenantName: tenantNameFromLocalStorage,
		};
	}

	return {
		organization: undefined,
		tenantName: undefined,
	};
};

import { ThumbnailItem } from 'entities/thumbnail/ui';
import { useTranslate } from 'features/common/utils/translateUtils';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { SiteResponse } from 'shared/models';
import { parseFile } from 'shared/utils/parse-file';
import { useThumbnailAutoEA } from '../hooks/use-thumbnail-auto-ea';

/**
 * @deprecated Update is blocked for EA
 */
export const ThumbnailAutoEA: FC<{ site: SiteResponse; isAutoRegenerating?: boolean }> = ({ site, isAutoRegenerating }) => {
	const t = useTranslate();
	const thumbnailFileInfo = parseFile(site?.thumbnailUrl);
	const { isRegenerating, onClickRegenerate } = useThumbnailAutoEA(site);

	return (
		<ThumbnailItem
			thumbnail={thumbnailFileInfo}
			buttonTitle={t(DICTIONARY.REGENERATE)}
			buttonCallback={onClickRegenerate}
			buttonSpinner={isAutoRegenerating || isRegenerating}
			loadingText={t(DICTIONARY.REGENERATING)}
		/>
	);
};

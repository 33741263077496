import { ThumbnailType, UseThumbnail } from 'features/thumbnail/models';
import { useCallback, useRef, useState } from 'react';
import { SITE_DEFINITION_FORM_EVENT_NAME, SITE_SETTINGS_FORM_EVENT_NAME } from 'shared/constants';
import { useEventListener } from 'usehooks-ts';
import { SiteGeneralSectionEvent } from 'widgets/site-settings-update-form/models';
import { useRemoveGeneralThumbnail } from './use-remove-general-thumbnail';
import { useRemoveThumbnail } from './use-remove-thumbnail';
import { useThumbnailAuto } from './use-thumbnail-auto';
import { useUpdateHostThumbnailSource } from './use-update-host-thumbnail-source';

export const useThumbnail: UseThumbnail = ({ site, siteDefinition, eventHandler }) => {
	const documentRef = useRef<Document>(document);
	const { isRegenerating, onClickRegenerate } = useThumbnailAuto(siteDefinition);
	const [typeThumbnail, setTypeThumbnail] = useState<ThumbnailType>(
		site?.thumbnail.autogenerated || siteDefinition?.thumbnailAutogenerated ? ThumbnailType.AUTO : ThumbnailType.MANUAL
	);
	const handleRemoveThumbnail = useRemoveGeneralThumbnail({ siteId: site?.id });
	const handleUpdateHostThumbnailSource = useUpdateHostThumbnailSource({ siteId: site?.id, hostId: site?.hosts[0]?.id });

	const handleHostRemoveThumbnail = useRemoveThumbnail({
		siteId: siteDefinition?.siteId,
		collectionId: siteDefinition?.collectionId,
		hostId: siteDefinition?.id,
	});

	const onAutoClickHandler = useCallback(() => {
		setTypeThumbnail(ThumbnailType.AUTO);

		if (siteDefinition && !siteDefinition?.thumbnailAutogenerated) {
			void handleHostRemoveThumbnail();
			void onClickRegenerate();

			return;
		}

		if (eventHandler) {
			eventHandler('', 'thumbnailBase64Url');
			eventHandler(ThumbnailType.AUTO, 'mode');
		}
	}, [eventHandler, handleHostRemoveThumbnail, onClickRegenerate, siteDefinition]);

	const onAutoSubmitHandler = useCallback(async () => {
		if (!site?.thumbnail?.autogenerated && !siteDefinition) {
			void handleRemoveThumbnail();
			void handleUpdateHostThumbnailSource(true);
		}

		// if (siteDefinition && !siteDefinition?.thumbnailAutogenerated) { //TODO: OOS for now
		// 	void handleHostRemoveThumbnail();
		// 	void onClickRegenerate();
		// }
	}, [handleRemoveThumbnail, handleUpdateHostThumbnailSource, site?.thumbnail?.autogenerated, siteDefinition]);

	const onManualClickHandler = useCallback(() => {
		if (eventHandler) {
			eventHandler(ThumbnailType.MANUAL, 'mode');
		}

		setTypeThumbnail(ThumbnailType.MANUAL);
	}, [eventHandler]);

	const onButtonsClick = useCallback(
		(event: CustomEvent<SiteGeneralSectionEvent>) => {
			const { type } = event.detail;

			if (type === 'reset') {
				setTypeThumbnail(site?.thumbnail.autogenerated || siteDefinition?.thumbnailAutogenerated ? ThumbnailType.AUTO : ThumbnailType.MANUAL);
			}
		},
		[site?.thumbnail.autogenerated, siteDefinition?.thumbnailAutogenerated]
	);

	useEventListener(SITE_SETTINGS_FORM_EVENT_NAME, onButtonsClick, documentRef);
	useEventListener(SITE_DEFINITION_FORM_EVENT_NAME, onButtonsClick, documentRef);

	return { onAutoClickHandler, onManualClickHandler, typeThumbnail, isRegenerating, onAutoSubmitHandler };
};

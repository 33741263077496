export type PagePermissions = {
	canAdmin: boolean;
	canWrite: boolean;
	canCreate: boolean;
	canDelete: boolean;
	canRename: boolean;
	canRead: boolean;
	canPublish: boolean;
	canDuplicate: boolean;
};

export type PageLocking = {
	canUnlock: boolean;
	isLocked: boolean;
	lockedBy: string;
	lockedByCurrentUser: boolean;
};

export type PageDataResponse = {
	id: string;
	name: string;
	displayName: string;
	language: string;
	version: number;
	templateId: string;
	parentId: string;
	hasPresentation: boolean;
	hasChildren: boolean;
	permissions: PagePermissions;
	locking: PageLocking;
	path: string;
};

export type PageResponse = {
	page: PageDataResponse;
	children: PageDataResponse[];
	ancestors: PageDataResponse[];
	siblings: PageDataResponse[];
};

export type PageViewModel = Omit<PageDataResponse, 'permissions' | 'locking'> & PagePermissions & PageLocking;

export const mapPage = (page: PageDataResponse): PageViewModel => ({
	...page,
	...page.permissions,
	...page.locking,
});

export const mapPageToResponse = (page: PageViewModel): PageDataResponse => ({
	...page,
	permissions: {
		canAdmin: page.canAdmin,
		canCreate: page.canCreate,
		canDelete: page.canDelete,
		canPublish: page.canPublish,
		canDuplicate: page.canDuplicate,
		canRead: page.canRead,
		canRename: page.canRename,
		canWrite: page.canWrite,
	},
	locking: {
		canUnlock: page.canUnlock,
		isLocked: page.isLocked,
		lockedBy: page.lockedBy,
		lockedByCurrentUser: page.lockedByCurrentUser,
	},
});

import {
	Button,
	CircularProgress,
	Flex,
	FormControl,
	FormHelperText,
	FormLabel,
	IconButton,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	Tooltip,
	useDisclosure,
} from '@chakra-ui/react';
import { mdiPencilOutline } from '@mdi/js';
import { t } from '@transifex/native';
import { T } from '@transifex/react';
import { FormFieldProps } from 'entities/form-field-control/models';
import { FormFieldControl } from 'entities/form-field-control/ui';
import { Icon } from 'entities/icon/ui';
import { useCheckCollectionSystemNameUniqueness } from 'features/collections/hooks/use-check-collection-uniqueness';
import { useRenameCollectionSystemName } from 'features/collections/hooks/use-rename-system-name-collection';
import { Field, Form, Formik } from 'formik';
import { FC, FocusEvent, useCallback } from 'react';
import { COLLECTION_NAME_VALIDATION_NUMBER, DICTIONARY } from 'shared/constants';

export const ControlFieldSystemName: FC<{ collectionId: string; collectionName: string }> = ({ collectionId, collectionName }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const validateCollectionNameUniqueness = useCheckCollectionSystemNameUniqueness({ collectionName, formKey: 'name' });
	const { newSystemNameRef, collectionRenameSystemNameValidationSchema, isPending, handleRename } = useRenameCollectionSystemName({
		value: collectionName,
		collectionId,
	});

	const submitHandler = useCallback(
		(values: any) => {
			void handleRename(values, onClose);
		},
		[handleRename, onClose]
	);

	return (
		<>
			<FormControl>
				<FormLabel>
					<T _str={DICTIONARY.COLLECTION_LABEL_SYSTEM_NAME} />
				</FormLabel>
				<Flex gap="2" alignItems="center">
					<Flex gap="2" hidden={!isPending}>
						<CircularProgress isIndeterminate size="5" capIsRound color="primary" />
						<Text>
							<T _str={DICTIONARY.COLLECTION_LABEL_CHANGE_SYSTEM_NAME} />
						</Text>
					</Flex>
					<Text>{newSystemNameRef.current}</Text>
					<Tooltip label={t(DICTIONARY.COLLECTION_EDIT_ICON)}>
						<IconButton
							data-testid="edit-system-name"
							size="xs"
							variant="ghost"
							aria-label={t(DICTIONARY.COLLECTION_EDIT_ICON)}
							icon={<Icon path={mdiPencilOutline} />}
							onClick={onOpen}
							hidden={isPending}
							onFocus={(e: FocusEvent<HTMLButtonElement>) => e.preventDefault()}
						/>
					</Tooltip>
				</Flex>
				<FormHelperText>
					<Text>
						<T _str={DICTIONARY.COLLECTION_HINT_EDIT_SYSTEM_NAME} />
					</Text>
				</FormHelperText>
			</FormControl>
			<Modal size="md" onClose={onClose} isOpen={isOpen}>
				<ModalOverlay />
				<Formik
					initialValues={{ name: collectionName || '' }}
					validationSchema={collectionRenameSystemNameValidationSchema}
					validate={(values) => validateCollectionNameUniqueness(values.name)}
					onSubmit={submitHandler}
					enableReinitialize
				>
					<Form noValidate>
						<ModalContent>
							<ModalHeader>
								<T _str={DICTIONARY.COLLECTION_EDIT_SYSTEM_NAME} />
							</ModalHeader>
							<ModalCloseButton />
							<ModalBody>
								<Flex gap="6" direction="column">
									<Text>
										<T _str={DICTIONARY.COLLECTION_POPUP_HINT_EDIT_SYSTEM_NAME} />
									</Text>
									<Field name="name">
										{({ field, form }: FormFieldProps) => (
											<FormFieldControl
												name={field.name}
												field={field}
												form={form}
												label={DICTIONARY.COLLECTION_LABEL_SYSTEM_NAME}
												isRequired
												maxLength={COLLECTION_NAME_VALIDATION_NUMBER}
												maxW="xl"
												helper={DICTIONARY.COLLECTION_POPUP_HINT_VALIDATION_SYSTEM_NAME}
											>
												<Input
													data-testid="collectionName-input"
													{...field}
													type="text"
													maxLength={COLLECTION_NAME_VALIDATION_NUMBER}
												/>
											</FormFieldControl>
										)}
									</Field>
								</Flex>
							</ModalBody>
							<ModalFooter gap="2">
								<Button variant="ghost" disabled={isPending} onClick={onClose}>
									<T _str={DICTIONARY.CLOSE} />
								</Button>
								<Button type="submit">
									<T _str={DICTIONARY.SAVE} />
								</Button>
							</ModalFooter>
						</ModalContent>
					</Form>
				</Formik>
			</Modal>
		</>
	);
};

import { Box, Stack } from '@chakra-ui/react';
import { FC } from 'react';
import { ScLaunchpadGroup } from './ScLaunchpadGroup';
import styles from './ScLaunchpad.module.css';
import { useGrid } from '../data/grid';

export const ScLaunchpadGrid: FC = () => {
	const grid = useGrid();

	return (
		<Box p="8">
			<Stack direction="column" spacing="10" className={styles['sc-launchpad']}>
				{grid.groups.map((group) => (
					<ScLaunchpadGroup key={group.name} {...group} />
				))}
			</Stack>
		</Box>
	);
};

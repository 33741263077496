import { useGetHostsWithMissingPosList } from 'features/point-of-sale/hooks/get-hosts-with-missing-pos-list';
import { useCallback } from 'react';
import { useCreatePoSAction } from 'shared/api';
import { POS_DEFAULT_BRAND, POS_DEFAULT_MARKET, POS_DEFAULT_TIMEOUT } from 'shared/constants';
import { PoSCreateInput } from 'shared/models';
import { useNavigateToRoute } from 'shared/navigation/hooks';
import { ROUTES } from 'shared/routes';

export const useMissingPosNotificationData = () => {
	const { missingPosList, isLoading } = useGetHostsWithMissingPosList();
	const { createPosIdentifiers } = useCreatePoSAction();
	const navigate = useNavigateToRoute();

	const handleSiteClick = useCallback(
		(siteId: string, hostId: string) => {
			navigate(
				`/${ROUTES.SITE.NAME}/${siteId}/${ROUTES.SITE.DEFINITIONS.NAME}/${hostId}/${ROUTES.SITE.DEFINITIONS.ANALYTICS_AND_PERSONALIZATION}`
			);
		},
		[navigate]
	);

	const handleCreateAllPos = useCallback(async () => {
		const missingPos = missingPosList.hosts.flatMap((site) => site.missingPos);
		const filteredMissingPos = missingPos.filter((pos, index) => missingPos.indexOf(pos) === index);
		const inputs = filteredMissingPos.map<PoSCreateInput>((name) => ({
			name,
			market: POS_DEFAULT_MARKET,
			brand: POS_DEFAULT_BRAND,
			timeout: POS_DEFAULT_TIMEOUT,
		}));

		await createPosIdentifiers(inputs);
	}, [missingPosList.hosts, createPosIdentifiers]);

	return {
		missingPosList,
		isLoading,
		handleCreateAllPos,
		handleSiteClick,
	};
};

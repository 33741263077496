import { useSaveNavigate } from 'features/common/utils/navigationUtils';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

export const tabs = [
	{
		name: 'general',
		index: 0,
	},
	{
		name: 'developersettings',
		index: 2,
	},
	{
		name: 'siteidentifiers',
		index: 1,
	},
	{
		name: 'userroles',
		index: 3,
	},
];

type Params = {
	sitename: string;
	tabname: string;
};

const findTabByName = (tabName?: string) => tabs.find((t) => t.name === tabName)?.index || 0;
const findTabNameByIndex = (tabIndex: number) => tabs.find((t) => t.index === tabIndex)?.name || '';

export const useSiteSettingsTabindex = (): [number, (index: number) => void] => {
	const { sitename, tabname } = useParams<keyof Params>();
	const navigate = useSaveNavigate();
	const tabIndex = findTabByName(tabname);

	const setCurrentTabIndex = useCallback(
		(index: number) => {
			const newTabName = findTabNameByIndex(index);

			if (!sitename || newTabName === tabname) {
				return;
			}

			navigate(`/sites/${sitename}/${newTabName}`);
		},
		[sitename, tabname, navigate]
	);

	return [tabIndex, setCurrentTabIndex];
};

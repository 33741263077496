import { DICTIONARY } from 'shared/constants';
import { TypeFilterUrlParams } from 'features/sites/utils/useTypeFilter';
import { useMemo } from 'react';

export const useSiteTypeName = (siteType: TypeFilterUrlParams | undefined): string => {
	return useMemo(() => {
		if (siteType === 'sxa') {
			return DICTIONARY.TYPE_FILTER.SXA_MANAGED;
		}

		return DICTIONARY.TYPE_FILTER.NON_SXA_MANAGED;
	}, [siteType]);
};

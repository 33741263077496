import { Button, ButtonGroup, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { SITE_NAME_PARAM_NAME } from 'features/common/utils/сlearNonRelevantQueryParams';
import { DICTIONARY } from 'shared/constants';
import {} from 'features/sites/utils/useGetSite';
import { FC, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { RenameFormHeader } from './RenameForm';
import { SiteNameField } from './SiteNameField';

export type RenameForm = {
	newName: string;
};

export const RenameSiteModal: FC<{ siteName: string; isOpen: boolean; onClose: () => void }> = ({ siteName, isOpen, onClose }) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const onRename = useCallback(
		(newName: string) => {
			if (searchParams.has(SITE_NAME_PARAM_NAME)) {
				searchParams.set(SITE_NAME_PARAM_NAME, newName);
				setSearchParams(searchParams);
			}

			onClose();
		},
		[onClose, searchParams, setSearchParams]
	);

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="md" orientation="vertical" blockScrollOnMount={false}>
			<ModalOverlay>
				<ModalContent>
					<RenameFormHeader siteName={siteName} initialNewName={siteName} onRename={onRename}>
						{({ submitForm, isSubmitting }) => (
							<>
								<ModalHeader>
									<T _str={DICTIONARY.RENAME_SITE} siteName={siteName} />
								</ModalHeader>
								<ModalCloseButton />
								<ModalBody minHeight="28">
									<SiteNameField label="New name" autoSelect={true} />
								</ModalBody>
								<ModalFooter>
									<ButtonGroup>
										<Button variant="ghost" onClick={onClose} data-testid="CancelButton">
											<T _str={DICTIONARY.CANCEL} />
										</Button>
										<Button type="submit" onClick={() => void submitForm()} isLoading={isSubmitting}>
											<T _str={DICTIONARY.RENAME} />
										</Button>
									</ButtonGroup>
								</ModalFooter>
							</>
						)}
					</RenameFormHeader>
				</ModalContent>
			</ModalOverlay>
		</Modal>
	);
};

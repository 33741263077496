import { Box, Tooltip, TooltipProps } from '@chakra-ui/react';
import { FC, ReactNode, useRef } from 'react';
import { TOOLTIP_SHOW_DELAY } from 'shared/constants';
import { useIsOverflowing } from 'shared/overflow-tooltip/hooks';

interface OverflowTooltipProps {
	label: string;
	children: ReactNode;
}

/**
 * label: a normal Tooltip label
 * children: an element like <Link> or <Text>
 *
 * Note: openDelay is 750 hardcoded but it can be overwritten through props
 */

export const OverflowTooltip: FC<OverflowTooltipProps & TooltipProps> = ({ label, children, ...props }: OverflowTooltipProps & TooltipProps) => {
	const ref = useRef<HTMLDivElement>(null);
	const isOverflowing = useIsOverflowing(ref);

	return (
		<Tooltip label={label} openDelay={TOOLTIP_SHOW_DELAY} isDisabled={!isOverflowing} {...props}>
			<Box ref={ref} whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
				{children}
			</Box>
		</Tooltip>
	);
};

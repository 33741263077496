import { Dispatch, useRef, useState } from 'react';
import { ENDPOINTS } from 'shared/endpoints';
import { PageViewModel } from 'shared/models';
import { useGet } from 'shared/react-query-wrappers/hooks';
import { useCreateEvents } from 'widgets/site-structure/hooks/use-create-events';
import { useDeleteEvents } from 'widgets/site-structure/hooks/use-delete-events';
import { useDuplicateEvents } from 'widgets/site-structure/hooks/use-duplicate-events';
import { useRenameEvents } from 'widgets/site-structure/hooks/use-rename-events';
import { PageAnimationState, pageAnimationStateDefault } from 'widgets/site-structure/models/props';

export type PageStructureEventsProps = {
	siteId: string;
	children: PageViewModel[];
	refetchCurrentBranch: () => Promise<any>;
	page?: PageViewModel | undefined;
	parentId?: string | undefined;
	setIsOpen?: (isOpen: boolean) => void;
};

export type PageStructureEventsAllProps = PageStructureEventsProps & {
	documentRef: React.MutableRefObject<Document>;
	setAnimationState: Dispatch<React.SetStateAction<PageAnimationState>>;
	refetchIfParent: () => Promise<any>;
	refetchIfNoParent: () => Promise<any>;
};

export const usePageStructureEvents = (props: PageStructureEventsProps) => {
	const { parentId, siteId } = props;
	const documentRef = useRef<Document>(document);
	const [animationState, setAnimationState] = useState<PageAnimationState>(pageAnimationStateDefault);

	const { refetch: refetchIfParent } = useGet({
		queryKey: { url: ENDPOINTS.HIERARCHY_PAGE, params: { siteId, pageId: parentId || '' } },
		enabled: false,
	});

	const { refetch: refetchIfNoParent } = useGet({
		queryKey: { url: ENDPOINTS.HIERARCHY, params: { siteId } },
		enabled: false,
	});
	const commonProps = { ...props, documentRef, animationState, setAnimationState, refetchIfNoParent, refetchIfParent };

	useDeleteEvents(commonProps);

	useCreateEvents(commonProps);

	useDuplicateEvents(commonProps);

	useRenameEvents(commonProps);

	return animationState;
};

import { SkeletonText, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { useSitesCounter } from 'widgets/sites-header/hooks';

export const SitesCounter: FC = () => {
	const { collectionsCount, sitesCount, isLoading } = useSitesCounter();

	if (collectionsCount === 0 && sitesCount === 0 && !isLoading) return <></>;

	return (
		<SkeletonText size="sm" noOfLines={1} skeletonHeight="4" borderRadius="6" overflow="hidden" isLoaded={!isLoading}>
			<Text variant="tiny" data-testid="sitesCollectionCount">
				<T _str={DICTIONARY.SITES_HEADER_COUNT_COLLECTIONS_AND_SITES} collectionsCount={collectionsCount} sitesCount={sitesCount} />
			</Text>
		</SkeletonText>
	);
};

import { Flex, FlexProps } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';

export const PageBody: FC<PropsWithChildren<FlexProps>> = ({ children, ...props }) => {
	return (
		<Flex {...props} flex="1" direction="row" overflow="hidden">
			{children}
		</Flex>
	);
};

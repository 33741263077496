import { Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { CreateSiteDefinitionModalProps } from 'features/site-definitions/models';
import { CreateSiteDefinitionForm } from 'features/site-definitions/ui/create-site-definition-form';
import { FC, PropsWithChildren } from 'react';
import { DICTIONARY } from 'shared/constants';
import { useModalWrapper } from 'shared/modal-wrapper/hooks';
import { ModalWrapperProps } from 'shared/modal-wrapper/models';

type CreateSiteDefinitionModalType = CreateSiteDefinitionModalProps & ModalWrapperProps;

export const CreateSiteDefinitionModal: FC<PropsWithChildren<CreateSiteDefinitionModalType>> = ({ children, onButtonClick, host, ...props }) => {
	const { isOpen, onClose, children: childrenElements } = useModalWrapper({ children, onButtonClick });

	return (
		<>
			{childrenElements}
			<Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						{host ? <T _str={DICTIONARY.DUPLICATE_ENTITY} name={host?.name} /> : <T _str={DICTIONARY.CREATE_SITE_DEFINITION} />}
					</ModalHeader>
					<ModalCloseButton />
					<CreateSiteDefinitionForm onClose={onClose} host={host} {...props} />
				</ModalContent>
			</Modal>
		</>
	);
};

import { Button, ButtonGroup, IconButton, Tooltip } from '@chakra-ui/react';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import { Icon } from 'entities/icon/ui';
import { useTranslate } from 'features/common/utils/translateUtils';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { PagingProps } from 'shared/paging/models';

type SimplePagingProps = PagingProps & {
	startIndex?: number;
};

type NavigateButtonsProps<T> = PagingProps & {
	type: T;
};

const getSizedArray = (length: number, start = 0) => Array.from({ length }, (_, i) => start + i + 1);

const NavigateBackForwardButtons: FC<NavigateButtonsProps<'previous' | 'next'>> = ({ type, page, total, onNavigate }) => {
	const t = useTranslate();
	const isNext = type === 'next';
	const pageMove = isNext ? page + 1 : page - 1;
	const isDisabled = isNext ? page === total : page === 1;

	return (
		<Tooltip label={isNext ? t(DICTIONARY.NEXT) : t(DICTIONARY.PREVIOUS)}>
			<IconButton
				data-testid={isNext ? 'next-page' : 'previous-page'}
				isDisabled={isDisabled}
				aria-label={isNext ? t(DICTIONARY.NEXT) : t(DICTIONARY.PREVIOUS)}
				icon={<Icon path={isNext ? mdiChevronRight : mdiChevronLeft} />}
				onClick={() => onNavigate(pageMove)}
			/>
		</Tooltip>
	);
};

const NavigateFirstLastPageButtons: FC<NavigateButtonsProps<'first' | 'last'>> = ({ page, total, type, onNavigate }) => {
	const condition = type === 'first' ? page >= 5 : page < total - 3;

	return (
		<>
			{condition && (
				<>
					{type === 'first' && (
						<Button data-testid="first-page" onClick={() => onNavigate(1)}>
							1
						</Button>
					)}
					<Button data-testid="page-separator" isDisabled>
						…
					</Button>
					{type === 'last' && (
						<Button data-testid="last-page" onClick={() => onNavigate(total)}>
							{total}
						</Button>
					)}
				</>
			)}
		</>
	);
};

const SimplePaging: FC<SimplePagingProps> = ({ total, page, onNavigate, startIndex = 0 }) => (
	<>
		{getSizedArray(total, startIndex).map((value) => (
			<Button key={value} data-testid={`page-${value}`} isActive={value === page} onClick={() => onNavigate(value)}>
				{value}
			</Button>
		))}
	</>
);

const AdvancedPagingButtons: FC<PagingProps> = ({ page, total, onNavigate }) => {
	if (page <= 4) {
		return <SimplePaging onNavigate={onNavigate} page={page} total={5} />;
	}

	if (page >= total - 3) {
		return <SimplePaging onNavigate={onNavigate} page={page} total={5} startIndex={total - 5} />;
	}

	return (
		<>
			<Button data-testid={`page-${page - 1}`} onClick={() => onNavigate(page - 1)}>
				{page - 1}
			</Button>
			<Button data-testid={`page-${page}`} isActive>
				{page}
			</Button>
			<Button data-testid={`page-${page + 1}`} onClick={() => onNavigate(page + 1)}>
				{page + 1}
			</Button>
		</>
	);
};

const AdvancedPaging: FC<PagingProps> = ({ page, total, onNavigate }) => (
	<>
		<NavigateFirstLastPageButtons type="first" onNavigate={onNavigate} page={page} total={total} />
		<AdvancedPagingButtons onNavigate={onNavigate} page={page} total={total} />
		<NavigateFirstLastPageButtons type="last" onNavigate={onNavigate} page={page} total={total} />
	</>
);

export const Paging: FC<PagingProps> = ({ total, ...props }) => {
	if (total < 2) {
		return <></>;
	}

	return (
		<ButtonGroup data-testid="paging" size="sm" variant="pagination" spacing="1">
			<NavigateBackForwardButtons type="previous" total={total} {...props} />
			{total <= 7 ? <SimplePaging total={total} {...props} /> : <AdvancedPaging total={total} {...props} />}
			<NavigateBackForwardButtons type="next" total={total} {...props} />
		</ButtonGroup>
	);
};

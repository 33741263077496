import { useCallback } from 'react';
import {
	DUPLICATE_PAGE_ERROR_EVENT_NAME,
	DUPLICATE_PAGE_START_EVENT_NAME,
	DUPLICATE_PAGE_SUCCESS_EVENT_NAME,
	DuplicatePageErrorEvent,
	DuplicatePageStartEvent,
	DuplicatePageSuccessEvent,
} from 'shared/site-structure';
import { isSameGuid } from 'shared/utils';
import { useEventListener } from 'usehooks-ts';
import { PageStructureEventsAllProps } from 'widgets/site-structure/hooks/use-page-structure-events';

export const useDuplicateEvents = ({ children, documentRef, refetchCurrentBranch, setAnimationState }: PageStructureEventsAllProps) => {
	const onDuplicatePageStart = useCallback(
		(event: CustomEvent<DuplicatePageStartEvent>) => {
			if (children.find((child) => isSameGuid(child.id, event.detail.pageId))) {
				setAnimationState((animationState) => ({
					...animationState,
					creating: {
						direction: 'after',
						id: event.detail.pageId,
						name: event.detail.name,
					},
					isDuplicate: true,
				}));
			}
		},
		[children, setAnimationState]
	);

	const onDuplicatePageSuccess = useCallback(
		(event: CustomEvent<DuplicatePageSuccessEvent>) => {
			if (children.find((child) => isSameGuid(child.id, event.detail.pageId))) {
				void refetchCurrentBranch().then(() => {
					setAnimationState((animationState) => ({
						...animationState,
						creating: undefined,
						isDuplicate: true,
					}));
				});
			}
		},
		[children, refetchCurrentBranch, setAnimationState]
	);

	const onDuplicatePageError = useCallback(
		(event: CustomEvent<DuplicatePageErrorEvent>) => {
			if (children.find((child) => isSameGuid(child.id, event.detail.pageId))) {
				setAnimationState((animationState) => ({
					...animationState,
					creating: undefined,
					isDuplicate: false,
				}));
			}
		},
		[children, setAnimationState]
	);

	useEventListener(DUPLICATE_PAGE_START_EVENT_NAME, onDuplicatePageStart, documentRef);
	useEventListener(DUPLICATE_PAGE_SUCCESS_EVENT_NAME, onDuplicatePageSuccess, documentRef);
	useEventListener(DUPLICATE_PAGE_ERROR_EVENT_NAME, onDuplicatePageError, documentRef);
};

import { useApolloClient, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { RenameSiteInput, RenameSitePayload } from '../data/createSiteTypes';
import { renameSiteMutation } from '../queries';
import { useWaitJobResult } from './useWaitJobResult';

export type RenameStatus = {
	success: boolean;
	errors: string[];
};

export const useRenameSite = () => {
	const client = useApolloClient();
	const [renameSiteMutationFn] = useMutation<RenameSitePayload, RenameSiteInput>(renameSiteMutation);
	const { waitJobResult } = useWaitJobResult({ iddleOnStart: 1000, interval: 2000, maxPollCount: 5 });
	const renameSite = useCallback(
		async (siteName: string, newName: string): Promise<RenameStatus> => {
			const { data } = await renameSiteMutationFn({
				variables: {
					newName,
					siteName,
				},
			});

			if (!data) {
				return {
					success: false,
					errors: ['Failed to get data from rename response'],
				};
			}

			const jobResult = await waitJobResult(data.renameSite.job.name, data.renameSite.job.handle);

			if (!jobResult.success) {
				return {
					success: false,
					errors: jobResult.errors,
				};
			}

			await client.refetchQueries({
				include: ['GetSitesAndJobsList'],
			});

			return {
				success: true,
				errors: [],
			};
		},
		[renameSiteMutationFn, waitJobResult, client]
	);

	return {
		renameSite,
	};
};

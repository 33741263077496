import { CheckPermissionsProps, SitesPermissionsResponse } from 'features/site-permissions/models';
import { ENDPOINTS } from 'shared/endpoints';
import { useSimpleGet } from 'shared/react-query-wrappers/hooks';

export const useCheckPermissions = (): CheckPermissionsProps => {
	const { data, isLoading } = useSimpleGet<SitesPermissionsResponse>(ENDPOINTS.SITE_PERMISSIONS, true);

	const permissions = data ?? { canAdmin: false, canWrite: false, canCreate: false, canDelete: false, canRead: false, canRename: false };

	return { ...permissions, isLoading };
};

import { useToast } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { DeletePageInput, UseDeletePageProps } from 'features/page-actions-menu/models/use-delete-page-props';
import { useCallback } from 'react';
import { DICTIONARY } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { useDelete } from 'shared/react-query-wrappers/hooks';
import { dispatchDeletePageErrorEvent, dispatchDeletePageStartEvent, dispatchDeletePageSuccessEvent } from 'shared/site-structure';

export const useDeletePage: UseDeletePageProps = (pageId, pageName, onClose) => {
	const { mutateAsync } = useDelete<string, DeletePageInput>({
		queryKey: { url: ENDPOINTS.PAGE, params: { pageId } },
	});
	const toast = useToast();

	const submitHandler = useCallback(async () => {
		onClose();
		dispatchDeletePageStartEvent(pageId);

		const promise = mutateAsync({ permanently: true, forceDelete: true });

		toast.promise(promise, {
			loading: { description: <T _str={DICTIONARY.DELETE_PAGE_PROMISE_TOAST.LOADING} pageName={pageName} /> },
			error: { description: <T _str={DICTIONARY.DELETE_PAGE_PROMISE_TOAST.ERROR} /> },
			success: { description: <T _str={DICTIONARY.DELETE_PAGE_PROMISE_TOAST.SUCCESS} /> },
		});

		// Dispatch page events are responsible for updating the UI
		promise.catch(() => dispatchDeletePageErrorEvent(pageId)).finally(() => dispatchDeletePageSuccessEvent(pageId));
	}, [mutateAsync, onClose, pageId, pageName, toast]);

	return { submitHandler };
};

import { MenuItem } from '@chakra-ui/react';
import { iconExplorer, iconPages } from '@sitecore/blok-theme';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { useRedirectSite } from 'features/site-actions-menu/hooks';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';

type RedirectLinkProps = {
	siteName: string;
};

/**
 * @author Panagiotis Dalakouras
 * @deprecated remove after showSitesGrouping is stable and permanent
 */
export const PageRedirectMenuItem: FC<RedirectLinkProps> = ({ siteName }) => {
	const { pageRedirectHandler } = useRedirectSite();

	return (
		<MenuItem icon={<Icon path={iconPages} />} onClick={(e) => pageRedirectHandler(e, siteName)} data-testid="openInPagesMenuButton">
			<T _str={DICTIONARY.OPEN_IN_PAGES} />
		</MenuItem>
	);
};

/**
 * @author Panagiotis Dalakouras
 * @deprecated remove after showSitesGrouping is stable and permanent
 */
export const ExplorerRedirectMenuItem: FC<RedirectLinkProps> = ({ siteName }) => {
	const { explorerRedirectHandler } = useRedirectSite();

	return (
		<MenuItem icon={<Icon path={iconExplorer} />} onClick={(e) => explorerRedirectHandler(e, siteName)} data-testid="openInExplorerMenuButton">
			<T _str={DICTIONARY.EDIT_CONTENT_IN_EXPLORER} />
		</MenuItem>
	);
};

import { useDisclosure } from '@chakra-ui/react';
import { mdiAccountCircleOutline, mdiHelpCircleOutline, mdiLogout } from '@mdi/js';
import { Icon } from 'entities/icon/ui';
import { useAuthWithClaims } from 'features/common/auth/useAuthWithClaims';
import config from 'features/common/config/config';
import { IGetUserData } from 'features/common/services/userModel';
import { useTranslate } from 'features/common/utils/translateUtils';
import { HelpDrawerWrapper } from 'features/help-drawer/ui';
import { FC, useMemo, useState } from 'react';
import { Location, NavigateOptions, useLocation } from 'react-router';
import { DICTIONARY, SC_ICONS_SRC } from 'shared/constants';
import { useNavigateToRoute } from 'shared/navigation/hooks';
import { NavigationHorizontal } from 'shared/navigation/ui';
import { useSimpleGet } from 'shared/react-query-wrappers/hooks';
import { ROUTES } from 'shared/routes';
import { AnnotationDetails } from './annotation-details';
import { AppSwitcher } from './app-switcher';

type NavigationItems = {
	location: Location;
	navigate: (routePath: string, options?: NavigateOptions) => void;
};

const navigationItemsGetter = ({ location, navigate }: NavigationItems) => {
	return [
		{
			label: 'Sites',
			onClick: () => navigate('/'),
			active: location?.pathname === '/',
		},
		{
			label: 'Tools',
			onClick: () => navigate(`/${ROUTES.TOOLS.NAME}`),
			active: location?.pathname.startsWith(`/${ROUTES.TOOLS.NAME}`),
		},
		{
			label: 'System settings',
			onClick: () => navigate(`/${ROUTES.SITE_SETTINGS.SELF}`),
			active: location?.pathname.startsWith(`/${ROUTES.SITE_SETTINGS.SELF}`),
		},
	];
};

export const TopBar: FC = () => {
	const location = useLocation();
	const navigate = useNavigateToRoute();
	const { logout } = useAuthWithClaims();
	const { data: currentUser } = useSimpleGet<IGetUserData>(config.identity.get_user.url);
	const t = useTranslate();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [isHelpDrawerAvailable, setIsHelpDrawerAvailable] = useState(true);

	const name = currentUser?.givenName && currentUser.familyName ? `${currentUser.givenName} ${currentUser.familyName}` : '';
	const email = currentUser?.email || '';

	const memoizedItems = useMemo(() => {
		const navigationItems = navigationItemsGetter({
			location,
			navigate,
		});

		return [...navigationItems];
	}, [location, navigate]);

	return (
		<>
			<NavigationHorizontal
				icon={<AppSwitcher />}
				items={memoizedItems}
				image={{
					cursor: 'pointer',
					src: SC_ICONS_SRC.XM_CLOUD_WITH_TEXT,
					onClick: () => navigate('/'),
				}}
				annotations={<AnnotationDetails mr="4" />}
				actions={[
					{
						icon: <Icon path={mdiHelpCircleOutline} />,
						onClick: () => {
							setIsHelpDrawerAvailable(true);
							onOpen();
						},
						tooltip: t(DICTIONARY.HELP),
					},
				]}
				menu={{
					name,
					email,
					items: [
						{
							label: t(DICTIONARY.MANAGE_ACCOUNT),
							icon: <Icon path={mdiAccountCircleOutline} />,
							onClick: () => window.open(config.portal.profile_manage),
						},
						{
							label: t(DICTIONARY.LOG_OUT),
							icon: <Icon path={mdiLogout} />,
							onClick: () => void logout({ logoutParams: { returnTo: window.location.origin } }),
						},
					],
				}}
			/>
			<HelpDrawerWrapper
				isHelpDrawerAvailable={isHelpDrawerAvailable}
				setIsHelpDrawerAvailable={setIsHelpDrawerAvailable}
				isOpen={isOpen}
				onClose={onClose}
			/>
		</>
	);
};

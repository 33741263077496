import { LanguagePayload } from 'features/common/languages';
import { DetailedItem } from 'features/millerColumns/models/item.model';
import { PosMapping, PosPayload } from 'features/pos/utils/types';
import { TypeFilterUrlParams } from 'features/sites/utils/useTypeFilter';

export type CreateSiteInput = {
	templateId: string;
	siteName: string;
	language: string;
	posMappings?: PosMapping[];
};

export type RenameSiteInput = {
	siteName: string;
	newName: string;
};

export type CreateSiteFodmData = {
	templateId: string;
	siteName: string;
	language: LanguagePayload;
	pos?: PosPayload;
};

export const siteInputDefaultValues: CreateSiteFodmData = {
	templateId: '',
	siteName: '',
	language: {
		name: 'en',
		iso: 'en',
		englishName: 'English',
	},
};

export type SiteTemplatePayload = {
	id: string;
	name: string;
	enabled: boolean;
	description?: string;
	thumbnailUrl?: string;
};

export type SolutionTemplates = {
	solutionTemplates: SiteTemplatePayload[];
};

export type SiteInfo = {
	id: string;
	name: string;
	imageSrc?: string;
	languages: string[];
} & RootItemIdField &
	SiteCollectionFields;

export type LanguageIso = {
	language: {
		iso?: string;
	};
};

export type VersionsCollection = {
	versions: LanguageIso[];
};

export type SolutionSiteType = {
	displayName: string;
	id: string;
	name: string;
	thumbnailUrl?: string;
	created: string;
	startItem?: VersionsCollection;
} & RootItemField &
	SiteCollectionField;

export type SolutionSites<T> = {
	solutionSites?: T[];
};

export type CreateSitePayload = {
	job: {
		name: string;
		handle: string;
		done: boolean;
		status: {
			exceptions: string[];
		};
	};
};

export type RenameSitePayload = {
	renameSite: JobPayload;
};

export type JobPayload = {
	job: SiteJob;
};

export type SiteJob = {
	name: string;
	done: boolean;
	queueTime: string;
	handle: string;
	status?: {
		exceptions: string[];
	};
};

export type JobOperation = 'create' | 'remove' | 'duplicate' | 'other';

export type SiteJobViewModel = {
	siteName: string;
	operation: JobOperation;
} & SiteJob;

export type SiteJobNodes = {
	nodes: SiteJob[];
};

export type SiteJobPayload = {
	jobs: SiteJobNodes;
};

export type SitesAndJobsPayload = SiteJobPayload & SolutionSites<SolutionSiteType>;

export type UpdateSitePosStatus = {
	id: string;
	success: boolean;
};

export type UpdateSitesPosPayload = {
	updateSolutionSitesPos?: {
		result?: UpdateSitePosStatus[];
	};
};

export type UpdateSolutionSitesPosInput = {
	posMappingsInput: SitePosMappingsInput[];
};

export type SitePosMappingsInput = {
	id: string;
	newValue: PosMapping[];
};

export type SiteWithPosPayload = {
	id: string;
	name: string;
	language: {
		name: string;
		iso: string;
	};
	posMappings?: PosMapping[];
	settings?: SiteSettings;
} & RootItemField &
	SiteCollectionField;

export type SiteSettings = {
	appName: {
		value: string;
	};
};

export type SiteWithPos = {
	id: string;
	name: string;
	languageIso: string;
	posMappings: PosMapping[];
	appName: string | undefined;
} & RootItemIdField &
	RootItemFields &
	SiteCollectionFields;

export type A = Pick<SolutionSiteType, 'id' | 'name'>;
export type RootItemField = {
	rootItem?: Pick<DetailedItem, 'itemId' | 'name' | 'path' | 'template' | 'createdBy'>;
};
export type RootItemIdField = {
	rootItemId?: string;
};
export type RootItemFields = {
	rootItemName?: string;
	rootItemPath?: string;
	rootItemCreatedBy?: string;
	rootItemTemplateName?: string;
	rootItemTemplateId?: string;
};

export type SiteCollectionField = {
	siteCollection?: SiteCollection;
};
export type SiteCollectionFields = {
	siteCollectionName?: string;
	siteCollectionId?: string;
	siteTypeByCollection: TypeFilterUrlParams;
};

export type SiteCollection = {
	displayName: string;
	id: string;
};
export type SiteCollections = {
	siteCollections: SiteCollection[];
};
export type SiteCollectionViewModel = {
	name: string;
	id: string | undefined;
};

export type RemoveSiteInput = {
	siteName: string;
};
export type RemoveSitePayload = CreateSitePayload;

export type CloneSiteInput = {
	siteName: string;
	cloneName: string;
};
export type CloneSitePayload = {
	cloneSite: JobPayload;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
const domain: string = (window as any)?.env?.REACT_APP_AUTH0_DOMAIN || process.env.REACT_APP_AUTH0_DOMAIN;
const clientId: string = (window as any)?.env?.REACT_APP_AUTH0_CLIENT_ID || process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience: string = (window as any)?.env?.REACT_APP_AUTH0_AUDIENCE || process.env.REACT_APP_AUTH0_AUDIENCE;
const tenantRegions: string = (window as any)?.env?.REACT_APP_TENANT_REGIONS_MAPPER || process.env.REACT_APP_TENANT_REGIONS_MAPPER || '';

export const identityBaseUrl: string = (window as any)?.env?.REACT_APP_IDENTITY_BASE_URL || process.env.REACT_APP_IDENTITY_BASE_URL;
export const portalBaseUrl: string = (window as any)?.env?.REACT_APP_PORTAL_BASE_URL || process.env.REACT_APP_PORTAL_BASE_URL;
export const inventoryBaseUrl: string = (window as any)?.env?.REACT_APP_INVENTORY_BASE_URL || process.env.REACT_APP_INVENTORY_BASE_URL;
export const inventoryXmCloudSystemId: string =
	(window as any)?.env?.REACT_APP_INVENTORY_XM_CLOUD_SYSTEMID || process.env.REACT_APP_INVENTORY_XM_CLOUD_SYSTEMID;
export const explorerBaseUrl: string = (window as any)?.env?.REACT_APP_EXPLORER_BASE_URL || process.env.REACT_APP_EXPLORER_BASE_URL;
export const formsBaseUrl: string = (window as any)?.env?.REACT_APP_FORMS_BASE_URL || process.env.REACT_APP_FORMS_BASE_URL;
export const analyticsBaseUrl: string = (window as any)?.env?.REACT_APP_ANALYTICS_BASE_URL || process.env.REACT_APP_ANALYTICS_BASE_URL;
export const pagesUri: string = (window as any)?.env?.REACT_APP_PAGES_BASE_URL || process.env.REACT_APP_PAGES_BASE_URL;
export const componentsBaseUrl: string = (window as any)?.env?.REACT_APP_COMPONENTS_BASE_URL || process.env.REACT_APP_COMPONENTS_BASE_URL;

export const environment: string = (window as any)?.env?.REACT_APP_ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT;
export const targetedEnvName: string = (window as any)?.env?.REACT_APP_TARGETED_ENVIRONMENT_NAME || process.env.REACT_APP_TARGETED_ENVIRONMENT_NAME;
export const xmCloudDeployBaseUrl: string = (window as any)?.env?.REACT_APP_XMCLOUD_DEPLOY_BASE_URL || process.env.REACT_APP_XMCLOUD_DEPLOY_BASE_URL;
export const xmCloudDeployAPIBaseUrl: string =
	(window as any)?.env?.REACT_APP_XMCLOUD_DEPLOY_API_BASE_URL || process.env.REACT_APP_XMCLOUD_DEPLOY_API_BASE_URL;
export const minimalXmCloudVersion: string = (window as any)?.env?.REACT_APP_MINIMAL_XMCLOUD_VERSION || process.env.REACT_APP_MINIMAL_XMCLOUD_VERSION;
export const featureFlagsBaseUrl: string = (window as any)?.env?.REACT_APP_FEATURE_FLAGS_BASE_URL || process.env.REACT_APP_FEATURE_FLAGS_BASE_URL;
export const edgeGraphQlUrl: string = (window as any)?.env?.REACT_APP_EDGE_GRAPHQL_URL || process.env.REACT_APP_EDGE_GRAPHQL_URL;
export const sitecoreXMappsApiURL: string = (window as any)?.env?.REACT_APP_SITECORE_XMAPPS_API_URL || process.env.REACT_APP_SITECORE_XMAPPS_API_URL;
export const tenantRegionsMapper = tenantRegions
	.split(';')
	.map((region) => {
		const value = region.split('=').map((item) => item.trim());

		return value;
	})
	.reduce<{ [key: string]: string }>((r, a) => {
		r[a[0]] = a[1];

		return r;
	}, Object.create(null));

/**
 * Service to send apm events from this app to.
 * Setting this url also enables APM.
 */
export const apmServerUrl: string = (window as any)?.env?.REACT_APP_APM_SERVER_URL || process.env.REACT_APP_APM_SERVER_URL;

/**
 * The label this app appears in APM with, in the list of services.
 * Allowed characters: a-z, A-Z, 0-9, -, _, and space.
 * Optional. Defaults to 'XM Apps Dashboard UI'.
 */
export const apmServiceName: string =
	(window as any)?.env?.REACT_APP_APM_SERVICE_NAME || process.env.REACT_APP_APM_SERVICE_NAME || 'XM Apps Dashboard UI';

export const getAuthSettings = () => {
	return { domain, clientId, audience };
};

import { Button } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { RemoveSitePropsEA } from 'features/remove-site/models';
import { RemoveSiteModalEA } from 'features/remove-site/ui';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';

/**
 * @deprecated Update is blocked for EA
 */
export const RemoveSiteButtonEA: FC<RemoveSitePropsEA> = (props) => {
	return (
		<RemoveSiteModalEA {...props}>
			<Button width="fit-content" colorScheme="danger">
				<T _str={DICTIONARY.DELETE_SITE} />
			</Button>
		</RemoveSiteModalEA>
	);
};

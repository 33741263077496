import { HOSTNAME_DELIMITER } from 'shared/constants';

export type SiteDefinitionViewModel = {
	name: string;
	homePageId: string;
	language: string;
	languageEmbedding: boolean;
	itemLanguageFallback: boolean;
	fieldLanguageFallback: boolean;
	linkableSite: boolean;
	hostname: string;
	targetHostname: string;
	renderingHost: string;
	validForEnvironment?: string;
	// linkProviderName?: string;
} & ExtraThumbnailFields;

export type EditSiteDefinitionInput = {
	name: string;
	hostnames: string[];
	targetHostname: string;
	renderingHost: string;
	homePageId: string;
	settings: Record<string, object | string>;
	languageSettings: {
		defaultLanguage: string;
		languageEmbedding: boolean;
		itemLanguageFallback: boolean;
		fieldLanguageFallback: boolean;
	};
};

export const mapToBoolean = (value: string | boolean | string[] | { value: string; label: string }): boolean =>
	typeof value === 'boolean' ? value : value === 'true';

export const mapSiteSettingsViewModelToEditSiteInput = (
	viewModel: Omit<SiteDefinitionViewModel, 'mode, thumbnailBase64Url, toBeDeleted'>
): EditSiteDefinitionInput => {
	return {
		homePageId: viewModel.homePageId,
		languageSettings: {
			defaultLanguage: viewModel.language,
			languageEmbedding: mapToBoolean(viewModel.languageEmbedding),
			itemLanguageFallback: mapToBoolean(viewModel.itemLanguageFallback),
			fieldLanguageFallback: mapToBoolean(viewModel.fieldLanguageFallback),
		},
		hostnames: viewModel.hostname.split(HOSTNAME_DELIMITER),
		settings: {
			sxaLinkable: mapToBoolean(viewModel.linkableSite).toString(),
			// linkProvider: viewModel.linkProviderName || '',
		},
		targetHostname: viewModel.targetHostname,
		renderingHost: (viewModel.renderingHost as unknown as string) || '',
		name: viewModel.name,
	};
};

export type ExtraThumbnailFields = {
	mode: string;
	thumbnailBase64Url: string;
	toBeDeleted: string;
};

import { useCdpTenant } from 'features/common/tenants/CdpTenantProvider';
import { isError } from 'features/common/utils/errorUtils';
import { useGetSitesWithPos } from 'features/sites/utils/useGetSitesWithPos';
import { useCallback, useMemo } from 'react';
import { getMissingPos } from './missingPosService';
import { useCreatePOS, useGetAllPOS } from './posService';

export type UseSyncPosResult = {
	synchronizePos: () => Promise<void>;
	isSynchronized: boolean;
	isVerifying: boolean;
};

export type UsePosSynchronizationOptions = {
	onSuccess?: () => void;
	onError?: (error: string) => void;
};

export const usePosSynchronization = (options?: UsePosSynchronizationOptions): UseSyncPosResult => {
	const { onSuccess, onError } = options || {};
	const { state } = useCdpTenant();
	const { posList, isLoading: loadingPOS } = useGetAllPOS();
	const createPos = useCreatePOS();
	const { sites, loading: loadingSites } = useGetSitesWithPos({ fetchPolicy: 'network-only' });
	const missingPos = useMemo(() => getMissingPos(sites, posList), [sites, posList]);

	const synchronizePos = useCallback(async () => {
		if (state != 'resolved') {
			return;
		}

		let syncState = true;

		try {
			if (missingPos.length > 0) {
				for (const cdpPos of missingPos) {
					await createPos(cdpPos.posName, cdpPos.posLanguage, 'global', cdpPos.posName, 30);
				}
			}
		} catch (error: unknown) {
			let msg = 'Failed to synchronize POS';

			if (isError(error)) {
				msg = error.message;
			} else if (typeof error == 'string') {
				msg = error;
			}

			onError?.(msg);
			syncState = false;
		}

		if (syncState) {
			onSuccess?.();
		}
	}, [createPos, missingPos, state, onError, onSuccess]);

	return {
		isSynchronized: missingPos.length == 0,
		isVerifying: loadingPOS || loadingSites,
		synchronizePos,
	};
};

import { IconButton, Tooltip, useDisclosure } from '@chakra-ui/react';
import { mdiMinusCircleOutline } from '@mdi/js';
import { Icon } from 'entities/icon/ui';
import { useTranslate } from 'features/common/utils/translateUtils';
import { PosModalWrapperProps } from 'features/point-of-sale/models';
import { UnassignPosModal } from 'features/point-of-sale/ui';
import { FC, PropsWithChildren } from 'react';
import { DICTIONARY } from 'shared/constants';

export const UnassignPosIconButton: FC<PropsWithChildren<PosModalWrapperProps>> = (props) => {
	const { isOpen, onClose, onOpen } = useDisclosure();
	const t = useTranslate();

	return (
		<>
			<Tooltip label={DICTIONARY.UNASSIGN}>
				<IconButton
					size="sm"
					variant="ghost"
					aria-label={t(DICTIONARY.UNASSIGN_ANALYTICS_IDENTIFIER)}
					icon={<Icon path={mdiMinusCircleOutline} onClick={onOpen} />}
					data-testid="create-unassign-pos-button-by-lang"
				/>
			</Tooltip>
			<UnassignPosModal {...props} isOpen={isOpen} onClose={onClose} />
		</>
	);
};

export const overrideProps = ['borderWidth', 'borderRadius'] as const;

export type ComponentPropMapping = { [key: string]: { [key: string]: string } };
export const cardSizes: ComponentPropMapping = {
	sm: {
		minW: '135px',
		maxW: '299px',
	},
	md: {
		minW: '300px',
		maxW: '449px',
	},
	lg: {
		minW: '450px',
		maxW: '600px',
	},
};

export const cardAvatarSizes: ComponentPropMapping = {
	sm: {
		w: '24px',
		h: '24px',
	},
	md: {
		w: '40px',
		h: '40px',
	},
};

export const imgColumnSizes: ComponentPropMapping = {
	sm: {
		h: '75px',
	},
	md: {
		h: '225px',
	},
	lg: {
		h: '225px',
	},
};

export const imgRowSizes: ComponentPropMapping = {
	md: {
		w: '105px',
	},
	lg: {
		w: '160px',
	},
};

import { IconButton, MenuItem, Tooltip } from '@chakra-ui/react';
import { mdiContentCopy } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { useTranslate } from 'features/common/utils/translateUtils';
import { SiteDuplicateModalWrapper } from 'features/duplicate-modal/ui/site-duplicate-modal-wrapper';
import { FC } from 'react';
import { DICTIONARY, TOOLTIP_SHOW_DELAY } from 'shared/constants';
import { Access } from 'shared/models';

type SiteDuplicateButtonWrapperProps = {
	siteName: string;
	siteId: string;
	siteAccess: Access;
	description: string;
	siteLanguage: string;
	isStandAlone?: boolean;
};

export const SiteDuplicateButtonWrapper: FC<SiteDuplicateButtonWrapperProps> = ({
	siteAccess,
	siteName,
	siteId,
	description,
	siteLanguage,
	isStandAlone = false,
}) => {
	const t = useTranslate();

	return (
		<Tooltip
			label={!siteAccess.canCreate ? t(DICTIONARY.ACCESS_DENIED.SITE_DUPLICATE) : isStandAlone ? t(DICTIONARY.DUPLICATE_SITE_TOOLTIP) : ''}
			openDelay={isStandAlone ? TOOLTIP_SHOW_DELAY : 0}
			shouldWrapChildren={true}
		>
			<SiteDuplicateModalWrapper originName={siteName} siteId={siteId} description={description} siteLanguage={siteLanguage}>
				{isStandAlone ? (
					<IconButton
						icon={<Icon path={mdiContentCopy} />}
						isDisabled={!siteAccess.canCreate}
						data-testid="duplicateSiteIconButton"
						aria-label={t(DICTIONARY.DUPLICATE_SITE_TOOLTIP)}
						variant="ghost"
					/>
				) : (
					<MenuItem icon={<Icon path={mdiContentCopy} />} isDisabled={!siteAccess.canCreate} data-testid="duplicateSiteMenuButton">
						<T _str={DICTIONARY.DUPLICATE} />
					</MenuItem>
				)}
			</SiteDuplicateModalWrapper>
		</Tooltip>
	);
};

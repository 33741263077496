import { MenuItem } from '@chakra-ui/react';
import { mdiPencilOutline } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { OpenInPagesProps } from 'shared/open-in-pages/models';
import { OpenInPagesWrapper } from 'shared/open-in-pages/ui/open-in-pages-wrapper';

export const OpenInPagesMenuItem: FC<OpenInPagesProps> = (props) => {
	return (
		<OpenInPagesWrapper {...props}>
			<MenuItem icon={<Icon path={mdiPencilOutline} />}>
				<T _str={props.itemId ? DICTIONARY.OPEN_IN_PAGES : DICTIONARY.EDIT_SITE} />
			</MenuItem>
		</OpenInPagesWrapper>
	);
};

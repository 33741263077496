import {
	Box,
	Button,
	ButtonGroup,
	Center,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Slider,
	SliderFilledTrack,
	SliderThumb,
	SliderTrack,
} from '@chakra-ui/react';
import { mdiMagnify } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { FC, useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Area, Point } from 'react-easy-crop/types';
import { DICTIONARY } from 'shared/constants';

type ThumbnailCropModalProps = {
	imgBase64: string;
	isOpen: boolean;
	onClose: () => void;
	setCroppedAreaPixels: (croppedAreaPixels: Area) => void;
	uploadCroppedImage: () => Promise<void>;
};

export const ThumbnailCropModal: FC<ThumbnailCropModalProps> = ({ imgBase64, isOpen, onClose, setCroppedAreaPixels, uploadCroppedImage }) => {
	const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [isCropperReady, setIsCropperReady] = useState(false);

	const onCropComplete = (_croppedArea: Area, croppedAreaPixels: Area) => {
		setCroppedAreaPixels(croppedAreaPixels);
	};

	useEffect(() => {
		if (isOpen) {
			const timer = setTimeout(() => setIsCropperReady(true), 150);

			return () => clearTimeout(timer);
		} else {
			setIsCropperReady(false);
		}
	}, [isOpen]);

	return (
		<Modal size="3xl" onClose={onClose} isOpen={isOpen}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					<T _str={DICTIONARY.THUMBNAIL.MODAL_HEADER} />
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					{isOpen && (
						<>
							<Box position="relative" width="full" height="md">
								{isCropperReady && (
									<Cropper
										image={imgBase64}
										crop={crop}
										zoom={zoom}
										maxZoom={4}
										aspect={1.8079}
										onCropChange={setCrop}
										onCropComplete={onCropComplete}
										onZoomChange={setZoom}
									/>
								)}
							</Box>
							<Center pt="4">
								<Slider
									value={zoom}
									min={1}
									max={4}
									step={0.5}
									width="50%"
									onChange={(value) => setZoom(value)}
									aria-label="zoom-control-slider"
								>
									<SliderTrack>
										<SliderFilledTrack />
									</SliderTrack>
									<SliderThumb boxSize="5">
										<Icon color="primary" path={mdiMagnify} />
									</SliderThumb>
								</Slider>
							</Center>
						</>
					)}
				</ModalBody>
				<ModalFooter>
					<ButtonGroup>
						<Button variant="ghost" onClick={onClose}>
							<T _str={DICTIONARY.CANCEL} />
						</Button>
						<Button onClick={() => void uploadCroppedImage()}>
							<T _str={DICTIONARY.THUMBNAIL.CROP} />
						</Button>
					</ButtonGroup>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

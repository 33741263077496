import { Box, Card, CardBody, CardHeader as CardImage, Flex, IconButton, Image, Stack, Text, Tooltip } from '@chakra-ui/react';
import { mdiEyeOutline } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { MediaLightbox } from 'entities/media-lightbox/ui/media-lightbox';
import { TemplateCardProps } from 'entities/template-card/models';
import { useTranslate } from 'features/common/utils/translateUtils';
import { FC, FocusEvent } from 'react';
import { DICTIONARY } from 'shared/constants';
import { srcFormatter } from 'shared/utils';

export const TemplateCard: FC<TemplateCardProps> = ({ thumbnail, name, description, image, isSelected }) => {
	const t = useTranslate();

	return (
		<Card
			layerStyle="interactive.raise"
			variant="outline"
			direction="row"
			overflow="hidden"
			size="sm"
			borderColor={isSelected ? 'primary.500' : 'inherit'}
			outline={isSelected ? '1px solid var(--colors-primary-500)' : 'none'}
		>
			<CardImage padding="0" width="44" height="32" background="blackAlpha.100" justifyContent="center" display="flex">
				{thumbnail ? (
					<Image width="full" height="fit-content" src={srcFormatter(thumbnail)} />
				) : (
					<Flex textTransform="uppercase" fontSize="xl" color="blackAlpha.400" fontWeight="bold" alignItems="center">
						<T _str={DICTIONARY.BLANK} />
					</Flex>
				)}
			</CardImage>
			<CardBody display="flex">
				<Stack gap="1">
					<Text variant="strong" noOfLines={2}>
						{name}
					</Text>
					<Text variant="subtle" noOfLines={2}>
						{description}
					</Text>
				</Stack>
				{image && (
					<Box position="relative" marginLeft="3">
						<MediaLightbox imageUrl={image} alt={name} title={name}>
							<Tooltip label={t(DICTIONARY.PREVIEW_TEMPLATE)}>
								<IconButton
									icon={<Icon path={mdiEyeOutline} />}
									variant="outline"
									aria-label={t(DICTIONARY.PREVIEW_TEMPLATE)}
									size="sm"
									onFocus={(e: FocusEvent<HTMLButtonElement>) => e.preventDefault()}
								/>
							</Tooltip>
						</MediaLightbox>
					</Box>
				)}
			</CardBody>
		</Card>
	);
};

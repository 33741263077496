import { Flex, FlexProps, Heading, IconButton, Skeleton } from '@chakra-ui/react';
import { mdiArrowLeft } from '@mdi/js';
import { Icon } from 'entities/icon/ui';
import { useTranslate } from 'features/common/utils/translateUtils';
import { FC, PropsWithChildren } from 'react';
import { DICTIONARY } from 'shared/constants';

type PageHeaderProps = {
	backAction: () => void;
	title?: string;
	isLoading?: boolean;
} & PropsWithChildren<FlexProps>;

export const PageHeader: FC<PageHeaderProps> = ({ backAction, children, isLoading = false, title = '', ...props }) => {
	const t = useTranslate();

	return (
		<Flex px="3" py="2.5" w="full" boxShadow="base" bgColor="chakra-body-bg" gap="2" alignItems="center" zIndex="sticky">
			<Flex gap="3" alignItems="center" minWidth="fit-content">
				<IconButton
					data-testid="goBack-button"
					variant="ghost"
					aria-label={t(DICTIONARY.CLOSE)}
					icon={<Icon path={mdiArrowLeft} />}
					onClick={backAction}
				/>
				{isLoading ? (
					<Skeleton width="40" height="6" />
				) : (
					<Heading data-testid="collection-header" size="md">
						{title}
					</Heading>
				)}
			</Flex>
			<Flex {...props}>{children}</Flex>
		</Flex>
	);
};

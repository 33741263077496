export const ROUTES = {
	PORTFOLIO: {
		NAME: 'portfolio',
		SELF: 'portfolio/:id',
		SITES: 'sites',
		GENERAL: 'general',
	},
	SITE: {
		NAME: 'site',
		SELF: 'site/:id',
		GENERAL: 'general',
		DEFINITIONS: {
			NAME: 'definition',
			SELF: 'definition/:hostId',
			ANALYTICS_AND_PERSONALIZATION: 'analytics-and-personalization',
			SETTING: 'settings',
		},
		ANALYTICS_AND_PERSONALIZATION: 'analytics-and-personalization',
		SITE_STRUCTURE: 'structure',
		LANGUAGES: 'languages',
	},
	SITE_SETTINGS: {
		SELF: 'settings',
		ANALYTICS_IDENTIFIERS: 'identifiers',
		SYSTEM_LANGUAGES: 'system-languages',
	},
	SITES: {
		NAME: 'sites',
	},
	TOOLS: {
		NAME: 'tools',
	},
};

import { ThumbnailDropzone, ThumbnailItem } from 'entities/thumbnail/ui';
import { OnChangeThumbnailProxyProps } from 'features/thumbnail/models';
import { FC, useState } from 'react';
import { DICTIONARY } from 'shared/constants';
import { SiteResponse } from 'shared/models';
import { parseFile } from 'shared/utils/parse-file';

export const ThumbnailManualGeneral: FC<{ site: SiteResponse; eventHandler?: OnChangeThumbnailProxyProps }> = ({ site, eventHandler }) => {
	const [unsavedUrl, setUnsavedUrl] = useState('');
	const [toBeDeleted, setToBeDeleted] = useState(false);
	const thumbnailFileInfo = parseFile(unsavedUrl || site?.thumbnail?.url, site?.thumbnail?.base64, site?.id);

	if ((site?.thumbnail?.autogenerated || toBeDeleted) && !unsavedUrl) {
		return <ThumbnailDropzone setUnsavedUrl={setUnsavedUrl} eventHandler={eventHandler} setToBeDeleted={setToBeDeleted} />;
	}

	return (
		<ThumbnailItem
			thumbnail={thumbnailFileInfo}
			buttonTitle={DICTIONARY.REPLACE}
			unsavedUrl={unsavedUrl}
			setUnsavedUrl={setUnsavedUrl}
			eventHandler={eventHandler}
			setToBeDeleted={setToBeDeleted}
		/>
	);
};

import { MenuItem } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { DICTIONARY } from 'shared/constants';
import { useDuplicateSiteProvider } from './DuplicateSiteProvider';

export const DuplicateSiteButton = ({ siteName }: { siteName: string }) => {
	const { openDuplicateSiteModal } = useDuplicateSiteProvider();

	return (
		<MenuItem onClick={() => openDuplicateSiteModal(siteName)} data-testid="duplicateSiteButton">
			<T _str={DICTIONARY.DUPLICATE} />
		</MenuItem>
	);
};

/* istanbul ignore file */

import { SITE_STRUCTURE_DEFAULT_HIGHLIGHT_TIMEOUT } from 'shared/constants';

const waitForRowToAppearElement = (selector: string) => {
	return new Promise<Element>((resolve) => {
		let element = document.querySelector(selector);

		if (element) {
			return resolve(element);
		}

		const observer = new MutationObserver(() => {
			element = document.querySelector(selector);

			if (element) {
				observer.disconnect();
				resolve(element);
			}
		});

		observer.observe(document.body, {
			childList: true,
			subtree: true,
		});
	});
};

export const blinkPage = async (itemId: string) => {
	const row = await waitForRowToAppearElement(`#page-${itemId.replace(/-/g, '')}`);

	if (!row) {
		return;
	}

	row.classList.add('blink');

	setTimeout(() => {
		row.classList.remove('blink');
	}, SITE_STRUCTURE_DEFAULT_HIGHLIGHT_TIMEOUT);
};

import { useDisclosure } from '@chakra-ui/react';
import { Children, PropsWithChildren, cloneElement, isValidElement, useCallback, useMemo } from 'react';
import { ModalWrapperProps } from 'shared/modal-wrapper/models';

export const useModalWrapper = ({ children, onButtonClick = () => {} }: PropsWithChildren<ModalWrapperProps>) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const onChildButtonClick = useCallback(() => {
		onButtonClick();
		onOpen();
	}, [onButtonClick, onOpen]);

	const childrenWithProps = useMemo(() => {
		return Children.map(children, (child) => {
			if (isValidElement(child)) {
				return cloneElement(child, { ...child.props, onClick: onChildButtonClick });
			}

			return child;
		});
	}, [children, onChildButtonClick]);

	return {
		isOpen,
		onClose,
		onOpen,
		children: childrenWithProps,
	};
};

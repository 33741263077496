/* eslint-disable */
import { css } from '@emotion/react';
import * as React from 'react';
import { convertChildrenArrayToObj, flattenChildren, overrideComponentProps, recursiveChildrenMap } from '../../../shared/layout/lib';

/**
 * Chakra UI components
 */
import {
	Avatar,
	Box as ChakraBox,
	Center as ChakraCenter,
	Image as ChakraImage,
	Spacer as ChakraSpacer,
	Flex,
	Heading,
	Icon,
	IconProps,
	Skeleton,
	Spacer,
	Stack,
	Text,
	forwardRef,
} from '@chakra-ui/react';

/**
 * Interfaces
 */
import type {
	CardActionsProps,
	CardAvatarProps,
	CardBottomPanelProps,
	CardDescriptionProps,
	CardImageProps,
	CardLoadingProps,
	CardProps,
	CardSubTitleProps,
	CardTitleProps,
} from './model';

/**
 * Prop override HoC
 */
import { withOverride } from '../HoC/withOverride';

/**
 * Props to override
 */
import { cardAvatarSizes, cardSizes, imgColumnSizes, imgRowSizes, overrideProps } from './resources';

const cardCss = css`
	background-color: white;
	&.active {
		border-color: var(--colors-primary-500);
	}
`;

export const forwardIcon = (props: any, ref: any) => <Icon ref={ref} {...props} />;

export const CardContainer: React.FC<CardProps> = withOverride(
	forwardRef((props, ref) => {
		let { hoverable, focusable, active, loading, onClick, ...restProps } = props;
		let children = props.children;
		if (props.disabled !== undefined) {
			children = recursiveChildrenMap(props.children, (child) => {
				return overrideComponentProps(child, {
					disabled: props.disabled || false,
				});
			});
		}

		return (
			<ChakraBox
				ref={ref}
				{...restProps}
				{...cardSizes[props.size]}
				{...(!props.disabled &&
					hoverable && {
						_hover: {
							backgroundColor: 'var(--colors-gray-100)',
							cursor: 'pointer',
						},
					})}
				{...(!props.disabled &&
					focusable && {
						_focus: {
							borderColor: 'var(--colors-primary-500)',
						},
						tabIndex: 1,
					})}
				{...(!props.disabled && {
					onClick,
				})}
				{...(props.disabled && { disabled: true })}
				_disabled={{
					opacity: 0.5,
					cursor: 'not-allowed',
				}}
				className={active ? 'active' : ''}
				borderRadius="md"
				css={cardCss}
			>
				<ChakraBox boxShadow="base" borderRadius="md">
					{loading ? (
						<ChakraBox p="3">
							<CardLoading />
						</ChakraBox>
					) : (
						children
					)}
				</ChakraBox>
			</ChakraBox>
		);
	}),
	overrideProps
);

export const CardLoading: React.FC<CardLoadingProps> = withOverride(
	forwardRef((props, ref) => {
		return (
			<Stack ref={ref} {...props} direction="column" css={{ width: '100%' }}>
				<Skeleton css={{ height: 20 }} />
				<Skeleton css={{ height: 20 }} />
				<Skeleton css={{ height: 20 }} />
				<Skeleton css={{ height: 20 }} />
			</Stack>
		);
	}),
	[]
);

/**
 * Sitecore UI Card components
 */
export const SmallCard: React.FC<CardProps> = withOverride(
	forwardRef((props, ref) => {
		const childrenKeyValue = convertChildrenArrayToObj(flattenChildren(props.children));
		let CardBottomPanel = childrenKeyValue['CardBottomPanel'];
		const Tag = childrenKeyValue['Tag'];
		if (CardBottomPanel) {
			childrenKeyValue['CardBottomPanel'] = overrideComponentProps(CardBottomPanel, { size: props.size });
		}

		return (
			<CardContainer ref={ref} {...props}>
				{childrenKeyValue['CardImage'] && (
					<>
						<ChakraBox borderTopRadius="md" overflow="hidden" w="100%" {...imgColumnSizes[props.size]}>
							{childrenKeyValue['CardImage']}
						</ChakraBox>
						<Spacer h="5" />
					</>
				)}
				{childrenKeyValue['CardIcon'] && (
					<>
						<Spacer h="5" />
						{childrenKeyValue['CardIcon']}
						<Spacer h="5" />
					</>
				)}
				{childrenKeyValue['CardActions'] && (
					<Flex>
						<ChakraSpacer />
						<ChakraBox>{childrenKeyValue['CardActions']}</ChakraBox>
					</Flex>
				)}
				{!childrenKeyValue['CardImage'] && !childrenKeyValue['CardIcon'] && !childrenKeyValue['CardActions'] && <Spacer h="10" />}
				{childrenKeyValue['CardAvatar'] && (
					<>
						<ChakraCenter>{childrenKeyValue['CardAvatar']}</ChakraCenter>
						<Spacer h="5" />
					</>
				)}
				{childrenKeyValue['CardTitle'] && (
					<ChakraBox px="5" textAlign="center">
						{childrenKeyValue['CardTitle']}
						<Spacer h="5" />
					</ChakraBox>
				)}
				{childrenKeyValue['CardBottomPanel']}
				{!!Tag && (
					<ChakraBox px="5" textAlign="center">
						{Tag}
						<Spacer h="5" />
					</ChakraBox>
				)}
			</CardContainer>
		);
	}),
	overrideProps
);

export const MediumLargeCard: React.FC<CardProps> = withOverride(
	forwardRef((props, ref) => {
		const childrenKeyValue = convertChildrenArrayToObj(flattenChildren(props.children));
		let CardBottomPanel = childrenKeyValue['CardBottomPanel'];
		let CardImage = childrenKeyValue['CardImage'];
		if (CardBottomPanel) {
			childrenKeyValue['CardBottomPanel'] = overrideComponentProps(CardBottomPanel, {
				size: props.size,
				variant: props.direction === 'row' && CardImage ? 'minimal' : 'basic',
			});
		}

		return (
			<CardContainer ref={ref} {...props}>
				<Flex direction={props.direction} align="stretch">
					{childrenKeyValue['CardImage'] && props.direction === 'column' && (
						<>
							<ChakraBox borderTopRadius="md" overflow="hidden" {...imgColumnSizes[props.size]}>
								{childrenKeyValue['CardImage']}
							</ChakraBox>
							<Spacer h="5" />
						</>
					)}
					{childrenKeyValue['CardImage'] && props.direction === 'row' && (
						<ChakraBox>
							<ChakraBox borderLeftRadius="md" overflow="hidden" h="full" {...imgRowSizes[props.size]}>
								{childrenKeyValue['CardImage']}
							</ChakraBox>
						</ChakraBox>
					)}

					<ChakraBox>
						{(!childrenKeyValue['CardImage'] || props.direction === 'row') && <Spacer h="5" />}
						<Flex>
							{childrenKeyValue['CardIcon'] && <ChakraBox pl="5">{childrenKeyValue['CardIcon']}</ChakraBox>}
							{childrenKeyValue['CardAvatar'] && <ChakraBox pl="5">{childrenKeyValue['CardAvatar']}</ChakraBox>}
							{childrenKeyValue['CardTitle'] && (
								<ChakraBox pl="5" flex="1" textAlign="left">
									{childrenKeyValue['CardTitle']}
									{childrenKeyValue['CardSubtitle']}
								</ChakraBox>
							)}
							{childrenKeyValue['CardActions'] && <ChakraBox pr="5">{childrenKeyValue['CardActions']}</ChakraBox>}
						</Flex>
						<Spacer {...{ h: props.size === 'md' ? '3' : '5' }} />
						{props.size === 'lg' && childrenKeyValue['CardDescription'] && (
							<>
								<ChakraBox px="5">{childrenKeyValue['CardDescription']}</ChakraBox>
								<Spacer {...{ h: props.size === 'md' ? '3' : '5' }} />
							</>
						)}
						{childrenKeyValue['CardBottomPanel']}
					</ChakraBox>
				</Flex>
			</CardContainer>
		);
	}),
	overrideProps
);

export const Card: React.FC<CardProps> = withOverride(
	forwardRef((props, ref) => {
		switch (props.size) {
			case 'sm':
				return <SmallCard ref={ref} {...props}></SmallCard>;
			case 'md':
			case 'lg':
			default:
				return <MediumLargeCard ref={ref} {...props}></MediumLargeCard>;
		}
	}),
	overrideProps
);

export const CardActions: React.FC<CardActionsProps> = withOverride(
	forwardRef((props, ref) => {
		return <>{props.children}</>;
	}),
	[]
);

export const CardBottomPanel: React.FC<CardBottomPanelProps> = withOverride(
	forwardRef((props, ref) => {
		const [left, right] = React.Children.toArray(props.children);
		const { showSeparator, withSpacer, ...restProps } = props;
		return (
			<ChakraBox {...(showSeparator && { borderTop: '1px solid chakra-border-color' })}>
				{props.size !== 'sm' && <Spacer {...{ h: props.size === 'md' ? '3' : '5' }} />}
				<Flex ref={ref} {...restProps} justify="space-between">
					{props.size == 'sm' ? (
						<ChakraCenter w="full">
							<ChakraBox w="75%" textAlign="center">
								{left}
							</ChakraBox>
						</ChakraCenter>
					) : (
						<ChakraBox flex="1">
							<Flex direction="column" justify="space-around">
								<ChakraBox pl="4" pr="2" textAlign="left">
									{left}
								</ChakraBox>
							</Flex>
						</ChakraBox>
					)}
					{props.variant !== 'minimal' && props.size !== 'sm' && right && (
						<ChakraBox flex="1">
							<Flex direction="column" justify="space-around">
								<ChakraBox pl="2" pr="5" textAlign="right">
									{right}
								</ChakraBox>
							</Flex>
						</ChakraBox>
					)}
				</Flex>
				{withSpacer && <Spacer h="5" />}
			</ChakraBox>
		);
	}),
	[]
);

export const CardAvatar: React.FC<CardAvatarProps> = withOverride(
	forwardRef((props, ref) => {
		return <Avatar ref={ref} {...props} {...cardAvatarSizes[props.size]}></Avatar>;
	}),
	[]
);

export const CardTitle: React.FC<CardTitleProps> = withOverride(
	forwardRef((props, ref) => {
		return <Heading size="md" ref={ref} {...props}></Heading>;
	}),
	[]
);

export const CardSubtitle: React.FC<CardSubTitleProps> = withOverride(
	forwardRef((props, ref) => {
		return <Text variant="microcopy" ref={ref} {...props} {...cardSizes[props.size]}></Text>;
	}),
	[]
);

export const CardDescription: React.FC<CardDescriptionProps> = withOverride(
	forwardRef((props, ref) => {
		return <Text ref={ref} {...props} variant="regular"></Text>;
	}),
	[]
);

const CardImageFallback: React.FC<CardImageProps> = withOverride(
	forwardRef(() => {
		return (
			<ChakraBox w="full" h="full" backgroundColor="gray.200" fontSize="xl">
				<ChakraCenter w="full" h="full">
					<strong>IMG</strong>
				</ChakraCenter>
			</ChakraBox>
		);
	}),
	[]
);

export const CardIcon: React.FC<IconProps> = forwardRef((props, ref) => {
	return (
		<Flex align="center" justify="center">
			{forwardIcon(props, ref)}
		</Flex>
	);
});

export const CardImage: React.FC<CardImageProps> = withOverride(
	forwardRef((props, ref) => {
		return <ChakraImage ref={ref} {...props} w="full" h="full" fallback={<CardImageFallback {...props} />} />;
	}),
	[]
);

/**
 * Sitecore UI Card component default props
 */
Card.defaultProps = { size: 'md', direction: 'column' };
CardBottomPanel.defaultProps = {
	showSeparator: false,
	variant: 'basic',
	size: 'md',
	withSpacer: true,
};
CardTitle.defaultProps = { align: 'inherit' };

/**
 * Sitecore UI Card components display names
 */
Card.displayName = 'Card';
CardIcon.displayName = 'CardIcon';
CardImage.displayName = 'CardImage';
CardTitle.displayName = 'CardTitle';
CardAvatar.displayName = 'CardAvatar';
CardActions.displayName = 'CardActions';
CardSubtitle.displayName = 'CardSubtitle';
CardDescription.displayName = 'CardDescription';
CardBottomPanel.displayName = 'CardBottomPanel';

import { ButtonProps, IconButton, Tooltip } from '@chakra-ui/react';
import { mdiArrowUp } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { useTranslate } from 'features/common/utils/translateUtils';
import { FC, RefObject, useCallback } from 'react';
import { DICTIONARY } from 'shared/constants';
import { useScroll } from '../hooks';

type ScrollToTopButtonProps = { boxRef: RefObject<HTMLDivElement> } & ButtonProps;

export const ScrollToTopButton: FC<ScrollToTopButtonProps> = ({ boxRef, ...rest }) => {
	const { hasScrolled } = useScroll(boxRef);
	const translate = useTranslate();

	const scrollToTop = useCallback(() => {
		boxRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
	}, [boxRef]);

	return (
		<Tooltip label={<T _str={DICTIONARY.SCROLL_TO_TOP} />}>
			<IconButton
				visibility={hasScrolled ? 'visible' : 'hidden'}
				position="fixed"
				bottom="6"
				right="6"
				onClick={scrollToTop}
				aria-label={translate(DICTIONARY.SCROLL_TO_TOP)}
				variant="ghost"
				shadow="md"
				backgroundColor="chakra-body-bg"
				borderRadius="full"
				icon={<Icon path={mdiArrowUp} boxSize="6" />}
				data-testid="scroll-to-top-button"
				{...rest}
			/>
		</Tooltip>
	);
};

// eslint-disable-next-line import/named
import { ThumbnailImage } from 'entities/thumbnail/models';
import { ThumbnailSelectItem } from 'features/thumbnail/models';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SITE_SETTINGS_FORM_EVENT_NAME } from 'shared/constants';
import { SiteResponse } from 'shared/models';
import { parseFile } from 'shared/utils';
import { useEventListener } from 'usehooks-ts';
import { SiteGeneralSectionEvent } from 'widgets/site-settings-update-form/models';

export const useThumbnailAutoWithDefinitions = (site: SiteResponse | undefined) => {
	const [currentDefinition, setCurrentDefinition] = useState<ThumbnailSelectItem | null>(null);
	const [thumbnailFileInfo, setThumbnailFileInfo] = useState<ThumbnailImage | null>(null);
	const documentRef = useRef<Document>(document);

	const hostItems: ThumbnailSelectItem[] = useMemo(
		() =>
			site?.hosts?.map((host) => ({
				isSiteThumbnailSource: host?.settings?.isSiteThumbnailSource,
				label: host.name,
				value: host.id,
				thumbnail: host.thumbnail.url,
				base64: host.thumbnail.base64,
			})) || [],
		[site?.hosts]
	);

	const onButtonsClick = useCallback(
		(event: CustomEvent<SiteGeneralSectionEvent>) => {
			const { type } = event.detail;

			if (type === 'reset') {
				setCurrentDefinition(hostItems.find((d) => d.isSiteThumbnailSource === 'true') || hostItems[0]);
			}
		},
		[hostItems]
	);

	useEffect(() => {
		if (!currentDefinition) {
			setCurrentDefinition(hostItems.find((d) => d.isSiteThumbnailSource === 'true') || hostItems[0]);
		} else {
			setThumbnailFileInfo(parseFile(currentDefinition?.thumbnail, currentDefinition.base64, currentDefinition.value));
		}
	}, [currentDefinition, currentDefinition?.thumbnail, hostItems]);

	useEventListener(SITE_SETTINGS_FORM_EVENT_NAME, onButtonsClick, documentRef);

	return { hostItems, currentDefinition, thumbnailFileInfo, setCurrentDefinition };
};

import { gql } from '@apollo/client';
import { hasDirectives, RemoveArgumentsConfig, removeArgumentsFromDocument, removeDirectivesFromDocument } from '@apollo/client/utilities';
// eslint-disable-next-line import/named
import { ASTNode, DocumentNode, Kind, StringValueNode, ValueNode, VariableDefinitionNode, visit } from 'graphql';

const dummyQuery = gql`
	query {
		__typename
	}
`;

function isStringValueNode(valueNode: ValueNode): valueNode is StringValueNode {
	return typeof (valueNode as StringValueNode).value == 'string';
}

function isVariableDefinitionNode(node: ASTNode | readonly ASTNode[]): node is VariableDefinitionNode {
	return (node as ASTNode)?.kind == Kind.VARIABLE_DEFINITION;
}

export const queryPool = new Map<DocumentNode, DocumentNode | null>();

export const hasFeatureDirectives = (query: DocumentNode) => hasDirectives(['feature'], query);

function removeNotUsedVariableDefinitions(query: DocumentNode) {
	const variablesInUse: string[] = [];
	const variableDefinitionsInUse: string[] = [];

	visit(query, {
		Variable: {
			enter: (node, key, parent) => {
				// don't track variable from variable definitions as we want to track only real usages
				if (parent && isVariableDefinitionNode(parent)) {
					return;
				}

				variablesInUse.push(node.name.value);
			},
		},
		VariableDefinition: {
			enter: (node) => {
				variableDefinitionsInUse.push(node.variable.name.value);
			},
		},
	});

	const varDefsToRemove = variableDefinitionsInUse.filter((vd) => variablesInUse.indexOf(vd) == -1);

	if (varDefsToRemove.length > 0) {
		const removeConfig: RemoveArgumentsConfig[] = varDefsToRemove.map((vd) => ({
			name: vd,
		}));

		return removeArgumentsFromDocument(removeConfig, query);
	}

	return query;
}

export function excludeNotSupportedFields(features: Record<string, boolean | undefined>, query: DocumentNode) {
	const rememberedQuery = queryPool.get(query);

	if (rememberedQuery) {
		return rememberedQuery;
	}

	let modifiedQuery = removeDirectivesFromDocument(
		[
			{
				remove: true,
				test: (node) => {
					if (node.name.value !== 'feature') {
						// igonore if not 'feature' directive
						return false;
					}

					const isArgument = node.arguments?.find((a) => a.name.value == 'is');

					if (!isArgument) {
						// missing 'is' argment, so can't check if field should be enabled, thus should be removed
						return true;
					}

					if (!isStringValueNode(isArgument.value)) {
						// 'is' value is not a string, so can't check if field should be enabled, thus should be removed
						return true;
					}

					const isAvaialable = features[isArgument.value.value] ?? false;

					// remove when feature is available
					return !isAvaialable;
				},
			},
		],
		query
	);

	// remove 'feature' directive from supported fields, without removing fields themselves
	if (modifiedQuery) {
		modifiedQuery = removeDirectivesFromDocument(
			[
				{
					remove: false,
					name: 'feature',
				},
			],
			modifiedQuery
		);
	}

	// if removed fields contained some variables which aren't used elsewhere in the query
	// then corresponding variable definitions should be removed as well
	if (modifiedQuery) {
		modifiedQuery = removeNotUsedVariableDefinitions(modifiedQuery);
	}

	queryPool.set(query, modifiedQuery);

	return modifiedQuery ?? dummyQuery;
}

/*istanbul ignore next*/
// Jest can't handle throwing errors in async functions, so we need to ignore this file for coverage
import { AxiosResponse } from 'axios';
import { IGNORE_GLOBAL_ERROR_HANDLER_FLAG } from 'shared/constants';

export const disableGlobalErrorHandler = async <TData>(response: Promise<AxiosResponse<TData, any>>, ignore: boolean | undefined) => {
	return response.catch((error) => {
		if (ignore) {
			error.name = IGNORE_GLOBAL_ERROR_HANDLER_FLAG;
		}

		throw error;
	});
};

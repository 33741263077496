import { SITE_NAME_PARAM_NAME } from 'features/common/utils/сlearNonRelevantQueryParams';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { MillerItemType } from '../models/MillerColumnsTypes';

export type Colors = 'primary.100' | 'neutral.100' | 'chakra-subtle-bg';

export const useGetMillerItemColor = (data: Pick<MillerItemType, 'type' | 'name'>, isActiveColumn: boolean, isActiveItem: boolean): Colors => {
	const [params] = useSearchParams();

	return useMemo(() => {
		const siteName = params.get(SITE_NAME_PARAM_NAME);
		const isActiveItemOrSite = data.type == 'site' ? isActiveItem && data.name === siteName : isActiveItem;

		if (isActiveColumn && isActiveItemOrSite) {
			return 'primary.100';
		} else if (isActiveItemOrSite) {
			return 'neutral.100';
		}

		return 'chakra-subtle-bg';
	}, [data.name, data.type, isActiveColumn, isActiveItem, params]);
};

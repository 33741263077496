import { useQuery } from '@apollo/client';
import { getLanguagesQuery } from 'features/common/languages/queries';
import { LanguagePayload, LanguagesPayload } from './types';

export const useGetLanguages = (onLanguagesLoaded?: (languages: LanguagePayload[]) => void) => {
	const { data, error, loading } = useQuery<LanguagesPayload>(getLanguagesQuery, {
		onCompleted(languagesData) {
			const languages = languagesData?.languages.nodes;

			if (onLanguagesLoaded) {
				onLanguagesLoaded(languages || []);
			}
		},
	});

	return { languages: data?.languages.nodes || [], loading, error };
};

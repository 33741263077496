import { useToast } from '@chakra-ui/react';
import { useTranslate } from 'features/common/utils/translateUtils';
import { SiteLanguagesViewModel, UseSiteLanguagesPage } from 'pages/languages/models';
import { SitePageOutlet } from 'pages/site/models';
import { useCallback, useMemo } from 'react';
import { useOutletContext } from 'react-router';
import { DICTIONARY } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { LanguageItem } from 'shared/languages/models';
import { SiteResponse } from 'shared/models';
import { usePatch } from 'shared/react-query-wrappers/hooks';
import { onIsSubmittingChange, onSiteSaved } from 'widgets/site-settings-update-form/models';

export const useSiteLanguagesPage: UseSiteLanguagesPage = () => {
	const { site, languages } = useOutletContext<SitePageOutlet>();
	const t = useTranslate();
	const toast = useToast();

	const { mutateAsync } = usePatch<SiteResponse, { supportedLanguages: string[] }>({
		queryKey: { url: ENDPOINTS.SITE, params: { id: site?.id || '' } },
		refetchQueriesByKey: [
			{ url: ENDPOINTS.COLLECTION_SITES, params: { collectionId: site?.collectionId || '' } },
			{ url: ENDPOINTS.SITE, params: { id: site?.id || '' } },
			{ url: ENDPOINTS.JOBS },
			{ url: ENDPOINTS.LANGUAGES },
		],
	});

	const onSubmit = useCallback(
		async (values: SiteLanguagesViewModel) => {
			onIsSubmittingChange(true);
			void mutateAsync({ supportedLanguages: values.languages?.map((lang: LanguageItem) => lang.value) || [] })
				.then(() => {
					onSiteSaved(true);
					onIsSubmittingChange(false);
					toast({ description: t(DICTIONARY.SITE_UPDATED_MESSAGE), status: 'success' });
				})
				.catch(() => {
					onSiteSaved(false);
					onIsSubmittingChange(false);
				});
		},
		[mutateAsync, t, toast]
	);

	const initialValues: SiteLanguagesViewModel = useMemo(() => ({ languages }), [languages]);

	return { onSubmit, initialValues };
};

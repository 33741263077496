// eslint-disable-next-line import/named
import { SingleValue } from 'chakra-react-select';
import { CollectionSingleValue, UseCollectionField } from 'features/create-site/models';
import { FocusEvent, useCallback } from 'react';

export const useCollectionField: UseCollectionField = ({ setFieldTouched, onCollectionChange, handleChange, collection, name }) => {
	const onBlur = useCallback(
		(value: FocusEvent<HTMLInputElement, Element>) => {
			setFieldTouched(name);

			if (value.target.value && !collection) {
				handleChange(name)('');
			}
		},
		[collection, handleChange, name, setFieldTouched]
	);

	const onInputChange = useCallback(
		(inputValue: string) => {
			if (inputValue && !collection) {
				onCollectionChange(null);
				handleChange(name)('');
			}
		},
		[collection, onCollectionChange, handleChange, name]
	);

	const onChange = useCallback(
		(selectedOption: SingleValue<CollectionSingleValue>) => {
			onCollectionChange(selectedOption || null);
			handleChange(name)(selectedOption ? selectedOption.label : '');
			setTimeout(() => void setFieldTouched(name), 0);
		},
		[handleChange, name, onCollectionChange, setFieldTouched]
	);

	return { onBlur, onInputChange, onChange };
};

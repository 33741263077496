import { DICTIONARY } from './constants';
import { ROUTES } from './routes';

export const LEFT_BAR_NAVIGATION = {
	PORTFOLIO_PAGE: [
		{
			label: DICTIONARY.GENERAL,
			route: ROUTES.PORTFOLIO.GENERAL,
		},
		{
			label: DICTIONARY.SITES,
			route: ROUTES.PORTFOLIO.SITES,
		},
	],
	SITE_PAGE: [
		{
			label: DICTIONARY.GENERAL,
			route: ROUTES.SITE.GENERAL,
		},
		{
			label: DICTIONARY.SITE_STRUCTURE,
			route: ROUTES.SITE.SITE_STRUCTURE,
			showSitesGrouping: true,
		},
		{
			label: DICTIONARY.SITE_DEFINITIONS,
			route: ROUTES.SITE.DEFINITIONS.NAME,
			showSitesGrouping: true,
		},
		{
			label: DICTIONARY.ANALYTICS_AND_PERSONALIZATION,
			route: ROUTES.SITE.ANALYTICS_AND_PERSONALIZATION,
			showSitesGrouping: false,
		},
		{
			label: DICTIONARY.LANGUAGES,
			route: ROUTES.SITE.LANGUAGES,
			showManagedLanguages: true,
		},
	],
	JSS_SITE_PAGE: [
		{
			label: DICTIONARY.GENERAL,
			route: ROUTES.SITE.GENERAL,
		},
	],
	SITE_SETTINGS_PAGE: [
		{
			label: DICTIONARY.LANGUAGES,
			route: ROUTES.SITE_SETTINGS.SYSTEM_LANGUAGES,
		},
		{
			label: DICTIONARY.ANALYTICS_IDENTIFIERS,
			route: ROUTES.SITE_SETTINGS.ANALYTICS_IDENTIFIERS,
		},
	],
	SITE_DEFINITION_PAGE: [
		{
			label: DICTIONARY.SETTINGS,
			route: ROUTES.SITE.DEFINITIONS.SETTING,
		},
		{
			label: DICTIONARY.ANALYTICS_AND_PERSONALIZATION,
			route: ROUTES.SITE.DEFINITIONS.ANALYTICS_AND_PERSONALIZATION,
		},
	],
};

import { Box, Button, Tooltip } from '@chakra-ui/react';
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { TextWithCopy } from 'features/common/components/TextWithCopy';
import { deploy } from 'features/common/config/config';
import { xmCloudDeployAPIBaseUrl } from 'features/common/config/envConfig';
import { useXMTenant } from 'features/common/tenants/xmTenantContext';
import { FC, useCallback, useState } from 'react';
import { useToggle } from 'react-use';
import { DICTIONARY } from 'shared/constants';
import { useGet } from 'shared/react-query-wrappers/hooks';
import { useHasScope } from '../../common/auth/useHasScope';

type XMCloudEdgeToken = {
	apiKey: string;
	edgeUrl: string;
};

export const GenerateApiKey: FC = () => {
	const [showKey, toggleShowKey] = useToggle(false);
	const { tenant } = useXMTenant();
	const hasAPIScope = useHasScope('xmcloud.cm:admin', 'xmclouddeploy.environments:manage');

	const { refetch, isLoading } = useGet<XMCloudEdgeToken>({
		queryKey: {
			url: deploy.generateApiKeyUrl,
			params: { xmCloudDeployAPIBaseUrl: xmCloudDeployAPIBaseUrl, environmentId: tenant.environemntId || '' },
		},
		enabled: false,
	});
	const [apiKey, setApiKey] = useState<string>();
	const generateKeyHandler = useCallback(async () => {
		const { data: response } = await refetch();

		if (response && response.data) {
			setApiKey(response.data.apiKey);
		}
	}, [setApiKey, refetch]);

	const showToggleIcon = showKey ? mdiEyeOutline : mdiEyeOffOutline;

	if (apiKey == undefined) {
		return (
			<Tooltip label={<T _str={DICTIONARY.YOU_DO_NOT_HAVE_ACCESS_RIGHTS} />} isDisabled={hasAPIScope} shouldWrapChildren>
				<Button
					variant="outline"
					colorScheme="primary"
					size="xs"
					mt={2}
					onClick={() => void generateKeyHandler()}
					isLoading={isLoading}
					isDisabled={!hasAPIScope}
					data-testid="generate-key-button"
				>
					<T _str={DICTIONARY.GENERATE_API_KEY} />
				</Button>
			</Tooltip>
		);
	}

	return (
		<Box>
			<TextWithCopy display="inline-block" text={apiKey} isSecret={!showKey}></TextWithCopy>
			<button onClick={() => toggleShowKey()} data-testid="showKey">
				<Icon path={showToggleIcon} color="neutral-fg" boxSize={4} />
			</button>
		</Box>
	);
};

import { useCallback, useEffect, useState } from 'react';
import { SHOW_MORE_COUNT_SITES } from 'shared/constants';
import { ShowMoreItemType, UseShowMoreType } from '../models';

export const useShowMore: UseShowMoreType = (data) => {
	const [showMoreItem, setShowMoreItem] = useState<ShowMoreItemType>({ items: [], isShowLess: false, isShowMore: false, step: 0, pages: 0 });
	const pages = Math.ceil((data?.length || 0) / SHOW_MORE_COUNT_SITES);
	const prepareShowMoreData = useCallback(
		(item: ShowMoreItemType) => {
			const nextStep = item.step + 1 <= pages ? item.step + 1 : 1;

			return {
				items: data?.slice(0, nextStep * SHOW_MORE_COUNT_SITES),
				pages,
				isShowMore: nextStep < pages,
				isShowLess: nextStep === pages && pages > 1,
				step: nextStep,
			};
		},
		[data, pages]
	);

	const toggleMore = useCallback(() => setShowMoreItem(prepareShowMoreData), [prepareShowMoreData]);

	useEffect(() => {
		if (showMoreItem.step === 0 && data?.length) {
			setShowMoreItem(prepareShowMoreData);
		}
	}, [showMoreItem.step, data, prepareShowMoreData]);

	useEffect(() => {
		setShowMoreItem(prepareShowMoreData({ ...showMoreItem, step: 0 }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, pages, prepareShowMoreData]);

	return {
		showMoreItem,
		toggleMore,
	};
};

import { format } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { DATE_FORMAT } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { useSimpleGet } from 'shared/react-query-wrappers/hooks';
import { TemplateItemResponse, UseGetTemplates } from '../models';

export const useGetTemplates: UseGetTemplates = () => {
	const { data } = useSimpleGet<TemplateItemResponse[]>(ENDPOINTS.TEMPLATES, true);

	const filteredData = useMemo(() => {
		return data?.map((template: TemplateItemResponse) => ({
			...template,
			updatedOn: template.updatedOn ? format(new Date(template.updatedOn), DATE_FORMAT) : '',
		}));
	}, [data]);

	const getTemplateById = useCallback((id: string) => filteredData?.find((template: TemplateItemResponse) => template.id === id), [filteredData]);

	return { filteredData, getTemplateById };
};

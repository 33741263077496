import { Stack } from '@chakra-ui/react';
import { SectionDataRecord } from 'entities/page-layout/ui';
import { FC, useMemo } from 'react';
import { DICTIONARY } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { Host, SiteResponse } from 'shared/models';
import { useGet } from 'shared/react-query-wrappers/hooks';
import { PosInfoPopoverProps } from 'widgets/site-pos-table/models';

export const PosInfoPopoverBody: FC<PosInfoPopoverProps> = ({ identifier }) => {
	const { data: sites, isLoading } = useGet<SiteResponse[]>({
		queryKey: { url: ENDPOINTS.POS_SITES, params: { identifier: encodeURIComponent(identifier?.name ?? '') } },
	});

	const hostsNames = useMemo(() => {
		const hosts = sites?.flatMap((site) => {
			return site.hosts.filter((host: Host) => Object.values(host.analyticsIdentifiers || {}).includes(identifier?.name ?? ''));
		});

		return hosts?.map((host) => host.name).join(', ');
	}, [sites, identifier]);

	return (
		<Stack gap="4">
			<SectionDataRecord header={DICTIONARY.NAME} value={identifier?.name ?? ''} />
			<SectionDataRecord header={DICTIONARY.ASSIGNED_TO_SITE_DEFINITION} value={hostsNames ?? ''} isLoading={isLoading} />
			<SectionDataRecord header={DICTIONARY.TIMEOUT_IN_MINUTES} value={identifier?.timeout?.toString() ?? ''} />
		</Stack>
	);
};

import { Card, CardBody, CardFooter, Flex, Heading, Image, Text } from '@chakra-ui/react';
import fallbackSrc from 'entities/site-card/images/thumbnail-fallback.svg';
import { FC, PropsWithChildren } from 'react';
import { SITE_CARD_SIZE } from 'shared/constants';
import { srcFormatter } from 'shared/utils';
import { SiteCardProps } from '../models';

const flexProps = { width: SITE_CARD_SIZE.WIDTH, height: SITE_CARD_SIZE.HEIGHT };
const cardProps = { layerStyle: 'interactive.raise', size: 'sm' };

export const SiteCard: FC<PropsWithChildren<SiteCardProps>> = ({ name, description, thumbnail, children, displayName, isStacked = false }) => (
	<Flex {...flexProps} position="relative">
		{isStacked && (
			<Flex {...flexProps} pl={2} pt={2} position="absolute">
				<Card {...cardProps} width="full" borderRadius="base" />
			</Flex>
		)}
		<Flex {...flexProps} pr={isStacked ? 2 : 0} pb={isStacked ? 2 : 0} position="absolute">
			<Card {...cardProps} data-testid={`siteCard-${name}`} width="full">
				<CardBody p="0" height="44" overflow="hidden" borderTopRadius="base">
					<Image
						src={srcFormatter(thumbnail?.base64 || thumbnail?.url)}
						fallbackSrc={fallbackSrc}
						width="full"
						data-testid="cardImage"
						alt={name}
					/>
				</CardBody>
				<CardFooter gap="3" p="3" justifyContent="space-between">
					<Flex direction="column">
						<Flex gap="1">
							<Heading noOfLines={1} size="sm" title={displayName || name} wordBreak="break-word">
								{displayName || name}
							</Heading>
						</Flex>
						<Text noOfLines={1} title={description} variant="small" wordBreak="break-word">
							{description}
						</Text>
					</Flex>
					{children}
				</CardFooter>
			</Card>
		</Flex>
	</Flex>
);

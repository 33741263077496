import { Box, Button, ButtonGroup } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { XMTenantVersionHandler } from 'features/main/components/XMTenantVersionHandler';
import { PosLayout } from 'features/pos/components/PosLayout';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';

export const SettingsLayout: FC = () => {
	return (
		<Box width="full" p="8" gap="8" display="flex">
			<XMTenantVersionHandler>
				<ButtonGroup variant="navigation" orientation="vertical" spacing="1" width="2xs">
					<Button isActive>
						<T _str={DICTIONARY.ANALYTICS_IDENTIFIER} />
					</Button>
				</ButtonGroup>
				<PosLayout />
			</XMTenantVersionHandler>
		</Box>
	);
};

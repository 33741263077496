import { Modal, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { RemoveCollectionProps } from 'features/remove-collection/models';
import { RemoveCollectionModalBody } from 'features/remove-collection/ui';
import { FC, PropsWithChildren, useState } from 'react';
import { DICTIONARY } from 'shared/constants';
import { useModalWrapper } from 'shared/modal-wrapper/hooks';

export const RemoveCollectionModal: FC<PropsWithChildren<RemoveCollectionProps>> = (props) => {
	const { children, isOpen, onClose } = useModalWrapper({ children: props.children });
	const [isLoading, setIsLoading] = useState(false);

	return (
		<>
			{children}
			<Modal isOpen={isOpen} onClose={onClose} closeOnEsc={!isLoading} closeOnOverlayClick={!isLoading}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						<T _str={DICTIONARY.DELETE_COLLECTION} />
					</ModalHeader>
					<RemoveCollectionModalBody isOpen={isOpen} onClose={onClose} {...props} setIsLoading={setIsLoading} />
				</ModalContent>
			</Modal>
		</>
	);
};

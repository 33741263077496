import { useCallback, useState } from 'react';

export const usePosMappingProcessing = () => {
	const [processingLang, setProcessingLang] = useState<string>();
	const processMapping = useCallback(async (processPromise: Promise<void>, siteLang?: string) => {
		setProcessingLang(siteLang);

		try {
			await processPromise;
		} finally {
			setProcessingLang(undefined);
		}
	}, []);

	return {
		processMapping,
		processingLang,
	};
};

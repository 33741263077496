import { Flex, IconButton, MenuItem, Text } from '@chakra-ui/react';
import { mdiChevronDown, mdiChevronRight } from '@mdi/js';
import { Icon } from 'entities/icon/ui';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import type { ItemTreeProps, ItemViewProps } from 'shared/item-tree/models';
import { useItemView } from '../hooks';

export const ItemView: FC<ItemViewProps & ItemTreeProps> = (props) => {
	const { item, opened, setOpened, level } = props;
	const { onSelect, color } = useItemView(props);

	if (!item || !item?.id) {
		return <></>;
	}

	return (
		<MenuItem p="1" backgroundColor={color} onClick={onSelect} as="span" cursor="pointer">
			<Flex direction="row" padding={1} gap={1} alignItems="center" paddingLeft={`${level ? level * 0.5 : 0}rem`}>
				<IconButton
					onClick={(e) => {
						setOpened(!opened);
						e.stopPropagation();
					}}
					variant="ghost"
					size="xs"
					aria-label={opened ? DICTIONARY.COLLAPSE : DICTIONARY.EXPAND}
					icon={<Icon path={opened ? mdiChevronDown : mdiChevronRight} />}
					visibility={item.hasChildren ? 'visible' : 'hidden'}
				/>
				<Text noOfLines={1} title={item.name}>
					{item.displayName || item.name}
				</Text>
			</Flex>
		</MenuItem>
	);
};

import { AlertDialog, AlertDialogContent, AlertDialogOverlay, Box, Tooltip } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { RemovePoSProps } from 'features/point-of-sale/models';
import { RemovePoSAlertBody } from 'features/point-of-sale/ui/remove-pos-alert-body';
import { FC, PropsWithChildren, useRef } from 'react';

import { DICTIONARY } from 'shared/constants';
import { useModalWrapper } from 'shared/modal-wrapper/hooks';

export const RemovePoSAlert: FC<PropsWithChildren<RemovePoSProps>> = (props) => {
	const { children, isOpen, onClose } = useModalWrapper(props);
	const cancelRef = useRef(null);

	return (
		<>
			<Tooltip label={<T _str={DICTIONARY.DELETE} />}>
				<Box w="fit-content">{children}</Box>
			</Tooltip>
			<AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef}>
				<AlertDialogOverlay />
				<AlertDialogContent>
					<RemovePoSAlertBody onClose={onClose} {...props} />
				</AlertDialogContent>
			</AlertDialog>
		</>
	);
};

import { TokenCustomClaimKeysEnum } from 'features/common/auth/SitecoreUser';
import { useAuthWithClaims } from 'features/common/auth/useAuthWithClaims';
import { pages } from 'features/common/config/config';
import { useXMTenant } from 'features/common/tenants/xmTenantContext';
import { UseRedirectDefinitionSiteProps } from 'shared/open-in-pages/models';

export const useRedirectDefinitionSite = ({ onClose }: UseRedirectDefinitionSiteProps) => {
	const { tenant } = useXMTenant();
	const { name: tenantName } = tenant;
	const { user } = useAuthWithClaims();
	const organizationId = user?.[TokenCustomClaimKeysEnum.ORG_ID];

	const redirectHandler =
		(getUrl: (...args: (string | undefined)[]) => string, siteDefinitionName: string, itemId?: string) =>
		(event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>): void => {
			const url = getUrl(organizationId, tenantName, siteDefinitionName, itemId);

			event.preventDefault();
			onClose();
			window.open(url, '_blank');
		};

	return {
		pageRedirectHandler: (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>, siteDefinitionName: string, itemId?: string) =>
			redirectHandler(pages.getUrl, siteDefinitionName, itemId)(event),
	};
};

import { useSaveNavigate } from 'features/common/utils/navigationUtils';
import { RenameFormHeader } from 'features/sites/components/rename/RenameForm';
import React, { ChangeEvent, FC, useCallback } from 'react';
import { RegisterDiscardChanges } from './RegisterDiscardChanges';
import { usePreventDiscardChanges } from './PreventDiscradChanges';

export const GeneralTabForm: FC<React.PropsWithChildren<{ siteName?: string }>> = ({ children, siteName }) => {
	const { setPendingChanges } = usePreventDiscardChanges();

	const navigate = useSaveNavigate();
	const navigateToNewName = useCallback(
		(newName: string) => {
			navigate(`/sites/${newName}/general`);
		},
		[navigate]
	);

	const onHandleChange = useCallback(
		(e: ChangeEvent) => {
			const input = e.target as HTMLInputElement;
			const newValue = input.value;

			if (newValue == siteName) {
				setPendingChanges(false);
			} else {
				setPendingChanges(true);
			}
		},
		[setPendingChanges, siteName]
	);

	const onResetForm = useCallback(() => {
		setPendingChanges(false);
	}, [setPendingChanges]);

	return (
		<RenameFormHeader
			siteName={siteName}
			initialNewName={siteName}
			onRename={navigateToNewName}
			onReset={onResetForm}
			onHandleChange={onHandleChange}
		>
			<>
				<RegisterDiscardChanges />
				{children}
			</>
		</RenameFormHeader>
	);
};

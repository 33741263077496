import { ReactQueryWrappersContext } from 'app/react-query-wrappers-provider/ui/react-query-wrappers-provider';
import { useContext } from 'react';

export const useReactQueryWrappersProvider = () => {
	const context = useContext(ReactQueryWrappersContext);

	if (!context) {
		throw new Error('useReactQueryWrappersProvider must be used within ReactQueryWrappersProvider');
	}

	return context;
};

import { useGetHostsWithMissingPosList } from 'features/point-of-sale/hooks';
import { SiteDefinitionPageOutlet } from 'pages/site-definition/models/site-definition-props';
import { SitePageOutlet } from 'pages/site/models';
import { useCallback, useMemo } from 'react';
import { useOutletContext, useParams } from 'react-router';
import { ENDPOINTS } from 'shared/endpoints';
import { useGetLanguages } from 'shared/languages/hooks';
import { RenderingHost, SiteResponse, mapDefinitionResponseToViewModel } from 'shared/models';
import { useNavigateToRoute } from 'shared/navigation/hooks';
import { useGet, useSimpleGet } from 'shared/react-query-wrappers/hooks';
import { ROUTES } from 'shared/routes';

export const useSiteDefinitionPage = () => {
	const navigate = useNavigateToRoute();
	const { site, languages, isLoading, locationOrigin, hosts } = useOutletContext<SitePageOutlet>();
	const { hostId } = useParams<{ hostId: string }>();
	const goBack = useCallback(() => navigate(`../${ROUTES.SITE.DEFINITIONS.NAME}`), [navigate]);
	const host = useMemo(() => hosts?.find((sd) => sd.id === hostId), [hosts, hostId]);
	const { data: allRenderingHosts } = useGet<RenderingHost[]>({
		queryKey: { url: ENDPOINTS.RENDERING_HOSTS, params: { siteId: site?.id || '' } },
		enabled: !!site,
	});
	const { data: sites } = useSimpleGet<SiteResponse[]>(ENDPOINTS.SITES);
	const { data: allLanguages } = useGetLanguages();

	const validations = useMemo(() => {
		const allHosts = sites?.flatMap((s) => s.hosts);
		const allDefinitionNames = hosts?.map((definition) => definition.name).filter((name) => name !== host?.name) ?? [];
		const allHostnames =
			allHosts?.flatMap((definition) => definition.hostnames).filter((hostname) => hostname !== '*' && !host?.hostnames.includes(hostname)) ??
			[];

		const siteHasAsterisk = site?.hosts?.some((h) => h.hostnames?.includes('*') && h.id !== hostId);

		if (siteHasAsterisk) {
			allHostnames.push('*');
		}

		const allTargetHostnames =
			allHosts
				?.filter((sd) => sd.id !== hostId && sd.targetHostname !== host?.targetHostname)
				.map((definition) => ({
					sideDefName: definition.name,
					targetHostName: definition.targetHostname,
				})) ?? [];

		return { allDefinitionNames, allHostnames, allTargetHostnames };
	}, [host?.hostnames, host?.name, host?.targetHostname, hostId, hosts, site?.hosts, sites]);

	const { allDefinitionNames, allHostnames, allTargetHostnames } = validations;
	const { missingPosList, isLoading: isPosLoading } = useGetHostsWithMissingPosList();

	const hostWithMissingPoSs = useMemo(() => {
		if (isPosLoading) {
			return undefined;
		}

		return missingPosList.hosts?.find((s) => s.id === hostId) || undefined;
	}, [missingPosList, isPosLoading, hostId]);

	const outletContext = useMemo<SiteDefinitionPageOutlet>(
		() => ({
			site,
			siteDefinition: mapDefinitionResponseToViewModel(host, site),
			languages,
			allLanguages,
			siteWithMissingPos: undefined,
			isLoading,
			locationOrigin: locationOrigin ?? '/',
			allDefinitionNames,
			allHostnames,
			allTargetHostnames,
			hostWithMissingPoSs,
			allRenderingHosts,
		}),
		[
			site,
			host,
			languages,
			allLanguages,
			isLoading,
			locationOrigin,
			allDefinitionNames,
			allHostnames,
			allTargetHostnames,
			hostWithMissingPoSs,
			allRenderingHosts,
		]
	);

	return {
		outletContext,
		goBack,
		allDefinitionNames,
		allHostnames,
		allTargetHostnames,
		allRenderingHosts,
	};
};

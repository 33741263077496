import { Button, ButtonGroup, ModalBody, ModalFooter, Stack } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FormFieldControl } from 'entities/form-field-control/ui';
import { InfoPopover } from 'entities/info-popover/ui';
import { useRenamePage } from 'features/page-actions-menu/hooks';
import { RenamePageModalBodyProps } from 'features/page-actions-menu/models';
import { Field, Form, Formik } from 'formik';
import { FC } from 'react';
import { DICTIONARY, VALIDATIONS } from 'shared/constants';
import type { FormikFieldProps } from 'shared/models';
import { NonPredictiveInput } from 'shared/utils';

export const RenamePageModalBody: FC<RenamePageModalBodyProps> = ({ onClose, id, siteId, name, displayName, siblingNames }) => {
	const { sitePageItemValidationSchema, validatePageNameUniqueness, submitHandler } = useRenamePage(
		id,
		siteId,
		siblingNames,
		name,
		displayName,
		onClose
	);

	return (
		<Formik
			initialValues={{ name: name || '', displayName: displayName || '' }}
			validationSchema={sitePageItemValidationSchema}
			validate={(values) => validatePageNameUniqueness(values.name)}
			onSubmit={submitHandler}
			validateOnBlur={false}
			validateOnChange={false}
		>
			{({ isSubmitting }) => (
				<Form noValidate>
					<ModalBody>
						<Stack gap="6">
							<Field name="name">
								{({ field, form }: FormikFieldProps<string>) => (
									<FormFieldControl
										form={form}
										field={field}
										name="name"
										isRequired
										label={DICTIONARY.ITEM_NAME}
										helper={DICTIONARY.USE_TO_BUILD_URL}
										maxLength={VALIDATIONS.PAGE_NAME_LENGTH}
									>
										<NonPredictiveInput
											{...field}
											autoFocus
											data-testid="rename-page-name-input"
											maxLength={VALIDATIONS.PAGE_NAME_LENGTH}
										/>
									</FormFieldControl>
								)}
							</Field>
							<Field name="displayName">
								{({ field, form }: FormikFieldProps<string>) => (
									<FormFieldControl
										form={form}
										field={field}
										name="displayName"
										label={DICTIONARY.DISPLAY_NAME_ENGLISH}
										helper={DICTIONARY.PAGE_NAME_WITHIN_XMCLOUD}
										maxLength={VALIDATIONS.PAGE_NAME_LENGTH}
										labelHint={<InfoPopover body={DICTIONARY.DISPLAY_NAME_ENGLISH_HINT} placement="bottom" />}
									>
										<NonPredictiveInput
											{...field}
											data-testid="rename-page-display-name-input"
											maxLength={VALIDATIONS.PAGE_NAME_LENGTH}
										/>
									</FormFieldControl>
								)}
							</Field>
						</Stack>
					</ModalBody>
					<ModalFooter>
						<ButtonGroup>
							<Button variant="ghost" onClick={onClose} isDisabled={isSubmitting}>
								<T _str={DICTIONARY.CANCEL} />
							</Button>
							<Button isLoading={isSubmitting} type="submit">
								<T _str={DICTIONARY.RENAME} />
							</Button>
						</ButtonGroup>
					</ModalFooter>
				</Form>
			)}
		</Formik>
	);
};

import { AppState, AuthorizationParams, GetTokenSilentlyOptions, RedirectLoginOptions } from '@auth0/auth0-react';
import { useAuthWithClaims } from 'features/common/auth/useAuthWithClaims';
import { FC, useEffect } from 'react';
import { targetedEnvName as environment } from '../../../features/common/config/envConfig';
import { AppSwitcherInterface } from '../models';

interface CompatibleRedirectLoginOptions extends Omit<RedirectLoginOptions<AppState>, 'authorizationParams'>, AuthorizationParams {
	redirectUri?: string;
}

export const compatibleLoginWithRedirect = (loginWithRedirect: (options?: RedirectLoginOptions<AppState>) => Promise<void>) => {
	return (oldOptions?: CompatibleRedirectLoginOptions) => {
		if (oldOptions && 'authorizationParams' in oldOptions) {
			return loginWithRedirect(oldOptions);
		}

		const { appState, fragment, ...authorizationParams } = oldOptions ?? {};

		// rename redirectUri property to redirect_uri
		authorizationParams.redirect_uri = authorizationParams.redirectUri;
		authorizationParams.redirectUri = undefined;
		// ensure tenant_name is not provided when organization switches
		authorizationParams.tenant_name = undefined;

		const options: RedirectLoginOptions<AppState> = {
			appState,
			fragment,
			authorizationParams,
		};

		return loginWithRedirect(options);
	};
};

export type GetTokenSilentlyAuthorizationParams = NonNullable<GetTokenSilentlyOptions['authorizationParams']>;
export interface OldGetTokenSilentlyOptions
	extends Omit<GetTokenSilentlyOptions, 'authorizationParams' | 'cacheMode'>,
		GetTokenSilentlyAuthorizationParams {
	ignoreCache?: boolean;
}

export const compatibleGetAccessTokenSilently = (getTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<string>) => {
	return (oldOptions?: OldGetTokenSilentlyOptions) => {
		const { timeoutInSeconds, detailedResponse, ignoreCache, ...authorizationParams } = oldOptions ?? {};

		if (oldOptions && 'authorizationParams' in oldOptions) {
			return getTokenSilently(oldOptions);
		}

		const options: GetTokenSilentlyOptions = {
			timeoutInSeconds,
			detailedResponse,
			cacheMode: ignoreCache ? 'off' : ignoreCache == false ? 'on' : undefined,
			authorizationParams,
		};

		return getTokenSilently(options);
	};
};

export const AppSwitcher: FC = () => {
	const { getAccessTokenSilently: getAccessToken, loginWithRedirect, user } = useAuthWithClaims();

	// Init app switcher
	useEffect(() => {
		const initialize = () => {
			const appSwitcher = document.querySelector('app-switcher') as unknown as AppSwitcherInterface;

			if (!appSwitcher || !appSwitcher?.componentOnReady) return;

			void appSwitcher.componentOnReady().then(() => {
				void appSwitcher.init({
					getAccessToken: compatibleGetAccessTokenSilently(getAccessToken),
					loginWithRedirect: compatibleLoginWithRedirect(loginWithRedirect),
					environment,
					organizationId: user?.['https://auth.sitecorecloud.io/claims/org_id'] ?? '',
					tenantId: user?.['https://auth.sitecorecloud.io/claims/tenant_id'],
					isDevMode: false,
					applicationName: 'Dashboard',
				});
			});
		};

		initialize();
	}, [loginWithRedirect, getAccessToken, user]);

	return (
		<div style={{ zIndex: 1 }}>
			<app-switcher></app-switcher>
		</div>
	);
};

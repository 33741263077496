import { useToast } from '@chakra-ui/react';
import { useFeatures } from 'features/common/featureFlags/featureFlags';
import { useTranslate } from 'features/common/utils/translateUtils';
import { CreateSiteData, UseCreateSite } from 'features/create-site/models';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useCreateSinglePoSAction } from 'shared/api';
import { CREATE_SITE_MODAL_HASHTAG, DICTIONARY, HOST_NAME, SELECTED_LANGUAGE } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { usePost } from 'shared/react-query-wrappers/hooks';
import { sanitizeSiteName } from 'shared/utils';

export const useCreateSite: UseCreateSite = (collectionId, onClose, isOpen) => {
	const { showManagedLanguages } = useFeatures();
	const [currentStep, setCurrentStep] = useState(0);
	const [isDetailsFormValid, setIsDetailsFormValid] = useState(false);
	const [isSiteCreationPending, setIsSiteCreationPending] = useState(false);
	const [createSiteData, setCreateSiteData] = useState<CreateSiteData | undefined>(
		showManagedLanguages ? { language: SELECTED_LANGUAGE, languages: [SELECTED_LANGUAGE] } : undefined
	);
	const { mutateAsync: createSite } = usePost({
		queryKey: { url: ENDPOINTS.SITES },
		refetchQueriesByKey: [{ url: ENDPOINTS.JOBS }, { url: ENDPOINTS.LANGUAGES }],
		ignoreGlobalErrorHandler: true,
	});
	const { createPoS } = useCreateSinglePoSAction();
	const navigate = useNavigate();
	const { hash, pathname, search } = useLocation();

	const toast = useToast();
	const t = useTranslate();

	const steps = useMemo(() => [{ title: t(DICTIONARY.SITE_TEMPLATE) }, { title: t(DICTIONARY.SITE_DETAILS) }], [t]);

	const isLastStep = useMemo(() => currentStep === steps.length - 1, [currentStep, steps.length]);

	const isNextStepDisabled = useMemo(() => {
		switch (currentStep) {
			case 0:
				return !createSiteData?.templateId;
			case 1:
				return !isDetailsFormValid || isSiteCreationPending;
			default:
				return true;
		}
	}, [createSiteData?.templateId, currentStep, isDetailsFormValid, isSiteCreationPending]);

	const onCloseComplete = useCallback(() => {
		setCurrentStep(0);
		setCreateSiteData(showManagedLanguages ? { language: SELECTED_LANGUAGE, languages: [SELECTED_LANGUAGE] } : {});
		setIsDetailsFormValid(false);
		setIsSiteCreationPending(false);
	}, [showManagedLanguages]);

	const onSiteCreate = useCallback(async () => {
		setIsSiteCreationPending(true);

		const sanitizedSiteName = sanitizeSiteName(createSiteData?.displayName);
		const sanitizedCollectionName = sanitizeSiteName(createSiteData?.collection?.label);
		const createPosIdentifierResult = await createPoS({ name: sanitizedSiteName ?? '' });
		let updatedCreateSiteData = {
			siteName: sanitizedSiteName,
			displayName: createSiteData?.displayName,
			templateId: createSiteData?.templateId,
			language: createSiteData?.language?.value,
			description: createSiteData?.description,
			hostName: HOST_NAME,
			...(createSiteData?.collection?.__isNew__
				? { collectionName: sanitizedCollectionName, collectionDisplayName: createSiteData?.collection?.label, collectionDescription: '' }
				: { collectionId: createSiteData?.collection?.value ?? collectionId }),
			posMappings: [{ name: createPosIdentifierResult, language: createSiteData?.language?.value }],
		};

		updatedCreateSiteData = {
			...updatedCreateSiteData,
			...(showManagedLanguages ? { languages: createSiteData?.languages?.map((lang) => lang.value) } : {}),
		};

		try {
			const createSiteResult = await createSite(updatedCreateSiteData);

			if (createSiteResult) {
				onClose();
			}

			return createSiteResult;
		} catch (error) {
			toast({ description: t(DICTIONARY.SITE_CREATION_FAILED), status: 'error' });

			throw new Error('Mutation failed on create site');
		} finally {
			setIsSiteCreationPending(false);
		}
	}, [
		createSiteData?.displayName,
		createSiteData?.collection?.label,
		createSiteData?.collection?.__isNew__,
		createSiteData?.collection?.value,
		createSiteData?.templateId,
		createSiteData?.language?.value,
		createSiteData?.description,
		createSiteData?.languages,
		createPoS,
		collectionId,
		showManagedLanguages,
		createSite,
		onClose,
		toast,
		t,
	]);

	const closeWindow = useCallback(() => {
		onClose();
		navigate(-1);
	}, [onClose, navigate]);

	const handleCreateSite = useCallback(async () => {
		await onSiteCreate();

		navigate(-1);
	}, [navigate, onSiteCreate]);

	useEffect(() => {
		if (hash !== CREATE_SITE_MODAL_HASHTAG && isOpen) {
			onClose();
		}
	}, [onClose, hash, isOpen, navigate, pathname, search]);

	return {
		steps,
		currentStep,
		setCurrentStep,
		createSiteData,
		setCreateSiteData,
		isNextStepDisabled,
		onCloseComplete,
		isLastStep,
		onSiteCreate,
		setIsDetailsFormValid,
		closeWindow,
		handleCreateSite,
		isSiteCreationPending,
	};
};

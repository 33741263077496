import { Flex, FormControl, FormErrorIcon, FormErrorMessage, FormHelperText, FormLabel, Stack } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FormFieldControlProps } from 'entities/form-field-control/models';
import React, { ChangeEvent, useCallback } from 'react';
import { XSS_REGEXP } from 'shared/constants';

export const FormFieldControl: React.FC<FormFieldControlProps> = ({
	children,
	form,
	helper,
	field,
	name,
	maxLength,
	label,
	xssDisable = false,
	labelHint,
	...props
}) => {
	const handlerChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			if (!xssDisable && (e.target.type === 'text' || e.target.type === 'textarea')) {
				form.setFieldValue(field.name, e.target.value.replace(XSS_REGEXP, ''));
			}
		},
		[field.name, form, xssDisable]
	);

	return (
		<FormControl isInvalid={form.errors[name] && form.touched[name]} {...props} onChange={handlerChange}>
			<Flex marginBottom="2">
				<FormLabel marginBottom="0" htmlFor={name} {...(labelHint ? { mr: 'unset' } : {})}>
					<T _str={label} />
				</FormLabel>
				{labelHint}
			</Flex>
			{children}
			<Flex justify="space-between">
				<Stack gap="0">
					{helper && <FormHelperText>{typeof helper === 'string' ? <T _str={helper} /> : helper}</FormHelperText>}
					<FormErrorMessage alignContent="end" data-testid={`${name}-error-container`}>
						<FormErrorIcon />
						<T _str={form.errors[name] || ''} />
					</FormErrorMessage>
				</Stack>
				{maxLength && (
					<FormHelperText>
						{field?.value?.length || 0}/{maxLength}
					</FormHelperText>
				)}
			</Flex>
		</FormControl>
	);
};

import { UseLanguageSectionForm } from 'pages/languages/models';
import { useCallback, useRef } from 'react';
import { SITE_SETTINGS_FORM_EVENT_NAME } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { Access } from 'shared/models';
import { useSimpleGet } from 'shared/react-query-wrappers/hooks';
import { useEventListener } from 'usehooks-ts';
import { GeneralSectionEvent } from 'widgets/site-settings-update-form/models';

export const useLanguageSectionForm: UseLanguageSectionForm = (submitForm, resetForm) => {
	const { data: languageAccess } = useSimpleGet<Access>(ENDPOINTS.LANGUAGE_PERMISSIONS);
	const documentRef = useRef<Document>(document);

	const onButtonsClick = useCallback(
		(event: CustomEvent<GeneralSectionEvent>) => {
			const { type } = event.detail;

			if (type === 'submit') {
				void submitForm();
			} else if (type === 'reset') {
				resetForm();
			}
		},
		[resetForm, submitForm]
	);

	useEventListener(SITE_SETTINGS_FORM_EVENT_NAME, onButtonsClick, documentRef);

	return { languageAccess };
};

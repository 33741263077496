import { useToast } from '@chakra-ui/react';
import { useTranslate } from 'features/common/utils/translateUtils';
import { useRemoveGeneralThumbnail, useThumbnail, useUpdateHostThumbnailSource, useUploadGeneralThumbnail } from 'features/thumbnail/hooks';
import { ThumbnailType } from 'features/thumbnail/models';
import { useCallback, useMemo, useState } from 'react';
import { DICTIONARY } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { Host, SiteResponse } from 'shared/models';
import { useGet, usePatch } from 'shared/react-query-wrappers/hooks';
import { base64ToFile } from 'shared/utils';
import { onIsSubmittingChange } from 'widgets/site-definition-update-form/models';
import { SiteGeneralInput, SiteGeneralViewModel, onSiteGeneralSettingsSaved } from 'widgets/site-settings-update-form/models';

export const useSiteSettingsGeneralSectionForm = (site: SiteResponse) => {
	const { id, collectionId, displayName, name, description, hosts, thumbnail, properties } = site;
	const toast = useToast();
	const t = useTranslate();
	const [hostId, setHostId] = useState('');

	const originalThumbnailHostId = useMemo<string>(() => {
		const activeHost = hosts?.find((host: Host) => host?.settings?.isSiteThumbnailSource === 'true');
		const originalHostId = activeHost ? activeHost.id : '';

		setHostId(originalHostId);

		return originalHostId;
	}, [hosts]);

	const initialValues: SiteGeneralViewModel = useMemo(
		() => ({
			displayName: displayName || name,
			description,
			originalThumbnailHostId,
			newThumbnailHostId: '',
			mode: thumbnail.autogenerated ? ThumbnailType.AUTO : ThumbnailType.MANUAL,
			thumbnailBase64Url: '',
			toBeDeleted: 'false',
			shared: properties?.sharedSite || 'false',
		}),
		[displayName, name, description, originalThumbnailHostId, thumbnail.autogenerated, properties]
	);

	const { data: sites, isLoading } = useGet<SiteResponse[]>({
		queryKey: { url: ENDPOINTS.COLLECTION_SITES, params: { collectionId } },
		enabled: !!collectionId,
	});

	const { mutateAsync } = usePatch<SiteResponse, SiteGeneralInput>({
		queryKey: { url: ENDPOINTS.SITE, params: { id } },
		refetchQueriesByKey: [
			{ url: ENDPOINTS.SITE, params: { id }, shouldWait: true },
			{ url: ENDPOINTS.COLLECTION_SITES, params: { collectionId } },
			{ url: ENDPOINTS.JOBS },
			{ url: ENDPOINTS.SITES },
		],
	});
	const { mutateAsync: mutateUpdateSelectedThumbnail } = usePatch({ queryKey: { url: ENDPOINTS.SITE_HOST, params: { siteId: id, hostId } } });

	const { onAutoSubmitHandler } = useThumbnail({ site });

	const siteNames = useMemo(() => {
		let names: string[] = [];
		let displayNames: string[] = [];

		if (sites && !isLoading) {
			names = sites.map(({ name: value }) => value);
			displayNames = sites.map(({ displayName: value }) => value);
		}

		return { names, displayNames };
	}, [isLoading, sites]);

	const handleRemoveThumbnail = useRemoveGeneralThumbnail({ siteId: id });
	const handleUploadThumbnail = useUploadGeneralThumbnail({ siteId: id });
	const hostIsThumbnailSource = site?.hosts?.find((host: Host) => host?.settings?.isSiteThumbnailSource === 'true');
	const handleUpdateHostThumbnailSource = useUpdateHostThumbnailSource({
		siteId: site?.id,
		hostId: hostIsThumbnailSource?.id || site?.hosts[0]?.id,
	});

	const handleUpdateThumbnail = useCallback(
		async (formData?: FormData) => {
			await handleUpdateHostThumbnailSource(false);
			await handleUploadThumbnail(formData);
		},
		[handleUpdateHostThumbnailSource, handleUploadThumbnail]
	);

	const submit = useCallback(
		async (values: SiteGeneralViewModel) => {
			try {
				onIsSubmittingChange(true);

				const promises = [];

				if (!!values.newThumbnailHostId && !!values.originalThumbnailHostId) {
					if (hostId) {
						promises.push(mutateUpdateSelectedThumbnail({ settings: { isSiteThumbnailSource: 'false' } }));
					}

					setHostId(values.newThumbnailHostId);
					promises.push(mutateUpdateSelectedThumbnail({ settings: { isSiteThumbnailSource: 'true' } }));
				}

				if (onAutoSubmitHandler && values.mode === ThumbnailType.AUTO) {
					promises.push(onAutoSubmitHandler());
				}

				if (values.thumbnailBase64Url) {
					const formData = new FormData();

					const file = base64ToFile(values.thumbnailBase64Url);

					formData.append('file', file);
					promises.push(handleUpdateThumbnail(formData));
				}

				if (values.toBeDeleted === 'true') {
					promises.push(handleUpdateHostThumbnailSource(true));
					promises.push(handleRemoveThumbnail());
				}

				await Promise.all(promises);

				await mutateAsync({ displayName: values.displayName, description: values.description, shared: values.shared === 'true' });

				onSiteGeneralSettingsSaved(true);
				onIsSubmittingChange(false);
				toast({ description: t(DICTIONARY.SITE_UPDATED_MESSAGE), status: 'success' });
			} catch (error) {
				onSiteGeneralSettingsSaved(false);
				onIsSubmittingChange(false);
			}
		},
		[
			handleRemoveThumbnail,
			handleUpdateHostThumbnailSource,
			handleUpdateThumbnail,
			hostId,
			mutateAsync,
			mutateUpdateSelectedThumbnail,
			onAutoSubmitHandler,
			t,
			toast,
		]
	);

	return {
		initialValues,
		submit,
		siteNames,
	};
};

import {
	Button,
	ButtonGroup,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FormFieldProps } from 'entities/form-field-control/models';
import { FormFieldControl } from 'entities/form-field-control/ui';
import { FormSelectField } from 'entities/form-fields/ui';
import { InfoPopover } from 'entities/info-popover/ui';
import { useCreateSitePage } from 'features/create-site-page/hooks';
import { CreateSitePageModalProps, CreateSitePageViewModel } from 'features/create-site-page/models';
import { Field, Form, Formik } from 'formik';
import { FC } from 'react';
import { DEFAULT_PAGE_NAME_LENGTH, DICTIONARY } from 'shared/constants';
import { NonPredictiveInput } from 'shared/utils';
import { sitePageNameValidationSchema } from 'shared/validations';

export const CreateSitePageModal: FC<CreateSitePageModalProps & Pick<ReturnType<typeof useDisclosure>, 'isOpen' | 'onClose'>> = ({
	isOpen,
	pageId,
	parentPageId,
	displayName,
	onClose,
	language,
	actionType,
	insertOptions,
	insertOptionsParent,
}) => {
	const isSameLevel = actionType !== 'inside';
	const parentId = isSameLevel ? parentPageId : pageId;
	const currentInsertOptions = isSameLevel ? insertOptionsParent : insertOptions;

	const header =
		actionType === 'inside'
			? DICTIONARY.CREATE_CHILD_PAGE_OF
			: actionType === 'before'
			? DICTIONARY.CREATE_PAGE_BEFORE_CURRENT
			: DICTIONARY.CREATE_PAGE_AFTER_CURRENT;

	const { submitHandler, validatePageNameUniqueness, initialValues, mappedOptions } = useCreateSitePage(
		pageId,
		parentId,
		language,
		currentInsertOptions,
		isSameLevel,
		actionType
	);

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="md" blockScrollOnMount={false}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					<T _str={header} pageName={displayName} />
				</ModalHeader>
				<ModalCloseButton />
				<Formik<CreateSitePageViewModel>
					initialValues={initialValues}
					onSubmit={(values) => {
						void submitHandler(values);
						onClose();
					}}
					validationSchema={sitePageNameValidationSchema}
					validate={(values) => validatePageNameUniqueness(values)}
					validateOnChange={false}
					validateOnBlur={false}
				>
					{({ isSubmitting, handleChange, setFieldValue, setFieldTouched, touched }) => (
						<Form noValidate>
							<ModalBody>
								<Flex direction="column" gap="6">
									<Field name="name" validate>
										{({ field, form }: FormFieldProps) => (
											<FormFieldControl
												form={form}
												field={field}
												name={field.name}
												maxLength={DEFAULT_PAGE_NAME_LENGTH}
												label={DICTIONARY.ITEM_NAME}
												helper={DICTIONARY.PAGE_NAME_HELPER}
												isDisabled={isSubmitting}
												isRequired
											>
												<NonPredictiveInput
													{...field}
													onChange={(e) => {
														handleChange(e);
														if (!touched.displayName) setFieldValue('displayName', e.target.value);
													}}
													maxLength={DEFAULT_PAGE_NAME_LENGTH}
													isRequired={false}
													autoFocus
													data-testid="page-name-input"
												/>
											</FormFieldControl>
										)}
									</Field>
									<Field name="displayName">
										{({ field, form }: FormFieldProps) => (
											<FormFieldControl
												form={form}
												field={field}
												name={field.name}
												maxLength={DEFAULT_PAGE_NAME_LENGTH}
												label={DICTIONARY.PAGE_DISPLAY_NAME}
												helper={DICTIONARY.PAGE_DISPLAY_HELPER}
												isDisabled={isSubmitting}
												labelHint={<InfoPopover body={DICTIONARY.PAGE_DISPLAY_POPOVER} placement="bottom" />}
											>
												<NonPredictiveInput
													{...field}
													maxLength={DEFAULT_PAGE_NAME_LENGTH}
													isRequired={false}
													data-testid="page-displayName-input"
												/>
											</FormFieldControl>
										)}
									</Field>
									<FormSelectField
										name="templateId"
										label={DICTIONARY.PAGE_TEMPLATE}
										options={mappedOptions}
										handleChange={handleChange}
										setFieldTouched={setFieldTouched}
										isClearable={false}
										isRequired
									/>
								</Flex>
							</ModalBody>
							<ModalFooter>
								<ButtonGroup>
									<Button variant="ghost" onClick={onClose} isDisabled={isSubmitting}>
										<T _str={DICTIONARY.CANCEL} />
									</Button>
									<Button type="submit" isLoading={isSubmitting} data-testid="create-site-page-modal-button">
										<T _str={DICTIONARY.CREATE} />
									</Button>
								</ButtonGroup>
							</ModalFooter>
						</Form>
					)}
				</Formik>
			</ModalContent>
		</Modal>
	);
};

import { useToast } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { EditLanguageInput, LanguageValue, UseEditLanguageProps } from 'features/languages/models';
import { useCallback } from 'react';
import { DICTIONARY } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { usePatch } from 'shared/react-query-wrappers/hooks';

export const useEditLanguage = ({ language, fallbackLanguage, onClose }: UseEditLanguageProps) => {
	const { mutateAsync } = usePatch<void, EditLanguageInput>({
		queryKey: { url: ENDPOINTS.LANGUAGE, params: { isoCode: language } },
		refetchQueriesByKey: [{ url: ENDPOINTS.LANGUAGES, shouldWait: true }],
	});
	const toast = useToast();

	return useCallback(
		async ({ fallbackLanguage: fallbackLang }: LanguageValue) => {
			onClose();

			if (fallbackLanguage === fallbackLang) {
				return;
			}

			const input: EditLanguageInput = { fallbackLanguageIso: fallbackLang };
			const promise = mutateAsync(input);

			toast.promise(promise, {
				loading: { description: <T _str={DICTIONARY.LANGUAGE_EDIT_PROMISE_TOAST.LOADING} /> },
				error: { description: <T _str={DICTIONARY.LANGUAGE_EDIT_PROMISE_TOAST.ERROR} /> },
				success: { description: <T _str={DICTIONARY.LANGUAGE_EDIT_PROMISE_TOAST.SUCCESS} /> },
			});
		},
		[mutateAsync, onClose, toast, fallbackLanguage]
	);
};

import { useToast } from '@chakra-ui/react';
import { useTranslate } from 'features/common/utils/translateUtils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { COMMON_REFETCH_INTERVAL, DICTIONARY, SITE_SETTINGS_FORM_EVENT_NAME, SITE_SETTINGS_TOUCHED } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { useDeleteWithJob } from 'shared/react-query-wrappers/hooks';
import { RemoveSitePropsEA } from '../models';

const resetSiteSettingsForm = () => {
	document.dispatchEvent(new CustomEvent(SITE_SETTINGS_FORM_EVENT_NAME, { detail: { type: 'reset' } }));
	document.dispatchEvent(new CustomEvent(SITE_SETTINGS_TOUCHED, { detail: { isDirty: false } }));
};

/**
 * @deprecated Update is blocked for EA
 */
export const useRemoveSiteDataEA = (props: RemoveSitePropsEA, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
	const navigate = useNavigate();
	const t = useTranslate();
	const toast = useToast({ status: 'success', description: t(DICTIONARY.SITE_DELETED) });
	const [text, setText] = useState('');
	const isRemoveDisabled = useMemo(() => text !== props.name, [text, props.name]);
	const { isPending, mutateAsync } = useDeleteWithJob(
		{
			queryKey: { url: ENDPOINTS.SITE, params: { id: props.id ?? '' } },
			refetchQueriesByKey: [
				{ url: ENDPOINTS.COLLECTION_SITES, params: { collectionId: props.collectionId ?? '' } },
				{ url: ENDPOINTS.SITES },
				{ url: ENDPOINTS.JOBS },
			],
		},
		COMMON_REFETCH_INTERVAL
	);

	const handleRemove = useCallback(async () => {
		resetSiteSettingsForm();
		await mutateAsync();
		toast();
		navigate(props.locationOrigin);
	}, [mutateAsync, navigate, props.locationOrigin, toast]);

	useEffect(() => setIsLoading(isPending), [isPending, setIsLoading]);

	return {
		text,
		isPending,
		isRemoveDisabled,
		handleRemove,
		setText,
	};
};

import { useToast } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { useReactQueryWrappersProvider } from 'app/react-query-wrappers-provider/hooks';
import { useXMTenant } from 'features/common/tenants/xmTenantContext';
import { useTranslate } from 'features/common/utils/translateUtils';
import { UseRemoveSiteData } from 'features/remove-site/models/remove-site-data';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { COMMON_REFETCH_INTERVAL, DICTIONARY, SITE_SETTINGS_FORM_EVENT_NAME, SITE_SETTINGS_TOUCHED } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { useDeleteWithJob } from 'shared/react-query-wrappers/hooks';

const resetSiteSettingsForm = () => {
	document.dispatchEvent(new CustomEvent(SITE_SETTINGS_FORM_EVENT_NAME, { detail: { type: 'reset' } }));
	document.dispatchEvent(new CustomEvent(SITE_SETTINGS_TOUCHED, { detail: { isDirty: false } }));
};

export const useRemoveSiteData: UseRemoveSiteData = (props) => {
	const navigate = useNavigate();
	const { tenant } = useXMTenant();
	const t = useTranslate();
	const toast = useToast();
	const { queryClient } = useReactQueryWrappersProvider();
	const [text, setText] = useState('');

	const callbackOnMutationSuccess = useCallback(() => void queryClient.invalidateQueries({ queryKey: [ENDPOINTS.JOBS] }), [queryClient]);

	const isRemoveDisabled = useMemo(() => text !== props.name, [text, props.name]);

	const { mutateAsync, isPending } = useDeleteWithJob(
		{
			queryKey: { url: ENDPOINTS.DELETE_SITE, params: { id: props.id ?? '', force: 'true' } },
			refetchQueriesByKey: [
				{ url: ENDPOINTS.COLLECTION_SITES, params: { collectionId: props.collectionId || '' }, shouldWait: true },
				{ url: ENDPOINTS.JOBS },
				{ url: ENDPOINTS.SITES },
			],
		},
		COMMON_REFETCH_INTERVAL,
		callbackOnMutationSuccess
	);

	const handleRemove = useCallback(() => {
		resetSiteSettingsForm();

		mutateAsync()
			.then(() => {
				toast({ description: t(DICTIONARY.SITE_DELETED), status: 'success' });
			})
			.catch(() => toast({ description: t(DICTIONARY.SITE_DELETION_FAILED), status: 'error' }));

		navigate(props.locationOrigin);
		toast({ description: <T _str={DICTIONARY.SITE_DELETING} sitename={props.name} />, status: 'loading' });
	}, [mutateAsync, navigate, props.locationOrigin, props.name, t, toast]);

	return {
		text,
		isRemoveDisabled,
		handleRemove,
		setText,
		environment: tenant.environmentName || '',
		isPending,
	};
};

import { useCallback } from 'react';
import { ENDPOINTS } from 'shared/endpoints';
import { usePatch } from 'shared/react-query-wrappers/hooks';

export const useUpdateHostThumbnailSource = ({ siteId, hostId }: { siteId: string | undefined; hostId: string | undefined }) => {
	const { mutateAsync } = usePatch({
		queryKey: { url: ENDPOINTS.SITE_HOST, params: { siteId: siteId || '', hostId: hostId || '' } },
	});

	return useCallback(
		async (value = false) => {
			await mutateAsync({ settings: { isSiteThumbnailSource: value.toString() } });
		},
		[mutateAsync]
	);
};

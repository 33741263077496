import { useToast } from '@chakra-ui/react';
import { useTranslate } from 'features/common/utils/translateUtils';
import { RemoveCollectionProps } from 'features/remove-collection/models';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { COMMON_REFETCH_INTERVAL, DICTIONARY } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { useDeleteWithJob, useGet } from 'shared/react-query-wrappers/hooks';

export const useRemoveCollectionData = (
	props: RemoveCollectionProps,
	isOpen: boolean,
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
	const navigate = useNavigate();
	const t = useTranslate();
	const toast = useToast({ status: 'success', description: t(DICTIONARY.COLLECTION_DELETED) });
	const [text, setText] = useState('');
	const isRemoveDisabled = useMemo(() => text !== props.displayName, [text, props.displayName]);
	const { isPending, mutateAsync } = useDeleteWithJob(
		{
			queryKey: { url: ENDPOINTS.COLLECTION, params: { collectionId: props.id ?? '' } },
			refetchQueriesByKey: [{ url: ENDPOINTS.COLLECTIONS, shouldWait: true }, { url: ENDPOINTS.JOBS }, { url: ENDPOINTS.SITES }],
		},
		COMMON_REFETCH_INTERVAL
	);
	const { data } = useGet<[]>({
		queryKey: { url: ENDPOINTS.COLLECTION_SITES, params: { collectionId: props.id ?? '' } },
		enabled: isOpen && !!props.id,
	});
	const siteCount: number = data?.length || 0;

	const handleRemove = useCallback(async () => {
		await mutateAsync();
		toast();
		navigate('/');
	}, [mutateAsync, navigate, toast]);

	useEffect(() => setIsLoading(isPending), [isPending, setIsLoading]);

	return {
		text,
		isPending,
		isRemoveDisabled,
		siteCount,
		handleRemove,
		setText,
	};
};

import { FC, useCallback, useMemo } from 'react';
import { ENDPOINTS } from 'shared/endpoints';
import { useSimpleGet } from 'shared/react-query-wrappers/hooks';
import { useEventListener } from 'usehooks-ts';
import config from '../config/config';
import { FlagsProvider } from './featureFlags';

export interface AzureFeatureFlagValue {
	id: string;
	enabled: boolean;
	conditions: {
		client_filters: { name: string; parameters?: Record<string, unknown> }[];
	};
	description?: string;
	displayName?: string;
}

export interface ApiFeatureFlagValue {
	feature: string;
	enabled: boolean;
}

export function transformFlags(azureFlags: AzureFeatureFlagValue[], apiFlags: ApiFeatureFlagValue[]): Record<string, boolean> {
	const result: Record<string, boolean> = {};

	for (const flag of azureFlags) {
		if (flag.enabled && flag.conditions.client_filters && flag.conditions.client_filters.length) {
			const localStorageCondition = flag.conditions.client_filters[0];

			if (localStorageCondition.name === 'ExtraConditions' && localStorageCondition.parameters?.apiFlagName) {
				const apiFlag = apiFlags.find((f) => f.feature === localStorageCondition.parameters?.apiFlagName);

				if (apiFlag) {
					result[flag.id] = apiFlag.enabled;

					if (!apiFlag.enabled) {
						continue;
					}
				}
			}

			if (localStorageCondition.name === 'ExtraConditions' && localStorageCondition.parameters?.localStorage === 'true') {
				const localStorageValue = localStorage.getItem(flag.id);

				result[flag.id] = localStorageValue ? localStorageValue === 'true' : false;
				continue;
			}
		}

		result[flag.id] = flag.enabled;
	}

	return result;
}

export const AzureFeaturesProvider: FC<React.PropsWithChildren> = ({ children }) => {
	const { data: azureFlags, isLoading: isAzureFlagsLoading } = useSimpleGet<AzureFeatureFlagValue[]>(config.featureFlags.url);
	const { data: apiFlags, isLoading: isApiFlagsLoading } = useSimpleGet<ApiFeatureFlagValue[]>(ENDPOINTS.FEATURE_FLAGS);
	const togglePoints = useMemo(() => transformFlags(azureFlags || [], apiFlags || []), [azureFlags, apiFlags]);

	const handleStorageEvent = useCallback(
		(event: StorageEvent) => {
			if (event.key && Object.keys(togglePoints).includes(event.key)) {
				window.location.reload();
			}
		},
		[togglePoints]
	);

	useEventListener('storage', handleStorageEvent);

	if (isAzureFlagsLoading || isApiFlagsLoading) {
		return null;
	}

	return <FlagsProvider features={togglePoints}>{children}</FlagsProvider>;
};

import { ApolloQueryResult, OperationVariables, useQuery } from '@apollo/client';
import { useCallback, useState } from 'react';
import { DetailedItem, ItemResponse } from '../models';
import { getItemQuery } from '../queries';

type GetItemQueryHook = (
	itemId: string | undefined,
	siteId: string | undefined,
	language: string | undefined,
	skip: boolean
) => {
	item: DetailedItem | undefined;
	itemLoading: boolean;
};

export const useGetItemQuery: GetItemQueryHook = (
	itemId: string | undefined,
	siteId: string | undefined,
	language: string | undefined,
	skip: boolean
) => {
	const [itemResponse, setItemResponse] = useState<DetailedItem>();
	// Fetch site children in edge cases
	const fetchSiteChildren = useCallback(
		(refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<ItemResponse>>) => {
			refetch({
				itemId: siteId,
				language: language,
				getAncestors: false,
			})
				.then((siteData) => {
					setItemResponse(siteData.data?.item);
				})
				.catch(() => {
					/*ignore*/
				});
		},
		[language, siteId]
	);
	const { loading, refetch } = useQuery<ItemResponse>(getItemQuery, {
		variables: {
			itemId: itemId || siteId,
			language: language,
			getAncestors: itemId ? true : false,
		},
		skip,
		onCompleted: (data) => {
			const item = data.item;

			// Prevent state update on site fallback
			if (item && item.itemId !== itemId && item.itemId === siteId) {
				return;
			}

			// If no item with ID found, call site fallback
			if (!item && siteId) {
				fetchSiteChildren(refetch);

				return;
			}

			// if item is a child of selected site or site is selected, set state
			const siteAncestor = item.ancestors?.find((an) => an.itemId === siteId);

			if (siteAncestor || (item && itemId === siteId)) {
				setItemResponse(item);

				return;
			}

			// fallback to site if item is NOT a child of selected site
			if (siteId) {
				fetchSiteChildren(refetch);
			}
		},
		onError: () => {
			// this happened when server return error about wrong guid
			// if guid was changed menually
			fetchSiteChildren(refetch);
		},
	});
	const response = itemId ? itemResponse : undefined;

	return {
		item: response,
		itemLoading: loading,
	};
};

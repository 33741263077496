import { ButtonProps, IconButton, Menu, MenuButton, MenuList, Stack, Tooltip } from '@chakra-ui/react';
import { mdiDotsHorizontal } from '@mdi/js';
import { Icon } from 'entities/icon/ui';
import { useTranslate } from 'features/common/utils/translateUtils';
import { FC, PropsWithChildren } from 'react';
import { DICTIONARY } from 'shared/constants';

type MenuIconButtonProps = Pick<ButtonProps, 'variant'> & {
	labelText?: string;
	size?: ButtonProps['size'];
	isDisabled?: boolean;
};

export const MenuIconButton: FC<PropsWithChildren<MenuIconButtonProps>> = ({
	children,
	variant = 'ghost',
	size = 'md',
	labelText = DICTIONARY.OPTIONS,
	isDisabled,
}) => {
	const t = useTranslate();

	return (
		<Menu isLazy lazyBehavior="keepMounted">
			<Tooltip label={t(labelText)}>
				<MenuButton
					onFocus={(e) => e.preventDefault()}
					as={IconButton}
					aria-label={t(labelText)}
					icon={<Icon path={mdiDotsHorizontal} />}
					variant={variant}
					data-testid="dots-icon-button-menu"
					size={size}
					isDisabled={isDisabled}
				/>
			</Tooltip>
			<MenuList data-testid="contextMenuList" zIndex="dropdown">
				<Stack gap="0">{children}</Stack>
			</MenuList>
		</Menu>
	);
};

import { MenuItem } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { CreateCollectionModal } from 'features/collections/ui';
import { DICTIONARY } from 'shared/constants';

export const CreateCollectionMenuItem = () => {
	return (
		<CreateCollectionModal>
			<MenuItem data-testid="create-collection">
				<T _str={DICTIONARY.COLLECTION} />
			</MenuItem>
		</CreateCollectionModal>
	);
};

import { useMutation } from '@tanstack/react-query';
import { useReactQueryWrappersProvider } from 'app/react-query-wrappers-provider/hooks';
import { useQueryKey } from 'shared/react-query-wrappers/hooks/use-query-key';
import { useRefetchQueriesByKey } from 'shared/react-query-wrappers/hooks/use-refetch-queries-by-key';
import { QueryProps } from 'shared/react-query-wrappers/models';
import { disableGlobalErrorHandler } from 'shared/react-query-wrappers/utils/disable-global-error-handler';

/**
 * Make POST request to the server.
 */
export const usePost = <TData, TInput>(props: QueryProps) => {
	const { queryKey, ignoreGlobalErrorHandler, refetchQueriesByKey } = props;
	const { axios, queryClient } = useReactQueryWrappersProvider();
	const { key, calculateKey } = useQueryKey(queryKey);
	const onSuccess = useRefetchQueriesByKey(refetchQueriesByKey, queryClient, calculateKey);

	const queryResult = useMutation({
		mutationKey: key.queryKey,
		mutationFn: async (input: TInput) => {
			return disableGlobalErrorHandler<TData>(axios.post<TData>(key.url, input), ignoreGlobalErrorHandler);
		},
		onSuccess,
	});

	return { ...queryResult, data: queryResult.data?.data };
};

/**
 * Make POST form-data request to the server.
 */
export const usePostForm = <TData, TInput>(props: QueryProps) => {
	const { queryKey, ignoreGlobalErrorHandler, refetchQueriesByKey } = props;
	const { axios, queryClient } = useReactQueryWrappersProvider();
	const { key, calculateKey } = useQueryKey(queryKey);
	const onSuccess = useRefetchQueriesByKey(refetchQueriesByKey, queryClient, calculateKey);

	const queryResult = useMutation({
		mutationKey: key.queryKey,
		mutationFn: async (input: TInput) => {
			return disableGlobalErrorHandler<TData>(axios.postForm<TData>(key.url, input), ignoreGlobalErrorHandler);
		},
		onSuccess,
	});

	return { ...queryResult, data: queryResult.data?.data };
};

/**
 * Make POST request to the server with additional url input.
 *
 * @param input: it is the request body payload as an object{}
 * @param additionalVariables: there are variables as an object{foo:'',bar:''}
 * 						that replace the url variables from ENDPOINTS string .../{foo}/{bar}/...
 */
export const usePostWithUrlInput = <TData, TInput, TUrlVariables>(props: QueryProps) => {
	const { queryKey, ignoreGlobalErrorHandler } = props;
	const { axios } = useReactQueryWrappersProvider();

	const queryResult = useMutation({
		mutationFn: async ({ input, additionalVariables }: { input: TInput; additionalVariables: TUrlVariables }) => {
			let url = queryKey.url;

			for (const variable in additionalVariables) {
				url = url.replace(`{${variable}}`, additionalVariables[variable] as unknown as string);
			}

			return disableGlobalErrorHandler<TData>(axios.post<TData>(url, input), ignoreGlobalErrorHandler);
		},
	});

	return { ...queryResult, data: queryResult.data?.data };
};

/**
 * Make POST request to the server. This hook is used for simple POST requests, where we don't need to use the queryKey.
 */
export const useSimplePost = <TData, TInput>(url: string) => {
	return usePost<TData, TInput>({
		queryKey: { url },
	});
};

import { Button, ButtonGroup } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FormikProps } from 'formik';
import { useUpdateCollection } from 'pages/portfolio/hooks';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';

type UpdateGeneralSectionProps = {
	formiks: FormikProps<unknown>[];
};

export const UpdateCollectionButtons: FC<UpdateGeneralSectionProps> = ({ formiks }) => {
	const { handleSubmit, handleDiscard } = useUpdateCollection(formiks);

	const dirty = formiks.some((formik) => formik.dirty);

	if (!dirty) return <></>;

	const isSubmitting = formiks.some((formik) => formik.isSubmitting);

	return (
		<ButtonGroup marginLeft="auto">
			<Button data-testid="discard-changes-button" variant="outline" isDisabled={isSubmitting} onClick={() => void handleDiscard()}>
				<T _str={DICTIONARY.DISCARD} />
			</Button>
			<Button
				data-testid="save-collection-button"
				type="submit"
				isLoading={isSubmitting}
				loadingText={DICTIONARY.SAVING}
				onClick={() => void handleSubmit()}
			>
				<T _str={DICTIONARY.SAVE} />
			</Button>
		</ButtonGroup>
	);
};

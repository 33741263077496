import { t } from '@transifex/native';
import { Select } from 'chakra-react-select';
import { FormFieldProps } from 'entities/form-field-control/models';
import { FormFieldControl } from 'entities/form-field-control/ui';
import { Field } from 'formik';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { MultiLanguageControlFormProps } from '../models';

export const MultiLanguageControlForm: FC<MultiLanguageControlFormProps> = ({
	name,
	selectedLanguages,
	languages,
	helper,
	onLanguageChange,
	setFieldTouched,
	useBasicStyles = false,
}) => {
	return (
		<Field name={name}>
			{({ field, form }: FormFieldProps) => (
				<FormFieldControl
					form={form}
					field={field}
					name={name}
					label={DICTIONARY.LANGUAGES}
					helper={helper}
					isRequired
					xssDisable
					isInvalid={!!form.errors[name]}
				>
					<Select
						value={form.dirty ? undefined : selectedLanguages}
						onBlur={() => setFieldTouched(name)}
						onChange={(selectedOption) => {
							onLanguageChange(selectedOption);
							form.setFieldValue(field.name, selectedOption);
						}}
						placeholder={t(DICTIONARY.SELECT)}
						noOptionsMessage={() => t(DICTIONARY.NO_SEARCH_RESULT)}
						selectedOptionStyle="check"
						isClearable
						isInvalid={!!form.errors[name]}
						options={languages}
						inputId="multi-language-field"
						closeMenuOnSelect={false}
						isMulti
						useBasicStyles={useBasicStyles}
					/>
				</FormFieldControl>
			)}
		</Field>
	);
};

import { HTMLChakraProps, Skeleton } from '@chakra-ui/react';
import { FC } from 'react';

export const PageLoading: FC<HTMLChakraProps<'div'>> = (props) => {
	return <Skeleton height="12" borderRadius="md" marginY="2" {...props} />;
};

export const SiteStructureLoading: FC = () => {
	return (
		<>
			<PageLoading />
			<PageLoading marginLeft="6" />
			<PageLoading marginLeft="6" />
		</>
	);
};

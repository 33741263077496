import {
	Box,
	Button,
	Flex,
	Heading,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Stack,
	UseDisclosureReturn,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { CreateSiteStepper } from 'entities/create-site-stepper/ui';
import { useFeatures } from 'features/common/featureFlags/featureFlags';
import { useCreateSite } from 'features/create-site/hooks/use-create-site';
import { CreateSiteModalProps, SiteDetailsFormData } from 'features/create-site/models';
import { SiteDetailsForm } from 'features/create-site/ui/site-details-form';
import { TemplateSelector } from 'features/create-site/ui/template-selector';
import { FC, PropsWithChildren, useMemo } from 'react';
import { DICTIONARY } from 'shared/constants';

export const CreateSiteModal: FC<PropsWithChildren<CreateSiteModalProps & Pick<UseDisclosureReturn, 'isOpen' | 'onClose'>>> = ({
	collectionId,
	onClose,
	isOpen,
}) => {
	const {
		steps,
		currentStep,
		setCurrentStep,
		createSiteData,
		setCreateSiteData,
		isNextStepDisabled,
		onCloseComplete,
		isLastStep,
		isSiteCreationPending,
		setIsDetailsFormValid,
		closeWindow,
		handleCreateSite,
	} = useCreateSite(collectionId, onClose, isOpen);
	const { showManagedLanguages } = useFeatures();

	const siteDetailsFormData = useMemo(() => {
		let result: SiteDetailsFormData = {
			displayName: createSiteData?.displayName ?? '',
			description: createSiteData?.description ?? '',
			language: createSiteData?.language ?? null,
			collection: createSiteData?.collection ?? null,
		};

		if (showManagedLanguages) {
			result = { ...result, languages: createSiteData?.languages || [] };
		}

		return result;
	}, [
		createSiteData?.collection,
		createSiteData?.description,
		createSiteData?.displayName,
		createSiteData?.language,
		createSiteData?.languages,
		showManagedLanguages,
	]);

	return (
		<Modal isOpen={isOpen} onClose={closeWindow} size="full" onCloseComplete={onCloseComplete} scrollBehavior="inside">
			<ModalOverlay bgColor="white" />
			<ModalContent boxShadow="unset">
				<ModalHeader display="flex" justifyContent="space-between" paddingY="3" paddingRight="14">
					<Heading size="lg">
						<T _str={DICTIONARY.CREATE_WEBSITE} />
					</Heading>
					<Flex gap="2">
						{!!currentStep && (
							<Button
								variant="outline"
								data-testid="previous-step-button"
								isDisabled={isSiteCreationPending}
								onClick={() => void (currentStep !== 0 && setCurrentStep(currentStep - 1))}
							>
								<T _str={DICTIONARY.PREVIOUS_STEP} />
							</Button>
						)}
						{isLastStep ? (
							<Button
								variant="solid"
								isDisabled={isNextStepDisabled}
								isLoading={isSiteCreationPending}
								data-testid="create-site-button"
								onClick={() => void handleCreateSite()}
							>
								<T _str={DICTIONARY.CREATE_WEBSITE} />
							</Button>
						) : (
							<Button
								variant="outline"
								data-testid="next-step-button"
								isDisabled={isNextStepDisabled}
								onClick={() => void setCurrentStep(+currentStep + 1)}
							>
								<T _str={DICTIONARY.NEXT_STEP} />
							</Button>
						)}
					</Flex>
					<ModalCloseButton top="4" />
				</ModalHeader>
				<ModalBody>
					<Stack alignItems="center">
						<CreateSiteStepper currentStep={currentStep} width="lg" steps={steps} paddingY="2" />
						<Box p="8">
							{currentStep === 0 && (
								<TemplateSelector
									selectedTemplateId={createSiteData?.templateId || ''}
									onSelectedTemplateChange={(templateId) => setCreateSiteData({ ...createSiteData, templateId })}
								/>
							)}
							{currentStep === 1 && (
								<SiteDetailsForm
									width="lg"
									onFormChange={setIsDetailsFormValid}
									collectionId={collectionId ?? ''}
									onSiteDetailsFormDataChange={(data) => setCreateSiteData({ ...createSiteData, ...data })}
									siteDetailsFormData={siteDetailsFormData}
								/>
							)}
						</Box>
					</Stack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

import { HostWithPoS } from 'features/point-of-sale/models';
import { useMemo } from 'react';
import { POS_FIELD_ENABLE_ANALYTICS } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { SiteResponse } from 'shared/models';
import { useSimpleGet } from 'shared/react-query-wrappers/hooks';

export const useGetHostsWithPoS = () => {
	const { data, isLoading } = useSimpleGet<SiteResponse[]>(ENDPOINTS.SITES);

	const hosts = useMemo<HostWithPoS[]>(() => {
		if (!data) {
			return [];
		}

		const hostsWithPoSs = data?.flatMap<HostWithPoS>((site) => {
			return site.hosts.flatMap((host) => {
				return host.settings?.[POS_FIELD_ENABLE_ANALYTICS] === 'false'
					? []
					: [
							{
								id: host.id,
								name: host.name,
								posIdentifiers: Object.values(host.analyticsIdentifiers ?? {}),
								siteId: site.id,
							},
					  ];
			});
		});

		return hostsWithPoSs ?? [];
	}, [data]);

	return {
		hosts,
		isLoading,
	};
};

import { PageViewModel } from 'shared/models';
import { RestoreCache } from 'widgets/site-structure/utils/optimistic-update';

export type DragNDropData = {
	movingPage: PageViewModel;
	restoreCacheData: RestoreCache;
};

export const serializeDrag = (data: DragNDropData) => {
	return JSON.stringify(data);
};

export const deserializeDrag = (data: string) => {
	return JSON.parse(data) as DragNDropData;
};

import { format } from 'date-fns';
import { PageBodyContent, PageBodyContentSection, SectionDataRecord } from 'entities/page-layout/ui';
import { useFeatures } from 'features/common/featureFlags/featureFlags';
import { RemoveSiteButtonEA } from 'features/remove-site/ui';
import { SitePageOutlet } from 'pages/site/models';
import { useOutletContext } from 'react-router';
import { DATE_FORMAT, DICTIONARY, EMPTY_VALUE } from 'shared/constants';
import { GeneralSectionForm, SiteLanguageForm } from 'widgets/site-settings-update-form/ui';
import { SettingsSectionForm } from 'widgets/site-settings-update-form/ui/settings-section-form';

/**
 * @deprecated Update is blocked for EA
 */
export const SiteGeneralPage = () => {
	const { site, languages, locationOrigin } = useOutletContext<SitePageOutlet>();
	const createdOn = site?.created ? format(new Date(site.created), DATE_FORMAT) : EMPTY_VALUE;
	const { showSitesGrouping } = useFeatures();

	if (showSitesGrouping) {
		return null;
	}

	return (
		<PageBodyContent>
			<PageBodyContentSection title={DICTIONARY.GENERAL}>
				{site && languages && <GeneralSectionForm {...site} xmAppsLanguages={languages} />}
			</PageBodyContentSection>
			<PageBodyContentSection title={DICTIONARY.LANGUAGES}>
				{site && languages && <SiteLanguageForm languages={languages} site={site} />}
			</PageBodyContentSection>
			<PageBodyContentSection title={DICTIONARY.SETTINGS}>{site && <SettingsSectionForm {...site} />}</PageBodyContentSection>
			<PageBodyContentSection title={DICTIONARY.DANGER_ZONE}>
				{site && (
					<RemoveSiteButtonEA
						id={site?.id}
						name={site?.displayName || site?.name}
						collectionId={site?.collectionId}
						hasMultiple={Boolean(Number(site?.hosts?.length) > 1)}
						locationOrigin={locationOrigin}
					/>
				)}
			</PageBodyContentSection>
			<PageBodyContentSection title={DICTIONARY.DETAILS}>
				<SectionDataRecord header={DICTIONARY.CREATED_BY} value={site?.createdBy || EMPTY_VALUE} />
				<SectionDataRecord header={DICTIONARY.CREATED_ON} value={createdOn} />
				<SectionDataRecord header={DICTIONARY.COLLECTION} value={site?.collectionName || EMPTY_VALUE} />
				<SectionDataRecord header={DICTIONARY.TEMPLATE} value={site?.siteTemplateInfo?.name || EMPTY_VALUE} />
				<SectionDataRecord
					header={DICTIONARY.ITEM_PATH}
					// eslint-disable-next-line prettier/prettier
					value={(site?.settings.rootPath as string) || site?.itemPath || EMPTY_VALUE}
				/>
			</PageBodyContentSection>
		</PageBodyContent>
	);
};

import { Skeleton } from '@chakra-ui/react';
import { SkeletonRepeaterProps } from 'entities/skeleton-repeater/models';
import { FC } from 'react';

export const SkeletonRepeater: FC<SkeletonRepeaterProps> = ({ count = 2, ...props }) => {
	return (
		<>
			{Array.from({ length: count }, (x, index) => (
				<Skeleton key={`${count}-${index}`} {...props} />
			))}
		</>
	);
};

import { Card, CardBody, Flex, Text } from '@chakra-ui/react';
import { Player } from '@lottiefiles/react-lottie-player';
import { T } from '@transifex/react';
import progress from 'entities/site-card/resources/site-creation-progress.json';
import { FC } from 'react';
import { DICTIONARY, SITE_CARD_SIZE } from 'shared/constants';

const iconSize = '48px';

type SiteCreationProgressCardProps = {
	siteName: string;
};

export const SiteCreationProgressCard: FC<SiteCreationProgressCardProps> = ({ siteName }) => (
	<Card width={SITE_CARD_SIZE.WIDTH} height={SITE_CARD_SIZE.HEIGHT}>
		<CardBody display="flex" flexDirection="column" gap="4" justifyContent="center">
			<Flex justify="center">
				<Player autoplay loop src={progress} style={{ height: iconSize, width: iconSize }} />
			</Flex>
			<Flex direction="column" textAlign="center">
				<Text fontWeight="semibold" paddingBottom="2">
					<T _str={DICTIONARY.SITE_CREATION_HEADER} siteName={siteName} />
				</Text>
				<Text align="center" variant="small">
					<T _str={DICTIONARY.THIS_CAN_TAKE_UP_TO_2_MINUTES} />
				</Text>
				<Text align="center" variant="small">
					<T _str={DICTIONARY.YOU_CAN_BROWSE_AWAY} />
				</Text>
			</Flex>
		</CardBody>
	</Card>
);

import {
	Container,
	Flex,
	IconButton,
	Modal,
	ModalBody,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
} from '@chakra-ui/react';
import { mdiArrowLeft } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { useSaveNavigate } from 'features/common/utils/navigationUtils';
import { useTranslate } from 'features/common/utils/translateUtils';
import { useNonSxaSiteCheck } from 'features/siteSettings/utils/useNonSxaSiteCheck';
import { FC, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { DICTIONARY } from 'shared/constants';
import { useSiteExistingCheck } from '../utils/useSiteExistingCheck';
import { useSiteSettingsTabindex } from '../utils/useSiteSettingsTabindex';
import { DeveloperSettings } from './DeveloperSettings';
import { GeneralTab } from './GeneralTab';
import { PreventDiscardChanges } from './GeneralTab/PreventDiscradChanges';
import { SiteIdentifiersSettings } from './SiteIdentifiersSettings';

export const SiteSettingsLayout: FC = () => {
	const navigate = useSaveNavigate();
	const t = useTranslate();
	const navigateBack = useCallback(() => navigate('/sites'), [navigate]);
	const { sitename } = useParams<'sitename'>();

	useSiteExistingCheck(navigateBack);

	const [currentTabIndex, setCurrentTabIndex] = useSiteSettingsTabindex();
	const isNonSxaSite = useNonSxaSiteCheck();

	return (
		<>
			<PreventDiscardChanges>
				{({ tryLeave }) => (
					<Modal isOpen={true} onClose={() => tryLeave(navigateBack)} size="full" orientation="horizontal" isCentered={false}>
						<ModalOverlay>
							<ModalContent height="fit-content" background="chakra-subtle-bg" borderRadius="0">
								<ModalHeader fontSize="3xl">
									<Flex align="center">
										<IconButton
											aria-label={t(DICTIONARY.CLOSE_CREATE_SITE_DIALOG)}
											size="md"
											mr="3"
											icon={<Icon path={mdiArrowLeft} />}
											variant="ghost"
											onClick={() => tryLeave(navigateBack)}
										></IconButton>
										<Text as="span">
											<T _str={DICTIONARY.SETTINGS_FOR_SITE} siteName={sitename} />
										</Text>
									</Flex>
								</ModalHeader>
								<ModalBody>
									<Container maxWidth="container.xl">
										<Tabs
											lazyBehavior="unmount"
											index={currentTabIndex}
											data-testid="siteSettingsTabs"
											onChange={(index) => {
												if (currentTabIndex === 0) {
													tryLeave(() => setCurrentTabIndex(index));
												} else {
													setCurrentTabIndex(index);
												}
											}}
										>
											<Flex flexGrow={1} flexDirection="column" height="full">
												<TabList>
													<Tab>
														<T _str={DICTIONARY.GENERAL} />
													</Tab>
													<Tab hidden={isNonSxaSite}>
														<T _str={DICTIONARY.ANALYTICS_IDENTIFIERS} />
													</Tab>
													<Tab>
														<T _str={DICTIONARY.DEVELOPER_SETTINGS} />
													</Tab>
												</TabList>
												<TabPanels>
													<TabPanel>
														<GeneralTab />
													</TabPanel>
													<TabPanel hidden={isNonSxaSite}>
														<SiteIdentifiersSettings />
													</TabPanel>
													<TabPanel>
														<DeveloperSettings />
													</TabPanel>
												</TabPanels>
											</Flex>
										</Tabs>
									</Container>
								</ModalBody>
							</ModalContent>
						</ModalOverlay>
					</Modal>
				)}
			</PreventDiscardChanges>
		</>
	);
};

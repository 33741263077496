import { useSaveNavigate } from 'features/common/utils/navigationUtils';
import { FC, useMemo } from 'react';
import { DICTIONARY, KNOWLEDGE_BASE_LINK, SC_ICONS_SRC } from 'shared/constants';
import { GenericErrorTemplateLink } from 'shared/generic-error/models';
import { GenericErrorTemplate } from 'shared/generic-error/ui';

export const NotFound: FC = () => {
	const navigate = useSaveNavigate();

	const links = useMemo<GenericErrorTemplateLink[]>(() => {
		return [
			{ label: DICTIONARY.GO_TO_HOMEPAGE, onClick: () => navigate('/') },
			{ label: DICTIONARY.VISIT_KNOWLEDGE_BASE, onClick: () => window.open(KNOWLEDGE_BASE_LINK, '_blank') },
		];
	}, [navigate]);

	return (
		<GenericErrorTemplate
			title={DICTIONARY.PAGE_NOT_FOUND}
			imageSrc={SC_ICONS_SRC.PAGE_NOT_FOUND}
			subtext={DICTIONARY.PAGE_NOT_FOUND_SUBTEXT}
			text={DICTIONARY.PAGE_NOT_FOUND_TEXT}
			links={links}
		/>
	);
};

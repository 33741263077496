import { AxiosResponse } from 'axios';
import config from 'features/common/config/config';
import { useCdpTenant } from 'features/common/tenants/CdpTenantProvider';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { RefetchOptions, RefetchQueryFilters, QueryObserverResult } from '@tanstack/react-query';
import { CreatePosInput, PosMapping, PosPayload } from './types';
import { useGet, usePost } from 'shared/react-query-wrappers/hooks';

type RefechCallback = (
	options?: (RefetchOptions & RefetchQueryFilters) | undefined
) => Promise<QueryObserverResult<AxiosResponse<PosPayload[]>, unknown>>;

export const useGetAllPOS = (): { posList: PosPayload[]; isLoading: boolean; refetch: RefechCallback } => {
	const { state, tenant: cdpTenant } = useCdpTenant();
	const getPostUrl = cdpTenant ? config.cdp.get_all_pos.url(cdpTenant?.apiUrl) : '';

	const { data, isLoading, refetch } = useGet<PosPayload[]>({ queryKey: { url: getPostUrl }, enabled: state == 'resolved' });

	const list = useMemo(() => {
		return _.sortBy(data ?? [], (pos) => pos.name.toLowerCase());
	}, [data]);

	return { posList: list, isLoading, refetch };
};

type CreatePosOutput = (name: string, language: string, market: string, brand: string, timeout: number) => Promise<PosMapping[]>;

export const useCreatePOS = (): CreatePosOutput => {
	const { state, tenant: cdpTenant } = useCdpTenant();
	const { posList } = useGetAllPOS();
	const createPosUrl = cdpTenant ? config.cdp.create_pos.url(cdpTenant?.apiUrl) : '';

	const { mutateAsync } = usePost<PosPayload, CreatePosInput>({ queryKey: { url: createPosUrl } });

	return useCallback(
		async (name: string, language: string, market: string, brand: string, timeout = 30) => {
			if (state != 'resolved') {
				return [];
			}

			try {
				if (posList.findIndex((x) => x.name.toLowerCase() == name.toLowerCase()) === -1) {
					const newPos = { name: name, language: language, brand: brand, market: market, timeout: timeout };

					await mutateAsync(newPos);
					posList.push(newPos);
				}

				return [{ language: '*', name: name }];
			} catch {
				// ignore
			}

			return [];
		},
		[state, mutateAsync, posList]
	);
};

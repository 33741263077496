export const DICTIONARY = {
	PROJECT_NAME: 'Project name',
	ENVIRONMENT_NAME: 'Environment name',
	SITE_LANGUAGE: 'Site language',
	IDENTIFIER_NAME: 'Identifier name',
	LANGUAGE: 'Language',
	LANGUAGES: 'Languages',
	MARKET: 'Market',
	BRAND: 'Brand',
	TIMEOUT_IN_MINUTES: 'Timeout (in minutes)',
	TIMEOUT: 'Timeout',
	FALLBACK: 'Fallback',
	NO_IDENTIFIER: 'No identifier',
	FAILED: 'Failed',
	ASSIGN_IDENTIFIER_TO_SITE: 'Assign identifier to site',
	UNASSIGN: 'Unassign',
	CREATE_AND_ASSIGN_ANALYTICS_IDENTIFIER: 'Create and assign analytics identifier',
	ANALYTICS_IDENTIFIERS: 'Analytics identifiers',
	ANALYTICS_IDENTIFIER: 'Analytics identifier',
	SELECT_ANALYTICS_IDENTIFIER: 'Select analytics identifier',
	IDENTIFIER_DETAILS: 'Identifier details',
	CREATE: 'Create',
	SAVE: 'Save',
	EDIT: 'Edit',
	CANCEL: 'Cancel',
	LOADING: 'Loading...',
	SAVING: 'Saving...',
	CREATING_TEXT: 'Creating...',
	DISABLE: 'Disable',
	THIS: 'this',
	CHANGE_NOT_SAVED: 'Changes not saved',
	UNABLE_TO_SAVE: 'Unable to save',
	THE_FOLLOWING_FIELDS_CONTAIN_ERRORS: 'The following fields contain errors:',
	DISCARD_CHANGES: 'Discard changes',
	DISCARD: 'Discard',
	KEEP_EDITING: 'Keep editing',
	DISCARD_CHANGES_AND_LEAVE: 'Discard unsaved changes and leave page?',
	ANALYTICS_IDENTIFIER_TOOLTIP:
		"Analytics identifier enables the organization's websites to track useful analytics on how your audiences engage with your site pages.",
	WEBSITE_NAME_SHOULD_BE_UNIQUE: 'Website name should be unique',
	SITE_RENAME_SUCCESS: 'Site renamed',
	SITE_RENAME_FAILURE: 'Renaming site failed. Try again.',
	RENAME_SITE_ERROR: ['Something went wrong.', 'failed to rename'],
	DUPLICATE_SITE_ERROR: ['Something went wrong.', 'failed to duplicate'],
	SEARCH_BY_SITE_NAME: 'Search by site name...',
	SYNCHRONIZATION_FAILED: 'Synchronization failed',
	SITECORE_PERSONALIZE_SYNCHRONIZATION_ERROR: 'An error occurred when synchronizing to Sitecore personalize. Try again later.',
	RESTORE_PERSONALIZE_AND_ANALYZE: 'Restore personalize and analyze',
	GO_TO_DASHBOARD_SETTINGS: 'Go to Dashboard Settings',
	PERSONALIZE_AND_ANALYZE_CONNECTION_IS_BROKEN:
		'The connection to personalize and analyze is broken for some of your websites. You can restore this connection from the Dashboard Settings tab.',
	NETWORK_ERROR: 'Network error',
	FAILED_TO_AUTHORIZE: 'Failed to authorize',
	LOGIN_AGAIN: 'Log in again',
	PAGE_ACCESS_RESTRICTED: "You don't have permission to access this page",
	ERROR_403: 'Error 403',
	ACCESS_DENIED: {
		DEFAULT: 'Access denied',
		SITE_RENAME: "You don't have permission to rename sites",
		SITE_DUPLICATE: "You don't have permission to duplicate sites",
		PAGE_DUPLICATE: "You don't have permission to duplicate this page",
		PAGE_RENAME: "You don't have permission to rename this page",
	},
	ACCESS_DENIED_DEFINITIONS: {
		SITE_RENAME: "You don't have permission to rename site definitions",
		SITE_DUPLICATE: "You don't have permission to duplicate site definitions",
	},
	GO_TO_HOMEPAGE: 'Go to homepage',
	GO_TO_XM_CLOUD_DEPLOY_APP: 'Go to XM Cloud Deploy App',
	UPDATE_YOUR_XM_CLOUD_BASE_IMAGE: 'Please update your XM Cloud base image',
	UPDATE_REQUIRED_MESSAGE:
		'We introduced new functionality in the Dashboard that requires a newer version of the XM Cloud base image. Please perform a Build & Deploy for your environment through the XM Cloud Deploy app to use the new version of the Dashboard.',
	COMING_SOON: 'coming soon',
	SETTINGS_FOR_SITE: 'Settings: {siteName}',
	GENERAL: 'General',
	DEVELOPER_SETTINGS: 'Developer settings',
	INVALID_ACCESS_TOKEN: 'Invalid access token. Contact the organization admin or {sitecoreSupport}',
	SETTINGS: 'Settings',
	TOOLS: 'Tools',
	SITES: 'Sites',
	NO_TENANT_CHOSEN: 'No Tenant selected.',
	NO_TENANT_CHOSEN_DESCRIPTION: 'An XM Cloud Instance Dashboard cannot display here because no Tenant is selected.',
	SELECT_ANOTHER_TENANT: 'Use the burger icon on the top left of the menu to select a tenant.',
	DESIGN: 'Design',
	DELETE: 'Delete',
	OPEN_IN_EXPLORER: 'Open in Explorer',
	OPEN_IN_PAGES: 'Edit page',
	EDIT_SITE: 'Edit site',
	OPEN_IN_PAGES_MODAL: 'Click a site definition to open it in Pages',
	EDIT_CONTENT_IN_EXPLORER: 'Manage content in Explorer',
	OPTIONS: 'Options',
	ACTION_MENU_PREVIEW_SITE: 'Preview site',
	MORE: 'More',
	POC_LAYOUT_DESCRIPTION:
		"Analytics identifier is added to each of your organization's websites to track useful analytics on how your audiences engage with your site pages.",
	CREATE_NEW: 'Create new',
	CREATE_NEW_ANALYTICS_IDENTIFIER: 'Create new analytics identifier',
	NEW_SITE: 'New site',
	NO_SITES_FOUND: 'No sites found',
	RESET_SEARCH: 'Reset search',
	NO_SITES_MATCH_FILTER: 'Try a different search query or remove filters',
	RESET_FILTER: 'Reset filter',
	NO_SITES_IN_THIS_COLLECTION: 'No sites in this collection',
	GENERATE_API_KEY: 'Generate API Key',
	YOU_DO_NOT_HAVE_ACCESS_RIGHTS: "You don't have right for that action, please contact your admin",
	DETAILS: 'Details',
	ROOT_ITEM_ID: 'Root item ID',
	ENVIRONMENT_VARIABLES: 'Environment variables',
	DEVELOPER_SETTINGS_ALERT: {
		MESSAGE:
			'If you are using JSS v. 21.5 or earlier, use the {olderLink} on this page to configure your environment. If you are using JSS 21.6.0 or later, use the {newerLink} available in the Deploy app, in Projects > Environments > Developer settings',
		ENVIRONMENT_VARIABLES: 'environment variables',
	},
	NO_APP_NAME_MESSAGE:
		'We introduced new functionality in the Dashboard that requires a newer version of the XM Cloud base image. You can get JSS_APP_NAME using Content Editor.',
	SITE_NAME: 'Site name',
	NAME_VALID_CHARACTERS_MESSAGE:
		'This name will appear in site listings internally. Characters allowed: A-Z, a-z, 0-9, -, (). Spaces are also allowed.',
	SITE_NAME_XM_CLOUD_INFO_MESSAGE: 'The site name within XM Cloud',
	RENAME_SITE: 'Rename "{siteName}"',
	RENAME_PAGE: 'Rename "{name}"',
	RENAME: 'Rename',
	DUPLICATE: 'Duplicate',
	DUPLICATE_SITE: 'Duplicate {name}',
	DUPLICATE_SITE_HEADER: 'Duplicate site "{siteName}"',
	DUPLICATE_SITE_TOOLTIP: 'Duplicate site',
	DUPLICATE_SITE_INFO_MESSAGE: 'Duplicating site. When it`s ready, you can see it in the list of sites.',
	DUPLICATE_SITE_ERROR_MESSAGE: 'Duplicating site failed. Try again.',
	TILES: 'Tiles',
	COLUMNS: 'Columns',
	CREATE_WEBSITE: 'Create site',
	NO_SITES_CREATED_YET: 'No sites created yet',
	BE_THE_FIRST_TO_CREATE_ONE: 'Be the first to create one',
	YOU_CAN_BROWSE_AWAY: 'You can browse away.',
	THIS_CAN_TAKE_UP_TO_2_MINUTES: 'This can take up to 2 minutes.',
	SITE_IS_BUILDING: 'Site "{name}" is building.',
	COLLECTION_WITH_COLON: 'Collection:',
	ACTIVATE_ANALYTICS_FOR_SITE_TOOLTIP: 'To activate analytics for your sites, go to the settings of your site and add analytics identifier',
	REMOVE_SITE_CONFIRMATION_MeSSAGE: 'This site and its pages will be deleted permanently. This action can not be undone.',
	REMOVE_SITE_CONFIRMATION_ACTION_TEXT: ['Confirm by typing', 'below'],
	FIRST_TAB_CHOOSE_TEMPLATE: '1: Select template',
	SECOND_TAB_CONFIGURE: '2: Configure',
	SITE_NAME_TOOLTIP: 'This name will appear in site listings internally',
	SITE_IS_PART_OF_SITE_COLLECTION: 'This site will be part of site collection',
	PREVIOUS_STEP: 'Previous step',
	NAME_YOUR_IDENTIFIER: 'Name your identifier',
	ENTER_IDENTIFIER_NAME: 'Enter a name for the identifier, such as myretailsite.co.uk.',
	SELECT_LANGUAGE_FOR_IDENTIFIER: 'Select the language for the identifier, such as Fr.',
	ENTER_MARKET_FOR_IDENTIFIER:
		'Enter the market for the identifier. This might be the region or country associated with the identifier, such as France.',
	ENTER_BRAND_FOR_IDENTIFIER:
		'Enter the brand for the identifier. This is the marketing brand for which the identifier is under, such as Acme French Scarves.',
	SELECT_NUMBER_OF_MINUTES: 'Select the number of minutes before the browser session times out as a result of inactivity, such as 20.',
	ANALYTICS_IDENTIFIER_ARE_RESTORED: 'Analytics identifier(s) are restored',
	PERSONALIZE_AND_ANALYZE_CONNECTION_RESTORED:
		'The connection to personalize and analyze has been restored for the websites that have analytics identifier assigned to them.',
	RESTORE: 'Restore',
	IF_THE_BUTTON_IS_DISABLED_CONTACT_SITECORE_SUPPORT: 'If the button is disabled, please contact Sitecore support.',
	CLICK_TO_RESTORE_CONNECTION_TO_PERSONALIZE_AND_ANALYZE:
		'If your website has been assigned analytics identifier, but has lost its connection to the personalize and analyze features, you can click here to restore it.',
	IDENTIFIER_IS_BROKEN_AND_FAILED_TO_RUN: 'This identifier is broken and failed to run.',
	COPIED: 'Copied',
	SYNCHRONIZING: 'Synchronizing',
	CLOSE_CREATE_SITE_DIALOG: 'Close create site dialog',
	CLOSE_ALERT: 'Close alert',
	CARDS_VIEW: 'Cards view',
	MILLER_COLUMN_VIEW: 'Miller column view',
	GENERIC_ERROR: {
		TITLE: 'Something went wrong',
		DESCRIPTION: 'Reach out to your Sitecore Cloud admin or contact {link}.',
		LINK_TEXT: 'Support',
	},
	TYPE_COLON: 'Type:',
	TYPE_FILTER: {
		ALL: 'All',
		SXA_MANAGED: 'SXA managed',
		NON_SXA_MANAGED: 'Non-SXA managed',
	},
	NOT_AVAILABLE: 'N/A',
	NEXT_POS_NONSXA_SITE_TOOLTIP: '',
	SITE_COLLECTION: 'Site collection',
	ITEM_NAME: 'Item name',
	ITEM_PATH: 'Item path',
	TEMPLATE: 'Template',
	CREATED_BY: 'Created by',
	SITE_TYPE: 'Site type',
	DISABLED_TYPE_FILTER_TOOLTIP: 'A collection is selected. Non-SXA managed sites do not support collections.',
	DISABLED_SITE_COLLECTION_TOOLTIP: 'Only SXA managed sites have collections',
	ASSIGN_WAS_UNSUCCESSFUL: 'Assign was unsuccessful.',
	NO_CONTENT: 'No content',
	SELECT: 'Select',
	GO_BACK: 'Go back',
	OR: 'or',
	SELECT_IDENTIFIER: 'Select identifier...',
	ANALYTICS_IDENTIFIER_HELPER: 'Analytics identifier helps you track analytics on how your audiences engage with your site pages.',
	OPTIONAL: 'Optional',
	MANAGE_ACCOUNT: 'Your account',
	LOG_OUT: 'Log out',
	ALL_COLLECTIONS: 'All collections',
	WELCOME_TO_XM_CLOUD: 'Welcome to XM Cloud',
	WELCOME_TO_XM_CLOUD_TEXT: 'Start by creating your first site',
	WELCOME_TO_XM_CLOUD_RESTRICTION_TEXT:
		"You don't have permission to create a site in this environment. Select another environment, or contact your XM Cloud administrator to request permission.",
	SITE_CREATION_HEADER: 'Building "{siteName}"',
	GO_TO_COLLECTION: 'Go to collection',
	SEE_MORE: 'See more',
	SEE_LESS: 'See less',
	EMPTY_PORTFOLIO_HEADING: `This collection doesn't have any sites`,
	DESIGN_IN_PAGES: 'Design in Pages',
	SITE_CARD_COUNT: '{count} {count, plural, one {site} other {sites}}',
	COLLECTION: 'Collection',
	SITE: 'Site',
	SITES_HEADER_COUNT_COLLECTIONS_AND_SITES:
		'{collectionsCount} {collectionsCount, plural, one {collection} other {collections}}, {sitesCount} {sitesCount, plural, one {site} other {sites}}',
	PUBLISHED: 'Published',
	UNPUBLISHED: 'Unpublished',
	DANGER_ZONE: 'Danger zone',
	CREATED_ON: 'Created on',
	CLOSE: 'Close',
	DELETE_COLLECTION: 'Delete collection',
	DELETE_COLLECTION_MESSAGE: 'contains {count} sites. They will also be deleted. This action cannot be undone.',
	DELETE_COLLECTION_CONFIRMATION_MESSAGE: 'Confirm by typing "{displayName}" below',
	DELETE_COLLECTION_BUTTON_TEXT: 'Delete collection and {count} sites',
	DELETING: 'Deleting...',
	COLLECTION_DELETED: 'Collection deleted',
	DELETE_SITE: 'Delete site',
	DELETE_SITE_MESSAGE: 'Deleting {name} will remove it from the list of sites.',
	DELETE_SITE_MAIN_TEXT: 'You are about to delete {sitename} from {env} environment. This action cannot be undone.',
	DELETE_SITE_BODY_TEXT: {
		TITLE: 'I understand that:',
		DELETE_EVERYTHING: 'All the pages, settings, media files, data sources, presentation elements, and dictionaries of this site will be deleted',
		DELETE_DEFINITION: 'Everyone in the current environment will lose access to this site',
	},
	DELETE_SITE_LIST_TEXT: {
		TITLE: 'After you delete this site:',
		DELETE_EVERYTHING:
			'All the pages, settings, media files, data sources, presentation elements, and dictionaries of {siteName} will be deleted',
		DELETE_DEFINITION: 'The site definitions will be deleted: {siteDefNames}',
		DELETE_SHARED_SITE:
			'Other sites in {collectionName} collection might be affected. {siteName} is a shared site, so deleting it could remove content from those sites.',
	},
	DELETE_SITE_ALERT_TITLE: 'Consider all environments',
	DELETE_SITE_ALERT_DESCRIPTION: 'For a complete deletion, check if this site exists in other environments and remove it from those as well.',
	DELETE_SITE_CONFIRMATION_MESSAGE: 'Confirm by typing “{name}” below',
	SITE_DELETED: 'Site deleted',
	SITE_DELETING: 'Deleting "{sitename}". You can browse away.',
	SITE_DELETION_FAILED: 'Site deletion failed',
	CREATE_COLLECTION: 'Create collection',
	COLLECTION_IS_REQUIRED: 'Collection is required',
	COLLECTION_UPDATED: 'Collection changes saved',
	COLLECTION_UPDATED_FAILED: 'Updating collection failed. Try again.',
	NAME: 'Name',
	DESCRIPTION: 'Description',
	SITE_CREATION_FAILED: 'Site creation failed. Try again.',
	SITE_TRAILING_SPACES: 'The site name cannot include leading or trailing spaces',
	SITE_NAME_INVALID_CHARACTERS: 'Invalid characters. Allowed are A–Z, a–z, 0–9, hyphen (-), underscore (_).',
	SITE_NAME_MAX_LENGTH: 'Site name cannot be longer than 50 characters.',
	SITE_DESCRIPTION_MAX_LENGTH: 'Description must be 100 characters or less.',
	SITE_EXISTS_RESPONSE_ERROR: 'is already defined on this level.',
	SITE_EXISTS_ERROR: 'A site with this name already exists',
	SITE_EXISTS_IN_COLLECTION_ERROR: 'A site with this name already exists in this collection',
	SITE_DEFINITION_EXISTS_ERROR: 'A site definition with this name already exists',
	PAGE_CREATE_TOOLTIP: 'Create page',
	PAGE_TEMPLATE: 'Page template',
	CREATE_CHILD_PAGE: 'Create child page',
	CREATE_PAGE_BEFORE: 'Create page before',
	CREATE_PAGE_AFTER: 'Create page after',
	PAGE_NAME_HELPER: 'Used to build the URL of the item',
	PAGE_DISPLAY_NAME: 'Display name (English)',
	PAGE_DISPLAY_HELPER: 'The page name within XM Cloud',
	PAGE_DISPLAY_POPOVER:
		'The item display name can be different per language. By default all pages in site structure are created and displayed in English (en).',
	PAGE_CREATION_FAILED: 'Page creation failed',
	PAGE_ITEM_EXISTS_ERROR: 'A page with this name already exists at this level',
	SITES_IN_COLLECTION: '{count} {count, plural, one {site} other {sites}} in this collection',
	SITE_ENVIRONMENT: '{envName} - {envType}',
	SITE_TEMPLATE: 'Site template',
	SITE_DETAILS: 'Site details',
	BLANK: 'Blank',
	PREVIEW_TEMPLATE: 'Preview template',
	LAST_UPDATED: 'Last updated',
	NEXT_STEP: 'Next step',
	SELECT_TEMPLATE_TO_SEE_MORE_DETAILS: 'Select a template to see more details',
	COLLECTION_HINT: 'Organize related sites in a collection to share content, templates, layout, and settings',
	DEFAULT_LANGUAGE: 'Default language',
	LANGUAGE_EMBEDDING: 'Language embedding',
	LANGUAGE_FALLBACK: 'Language fallback',
	LANGUAGE_FALLBACK_FOR_FIELDS: 'Language fallback for fields',
	LEARN_MORE: 'Learn more',
	ABOUT_LANGUAGE_FALLBACK: 'about language fallback',
	LANGUAGE_REQUIRED: 'Language is required',
	SITE_UPDATED_MESSAGE: 'All changes saved',
	ANALYTICS_IDENTIFIERS_SETTINGS_TITLE:
		'Analytics identifiers are used for tracking, personalization, and analytics per site definition.This list shows all the analytics identifiers for the {organizationName} organization, but only details of assigned site definitions in the current environment.',
	ASSIGNED_TO_SITE_DEFINITION: 'Assigned to site definition in this environment',
	ASSIGNED_TO_SITE_DEFINITION_TABLE: 'Assigned to site definition',
	SEARCH: 'Search',
	CLEAR_SEARCH: 'Clear search',
	SEARCH_FOR_ANALYTICS_IDENTIFIER_OR_SITE_DEFINITION_NAME: 'Search for analytics identifier or site definition name',
	PREVIOUS: 'Previous',
	NEXT: 'Next',
	POS_TABLE_HINTS: {
		IDENTIFIER: {
			TITLE: 'What is an analytics identifier?',
			DESCRIPTION:
				'An analytic identifier enables the collection of data on user traffic and interactions with the website pages. It also lets you access the personalize feature available in Pages.',
		},
		TIMEOUT: {
			TITLE: 'What is a timeout?',
			DESCRIPTION: 'The time that passes before the browser session times out.',
		},
		ASSIGNED_TO_SITE_DEFINITION: {
			TITLE: 'Assigned to site definition',
			DESCRIPTION:
				'You can only see names of site definitions from the current environment. An empty value might mean that this identifier is assigned to site definitions in a different environment within the organization.',
		},
	},
	NO_SEARCH_RESULT: 'No search results',
	TRY_DIFFERENT_SEARCH_OR_FILTER: 'Try a different search query',
	DELETE_ANALYTICS_IDENTIFIER: 'Delete analytics identifier',
	DELETE_ANALYTICS_IDENTIFIER_ALERT: 'Before deleting check if this analytics identifier is used in other environments',
	DELETE_ANALYTICS_IDENTIFIER_CONFIRMATION:
		"{identifier} is assigned to {numberOfHosts} {numberOfHosts, plural, one {site definition} other {site definitions}} in this environment. After deletion you will no longer receive analytics data, the site and page insights will become outdated after 30 days, and the personalize functionality won't be available.",
	ANALYTICS_IDENTIFIER_DELETE: 'Analytics identifier deleted',
	SOMETHING_WENT_WRONG: 'Something went wrong. Try again.',
	CREATING_COLLECTION: 'Creating "{collection}" collection',
	CREATING_COLLECTION_AND_SITE: 'Creating "{collection}" collection and "{site}" site',
	ANALYTICS_AND_PERSONALIZATION: 'Analytics and personalization',
	ANALYTICS_AND_PERSONALIZATION_DESCRIPTION:
		'Get useful analytics, track how your audiences interact with your pages, and use the personalize functionality available in XM Cloud Pages.',
	ANALYTICS_AND_PERSONALIZATION_DESCRIPTION_POS_ENABLE:
		'We recommend assigning one analytics identifier per language to view analytics and personalize pages for individual languages.',
	COLLAPSE: 'Collapse',
	EXPAND: 'Expand',
	HOMEPAGE: 'Homepage',
	HOMEPAGE_HELPER: 'Select a homepage for this site definition',
	INCLUDED_IN_THIS_TEMPLATE: 'Included in this template',
	HOST_NAME_REQUIRED: 'Hostname is required',
	HOST_NAME_INVALID_URL_TYPE: 'Enter a valid URI',
	HOST_NAME_ALREADY_USED: 'This hostname is used by another site definition',
	HOST_NAME: 'Hostname',
	HOST_NAME_DESCRIPTION: 'URI format',
	TARGET_HOST_NAME: 'Target hostname',
	LINKABLE_SITE: 'Linkable site',
	LINKABLE_SITE_DESCRIPTION: 'Enable linking from other websites to the current website',
	MISSING_POS_MESSAGE: 'Non-existing analytics identifiers were assigned to these site definitions:',
	MISSING_POS_CREATE_POS_MESSAGE:
		'Create the missing analytics identifiers, or navigate to the settings of the site definitions to manually assign analytics identifiers.',
	CREATE_MISSING_POS_IDENTIFIERS: 'Create missing analytics identifiers',
	POS_CREATED: 'Analytics identifiers created',
	POS_CREATING: 'Creating missing analytics identifiers...',
	SITES_MISSING_POS_NOTIFICATION: [
		'Non-existing analytics identifiers were assigned to this site definition.',
		'Create the missing analytics identifiers, or manually assign analytics identifiers.',
	],
	DOT: '.',
	COMMA: ',',
	ASSIGN_ANALYTICS_IDENTIFIER: 'Assign analytics identifier',
	UNASSIGN_ANALYTICS_IDENTIFIER: 'Unassign analytics identifier',
	ASSIGN_EXISTING: 'Assign existing',
	ASSIGN: 'Assign',
	POS_NAME_SPACES_ERROR: 'Spaces are not allowed. Use - or _ instead.',
	POS_NAME_HELPER: 'Example: mysite/en-us',
	POS_EXISTS_ERROR: 'Analytics identifier with this name already exists',
	POS_TIMEOUT_REQUIRED: 'Timeout is required',
	POS_TIMEOUT_HELPER: 'The time that passes before the browser session times out',
	CREATE_POS_ALERT: 'After creating an analytics identifier, you cannot edit it',
	NOT_ASSIGNED: 'Not assigned',
	ANALYTICS_IDENTIFIER_DETAILS: 'Analytics identifier details',
	ASSIGNED_ANALYTICS_IDENTIFIER: 'Assigned analytics identifier',
	ANALYTICS_IDENTIFIER_DOES_NOT_EXIST: "Analytics identifier does't exist",
	UNASSIGN_ANALYTICS_IDENTIFIER_DESCRIPTION:
		'You will no longer receive analytics data for this language. The site and page insights become outdated after 30 days. The personalize functionality won`t be available.',
	UNASSIGNING: 'Unassigning...',
	ASSIGNING: 'Assigning...',
	NO_ANALYTICS_IDENTIFIERS_TEXT: 'No analytics identifiers yet',
	NO_ANALYTICS_IDENTIFIERS_DESCRIPTION: 'To create analytics identifiers navigate to the site settings.',
	POS_POPUP_ENABLEMENT_TITLE: 'Disable analytics and personalization',
	ENABLE_ANALYTICS_AND_PERSONALIZATION: 'Enable analytics and personalization',
	DISABLE_ANALYTICS_AND_PERSONALIZATION: 'Disable analytics and personalization',
	POS_POPUP_ENABLEMENT_DESCRIPTION: `You will no longer receive analytics data for this site. The site and page insights become outdated after 30 days. The personalize functionality won't be available.`,
	THUMBNAIL: {
		LABEL: 'Thumbnail',
		MANUAL_UPLOADING: 'Cropping thumbnail...',
		ERROR_MESSAGE: {
			BIG_FILE_SIZE: 'Thumbnail size is more than 1MB',
			WRONG_DIMENSION: 'Thumbnail should be 320x177 pixels',
			UNSUPPORTED_FILE_TYPE: 'This file type is not supported',
		},
		ADD_CUSTOM_THUMBNAIL: 'Upload custom thumbnail',
		UPLOAD_FILE_LIMITS: 'PNG or JPG',
		UPLOAD_FILE_RATIO: 'Final thumbnail size will be 320x177 pixels',
		CROP: 'Crop',
		MODAL_HEADER: 'Crop thumbnail before uploading',
	},
	AUTOMATIC: 'Automatic',
	AUTOGENERATE: 'Autogenerate',
	REPLACE: 'Replace',
	REGENERATING: 'Regenerating...',
	REGENERATE: 'Regenerate',
	UPLOAD: 'Upload',
	SITE_LABEL_SYSTEM_NAME: 'System name',
	SITE_EDIT_SYSTEM_NAME: 'Edit system name',
	SITE_LABEL_CHANGE_SYSTEM_NAME: 'Changing system name to',
	SITE_HINT_EDIT_SYSTEM_NAME: 'The underlying identifier for the site used for folder names and backend processes',
	SITE_POPUP_HINT_EDIT_SYSTEM_NAME:
		'If you change the system name, underlying folders that use the system name will be renamed too. This may take a while.',
	SITE_POPUP_HINT_VALIDATION_SYSTEM_NAME: 'Use hyphens (-) or underscores (_) instead of spaces and avoid special characters',
	SITE_EDIT_ICON: 'Edit site',
	SITE_ERROR_SYSTEM_NAME: 'Changing system name failed. Try again.',
	SELECT_OR_CREATE_COLLECTION: 'Select or type to create new',
	SELECT_COLLECTION_NO_OPTIONS: 'Nothing created yet',
	COLLECTION_LABEL_SYSTEM_NAME: 'System name',
	COLLECTION_EDIT_SYSTEM_NAME: 'Edit system name',
	COLLECTION_LABEL_CHANGE_SYSTEM_NAME: 'Changing system name to',
	COLLECTION_HINT_EDIT_SYSTEM_NAME: 'The underlying identifier for the collection used for folder names and backend processes',
	COLLECTION_POPUP_HINT_EDIT_SYSTEM_NAME:
		'If you change the system name, underlying folders that use the system name will be renamed too. This may take a while.',
	COLLECTION_POPUP_HINT_VALIDATION_SYSTEM_NAME: 'Use hyphens (-) or underscores (_) instead of spaces and avoid special characters',
	COLLECTION_EDIT_ICON: 'Edit collection',
	COLLECTION_NAME_XM_CLOUD_INFO_MESSAGE: 'The collection name within XM Cloud',
	COLLECTION_SYSTEM_NAME_INFO_MESSAGE: 'The underlying identifier for the collection used for folder names and backend processes',
	COLLECTION_SYSTEM_NAME_ERROR: 'Changing system name failed. Try again.',
	BACK_TO_ALL_SITE_DEFINITIONS: 'Back to all site definitions',
	HOST_NAME_POPOVER_HEADER: 'Hostname',
	HOST_NAME_POPOVER_BODY: 'The location where your site is hosted, as defined in your front-end hosting service.',
	TARGET_HOST_NAME_POPOVER_HEADER: 'What is target hostname?',
	TARGET_HOST_NAME_POPOVER_BODY: 'The hostname you want to use for generating URLs for pages, links, and sitemaps.',
	TARGET_HOST_NAME_EXISTS: 'The same target hostname is used by {siteDefName}',
	VALID_FOR_ENVIRONMENT_POPOVER_HEADER: 'What is valid for environment?',
	VALID_FOR_ENVIRONMENT_POPOVER_BODY:
		'Valid for environment specifies the XM Cloud environment that the site is resolved for. You must define the environment name that this field refers to in your config file.',
	ADD_HOSTNAME: 'Add hostname',
	RENDERING_HOST: 'Rendering host',
	RENDERING_HOST_REQUIRED: 'Rendering host is required',
	RENDERING_HOST_DESCRIPTION:
		'The front-end application is also referred to as "rendering host," and it is used for displaying the site to your audience',
	VALID_FOR_DEVELOPMENT: 'Valid for development',
	VALID_FOR_DEVELOPMENT_DESCRIPTION: 'Allowed values are empty string, *, or an environment name without blank spaces',
	VALID_FOR_DEVELOPMENT_POPOVER_HEADER: 'What is valid for development?',
	VALID_FOR_DEVELOPMENT_POPOVER_BODY:
		'Valid for environment specifies the XM Cloud environment that the site is resolved for. You must define the environment name that this field refers to in your config file.',
	lINK_PROVIDER_NAME: 'Link provider name',
	LINK_PROVIDER_NAME_DESCRIPTION: 'A component that resolves and generates links within the system.',
	HELP: 'Help',
	XM_CLOUD_DOCUMENTATION: 'XM Cloud documentation',
	XM_CLOUD_SITES_DOCUMENTATION: 'XM Cloud Sites documentation',
	SITECORE_SUPPORT_PORTAL: 'Sitecore Support Portal',
	REPORT_ISSUE_AND_MANAGE: 'Report issues and manage your support cases',
	XM_CLOUD_DEVELOPER_PORTAL: 'XM Cloud Developer Portal',
	RESOURCES_FOR_DEVELOPER: 'Resources for the best developer experience',
	JOIN_SITECORE: 'Join the Sitecore Collaborative Program',
	HELP_US: 'Help us create better products',
	CREATE_SUPPORT_TICKET: 'Create support ticket',
	JSS_SITE_CARD_BADGE: 'Limited administration site',
	JSS_PORTFOLIO_NAME: 'Limited administration sites',
	JSS_PORTFOLIO_DESCRIPTION: 'Sites that were created in a code flow and are managed by developers',
	JSS_SITE_GENERAL_PAGE_ALERT: '{siteName} was created in a code flow and is managed by developers',
	SITE_DEFINITIONS: 'Site definitions',
	SITE_DEFINITIONS_DESCRIPTION:
		'Reuse the site content and structure in different language/domain combinations by creating site definitions. You can also specify a different homepage per site definition.',
	CREATE_SITE_DEFINITION: 'Create site definition',
	SITE_DEFINITION_RENAME_MESSAGE: {
		SUCCESS: 'Site definition renamed',
		ERROR: 'Site definition renamed failed',
		LOADING: 'Renaming site definition',
	},
	RENDERING_HOST_HINT: 'The front-end application is also referred to as "rendering host" and it is used for displaying the site to your audience',
	HOSTNAME_HELPER: 'URI format',
	DEFAULT: 'Default',
	SITE_DEFINITION_CREATED: 'Site definition created',
	SITE_DEFINITION_FAILED: 'Site definition creation failed',
	CREATING: 'Creating "{name}"',
	DUPLICATING_SITE_DEFINITION: 'Duplicating site definition',
	SITE_DEFINITION_DUPLICATED: 'Site definition duplicated',
	SITE_DEFINITION_DUPLICATE_FAILED: 'Site definition duplication failed',
	DUPLICATE_ENTITY: 'Duplicate {name}',
	DELETE_SITE_DEFINITION: 'Delete site definition',
	DELETE_SITE_DEFINITION_TOOLTIP:
		"This is the only site definition for the site and can't be deleted. You can create a new definition, then delete this one.",
	DELETE_SITE_DEFINITION_CONFIRMATION:
		"You are about to delete {defName} from {siteName} site. Visitors who try to access it won't be able to, and will receive an error. This action cannot be undone.",
	DELETE_PROMISE_TOAST: {
		LOADING: 'Deleting "{defName}". You can browse away.',
		SUCCESS: 'Site definition deleted',
		ERROR: 'Site definition deletion failed',
	},
	THUMBNAIL_SITE_DEFINITIONS_HINT:
		'The automatic thumbnail is derived from the selected site definition. If you have multiple site definitions, you can select another one to take the thumbnail from.',
	DELETE_PAGE: 'Delete page',
	DELETE_PAGE_HINT: 'Are you sure you want to delete {displayName}? This action cannot be undone.',
	DELETE_PAGE_WITH_CHILDREN_HINT:
		'Are you sure you want to delete {displayName}? This page has subpages which will also be deleted. This action cannot be undone.',
	SITE_STRUCTURE: 'Site structure',
	THIS_PAGE_IS_HOMEPAGE_TOOLTIP: 'This page is set as a homepage for {hosts} and it cannot be deleted.',
	THIS_PAGE_IS_PARENT_FOR_HOMEPAGE_TOOLTIP: 'This page is a parent to items that are set as a homepage for {hosts} and it cannot be deleted.',
	DELETE_PAGE_PROMISE_TOAST: {
		LOADING: 'Deleting "{pageName}"',
		SUCCESS: 'Page deleted',
		ERROR: 'Page deletion failed',
	},
	DUPLICATE_PAGE: 'Duplicate "{name}"',
	PAGE_DUPLICATION_FAILED: 'Page duplication failed',
	USE_TO_BUILD_URL: 'Used to build the URL of the item',
	DUPLICATE_SITE_ALERT_DESCRIPTION: 'This page has subpages which will also be duplicated',
	DISPLAY_NAME_ENGLISH: 'Display name (English)',
	DISPLAY_NAME_ENGLISH_HINT:
		'The item display name can be different per language. By default all pages in site structure are created and displayed in English (en).',
	PAGE_NAME_WITHIN_XMCLOUD: 'The page name within XM Cloud',
	RENAME_PAGE_PROMISE_TOAST: {
		LOADING: 'Renaming page',
		SUCCESS: 'Page renamed',
		ERROR: 'Page renaming failed',
	},
	SYSTEM_LANGUAGE_PAGE_SUBTITLE:
		'[WIP] The default language is English, but you can add any number of languages to your XM Cloud environment. After adding languages, you create the language versions you want for your items or sites. Then you can start translating the content of your websites.',
	LANGUAGE_CODE: 'Language code',
	FALLBACK_LANGUAGE: 'Fallback language',
	LANGUAGE_DEFAULT_TOOLTIP: 'English is the default XM Cloud language and it cannot be edited or deleted',
	ADD_LANGUAGE: 'Add language',
	ADD: 'Add',
	FALLBACK_LANGUAGE_DESCRIPTION:
		'In a multilanguage solution, language fallback is a mechanism that displays content in a different language if that content does not exist in the current language. With language fallback, you can control whether you want items or fields to be translated or reuse content from another language.',
	LANGUAGE_CREATE_PROMISE_TOAST: {
		LOADING: 'Adding language',
		SUCCESS: 'Language added',
		ERROR: 'Adding language failed',
	},
	EDIT_LANGUAGE: 'Edit language',
	LANGUAGE_EDIT_PROMISE_TOAST: {
		LOADING: 'Saving changes',
		SUCCESS: 'All changes saved',
		ERROR: 'Saving language changes failed',
	},
	DELETED_FALLBACK_LANGUAGE_LABEL: 'The selected fallback language was deleted from this environment',
	DELETE_LANGUAGE: 'Delete language',
	DELETE_LANGUAGE_MAIN_TEXT:
		'Are you sure you want to delete {englishName} from {environmentType} environment? All {englishName} translated content and language versions will be deleted from all sites in this environment. This action cannot be undone.',
	DELETE_LANGUAGE_TOAST: {
		LOADING: 'Deleting “{englishName}”. You can browse away.',
		SUCCESS: 'Language deleted',
		ERROR: 'Language deletion failed',
	},
	WARNING: 'Warning',
	IS_FALLBACK_LANGUAGE_OF: '{englishName} is set as the fallback language for {isFallbackOf}',
	IS_DEFAULT_LANGUAGE_OF:
		'{englishName} is set as default language for {isDefaultLanguageOf} {noOfLanguages, plural, one {site definition} other {site definitions}}',
	SCROLL_TO_TOP: 'Scroll to top',
	BASIC_INFORMATION: 'Basic information',
	COMMON_MOVE_ERROR: 'Moving failed. Try again.',
	PAGE_EXISTS_ERROR: 'Page could not be moved because an item with the same item name already exists on this level',
	LANGUAGES_HINT: 'The languages this site will support. You can change your selection later from the site settings.',
	SHARED_SITE: 'Shared site',
	SITE_SHARING: 'Site sharing',
	SITE_SHARING_DESCRIPTION:
		'Enable this option to share data sources, page designs, and partial designs with all other sites within {collectionName} collection. {link}',
	SHARE_SITE: 'Share site',
	GENERIC_ERROR_IMG_ALT: 'Generic error',
	PAGE_NOT_FOUND: 'Page not found',
	PAGE_NOT_FOUND_SUBTEXT: 'Error 404',
	PAGE_NOT_FOUND_TEXT: 'The page you are looking for cannot be found.',
	VISIT_KNOWLEDGE_BASE: 'Visit knowledge base',
	UTILITIES: 'Utilities',
	ACCESS_MANAGEMENT: 'Access management',
	DESKTOP: 'Desktop',
	USER_MANAGER: 'User manager',
	ROLE_MANAGER: 'Role manager',
	SECURITY_EDITOR: 'Security editor',
	ACCESS_VIEWER: 'Access viewer',
	SUPPORT_LANGUAGES_HINT: 'The languages this site supports',
	SITE_STRUCTURE_PAGE_CANNOT_BE_CREATED:
		'Page cannot be created because "{nameSiteOrPage}" does not have any templates assigned as insert options.',
	ENGLISH: 'English',
	MANAGING_SITE_STRUCTURE_BUTTON_TOOLTIP: 'Managing site structure in multiple languages is coming soon.',
	MOVE_PAGE: 'Move page',
	HOMEPAGE_FOR: 'Homepage for {siteDefinitions}',
	YOU_DO_NOT_HAVE_PERMISSION_TO_DELETE_PAGE: "You don't have permission to delete this page",
	CREATE_CHILD_PAGE_OF: 'Create child page of "{pageName}"',
	CREATE_PAGE_BEFORE_CURRENT: 'Create page before "{pageName}"',
	CREATE_PAGE_AFTER_CURRENT: 'Create page after "{pageName}"',
};

export const defaultSitesInput = { database: 'master' };

export const SUPPORT_PAGE_LINK = 'https://www.sitecore.com/company/contact-us';

export const KNOWLEDGE_BASE_LINK = 'https://support.sitecore.com/kb';

export const ENV = {
	DEV: 'dev',
	QA: 'qa',
	STAGING: 'staging',
	PRE_PRODUCTION: 'pre-production',
	PRODUCTION: 'production',
};

export const IGNORE_GLOBAL_ERROR_HANDLER_FLAG = 'IGNORE_GLOBAL_ERROR_HANDLER';

export const SC_ICONS_SRC = {
	XM_CLOUD: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-xm-cloud',
	NO_SITE: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-xm_cloud',
	MAGNIFYING_GLASS_X_GRAY: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-magnify-close-neutral',
	NEUTRAL_CACTUS: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-cactus-neutral',
	XM_CLOUD_WITH_TEXT: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/logo-xm_cloud',
	GENERIC_ERROR: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-alert',
	BAD_REQUEST: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-alert-circle',
	UNAUTHORIZED: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-stop',
	FORBIDDEN: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-lock',
	PAGE_NOT_FOUND: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-map-search',
	INTERNAL_SERVER_ERROR: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-alert-circle',
	SERVICE_UNAVAILABLE: 'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/spot-wrench-clock',
};

export const DEFAULT_SITE_NAME_LENGTH = 50;

export const DEFAULT_SITE_DESCRIPTION_LENGTH = 100;

export const DEFAULT_PAGE_NAME_LENGTH = 100;

export const JOB_STATUS_REFETCH_INTERVAL = 20_000;

export const COMMON_REFETCH_INTERVAL = 5000;

export const DATE_FORMAT = 'MMM dd, yyyy, hh:mm:ss a';

export const EMPTY_VALUE = '--';

export const VALIDATIONS = {
	INTEGER_MESSAGE: 'The integer is out of range',
	NUMBER_MESSAGE: 'The number is out of range',
	NUMBER_MAX_VALUE: Number.MAX_SAFE_INTEGER,
	NUMBER_MIN_VALUE: Number.MIN_SAFE_INTEGER,
	NAME_REQUIRED: 'Name is required',
	ITEM_NAME_REQUIRED: 'Item name is required',
	SYSTEM_NAME_REQUIRED: 'System name is required',
	NAME_VALIDATION_TRIM: 'Name cannot include leading or trailing spaces',
	ITEM_NAME_VALIDATION_TRIM: 'Item name cannot include leading or trailing spaces',
	NAME_VALIDATION_MATCHES: 'Invalid characters. Allowed are A–Z, a–z, 0–9, hyphen (-), underscore (_).',
	NAME_VALIDATION_HYPHEN_START: 'Name cannot start with special characters',
	SYSTEM_NAME_VALIDATION_HYPHEN_START: 'System name cannot start with special characters',
	DEFINITION_NAME_VALIDATION_MATCHES: 'Invalid characters. Allowed are A–Z, a–z, 0–9, hyphen (-) and underscore (_).',
	NAME_VALIDATION_NUMBER: 50,
	NAME_VALIDATION_MAX: 'Max 50 characters allowed',
	COLLECTION_REQUIRED: 'Collection is required',
	COLLECTION_SYSTEM_NAME_REQUIRED: 'System name is required',
	DEFAULT_LANGUAGE_REQUIRED: 'Default language is required',
	COLLECTION_META_NAME_ALREADY_EXISTS: 'A collection with this name already exists',
	COLLECTION_SYSTEM_NAME_ALREADY_EXISTS: 'A collection with this system name already exists',
	DEFINITION_SPACES_NOT_ALLOWED: 'Spaces are not allowed',
	HOSTNAME_REQUIRED: 'Hostname is required',
	SPACE_IS_NOT_ALLOWED: 'Spaces are not allowed. Use - or _ instead.',
	SITE_NAME_VALIDATION: 'Invalid characters. Allowed are A–Z, a–z, 0–9, hyphen (-) and underscore (_).',
	SITE_DEFINITION_EXISTS: 'A site definition with this name already exists',
	HOSTNAME_EXISTS: 'This hostname is used by another site definition',
	INVALID_URI: 'Enter a valid URI',
	PAGE_NAME_MATCHES: 'Invalid characters. Allowed are A–Z, a–z, 0–9, hyphen (-), underscore (_) and spaces.',
	PAGE_NAME_TRIM: 'Item name cannot include leading or trailing spaces',
	PAGE_DISPLAY_NAME_TRIM: 'Display name cannot include leading or trailing spaces',
	PAGE_NAME_REQUIRED: 'Item name is required',
	PAGE_TEMPLATE_REQUIRED: 'Page template is required',
	PAGE_NAME_LENGTH: 100,
	PAGE_NAME_VALIDATION_MAX: 'Max 100 characters allowed',
	SITE_PAGE_VALIDATION_MATCHES: 'Invalid characters. Allowed are A–Z, a–z, 0–9, hyphen (-), underscore (_) and spaces.',
	DISPLAY_NAME_VALIDATION_TRIM: 'Display name cannot include leading or trailing spaces',
	SITE_MISSING_LANGUAGE: 'The default language was removed from the site',
	SYSTEM_MISSING_LANGUAGE: 'The default language was deleted from the environment',
	ONE_LANGUAGE_IS_REQUIRED: 'At least one language is required',
};

export const ITEM_CREATION_REGEX = /^[\w][\w\s-]*(\(\d{1,}\)){0,1}$/;

export const ITEM_NAME_REGEX = /^(?!\\s)[a-zA-Z0-9_\\s-]*(?<!\\s)$/;

export const ITEM_SYSTEM_NAME_REGEX_SANITIZER = /[^A-Za-z0-9_-\s]*/g;

export const WHITE_SPACE_REGEX = /^\S*$/;

export const XSS_REGEXP = /[<>]/g;

export const ITEM_SYSTEM_NAME_REGEX = /^[A-Za-z0-9_-]*$/;

export const ITEM_SYSTEM_NAME_REGEX_EXPANSION = /^[^-]/;

export const SITE_PAGE_NAME_REGEX = /^[A-Za-z0-9_ -]*$/;

export const DEFAULT_STALE_TIME = 1000 * 60 * 5;

export const DEVELOPER_SETTINGS_LINKS = {
	OLDER: 'https://doc.sitecore.com/xmc/en/developers/jss/215/jss-xmc/next-js-for-xm-cloud-environment-variables.html',
	NEWER: 'https://doc.sitecore.com/xmc/en/developers/jss/latest/jss-xmc/next-js-for-xm-cloud-environment-variables.html',
};
export const HEADER_SITES_COUNT_CHECK_INTERVAL = 1000;

export const COLLECTION_NAME_VALIDATION_NUMBER = 50;

export const COLLECTION_DESCRIPTION_VALIDATION_NUMBER = 100;

export const COPY_OF_PREFIX = 'Copy of';

export const COPY_OF_PREFIX_WITH_DASH = 'Copy-of-';

export const SITE_SETTINGS_FORM_EVENT_NAME = 'SiteSettingsFormEvent';

export const SITE_SETTINGS_TOUCHED = 'SiteSettingsTouched';

export const SITE_SETTINGS_SAVED = 'SiteSettingsSaved';

export const SITE_IS_SUBMITTING = 'SiteIsSubmitting';

export const SITE_SETTINGS_FIELDS_EVENT = 'SiteSettingsFieldsEvent';

export const SITE_GENERAL_SETTINGS_FIELDS_EVENT = 'SiteGeneralFieldsEvent';

export const SITE_DEFINITION_FORM_EVENT_NAME = 'SiteDefinitionFormEvent';

export const SITE_DEFINITION_TOUCHED = 'SiteDefinitionTouched';

export const SITE_DEFINITION_SAVED = 'SiteDefinitionSaved';

export const SITE_DEFINITION_FIELDS_EVENT = 'SiteDefinitionFieldsEvent';

export const SHOW_MORE_COUNT_SITES = 10;

export const HOST_NAME = '*';

export const LANGUAGE_FALLBACK_INFO_LINK = 'https://doc.sitecore.com/xmc/en/developers/xm-cloud/language-fallback.html';

export const JOB_STATUS = {
	SCAFFOLD_SITE: 'Scaffold site [',
	CREATE_SITE: 'Create site [',
	CREATE_SITE_COLLECTION: 'Create site collection [',
	CLONE_SITE: 'Clone site [',
	REMOVE_SITE: 'Remove site [',
};

export const NORMALIZE_ID_WITHOUT_DASH_REGEX = /[{}-]/g;

export const NORMALIZE_GUID_REGEX = /{?(.{8})-?(.{4})-?(.{4})-?(.{4})-?(.{12})}?$/;

export const SITE_CARD_SIZE = {
	WIDTH: 'xs',
	HEIGHT: 60,
};

export const POS_CREATION_REGEX = /^[\w*\-/.][\w\-/.]*(\(\d{1,}\)){0,1}$/;

export const POS_NAME_ALLOWED_CHARACTERS_REGEX = /[^a-z0-9-_/ .]/g;

export const POS_DEFAULT_MARKET = 'global';

export const POS_DEFAULT_BRAND = 'DEFAULT';

export const POS_DEFAULT_TIMEOUT = 30;

export const POS_NAME_MAX_LENGTH = 50;

export const POS_FIELD_ENABLE_ANALYTICS = 'uxEnableAnalytics';

export const THUMBNAIL_MAX_FILE_SIZE = 1024 * 400;

export const THUMBNAIL_MAX_PIXEL_SIDE_SIZE = 400;

export const THUMBNAIL_MIN_WIDTH = 320;

export const THUMBNAIL_MIN_HEIGHT = 177;

export const THUMBNAIL_ACCEPTED_FILES = ['image/jpeg', 'image/jpg', 'image/png'];

export const THUMBNAIL_REGENERATE_TIMEOUT = 6000;

export const TIMEOUT_HALF_SECOND = 500;

export const TOOLTIP_SHOW_DELAY = 750;

export const MENU_ITEM_HEIGHT = 34;

export const CREATE_SITE_MODAL_HASHTAG = '#createSite';

export const URL_VALIDATE_REGEX = /^[a-zA-Z0-9-.]*[a-zA-Z]$/;

export const ASTERISK_REGEX = /^\*$/;

export const HOSTNAME_DELIMITER = '|';

export const XM_CLOUD_DOC = 'https://doc.sitecore.com/xmc';

export const JOIN_SITECORE_COLLABORATIVE_PROGRAM_LINK = 'https://www.sitecore.com/help-us-build-products';

export const XM_CLOUD_DEVELOPER_PORTAL = 'https://developers.sitecore.com/content-management/xm-cloud';

export const SITECORE_SUPPORT_PORTAL = 'https://support.sitecore.com/csm?utm_source=cloud-portal&utm_medium=help-section';

export const XM_CLOUD_SITES_DOC = 'https://doc.sitecore.com/xmc/en/users/xm-cloud/sites.html';

export const SITE_SHARING_DOC = 'https://doc.sitecore.com/xmc/en/developers/xm-cloud/share-content-between-headless-sites.html';

export const CREATE_SUPPORT_CASE_COMPONENT_NAME = 'create-support-case';

export const CREATE_SUPPORT_CASE_BUTTON_SELECTOR = `[data-support-case="${CREATE_SUPPORT_CASE_COMPONENT_NAME}"]`;

export const SPACE_REGEX = /^\S*$/;

export const HOSTNAME_REGEX = /^([a-zA-Z0-9-.]*[a-zA-Z]|[*])$/;

export const SITE_DEFINITION_REMOVING_LOCAL_STORAGE_KEY = 'site-definition';

export const SITE_DEFINITION_EVENT_REMOVE_ERROR = 'removed-site-definition';

export const DEFAULT_LANGUAGE = 'en';

export const DISPLAY_NAME_ITEM_ID = 'b5e02ad9-d56f-4c41-a065-a133db87bdeb';

export const REMOVE_QUERY_STRING = 'removeQueryString';

export const SITE_STRUCTURE_DEFAULT_HIGHLIGHT_TIMEOUT = 3000;

export const SELECTED_LANGUAGE = { value: 'en', label: 'English' };

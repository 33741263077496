import { Box, Heading, Stack, Text } from '@chakra-ui/react';
import { mdiCursorDefaultClickOutline } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { SectionDataRecord } from 'entities/page-layout/ui';
import { SelectedTemplate } from 'features/create-site/models';
import { TemplateContents } from 'features/create-site/ui/template-contents';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { useGetTemplates } from 'shared/templates/hooks';

export const TemplateDetails: FC<Partial<SelectedTemplate>> = ({ selectedTemplateId }) => {
	const { getTemplateById } = useGetTemplates();

	return !selectedTemplateId ? (
		<Stack flexGrow="1" alignItems="center" paddingTop="20" gap="6">
			<Icon path={mdiCursorDefaultClickOutline} boxSize="20" color="neutral.400" />
			<Text>
				<T _str={DICTIONARY.SELECT_TEMPLATE_TO_SEE_MORE_DETAILS} />
			</Text>
		</Stack>
	) : (
		<Stack gap="8" width="full">
			<Box>
				<Heading size="sm">{selectedTemplateId && getTemplateById(selectedTemplateId)?.name}</Heading>
				<Text paddingTop="2">{selectedTemplateId && getTemplateById(selectedTemplateId)?.description}</Text>
			</Box>
			<TemplateContents data={getTemplateById(selectedTemplateId)?.contents} />
			<Stack gap="3">
				<SectionDataRecord
					header={DICTIONARY.CREATED_BY}
					value={(selectedTemplateId && getTemplateById(selectedTemplateId)?.createdBy) ?? ''}
				/>
				<SectionDataRecord
					header={DICTIONARY.LAST_UPDATED}
					value={(selectedTemplateId && getTemplateById(selectedTemplateId)?.updatedOn) ?? ''}
				/>
			</Stack>
		</Stack>
	);
};

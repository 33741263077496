import {
	Button,
	ButtonGroup,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Textarea,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FormFieldProps } from 'entities/form-field-control/models';
import { FormFieldControl } from 'entities/form-field-control/ui';
import { useSiteDuplicate } from 'features/duplicate-modal/hooks';
import { DuplicateSiteViewModel, SiteDuplicateModalProps } from 'features/duplicate-modal/models/duplicate-site';
import { useCheckMetaNameUniqueness } from 'features/site-actions-menu/hooks';
import { Field, Form, Formik } from 'formik';
import { FC, useMemo } from 'react';
import { COPY_OF_PREFIX, DEFAULT_SITE_DESCRIPTION_LENGTH, DEFAULT_SITE_NAME_LENGTH, DICTIONARY } from 'shared/constants';
import { NonPredictiveInput } from 'shared/utils';
import { siteMetaNameValidationSchema } from 'shared/validations';

export const SiteDuplicateModal: FC<SiteDuplicateModalProps> = ({ isOpen, onClose, originName, siteId, description }) => {
	const { duplicate, siteNames, collectionId } = useSiteDuplicate(siteId, onClose);
	const checkUniqueness = useCheckMetaNameUniqueness({
		formKey: 'displayName',
		originalValue: '',
		collectionId,
	});
	const initialValue: DuplicateSiteViewModel = useMemo(() => {
		return {
			name: '',
			displayName: `${COPY_OF_PREFIX} ${originName}`,
			description,
		};
	}, [description, originName]);

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="md" blockScrollOnMount={false}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					<T _str={DICTIONARY.DUPLICATE_SITE_HEADER} siteName={originName} />
				</ModalHeader>
				<ModalCloseButton />
				<Formik<DuplicateSiteViewModel>
					initialValues={initialValue}
					onSubmit={duplicate}
					validationSchema={siteMetaNameValidationSchema(siteNames)}
					validate={(values) => checkUniqueness(values.displayName)}
					validateOnChange={false}
					validateOnBlur={false}
					validateOnMount={false}
				>
					{({ submitForm, isSubmitting }) => (
						<Form
							noValidate
							onSubmit={(e) => {
								e.preventDefault();
								void submitForm();
							}}
						>
							<ModalBody>
								<Flex direction="column" gap="6">
									<Field name="displayName" validate>
										{({ field, form }: FormFieldProps) => (
											<FormFieldControl
												form={form}
												field={field}
												name={field.name}
												maxLength={DEFAULT_SITE_NAME_LENGTH}
												label={DICTIONARY.NAME}
												helper={DICTIONARY.SITE_NAME_XM_CLOUD_INFO_MESSAGE}
												isDisabled={isSubmitting}
												isRequired
											>
												<NonPredictiveInput {...field} maxLength={DEFAULT_SITE_NAME_LENGTH} isRequired={false} autoFocus />
											</FormFieldControl>
										)}
									</Field>
									<Field name="description">
										{({ field, form }: FormFieldProps) => (
											<FormFieldControl
												form={form}
												field={field}
												name={field.name}
												maxLength={DEFAULT_SITE_DESCRIPTION_LENGTH}
												label={DICTIONARY.DESCRIPTION}
												isDisabled={isSubmitting}
											>
												<Textarea {...field} maxLength={DEFAULT_SITE_DESCRIPTION_LENGTH} />
											</FormFieldControl>
										)}
									</Field>
								</Flex>
							</ModalBody>
							<ModalFooter>
								<ButtonGroup>
									<Button variant="ghost" onClick={onClose} isDisabled={isSubmitting}>
										<T _str={DICTIONARY.CANCEL} />
									</Button>
									<Button type="submit" isLoading={isSubmitting} data-testid="duplicate_modal_button">
										<T _str={DICTIONARY.DUPLICATE} />
									</Button>
								</ButtonGroup>
							</ModalFooter>
						</Form>
					)}
				</Formik>
			</ModalContent>
		</Modal>
	);
};

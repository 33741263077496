import { CircularProgress, Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { isSameGuid } from 'shared/utils';
import { CreatingState } from 'widgets/site-structure/models/props';
import { calculateMargin } from 'widgets/site-structure/utils/calculate-margin';
import '../styles/page.scss';

export type CreatingPageProps = {
	creating: CreatingState | undefined;
	pageId?: string;
	lvl: number;
};

export const CreatingPage: FC<CreatingPageProps> = ({ creating, lvl, pageId }) => {
	const marginLeft = calculateMargin(lvl);

	if (creating && (isSameGuid(creating.id, pageId) || creating.direction === 'inside')) {
		return (
			<Flex className="page" marginLeft={marginLeft} alignItems="center" marginY="2">
				<Flex gap="2">
					<Flex boxSize="8" />
					<Flex boxSize="8" />
					<Flex gap="2" alignItems="center">
						<CircularProgress size="6" isIndeterminate color="primary" />
						<Text noOfLines={1} title={creating.name} as="span">
							{creating.name}
						</Text>
					</Flex>
				</Flex>
			</Flex>
		);
	}

	return null;
};

import { Tab, TabList, Tabs } from '@chakra-ui/react';
import { mdiAlertOutline } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { useNavigationItems } from 'entities/page-layout/hooks';
import { FC, useMemo } from 'react';
import { LEFT_BAR_NAVIGATION } from 'shared/pageNavigation';

type SiteDefinitionTabsProps = {
	hasMissingPoSs: boolean;
};

export const SiteDefinitionTabs: FC<SiteDefinitionTabsProps> = ({ hasMissingPoSs }) => {
	const defaultLinks = useNavigationItems(LEFT_BAR_NAVIGATION.SITE_DEFINITION_PAGE);
	const links = useMemo(() => {
		if (hasMissingPoSs) {
			defaultLinks[1].icon = <Icon path={mdiAlertOutline} color="warning" />;
		}

		return defaultLinks;
	}, [defaultLinks, hasMissingPoSs]);

	return (
		<Tabs index={defaultLinks.findIndex((link) => link.active)}>
			<TabList>
				{links.map((link) => (
					<Tab gap="1" key={`${link.label}_${link.active ? 'true' : 'false'}`} onClick={() => link.onClick()}>
						{!!link.icon && link.icon}
						<T _str={link.label} />
					</Tab>
				))}
			</TabList>
		</Tabs>
	);
};

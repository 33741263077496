import { IconButton } from '@chakra-ui/react';
import { mdiTrashCanOutline } from '@mdi/js';
import { Icon } from 'entities/icon/ui';
import { useTranslate } from 'features/common/utils/translateUtils';
import { RemovePoSProps } from 'features/point-of-sale/models';
import { RemovePoSAlert } from 'features/point-of-sale/ui';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';

export const RemovePoSButton: FC<RemovePoSProps> = (props) => {
	const t = useTranslate();

	return (
		<RemovePoSAlert {...props}>
			<IconButton
				variant="ghost"
				size="sm"
				aria-label={t(DICTIONARY.DELETE)}
				icon={<Icon path={mdiTrashCanOutline} />}
				data-testid="remove-pos-identifier-from-pos-list"
			/>
		</RemovePoSAlert>
	);
};

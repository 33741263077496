import { PageBodyContent } from 'entities/page-layout/ui';
import { useSitePageData } from 'pages/site/hooks';
import { SitePageOutlet } from 'pages/site/models';
import { JssSiteGeneralPage, JssSitePage, SiteGeneralPage, SitePage } from 'pages/site/ui';
import { SiteGeneralPageFeature } from 'pages/site/ui/site-general-page';
import { SitePageLoader } from 'pages/site/ui/site-page-loader';
import { FC } from 'react';
import { useOutletContext } from 'react-router';

export const SitePageSwitcher: FC = () => {
	const { site, goBack, environment, environmentType, isLoading } = useSitePageData(true, false);
	const isJssSite = !site?.collectionId;

	if (isLoading) {
		return <SitePageLoader site={site} isLoading goBack={goBack} environment={environment} environmentType={environmentType} />;
	}

	return isJssSite ? (
		<JssSitePage site={site} isLoading={isLoading} environment={environment} environmentType={environmentType} goBack={goBack} />
	) : (
		<SitePage />
	);
};

export const SiteGeneralPageSwitcher: FC = () => {
	const { site } = useOutletContext<SitePageOutlet>();
	const isJssSite = !site?.collectionId;

	return isJssSite ? (
		<JssSiteGeneralPage />
	) : (
		<PageBodyContent>
			<SiteGeneralPageFeature />
			<SiteGeneralPage />
		</PageBodyContent>
	);
};

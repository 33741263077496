import { useCallback } from 'react';
import { useObservability } from 'shared/observability/ui';
import { ORGANIZATION, TENANT_NAME, orgIdStorageKey, tenantNameStorageKey } from '../auth/authProtocolParameters';

function updateSearchParamsIfNeeded(searchParams: URLSearchParams, name: string, value: string | undefined) {
	if (!value && searchParams.has(name)) {
		searchParams.delete(name);

		return true;
	}

	if (value && searchParams.get(name) != value) {
		searchParams.set(name, value);

		return true;
	}

	return false;
}

function saveInStorage(storageKey: string, newValue: string | undefined) {
	if (!newValue) {
		localStorage.removeItem(storageKey);
		sessionStorage.removeItem(storageKey);
	} else {
		localStorage.setItem(storageKey, newValue);
		sessionStorage.setItem(storageKey, newValue);
	}
}

const removeAuth0QueryParamsFromUrl = (searchParams: URLSearchParams) => {
	if (searchParams.has('code') || searchParams.has('state')) {
		searchParams.delete('code');
		searchParams.delete('state');

		return true;
	}

	return false;
};

export const useRememberTenantAndOrg = () => {
	const { apm } = useObservability();

	return useCallback(
		(organizationId: string | undefined, tenantName: string | undefined) => {
			const { location } = window;
			const searchParams = new URLSearchParams(location.search);
			const organizationIdUpdated = updateSearchParamsIfNeeded(searchParams, ORGANIZATION, organizationId);
			const tenantNameUpdated = updateSearchParamsIfNeeded(searchParams, TENANT_NAME, tenantName);
			const isAuth0ParamsRemoved = removeAuth0QueryParamsFromUrl(searchParams);

			if (organizationIdUpdated || tenantNameUpdated || isAuth0ParamsRemoved) {
				window.history.pushState({}, '', `${location.pathname}?${searchParams.toString()}`);
			}

			if (organizationId && tenantName) {
				apm?.addLabels({ tenant_name: tenantName, org_id: organizationId });
			}

			saveInStorage(orgIdStorageKey, organizationId);
			saveInStorage(tenantNameStorageKey, tenantName);
		},
		[apm]
	);
};

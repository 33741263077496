export const DELETE_PAGE_START_EVENT_NAME = 'delete-page-start';
export const DELETE_PAGE_SUCCESS_EVENT_NAME = 'delete-page-success';
export const DELETE_PAGE_ERROR_EVENT_NAME = 'delete-page-error';

export const CREATE_PAGE_START_EVENT_NAME = 'create-page-start';
export const CREATE_PAGE_SUCCESS_EVENT_NAME = 'create-page-success';
export const CREATE_PAGE_ERROR_EVENT_NAME = 'create-page-error';

export const DUPLICATE_PAGE_START_EVENT_NAME = 'duplicate-page-start';
export const DUPLICATE_PAGE_SUCCESS_EVENT_NAME = 'duplicate-page-success';
export const DUPLICATE_PAGE_ERROR_EVENT_NAME = 'duplicate-page-error';

export const RENAME_PAGE_START_EVENT_NAME = 'rename-page-start';
export const RENAME_PAGE_END_EVENT_NAME = 'rename-page-end';

export type Direction = 'before' | 'after' | 'inside';

export type CommonPageEvent = {
	pageId: string;
};

export type DirectionProps = {
	direction: Direction;
};

export type NameProps = {
	name: string;
};

export type DeletePageStartEvent = CommonPageEvent;
export type DeletePageSuccessEvent = CommonPageEvent;
export type DeletePageErrorEvent = CommonPageEvent;

export type CreatePageStartEvent = CommonPageEvent & DirectionProps & NameProps;
export type CreatePageSuccessEvent = CommonPageEvent & DirectionProps;
export type CreatePageErrorEvent = CommonPageEvent;

export type DuplicatePageStartEvent = CommonPageEvent & NameProps;
export type DuplicatePageSuccessEvent = CommonPageEvent;
export type DuplicatePageErrorEvent = CommonPageEvent;

export type RenamePageStartEvent = CommonPageEvent & NameProps;
export type RenamePageEndEvent = CommonPageEvent;

declare global {
	interface DocumentEventMap {
		[DELETE_PAGE_START_EVENT_NAME]: CustomEvent<DeletePageStartEvent>;
		[DELETE_PAGE_SUCCESS_EVENT_NAME]: CustomEvent<DeletePageSuccessEvent>;
		[DELETE_PAGE_ERROR_EVENT_NAME]: CustomEvent<DeletePageErrorEvent>;

		[CREATE_PAGE_START_EVENT_NAME]: CustomEvent<CreatePageStartEvent>;
		[CREATE_PAGE_SUCCESS_EVENT_NAME]: CustomEvent<CreatePageSuccessEvent>;
		[CREATE_PAGE_ERROR_EVENT_NAME]: CustomEvent<CreatePageErrorEvent>;

		[DUPLICATE_PAGE_START_EVENT_NAME]: CustomEvent<DuplicatePageStartEvent>;
		[DUPLICATE_PAGE_SUCCESS_EVENT_NAME]: CustomEvent<DuplicatePageSuccessEvent>;
		[DUPLICATE_PAGE_ERROR_EVENT_NAME]: CustomEvent<DuplicatePageErrorEvent>;

		[RENAME_PAGE_START_EVENT_NAME]: CustomEvent<RenamePageStartEvent>;
		[RENAME_PAGE_END_EVENT_NAME]: CustomEvent<RenamePageEndEvent>;
	}
}

export const dispatchDeletePageStartEvent = (pageId: string) => {
	document.dispatchEvent(new CustomEvent<DeletePageStartEvent>(DELETE_PAGE_START_EVENT_NAME, { detail: { pageId } }));
};

export const dispatchDeletePageSuccessEvent = (pageId: string) => {
	document.dispatchEvent(new CustomEvent<DeletePageSuccessEvent>(DELETE_PAGE_SUCCESS_EVENT_NAME, { detail: { pageId } }));
};

export const dispatchDeletePageErrorEvent = (pageId: string) => {
	document.dispatchEvent(new CustomEvent<DeletePageErrorEvent>(DELETE_PAGE_ERROR_EVENT_NAME, { detail: { pageId } }));
};

export const dispatchCreatePageStartEvent = (pageId: string, direction: Direction, name: string) => {
	document.dispatchEvent(new CustomEvent<CreatePageStartEvent>(CREATE_PAGE_START_EVENT_NAME, { detail: { pageId, direction, name } }));
};

export const dispatchCreatePageSuccessEvent = (pageId: string, direction: Direction) => {
	document.dispatchEvent(new CustomEvent<CreatePageSuccessEvent>(CREATE_PAGE_SUCCESS_EVENT_NAME, { detail: { pageId, direction } }));
};

export const dispatchCreatePageErrorEvent = (pageId: string) => {
	document.dispatchEvent(new CustomEvent<CreatePageErrorEvent>(CREATE_PAGE_ERROR_EVENT_NAME, { detail: { pageId } }));
};

export const dispatchDuplicatePageStartEvent = (pageId: string, name: string) => {
	document.dispatchEvent(new CustomEvent<DuplicatePageStartEvent>(DUPLICATE_PAGE_START_EVENT_NAME, { detail: { pageId, name } }));
};

export const dispatchDuplicatePageSuccessEvent = (pageId: string) => {
	document.dispatchEvent(new CustomEvent<DuplicatePageSuccessEvent>(DUPLICATE_PAGE_SUCCESS_EVENT_NAME, { detail: { pageId } }));
};

export const dispatchDuplicatePageErrorEvent = (pageId: string) => {
	document.dispatchEvent(new CustomEvent<DuplicatePageErrorEvent>(DUPLICATE_PAGE_ERROR_EVENT_NAME, { detail: { pageId } }));
};

export const dispatchRenamePageStartEvent = (pageId: string, name: string) => {
	document.dispatchEvent(new CustomEvent<RenamePageStartEvent>(RENAME_PAGE_START_EVENT_NAME, { detail: { pageId, name } }));
};

export const dispatchRenamePageEndEvent = (pageId: string) => {
	document.dispatchEvent(new CustomEvent<RenamePageEndEvent>(RENAME_PAGE_END_EVENT_NAME, { detail: { pageId } }));
};

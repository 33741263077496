import { t } from '@transifex/native';
import { Select } from 'chakra-react-select';
import { FormFieldProps } from 'entities/form-field-control/models';
import { FormFieldControl } from 'entities/form-field-control/ui';
import { Field } from 'formik';
import { DICTIONARY } from 'shared/constants';
import { LanguageControlFormProps } from '../models';

export const LanguageControlForm: React.FC<LanguageControlFormProps> = ({
	name,
	language,
	languages,
	setFieldTouched,
	onLanguageChange,
	handleChange,
	useBasicStyles = false,
	isDisabled = false,
}) => {
	return (
		<Field name={name}>
			{({ field, form }: FormFieldProps) => (
				<FormFieldControl form={form} field={field} name={name} label={DICTIONARY.DEFAULT_LANGUAGE} isRequired xssDisable>
					<Select
						placeholder={t(DICTIONARY.SELECT)}
						noOptionsMessage={() => t(DICTIONARY.NO_SEARCH_RESULT)}
						selectedOptionStyle="check"
						isInvalid={!!form.errors[field.name] && !!form.touched[field.name]}
						options={languages}
						onBlur={() => setFieldTouched(name)}
						value={language}
						defaultValue={language}
						inputId="language-field"
						onChange={(selectedOption) => {
							onLanguageChange(selectedOption);
							handleChange(name)(selectedOption ? selectedOption?.value : '');
						}}
						useBasicStyles={useBasicStyles}
						isDisabled={isDisabled}
					/>
				</FormFieldControl>
			)}
		</Field>
	);
};

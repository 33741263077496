import { ButtonGroup, IconButton, Menu, MenuButton, MenuDivider, MenuList, Tag, TagLabel, Tooltip } from '@chakra-ui/react';
import { mdiAlertCircleOutline, mdiDotsHorizontal } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { useNavigationItems } from 'entities/page-layout/hooks';
import { Page, PageBody, PageBodyLeftBar, PageHeader } from 'entities/page-layout/ui';
import { PageLeftNavigation } from 'entities/page-layout/ui/page-left-navigation';
import { useFeatures } from 'features/common/featureFlags/featureFlags';
import { useTranslate } from 'features/common/utils/translateUtils';
import { SiteDuplicateButtonWrapper } from 'features/duplicate-modal/ui';
import { ExplorerRedirectMenuItem, PageRedirectMenuItem } from 'features/site-actions-menu/ui';
import { useSitePageData } from 'pages/site/hooks';
import { SitePageOutlet } from 'pages/site/models';
import { FC, useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router';
import { DICTIONARY } from 'shared/constants';
import { Access } from 'shared/models';
import { OpenInPagesButton } from 'shared/open-in-pages/ui';
import { LEFT_BAR_NAVIGATION } from 'shared/pageNavigation';
import { SubmitAndResetButtonsFeature } from 'widgets/site-definition-update-form/ui';
import { SubmitAndResetButtons } from 'widgets/site-settings-update-form/ui';

export const SitePage: FC = () => {
	const { showSitesGrouping, showManagedLanguages } = useFeatures();
	const defaultLinks = useNavigationItems(
		LEFT_BAR_NAVIGATION.SITE_PAGE.filter((link) => link.showSitesGrouping === undefined || link.showSitesGrouping === showSitesGrouping).filter(
			(link) => link.showManagedLanguages === undefined || link.showManagedLanguages === showManagedLanguages
		)
	);
	const { site, languages, hosts, environment, environmentType, goBack, isLoading, isLanguagesLoading } = useSitePageData();
	const t = useTranslate();
	const location = useLocation();

	const [isSubmitAndResetVisible, setIsSubmitAndResetVisible] = useState(false);

	const links = useMemo(() => {
		if (!showManagedLanguages || !site || !languages) {
			return defaultLinks;
		}

		const hostsLanguages = site?.hosts?.map((host) => host.languageSettings.defaultLanguage) ?? [];
		const uniqueHostsLanguages = hostsLanguages.filter((lang, index) => hostsLanguages.indexOf(lang) === index);
		const hasMissingSiteLang = uniqueHostsLanguages.some((lang) => !site?.supportedLanguages?.includes(lang));
		const hasMissingSystemLang = uniqueHostsLanguages.some((lang) => !languages.find((l) => l.value === lang));
		const siteLanguages = site?.supportedLanguages || [];
		const icon = <Icon path={mdiAlertCircleOutline} color="warning" />;

		defaultLinks[2].icon = hasMissingSiteLang || hasMissingSystemLang ? icon : undefined;
		defaultLinks[3].icon = siteLanguages.length === 0 ? icon : undefined;

		return defaultLinks;
	}, [showManagedLanguages, site, languages, defaultLinks]);

	const outletContext = useMemo<SitePageOutlet>(
		() => ({
			site,
			languages,
			hosts,
			siteWithMissingPos: undefined,
			isLoading,
			isLanguagesLoading,
			locationOrigin: location.state?.origin ?? '/',
		}),
		[site, languages, hosts, isLoading, isLanguagesLoading, location.state]
	);

	const siteName = site?.displayName || site?.name || '';

	return (
		<Page data-testid="site-settings-page">
			<PageHeader backAction={goBack} title={siteName} alignItems="center" justifyContent="space-between" w="full">
				<Tag size="sm" height="fit-content">
					<TagLabel>
						<T _str={DICTIONARY.SITE_ENVIRONMENT} envName={environment} envType={environmentType} />
					</TagLabel>
				</Tag>
				<ButtonGroup>
					<SubmitAndResetButtons onIsDirtyChange={setIsSubmitAndResetVisible} />
					<SubmitAndResetButtonsFeature onIsDirtyChange={setIsSubmitAndResetVisible} />
					{showSitesGrouping && !isSubmitAndResetVisible && (
						<ButtonGroup>
							<SiteDuplicateButtonWrapper
								siteAccess={site?.access ?? ({} as Access)}
								siteName={siteName}
								siteId={site?.id ?? ''}
								description={site?.description ?? ''}
								siteLanguage={site?.defaultLanguage ?? ''}
								isStandAlone={true}
							/>
							<OpenInPagesButton hosts={hosts} />
						</ButtonGroup>
					)}
					{!showSitesGrouping && !isSubmitAndResetVisible && (
						<Menu isLazy lazyBehavior="keepMounted">
							<Tooltip label={t(DICTIONARY.OPTIONS)}>
								<MenuButton
									onFocus={(e) => e.preventDefault()}
									as={IconButton}
									aria-label={t(DICTIONARY.OPTIONS)}
									icon={<Icon path={mdiDotsHorizontal} />}
									variant="outline"
									data-testid="site-settings-menu"
								/>
							</Tooltip>
							<MenuList data-testid="contextMenuList">
								<PageRedirectMenuItem siteName={site?.name ?? ''} />
								<ExplorerRedirectMenuItem siteName={site?.name ?? ''} />
								<MenuDivider />
								<SiteDuplicateButtonWrapper
									siteAccess={site?.access ?? ({} as Access)}
									siteName={siteName}
									siteId={site?.id ?? ''}
									description={site?.description ?? ''}
									siteLanguage={site?.defaultLanguage ?? ''}
								/>
							</MenuList>
						</Menu>
					)}
				</ButtonGroup>
			</PageHeader>
			<PageBody>
				<PageBodyLeftBar>
					<PageLeftNavigation links={links} />
				</PageBodyLeftBar>
				<Outlet context={outletContext} />
			</PageBody>
		</Page>
	);
};

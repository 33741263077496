import { ApmBase } from '@elastic/apm-rum';
import { FC, PropsWithChildren, createContext, useContext } from 'react';

export type ObservabilityProviderProps = {
	apm?: ApmBase;
};

export const ObservabilityContext = createContext<ObservabilityProviderProps>({} as ObservabilityProviderProps);

export const ObservabilityProvider: FC<PropsWithChildren<ObservabilityProviderProps>> = ({ apm, children }) => {
	return <ObservabilityContext.Provider value={{ apm }}>{children}</ObservabilityContext.Provider>;
};

export const useObservability = () => useContext(ObservabilityContext);

import { resizeFile } from 'entities/thumbnail/utils/resizer';
import type { Area } from 'react-easy-crop/types';
import { THUMBNAIL_MAX_FILE_SIZE } from 'shared/constants';

export const createImage = (url: string): Promise<HTMLImageElement> =>
	new Promise((resolve, reject) => {
		const image = new Image();

		image.addEventListener('load', () => resolve(image));
		image.addEventListener('error', () => reject(new Error('Error loading image')));
		image.src = url;
	});

export const blobToBase64 = (blob: Blob): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.onloadend = () => resolve(reader.result as string);
		reader.onerror = () => reject(new Error('Error converting blob to base64'));
		reader.readAsDataURL(blob);
	});

export const getRadianAngle = (degreeValue: number) => {
	return (degreeValue * Math.PI) / 180;
};

export const rotateSize = (width: number, height: number, rotation: number) => {
	const rotRad = getRadianAngle(rotation);

	return {
		width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
		height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
	};
};

export const getCroppedImageBase64 = async (
	imageSrc: string,
	pixelCrop: Area | undefined,
	rotation: number,
	flip = { horizontal: false, vertical: false }
): Promise<string | undefined> => {
	const imageType = imageSrc.split(';')[0].split('/')[1].replace('JPG', 'JPEG');

	const image = await createImage(imageSrc);

	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');

	if (!ctx) {
		throw new Error('Could not get canvas context');
	}

	const rotRad = getRadianAngle(rotation);

	//calculate bounding box of the rotated image
	const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

	//set canvas size to match the bounding box
	canvas.width = bBoxWidth;
	canvas.height = bBoxHeight;

	//translate canvas context to a central location to allow rotating and flipping around the center
	ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
	ctx.rotate(rotRad);
	ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
	ctx.translate(-image.width / 2, -image.height / 2);

	// draw rotated image
	ctx.drawImage(image, 0, 0);

	const croppedCanvas = document.createElement('canvas');

	const croppedCtx = croppedCanvas.getContext('2d');

	if (!croppedCtx) {
		throw new Error('Could not get canvas cropped context');
	}

	if (!pixelCrop) {
		throw new Error('Could not get pixel crop');
	}

	// Set the size of the cropped canvas
	croppedCanvas.width = pixelCrop.width;
	croppedCanvas.height = pixelCrop.height;

	// Draw the cropped image onto the new canvas
	croppedCtx.drawImage(canvas, pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height, 0, 0, pixelCrop.width, pixelCrop.height);

	const blob: Blob = await new Promise((resolve, reject) => {
		croppedCanvas.toBlob(
			(file) => {
				if (file) {
					resolve(file);
				} else {
					reject(new Error('Error converting canvas to blob'));
				}
			},
			`image/${imageType}`,
			1 //max quality
		);
	});

	let result: string | undefined;

	if (blob.size >= THUMBNAIL_MAX_FILE_SIZE) {
		const file = new File([blob], 'thumbnail', { type: `image/${imageType}` });

		result = await resizeFile(file, imageType);
	} else {
		result = await blobToBase64(blob);
	}

	return result;
};

import { MenuIconButton } from 'entities/menu-icon-button/ui';
import { EditLanguageMenuItem } from 'features/languages/ui/edit-language-menu-item';
import { RemoveLanguageMenuItem } from 'features/remove-language/ui';
import { FC } from 'react';
import { DEFAULT_LANGUAGE, DICTIONARY } from 'shared/constants';
import { LanguageResponse } from 'shared/languages/models/languages';
import { Access } from 'shared/models';

export type LanguageActionsButtonProps = {
	language: LanguageResponse;
	systemLanguages: LanguageResponse[];
	access?: Access;
};

export const LanguageActionsButton: FC<LanguageActionsButtonProps> = (props) => {
	const { language, systemLanguages, access } = props;

	return (
		<MenuIconButton
			size="sm"
			labelText={language.name === DEFAULT_LANGUAGE ? DICTIONARY.LANGUAGE_DEFAULT_TOOLTIP : ''}
			isDisabled={language.name === DEFAULT_LANGUAGE}
		>
			<EditLanguageMenuItem language={language} canEdit={access?.canWrite} />
			<RemoveLanguageMenuItem {...props} allLanguages={systemLanguages} canDelete={access?.canDelete} />
		</MenuIconButton>
	);
};

import { Flex, FlexProps } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';

export const Page: FC<PropsWithChildren<FlexProps>> = ({ children, ...props }) => {
	return (
		<Flex direction="column" position="absolute" boxSize="full" bgColor="chakra-subtle-bg" top="0" zIndex="docked" overflow="hidden" {...props}>
			{children}
		</Flex>
	);
};

import { loader } from 'graphql.macro';

export const createSiteMutation = loader('./createSite.graphql');
export const getSitesAndJobsQuery = loader('./getSitesAndJobsList.graphql');
export const updateSitePosMutation = loader('./updateSolutionSitesPos.graphql');
export const getTemplatesQuery = loader('./getSiteTemplates.graphql');
export const getSiteCollectionsList = loader('./getSiteCollectionsList.graphql');
export const removeSiteQuery = loader('./RemoveSite.graphql');
export const renameSiteMutation = loader('./renameSite.graphql');
export const getJobQuery = loader('./getJob.graphql');
export const duplicateSiteQuery = loader('./duplicateSite.graphql');

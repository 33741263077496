/* eslint-disable */

import * as React from 'react';
import { isStyleProp } from '@chakra-ui/styled-system';

export const withOverride = (
	WrappedComponent: React.FC<any>,
	propsToOverride: Readonly<string[]> = [],
	propsNotToOverride: Readonly<string[]> = [],
	withRef: boolean = true
): any => {
	if (withRef) {
		return React.forwardRef((props, ref) => {
			// const overridenProps = overrideProps(props, propsToOverride, propsNotToOverride);
			return <WrappedComponent ref={ref} {...props} />;
		});
	}
	return function WithOverride(props: any) {
		// const overridenProps = overrideProps(props, propsToOverride, propsNotToOverride);
		return <WrappedComponent {...props} />;
	};
};

export const overrideProps = (
	componentProps: { [key: string]: any },
	propsToOverride: { [key: string]: any },
	propsNotToOverride: Readonly<string[]> = []
) => {
	const overridenProps: { [key: string]: any } = {};

	// Override style props
	const propNames = Object.keys(componentProps);
	propNames.forEach((propName) => {
		if (isStyleProp(propName) && !propsNotToOverride.includes(propName)) {
			overridenProps[propName] = undefined;
		}
	});

	// Override props set by component
	propsToOverride.forEach((propName: string) => {
		overridenProps[propName] = undefined;
	});

	return overridenProps;
};

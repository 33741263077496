import { Feature } from 'features/common/featureFlags/featureFlags';
import { NotFound } from 'features/main/components/NotFound';
import { SiteSettingsLayout } from 'features/siteSettings/components/SiteSettingsLayout';
import { ScLaunchpadGrid } from 'features/tools/components/ScLaunchpadGrid';
import { SiteLanguagesPage, SystemLanguagesPage } from 'pages/languages/ui';
import { PortfolioGeneralPage, PortfolioPage } from 'pages/portfolio/ui';
import { PortfolioSitesPage } from 'pages/portfolio/ui/portfolio-sites-page';
import { SettingsIdentifiersPage, SettingsPage } from 'pages/settings/ui';
import { SiteDefinitionPage, SiteDefinitionPageGeneral, SiteDefinitionPagePos } from 'pages/site-definition/ui';
import { SiteStructurePage } from 'pages/site-structure/ui/site-structure-page';
import { SiteDefinitionsPage, SiteGeneralPageSwitcher, SitePagePos, SitePageSwitcher } from 'pages/site/ui';
import { SitesPage } from 'pages/sites/ui';
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { NavigateWrapper } from 'shared/navigation/ui';
import { ROUTES } from 'shared/routes';
import { PageLayout } from './page-layout';

const routes = createBrowserRouter([
	{
		element: <PageLayout />,
		children: [
			{
				path: '/',
				element: <Outlet />,
				children: [
					{
						path: ROUTES.PORTFOLIO.SELF,
						element: <PortfolioPage />,
						children: [
							{
								path: ROUTES.PORTFOLIO.GENERAL,
								element: <PortfolioGeneralPage />,
							},
							{
								path: ROUTES.PORTFOLIO.SITES,
								element: <PortfolioSitesPage />,
							},
							{
								path: '',
								element: <NavigateWrapper relative="path" to={ROUTES.PORTFOLIO.GENERAL} replace />,
							},
							{
								path: '*',
								element: <NotFound />,
							},
						],
					},
					{
						path: ROUTES.SITE.SELF,
						element: <SitePageSwitcher />,
						children: [
							{
								path: ROUTES.SITE.GENERAL,
								element: <SiteGeneralPageSwitcher />,
							},
							{
								path: ROUTES.SITE.ANALYTICS_AND_PERSONALIZATION,
								element: <Feature name="showSitesGrouping" render={() => <NotFound />} fallback={() => <SitePagePos />} />,
							},
							{
								path: ROUTES.SITE.DEFINITIONS.NAME,
								element: <Feature name="showSitesGrouping" render={() => <SiteDefinitionsPage />} fallback={() => <NotFound />} />,
							},
							{
								path: ROUTES.SITE.DEFINITIONS.SELF,
								element: <Feature name="showSitesGrouping" render={() => <SiteDefinitionPage />} fallback={() => <NotFound />} />,
								children: [
									{
										path: ROUTES.SITE.DEFINITIONS.SETTING,
										element: <SiteDefinitionPageGeneral />,
									},
									{
										path: ROUTES.SITE.DEFINITIONS.ANALYTICS_AND_PERSONALIZATION,
										element: <SiteDefinitionPagePos />,
									},
									{
										path: '',
										element: <NavigateWrapper relative="path" to={ROUTES.SITE.DEFINITIONS.SETTING} replace />,
									},
								],
							},
							{
								path: ROUTES.SITE.SITE_STRUCTURE,
								element: <Feature name="showSitesGrouping" render={() => <SiteStructurePage />} fallback={() => <NotFound />} />,
							},
							{
								path: ROUTES.SITE.LANGUAGES,
								element: <Feature name="showManagedLanguages" render={() => <SiteLanguagesPage />} fallback={() => <NotFound />} />,
							},
							{
								path: '',
								element: <NavigateWrapper relative="path" to={ROUTES.SITE.GENERAL} replace />,
							},
							{
								path: '*',
								element: <NotFound />,
							},
						],
					},
					{
						path: '',
						element: <SitesPage />,
					},
					{
						path: '*',
						element: <NotFound />,
					},
				],
			},
			{
				path: ROUTES.SITE_SETTINGS.SELF,
				element: <SettingsPage />,
				children: [
					{
						path: ROUTES.SITE_SETTINGS.SYSTEM_LANGUAGES,
						element: <Feature name="showManagedLanguages" render={() => <SystemLanguagesPage />} fallback={() => <NotFound />} />,
					},
					{
						path: ROUTES.SITE_SETTINGS.ANALYTICS_IDENTIFIERS,
						element: <SettingsIdentifiersPage />,
					},
					{
						path: '',
						element: (
							<Feature
								name="showManagedLanguages"
								render={() => <NavigateWrapper relative="path" to={ROUTES.SITE_SETTINGS.SYSTEM_LANGUAGES} replace />}
								fallback={() => <NavigateWrapper relative="path" to={ROUTES.SITE_SETTINGS.ANALYTICS_IDENTIFIERS} replace />}
							/>
						),
					},
					{
						path: '*',
						element: <NotFound />,
					},
				],
			},
			{
				path: '/tools',
				element: <ScLaunchpadGrid />,
			},
			{
				path: '/sites/:sitename/*',
				element: <SiteSettingsLayout />,
			},
			{
				path: '/sites/:sitename/:tabname/*',
				element: <SiteSettingsLayout />,
			},
			{
				path: ROUTES.SITES.NAME,
				element: <NavigateWrapper relative="path" to={'/'} replace />,
			},
			{
				path: '*',
				element: <NotFound />,
			},
		],
	},
]);

export const AppRouter = () => {
	return <RouterProvider router={routes} />;
};

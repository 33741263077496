import { useGetSite } from 'features/sites/utils/useGetSite';
import { NonSxaSiteGeneralTab } from 'features/siteSettings/components/GeneralTab/NonSxaSiteGeneralTab';
import { SxaSiteGeneralTab } from 'features/siteSettings/components/GeneralTab/SxaSiteGeneralTab';
import { FC } from 'react';

export const GeneralTab: FC = () => {
	const { site } = useGetSite();

	if (site?.siteTypeByCollection === 'sxa') {
		return <SxaSiteGeneralTab site={site} />;
	}

	return <NonSxaSiteGeneralTab site={site} />;
};

import { Button, Flex, Input, Stack, Switch } from '@chakra-ui/react';
import { mdiAlert, mdiPlus } from '@mdi/js';
import { T } from '@transifex/react';
import { Select as ReactSelect } from 'chakra-react-select';
import { FormFieldProps } from 'entities/form-field-control/models';
import { FormFieldControl } from 'entities/form-field-control/ui';
import { TreeMenu } from 'entities/homepage-menu/ui';
import { Icon } from 'entities/icon/ui';
import { InfoPopover } from 'entities/info-popover/ui/';
import { Field } from 'formik';
import { TargetHostnames } from 'pages/site-definition/models/site-definition-props';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { RenderingHost } from 'shared/models';
import { useSettingsSectionBody } from 'widgets/site-definition-update-form/hooks/use-settings-section-body';
import { SingleValue } from 'widgets/site-definition-update-form/models';
import { setSiteDefinitionFieldValue } from 'widgets/site-definition-update-form/models/event';
import { HostnameField } from 'widgets/site-definition-update-form/ui/fields';

export type SettingsSectionBodyProps = {
	resetForm: () => void;
	submitForm: (() => Promise<void>) & (() => Promise<any>);
	name: string;
	homePageId: string;
	id: string;
	siteId: string;
	existingTargetHostnames: TargetHostnames[];
	allHostnames: string[];
	allRenderingHosts: RenderingHost[] | undefined;
};

export const SettingsSectionBody: FC<SettingsSectionBodyProps> = (props) => {
	const { homePageId, allHostnames, siteId } = props;
	const { isTargetHostNameAlreadyExists, renderingHostOptions } = useSettingsSectionBody(props);

	return (
		<>
			<Field name="homePageId">
				{({ field, form }: FormFieldProps) => (
					<FormFieldControl
						form={form}
						field={field}
						name="homePageId"
						label={DICTIONARY.HOMEPAGE}
						minW="xs"
						maxW="xl"
						helper={DICTIONARY.HOMEPAGE_HELPER}
						data-testid="site-definition-general-home-page"
					>
						<TreeMenu {...form} siteId={siteId} homePageId={homePageId} setFieldValue={form.setFieldValue} />
					</FormFieldControl>
				)}
			</Field>
			<Field name="hostname">
				{({ field, form }: FormFieldProps) => (
					<Stack>
						<FormFieldControl
							form={form}
							field={field}
							name={field.name}
							label={DICTIONARY.HOST_NAME}
							helper={DICTIONARY.HOST_NAME_DESCRIPTION}
							minW="xs"
							maxW="xl"
							isRequired
							labelHint={<InfoPopover header={DICTIONARY.HOST_NAME_POPOVER_HEADER} body={DICTIONARY.HOST_NAME_POPOVER_BODY} />}
							data-testid="site-definition-general-hostname"
						>
							<HostnameField
								allHostNames={allHostnames}
								hostname={field.value}
								setHostname={(newVal) => {
									form.setFieldValue(field.name, newVal);
									setSiteDefinitionFieldValue(field.name, newVal);
								}}
							/>
						</FormFieldControl>
						<Button
							variant="ghost"
							colorScheme="primary"
							size="xs"
							leftIcon={<Icon path={mdiPlus} />}
							alignSelf="flex-start"
							onClick={() => form.setFieldValue(field.name, `${field.value as string}|`)}
							data-testid="site-definition-general-add-hostname"
						>
							<T _str={DICTIONARY.ADD_HOSTNAME} />
						</Button>
					</Stack>
				)}
			</Field>
			<Field name="targetHostname">
				{({ field, form }: FormFieldProps) => (
					<Stack gap="0">
						<FormFieldControl
							form={form}
							field={field}
							name={field.name}
							label={DICTIONARY.TARGET_HOST_NAME}
							helper={DICTIONARY.HOST_NAME_DESCRIPTION}
							minW="xs"
							maxW="xl"
							labelHint={
								<InfoPopover header={DICTIONARY.TARGET_HOST_NAME_POPOVER_HEADER} body={DICTIONARY.TARGET_HOST_NAME_POPOVER_BODY} />
							}
						>
							<Input
								type="text"
								{...field}
								onChange={(e) => {
									setSiteDefinitionFieldValue(field.name, e.target.value);
								}}
							/>
						</FormFieldControl>
						{isTargetHostNameAlreadyExists(field.value) && (
							<Flex color="warning" alignItems="center" marginTop="2">
								<Icon path={mdiAlert} boxSize="4" color="warning" marginRight="1" />
								<T
									_str={DICTIONARY.TARGET_HOST_NAME_EXISTS}
									size="sm"
									siteDefName={isTargetHostNameAlreadyExists(field.value)?.sideDefName}
								/>
							</Flex>
						)}
					</Stack>
				)}
			</Field>
			<Field name="renderingHost">
				{({ field, form }: FormFieldProps) => (
					<FormFieldControl
						form={form}
						field={field}
						name={field.name}
						isInvalid={!!form.errors[field.name] && !!form.touched[field.name]}
						label={DICTIONARY.RENDERING_HOST}
						helper={DICTIONARY.RENDERING_HOST_DESCRIPTION}
						minW="xs"
						maxW="xl"
						isRequired
						data-testid="site-definition-general-renderinghost-dropdown"
					>
						<ReactSelect
							{...field}
							value={{ label: field.value, value: field.value } as SingleValue}
							selectedOptionStyle="check"
							options={renderingHostOptions}
							onChange={(newValue) => {
								if (!newValue || typeof newValue === 'string') {
									return;
								}

								form.setFieldValue(field.name, newValue.value);
								setSiteDefinitionFieldValue(field.name, newValue.value);
							}}
						/>
					</FormFieldControl>
				)}
			</Field>
			{/* TODO: uncomment when BE will add this field to Settings property */}
			{/* <Field name="validForEnvironment">
				{({ field, form }: FormFieldProps) => (
					<FormFieldControl
						form={form}
						field={field}
						name={field.name}
						label={DICTIONARY.VALID_FOR_DEVELOPMENT}
						helper={DICTIONARY.VALID_FOR_DEVELOPMENT_DESCRIPTION}
						minW="xs"
						maxW="xl"
						popoverHeader={<T _str={DICTIONARY.VALID_FOR_DEVELOPMENT_POPOVER_HEADER} />}
						popoverBody={<T _str={DICTIONARY.VALID_FOR_DEVELOPMENT_POPOVER_BODY} />}
					>
						<Input
							type="text"
							{...field}
							onChange={(e) => {
								field.onChange(e);
								setSiteDefinitionFieldValue(field.name, e.target.value);
							}}
						/>
					</FormFieldControl>
				)}
			</Field> */}
			{/* <Field name="linkProviderName">
				{({ field, form }: FormFieldProps) => (
					<FormFieldControl
						form={form}
						field={field}
						name={field.name}
						label={DICTIONARY.lINK_PROVIDER_NAME}
						helper={
							<>
								<Text as="span" marginRight="1">
									<T _str={DICTIONARY.LINK_PROVIDER_NAME_DESCRIPTION} />
								</Text>
								<Text as="span">
									<Link href="" isExternal>
										<T _str={DICTIONARY.LEARN_MORE} />
									</Link>
								</Text>
							</>
						}
						minW="xs"
						maxW="xl"
					>
						<Input
							type="text"
							{...field}
							onChange={(e) => {
								field.onChange(e);
								setSiteDefinitionFieldValue(field.name, e.target.value);
							}}
						/>
					</FormFieldControl>
				)}
			</Field> */}
			<Field name="linkableSite">
				{({ field, form }: FormFieldProps<boolean>) => (
					<FormFieldControl
						form={form}
						field={field}
						name={field.name}
						label={DICTIONARY.LINKABLE_SITE}
						helper={DICTIONARY.LINKABLE_SITE_DESCRIPTION}
						data-testid="site-definition-general-linkable-site"
					>
						<Switch
							name={field.name}
							isChecked={field.value}
							onChange={(e) => {
								field.onChange(e);
								setSiteDefinitionFieldValue(field.name, e.target.checked.toString());
							}}
						/>
					</FormFieldControl>
				)}
			</Field>
		</>
	);
};

import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useMemo } from 'react';
import { SitecoreUser } from '../auth/SitecoreUser';
import config from '../config/config';
import { useRememberTenantAndOrg } from './tenantParameter';
import { useGetTenantOrDefault } from './tenants';
import { ITenant } from './tenantsModel';

export const useResolveTenant = (onTenantResolved?: (tenant: ITenant | undefined) => void) => {
	const rememberOrgAndTenant = useRememberTenantAndOrg();
	const { user } = useAuth0<SitecoreUser>();
	const orgIdClaim = user?.['https://auth.sitecorecloud.io/claims/org_id'];
	const tenantNameClaim = user?.['https://auth.sitecorecloud.io/claims/tenant_name'];

	const error = useMemo(() => {
		if (!orgIdClaim) {
			return new Error('No organization found');
		}

		return undefined;
	}, [orgIdClaim]);

	const tenantResponse = useGetTenantOrDefault(
		{
			organizationId: orgIdClaim ?? '',
			tenantName: tenantNameClaim,
			systemId: config.inventory.xmSystemId,
		},
		!!orgIdClaim
	);

	const { data } = tenantResponse;

	useEffect(() => {
		const tenant: ITenant | undefined = data?.data[0];

		if (!tenant) {
			return;
		}

		rememberOrgAndTenant(orgIdClaim, tenant?.name);

		if (onTenantResolved) {
			onTenantResolved(tenant);
		}
	}, [data, onTenantResolved, orgIdClaim, rememberOrgAndTenant]);

	return {
		tenant: tenantResponse.data?.data[0],
		loading: tenantResponse.isLoading,
		error: error,
	};
};

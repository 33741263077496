import { Flex, Heading, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { TextWithCopy } from 'features/common/components/TextWithCopy';
import { GenaralTabFieldDetails } from 'features/siteSettings/components/GeneralTab/FieldDetails';
import { SiteWithPos } from 'features/sites/data/createSiteTypes';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';

export const NonSxaSiteGeneralTab: FC<{ site: SiteWithPos | undefined }> = ({ site }) => {
	return (
		<Flex direction="column" gap="6" mt="6">
			<Heading size="lg">
				<T _str={DICTIONARY.GENERAL} />
			</Heading>
			<Flex direction="column">
				<Text fontSize="sm" fontWeight="medium" color="neutral-fg">
					<T _str={DICTIONARY.ROOT_ITEM_ID} />
				</Text>
				<TextWithCopy textTransform="uppercase" text={site?.rootItemId} />
			</Flex>
			<GenaralTabFieldDetails name={DICTIONARY.ITEM_NAME} value={site?.rootItemName} />
			<GenaralTabFieldDetails name={DICTIONARY.ITEM_PATH} value={site?.rootItemPath} />
			<GenaralTabFieldDetails
				name={DICTIONARY.TEMPLATE}
				value={`${site?.rootItemTemplateName || ''} - \n${site?.rootItemTemplateId?.toUpperCase() || ''}`}
			/>
			<GenaralTabFieldDetails name={DICTIONARY.SITE_TYPE} value={DICTIONARY.TYPE_FILTER.NON_SXA_MANAGED} />
			<GenaralTabFieldDetails name={DICTIONARY.CREATED_BY} value={site?.rootItemCreatedBy} />
		</Flex>
	);
};

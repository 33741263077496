import { FC } from 'react';
import { useGetLanguages } from 'shared/languages/hooks';
import { MultiLanguageControlForm } from 'shared/languages/ui';
import { MultiLanguageFieldProps } from '../models';

export const MultiLanguagesField: FC<MultiLanguageFieldProps> = ({ shouldShowSupportedLanguages, ...props }) => {
	const { data: languages } = useGetLanguages(true, { supportedLanguages: shouldShowSupportedLanguages });

	return <MultiLanguageControlForm name="languages" languages={languages} {...props} />;
};

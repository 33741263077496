import { ToggleSide } from 'entities/toggle-switcher/ui';
import { PosModalProps } from 'features/point-of-sale/models';
import { mapSiteResponseToInput } from 'features/point-of-sale/models/save-pos-input';
import { useCallback, useState } from 'react';
import { ENDPOINTS } from 'shared/endpoints';
import { EditHostPOSInput, SiteResponse } from 'shared/models';
import { usePatch } from 'shared/react-query-wrappers/hooks';

export const useAssignPosModal = (props: PosModalProps) => {
	const { onClose, id: hostId, siteId, analyticsIdentifiers, siteLanguage } = props;
	const [side, setSide] = useState<ToggleSide>('left');

	const { mutateAsync, isPending } = usePatch<SiteResponse, EditHostPOSInput>({
		queryKey: { url: ENDPOINTS.HOST, params: { siteId, hostId } },
		refetchQueriesByKey: [{ url: ENDPOINTS.SITE, params: { id: siteId }, shouldWait: true }, { url: ENDPOINTS.SITES }],
	});

	console.log(siteId);

	const assign = useCallback(
		async (posName: string) => {
			const input = mapSiteResponseToInput(analyticsIdentifiers);
			const existingPos = input.analyticsIdentifiers.find((pos) => pos.language === siteLanguage);

			if (existingPos) {
				existingPos.name = posName;
			} else {
				input.analyticsIdentifiers.push({ language: siteLanguage, name: posName });
			}

			await mutateAsync(input);
			onClose();
		},
		[analyticsIdentifiers, mutateAsync, onClose, siteLanguage]
	);

	const onCloseModal = useCallback(() => {
		onClose();
		setSide('left');
	}, [onClose]);

	return { assign, side, setSide, isPending, onCloseModal };
};

import { Button, ButtonGroup, ButtonGroupProps, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';

export type ToggleSide = 'left' | 'right';

export type ToggleProps = {
	side: ToggleSide;
	setSide: (side: ToggleSide) => void;
	leftText: string;
	rightText: string;
} & ButtonGroupProps;

export const Toggle: FC<ToggleProps> = ({ leftText, rightText, setSide, side, ...props }) => {
	return (
		<ButtonGroup
			{...props}
			variant="toggle"
			size="sm"
			borderRadius="full"
			border="1px"
			borderColor="chakra-border-color"
			background="chakra-body-bg"
			padding="1"
			spacing="0"
		>
			<Button isActive={side === 'left'} size="sm" borderRadius="full" onClick={() => setSide('left')} data-testid="toggle-left">
				<Text variant="strong">
					<T _str={leftText} />
				</Text>
			</Button>
			<Button isActive={side === 'right'} size="sm" borderRadius="full" onClick={() => setSide('right')} data-testid="toggle-right">
				<Text variant="strong">
					<T _str={rightText} />
				</Text>
			</Button>
		</ButtonGroup>
	);
};

import { useCallback } from 'react';
import {
	CREATE_PAGE_ERROR_EVENT_NAME,
	CREATE_PAGE_START_EVENT_NAME,
	CREATE_PAGE_SUCCESS_EVENT_NAME,
	CreatePageErrorEvent,
	CreatePageStartEvent,
	CreatePageSuccessEvent,
} from 'shared/site-structure';
import { isSameGuid } from 'shared/utils';
import { useEventListener } from 'usehooks-ts';
import { PageStructureEventsAllProps } from 'widgets/site-structure/hooks/use-page-structure-events';
import { CreatingState } from 'widgets/site-structure/models/props';

export const useCreateEvents = ({ documentRef, setAnimationState, page, setIsOpen, children, refetchCurrentBranch }: PageStructureEventsAllProps) => {
	const onCreatePageStart = useCallback(
		(event: CustomEvent<CreatePageStartEvent>) => {
			let creating: CreatingState | undefined;

			if (event.detail.direction === 'inside' && isSameGuid(page?.id, event.detail.pageId)) {
				creating = {
					direction: event.detail.direction,
					name: event.detail.name,
				};
			} else if (
				(event.detail.direction === 'before' || event.detail.direction === 'after') &&
				children.find((child) => isSameGuid(child.id, event.detail.pageId))
			) {
				creating = {
					id: event.detail.pageId,
					direction: event.detail.direction,
					name: event.detail.name,
				};
			}

			if (creating) {
				setAnimationState((animationState) => ({
					...animationState,
					creating,
				}));
			}
		},
		[children, page?.id, setAnimationState]
	);

	const onCreatePageSuccess = useCallback(
		(event: CustomEvent<CreatePageSuccessEvent>) => {
			if (event.detail.direction === 'inside' && isSameGuid(page?.id, event.detail.pageId)) {
				void refetchCurrentBranch().then(() => {
					setAnimationState((animationState) => ({
						...animationState,
						creating: undefined,
					}));
					setIsOpen?.(true); // since the changing of isOpen sends the request to update the children, we need to set it to true to reflect the new children
				});
			} else if (
				(event.detail.direction === 'before' || event.detail.direction === 'after') &&
				children.find((child) => isSameGuid(child.id, event.detail.pageId))
			) {
				void refetchCurrentBranch().then(() => {
					setAnimationState((animationState) => ({
						...animationState,
						creating: undefined,
					}));
				});
			}
		},
		[children, page?.id, refetchCurrentBranch, setAnimationState, setIsOpen]
	);

	const onCreatePageError = useCallback(
		(event: CustomEvent<CreatePageErrorEvent>) => {
			if (isSameGuid(page?.id, event.detail.pageId) || children.find((child) => isSameGuid(child.id, event.detail.pageId))) {
				setAnimationState((animationState) => ({
					...animationState,
					creating: undefined,
				}));
			}
		},
		[children, page?.id, setAnimationState]
	);

	useEventListener(CREATE_PAGE_START_EVENT_NAME, onCreatePageStart, documentRef);
	useEventListener(CREATE_PAGE_SUCCESS_EVENT_NAME, onCreatePageSuccess, documentRef);
	useEventListener(CREATE_PAGE_ERROR_EVENT_NAME, onCreatePageError, documentRef);
};

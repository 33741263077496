import { CircularProgress, useToast } from '@chakra-ui/react';
import { useCdpTenant } from 'features/common/tenants/CdpTenantProvider';
import { useTranslate } from 'features/common/utils/translateUtils';
import { DICTIONARY } from 'shared/constants';
import { usePosSynchronization } from 'features/pos/utils/usePosSynchronization';
import { FC, useCallback, useState } from 'react';
import { MissingPosBox } from './MissingPosBox';
import { SynchronizedPosBox } from './SynchronizedPosBox';

export type SynchonizationState = 'idle' | 'synchronizing' | 'done';

export const SynchronizationBox: FC = () => {
	const toast = useToast();
	const translate = useTranslate();
	const { isEnabled } = useCdpTenant();

	const [state, setState] = useState<SynchonizationState>('idle');
	const { synchronizePos, isSynchronized, isVerifying } = usePosSynchronization({
		onSuccess: () => {
			setState('done');
		},
		onError: () => {
			toast({
				status: 'error',
				title: translate(DICTIONARY.SYNCHRONIZATION_FAILED),
				description: translate(DICTIONARY.SITECORE_PERSONALIZE_SYNCHRONIZATION_ERROR),
			});
			setState('idle');
		},
	});

	const onClick = useCallback(() => {
		setState('synchronizing');
		void synchronizePos();
	}, [synchronizePos]);

	if (isVerifying) {
		return <CircularProgress isIndeterminate size={10} capIsRound color="primary" trackColor="neutral-color.200" />;
	}

	if (isSynchronized || state == 'done') {
		return <SynchronizedPosBox />;
	}

	return <MissingPosBox state={state} onClick={onClick} disabled={!isEnabled} />;
};

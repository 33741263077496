import { useToast } from '@chakra-ui/react';
import { FormikWithCallbacks } from 'features/common/forms/FormikWithCallbacks';
import { useTranslate } from 'features/common/utils/translateUtils';
import { DICTIONARY } from 'shared/constants';
import { RenameForm } from 'features/sites/components/rename/RenameSiteModal';
import { renameValidationSchema } from 'features/sites/utils/siteValidationSchema';
import { useCheckSiteUniqueness } from 'features/sites/utils/useCheckSiteUniqueness';
import { FormikHelpers, FormikProps } from 'formik';
import { ChangeEvent, useCallback } from 'react';

export type RenameFormProps = {
	siteName?: string;
	initialNewName?: string;
	onDuplicate: (newName: string) => Promise<boolean>;
	onReset?: (values: RenameForm, formikHelpers: FormikHelpers<RenameForm>) => void;
	onHandleChange?: (e: ChangeEvent) => void;
	children?: ((props: FormikProps<RenameForm>) => React.ReactNode) | React.ReactNode;
};

export const DuplicateFormHeader = ({ children, siteName = '', initialNewName = '', onDuplicate, onHandleChange, onReset }: RenameFormProps) => {
	const toast = useToast();
	const t = useTranslate();
	const validateSiteUniqueness = useCheckSiteUniqueness();

	const onSubmit = useCallback(
		async (formValues: RenameForm) => {
			const success = await onDuplicate(formValues.newName);

			if (!success) {
				toast({
					status: 'error',
					title: t(`${DICTIONARY.DUPLICATE_SITE_ERROR[0]} "${siteName ?? ''}" ${DICTIONARY.DUPLICATE_SITE_ERROR[1]}`),
				});

				return;
			}
		},
		[onDuplicate, siteName, t, toast]
	);

	return (
		<FormikWithCallbacks<RenameForm>
			initialValues={{
				newName: initialNewName,
			}}
			enableReinitialize={true}
			onSubmit={onSubmit}
			validateOnChange={false}
			validateOnBlur={true}
			validateOnMount={false}
			validationSchema={renameValidationSchema}
			validate={validateSiteUniqueness}
			onHandleChange={onHandleChange}
			onReset={onReset}
		>
			{children}
		</FormikWithCallbacks>
	);
};

import { Alert, AlertDescription, AlertIcon, Box, Flex, Heading, Link, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { TextWithCopy } from 'features/common/components/TextWithCopy';
import { edgeGraphQlUrl } from 'features/common/config/envConfig';
import { useCdpTenant } from 'features/common/tenants/CdpTenantProvider';
import { PosNotAvailableText } from 'features/siteSettings/components/DeveloperSettings/PosNotAvailableText';
import { useGetSite } from 'features/sites/utils/useGetSite';
import { FC, useMemo } from 'react';
import { DEVELOPER_SETTINGS_LINKS, DICTIONARY } from 'shared/constants';
import { GenerateApiKey } from './GenerateApiKey';

export const DeveloperSettings: FC = () => {
	const { isEnabled, tenant } = useCdpTenant();
	const { site } = useGetSite();
	const cdpPos: string = useMemo(() => {
		if (!site || site.siteTypeByCollection === 'nonsxa') {
			return '';
		}

		const record: Record<string, string> = {};

		for (const pos of site.posMappings) {
			record[pos.language] = pos.name;
		}

		return JSON.stringify(record);
	}, [site]);

	return (
		<Flex direction="column" gap="6" mt="6" data-testid="DataTable">
			<Heading size="lg">
				<T _str={DICTIONARY.ENVIRONMENT_VARIABLES} />
			</Heading>
			<Alert status="info" borderRadius="md">
				<AlertIcon />
				<AlertDescription>
					<Text>
						<T
							_str={DICTIONARY.DEVELOPER_SETTINGS_ALERT.MESSAGE}
							olderLink={
								<Link as="a" href={DEVELOPER_SETTINGS_LINKS.OLDER}>
									<T _str={DICTIONARY.DEVELOPER_SETTINGS_ALERT.ENVIRONMENT_VARIABLES} />
								</Link>
							}
							newerLink={
								<Link as="a" href={DEVELOPER_SETTINGS_LINKS.NEWER}>
									<T _str={DICTIONARY.DEVELOPER_SETTINGS_ALERT.ENVIRONMENT_VARIABLES} />
								</Link>
							}
						/>
					</Text>
				</AlertDescription>
			</Alert>
			{isEnabled && (
				<Box>
					<TextWithCopy fontWeight="semibold" text="NEXT_PUBLIC_CDP_TARGET_URL" />
					<TextWithCopy text={tenant?.apiUrl} />
				</Box>
			)}
			{isEnabled && (
				<Box>
					<TextWithCopy fontWeight="semibold" text="NEXT_PUBLIC_CDP_CLIENT_KEY" />
					<TextWithCopy text={tenant?.clientKey} />
				</Box>
			)}
			<Box>
				<TextWithCopy fontWeight="semibold" text="JSS_APP_NAME" />
				<TextWithCopy text={site?.appName} />
			</Box>
			{isEnabled && (
				<Box>
					<TextWithCopy fontWeight="semibold" text="NEXT_PUBLIC_CDP_POINTOFSALE" />
					{site?.siteTypeByCollection === 'sxa' ? <TextWithCopy text={cdpPos} /> : <PosNotAvailableText />}
				</Box>
			)}
			<Box>
				<TextWithCopy fontWeight="semibold" text="GRAPH_QL_ENDPOINT" />
				<TextWithCopy text={edgeGraphQlUrl} />
			</Box>
			<Box>
				<TextWithCopy fontWeight="semibold" as="span" text="SITECORE_API_KEY" />
				<GenerateApiKey />
			</Box>
		</Flex>
	);
};

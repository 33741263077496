import { Heading, Link } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { format } from 'date-fns';
import { PageBodyContentSection, SectionDataRecord } from 'entities/page-layout/ui';
import { behindFeature, useFeatures } from 'features/common/featureFlags/featureFlags';
import { RemoveSiteButton } from 'features/remove-site/ui';
import { SitePageOutlet } from 'pages/site/models';
import { useOutletContext } from 'react-router';
import { DATE_FORMAT, DICTIONARY, EMPTY_VALUE, SITE_SHARING_DOC } from 'shared/constants';
import { SiteSettingsGeneralSectionForm } from 'widgets/site-settings-update-form/ui';
import { SiteSharingSectionForm } from 'widgets/site-sharing/ui';

export const SiteGeneralPage = () => {
	const { site, locationOrigin, hosts } = useOutletContext<SitePageOutlet>();
	const { showSharedSite } = useFeatures();
	const createdOn = site?.created ? format(new Date(site.created), DATE_FORMAT) : EMPTY_VALUE;

	return (
		<>
			<Heading size="lg">
				<T _str={DICTIONARY.GENERAL} />
			</Heading>
			<PageBodyContentSection title={DICTIONARY.BASIC_INFORMATION}>
				{site && <SiteSettingsGeneralSectionForm {...site} />}
			</PageBodyContentSection>
			{showSharedSite && (
				<PageBodyContentSection
					title={DICTIONARY.SITE_SHARING}
					description={
						<T
							_str={DICTIONARY.SITE_SHARING_DESCRIPTION}
							collectionName={site?.collectionName || ''}
							link={
								<Link href={SITE_SHARING_DOC} target="_blank">
									<T _str={DICTIONARY.LEARN_MORE} />
								</Link>
							}
						/>
					}
				>
					<SiteSharingSectionForm isShared={site?.properties?.sharedSite === 'true'} />
				</PageBodyContentSection>
			)}
			<PageBodyContentSection title={DICTIONARY.DANGER_ZONE}>
				{site && (
					<RemoveSiteButton
						id={site?.id}
						name={site?.displayName || site?.name}
						collectionId={site?.collectionId}
						collectionName={site?.collectionName}
						isShared={site?.properties?.sharedSite === 'true'}
						hosts={hosts?.map((host) => host?.name) || []}
						locationOrigin={locationOrigin}
					/>
				)}
			</PageBodyContentSection>
			<PageBodyContentSection title={DICTIONARY.DETAILS}>
				<SectionDataRecord header={DICTIONARY.CREATED_BY} value={site?.createdBy || EMPTY_VALUE} />
				<SectionDataRecord header={DICTIONARY.CREATED_ON} value={createdOn} />
				<SectionDataRecord header={DICTIONARY.COLLECTION} value={site?.collectionName || EMPTY_VALUE} />
				<SectionDataRecord header={DICTIONARY.TEMPLATE} value={site?.siteTemplateInfo?.name || EMPTY_VALUE} />
				<SectionDataRecord header={DICTIONARY.ITEM_PATH} value={site?.properties?.rootPath || site?.itemPath || EMPTY_VALUE} />
			</PageBodyContentSection>
		</>
	);
};

export const SiteGeneralPageFeature = behindFeature('showSitesGrouping')(SiteGeneralPage);

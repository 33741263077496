import { Box, Flex, Heading } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { useFeatures } from 'features/common/featureFlags/featureFlags';
import { FC } from 'react';
import { ScLaunchpadGroup as Group } from '../data/types';
import styles from './ScLaunchpad.module.css';
import { ScLaunchpadItem } from './ScLaunchpadItem';

export const ScLaunchpadGroup: FC<Group> = ({ name, groupItems, colorScheme }) => {
	const { showFormsRedirect, showAnalyticsRedirect } = useFeatures();

	return (
		<Box className={styles['sc-launchpad-group']}>
			<Heading size="md" mb="4">
				<T _str={name} />
			</Heading>
			<Flex flexWrap="wrap" gap="4">
				{groupItems.map((item) => {
					if ((item.name === 'Forms' && !showFormsRedirect) || (item.name === 'Analytics' && !showAnalyticsRedirect)) {
						return false;
					} else {
						return <ScLaunchpadItem key={item.name} {...item} colorScheme={colorScheme} />;
					}
				})}
			</Flex>
		</Box>
	);
};

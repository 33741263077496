import { useCallback } from 'react';
import {
	DELETE_PAGE_ERROR_EVENT_NAME,
	DELETE_PAGE_START_EVENT_NAME,
	DELETE_PAGE_SUCCESS_EVENT_NAME,
	DeletePageErrorEvent,
	DeletePageStartEvent,
	DeletePageSuccessEvent,
} from 'shared/site-structure';
import { isSameGuid } from 'shared/utils';
import { useEventListener } from 'usehooks-ts';
import { PageStructureEventsAllProps } from 'widgets/site-structure/hooks/use-page-structure-events';

export const useDeleteEvents = ({
	documentRef,
	refetchIfNoParent,
	refetchIfParent,
	setAnimationState,
	page,
	parentId,
}: PageStructureEventsAllProps) => {
	const onDeletePageStart = useCallback(
		(event: CustomEvent<DeletePageStartEvent>) => {
			if (isSameGuid(page?.id, event.detail.pageId)) {
				setAnimationState((animationState) => ({ ...animationState, isDeleting: true }));
			}
		},
		[setAnimationState, page?.id]
	);

	const onDeletePageSuccess = useCallback(
		(event: CustomEvent<DeletePageSuccessEvent>) => {
			if (isSameGuid(page?.id, event.detail.pageId)) {
				if (parentId) {
					void refetchIfParent();
				} else {
					void refetchIfNoParent();
				}
			}
		},
		[page?.id, parentId, refetchIfParent, refetchIfNoParent]
	);

	const onDeletePageError = useCallback(
		(event: CustomEvent<DeletePageErrorEvent>) => {
			if (isSameGuid(page?.id, event.detail.pageId)) {
				setAnimationState((animationState) => ({ ...animationState, isDeleting: false }));
			}
		},
		[page?.id, setAnimationState]
	);

	useEventListener(DELETE_PAGE_START_EVENT_NAME, onDeletePageStart, documentRef);
	useEventListener(DELETE_PAGE_SUCCESS_EVENT_NAME, onDeletePageSuccess, documentRef);
	useEventListener(DELETE_PAGE_ERROR_EVENT_NAME, onDeletePageError, documentRef);
};

import { LanguageIso, SiteInfo, SiteJobViewModel } from 'features/sites/data/createSiteTypes';
import { DateTime } from 'luxon';
import { DATE_FORMAT } from '../constants';
import { Item, MillerItemType, DetailedItem, ItemViewModel } from '../models';
import _ from 'lodash';

export const formatSites = (sites: SiteInfo[]): MillerItemType[] => {
	return sites.map(
		(site: SiteInfo) =>
			({
				id: site.rootItemId || site.id,
				name: site.name,
				hasChildren: true,
				type: 'site',
				siteType: 'site',
				languages: site.languages,
				thumbnailUrl: site.imageSrc,
				siteCollectionName: site.siteCollectionName,
				siteTypeByCollection: site.siteCollectionId ? 'sxa' : 'nonsxa',
			} as MillerItemType)
	);
};

export const formatJobs = (jobs: SiteJobViewModel[]): MillerItemType[] => {
	return jobs.map(
		(job: SiteJobViewModel) => ({ id: job.handle, name: job.siteName, hasChildren: false, type: 'site', siteType: 'job' } as MillerItemType)
	);
};

export const collectLanguagesIso = (items: LanguageIso[] | undefined) => {
	if (!items || items.length === 0) {
		return [];
	}

	const languages = items.map((item) => item.language.iso);

	return languages.filter((element, index) => languages.indexOf(element) === index) as string[];
};

export const formatItem = (item: Item, type: 'folder' | 'page') => {
	return {
		id: item.itemId,
		name: item.displayName || item.name,
		hasChildren: item.hasFolderChildren || item.hasPageChildren,
		languages: collectLanguagesIso(item.versions),
		workflowState: item.workflow?.workflowState && {
			isFinal: item.workflow.workflowState?.final,
			name: item.workflow.workflowState?.displayName,
		},
		type: 'item',
		scItemType: type,
		thumbnailUrl: item.thumbnailUrl,
	} as MillerItemType;
};

export const formatItems = (items: DetailedItem): MillerItemType[] => {
	const folderMillerItems = items.folderChildren.nodes.filter((item) => !item.hasPresentation).map((item) => formatItem(item, 'folder'));
	const pagesMillerItems = items.pageChildren.nodes.map((item) => formatItem(item, 'page'));

	return _.orderBy(folderMillerItems.concat(pagesMillerItems), (item) => item.name);
};

export const formatDetailedItem = (detailedItem: DetailedItem): ItemViewModel => {
	let lastLangVersion: DetailedItem | undefined = undefined;

	if (detailedItem.versions && detailedItem.versions.length > 0) {
		lastLangVersion = [...detailedItem.versions].sort((a, b) => b.version - a.version)[0];
	}

	return {
		itemId: detailedItem.itemId.toUpperCase(),
		name: detailedItem.name,
		displayName: detailedItem.displayName,
		path: detailedItem.path,
		templateId: detailedItem.template.templateId.toUpperCase(),
		templateName: detailedItem.template.name,
		templatePath: detailedItem.template.fullName,
		itemOwner: detailedItem.createdBy?.value,
		dateOfCreation: detailedItem.createdAt ? DateTime.fromISO(detailedItem.createdAt.value).toFormat(DATE_FORMAT) : undefined,
		updatedBy: detailedItem.updatedBy?.value,
		dateOfUpdate: detailedItem.updatedAt ? DateTime.fromISO(detailedItem.updatedAt.value).toFormat(DATE_FORMAT) : undefined,
		thumbnailUrl: detailedItem.thumbnailUrl,
		isFinalWorkflowState: lastLangVersion?.workflow?.workflowState?.final,
		hasPresentation: lastLangVersion?.hasPresentation ?? false,
		workflowStateName: lastLangVersion?.workflow?.workflowState?.displayName,
	};
};

import { Flex, FlexProps } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';

export const PageBodyLeftBar: FC<PropsWithChildren<FlexProps>> = ({ children, ...props }) => {
	return (
		<Flex {...props} px="2" py="6" direction="column">
			{children}
		</Flex>
	);
};

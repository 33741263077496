import { Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { SiteInfo, SiteJobViewModel } from '../data/createSiteTypes';
import { useOpenSiteEditor } from '../utils/useOpenSiteEditor';
import { FilterResult } from '../utils/useSiteFilter';
import { NoSitesFound } from './NoSitesFound';
import { SiteCard } from './SiteCard';
import { SiteJobCard } from './SiteJobCard';

type SitesListProps = {
	sites: SiteInfo[];
	jobs: SiteJobViewModel[];
} & Pick<FilterResult, 'isFiltering' | 'resetFilters'>;

export const SitesList: FC<SitesListProps> = ({ sites, jobs, resetFilters, isFiltering }) => {
	const openSiteEditor = useOpenSiteEditor();

	if (isFiltering && !sites.length) {
		return <NoSitesFound clearFilter={resetFilters} />;
	}

	return (
		<Flex flexWrap="wrap" data-testid="SitesList" gap="4">
			{jobs.map((job) => (
				<SiteJobCard key={job.siteName} name={job.siteName}></SiteJobCard>
			))}
			{sites.map((item) => (
				<SiteCard key={item.id} {...item} openSiteEditor={openSiteEditor} />
			))}
		</Flex>
	);
};

import { CollectionItemResponse, UseGetCollections } from 'features/create-site/models';
import { useMemo } from 'react';
import { ENDPOINTS } from 'shared/endpoints';
import { useSimpleGet } from 'shared/react-query-wrappers/hooks';

export const useGetCollections: UseGetCollections = () => {
	const { data } = useSimpleGet<CollectionItemResponse[]>(ENDPOINTS.COLLECTIONS, true);

	return useMemo(() => (data || []).map((item) => ({ value: item.id, label: item.displayName || item.name })), [data]);
};

import { Input, Switch } from '@chakra-ui/react';
import { FormFieldProps } from 'entities/form-field-control/models';
import { FormFieldControl } from 'entities/form-field-control/ui';
import { Field } from 'formik';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { useSettingsSectionBody } from 'widgets/site-settings-update-form/hooks/use-settings-section-body';
import { setSiteSettingsFieldValue } from 'widgets/site-settings-update-form/models/event';

/**
 * @deprecated Update is blocked for EA
 */
export type SettingsSectionBodyProps = {
	resetForm: () => void;
	submitForm: (() => Promise<void>) & (() => Promise<any>);
};

/**
 * @deprecated Update is blocked for EA
 */
export const SettingsSectionBody: FC<SettingsSectionBodyProps> = (props) => {
	useSettingsSectionBody(props);

	return (
		<>
			<Field name="hostname">
				{({ field, form }: FormFieldProps) => (
					<FormFieldControl
						form={form}
						field={field}
						name={field.name}
						label={DICTIONARY.HOST_NAME}
						helper={DICTIONARY.HOST_NAME_DESCRIPTION}
						minW="xs"
						maxW="xl"
						isRequired
					>
						<Input
							type="text"
							{...field}
							onChange={(e) => {
								field.onChange(e);
								setSiteSettingsFieldValue(field.name, e.target.value);
							}}
						/>
					</FormFieldControl>
				)}
			</Field>
			<Field name="targetHostname">
				{({ field, form }: FormFieldProps) => (
					<FormFieldControl
						form={form}
						field={field}
						name={field.name}
						label={DICTIONARY.TARGET_HOST_NAME}
						helper={DICTIONARY.HOST_NAME_DESCRIPTION}
						minW="xs"
						maxW="xl"
					>
						<Input
							type="text"
							{...field}
							onChange={(e) => {
								field.onChange(e);
								setSiteSettingsFieldValue(field.name, e.target.value);
							}}
						/>
					</FormFieldControl>
				)}
			</Field>
			<Field name="linkableSite">
				{({ field, form }: FormFieldProps<boolean>) => (
					<FormFieldControl
						form={form}
						field={field}
						name={field.name}
						label={DICTIONARY.LINKABLE_SITE}
						helper={DICTIONARY.LINKABLE_SITE_DESCRIPTION}
					>
						<Switch
							name={field.name}
							isChecked={field.value}
							onChange={(e) => {
								field.onChange(e);
								setSiteSettingsFieldValue(field.name, e.target.checked.toString());
							}}
						/>
					</FormFieldControl>
				)}
			</Field>
		</>
	);
};

import { PortfolioContextProps, SiteData } from 'pages/portfolio/models';
import { useOutletContext } from 'react-router';
import { ENDPOINTS } from 'shared/endpoints';
import { useGet } from 'shared/react-query-wrappers/hooks';

export const usePortfolioSitesPageData = () => {
	const { collection } = useOutletContext<PortfolioContextProps>();
	const { data, isLoading } = useGet<SiteData[]>({
		queryKey: { url: ENDPOINTS.COLLECTION_SITES, params: { collectionId: collection?.id ?? '' } },
		enabled: !!collection?.id,
	});

	return { sites: data, collectionId: collection?.id, isLoading };
};

import { Box, Flex } from '@chakra-ui/react';
import { mdiOpenInNew } from '@mdi/js';
import { Icon } from 'entities/icon/ui';
import { useMediaLightbox } from 'entities/media-lightbox/hooks';
import { MediaLightboxProps } from 'entities/media-lightbox/models';
import { FC, PropsWithChildren } from 'react';
import { Lightbox } from 'yet-another-react-lightbox';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import 'yet-another-react-lightbox/plugins/captions.css';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/styles.css';

export const MediaLightbox: FC<PropsWithChildren<MediaLightboxProps>> = ({ children, imageUrl, title, alt, overlayStyles }) => {
	const slides = [{ src: imageUrl, alt, title }];

	const { handleKeyDown, handleOverlayClick, setHover, hover, openLightbox, setOpenLightbox } = useMediaLightbox(imageUrl);

	return (
		<Box onKeyDown={handleKeyDown} onClick={handleOverlayClick}>
			{children}
			<Flex
				justify="center"
				alignItems="center"
				backgroundColor={hover ? 'blackAlpha.600' : ''}
				onMouseEnter={() => imageUrl && setHover(true)}
				onMouseLeave={() => imageUrl && setHover(false)}
				data-testid="image-overlay-area"
				{...overlayStyles}
			>
				{hover && <Icon path={mdiOpenInNew} boxSize="4" color="white" data-testid="image-overlay-area-icon" />}
			</Flex>
			{openLightbox && (
				<Lightbox
					carousel={{ finite: slides.length <= 1 }}
					render={{
						buttonPrev: slides.length <= 1 ? () => null : undefined,
						buttonNext: slides.length <= 1 ? () => null : undefined,
					}}
					open={openLightbox}
					close={() => setOpenLightbox(false)}
					slides={slides}
					plugins={[Captions, Zoom]}
					zoom={{ scrollToZoom: true, maxZoomPixelRatio: 1.5 }}
				/>
			)}
		</Box>
	);
};

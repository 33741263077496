import config from 'features/common/config/config';
import { useCdpTenant } from 'features/common/tenants/CdpTenantProvider';
import { PosResponse } from 'features/point-of-sale/models';
import _ from 'lodash';
import { useMemo } from 'react';
import { useGet } from 'shared/react-query-wrappers/hooks';

export const useGetAllPoS = () => {
	const { state, tenant: cdpTenant } = useCdpTenant();
	const getPostUrl = cdpTenant ? config.cdp.get_all_pos.url(cdpTenant?.apiUrl) : '';

	const { data, isLoading, refetch } = useGet<PosResponse>({ queryKey: { url: getPostUrl }, enabled: state === 'resolved' });

	const posList = useMemo(() => {
		return _.sortBy(data ?? [], (pos) => pos.name.toLowerCase());
	}, [data]);

	return { posList, isLoading, refetch };
};

import { useMemo } from 'react';
import { useXMTenant } from './xmTenantContext';

export const useGetThumbnail = (thumbnailUrl: string | undefined, isDefaultUrl = true): string | undefined => {
	const { tenant } = useXMTenant(true);

	return useMemo(() => {
		if (tenant && tenant.url && thumbnailUrl) {
			return tenant.url + thumbnailUrl;
		}

		if (isDefaultUrl) {
			return '/bg-panel.png';
		}

		return undefined;
	}, [tenant, thumbnailUrl, isDefaultUrl]);
};

import { Skeleton, Stack } from '@chakra-ui/react';
import { FC } from 'react';

export const TreeLoadingSkeleton: FC = () => {
	return (
		<Stack data-testid="treeLoadingSkeleton">
			<Skeleton key={1} css={{ height: 32, margin: 4 }} />
			<Skeleton key={2} css={{ height: 32, margin: 4 }} />
			<Skeleton key={3} css={{ height: 32, margin: 4 }} />
		</Stack>
	);
};

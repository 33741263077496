import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Box, Stack } from '@chakra-ui/react';
import { Loading } from 'features/common/components/Loading';
import { MissingPosNotification } from 'features/pos/components/MissingPosNotification';
import { SettingsLayout } from 'features/settings/components/SettingsLayout';
import { SiteSettingsLayout } from 'features/siteSettings/components/SiteSettingsLayout';
import { RemoveSiteProvider } from 'features/sites/components/RemoveSiteContext';
import { SiteAnalyticsAlertProvider } from 'features/sites/components/SiteAnalyticsAlert';
import { XMTenantSaveSiteLayout } from 'features/sites/components/XMTenantSaveSiteLayout';
import { DuplicateSiteProvider } from 'features/sites/components/duplicate/DuplicateSiteProvider';
import { RenameSiteProvider } from 'features/sites/components/rename/RenameSiteProvider';
import { ScLaunchpadGrid } from 'features/tools/components/ScLaunchpadGrid';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Layout, LayoutHeader, LayoutMain } from 'shared/layout/ui';
import { TopBar } from 'widgets/topbar/ui';
import { XMTenantErrorHandler } from 'widgets/xmTenantErrorHandler';
import { NotFound } from './NotFound';

export const MainContainer = withAuthenticationRequired(
	() => {
		return (
			<BrowserRouter>
				<Layout>
					<LayoutHeader>
						<TopBar />
					</LayoutHeader>
					<LayoutMain disablePadding overflow="hidden" height="100%">
						<XMTenantErrorHandler>
							<MissingPosNotification />
							<SiteAnalyticsAlertProvider>
								<RenameSiteProvider>
									<RemoveSiteProvider>
										<DuplicateSiteProvider>
											<Stack height="100%" flexGrow={1}>
												<Box flexGrow={1} height="100%" overflow="auto" backgroundColor="chakra-subtle-bg">
													<Routes>
														<Route path="/" element={<XMTenantSaveSiteLayout />} />
														<Route path="/sites" element={<XMTenantSaveSiteLayout />} />
														<Route path="/tools" element={<ScLaunchpadGrid />} />
														<Route path="/settings" element={<SettingsLayout />} />
														<Route path="/sites/:sitename/*" element={<SiteSettingsLayout />} />
														<Route path="/sites/:sitename/:tabname/*" element={<SiteSettingsLayout />} />
														<Route path="*" element={<NotFound />} />
													</Routes>
												</Box>
											</Stack>
										</DuplicateSiteProvider>
									</RemoveSiteProvider>
								</RenameSiteProvider>
							</SiteAnalyticsAlertProvider>
						</XMTenantErrorHandler>
					</LayoutMain>
				</Layout>
			</BrowserRouter>
		);
	},
	{
		onRedirecting: () => <Loading />,
	}
);

import { CollectionResponse } from 'features/collections/models';
import { useFeatures } from 'features/common/featureFlags/featureFlags';
import { UseSiteDetailsForm } from 'features/create-site/models';
import { useMemo } from 'react';
import { SELECTED_LANGUAGE } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { SiteResponse } from 'shared/models';
import { useGet } from 'shared/react-query-wrappers/hooks';
import { isSameGuid } from 'shared/utils';
import { siteDetailsValidationSchema } from 'shared/validations';
import { ValidationError } from 'yup';

export type QueryData = { data: Array<{ name: string; displayName: string }> };

export const useSiteDetailsForm: UseSiteDetailsForm = (collectionId, siteDetailsFormData) => {
	const { showManagedLanguages } = useFeatures();

	const initialValues = useMemo(
		() => ({
			displayName: siteDetailsFormData.displayName,
			description: siteDetailsFormData.description,
			collection: collectionId || siteDetailsFormData.collection?.value || '',
			language: siteDetailsFormData.language?.value || showManagedLanguages ? SELECTED_LANGUAGE.value : '',
			languages: siteDetailsFormData.languages || [SELECTED_LANGUAGE],
		}),
		[collectionId, showManagedLanguages, siteDetailsFormData]
	);

	const { data: sites, isLoading } = useGet<SiteResponse[]>({ queryKey: { url: ENDPOINTS.SITES } });
	const { data: collections, isLoading: isCollectionsLoading } = useGet<CollectionResponse[]>({ queryKey: { url: ENDPOINTS.COLLECTIONS } });
	const siteNames = useMemo(() => {
		let names: string[] = [];
		let displayNames: string[] = [];

		if (sites && !isLoading) {
			names = sites.map(({ name }) => name);
			displayNames = sites
				.filter((f) => isSameGuid(f.collectionId, siteDetailsFormData.collection?.value || collectionId))
				.map(({ displayName }) => displayName);
		}

		return { names, displayNames };
	}, [collectionId, isLoading, siteDetailsFormData.collection?.value, sites]);
	const collectionSystemNames = useMemo(() => {
		return collections && !isCollectionsLoading ? collections.map(({ name, displayName }) => ({ name, displayName })) : [];
	}, [isCollectionsLoading, collections]);

	const initialErrors = useMemo(() => {
		const errors: Record<string, string> = {};

		siteDetailsValidationSchema(siteNames, collectionSystemNames, siteDetailsFormData.collection, collectionId, showManagedLanguages)
			.validate(initialValues, { abortEarly: false })
			.catch((err: ValidationError) => err.inner.forEach((e: ValidationError) => (errors[e?.path || ''] = e.message)));

		return errors;
	}, [siteNames, collectionSystemNames, siteDetailsFormData.collection, collectionId, showManagedLanguages, initialValues]);

	return { initialValues, initialErrors, siteNames, collectionSystemNames };
};

import { useQuery } from '@apollo/client';
import { createContext, FC, useContext, useMemo } from 'react';
import { compare } from 'compare-versions';
import { getMetaVersion } from '../queries';

const minimalDashboardApiVersionsByFeatures = {
	XmTenant: '1.0.0',
	NonSXASites: '1.4.226',
};

export const defaultFeatureValues: CompatibilityFeatures = {
	XmTenant: false,
	NonSXASites: false,
};

export type FeatureNames = keyof typeof minimalDashboardApiVersionsByFeatures;

export type CompatibilityFeatures = {
	[P in FeatureNames]: boolean;
};

export type TenantVersionFeaturesModel = {
	loading: boolean;
	version: string | undefined;
	features: CompatibilityFeatures;
};

export type MetaPayload = {
	meta: {
		xMVersion: string;
	};
};

const defaultTenantVersionFeaturesModel: TenantVersionFeaturesModel = {
	features: defaultFeatureValues,
	loading: false,
	version: undefined,
};

export const checkIfVersionIsSupported = (version: string | undefined, targetVersion: string) =>
	version ? compare(version, targetVersion, '>=') : false;

function getFeaturesFromVersion(version: string | undefined) {
	if (!version) {
		return defaultFeatureValues;
	}

	const features: Record<string, boolean> = {};

	for (const featureName in minimalDashboardApiVersionsByFeatures) {
		if (Object.prototype.hasOwnProperty.call(minimalDashboardApiVersionsByFeatures, featureName)) {
			const targetVersion = minimalDashboardApiVersionsByFeatures[featureName as FeatureNames];

			features[featureName] = checkIfVersionIsSupported(version, targetVersion);
		}
	}

	return features as CompatibilityFeatures;
}

export const TenantVersionFeaturesContext = createContext<TenantVersionFeaturesModel>(defaultTenantVersionFeaturesModel);

export const TenantVersionFeaturesProvider: FC<React.PropsWithChildren> = ({ children }) => {
	const { data, loading } = useQuery<MetaPayload>(getMetaVersion);
	const version = data?.meta?.xMVersion;
	const currentFeatures: CompatibilityFeatures = useMemo(() => {
		if (loading) {
			return defaultTenantVersionFeaturesModel.features;
		}

		return getFeaturesFromVersion(version);
	}, [loading, version]);

	const value = useMemo(
		() => ({
			features: currentFeatures,
			loading: loading,
			version: version,
		}),
		[currentFeatures, loading, version]
	);

	return <TenantVersionFeaturesContext.Provider value={value}>{children}</TenantVersionFeaturesContext.Provider>;
};

export const useTenantVersionFeaturesProvider = () => {
	return useContext(TenantVersionFeaturesContext);
};

export const useIsFeaturesAvailable = (...featureNames: FeatureNames[]) => {
	const { features, loading, version } = useTenantVersionFeaturesProvider();
	const isAvaialable = useMemo(() => {
		const available: boolean[] = [];

		for (const featureName of featureNames) {
			available.push(features[featureName]);
		}

		return available;
	}, [features, featureNames]);

	const allAvailable = useMemo(() => isAvaialable.reduce((prev, cur) => prev && cur, true), [isAvaialable]);

	return { isAvaialable, allAvailable, loading, version };
};

export const useIsFeatureAvailable = (featureName: FeatureNames) => {
	const { features, loading, version } = useTenantVersionFeaturesProvider();
	const isAvaialable = useMemo(() => features[featureName], [features, featureName]);

	return { isAvaialable, loading, version };
};

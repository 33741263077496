import { Flex, Heading, Text, Image } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { PropsWithChildren } from 'react';
import { DICTIONARY, SC_ICONS_SRC } from 'shared/constants';

export const MissingPortfolioArea = ({ children }: PropsWithChildren) => {
	return (
		<Flex direction="column" gap="6" justifyContent="center" alignItems="center" py="10" data-testid="sc-missing-portfolio-area">
			<Flex boxSize="20">
				<Image src={SC_ICONS_SRC.XM_CLOUD} />
			</Flex>
			<Flex direction="column" gap="4" justifyContent="baseline" alignItems="center">
				<Flex direction="column" gap="2" justifyContent="center" alignItems="center">
					<Heading size="sm">
						<T _str={DICTIONARY.WELCOME_TO_XM_CLOUD} />
					</Heading>
					<Text>
						<T _str={DICTIONARY.WELCOME_TO_XM_CLOUD_TEXT} />
					</Text>
				</Flex>
				{children}
			</Flex>
		</Flex>
	);
};

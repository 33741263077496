import { Flex, Textarea } from '@chakra-ui/react';
import { FormFieldProps } from 'entities/form-field-control/models';
import { FormFieldControl } from 'entities/form-field-control/ui';
import { Field, Form, FormikProvider } from 'formik';
import { FC } from 'react';
import { COLLECTION_DESCRIPTION_VALIDATION_NUMBER, COLLECTION_NAME_VALIDATION_NUMBER, DICTIONARY } from 'shared/constants';
import { NonPredictiveInput } from 'shared/utils';
import { GeneralFormProps } from '../models';
import { ControlFieldSystemName } from './control-field-system-name';

export const GeneralForm: FC<GeneralFormProps> = ({ formik, collection }) => {
	return (
		<FormikProvider value={formik}>
			<Form noValidate>
				<Flex direction="column" gap="6">
					<Field name="displayName">
						{({ field, form }: FormFieldProps) => (
							<FormFieldControl
								name="displayName"
								field={field}
								form={form}
								label={DICTIONARY.NAME}
								isRequired
								maxLength={COLLECTION_NAME_VALIDATION_NUMBER}
								maxW="xl"
							>
								<NonPredictiveInput data-testid="collectionName-input" {...field} maxLength={COLLECTION_NAME_VALIDATION_NUMBER} />
							</FormFieldControl>
						)}
					</Field>
					{!!collection?.name && <ControlFieldSystemName collectionId={collection?.id ?? ''} collectionName={collection.name} />}
					<Field name="description">
						{({ field, form }: FormFieldProps) => (
							<FormFieldControl
								name="description"
								field={field}
								form={form}
								label={DICTIONARY.DESCRIPTION}
								maxLength={COLLECTION_DESCRIPTION_VALIDATION_NUMBER}
								maxW="xl"
							>
								<Textarea data-testid="collectionDescription-input" {...field} maxLength={COLLECTION_DESCRIPTION_VALIDATION_NUMBER} />
							</FormFieldControl>
						)}
					</Field>
				</Flex>
			</Form>
		</FormikProvider>
	);
};

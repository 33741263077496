import { FC, memo, useEffect } from 'react';

export interface ScriptInjectorProps {
	src?: string;
	code?: string;
	id?: string;
}

const ScriptInjector: FC<ScriptInjectorProps> = ({ src, code, id = '' }) => {
	useEffect(() => {
		if (!code && !src) {
			return;
		}

		const script = document.createElement('script');

		if (src) {
			script.src = src;
			script.async = true;
		} else if (code) {
			script.innerHTML = code;
		}

		script.id = id;

		document.head.appendChild(script);

		return () => {
			document.head.removeChild(script);
		};
	}, [src, code, id]);

	return <></>;
};

export default memo(ScriptInjector);

import {
	Button,
	ButtonGroup,
	CircularProgress,
	Flex,
	FormControl,
	FormHelperText,
	FormLabel,
	IconButton,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Stack,
	Text,
	Tooltip,
} from '@chakra-ui/react';
import { mdiPencilOutline } from '@mdi/js';
import { T } from '@transifex/react';
import { FormFieldProps } from 'entities/form-field-control/models';
import { FormFieldControl } from 'entities/form-field-control/ui';
import { Icon } from 'entities/icon/ui';
import { useTranslate } from 'features/common/utils/translateUtils';
import { useCheckSiteSystemNameUniqueness } from 'features/site-actions-menu/hooks';
import { Field, Form, Formik } from 'formik';
import { FC, FocusEvent } from 'react';
import { DICTIONARY, VALIDATIONS } from 'shared/constants';
import { siteNameValidationSchema } from 'shared/validations';
import { useRenameSystemName } from 'widgets/site-settings-update-form/hooks';

type ControlFieldSystemNameProps = {
	value: string;
	siteId: string;
	collectionId: string;
};

export const ControlFieldSystemName: FC<ControlFieldSystemNameProps> = ({ value, siteId, collectionId }) => {
	const { isPending, textValue, handleRename, isOpen, onOpen, onClose } = useRenameSystemName({ value, siteId, collectionId });
	const t = useTranslate();
	const validateSystemNameUniqueness = useCheckSiteSystemNameUniqueness({ originalValue: value, formKey: 'name' });

	return (
		<>
			<FormControl>
				<FormLabel>
					<T _str={DICTIONARY.SITE_LABEL_SYSTEM_NAME} />
				</FormLabel>
				<Flex gap="2" alignItems="center">
					<Flex gap="2" hidden={!isPending}>
						<CircularProgress isIndeterminate size="5" capIsRound color="primary" />
						<Text>
							<T _str={DICTIONARY.SITE_LABEL_CHANGE_SYSTEM_NAME} />
						</Text>
					</Flex>
					<Text data-testid="site-system-name">{textValue.current}</Text>
					<Tooltip label={DICTIONARY.EDIT}>
						<IconButton
							data-testid="edit-system-name"
							size="xs"
							variant="ghost"
							aria-label={t(DICTIONARY.SITE_EDIT_ICON)}
							icon={<Icon path={mdiPencilOutline} />}
							onClick={onOpen}
							hidden={isPending}
							onFocus={(e: FocusEvent<HTMLButtonElement>) => e.preventDefault()}
						/>
					</Tooltip>
				</Flex>
				<FormHelperText>
					<Text>
						<T _str={DICTIONARY.SITE_HINT_EDIT_SYSTEM_NAME} />
					</Text>
				</FormHelperText>
			</FormControl>
			<Modal size="md" onClose={onClose} isOpen={isOpen}>
				<ModalOverlay />
				<Formik
					initialValues={{ name: value || '' }}
					validationSchema={siteNameValidationSchema}
					validate={(values) => validateSystemNameUniqueness(values.name)}
					onSubmit={handleRename}
					enableReinitialize
				>
					<Form noValidate>
						<ModalContent>
							<ModalHeader>
								<T _str={DICTIONARY.SITE_EDIT_SYSTEM_NAME} />
							</ModalHeader>
							<ModalCloseButton />
							<ModalBody>
								<Stack gap="6">
									<Text>
										<T _str={DICTIONARY.SITE_POPUP_HINT_EDIT_SYSTEM_NAME} />
									</Text>
									<Field name="name">
										{({ field, form }: FormFieldProps) => (
											<FormFieldControl
												name="name"
												field={field}
												form={form}
												label={DICTIONARY.SITE_LABEL_SYSTEM_NAME}
												isRequired
												maxLength={VALIDATIONS.NAME_VALIDATION_NUMBER}
												maxW="xl"
												helper={DICTIONARY.SITE_POPUP_HINT_VALIDATION_SYSTEM_NAME}
											>
												<Input
													data-testid="siteName-input"
													{...field}
													type="text"
													maxLength={VALIDATIONS.NAME_VALIDATION_NUMBER}
												/>
											</FormFieldControl>
										)}
									</Field>
								</Stack>
							</ModalBody>
							<ModalFooter gap="2">
								<ButtonGroup>
									<Button variant="ghost" disabled={isPending} onClick={onClose}>
										<T _str={DICTIONARY.CANCEL} />
									</Button>
									<Button type="submit">
										<T _str={DICTIONARY.SAVE} />
									</Button>
								</ButtonGroup>
							</ModalFooter>
						</ModalContent>
					</Form>
				</Formik>
			</Modal>
		</>
	);
};

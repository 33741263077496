import { useGetAllPOS } from 'features/pos/utils/posService';
import { PosPayload } from 'features/pos/utils/types';
import { useGetUniqueUntlLanguages } from 'features/pos/utils/useGetUniqueUntlLanguages';
import { useMemo } from 'react';
import { useGetSite } from '../../sites/utils/useGetSite';

export type PosBySite = PosPayload & {
	siteLanguage: string;
	state: 'ok' | 'failed' | 'no identifier';
};

type GetSiteReturnStatement = {
	posList: PosBySite[];
	isLoading: boolean;
};

export const useGetSitePos = (): GetSiteReturnStatement => {
	const { posList, isLoading: posLoading } = useGetAllPOS();
	const isoLanguages = useGetUniqueUntlLanguages();
	const { site, loading: sitesLoading } = useGetSite();

	const list: PosBySite[] | null = useMemo(() => {
		if (!site) {
			return [];
		}

		const posMappings = site.posMappings;
		const sitePosList: PosBySite[] = [];

		const asteriskMapping = posMappings.find((mapping) => mapping.language === '*');

		if (asteriskMapping) {
			const pos = posList.find((p) => p.name === asteriskMapping.name);

			if (pos) {
				sitePosList.push({
					siteLanguage: '*',
					brand: pos.brand,
					language: pos.language,
					market: pos.market,
					name: pos.name,
					timeout: pos.timeout,
					state: 'ok',
				});
			} else {
				sitePosList.push({ siteLanguage: '*', brand: '', language: '', market: '', name: '', timeout: 0, state: 'failed' });
			}
		}

		for (const isoLang of isoLanguages) {
			const lang = isoLang.value;
			const posMapping = posMappings.find((mapping) => mapping.language === lang);

			if (!posMapping) {
				sitePosList.push({ siteLanguage: lang, brand: '', language: '', market: '', name: '', timeout: 0, state: 'no identifier' });
				continue;
			}

			const pos = posList.find((p) => p.name.toLowerCase() === posMapping.name.toLowerCase());

			if (!pos) {
				sitePosList.push({ siteLanguage: lang, brand: '', language: '', market: '', name: '', timeout: 0, state: 'failed' });
				continue;
			}

			sitePosList.push({
				siteLanguage: lang,
				brand: pos.brand,
				language: pos.language,
				market: pos.market,
				name: pos.name,
				timeout: pos.timeout,
				state: 'ok',
			});
		}

		return sitePosList;
	}, [isoLanguages, posList, site]);

	return {
		isLoading: posLoading || sitesLoading,
		posList: list || [],
	};
};

import { ITEM_ID_PARAM_NAME, SITE_NAME_PARAM_NAME, TYPE_FILTER_NAME } from 'features/common/utils/сlearNonRelevantQueryParams';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLanguageContext } from '../components/LanguageProvider';

export const useColumnsURLState = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { selectedLanguage } = useLanguageContext();

	const { siteName, itemId } = useMemo(() => {
		return {
			siteName: searchParams.get(SITE_NAME_PARAM_NAME),
			itemId: searchParams.get(ITEM_ID_PARAM_NAME),
		};
	}, [searchParams]);

	const setParams = useCallback(
		(newSiteName: string | null | undefined, newItemId: string | null | undefined) => {
			if (newSiteName === siteName && newItemId === itemId) {
				return;
			}

			if (!newSiteName) {
				searchParams.delete(SITE_NAME_PARAM_NAME);
			} else {
				searchParams.set(SITE_NAME_PARAM_NAME, newSiteName);
			}

			if (!newItemId) {
				searchParams.delete(ITEM_ID_PARAM_NAME);
			} else {
				searchParams.set(ITEM_ID_PARAM_NAME, newItemId);
			}

			setSearchParams(searchParams);
		},
		[itemId, searchParams, setSearchParams, siteName]
	);

	const setSiteName = useCallback((newSiteName: string) => setParams(newSiteName, null), [setParams]);
	const setItemId = useCallback((newItemId: string) => setParams(siteName, newItemId), [setParams, siteName]);

	const resetFilters = useCallback(() => {
		searchParams.delete(TYPE_FILTER_NAME);
		setSearchParams(searchParams);
	}, [searchParams, setSearchParams]);

	return { siteName, setSiteName, language: selectedLanguage?.name, itemId, setItemId, resetFilters };
};

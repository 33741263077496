import { Box, Button, Menu, MenuButton, MenuItem, MenuList, Tooltip } from '@chakra-ui/react';
import { mdiChevronDown } from '@mdi/js';
import { Icon } from 'entities/icon/ui';
import { useTranslate } from 'features/common/utils/translateUtils';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { useSiteFilterContext } from './SiteFilterContext';

export const SiteCollectionsFilter: FC = () => {
	const { siteCollection, siteCollections, setCollection, siteType } = useSiteFilterContext();
	const t = useTranslate();

	if (siteCollections.length <= 2) {
		return <></>;
	}

	return (
		<Box>
			<Menu>
				<Tooltip label={t(DICTIONARY.DISABLED_SITE_COLLECTION_TOOLTIP)} isDisabled={siteType !== 'nonsxa'}>
					<MenuButton
						as={Button}
						size="md"
						rightIcon={<Icon path={mdiChevronDown} layerStyle="menuButtonIcon" />}
						variant="outline"
						borderRadius="md"
						data-testid="selectedLanguage"
						isDisabled={siteType === 'nonsxa'}
					>
						{siteCollection?.name}
					</MenuButton>
				</Tooltip>
				<MenuList>
					{siteCollections &&
						siteCollections.map((collection, index) => (
							<MenuItem key={index} data-testid="siteCollections" onClick={() => setCollection(collection.id)}>
								{collection.name}
							</MenuItem>
						))}
				</MenuList>
			</Menu>
		</Box>
	);
};

import { FieldDetails } from 'features/millerColumns/components/detailsSection/FieldDetails';
import { FC } from 'react';

export const GenaralTabFieldDetails: FC<{ name: string; value: string | undefined; alt?: string }> = ({ name, value, alt }) => {
	return (
		<FieldDetails
			labelProps={{ fontWeight: 'medium', color: 'neutral-fg' }}
			textProps={{ color: 'chakra-body-text' }}
			name={name}
			value={value || alt}
		/>
	);
};

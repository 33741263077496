import { Flex, Stack } from '@chakra-ui/react';
import { FC } from 'react';
import type { ItemTreeProps, ItemViewModel, TreeViewProps } from 'shared/item-tree/models';
import { useTreeView } from '../hooks';
import { TreeLoadingSkeleton } from './tree-skeleton';

export const TreeView: FC<ItemTreeProps & Omit<TreeViewProps, 'stopLoadAncestors'>> = (props) => {
	const { childrenItem, getItemView, level = 0, isMultiple, ancestors } = props;
	const { opened, data, isLoading, checkAncestor, onBranchOpen, openBranchOnClick } = useTreeView(props);

	return (
		<Stack gap="0" data-testid="tree-node" flex={isMultiple ? 0 : 1}>
			{getItemView({ ...props, item: data || childrenItem, ancestors, opened, setOpened: onBranchOpen, setSelectedIds: openBranchOnClick })}
			{isLoading && <TreeLoadingSkeleton />}
			<Stack gap="0" minWidth="2xs">
				{opened &&
					data?.children &&
					data.children.map((it: ItemViewModel) => (
						<Flex key={it.id}>
							<TreeView
								{...props}
								isParent={false}
								itemId={it.id}
								childrenItem={it}
								isOpened={checkAncestor(it.id)}
								level={level + 1}
							/>
						</Flex>
					))}
			</Stack>
			{data?.siblings &&
				!props.noUseSibling &&
				level === 0 &&
				data.siblings.map((sibling: ItemViewModel) => (
					<TreeView
						key={sibling.id}
						itemId={sibling.id}
						siteId={props.siteId}
						useGetItem={props.useGetItem}
						selectedIds={props.selectedIds}
						setSelectedIds={props.setSelectedIds}
						selectionVariant="single"
						expandOnSelect={props.expandOnSelect}
						getItemView={props.getItemView}
						noUseSibling={true}
						isOpened={false}
					/>
				))}
		</Stack>
	);
};

import { Box, Stack, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { PageBodyContentSection } from 'entities/page-layout/ui';
import { useTranslate } from 'features/common/utils/translateUtils';
import { HostMissingPosNotification, PosEnablementSwitcher } from 'features/point-of-sale/ui';
import { SiteDefinitionPageOutlet } from 'pages/site-definition/models/site-definition-props';
import { FC, useMemo } from 'react';
import { useOutletContext } from 'react-router';
import { DICTIONARY, POS_FIELD_ENABLE_ANALYTICS } from 'shared/constants';
import { SitePosData } from 'widgets/site-pos-table/models';
import { SitePosTable } from 'widgets/site-pos-table/ui';

export const SiteDefinitionPagePos: FC = () => {
	const { siteDefinition: host, site, hostWithMissingPoSs, languages, isLoading } = useOutletContext<SiteDefinitionPageOutlet>();
	const t = useTranslate();
	const isEnableAnalytics = !!(!host?.settings[POS_FIELD_ENABLE_ANALYTICS] || host?.settings[POS_FIELD_ENABLE_ANALYTICS] === 'true');
	const siteData = useMemo<SitePosData | undefined>(() => {
		if (!host || !site) {
			return undefined;
		}

		return {
			id: host?.id || '',
			siteId: site?.id || '',
			name: host?.name || '',
			settings: host?.settings || {},
			analyticsIdentifiers: host?.analyticsIdentifiers || {},
			siteName: site?.name || '',
		};
	}, [host, site]);

	return (
		<>
			<HostMissingPosNotification host={hostWithMissingPoSs} />
			<PageBodyContentSection
				title={t(DICTIONARY.ANALYTICS_AND_PERSONALIZATION)}
				headerNode={<PosEnablementSwitcher id={host?.id || ''} siteId={site?.id || ''} settings={host?.settings || {}} />}
			>
				<Stack gap={6}>
					<Box>
						<Text>
							<T _str={DICTIONARY.ANALYTICS_AND_PERSONALIZATION_DESCRIPTION} />
						</Text>
						<Text hidden={!isEnableAnalytics}>
							<T _str={DICTIONARY.ANALYTICS_AND_PERSONALIZATION_DESCRIPTION_POS_ENABLE} />
						</Text>
					</Box>
					{isEnableAnalytics && siteData && <SitePosTable languages={languages} siteData={siteData} isLoading={isLoading} />}
				</Stack>
			</PageBodyContentSection>
		</>
	);
};

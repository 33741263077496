import { Card, Flex, FlexProps, Table, TableContainer } from '@chakra-ui/react';
import { EmptyContentArea } from 'entities/empty-content-area/ui';
import { NoSearchResult } from 'entities/no-search-result/ui';
import { TableLoading } from 'entities/table/ui';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { Paging } from 'shared/paging/ui';
import { SearchInput } from 'shared/search-input/ui';
import { useGetPoSData, usePoSActions } from 'widgets/pos-table/hooks';
import { PosTableBody } from 'widgets/pos-table/ui/pos-table-body';
import { PosTableHead } from 'widgets/pos-table/ui/pos-table-head';

export const PosTable: FC<FlexProps> = (props) => {
	const { posData, currentPage, totalPages, initialSearchValue, isLoading, hasSearch } = useGetPoSData();
	const { ref, searchFor, activatePage } = usePoSActions();

	if ((!posData || posData.length === 0) && !isLoading && !hasSearch) {
		return (
			<EmptyContentArea py="6" title={DICTIONARY.NO_ANALYTICS_IDENTIFIERS_TEXT} description={DICTIONARY.NO_ANALYTICS_IDENTIFIERS_DESCRIPTION} />
		);
	}

	return (
		<Flex direction="column" gap="6" {...props}>
			<SearchInput
				containerStyle={{ maxWidth: 96 }}
				defaultValue={initialSearchValue}
				placeholder={DICTIONARY.SEARCH_FOR_ANALYTICS_IDENTIFIER_OR_SITE_DEFINITION_NAME}
				onClear={searchFor}
				onSearch={searchFor}
				inputRef={ref}
			/>
			{!posData.length && !isLoading ? (
				<NoSearchResult onReset={() => searchFor()} />
			) : (
				<Flex direction="column" gap="4" alignItems="end">
					<TableContainer as={Card} variant="flat" size="lg" whiteSpace="normal" boxSize="full" overflowY="unset" overflowX="unset">
						<Table>
							<PosTableHead />
							{!isLoading ? <PosTableBody data={posData} /> : <TableLoading columns={3} rows={4} />}
						</Table>
					</TableContainer>
					<Paging page={currentPage} total={totalPages} onNavigate={activatePage} />
				</Flex>
			)}
		</Flex>
	);
};

import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useSyncLocationState } from 'app/use-sync-location-state.deprecated';
import { Loading } from 'features/common/components/Loading';
import { Feature } from 'features/common/featureFlags/featureFlags';
import { MissingPosNotification, MissingPosNotificationEA } from 'features/point-of-sale/ui';
import { SiteAnalyticsAlertProvider } from 'features/sites/components/SiteAnalyticsAlert';
import { Outlet } from 'react-router-dom';
import { onDrop } from 'shared/drag-n-drop';
import { Layout, LayoutHeader, LayoutMain } from 'shared/layout/ui';
import { TopBar } from 'widgets/topbar/ui';
import { XMTenantErrorHandler } from 'widgets/xmTenantErrorHandler';

export const PageLayout = withAuthenticationRequired(
	() => {
		useSyncLocationState();

		return (
			<Layout>
				<LayoutHeader>
					<TopBar />
				</LayoutHeader>
				<LayoutMain disablePadding height="full" backgroundColor="chakra-subtle-bg" onDrop={onDrop}>
					<XMTenantErrorHandler>
						<SiteAnalyticsAlertProvider>
							<MissingPosNotification />
							{/* <RenameSiteProvider> */}
							{/* <RemoveSiteProvider> */}
							{/* <DuplicateSiteProvider> */}
							<Outlet />
							{/* </DuplicateSiteProvider> */}
							{/* </RemoveSiteProvider> */}
							{/* </RenameSiteProvider> */}
						</SiteAnalyticsAlertProvider>
					</XMTenantErrorHandler>
				</LayoutMain>
				<Feature name="showSitesGrouping" render={() => <MissingPosNotification />} fallback={() => <MissingPosNotificationEA />} />
			</Layout>
		);
	},
	{
		onRedirecting: () => <Loading />,
	}
);

import { useNavigationItems } from 'entities/page-layout/hooks';
import { Page, PageBody, PageBodyContent, PageBodyLeftBar, PageHeader } from 'entities/page-layout/ui';
import { PageLeftNavigation } from 'entities/page-layout/ui/page-left-navigation';
import { FormikProps } from 'formik';
import { usePortfolioPageData } from 'pages/portfolio/hooks';
import { PortfolioContextProps } from 'pages/portfolio/models';
import { FC } from 'react';
import { Outlet } from 'react-router';
import { LEFT_BAR_NAVIGATION } from 'shared/pageNavigation';
import { useGeneralSection } from 'widgets/collection-settings/hooks';
import { UpdateCollectionButtons } from 'widgets/collection-settings/ui';

export const PortfolioPage: FC = () => {
	const links = useNavigationItems(LEFT_BAR_NAVIGATION.PORTFOLIO_PAGE);
	const { data: collection, displayName, description, goBack, dialog } = usePortfolioPageData();
	const formikUpdate = useGeneralSection({
		id: collection?.id || '',
		displayName,
		description,
	});

	return (
		<Page data-testid={`collectionPage-${collection?.name || ''}`}>
			<PageHeader backAction={goBack} title={displayName} w="full">
				<UpdateCollectionButtons formiks={[formikUpdate] as FormikProps<unknown>[]} />
			</PageHeader>
			<PageBody>
				<PageBodyLeftBar>
					<PageLeftNavigation links={links} {...dialog} />
				</PageBodyLeftBar>
				<PageBodyContent>
					<Outlet context={{ collection, dialog, formiks: [formikUpdate] } as PortfolioContextProps} />
				</PageBodyContent>
			</PageBody>
		</Page>
	);
};

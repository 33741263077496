import { Button, Flex, FlexProps, Heading, Image, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY, SC_ICONS_SRC } from 'shared/constants';

type NoSearchResultProps = FlexProps & {
	onReset?: () => void;
};

export const NoSearchResult: FC<NoSearchResultProps> = ({ onReset, ...props }) => (
	<Flex direction="column" alignItems="center" data-testid="no-search-result" py="6" {...props}>
		<Image src={SC_ICONS_SRC.MAGNIFYING_GLASS_X_GRAY} height="20" />
		<Flex direction="column" gap="4" alignSelf="stretch" alignItems="center">
			<Flex direction="column" gap="2" alignItems="center">
				<Heading size="sm">
					<T _str={DICTIONARY.NO_SEARCH_RESULT} />
				</Heading>
				<Text>
					<T _str={DICTIONARY.TRY_DIFFERENT_SEARCH_OR_FILTER} />
				</Text>
			</Flex>
			<Button data-testid="reset-search-params" variant="link" onClick={onReset}>
				<T _str={DICTIONARY.RESET_SEARCH} />
			</Button>
		</Flex>
	</Flex>
);

import { Flex, IconButton, MenuDivider, Text, Tooltip } from '@chakra-ui/react';
import { mdiDragVertical } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { MenuIconButton } from 'entities/menu-icon-button/ui';
import { CreateSitePageButton } from 'features/create-site-page/ui';
import { DeletePageMenuItem, DuplicatePageMenuItem } from 'features/page-actions-menu/ui';
import { RenamePageMenuItem } from 'features/page-actions-menu/ui/rename-page-menu-item';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { OpenInPagesMenuItem } from 'shared/open-in-pages/ui';
import { usePage } from 'widgets/site-structure/hooks';
import { PageProps } from 'widgets/site-structure/models/props';
import { iconStyles } from 'widgets/site-structure/styles';
import '../styles/page.scss';

export const Page: FC<PageProps> = (props) => {
	const {
		isOpen,
		setIsOpen,
		id,
		parentId,
		name,
		displayName,
		hasChildren,
		siteName,
		siblingNames,
		canDuplicate,
		canRename,
		siteId,
		canCreate,
		parentCanCreate,
		parentPageName,
		isLocked,
	} = props;
	const {
		blockProps,
		cantBeDeleted,
		chevronIcon,
		marginLeft,
		pageIcon,
		parentOfHosts,
		isHomePage,
		childOfHosts,
		startItemOfHosts,
		onDragEnter,
		onDragLeave,
		onDragStart,
		onDragOver,
		onDrop,
		classNames,
		showTreeDragNDrop,
	} = usePage(props);

	return (
		<Flex
			className={classNames}
			marginLeft={marginLeft}
			{...blockProps}
			onDragStart={(e) => void onDragStart(e)}
			onDragEnter={onDragEnter}
			onDragLeave={onDragLeave}
			onDragOver={onDragOver}
			onDrop={(e) => void onDrop(e)}
		>
			<Flex gap="2">
				{showTreeDragNDrop && (
					<Flex boxSize="8">
						{!isLocked && (
							<Tooltip label={<T _str={DICTIONARY.MOVE_PAGE} />}>
								<IconButton {...iconStyles} icon={<Icon path={mdiDragVertical} />} cursor="move" />
							</Tooltip>
						)}
					</Flex>
				)}
				<Flex boxSize="8">
					{hasChildren && (
						<Tooltip label={<T _str={isOpen ? DICTIONARY.COLLAPSE : DICTIONARY.EXPAND} />}>
							<IconButton {...iconStyles} icon={<Icon path={chevronIcon} />} onClick={() => setIsOpen(!isOpen)} />
						</Tooltip>
					)}
				</Flex>
				<Flex gap="2" alignItems="center">
					<Tooltip
						label={<T _str={DICTIONARY.HOMEPAGE_FOR} siteDefinitions={startItemOfHosts.join(', ')} />}
						isDisabled={!isHomePage}
						shouldWrapChildren
					>
						<Icon color="neutral" path={pageIcon} boxSize="6" />
					</Tooltip>
					<Text noOfLines={1} title={name} as="span">
						{displayName || name}
					</Text>
				</Flex>
			</Flex>
			<Flex gap="2">
				<CreateSitePageButton
					pageId={id}
					siteName={siteName}
					parentPageName={parentPageName}
					displayName={displayName}
					parentPageId={parentId}
					parentCanCreate={parentCanCreate}
					canCreate={canCreate}
				/>
				<MenuIconButton size="sm">
					<OpenInPagesMenuItem hosts={childOfHosts} itemId={id} />
					<MenuDivider />
					<RenamePageMenuItem
						id={id}
						siteId={siteId}
						canRename={canRename}
						name={name}
						displayName={displayName}
						siblingNames={siblingNames}
					/>
					<DuplicatePageMenuItem
						id={id}
						canDuplicate={canDuplicate}
						name={name}
						siblingNames={siblingNames}
						siteId={siteId}
						hasChildren={hasChildren}
					/>
					<DeletePageMenuItem
						canDelete={!cantBeDeleted}
						id={id}
						name={displayName || name}
						startItemOfHosts={startItemOfHosts}
						parentOfHosts={parentOfHosts}
						hasChildren={hasChildren}
					/>
				</MenuIconButton>
			</Flex>
		</Flex>
	);
};

import { CloseButton, HStack, Input, InputGroup, InputLeftElement, InputRightElement, Tooltip } from '@chakra-ui/react';
import { mdiMagnify } from '@mdi/js';
import { Icon } from 'entities/icon/ui';
import { useTranslate } from 'features/common/utils/translateUtils';
import { FC, useState } from 'react';
import { DICTIONARY } from 'shared/constants';
import { useSiteFilterContext } from './SiteFilterContext';

type ChangeEvent = React.ChangeEvent<HTMLInputElement>;

export const SiteFilter: FC = () => {
	const { filter, setFilter } = useSiteFilterContext();
	const t = useTranslate();
	const [clearButtonVisibility, setClearButtonVisibility] = useState<'visible' | 'hidden'>('hidden');

	return (
		<HStack>
			<InputGroup
				size="md"
				width={80}
				onMouseOver={() => setClearButtonVisibility('visible')}
				onMouseOut={() => setClearButtonVisibility('hidden')}
			>
				<InputLeftElement>
					<Icon path={mdiMagnify} />
				</InputLeftElement>
				<Input
					placeholder={t(DICTIONARY.SEARCH_BY_SITE_NAME)}
					value={filter}
					onChange={(e: ChangeEvent) => setFilter(e.target.value)}
					maxLength={50}
					borderRadius="md"
					data-testid="filterInput"
				/>
				<InputRightElement style={{ visibility: clearButtonVisibility }} onClick={() => setFilter('')} data-testid="resetSearchButton">
					<Tooltip label="Clear search">
						<CloseButton />
					</Tooltip>
				</InputRightElement>
			</InputGroup>
		</HStack>
	);
};

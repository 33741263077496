import { Button, Heading, Image, Text, VStack } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY, SC_ICONS_SRC } from 'shared/constants';

export type NoSitesFoundProps = {
	clearFilter: () => void;
};

export const NoSitesFound: FC<NoSitesFoundProps> = ({ clearFilter }) => {
	return (
		<VStack data-testid="NoSitesScreen" paddingTop="28" spacing="6">
			<Image src={SC_ICONS_SRC.MAGNIFYING_GLASS_X_GRAY} height="20" />
			<VStack gap="2">
				<Heading size="sm">
					<T _str={DICTIONARY.NO_SITES_FOUND} />
				</Heading>
				<Text>
					<T _str={DICTIONARY.NO_SITES_MATCH_FILTER} />
				</Text>
			</VStack>
			<Button variant="link" onClick={clearFilter} data-testid="resetSearchLink" mt="-2">
				<T _str={DICTIONARY.RESET_SEARCH} />
			</Button>
		</VStack>
	);
};

import { useReactQueryWrappersProvider } from 'app/react-query-wrappers-provider/hooks';
import { useCallback } from 'react';
import { ENDPOINTS } from 'shared/endpoints';
import { PageResponse } from 'shared/models';
import { blinkPage } from 'shared/site-structure';

export const useBlinkOnDuplicate = (siteId: string) => {
	const { axios } = useReactQueryWrappersProvider();

	return useCallback(
		async (pageId: string) => {
			await blinkPage(pageId);

			const result = await axios.get<PageResponse>(ENDPOINTS.HIERARCHY_PAGE.replace('{siteId}', siteId).replace('{pageId}', pageId));
			const children = result.data.children.map((pageData) => pageData.id);

			for (const childId of children) {
				await blinkPage(childId);
			}
		},
		[axios, siteId]
	);
};

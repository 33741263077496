export const buildSearchParams = (params: Record<string, string | undefined>) => {
	const searchParams = new URLSearchParams();

	for (const key in params) {
		if (Object.prototype.hasOwnProperty.call(params, key)) {
			const element = params[key];

			if (element) {
				searchParams.set(key, element);
			}
		}
	}

	const serializedParams = searchParams.toString();

	if (!serializedParams) {
		return '';
	}

	return '?' + serializedParams;
};

import { Flex, Heading } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC, PropsWithChildren } from 'react';
import { DICTIONARY } from 'shared/constants';

export const EmptyPortfolioArea: FC<PropsWithChildren> = ({ children }) => (
	<Flex direction="column" gap="4" alignItems="center">
		<Heading size="sm">
			<T _str={DICTIONARY.EMPTY_PORTFOLIO_HEADING} />
		</Heading>
		{children}
	</Flex>
);

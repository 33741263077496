import { MenuItem } from '@chakra-ui/react';
import { mdiTrashCanOutline } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { RemoveLanguageProps } from 'features/remove-language/models';
import { RemoveLanguageAlert } from 'features/remove-language/ui/remove-language-alert';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';

export const RemoveLanguageMenuItem: FC<RemoveLanguageProps> = (props) => {
	const canDelete = props.canDelete ?? true;

	return (
		<RemoveLanguageAlert {...props}>
			<MenuItem
				color="danger"
				icon={<Icon path={mdiTrashCanOutline} color="danger" />}
				isDisabled={!canDelete}
				data-testid="remove-language-menu-item"
			>
				<T _str={DICTIONARY.DELETE} />
			</MenuItem>
		</RemoveLanguageAlert>
	);
};

import { useGetHostsWithPoS } from 'features/point-of-sale/hooks/get-hosts-with-pos';
import { HostWithMissingPos, MissingPoSs } from 'features/point-of-sale/models';
import { useMemo } from 'react';
import { useGetAllPoS } from 'shared/api';

export const useGetHostsWithMissingPosList = () => {
	const { hosts, isLoading: isSitesLoading } = useGetHostsWithPoS();
	const { posList, isLoading: isPosLoading } = useGetAllPoS();

	const missingPosList = useMemo<MissingPoSs>(() => {
		const sitesWithMissingPos: HostWithMissingPos[] = [];

		if (isPosLoading || isSitesLoading) {
			return { hosts: sitesWithMissingPos };
		}

		hosts.forEach((host) => {
			const missingPos = host?.posIdentifiers?.filter((pos) => !posList.some((p) => p.name === pos)) ?? [];
			const filteredMissingPos = missingPos.filter((pos, index) => missingPos.indexOf(pos) === index);

			if (filteredMissingPos.length) {
				sitesWithMissingPos.push({
					id: host.id,
					name: host.name,
					missingPos: filteredMissingPos,
					siteId: host.siteId,
				});
			}
		});

		return { hosts: sitesWithMissingPos };
	}, [isPosLoading, isSitesLoading, hosts, posList]);

	return { missingPosList, isLoading: isPosLoading || isSitesLoading };
};

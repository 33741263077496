import { UseThumbnailAuto } from 'features/thumbnail/models';
import { useState } from 'react';
import { THUMBNAIL_REGENERATE_TIMEOUT } from 'shared/constants';
import { useRegenerateThumbnail } from './use-regenerate-thumbnail';

export const useThumbnailAuto: UseThumbnailAuto = (site) => {
	const [isRegenerating, setIsRegenerating] = useState<boolean>(false);
	const handleRegenerateThumbnail = useRegenerateThumbnail({
		siteId: site?.siteId,
		collectionId: site?.collectionId,
		hostId: site?.id,
	});

	const onClickRegenerate = async () => {
		setIsRegenerating(true);
		await handleRegenerateThumbnail();
		setTimeout(() => {
			setIsRegenerating(false);
		}, THUMBNAIL_REGENERATE_TIMEOUT);
	};

	return { isRegenerating, onClickRegenerate };
};

import { Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { RemoveSiteProps } from 'features/remove-site/models';
import { RemoveSiteModalBody } from 'features/remove-site/ui/remove-site-modal-body';
import { FC, PropsWithChildren } from 'react';
import { DICTIONARY } from 'shared/constants';
import { useModalWrapper } from 'shared/modal-wrapper/hooks';

export const RemoveSiteModal: FC<PropsWithChildren<RemoveSiteProps>> = (props) => {
	const { children, isOpen, onClose } = useModalWrapper({ children: props.children });

	return (
		<>
			{children}
			<Modal isOpen={isOpen} onClose={onClose} size="3xl" scrollBehavior="inside">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						<T _str={DICTIONARY.DELETE_SITE} />
					</ModalHeader>
					<ModalCloseButton />
					<RemoveSiteModalBody onClose={onClose} {...props} />
				</ModalContent>
			</Modal>
		</>
	);
};

import { Alert, AlertDescription, AlertIcon, AlertTitle, Box } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';

export const SynchronizedPosBox: FC = () => {
	return (
		<Alert status="success">
			<AlertIcon />
			<Box>
				<AlertTitle>
					<T _str={DICTIONARY.ANALYTICS_IDENTIFIER_ARE_RESTORED} />
				</AlertTitle>
				<AlertDescription>
					<T _str={DICTIONARY.PERSONALIZE_AND_ANALYZE_CONNECTION_RESTORED} />
				</AlertDescription>
			</Box>
		</Alert>
	);
};

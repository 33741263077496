import { useQuery } from '@tanstack/react-query';
import { useReactQueryWrappersProvider } from 'app/react-query-wrappers-provider/hooks';
import { AxiosResponse } from 'axios';
import { useQueryKey } from 'shared/react-query-wrappers/hooks/use-query-key';
import { GetExtendedProps, RefetchIntervalQuery } from 'shared/react-query-wrappers/models';
import { disableGlobalErrorHandler } from 'shared/react-query-wrappers/utils/disable-global-error-handler';
import { intervalWrapper } from 'shared/react-query-wrappers/utils/interval-wrapper';

/**
 * Make GET request to the server.
 */
export const useGet = <TData = Record<string, string>>(params: GetExtendedProps<TData>) => {
	const { queryKey, enabled, refetchOnMount, refetchInterval, ignoreGlobalErrorHandler } = params;
	const { axios } = useReactQueryWrappersProvider();

	const { key } = useQueryKey(queryKey);

	const queryResult = useQuery({
		queryKey: key.queryKey,
		queryFn: async () => {
			return disableGlobalErrorHandler<TData>(axios.get<TData>(key.url), ignoreGlobalErrorHandler);
		},
		enabled: enabled,
		refetchOnMount: refetchOnMount,
		refetchInterval: (query: RefetchIntervalQuery<TData>) => intervalWrapper<TData>(query, refetchInterval),
	});

	return { ...queryResult, data: queryResult.data?.data };
};

/**
 * Make GET request to the server. This hook is used for simple GET requests, where we don't need to use the queryKey.
 */
export const useSimpleGet = <TData = Record<string, string>>(url: string, enabled = true) => {
	const queryResult = useQuery<AxiosResponse<TData, any>>({ queryKey: [url], enabled: enabled });

	return { ...queryResult, data: queryResult.data?.data };
};

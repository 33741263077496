import { Flex, MenuDivider, Skeleton, SkeletonText } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { EmptyPortfolioArea } from 'entities/empty-portfolio-area/ui';
import { PageBodyContentSection } from 'entities/page-layout/ui';
import { SkeletonRepeater } from 'entities/skeleton-repeater/ui';
import { ClickableSiteCard } from 'features/clickable-site-card/ui';
import { useFeatures } from 'features/common/featureFlags/featureFlags';
import { CreateSiteButton } from 'features/create-site/ui';
import { SiteDuplicateButtonWrapper } from 'features/duplicate-modal/ui';
import { ExplorerRedirectMenuItem, OpenSiteSettingsMenuItem, PageRedirectMenuItem, RenameSiteMenuItem } from 'features/site-actions-menu/ui';
import { useCheckPermissions } from 'features/site-permissions/hooks';
import { usePortfolioSitesPageData } from 'pages/portfolio/hooks';
import { FC } from 'react';
import { useCheckJobStatus } from 'shared/check-job-status';
import { DICTIONARY, SITE_CARD_SIZE } from 'shared/constants';
import { OpenInPagesMenuItem } from 'shared/open-in-pages/ui';
import { SitesCreationInProgress } from 'widgets/portfolio/ui/sites-creation-in-progress';

export const PortfolioSitesPage: FC = () => {
	const { sites, collectionId, isLoading: isSitesLoading } = usePortfolioSitesPageData();
	const { hasRunningSiteCreationsWithinCollection } = useCheckJobStatus();
	const { canCreate, isLoading: isPermissionsLoading } = useCheckPermissions();
	const { showSitesGrouping } = useFeatures();

	return (
		<PageBodyContentSection
			gap="4"
			title={DICTIONARY.SITES}
			headerNode={
				<Skeleton borderRadius="full" isLoaded={!isPermissionsLoading}>
					<CreateSiteButton text={DICTIONARY.CREATE} collectionId={collectionId} canCreate={canCreate} buttonProps={{ variant: 'solid' }} />
				</Skeleton>
			}
			description={
				<SkeletonText size="sm" noOfLines={1} skeletonHeight="4" borderRadius="6" overflow="hidden" isLoaded={!!sites}>
					<T _str={DICTIONARY.SITES_IN_COLLECTION} count={sites?.length} />
				</SkeletonText>
			}
		>
			{sites?.length === 0 && !hasRunningSiteCreationsWithinCollection(collectionId ?? '') && !isSitesLoading ? (
				<Flex py="3" justify="center">
					<EmptyPortfolioArea />
				</Flex>
			) : (
				<Flex minHeight={SITE_CARD_SIZE.HEIGHT} boxSize="full" gap="4" flexWrap="wrap">
					{isSitesLoading ? (
						<SkeletonRepeater borderRadius="6" count={2} width="xs" height="full" isLoaded={false} />
					) : (
						<>
							<SitesCreationInProgress collectionId={collectionId ?? ''} />
							{sites?.map((site) => (
								<ClickableSiteCard key={site.id} {...site}>
									<OpenSiteSettingsMenuItem siteId={site.id} />
									<RenameSiteMenuItem
										collectionId={collectionId || ''}
										siteAccess={site.access}
										siteName={site.displayName || site.name}
										siteId={site.id}
									/>
									<SiteDuplicateButtonWrapper
										siteAccess={site.access}
										siteName={site.displayName || site.name}
										siteId={site.id}
										description={site.description}
										siteLanguage={site.defaultLanguage}
									/>
									<MenuDivider />
									{showSitesGrouping ? (
										<OpenInPagesMenuItem hosts={site.hosts} />
									) : (
										<>
											<PageRedirectMenuItem siteName={site.name} />
											<ExplorerRedirectMenuItem siteName={site.name} />
										</>
									)}
								</ClickableSiteCard>
							))}
						</>
					)}
				</Flex>
			)}
		</PageBodyContentSection>
	);
};

import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Heading, Stack, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { TemplateContentsProps } from 'features/create-site/models';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';

export const TemplateContents: FC<TemplateContentsProps> = ({ data = [], ...props }) => {
	return !data.length ? null : (
		<Stack gap="3" {...props}>
			<Heading variant="section">
				<T _str={DICTIONARY.INCLUDED_IN_THIS_TEMPLATE} />
			</Heading>
			<Accordion allowMultiple>
				{data?.map((item) => (
					<AccordionItem key={item.key}>
						<AccordionButton>
							<Text variant="strong" width="full" textAlign="left">
								{item.key}
							</Text>
							<AccordionIcon />
						</AccordionButton>
						<AccordionPanel paddingBottom={4}>{item.value}</AccordionPanel>
					</AccordionItem>
				))}
			</Accordion>
		</Stack>
	);
};

import { InsertOption } from 'features/create-site-page/models';
import { SitePageOutlet } from 'pages/site/models';
import { useOutletContext } from 'react-router';
import { DEFAULT_LANGUAGE } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { useGet } from 'shared/react-query-wrappers/hooks';

export const useCreateSitePageMenu = (pageId: string, parentPageId: string) => {
	const { site } = useOutletContext<SitePageOutlet>();
	const siteName = site?.hosts[0].name || '';
	const language = site?.languages[0] || DEFAULT_LANGUAGE;

	const { data: insertOptions = [] as InsertOption[], isLoading: isLoadingInsertOptions } = useGet<InsertOption[]>({
		queryKey: {
			url: ENDPOINTS.PAGE_INSERT_OPTIONS,
			params: { siteName, pageId, language, insertOptionKind: 'Page' },
		},
		enabled: !!siteName && !!pageId,
	});

	const { data: insertOptionsParent = [] as InsertOption[], isLoading: isLoadingParentInsertOptions } = useGet<InsertOption[]>({
		queryKey: {
			url: ENDPOINTS.PAGE_INSERT_OPTIONS,
			params: { siteName, pageId: parentPageId || '', language, insertOptionKind: 'Page' },
		},
		enabled: !!siteName && !!parentPageId,
	});

	const isLoading = isLoadingInsertOptions || isLoadingParentInsertOptions;

	return {
		language,
		insertOptions,
		insertOptionsParent,
		isLoading,
	};
};

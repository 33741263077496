import { HostPosData } from 'features/point-of-sale/models';
import { useCallback, useMemo } from 'react';
import { POS_FIELD_ENABLE_ANALYTICS } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { Host, PatchPoSInput } from 'shared/models';
import { usePatch } from 'shared/react-query-wrappers/hooks';

export const useUpdatePOSAnalytics = (props: HostPosData) => {
	const { id, settings, siteId } = props;
	const { mutateAsync, isPending } = usePatch({
		queryKey: {
			url: ENDPOINTS.HOST,
			params: { siteId, hostId: id },
		},
		refetchQueriesByKey: [{ url: ENDPOINTS.SITE, params: { id: siteId }, shouldWait: true }, { url: ENDPOINTS.SITES }],
	});

	const newValue = useMemo(() => {
		return !(!settings[POS_FIELD_ENABLE_ANALYTICS] || settings[POS_FIELD_ENABLE_ANALYTICS] === 'true') ? 'true' : 'false';
	}, [settings]);

	const handlerSwitcher = useCallback(async () => {
		let params: Pick<Host, 'settings'> & Partial<PatchPoSInput> = {
			settings: { [POS_FIELD_ENABLE_ANALYTICS]: newValue },
		};

		if (newValue === 'false') {
			params = { ...params, analyticsIdentifiers: [] };
		}

		await mutateAsync(params);
	}, [mutateAsync, newValue]);

	return {
		handlerSwitcher,
		isPending,
	};
};

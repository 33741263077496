import { FC } from 'react';
import { useXMTenant } from '../tenants/xmTenantContext';
import { DashboardApolloProvider } from './ApolloProvider';

export const ApolloProviderTenenantWrapper: FC<React.PropsWithChildren> = ({ children }) => {
	const { tenant } = useXMTenant(true);

	if (tenant) {
		return <DashboardApolloProvider {...tenant}>{children}</DashboardApolloProvider>;
	}

	return <>{children}</>;
};

import { DataTableListOptions } from 'features/temporary/DataTable';
import { useCallback, useMemo, useState } from 'react';

type SortOrder = 'asc' | 'desc' | undefined;

type SortBy = {
	id: string;
	desc?: boolean;
};

interface UseSort<T> {
	sortBy: SortBy[];
	pageSize: number;
	page: number;
	onChange: (options: DataTableListOptions) => void;
	data: T[];
	totalItems: number;
}

interface UseSortOptions<T> {
	initialSortBy: keyof T;
	initialSortOrder: SortOrder;
	pageSize: number;
	columns: Partial<
		Record<
			keyof T,
			{
				compareType: 'string' | 'dateString';
			}
		>
	>;
}

const toDate = (dateString: string) => new Date(Date.parse(dateString));

/**
 * A helper for basic paging and sorting for DataTable.
 * Remember to set column ids when column accessors are
 * not just a simple key for sorting to work better!
 */
export function useTablePageSort<T>(data: T[], options?: Partial<UseSortOptions<T>>): UseSort<T> {
	const [page, setPage] = useState(1);
	const pageSize = options?.pageSize ?? 10;

	const [sortBy, setSortBy] = useState<string | number | symbol | undefined>(options?.initialSortBy);
	const [sortOrder, setSortOrder] = useState<SortOrder>(options?.initialSortOrder);

	const sorting = useMemo<SortBy[]>(
		() => [
			{
				id: (sortBy as string) || '',
				desc: sortOrder ? sortOrder === 'desc' : undefined,
			},
		],
		[sortOrder, sortBy]
	);

	const sortData = useCallback(
		(params: T[]) => {
			const sortId = sorting[0].id as keyof T;
			const sortDesc = sorting[0].desc;

			if (!sortId) {
				return params;
			}

			const isDateString = options?.columns?.[sortId]?.compareType === 'dateString';

			return params.slice().sort((a, b) => {
				const fieldA = isDateString ? toDate(a[sortId] as unknown as string) : a[sortId];
				const fieldB = isDateString ? toDate(b[sortId] as unknown as string) : b[sortId];

				if (fieldA < fieldB) {
					return sortDesc ? -1 : 1;
				}

				if (fieldA > fieldB) {
					return sortDesc ? 1 : -1;
				}

				return 0;
			});
		},
		[options?.columns, sorting]
	);

	const currentData = useMemo(() => {
		const sortedData = sortData(data);
		const start = (page - 1) * pageSize;
		const end = start + pageSize;
		const paginatedData = sortedData.slice(start, end);

		return paginatedData;
	}, [sortData, data, page, pageSize]);

	const onChange = useCallback((listOptions: DataTableListOptions) => {
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const { sortBy, page } = listOptions;

		setPage(page);

		const sort = sortBy[0];

		if (sort && sort.id) {
			setSortBy(sort.id);
			setSortOrder(sort.desc ? 'desc' : 'asc');
		} else {
			setSortBy(undefined);
			setSortOrder(undefined);
		}
	}, []);

	return {
		data: currentData,
		onChange,
		page,
		pageSize,
		sortBy: sorting,
		totalItems: data.length,
	};
}

import { useNavigationItems } from 'entities/page-layout/hooks';
import { Page, PageBody, PageBodyContent, PageBodyLeftBar, PageLeftExternalNavigation } from 'entities/page-layout/ui';
import { PageLeftNavigation } from 'entities/page-layout/ui/page-left-navigation';
import { useFeatures } from 'features/common/featureFlags/featureFlags';
import { FC, useMemo } from 'react';
import { Outlet } from 'react-router';
import { DICTIONARY } from 'shared/constants';
import { LEFT_BAR_NAVIGATION } from 'shared/pageNavigation';
import { useExternalLinks } from 'widgets/external-links/hooks';

export const SettingsPage: FC = () => {
	const defaultLinks = useNavigationItems(LEFT_BAR_NAVIGATION.SITE_SETTINGS_PAGE);
	const { utilities, accessManagement } = useExternalLinks();
	const { showManagedLanguages } = useFeatures();

	const links = useMemo(() => {
		return showManagedLanguages ? defaultLinks : defaultLinks.filter((link) => link.label !== DICTIONARY.LANGUAGES);
	}, [defaultLinks, showManagedLanguages]);

	return (
		<Page data-testid={`collectionPage-`} position="static">
			<PageBody>
				<PageBodyLeftBar gap="4">
					<PageLeftNavigation links={links} />
					<PageLeftExternalNavigation {...utilities} />
					<PageLeftExternalNavigation {...accessManagement} />
				</PageBodyLeftBar>
				<PageBodyContent p="8" pb="6">
					<Outlet />
				</PageBodyContent>
			</PageBody>
		</Page>
	);
};

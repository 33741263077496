import { Box, Button, Card, CardBody, CardFooter, Divider, Flex, Image, Stack, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { useGetThumbnail } from 'features/common/tenants/useGetThumbnail';
import { DICTIONARY } from 'shared/constants';
import { FC } from 'react';
import classes from './TemplateBlock.module.css';

type TemplateBlockProps = {
	name: string;
	templateId: string;
	thumbnailUrl?: string;
	setSelectedTemplateId: (templateId: string) => void;
	description?: string;
};

export const TemplateBlock: FC<TemplateBlockProps> = ({ name, templateId, thumbnailUrl, setSelectedTemplateId, description }) => {
	const thumbnail = useGetThumbnail(thumbnailUrl, false);

	return (
		<Card variant="elevated" minW="72" maxW="72">
			<Box flexGrow={1} w="full" h="80" className={classes.siteTemplateContent} data-testid="sitetemplate-content" overflow="hidden">
				<Box className={classes.siteTemplateImg} w="full" h="full">
					{thumbnail ? (
						<Image objectFit="contain" src={thumbnail} />
					) : (
						<Flex direction="column" justifyContent="center" w="full" h="full">
							<Text fontSize="xl" fontWeight="medium" color="chakra-subtle-text" textAlign="center" w="full">
								<T _str={DICTIONARY.NO_CONTENT} />
							</Text>
						</Flex>
					)}
				</Box>

				<CardBody className={classes.siteTemplateActions} alignContent="center">
					<Stack spacing="4" mt="10">
						<Text textAlign="center" noOfLines={3}>
							{description}
						</Text>
						<Button size="md" width="full" onClick={() => setSelectedTemplateId(templateId)}>
							<T _str={DICTIONARY.SELECT} />
						</Button>
					</Stack>
				</CardBody>
			</Box>
			<Divider color="chakra-border-color" />
			<CardFooter>
				<Text variant="strong" data-testid={name}>
					{name}
				</Text>
			</CardFooter>
		</Card>
	);
};

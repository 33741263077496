import { Box, Flex } from '@chakra-ui/react';
import { RefObject, useCallback, useRef, ReactNode, ReactElement } from 'react';
import classes from './MillerItem.module.css';
import { useColumnScroll } from '../utils/useColumnScroll';
import { MillerItemProps } from './MillerItem';
import { MillerColumn } from './MillerColumn';
import { MillerItemBase } from '../models';

export type MillerItemFactory<T> = (props: MillerItemProps<T>) => ReactNode;

export type MillerActiveIndex = {
	column: number;
	index: number;
};

export type MillerControl<T> = {
	selectItem: (item: T, columnIndex: number) => void;
};

export type MillerColumnsProps<T> = {
	columns: T[][];
	activeIds: (string | undefined)[];
	onClick?: (item: T) => void;
	columnWidth?: number;
	visibleNum?: number;
	millerItemFactory: MillerItemFactory<T>;
};

export const MillerColumns = <T extends MillerItemBase>({
	columns,
	activeIds,
	onClick,
	columnWidth = 300,
	millerItemFactory: millerItemFactory,
}: MillerColumnsProps<T>): ReactElement => {
	const columnsViewRef = useRef<HTMLDivElement | null>();

	const { millerColumnsSize } = useColumnScroll(columns.length, columnWidth, columnsViewRef);

	const onItemSelected = useCallback(
		(item: T) => {
			if (onClick) {
				onClick(item);
			}
		},
		[onClick]
	);

	return (
		<Box data-testid="columns-box" className={classes.wrapper} ref={columnsViewRef as RefObject<HTMLDivElement>}>
			<Flex minW={millerColumnsSize * columnWidth} h="full">
				{columns.map((column: T[], index: number) => (
					<MillerColumn
						key={`${index}`}
						isActiveColumn={index === activeIds.length - 1}
						activeId={activeIds[index]}
						w={columnWidth}
						items={column}
						onClick={(item: T) => onItemSelected(item)}
						millerItemFactory={millerItemFactory}
					/>
				))}
			</Flex>
		</Box>
	);
};

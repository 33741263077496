import { Box, Button, HStack, Text, useDisclosure } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { Select } from 'chakra-react-select';
import { FC, useMemo } from 'react';
import { DICTIONARY } from 'shared/constants';
import { useGetAllPOS } from '../utils/posService';
import { PosPayload } from '../utils/types';
import { CreateModal } from './createPOS/CreateModal';

type PosSelect = {
	value: PosPayload;
	label: string;
};

export const findSelected = (selectedValue: PosPayload | undefined, items: PosSelect[]): PosSelect | undefined => {
	return items.find((i) => i.value.name === selectedValue?.name);
};

export const SelectOrCreatePos: FC<{ intialLanguageIso: string; value: PosPayload | undefined; onChange: (pos?: PosPayload) => void }> = ({
	value,
	intialLanguageIso,
	onChange,
}) => {
	const { posList } = useGetAllPOS();
	const posOptions = useMemo(() => posList.map((p) => ({ value: p, label: `${p.name} (${p.language.toUpperCase()})` })), [posList]);
	const disclosure = useDisclosure();

	return (
		<HStack gap="3">
			<Box width="full">
				<Select
					id="pos"
					options={posOptions}
					value={findSelected(value, posOptions)}
					onChange={(newValue: PosSelect | null) => {
						onChange(newValue?.value);
					}}
					isClearable
					placeholder={<T _str={DICTIONARY.SELECT_IDENTIFIER} />}
				/>
			</Box>
			<Text>
				<T _str={DICTIONARY.OR} />
			</Text>
			<Button variant="outline" onClick={disclosure.onOpen} data-testid="pos-create" minWidth="fit-content">
				<T _str={DICTIONARY.CREATE_NEW} />
			</Button>
			<CreateModal {...disclosure} initialLanguage={intialLanguageIso} onCreated={onChange} />
		</HStack>
	);
};

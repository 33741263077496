import { useXMTenant } from 'features/common/tenants/xmTenantContext';
import { FC } from 'react';
import { AccessDenied } from './AccessDenied';
import { NoTenant } from './NoTenant';

export const XMTenantErrorHandler: FC<React.PropsWithChildren> = ({ children }) => {
	const { tenant, error } = useXMTenant(true);

	if (error) {
		return <AccessDenied />;
	}

	if (!tenant) {
		return <NoTenant />;
	}

	return <>{children}</>;
};

import { useCallback } from 'react';
import { NavigateOptions, useLocation, useNavigate } from 'react-router';
import { REMOVE_QUERY_STRING } from 'shared/constants';

/**
 * It is a wrapper on useNavigate that allows to navigate to a route with the ability to remove query string parameters.
 * Query string params which has to be removed should be passed in location.state[REMOVE_QUERY_STRING].
 * @returns A function that navigates to a route.
 */
export const useNavigateToRoute = () => {
	const navigate = useNavigate();
	const location = useLocation();

	return useCallback(
		(routePath: string, options: NavigateOptions = {}) => {
			const searchParams = new URLSearchParams(location.search);

			if (location.state?.[REMOVE_QUERY_STRING]) {
				location.state[REMOVE_QUERY_STRING].forEach((param: string) => searchParams.delete(param));
			}

			navigate({ ...location, pathname: routePath, search: searchParams.toString() }, options);
		},
		[location, navigate]
	);
};

import { useCallback, useMemo } from 'react';
import { ENDPOINTS } from 'shared/endpoints';
import { PageResponse, mapPage } from 'shared/models';
import { useGet } from 'shared/react-query-wrappers/hooks';
import { usePageStructureEvents } from 'widgets/site-structure/hooks/use-page-structure-events';
import { PageBranchProps } from 'widgets/site-structure/models/props';

export const usePageBranch = (
	props: PageBranchProps & {
		isOpen: boolean;
		setIsOpen: (value: boolean) => void;
	}
) => {
	const { siteId, id, isDeleting: isParentDeleting, parentId, isOpen, setIsOpen } = props;

	const { data, isLoading, refetch } = useGet<PageResponse>({
		queryKey: { url: ENDPOINTS.HIERARCHY_PAGE, params: { pageId: id, siteId } },
		enabled: isOpen,
		refetchOnMount: 'always',
	});

	const page = useMemo(() => (data?.page ? mapPage(data.page) : {}), [data]);

	const children = useMemo(() => data?.children.map((pageData) => mapPage(pageData)) || [], [data]);

	const childrenNames = useMemo(() => children.map((child) => child.name), [children]);

	const siblingNames = useMemo(() => data?.children.map((child) => child.name) || [], [data]);

	const { isDeleting, creating, isDuplicate } = usePageStructureEvents({
		page: props,
		parentId,
		siteId,
		children,
		refetchCurrentBranch: refetch,
		setIsOpen,
	});

	const openAndRefetch = useCallback(
		(value: boolean) => {
			setIsOpen(value);

			if (children.length !== 0) {
				void refetch();
			}
		},
		[children.length, refetch, setIsOpen]
	);

	return {
		isDeleting: isParentDeleting || isDeleting,
		creating,
		isDuplicate,
		children,
		childrenNames,
		page,
		isLoading,
		siblingNames,
		openAndRefetch,
	};
};

import { createContext, FC, useCallback, useContext, useMemo } from 'react';
import { useGetLanguages } from '../utils';
import { LanguageItem } from '../models';
import { useSearchParams } from 'react-router-dom';
import { LANGUAGE_PARAM_NAME } from 'features/common/utils/сlearNonRelevantQueryParams';

export interface LanguageContextType {
	selectedLanguage?: LanguageItem;
	setSelectedLanguage: (value: LanguageItem | undefined) => void;
	languages?: LanguageItem[];
	loadingLanguages: boolean;
}

export const LanguageContext = createContext({} as LanguageContextType);

export const LanguageProvider: FC<React.PropsWithChildren> = ({ children }) => {
	const [searchParams, setUrlSearchParams] = useSearchParams();

	const { languages, loading: loadingLanguages } = useGetLanguages();

	const selectedLanguage = useMemo(() => {
		const languageParam = searchParams.get(LANGUAGE_PARAM_NAME);

		if (!languageParam && languages.length) {
			return languages[0];
		}

		return languages.find((lang) => lang.name === languageParam);
	}, [languages, searchParams]);

	const setSelectedLanguage = useCallback(
		(value: LanguageItem | undefined) => {
			if (!value) {
				searchParams.delete(LANGUAGE_PARAM_NAME);
			} else {
				searchParams.set(LANGUAGE_PARAM_NAME, value.name);
			}

			setUrlSearchParams(searchParams);
		},
		[searchParams, setUrlSearchParams]
	);

	const value: LanguageContextType = useMemo(
		() => ({
			selectedLanguage,
			setSelectedLanguage,
			languages,
			loadingLanguages,
		}),
		[selectedLanguage, setSelectedLanguage, languages, loadingLanguages]
	);

	return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
};

export const useLanguageContext = () => {
	return useContext(LanguageContext);
};

import { format } from 'date-fns';
import { PageBodyContentSection, SectionDataRecord } from 'entities/page-layout/ui';
import { RemoveCollectionButton } from 'features/remove-collection/ui';
import { FormikProps } from 'formik';
import { FC, useEffect } from 'react';
import { useOutletContext } from 'react-router';
import { DATE_FORMAT, DICTIONARY, EMPTY_VALUE } from 'shared/constants';
import { GeneralFormRenameInputs } from 'widgets/collection-settings/models';
import { DiscardUnsavedAlert, GeneralForm } from 'widgets/collection-settings/ui';
import { PortfolioContextProps } from '../models';

export const PortfolioGeneralPage: FC = () => {
	const { collection, dialog, formiks } = useOutletContext<PortfolioContextProps>();
	const createdOn = collection?.created ? format(new Date(collection.created), DATE_FORMAT) : EMPTY_VALUE;

	useEffect(() => {
		const isAnyFormikDirty = formiks && formiks.some((formik) => formik.dirty);
		const isAnyFormikSubmitting = formiks && formiks.some((formik) => formik.isSubmitting);

		if (dialog.dirty.current !== isAnyFormikDirty) {
			dialog.dirty.current = isAnyFormikDirty;
		}

		if (dialog.isSubmitting.current !== isAnyFormikSubmitting) {
			dialog.isSubmitting.current = isAnyFormikSubmitting;
		}
	}, [dialog.dirty, dialog.isSubmitting, formiks]);

	return (
		<>
			<DiscardUnsavedAlert {...dialog} formiks={formiks} />
			<PageBodyContentSection data-testid="general-section" title={DICTIONARY.GENERAL}>
				{collection && <GeneralForm formik={formiks[0] as FormikProps<GeneralFormRenameInputs>} collection={collection} />}
			</PageBodyContentSection>
			<PageBodyContentSection data-testid="danger-zone-section" title={DICTIONARY.DANGER_ZONE}>
				<RemoveCollectionButton id={collection?.id} displayName={collection?.displayName || collection?.name} />
			</PageBodyContentSection>
			<PageBodyContentSection data-testid="details-section" title={DICTIONARY.DETAILS}>
				<SectionDataRecord header={DICTIONARY.CREATED_BY} value={collection?.createdBy ?? EMPTY_VALUE} />
				<SectionDataRecord header={DICTIONARY.CREATED_ON} value={createdOn} />
				<SectionDataRecord header={DICTIONARY.ITEM_PATH} value={(collection?.settings?.itemPath || collection?.itemPath) ?? EMPTY_VALUE} />
			</PageBodyContentSection>
		</>
	);
};

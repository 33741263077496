import { FILTER_PARAM_NAME, SITE_COLLECTION_NAME_PARAM_NAME, TYPE_FILTER_NAME } from 'features/common/utils/сlearNonRelevantQueryParams';
import { TypeFilterUrlParams } from 'features/sites/utils/useTypeFilter';
import { FC, createContext, useCallback, useContext, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DICTIONARY } from 'shared/constants';
import { SiteCollectionViewModel } from '../data/createSiteTypes';
import { useGetSiteCollections } from '../utils/useGetSiteCollections';

export const defalutSiteCollectionValue: SiteCollectionViewModel = {
	id: undefined,
	name: DICTIONARY.ALL_COLLECTIONS,
};

export type SiteFilterContextType = {
	filter: string;
	setFilter: (value: string | undefined) => void;
	siteCollections: SiteCollectionViewModel[];
	siteCollection: SiteCollectionViewModel | undefined;
	setCollection: (value: string | undefined) => void;
	siteType: TypeFilterUrlParams;
	resetSiteType: () => void;
};

export const defaultFilterContext: SiteFilterContextType = {
	filter: '',
	setFilter: () => {},
	siteCollections: [],
	siteCollection: undefined,
	setCollection: () => {},
	siteType: 'none',
	resetSiteType: () => {},
};
export const SiteFilterContext = createContext<SiteFilterContextType>(defaultFilterContext);

export const SiteFilterProvider: FC<React.PropsWithChildren> = ({ children }) => {
	const [searchParams, setUrlSearchParams] = useSearchParams();
	const siteCollections = useGetSiteCollections();

	const filter = useMemo(() => {
		return searchParams.get(FILTER_PARAM_NAME) || '';
	}, [searchParams]);

	const setFilter = useCallback(
		(value: string | undefined) => {
			if (!value) {
				searchParams.delete(FILTER_PARAM_NAME);
				setUrlSearchParams(searchParams);
			} else if (searchParams.has(FILTER_PARAM_NAME)) {
				searchParams.set(FILTER_PARAM_NAME, value);
				setUrlSearchParams(searchParams, { replace: true });
			} else {
				searchParams.set(FILTER_PARAM_NAME, value);
				setUrlSearchParams(searchParams);
			}
		},
		[searchParams, setUrlSearchParams]
	);

	const siteCollection = useMemo(() => {
		const collectionName = searchParams.get(SITE_COLLECTION_NAME_PARAM_NAME);

		return [...siteCollections.slice(1)].find((collection) => collection.name === collectionName) || defalutSiteCollectionValue;
	}, [searchParams, siteCollections]);

	const setCollection = useCallback(
		(value: string | undefined) => {
			if (!value || value === defalutSiteCollectionValue.id) {
				searchParams.delete(SITE_COLLECTION_NAME_PARAM_NAME);
				setUrlSearchParams(searchParams);
			} else {
				const name = siteCollections.find((collection) => collection.id === value)?.name;

				if (name) {
					searchParams.set(SITE_COLLECTION_NAME_PARAM_NAME, name);
					setUrlSearchParams(searchParams);
				}
			}
		},
		[searchParams, setUrlSearchParams, siteCollections]
	);

	const siteType: TypeFilterUrlParams = useMemo(() => {
		const value = searchParams.get(TYPE_FILTER_NAME);

		return value === null ? 'none' : (value as TypeFilterUrlParams);
	}, [searchParams]);

	const resetSiteType = useCallback(() => {
		searchParams.delete(TYPE_FILTER_NAME);
		setUrlSearchParams(searchParams);
	}, [searchParams, setUrlSearchParams]);

	const value: SiteFilterContextType = useMemo(
		() => ({
			filter,
			setFilter,
			siteCollections,
			siteCollection,
			setCollection,
			siteType,
			resetSiteType,
		}),
		[filter, resetSiteType, setCollection, setFilter, siteCollection, siteCollections, siteType]
	);

	return <SiteFilterContext.Provider value={value}>{children}</SiteFilterContext.Provider>;
};

export const useSiteFilterContext: () => SiteFilterContextType = () => {
	return useContext(SiteFilterContext);
};

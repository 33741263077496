import {
	Box,
	Button,
	Flex,
	FormControl,
	FormErrorIcon,
	FormErrorMessage,
	FormHelperText,
	FormLabel,
	Input,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Text,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { Select } from 'chakra-react-select';
import { posPayloadValidationSchema } from 'features/pos/utils/posPayloadValidationSchema';
import { useCreateModal } from 'features/pos/utils/useCreateModal';
import { LanguageSelectItem, findSelectedUniqueUntlLanguage, useGetUniqueUntlLanguages } from 'features/pos/utils/useGetUniqueUntlLanguages';
import { Formik } from 'formik';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { PosPayload } from '../../utils/types';

const formInitialValue: PosPayload = {
	timeout: 30,
	market: 'global',
	brand: 'DEFAULT',
	language: 'en',
	name: '',
};

export const CreateForm: FC<{
	onCancel: () => void;
	onCreated: (newPos: PosPayload) => void;
	initialLanguage?: string;
	predefinedLanguages?: string[];
	lockLanguage?: boolean;
}> = ({ onCancel, onCreated, initialLanguage, predefinedLanguages, lockLanguage }) => {
	const { onSubmit, validate } = useCreateModal(onCreated);
	const languages = useGetUniqueUntlLanguages(predefinedLanguages);
	const initialValue = {
		...formInitialValue,
		language: initialLanguage ?? languages.at(0)?.value ?? '',
	};

	return (
		<Formik<PosPayload>
			initialValues={initialValue}
			onSubmit={onSubmit}
			validate={validate}
			validateOnChange={false}
			validateOnBlur={true}
			validateOnMount={false}
			validationSchema={posPayloadValidationSchema}
		>
			{({ errors, touched, handleChange, values, setFieldValue, handleBlur, submitForm, isSubmitting }) => (
				<Flex direction="column" gap="6" marginBottom="4">
					<Box>
						<Flex direction="column" gap="6">
							<FormControl isRequired={true} isInvalid={!!(errors.name && touched.name)}>
								<FormLabel htmlFor="name">
									<T _str={DICTIONARY.NAME_YOUR_IDENTIFIER} />
								</FormLabel>
								<Input
									type="url"
									name="name"
									id="name"
									value={values.name}
									onChange={handleChange}
									maxLength={50}
									onBlur={handleBlur}
								/>
								<FormHelperText>
									<T _str={DICTIONARY.ENTER_IDENTIFIER_NAME} />
								</FormHelperText>
								<FormErrorMessage>
									<FormErrorIcon />
									<Text>{errors.name}</Text>
								</FormErrorMessage>
							</FormControl>
							<FormControl isRequired={true} isInvalid={!!(errors.language && touched.language)}>
								<FormLabel htmlFor="language">
									<T _str={DICTIONARY.LANGUAGE} />
								</FormLabel>
								<Select
									id="language"
									options={languages}
									isDisabled={lockLanguage}
									inputId="language-input"
									value={findSelectedUniqueUntlLanguage(values.language, languages)}
									onChange={(newValue: LanguageSelectItem | null) => setFieldValue('language', newValue?.value)}
								/>
								<FormHelperText>
									<T _str={DICTIONARY.SELECT_LANGUAGE_FOR_IDENTIFIER} />
								</FormHelperText>
								<FormErrorMessage>
									<FormErrorIcon />
									{errors.language}
								</FormErrorMessage>
							</FormControl>
							<FormControl isRequired={true} isInvalid={!!(errors.market && touched.market)}>
								<FormLabel htmlFor="market">
									<T _str={DICTIONARY.MARKET} />
								</FormLabel>
								<Input type="text" name="market" id="market" value={values.market} onChange={handleChange} maxLength={50} />
								<FormHelperText>
									<T _str={DICTIONARY.ENTER_MARKET_FOR_IDENTIFIER} />
								</FormHelperText>
								<FormErrorMessage>
									<FormErrorIcon />
									<Text>{errors.market}</Text>
								</FormErrorMessage>
							</FormControl>
							<FormControl isRequired={true} isInvalid={!!(errors.brand && touched.brand)}>
								<FormLabel htmlFor="brand">
									<T _str={DICTIONARY.BRAND} />
								</FormLabel>
								<Input type="text" name="brand" id="brand" value={values.brand} onChange={handleChange} maxLength={50} />
								<FormHelperText>
									<T _str={DICTIONARY.ENTER_BRAND_FOR_IDENTIFIER} />
								</FormHelperText>
								<FormErrorMessage>
									<FormErrorIcon />
									<Text>{errors.brand}</Text>
								</FormErrorMessage>
							</FormControl>
							<FormControl isRequired={true} isInvalid={!!(errors.timeout && touched.timeout)}>
								<FormLabel htmlFor="timeout">
									<T _str={DICTIONARY.TIMEOUT_IN_MINUTES} />
								</FormLabel>
								<NumberInput value={values.timeout} onChange={(s) => setFieldValue('timeout', s)}>
									<NumberInputField id="timeout" value={values.timeout} />
									<NumberInputStepper>
										<NumberIncrementStepper />
										<NumberDecrementStepper />
									</NumberInputStepper>
								</NumberInput>
								<FormHelperText>
									<T _str={DICTIONARY.SELECT_NUMBER_OF_MINUTES} />
								</FormHelperText>
								<FormErrorMessage>
									<FormErrorIcon />
									<Text>{errors.timeout}</Text>
								</FormErrorMessage>
							</FormControl>
						</Flex>
					</Box>
					<Flex direction="row-reverse" gap={2}>
						<Button id="createPOSBtn" onClick={() => void submitForm()} isLoading={isSubmitting}>
							<T _str={DICTIONARY.SAVE} />
						</Button>
						<Button onClick={onCancel} variant="ghost">
							<T _str={DICTIONARY.CANCEL} />
						</Button>
					</Flex>
				</Flex>
			)}
		</Formik>
	);
};

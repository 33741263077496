import { ListItem, Text, UnorderedList, useToast } from '@chakra-ui/react';
import { useCallback } from 'react';

export type ShowToastProps = {
	errors: Record<string, string>;
	fieldsDictionary: Record<string, string>;
	title: string;
	description: string | JSX.Element;
	status: 'error' | 'success' | 'warning' | 'info';
};

export const useFormErrorsToast = () => {
	const toast = useToast();

	const showToast = useCallback(
		({ errors, fieldsDictionary, title, description, status }: ShowToastProps) => {
			if (errors && Object.keys(errors).length > 0) {
				const errorFields = Object.keys(errors);

				const toastMessage = (
					<Text>
						{description}
						<UnorderedList marginInlineStart="6">
							{errorFields.map((field) => {
								const fieldName = fieldsDictionary[field];

								return <ListItem key={field}>{fieldName || field}</ListItem>;
							})}
						</UnorderedList>
					</Text>
				);

				toast({ title, description: toastMessage, status });
			}
		},
		[toast]
	);

	return { showToast };
};

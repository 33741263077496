import { Badge, Flex, MenuDivider } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { SkeletonRepeater } from 'entities/skeleton-repeater/ui';
import { ClickableSiteCard } from 'features/clickable-site-card/ui';
import { useFeatures } from 'features/common/featureFlags/featureFlags';
import { SiteDuplicateButtonWrapper } from 'features/duplicate-modal/ui';
import { ExplorerRedirectMenuItem, OpenSiteSettingsMenuItem, PageRedirectMenuItem, RenameSiteMenuItem } from 'features/site-actions-menu/ui';
import { FC } from 'react';
import { useCheckJobStatus } from 'shared/check-job-status';
import { DICTIONARY, SITE_CARD_SIZE } from 'shared/constants';
import { OpenInPagesMenuItem } from 'shared/open-in-pages/ui';
import { PortfolioNoSites } from 'widgets/portfolio/ui/portfolio-no-sites';
import { SitesCreationInProgress } from 'widgets/portfolio/ui/sites-creation-in-progress';
import { PortfolioBodyProps, SiteViewModel } from '../models';

const SharedSiteBadge: FC = () => (
	<Badge colorScheme="blue">
		<T _str={DICTIONARY.SHARED_SITE} />
	</Badge>
);

export const PortfolioBody: FC<PortfolioBodyProps> = ({ items, collectionId, isLoading, isRecentlyCreated }) => {
	const { hasRunningSiteCreationsWithinCollection } = useCheckJobStatus();
	const { showSitesGrouping, showSharedSite } = useFeatures();

	if ((items?.length === 0 && !hasRunningSiteCreationsWithinCollection(collectionId) && !isLoading) || (isLoading && isRecentlyCreated)) {
		return <PortfolioNoSites collectionId={collectionId} />;
	}

	return (
		<Flex minHeight={SITE_CARD_SIZE.HEIGHT} flexWrap="wrap" justify="flex-start" data-testid="portfolio-site-list" gap="4">
			{isLoading && !isRecentlyCreated ? (
				<SkeletonRepeater borderRadius="6" count={2} width={SITE_CARD_SIZE.WIDTH} height="full" isLoaded={false} />
			) : (
				<>
					<SitesCreationInProgress collectionId={collectionId} />
					{items?.map((site: SiteViewModel) => (
						<ClickableSiteCard
							key={site.id}
							{...site}
							badge={showSharedSite && site?.properties?.sharedSite === 'true' ? <SharedSiteBadge /> : undefined}
						>
							<OpenSiteSettingsMenuItem siteId={site.id} />
							<RenameSiteMenuItem
								collectionId={site.collectionId}
								siteAccess={site.access}
								siteName={site.displayName || site.name}
								siteId={site.id}
							/>
							<SiteDuplicateButtonWrapper
								siteName={site.displayName || site.name}
								siteAccess={site.access}
								siteId={site.id}
								description={site.description}
								siteLanguage={site.defaultLanguage}
							/>
							<MenuDivider />
							{showSitesGrouping ? (
								<OpenInPagesMenuItem hosts={site.hosts} />
							) : (
								<>
									<PageRedirectMenuItem siteName={site.name} />
									<ExplorerRedirectMenuItem siteName={site.name} />
								</>
							)}
						</ClickableSiteCard>
					))}
				</>
			)}
		</Flex>
	);
};

import { Button, useDisclosure } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { useCdpTenant } from 'features/common/tenants/CdpTenantProvider';
import { DICTIONARY } from 'shared/constants';
import { FC } from 'react';
import { CreateModal } from './CreateModal';

export const CreateButton: FC = () => {
	const disclosure = useDisclosure();
	const { isEnabled } = useCdpTenant();

	return (
		<>
			<Button variant="solid" minWidth="fit-content" onClick={() => disclosure.onOpen()} isDisabled={!isEnabled} data-testid="createNewPos">
				<T _str={DICTIONARY.CREATE_NEW} />
			</Button>
			<CreateModal {...disclosure} />
		</>
	);
};

import { Formik } from 'formik';
import { SiteDefinitionPageOutlet } from 'pages/site-definition/models/site-definition-props';
import { FC } from 'react';
import { useOutletContext } from 'react-router-dom';
import { LanguageItem } from 'shared/languages/models';
import { SiteDefinitionResponseViewModel } from 'shared/models';
import { siteDefinitionValidationSchema } from 'shared/validations';
import { useGeneralSectionForm } from 'widgets/site-definition-update-form/hooks/use-general-section-form';
import { SiteDefinitionViewModel } from 'widgets/site-definition-update-form/models';
import { GeneralSectionBody } from 'widgets/site-definition-update-form/ui';

export type SiteSettingsFormProps = SiteDefinitionResponseViewModel & { xmAppsLanguages: LanguageItem[] };

export const GeneralSectionForm: FC<SiteSettingsFormProps> = (props) => {
	const { allHostnames, allDefinitionNames, site } = useOutletContext<SiteDefinitionPageOutlet>();
	const { initialValues, submit } = useGeneralSectionForm(props, site?.id || '');

	return (
		<Formik<SiteDefinitionViewModel>
			initialValues={initialValues}
			onSubmit={submit}
			validationSchema={siteDefinitionValidationSchema(allDefinitionNames, allHostnames)}
			validateOnBlur
			enableReinitialize
		>
			{(formikProps) => <GeneralSectionBody {...formikProps} {...props} />}
		</Formik>
	);
};

import { Formik } from 'formik';
import { FC } from 'react';
import { SiteResponse } from 'shared/models';
import { siteSettingsGeneralSectionValidationSchema } from 'shared/validations';
import { useSiteSettingsGeneralSectionForm } from 'widgets/site-settings-update-form/hooks';
import { SiteGeneralViewModel } from 'widgets/site-settings-update-form/models';
import { SiteSettingsGeneralSectionBody } from 'widgets/site-settings-update-form/ui';

export const SiteSettingsGeneralSectionForm: FC<SiteResponse> = (props) => {
	const { initialValues, submit, siteNames } = useSiteSettingsGeneralSectionForm(props);

	return (
		<Formik<SiteGeneralViewModel>
			initialValues={initialValues}
			onSubmit={submit}
			validationSchema={siteSettingsGeneralSectionValidationSchema(siteNames, initialValues.displayName)}
			validateOnBlur
			enableReinitialize
		>
			{(formikProps) => <SiteSettingsGeneralSectionBody {...formikProps} {...props} />}
		</Formik>
	);
};

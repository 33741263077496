import { Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { RenamePageModalProps } from 'features/page-actions-menu/models';
import { RenamePageModalBody } from 'features/page-actions-menu/ui/rename-page-modal-body';
import { FC, PropsWithChildren } from 'react';
import { DICTIONARY } from 'shared/constants';
import { useModalWrapper } from 'shared/modal-wrapper/hooks';
import type { ModalWrapperProps } from 'shared/modal-wrapper/models';

export const RenamePageModal: FC<PropsWithChildren<RenamePageModalProps & ModalWrapperProps>> = (props) => {
	const { isOpen, onClose, children } = useModalWrapper(props);

	return (
		<>
			{children}
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						<T _str={DICTIONARY.RENAME_PAGE} name={props.displayName} />
					</ModalHeader>
					<ModalCloseButton />
					<RenamePageModalBody onClose={onClose} {...props} />
				</ModalContent>
			</Modal>
		</>
	);
};

import debounce from 'lodash/debounce';
import { RefObject, useEffect, useMemo, useState, useTransition } from 'react';
import { DEBOUNCE_EVENT_TIME } from 'shared/config';

export const useScroll = (ref: RefObject<HTMLDivElement>) => {
	const [hasScrolled, setHasScrolled] = useState<boolean>(false);
	const [, startTransition] = useTransition();

	const onScroll = useMemo(
		() =>
			debounce(
				(event: Event) => {
					const target = event.target as HTMLDivElement;
					const { scrollTop } = target;

					startTransition(() => {
						setHasScrolled(!!scrollTop);
					});
				},
				DEBOUNCE_EVENT_TIME,
				{ leading: true }
			),
		[]
	);

	useEffect(() => {
		const target = ref?.current;

		if (target) {
			target.addEventListener('scroll', onScroll);
		}

		return () => {
			if (target) {
				target.removeEventListener('scroll', onScroll);
			}
		};
	}, [onScroll, ref]);

	return { hasScrolled };
};

import { Box, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
// eslint-disable-next-line import/named
import { Select, SingleValue } from 'chakra-react-select';
import { ThumbnailItem } from 'entities/thumbnail/ui';
import { useTranslate } from 'features/common/utils/translateUtils';
import { useThumbnailAutoWithDefinitions } from 'features/thumbnail/hooks';
import { OnChangeThumbnailProxyProps, ThumbnailSelectItem } from 'features/thumbnail/models';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { SiteResponse } from 'shared/models';

export const ThumbnailAutoWithDefinitions: FC<{
	site: SiteResponse | undefined;
	eventHandler?: OnChangeThumbnailProxyProps;
}> = ({ site, eventHandler }) => {
	const { hostItems, currentDefinition, thumbnailFileInfo, setCurrentDefinition } = useThumbnailAutoWithDefinitions(site);
	const t = useTranslate();

	return (
		<>
			<Box width="md">
				<Select
					placeholder={t(DICTIONARY.SELECT)}
					noOptionsMessage={() => t(DICTIONARY.NO_SEARCH_RESULT)}
					selectedOptionStyle="check"
					options={hostItems}
					value={currentDefinition}
					inputId="definitions-select"
					isDisabled={hostItems.length === 1}
					onChange={(value: SingleValue<ThumbnailSelectItem>) => {
						if (value) {
							setCurrentDefinition(value);

							if (eventHandler) {
								eventHandler(value, 'newThumbnailHostId');
							}
						}
					}}
				/>
			</Box>
			{thumbnailFileInfo && <ThumbnailItem thumbnail={thumbnailFileInfo} />}
			<Text variant="small">
				<T _str={DICTIONARY.THUMBNAIL_SITE_DEFINITIONS_HINT} />
			</Text>
		</>
	);
};

import { Flex, Skeleton, Td, Tr } from '@chakra-ui/react';
import { FC } from 'react';
import { SiteDefinitionsTableProps } from 'widgets/site-definitions-table/models';

type SiteDefinitionsTableRowLoadingProps = { cells: number } & SiteDefinitionsTableProps;

export const SiteDefinitionsTableRowLoading: FC<SiteDefinitionsTableRowLoadingProps> = ({ hostsToCreateCount, cells }) => {
	return (
		<>
			{Array.from({ length: hostsToCreateCount ?? 0 }, (__, i) => i + 1).map((__, rowIndex) => (
				<Tr key={`sc-sd-row-loading-${rowIndex}`} data-testid={`sc-sd-row-loading-${rowIndex}`}>
					<Td>
						<Flex gap="3" alignItems="center" width="full">
							<Skeleton w="16" h="10" />
							<Skeleton w="full" h="5" />
						</Flex>
					</Td>
					{Array.from({ length: cells - 1 }, (___, i) => i + 1).map((___, cellIndex) => (
						<Td key={`sc-sd-cell-loading-${cellIndex}`} data-testid={`sc-sd-cell-loading-${cellIndex}`}>
							<Skeleton h="5" />
						</Td>
					))}
					<Td />
				</Tr>
			))}
		</>
	);
};

import { AppState, Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import config from '../config/config';
import { getAuthSettings } from '../config/envConfig';
import { getAuthProtocolParameters } from './authProtocolParameters';

const authProtocolParams = getAuthProtocolParameters();

export const Auth0ProviderWithHistory: React.FC<React.PropsWithChildren> = ({ children }) => {
	const { domain, clientId, audience } = getAuthSettings();

	const onRedirectCallback = (appState?: AppState) => {
		const location = window.location;

		if (appState?.returnTo && location.pathname !== appState?.returnTo) {
			window.history.pushState({}, '', appState.returnTo);
		}
	};

	if (!domain || !clientId || !audience) {
		throw new Error('Missing auth provider properties');
	}

	return (
		<Auth0Provider
			domain={domain}
			clientId={clientId}
			onRedirectCallback={onRedirectCallback}
			useRefreshTokens={true}
			authorizationParams={{
				audience: audience,
				redirect_uri: window.location.origin,
				organization_id: authProtocolParams?.organization,
				tenant_name: authProtocolParams?.tenantName,
				system_id: config.inventory.xmSystemId,
			}}
		>
			{children}
		</Auth0Provider>
	);
};

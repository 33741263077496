import { CreateSiteModalProps } from 'features/create-site/models';
import { CreateSiteModal } from 'features/create-site/ui/create-site-modal';
import { FC, PropsWithChildren } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CREATE_SITE_MODAL_HASHTAG } from 'shared/constants';
import { useModalWrapper } from 'shared/modal-wrapper/hooks';

export const CreateSiteModalWrapper: FC<PropsWithChildren<CreateSiteModalProps>> = ({ collectionId, ...props }) => {
	const navigate = useNavigate();
	const { pathname, search } = useLocation();
	const { isOpen, onClose, children } = useModalWrapper({
		...props,
		onButtonClick: () => {
			navigate(`${pathname}${search}${CREATE_SITE_MODAL_HASHTAG}`);
		},
	});

	return (
		<>
			{children}
			<CreateSiteModal onClose={onClose} isOpen={isOpen} collectionId={collectionId} />
		</>
	);
};

import { QueryKey } from '@tanstack/react-query';
import axios from 'axios';
import { useAuthWithClaims } from 'features/common/auth/useAuthWithClaims';
import { sitecoreXMappsApiURL } from 'features/common/config/envConfig';
import { useCallback } from 'react';

export const useAxios = () => {
	const { getAccessTokenSilently } = useAuthWithClaims();

	const axiosInstance = axios.create({
		baseURL: sitecoreXMappsApiURL,
		headers: { 'Content-Type': 'application/json' },
	});

	axiosInstance.interceptors.request.use(async (request) => {
		const token = await getAccessTokenSilently();

		if (request && request.headers) {
			request.headers.Authorization = `Bearer ${token}`;
		}

		return request;
	});

	const defaultQueryFn = useCallback(
		async ({ queryKey }: { queryKey: QueryKey }) => {
			const url = queryKey[0] as string;

			if (!url) {
				throw new Error('URL is required and cannot be empty.');
			}

			return axiosInstance.get(queryKey[0] as string);
		},
		[axiosInstance]
	);

	return { axiosInstance, defaultQueryFn };
};

import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { useMemo } from 'react';
import { defalutSiteCollectionValue } from '../components/SiteFilterContext';
import { SiteCollections, SiteCollectionViewModel } from '../data/createSiteTypes';
import { getSiteCollectionsList } from '../queries';
import { useTranslate } from 'features/common/utils/translateUtils';

export const useGetSiteCollections = (): SiteCollectionViewModel[] => {
	const { data } = useQuery<SiteCollections>(getSiteCollectionsList);
	const t = useTranslate();

	const siteCollections = useMemo(() => {
		const defaultSiteCollection = { ...defalutSiteCollectionValue, name: t(defalutSiteCollectionValue.name) };
		const collections = data?.siteCollections;

		if (!collections) {
			return [defaultSiteCollection];
		}

		let collectionsModel = collections.map<SiteCollectionViewModel>((collection) => ({
			id: collection.id,
			name: collection.displayName,
		}));

		collectionsModel = _.uniqBy(collectionsModel, (c) => c.name);
		collectionsModel = _.sortBy(collectionsModel, [(c) => c.name]);

		return [defaultSiteCollection, ...collectionsModel];
	}, [data?.siteCollections, t]);

	return siteCollections;
};

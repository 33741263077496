import { useGetHostsWithPoS } from 'features/point-of-sale/hooks/get-hosts-with-pos';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetAllPoS } from 'shared/api';
import { PoSEntity } from 'widgets/pos-table/models';
import { POS_PAGE_SEARCH_PARAM, POS_PAGE_SIZE, POS_SEARCH_PARAM } from 'widgets/pos-table/pos-constants';

export const useGetPoSData = () => {
	const { posList, isLoading: isPosLoading, refetch: refetchPos } = useGetAllPoS();
	const [searchParams] = useSearchParams();
	const { hosts, isLoading: isHostsLoading } = useGetHostsWithPoS();

	const { search, currentPage } = useMemo(() => {
		return {
			search: searchParams.get(POS_SEARCH_PARAM) || '',
			currentPage: parseInt(searchParams.get(POS_PAGE_SEARCH_PARAM) || '1') ?? 1,
		};
	}, [searchParams]);

	const initialSearchValue = useMemo(() => {
		const querystring = new URLSearchParams(window.location.search);

		return querystring.get(POS_SEARCH_PARAM) || '';
	}, []);

	const posEntities = useMemo(() => {
		const mapResult = posList?.map<PoSEntity>((pos) => {
			const assignedHosts = hosts?.filter((host) => host.posIdentifiers.includes(pos.name));

			return {
				name: pos.name,
				timeout: pos.timeout,
				assignedHosts: assignedHosts ?? [],
			};
		});

		return mapResult || [];
	}, [posList, hosts]);

	const { posData, totalPages, realCurrentPage } = useMemo(() => {
		const list =
			search && search.length >= 2
				? posEntities.filter((pos) => {
						const hasPosMatch = pos.name.toLowerCase().includes(search.toLowerCase());
						const hasHostMatch = pos.assignedHosts.some((host) => host.name.toLowerCase().includes(search.toLowerCase()));

						return hasPosMatch || hasHostMatch;
				  })
				: posEntities;
		let skip = (currentPage - 1) * POS_PAGE_SIZE;
		let realPageNumberPage = currentPage;

		if (skip > list.length) {
			const overflow = Math.ceil(Math.abs(list.length - skip) / POS_PAGE_SIZE);

			realPageNumberPage = currentPage - overflow;
			skip = (realPageNumberPage - 1) * POS_PAGE_SIZE;
		}

		if (skip === list.length) {
			skip = (--realPageNumberPage - 1) * POS_PAGE_SIZE;
		}

		const take = skip + POS_PAGE_SIZE;
		const pages = Math.ceil(list.length / POS_PAGE_SIZE);
		const data = list.slice(skip, take);

		return { posData: data, totalPages: pages, realCurrentPage: realPageNumberPage };
	}, [posEntities, search, currentPage]);

	return {
		posData,
		initialSearchValue,
		hasSearch: !!search,
		currentPage: realCurrentPage,
		totalPages,
		isLoading: isPosLoading || isHostsLoading,
		refetchPos,
	};
};

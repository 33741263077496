import { IconButton, Tooltip, useDisclosure } from '@chakra-ui/react';
import { mdiMinusCircleOutline } from '@mdi/js';
import { Icon } from 'entities/icon/ui';
import { useTranslate } from 'features/common/utils/translateUtils';
import { PosModalWrapperPropsEA } from 'features/point-of-sale/models';
import { UnassignPosModalEA } from 'features/point-of-sale/ui/unassign-pos-modal-ea';
import { FC, PropsWithChildren } from 'react';
import { DICTIONARY } from 'shared/constants';

/** @deprecated */
export const UnassignPosIconButtonEA: FC<PropsWithChildren<PosModalWrapperPropsEA>> = (props) => {
	const { isOpen, onClose, onOpen } = useDisclosure();
	const t = useTranslate();

	return (
		<>
			<Tooltip label={DICTIONARY.UNASSIGN}>
				<IconButton
					size="sm"
					variant="ghost"
					aria-label={t(DICTIONARY.UNASSIGN_ANALYTICS_IDENTIFIER)}
					icon={<Icon path={mdiMinusCircleOutline} onClick={onOpen} />}
				/>
			</Tooltip>
			<UnassignPosModalEA {...props} isOpen={isOpen} onClose={onClose} />
		</>
	);
};

import { ThumbnailItem } from 'entities/thumbnail/ui';
import { OnChangeThumbnailProxyProps } from 'features/thumbnail/models';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { SiteDefinitionResponseViewModel } from 'shared/models';
import { parseFile } from 'shared/utils/parse-file';
import { useThumbnailAuto } from '../hooks/use-thumbnail-auto';

export const ThumbnailAuto: FC<{
	site: SiteDefinitionResponseViewModel;
	isAutoRegenerating?: boolean;
	eventHandler?: OnChangeThumbnailProxyProps;
}> = ({ site, isAutoRegenerating, eventHandler }) => {
	const thumbnailFileInfo = parseFile(site?.thumbnailUrl, site?.thumbnailBase64, site?.id);
	const { isRegenerating, onClickRegenerate } = useThumbnailAuto(site);

	return (
		<ThumbnailItem
			thumbnail={thumbnailFileInfo}
			buttonTitle={DICTIONARY.REGENERATE}
			buttonCallback={onClickRegenerate}
			buttonSpinner={isRegenerating || isAutoRegenerating}
			loadingText={DICTIONARY.REGENERATING}
			eventHandler={eventHandler}
		/>
	);
};

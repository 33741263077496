import { Box, Step, StepIcon, StepIndicator, StepNumber, StepSeparator, StepStatus, StepTitle, Stepper } from '@chakra-ui/react';
import { FC } from 'react';
import { CreateSiteStepperProps } from '../models';

export const CreateSiteStepper: FC<CreateSiteStepperProps> = ({ steps, currentStep = 0, ...props }) => {
	return (
		<Stepper index={currentStep} {...props}>
			{steps.map((step, index) => (
				<Step key={index}>
					<StepIndicator>
						<StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
					</StepIndicator>
					<Box flexShrink="0">
						<StepTitle>{step.title}</StepTitle>
					</Box>
					<StepSeparator />
				</Step>
			))}
		</Stepper>
	);
};

import { Box, Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { mdiChevronDown } from '@mdi/js';
import { Icon } from 'entities/icon/ui';
import { FC } from 'react';
import { useLanguageContext } from './LanguageProvider';

export const LanguageSwitcher: FC = () => {
	const { selectedLanguage, setSelectedLanguage, languages } = useLanguageContext();

	return (
		<Box>
			<Menu>
				<MenuButton
					as={Button}
					size="md"
					rightIcon={<Icon path={mdiChevronDown} layerStyle="menuButtonIcon" />}
					variant="outline"
					borderRadius="md"
					data-testid="selectedLanguage"
				>
					{selectedLanguage?.englishName}
				</MenuButton>
				<MenuList>
					{languages &&
						languages.map((lang, index) => (
							<MenuItem key={index} data-testid="language" onClick={() => setSelectedLanguage(lang)}>
								{lang.englishName}
							</MenuItem>
						))}
				</MenuList>
			</Menu>
		</Box>
	);
};

import { ItemType } from 'features/millerColumns/utils';
import { TypeFilterUrlParams } from 'features/sites/utils/useTypeFilter';
import { FC } from 'react';
import { SiteMenu } from './SiteMenu';

export const VariousButton: FC<{ type: ItemType; name?: string; siteType?: TypeFilterUrlParams }> = ({ type, name, siteType }) => {
	if (type === 'site' && name) {
		return <SiteMenu siteName={name} siteType={siteType} />;
	}

	return <></>;
};

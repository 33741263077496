import { useDisclosure } from '@chakra-ui/react';
import { useDuplicateSite } from 'features/sites/utils/useDuplicateSite';
import { createContext, FC, Fragment, useContext, useMemo } from 'react';
import { DuplicateSiteModal } from './DuplicateSiteModal';

export type DuplicateKey = {
	siteName: string;
	cloneName: string;
};

export type DuplicateSiteContextType = {
	sitesInProgress: DuplicateKey[];
	openDuplicateSiteModal: (siteName: string) => void;
	removeSitesInProgress: (cloneNames: string[]) => void;
};

const DuplicateSiteContext = createContext<DuplicateSiteContextType>({
	sitesInProgress: [],
	openDuplicateSiteModal: () => {},
	removeSitesInProgress: () => {},
});

export const DuplicateSiteProvider: FC<React.PropsWithChildren> = ({ children }) => {
	const { isOpen, onClose, onOpen } = useDisclosure();
	const { siteName, duplicateSite, openDuplicateSiteModal, removeSitesInProgress, sitesInProgress } = useDuplicateSite(onOpen, onClose);

	const value = useMemo(() => {
		return {
			sitesInProgress,
			removeSitesInProgress,
			openDuplicateSiteModal,
		};
	}, [openDuplicateSiteModal, removeSitesInProgress, sitesInProgress]);

	return (
		<Fragment>
			<DuplicateSiteContext.Provider value={value}>{children}</DuplicateSiteContext.Provider>
			<DuplicateSiteModal isOpen={isOpen} onClose={onClose} siteName={siteName} onSubmit={duplicateSite}></DuplicateSiteModal>
		</Fragment>
	);
};

export const useDuplicateSiteProvider = () => {
	return useContext(DuplicateSiteContext);
};

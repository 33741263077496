import { Flex, Progress, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';

export const ThumbnailUploading: FC = () => (
	<Flex
		alignSelf="stretch"
		alignItems="center"
		backgroundColor="chakra-body-bg"
		border="1px"
		borderColor="chakra-border-color"
		borderRadius="md"
		borderStyle="solid"
		gap="3"
		flexDir="column"
		justifyContent="flex-end"
		minW="sm"
		maxW="xl"
		minH="28"
		h="128"
		mt="2"
		pt="4"
		position="relative"
	>
		<Flex p="1" justifyContent="center" alignItems="center" alignSelf="stretch">
			<Progress isIndeterminate flex="1 0 0" alignItems="flex-start" h="1" />
		</Flex>
		<Text
			color="chakra-body-text"
			fontSize="13px"
			fontWeight="400"
			textAlign="center"
			position="absolute"
			top="50%"
			transform="translateY(-50%)"
			userSelect="none"
		>
			<T _str={DICTIONARY.THUMBNAIL.MANUAL_UPLOADING} />
		</Text>
	</Flex>
);

import { Box, Flex, Heading } from '@chakra-ui/react';
import { DetailedItemBase } from 'features/millerColumns/utils';
import { FC } from 'react';
import { WorkflowBadge } from '../WorkflowBadge';
import { ActionsPanel } from './ActionsPanel';
import { FieldDetails } from './FieldDetails';
import { Thumbnail } from './Thumbnail';
import { DICTIONARY } from 'features/millerColumns/constants';
import { useSiteTypeName } from 'features/sites/utils/useSiteTypeName';
import { useIsFeatureAvailable } from 'features/common/tenantVersionPrivider/components/TenantVersionProvider';

export const DetailsSection: FC<DetailedItemBase> = ({ currentItem, itemType, currentSite }) => {
	const siteTypeName = useSiteTypeName(currentSite?.siteTypeByCollection);
	const { isAvaialable } = useIsFeatureAvailable('NonSXASites');
	const isSite = itemType === 'site';

	if (!currentItem) {
		return <></>;
	}

	return (
		<Flex
			direction="column"
			width="lg"
			borderLeft="1px"
			borderColor="chakra-border-color"
			data-testid="detailsSection"
			h="full"
			overflowY="auto"
			gap="1"
			p="6"
		>
			<ActionsPanel currentItem={currentItem} itemType={itemType} currentSite={currentSite} />
			<Heading size="lg" pt="3">
				{currentItem.displayName}
			</Heading>
			<Box>
				<WorkflowBadge isFinal={currentItem.isFinalWorkflowState} style="fill" stateName={currentItem.workflowStateName} />
			</Box>
			<Flex justifyContent="center" alignItems="center">
				<Thumbnail item={currentItem} siteAlternativeImageUrl="/bg-panel.png" type={itemType} />
			</Flex>
			<Flex direction="column" gap="5">
				<FieldDetails name={DICTIONARY.ITEM_ID} value={currentItem.itemId} />
				<FieldDetails name={DICTIONARY.ITEM_NAME} value={currentItem.name} />
				<FieldDetails name={DICTIONARY.ITEM_PATH} value={currentItem.path} />
				<FieldDetails name={DICTIONARY.TEMPLATE} value={`${currentItem.templatePath} - \n${currentItem.templateId}`} />
				{isSite && isAvaialable && <FieldDetails name={DICTIONARY.SITE_TYPE} value={siteTypeName} />}
				<FieldDetails name={DICTIONARY.ITEM_OWNER} value={currentItem.itemOwner} />
				<FieldDetails name={DICTIONARY.DATE_OF_CREATION} value={currentItem.dateOfCreation} />
				<FieldDetails name={DICTIONARY.UPDATED_BY} value={currentItem.updatedBy} />
				<FieldDetails name={DICTIONARY.DATE_OF_UPDATE} value={currentItem.dateOfUpdate} />
				<FieldDetails name={DICTIONARY.WORKFLOW} value={currentItem.workflowStateName} alt={DICTIONARY.NO_WORKFLOW} />
				{itemType == 'site' && currentSite?.siteTypeByCollection === 'sxa' && (
					<FieldDetails name={DICTIONARY.SITE_COLLECTION} value={currentSite?.siteCollectionName} alt={DICTIONARY.NO_WORKFLOW} />
				)}
			</Flex>
		</Flex>
	);
};

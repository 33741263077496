import { Flex } from '@chakra-ui/react';
import { useShowMore } from 'features/show-more/hooks';
import { FC } from 'react';
import { usePortfolio } from '../hooks';
import { PortfolioProps } from '../models';
import { PortfolioBody } from './portfolio-body';
import { PortfolioFooter } from './portfolio-footer';
import { PortfolioHeader } from './portfolio-header';

export const Portfolio: FC<PortfolioProps> = (props) => {
	const { sites, isLoading, isRecentlyCreated } = usePortfolio(props.id);
	const { showMoreItem, toggleMore } = useShowMore(sites);

	return (
		<Flex direction="column" padding="5" background="neutral.50" borderRadius="md" gap="4" data-testid="portfolio">
			<PortfolioHeader sitesCount={sites?.length} isLoading={isLoading} isRecentlyCreated={isRecentlyCreated(props.name)} {...props} />
			<PortfolioBody
				items={showMoreItem.items}
				collectionId={props.id}
				isLoading={isLoading && !sites}
				isRecentlyCreated={isRecentlyCreated(props.name)}
			/>
			<PortfolioFooter isShowLess={showMoreItem.isShowLess} isShowMore={showMoreItem.isShowMore} onToggleMoreClick={toggleMore} />
		</Flex>
	);
};

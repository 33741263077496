import { useCallback, useState } from 'react';
import type { ItemTreeProps, TreeViewProps } from 'shared/item-tree/models';

export const useTreeView = (props: TreeViewProps & ItemTreeProps) => {
	const { isParent, siteId, childrenItem, useGetItem, isOpened, expandOnSelect, setSelectedIds, ancestors } = props;
	const [opened, setOpened] = useState(isParent || isOpened || false);
	const { data, isLoading } = useGetItem(siteId, childrenItem?.id, opened);

	const checkAncestor = useCallback(
		(ancestorId: string): boolean => {
			if (!ancestors || !ancestors.length) return false;

			return !!ancestors.some((s) => s.id === ancestorId);
		},
		[ancestors]
	);

	const onBranchOpen = useCallback((value: boolean) => {
		setOpened(value);
	}, []);

	const openBranchOnClick = useCallback(
		(values: string[]) => {
			setSelectedIds(values);

			if (expandOnSelect && childrenItem?.hasChildren && !opened) {
				setOpened(true);
			}
		},
		[expandOnSelect, childrenItem?.hasChildren, opened, setSelectedIds]
	);

	return {
		ancestors,
		opened,
		data,
		isLoading,
		checkAncestor,
		onBranchOpen,
		openBranchOnClick,
	};
};

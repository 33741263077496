import { Button, ButtonGroup, ModalBody, ModalFooter, UseDisclosureReturn } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FormFieldControl } from 'entities/form-field-control/ui';
import { useCheckSiteDefinitionNameUniqueness } from 'features/site-actions-menu/hooks';

import { useRenameSiteDefinition } from 'features/site-definition-actions-menu/hooks';
import { RenameSiteDefinitionModalInputProps, RenameSiteDefinitionModalProps } from 'features/site-definition-actions-menu/models';
import { Field, Form, Formik } from 'formik';
import { FC, PropsWithChildren } from 'react';
import { DICTIONARY, VALIDATIONS } from 'shared/constants';
import { ModalWrapperProps } from 'shared/modal-wrapper/models';
import type { FormikFieldProps } from 'shared/models';
import { NonPredictiveInput } from 'shared/utils';

type Props = PropsWithChildren<Pick<UseDisclosureReturn, 'onClose'> & RenameSiteDefinitionModalProps & ModalWrapperProps>;

export const RenameSiteDefinitionModalBody: FC<Props> = ({ onClose, siteId, hostId, name, collectionId }) => {
	const { siteDefinitionNameValidationSchema, handleRename } = useRenameSiteDefinition({ siteId, hostId, collectionId });
	const validateSiteDefinitionUniqueness = useCheckSiteDefinitionNameUniqueness({ originalValue: name, formKey: 'name' });

	const submitHandler = (values: RenameSiteDefinitionModalInputProps) => {
		if (values.name === name) {
			onClose();

			return;
		}

		void handleRename(values, onClose);
	};

	return (
		<Formik
			initialValues={{ name: name || '' }}
			validationSchema={siteDefinitionNameValidationSchema}
			validate={(values) => validateSiteDefinitionUniqueness(values.name)}
			onSubmit={submitHandler}
			validateOnBlur={false}
			validateOnChange={false}
			validateOnMount={false}
		>
			{({ isSubmitting }) => (
				<Form noValidate>
					<ModalBody>
						<Field name="name" validate>
							{({ field, form }: FormikFieldProps<string>) => (
								<FormFieldControl
									form={form}
									field={field}
									name={field.name}
									label={DICTIONARY.NAME}
									maxLength={VALIDATIONS.NAME_VALIDATION_NUMBER}
									isRequired
								>
									<NonPredictiveInput
										{...field}
										autoFocus
										data-testid="rename-site-definition-input"
										maxLength={VALIDATIONS.NAME_VALIDATION_NUMBER}
									/>
								</FormFieldControl>
							)}
						</Field>
					</ModalBody>
					<ModalFooter>
						<ButtonGroup>
							<Button variant="ghost" onClick={onClose} isDisabled={isSubmitting}>
								<T _str={DICTIONARY.CANCEL} />
							</Button>
							<Button isLoading={isSubmitting} type="submit">
								<T _str={DICTIONARY.RENAME} />
							</Button>
						</ButtonGroup>
					</ModalFooter>
				</Form>
			)}
		</Formik>
	);
};

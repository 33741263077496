import { Input, Stack, Textarea } from '@chakra-ui/react';
import { FormFieldProps } from 'entities/form-field-control/models';
import { FormFieldControl } from 'entities/form-field-control/ui';
import { useFeatures } from 'features/common/featureFlags/featureFlags';
import { useSiteDetailsFormBody } from 'features/create-site/hooks';
import { FieldProps, SiteDetailsFormProps } from 'features/create-site/models';
import { CollectionField } from 'features/create-site/ui/collection-field';
import { Field, Form } from 'formik';
import { ChangeEvent, FC } from 'react';
import { DEFAULT_SITE_DESCRIPTION_LENGTH, DEFAULT_SITE_NAME_LENGTH, DICTIONARY } from 'shared/constants';
import { LanguageSupportedField, MultiLanguagesField } from 'shared/languages/ui';

export const SiteDetailsFormBody: FC<SiteDetailsFormProps & FieldProps> = ({
	collectionId,
	onFormChange,
	handleChange,
	setFieldTouched,
	siteDetailsFormData,
	onSiteDetailsFormDataChange,
	...props
}) => {
	const { handleFieldChange, onMultiLanguagesFieldChange, canCreateLanguage } = useSiteDetailsFormBody(
		onSiteDetailsFormDataChange,
		siteDetailsFormData,
		onFormChange,
		setFieldTouched,
		handleChange
	);
	const { showManagedLanguages } = useFeatures();

	return (
		<Form>
			<Stack gap="6" {...props}>
				<Field name="displayName">
					{({ field, form }: FormFieldProps<string>) => (
						<FormFieldControl
							form={form}
							field={field}
							name={field.name}
							isRequired
							maxLength={DEFAULT_SITE_NAME_LENGTH}
							label={DICTIONARY.NAME}
							helper={DICTIONARY.SITE_NAME_XM_CLOUD_INFO_MESSAGE}
						>
							<Input
								data-testid="meta-name"
								{...field}
								value={siteDetailsFormData.displayName}
								maxLength={DEFAULT_SITE_NAME_LENGTH}
								onBlurCapture={(e) => handleFieldChange({ displayName: e.target.value })}
								onChangeCapture={(e: ChangeEvent<HTMLInputElement>) => handleFieldChange({ displayName: e.target.value })}
							/>
						</FormFieldControl>
					)}
				</Field>
				<Field name="description">
					{({ field, form }: FormFieldProps<string>) => (
						<FormFieldControl
							form={form}
							field={field}
							name={field.name}
							maxLength={DEFAULT_SITE_DESCRIPTION_LENGTH}
							label={DICTIONARY.DESCRIPTION}
						>
							<Textarea
								{...field}
								maxLength={DEFAULT_SITE_DESCRIPTION_LENGTH}
								value={siteDetailsFormData.description}
								onChangeCapture={(e: ChangeEvent<HTMLTextAreaElement>) => handleFieldChange({ description: e.target.value })}
								data-testid="description"
							/>
						</FormFieldControl>
					)}
				</Field>
				{!collectionId && (
					<CollectionField
						collection={siteDetailsFormData.collection}
						setFieldTouched={setFieldTouched}
						onCollectionChange={(collection) => handleFieldChange({ collection })}
						handleChange={handleChange}
						useBasicStyles
					/>
				)}
				{showManagedLanguages && (
					<MultiLanguagesField
						selectedLanguages={siteDetailsFormData.languages}
						setFieldTouched={setFieldTouched}
						onLanguageChange={onMultiLanguagesFieldChange}
						shouldShowSupportedLanguages={canCreateLanguage}
						helper={DICTIONARY.LANGUAGES_HINT}
						useBasicStyles
					/>
				)}
				<LanguageSupportedField
					language={siteDetailsFormData.language}
					languages={showManagedLanguages ? siteDetailsFormData.languages : undefined}
					setFieldTouched={setFieldTouched}
					onLanguageChange={(language) => handleFieldChange({ language })}
					handleChange={handleChange}
					useBasicStyles
					isDisabled={showManagedLanguages && siteDetailsFormData.languages?.length === 0}
				/>
			</Stack>
		</Form>
	);
};

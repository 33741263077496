import { Button, ButtonGroup, Flex, FormControl, FormLabel, ModalBody, ModalFooter, Stack, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { Select } from 'chakra-react-select';
import { InfoPopover } from 'entities/info-popover/ui';
import { useTranslate } from 'features/common/utils/translateUtils';
import { useCreateLanguageData } from 'features/languages/hooks';
import { CreateLanguageValue, LanguageModalBodyProps } from 'features/languages/models';
import { FC, useEffect, useState } from 'react';
import { DICTIONARY } from 'shared/constants';

export const LanguageModalBody: FC<LanguageModalBodyProps> = ({
	language,
	fallbackLanguage,
	isLanguageLocked,
	onClose,
	handleSubmit,
	submitText = DICTIONARY.ADD,
}) => {
	const { createLanguagesOptions, fallbackLanguagesOptions } = useCreateLanguageData();
	const t = useTranslate();
	const [value, setValue] = useState<CreateLanguageValue>({ language: undefined, fallbackLanguage: undefined });

	useEffect(() => {
		if (!fallbackLanguagesOptions || !fallbackLanguagesOptions.length) {
			return;
		}

		setValue({
			language: fallbackLanguagesOptions.find((option) => option.value === language),
			fallbackLanguage: fallbackLanguagesOptions.find((option) => option.value === fallbackLanguage),
		});
	}, [language, fallbackLanguage, fallbackLanguagesOptions, setValue]);

	const hasValue = !!value.language?.value;

	return (
		<>
			<ModalBody data-testid="language-modal-body" display="flex" flexDir="column" gap="6" overflow="visible">
				<FormControl isRequired>
					<FormLabel marginBottom="2">
						<T _str={DICTIONARY.LANGUAGE} />
					</FormLabel>
					<Select
						isDisabled={isLanguageLocked}
						placeholder={t(DICTIONARY.SELECT)}
						noOptionsMessage={() => t(DICTIONARY.NO_SEARCH_RESULT)}
						selectedOptionStyle="check"
						isClearable
						options={createLanguagesOptions}
						value={value.language}
						className="language-select-field"
						onChange={(selectedOption) => setValue((prev) => ({ ...prev, language: selectedOption || undefined }))}
					/>
				</FormControl>
				<FormControl>
					<Flex alignItems="center" marginBottom="2">
						<FormLabel marginBottom="unset" marginRight="unset">
							<T _str={DICTIONARY.FALLBACK_LANGUAGE} />
						</FormLabel>
						<InfoPopover header={DICTIONARY.FALLBACK_LANGUAGE} body={DICTIONARY.FALLBACK_LANGUAGE_DESCRIPTION} />
					</Flex>
					<Select
						useBasicStyles
						placeholder={t(DICTIONARY.SELECT)}
						noOptionsMessage={() => t(DICTIONARY.NO_SEARCH_RESULT)}
						selectedOptionStyle="check"
						isClearable
						options={fallbackLanguagesOptions.filter((option) => option.value !== language)}
						value={value.fallbackLanguage || undefined}
						className="fallback-language-select-field"
						onChange={(selectedOption) => setValue((prev) => ({ ...prev, fallbackLanguage: selectedOption || undefined }))}
					/>
				</FormControl>
				{hasValue && (
					<Stack gap="1">
						<FormLabel marginBottom="unset" marginRight="unset">
							<T _str={DICTIONARY.LANGUAGE_CODE} />
						</FormLabel>
						<Text data-testid="language-code-value">{value.language?.value}</Text>
					</Stack>
				)}
			</ModalBody>
			<ModalFooter data-testid="language-modal-footer">
				<ButtonGroup>
					<Button variant="ghost" onClick={onClose} data-testid="sc-create-language-cancel">
						<T _str={DICTIONARY.CANCEL} />
					</Button>
					<Button
						isDisabled={!hasValue}
						data-testid="sc-handle-language"
						onClick={() =>
							void handleSubmit({ language: value?.language?.value || '', fallbackLanguage: value?.fallbackLanguage?.value || '' })
						}
					>
						<T _str={submitText} />
					</Button>
				</ButtonGroup>
			</ModalFooter>
		</>
	);
};

import { Flex, Heading, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { DICTIONARY } from 'shared/constants';
import { FC } from 'react';

export const NoTenant: FC = () => {
	return (
		<Flex mt={56} color="chakra-body-text" alignItems="center" flexDirection="column">
			<Heading size="sm" mb={7}>
				<T _str={DICTIONARY.NO_TENANT_CHOSEN} />
			</Heading>
			<Text lineHeight="30px">
				<T _str={DICTIONARY.NO_TENANT_CHOSEN_DESCRIPTION} />
			</Text>
			<Text lineHeight="30px">
				<T _str={DICTIONARY.SELECT_ANOTHER_TENANT} />
			</Text>
		</Flex>
	);
};

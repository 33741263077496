import { useFormikContext } from 'formik';
import { FC } from 'react';
import { usePreventDiscardChanges } from './PreventDiscradChanges';

export const RegisterDiscardChanges: FC<React.PropsWithChildren> = ({ children }) => {
	const { resetForm } = useFormikContext();

	usePreventDiscardChanges(resetForm);

	return <>{children}</>;
};

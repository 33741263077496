import { Input, Textarea } from '@chakra-ui/react';
import { FormFieldProps } from 'entities/form-field-control/models';
import { FormFieldControl } from 'entities/form-field-control/ui';
import { Thumbnail } from 'features/thumbnail/ui';
import { Field, FormikProps } from 'formik';
import { SitePageOutlet } from 'pages/site/models';
import { FC } from 'react';
import { useOutletContext } from 'react-router';
import { DEFAULT_SITE_DESCRIPTION_LENGTH, DEFAULT_SITE_NAME_LENGTH, DICTIONARY } from 'shared/constants';
import { SiteResponse } from 'shared/models';
import { useSiteSettingsGeneralSectionBody } from 'widgets/site-settings-update-form/hooks';
import { SiteGeneralViewModel } from 'widgets/site-settings-update-form/models';
import { ControlFieldSystemName } from 'widgets/site-settings-update-form/ui/control-field-system-name';

export const SiteSettingsGeneralSectionBody: FC<FormikProps<SiteGeneralViewModel> & Pick<SiteResponse, 'id' | 'name'>> = ({
	values,
	handleChange,
	submitForm,
	resetForm,
	id,
	initialValues,
	name,
}) => {
	const { onChangeProxy, onChangeThumbnailProxy } = useSiteSettingsGeneralSectionBody({
		handleChange,
		submitForm,
		resetForm,
		initialValues,
		values,
	});
	const { site } = useOutletContext<SitePageOutlet>();

	return (
		<>
			<Field name="displayName">
				{({ field, form }: FormFieldProps) => (
					<FormFieldControl
						form={form}
						field={field}
						name="displayName"
						maxLength={DEFAULT_SITE_NAME_LENGTH}
						label={DICTIONARY.NAME}
						helper={DICTIONARY.SITE_NAME_XM_CLOUD_INFO_MESSAGE}
						minW="xs"
						maxW="xl"
						isRequired
					>
						<Input type="text" {...field} value={values.displayName} onChange={onChangeProxy} maxLength={DEFAULT_SITE_NAME_LENGTH} />
					</FormFieldControl>
				)}
			</Field>
			{!!name && <ControlFieldSystemName value={name} siteId={id} collectionId={site?.collectionId || ''} />}
			<Field name="description">
				{({ field, form }: FormFieldProps) => (
					<FormFieldControl
						form={form}
						field={field}
						name="description"
						maxLength={DEFAULT_SITE_DESCRIPTION_LENGTH}
						label={DICTIONARY.DESCRIPTION}
						minW="xs"
						maxW="xl"
					>
						<Textarea {...field} value={values.description || ''} onChange={onChangeProxy} maxLength={DEFAULT_SITE_DESCRIPTION_LENGTH} />
					</FormFieldControl>
				)}
			</Field>
			{site && <Thumbnail site={site} autoLabel={DICTIONARY.AUTOMATIC} eventHandler={onChangeThumbnailProxy} />}
		</>
	);
};

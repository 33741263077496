import { Badge, Flex, Tbody, Td, Text, Tooltip, Tr } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { CreateAssignPosIconButtonEA, UnassignPosIconButtonEA } from 'features/point-of-sale/ui';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { SitePosTableBodyPropsEA } from 'widgets/site-pos-table/models';
import { MissingPosLabel } from 'widgets/site-pos-table/ui/missing-pos-label';
import { PosInfoPopoverEA } from 'widgets/site-pos-table/ui/pos-info-popover-ea';

/**
 * @deprecated
 */
export const SitePosTableBodyEA: FC<SitePosTableBodyPropsEA> = ({ site, languages, posList }) => {
	return (
		<Tbody>
			{languages
				?.sort((a, b) => a.label.localeCompare(b.label))
				.map((lang, index) => {
					const posIdentifier = Object.entries(site?.pointsOfSale || {}).find(
						([key]) => key.toLocaleLowerCase() === lang.value.toLocaleLowerCase()
					);
					const identifier = posList.find((pos) => pos.name.toLocaleLowerCase() === posIdentifier?.[1].toLocaleLowerCase());

					return (
						<Tr key={`${lang.value}-${index}`}>
							<Td maxW="0">
								<Tooltip label={lang.label}>
									<Text variant="strong" noOfLines={2} maxWidth="max-content">
										{lang.label}
									</Text>
								</Tooltip>
							</Td>
							<Td maxW="0">
								<Tooltip
									label={
										posIdentifier && !identifier ? <T _str={DICTIONARY.ANALYTICS_IDENTIFIER_DOES_NOT_EXIST} /> : identifier?.name
									}
								>
									<Flex noOfLines={2} maxWidth="max-content">
										{posIdentifier && !identifier && <MissingPosLabel identifier={posIdentifier?.[1]} />}
										{posIdentifier && identifier && <PosInfoPopoverEA identifier={identifier} />}
										{!posIdentifier && <Badge>{DICTIONARY.NOT_ASSIGNED}</Badge>}
									</Flex>
								</Tooltip>
							</Td>
							<Td width="8">
								{site && (!posIdentifier || !identifier) && <CreateAssignPosIconButtonEA {...site} siteLanguage={lang.value} />}
								{site && posIdentifier && identifier && <UnassignPosIconButtonEA {...site} siteLanguage={lang.value} />}
							</Td>
						</Tr>
					);
				})}
		</Tbody>
	);
};

import { useMemo } from 'react';
import { ENDPOINTS } from 'shared/endpoints';
import { LanguageItem, LanguageResponse, UseGetAllLanguages } from 'shared/languages/models';
import { useSimpleGet } from 'shared/react-query-wrappers/hooks';

/**
 * Gets all list of languages (supported and system) without duplication.
 * If [supportedLanguages] is false, it will return only the list of system languages.
 * @param enabled Checks if the hook should send a request or not.
 * @param shouldInclude Checks which type of languages has to be included to the list of languages. There are two types of languages: supported and system.
 */
export const useGetLanguages: UseGetAllLanguages = (enabled = true, shouldInclude = { supportedLanguages: true, systemLanguages: true }) => {
	const { data: languages, isLoading: isLanguagesLoading } = useSimpleGet<LanguageResponse[]>(
		ENDPOINTS.LANGUAGES_SUPPORTED,
		enabled && shouldInclude.supportedLanguages
	);

	const { data: systemLanguages, isLoading: isSystemLanguagesLoading } = useSimpleGet<LanguageResponse[]>(
		ENDPOINTS.LANGUAGES,
		enabled && shouldInclude.systemLanguages
	);

	const allLanguages = useMemo<LanguageItem[]>(() => {
		if (!systemLanguages || (!languages && shouldInclude.supportedLanguages)) {
			return [];
		}

		let langs: LanguageResponse[] = [];

		if (languages && shouldInclude.supportedLanguages) {
			langs = [...languages];

			systemLanguages.forEach((lang) => {
				if (!langs.find((l) => l.name === lang.name)) {
					langs.push(lang);
				}
			});
		} else {
			langs = [...systemLanguages];
		}

		return langs.map((item) => ({ value: item.name, label: item.englishName })).sort((a, b) => a.label.localeCompare(b.label));
	}, [systemLanguages, languages, shouldInclude.supportedLanguages]);

	return {
		data: allLanguages,
		isLoading: isSystemLanguagesLoading || isLanguagesLoading,
	};
};

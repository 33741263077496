import { Badge, Box } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { TypeFilterUrlParams } from 'features/sites/utils/useTypeFilter';
import { FC } from 'react';
import { useSiteTypeName } from '../utils/useSiteTypeName';

export const SiteTypeBadge: FC<{ siteType: TypeFilterUrlParams | undefined }> = ({ siteType }) => {
	const badgeText = useSiteTypeName(siteType);

	return (
		<Box position="absolute" top="2" right="3">
			<Badge variant="solid">
				<T _str={badgeText} />
			</Badge>
		</Box>
	);
};

import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { SiteTemplatePayload, SolutionTemplates } from '../data/createSiteTypes';
import { getTemplatesQuery } from '../queries';

export const useGetTemplateList = (): SiteTemplatePayload[] => {
	const { data, loading } = useQuery<SolutionTemplates>(getTemplatesQuery);

	const templates = useMemo(() => {
		if (loading || !data?.solutionTemplates) {
			return [];
		}

		return data.solutionTemplates
			.map((template) => {
				const templateName = template.name.endsWith('Site') ? template.name : `${template.name} Site`;

				return {
					...template,
					name: templateName,
				};
			})
			.sort((a, b) => Number(b.enabled) - Number(a.enabled));
	}, [data, loading]);

	return templates;
};

import { Box, Flex, FlexProps, HStack, Image, Link, TableRowProps, Tbody, Td, Text, Tooltip, Tr } from '@chakra-ui/react';
import { MediaLightbox } from 'entities/media-lightbox/ui';
import { MenuIconButton } from 'entities/menu-icon-button/ui';
import fallbackSrc from 'entities/site-card/images/thumbnail-fallback.svg';
import { RenameSiteDefinitionMenuItem } from 'features/site-definition-actions-menu/ui';
import { DuplicateSiteDefinitionMenuItem } from 'features/site-definitions/ui';
import { SitePageOutlet } from 'pages/site/models';
import { FC, useCallback, useState } from 'react';
import { useOutletContext, useParams } from 'react-router';
import { SITE_DEFINITION_REMOVING_LOCAL_STORAGE_KEY, TOOLTIP_SHOW_DELAY } from 'shared/constants';
import { useNavigateToRoute } from 'shared/navigation/hooks';
import { OverflowTooltip } from 'shared/overflow-tooltip/ui';
import { ROUTES } from 'shared/routes';
import { srcFormatter } from 'shared/utils';
import { useLocalStorage } from 'usehooks-ts';
import { SiteDefinitionsTableBodyProps } from 'widgets/site-definitions-table/models';
import { SiteDefinitionMissingLanguage } from 'widgets/site-definitions-table/ui/site-definition-missing-language';
import { SiteDefinitionsTableRowLoading } from 'widgets/site-definitions-table/ui/site-definitions-table-row-loading';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/styles.css';

const overlayStyles = {
	position: 'absolute',
	borderRadius: 'sm',
	boxSize: 'full',
	top: 0,
	cursor: 'pointer',
} as FlexProps;

const rowBlockStyles = { pointerEvents: 'none', opacity: '0.4' } as TableRowProps;

export const SiteDefinitionsTableBody: FC<SiteDefinitionsTableBodyProps> = ({ hostsToCreateCount, allLanguages, systemLanguages }) => {
	const { hosts, site, languages } = useOutletContext<SitePageOutlet>();
	const navigateToRoute = useNavigateToRoute();
	const params = useParams();
	const [hostsToDuplicateCount, setHostsToDuplicateCount] = useState(0);
	const skeletonCount = (hostsToCreateCount ?? 0) + hostsToDuplicateCount;
	const [removingHostId] = useLocalStorage<string | undefined>(SITE_DEFINITION_REMOVING_LOCAL_STORAGE_KEY, undefined);

	const handleClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault();
		e.stopPropagation();
		const el = e.currentTarget;

		window.open(el.href, el.target);
	}, []);

	const redirectToDefinition = useCallback(
		(id: string) => {
			navigateToRoute(`/${ROUTES.SITE.NAME}/${params?.id || ''}/${ROUTES.SITE.DEFINITIONS.NAME}/${id}`);
		},
		[navigateToRoute, params?.id]
	);

	const getBlockedRowStyles = useCallback((id: string) => (removingHostId === id ? rowBlockStyles : undefined), [removingHostId]);

	return (
		<Tbody>
			{hosts &&
				hosts.map((host) => {
					return (
						<Tr
							key={host.id}
							data-testid={`host-row-${host.id}`}
							onClick={() => redirectToDefinition(host.id)}
							cursor="pointer"
							_hover={{ bg: 'blackAlpha.100' }}
							{...getBlockedRowStyles(host.id)}
						>
							<Td maxW="0" minW="60">
								<HStack gap="3">
									<Box position="relative">
										<MediaLightbox
											imageUrl={srcFormatter(host.thumbnail.base64 || host.thumbnail.url) || ''}
											title={host.name}
											alt={`${host.name} thumbnail`}
											overlayStyles={overlayStyles}
										>
											<Box borderRadius="sm" w="14" h="1.93594rem" overflow="hidden">
												<Image
													fallbackSrc={fallbackSrc}
													objectFit="cover"
													src={srcFormatter(host.thumbnail.base64 || host.thumbnail.url)}
												/>
											</Box>
										</MediaLightbox>
									</Box>
									<Tooltip label={host.name} openDelay={TOOLTIP_SHOW_DELAY}>
										<Text variant="strong" noOfLines={2} maxW="max-content">
											{host.name}
										</Text>
									</Tooltip>
								</HStack>
							</Td>
							<Td maxW="0">
								<SiteDefinitionMissingLanguage
									defaultLanguage={host.languageSettings.defaultLanguage}
									siteLanguages={languages || []}
									systemLanguages={systemLanguages || []}
									allLanguages={allLanguages || []}
								/>
							</Td>
							<Td maxW="0">
								{host.hostnames.map((name, index) => {
									return name.includes('.') ? (
										<OverflowTooltip key={`${host.id}-${index}`} label={name}>
											<Link href={`https://${name}`} isExternal maxW="max-content" onClick={handleClick}>
												{name}
											</Link>
										</OverflowTooltip>
									) : (
										<OverflowTooltip key={`${host.id}-${index}`} label={name}>
											<Text isTruncated>{name}</Text>
										</OverflowTooltip>
									);
								})}
							</Td>
							<Td maxW="0">
								{host.targetHostname.includes('.') ? (
									<OverflowTooltip label={host.targetHostname}>
										<Link href={`https://${host.targetHostname}`} isExternal onClick={handleClick}>
											{host.targetHostname}
										</Link>
									</OverflowTooltip>
								) : (
									<OverflowTooltip label={host.targetHostname}>
										<Text isTruncated>{host.targetHostname}</Text>
									</OverflowTooltip>
								)}
							</Td>
							<Td maxW="0">
								<Tooltip label={host.renderingHost.appName} openDelay={TOOLTIP_SHOW_DELAY}>
									<Text noOfLines={2} maxW="max-content">
										{host.renderingHost.appName}
									</Text>
								</Tooltip>
							</Td>
							<Td width="8">
								<Flex onClick={(e) => e.stopPropagation()}>
									<MenuIconButton size="sm">
										<RenameSiteDefinitionMenuItem
											collectionId={site?.collectionId || ''}
											siteAccess={host.permissions}
											name={host.name}
											siteId={site?.id || ''}
											hostId={host.id}
										/>
										<DuplicateSiteDefinitionMenuItem
											siteId={site?.id || ''}
											collectionId={site?.collectionId || ''}
											siteName={site?.displayName || site?.name || ''}
											languages={languages || []}
											canCreate={site?.access?.canCreate || false}
											onStart={() => setHostsToDuplicateCount((s) => ++s)}
											onEnd={() => setHostsToDuplicateCount((s) => --s)}
											host={host}
										/>
									</MenuIconButton>
								</Flex>
							</Td>
						</Tr>
					);
				})}
			<SiteDefinitionsTableRowLoading hostsToCreateCount={skeletonCount} cells={5} />
		</Tbody>
	);
};

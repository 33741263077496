import { Flex, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
// import { useTranslate } from 'features/common/utils/translateUtils';
import { DICTIONARY } from 'shared/constants';
import { FC } from 'react';

export const PosNotAvailableText: FC = () => {
	// const t = useTranslate();
	return (
		<Flex gap="1" alignItems="center">
			<Text>
				<T _str={DICTIONARY.NOT_AVAILABLE} />
			</Text>
			{/* <Tooltip label={t(DICTIONARY.NEXT_POS_NONSXA_SITE_TOOLTIP)}>
				<Box>
					<Icon path={mdiInformationOutline} fontSize="xs" color="neutral" />
				</Box>
			</Tooltip> */}
		</Flex>
	);
};

// eslint-disable-next-line import/named
import { SingleValue } from 'chakra-react-select';
import { useMemo, useState } from 'react';
import { useGetAllPoS } from 'shared/api';
import { ENDPOINTS } from 'shared/endpoints';
import { SiteResponse } from 'shared/models';
import { useGet } from 'shared/react-query-wrappers/hooks';

type POSOption = {
	label: string;
	value: string;
	timeout: number;
};

/** @deprecated */
export const useAssignPosFormEA = () => {
	const { posList } = useGetAllPoS();

	const [option, setOption] = useState<SingleValue<POSOption>>();

	const { data } = useGet<SiteResponse[]>({
		queryKey: { url: ENDPOINTS.POS_SITES_OLD, params: { pos: encodeURIComponent(option?.value || '') } },
		enabled: !!option,
		ignoreGlobalErrorHandler: true,
	});

	const posOptions: POSOption[] = useMemo(
		() =>
			posList.map((pos) => ({
				label: pos.name,
				value: pos.name,
				timeout: pos.timeout,
			})),
		[posList]
	);

	return {
		option,
		setOption,
		posOptions,
		sitesList: data,
	};
};

export const onDrop = () => {
	const pages = document.getElementsByClassName('page');

	Array.from(pages).forEach((page) => {
		page.classList.remove('drag-target');
	});
};

export const onPageDragStart = () => {
	const pages = document.getElementsByClassName('page');

	Array.from(pages).forEach((page) => {
		page.classList.add('drag-target');
	});
};

import { MenuItem, Tooltip } from '@chakra-ui/react';
import { mdiFormTextbox } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { useTranslate } from 'features/common/utils/translateUtils';
import { RenamePageMenuItemProps } from 'features/page-actions-menu/models';
import { RenamePageModal } from 'features/page-actions-menu/ui/rename-page-modal';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';

export const RenamePageMenuItem: FC<RenamePageMenuItemProps> = ({ canRename, ...props }) => {
	const t = useTranslate();

	return (
		<Tooltip label={!canRename ? t(DICTIONARY.ACCESS_DENIED.PAGE_RENAME) : ''} shouldWrapChildren>
			<RenamePageModal {...props}>
				<MenuItem icon={<Icon path={mdiFormTextbox} />} isDisabled={!canRename} data-testid="rename-page-menu-item">
					<T _str={DICTIONARY.RENAME} />
				</MenuItem>
			</RenamePageModal>
		</Tooltip>
	);
};

import { useReactQueryWrappersProvider } from 'app/react-query-wrappers-provider/hooks';
import { useCallback } from 'react';
import { useQueryKey } from 'shared/react-query-wrappers/hooks/use-query-key';
import { CommonQueryKey } from 'shared/react-query-wrappers/models';
import { disableGlobalErrorHandler } from 'shared/react-query-wrappers/utils/disable-global-error-handler';

const dummyQueryKey = { url: '' };

export const usePrefetchData = () => {
	const { axios, queryClient } = useReactQueryWrappersProvider();
	const { calculateKey } = useQueryKey(dummyQueryKey);

	const fetchRequest = useCallback((url: string) => disableGlobalErrorHandler(axios.get(url), true), [axios]);

	return useCallback(
		(queryKeys: CommonQueryKey[]) => {
			queryKeys.forEach((queryKey) => {
				const key = calculateKey(queryKey.url, queryKey.params);

				void queryClient.prefetchQuery({ queryKey: key.queryKey, queryFn: () => fetchRequest(key.url) });
			});
		},
		[queryClient, calculateKey, fetchRequest]
	);
};

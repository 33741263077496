import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { useUnassignPosModal } from 'features/point-of-sale/hooks';
import { PosModalProps } from 'features/point-of-sale/models';
import { FC, useRef } from 'react';
import { DICTIONARY } from 'shared/constants';

export const UnassignPosModal: FC<PosModalProps> = (props) => {
	const { isOpen, onClose } = props;
	const cancelRef = useRef<HTMLButtonElement>(null);

	const { unassignPos, isPending } = useUnassignPosModal(props);

	return (
		<AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize="lg" fontWeight="bold">
						<T _str={DICTIONARY.UNASSIGN_ANALYTICS_IDENTIFIER} />
					</AlertDialogHeader>
					<AlertDialogBody>
						<T _str={DICTIONARY.UNASSIGN_ANALYTICS_IDENTIFIER_DESCRIPTION} />
					</AlertDialogBody>
					<AlertDialogFooter>
						<Button variant="ghost" onClick={onClose} isDisabled={isPending}>
							<T _str={DICTIONARY.CANCEL} />
						</Button>
						<Button
							colorScheme="danger"
							isLoading={isPending}
							loadingText={<T _str={DICTIONARY.UNASSIGNING} />}
							onClick={() => void unassignPos()}
							data-testid="unassign-pos-modal-button"
						>
							<T _str={DICTIONARY.UNASSIGN} />
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};

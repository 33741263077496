import { Link } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC, Fragment, useMemo } from 'react';
import { DICTIONARY } from 'shared/constants';
import { Host, SiteResponse } from 'shared/models';
import { ROUTES } from 'shared/routes';

type HostLink = {
	id: string;
	name: string;
	siteId: string;
};

export const PosSitesList: FC<{ sitesList: SiteResponse[]; identifier: string }> = ({ sitesList, identifier }) => {
	const hosts = useMemo(() => {
		return sitesList
			?.flatMap<HostLink[]>((site) => {
				const filteredHosts = site.hosts.filter((host: Host) => Object.values(host.analyticsIdentifiers || {}).includes(identifier ?? ''));

				return filteredHosts.map((host) => ({ id: host.id, name: host.name, siteId: site.id }));
			})
			.flat();
	}, [sitesList, identifier]);

	return (
		<>
			{hosts.map((host, index) => (
				<Fragment key={host.id}>
					<Link
						href={`/${ROUTES.SITE.NAME}/${host.siteId}/${ROUTES.SITE.DEFINITIONS.NAME}/${host.id}/${ROUTES.SITE.ANALYTICS_AND_PERSONALIZATION}`}
						target="_blank"
					>
						{host.name}
					</Link>
					{index < hosts.length - 1 && <T _str={`${DICTIONARY.COMMA} `} />}
				</Fragment>
			))}
		</>
	);
};

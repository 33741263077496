import { Box, Flex, HStack, Heading, Image, Spacer, Text, VStack } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { DashboardLoadingScreen } from 'features/common/components/DashboardLoadingScreen';
import { MillerContainer } from 'features/millerColumns/components';
import { LanguageSwitcher } from 'features/millerColumns/components/LangaugeSwitcher';
import { LanguageProvider } from 'features/millerColumns/components/LanguageProvider';
import { TypeFilterBCWrapper } from 'features/sites/components/TypeFilterBCWrapper';
import { FC } from 'react';
import { DICTIONARY, SC_ICONS_SRC } from 'shared/constants';
import { useSitesAndJobsList } from '../utils/useSitesAndJobsList';
import { useViewSwitcherModes } from '../utils/useViewSwitcherModes';
import { CreateWebsiteButton } from './CreateWebsiteButton';
import { SiteCollectionsFilter } from './SiteCollectionsFilter';
import { SiteFilter } from './SiteFilter';
import { SiteFilterProvider } from './SiteFilterContext';
import { SitesCardView } from './SitesCardView';
import { ViewSwitcher } from './ViewSwitcher';

export const SitesLayout: FC = () => {
	const { jobs, sites, refetch, loading } = useSitesAndJobsList();
	const [viewMode, setViewMode] = useViewSwitcherModes();

	if (loading) {
		return (
			<Box marginTop={40}>
				<DashboardLoadingScreen text="Loading sites..." />
			</Box>
		);
	}

	if (!sites.length && !jobs.length) {
		return (
			<VStack>
				<Box height={28} />
				<Image src={SC_ICONS_SRC.NEUTRAL_CACTUS} height={20} />
				<Heading size="sm">
					<T _str={DICTIONARY.NO_SITES_CREATED_YET} />
				</Heading>
				<Text>
					<T _str={DICTIONARY.BE_THE_FIRST_TO_CREATE_ONE} />
				</Text>
				<CreateWebsiteButton
					buttonProps={{
						id: 'FirstWebsiteButton',
						variant: 'solid',
						size: 'md',
					}}
					refetchJobsList={refetch}
				>
					<T _str={DICTIONARY.CREATE_WEBSITE} />
				</CreateWebsiteButton>
			</VStack>
		);
	}

	return (
		<LanguageProvider>
			<SiteFilterProvider>
				<Flex direction="column" flexGrow={1} gap="0" height="full" minHeight="full">
					<HStack px="8" py="4" gap="6">
						<ViewSwitcher mode={viewMode} onModeChange={setViewMode} />
						{viewMode === 'tiles' && <SiteFilter />}
						<Flex gap="2">
							{viewMode === 'tiles' && <SiteCollectionsFilter />}
							<TypeFilterBCWrapper />
							{viewMode === 'columns' && <LanguageSwitcher />}
						</Flex>
						<Spacer />
						<CreateWebsiteButton buttonProps={{ id: 'CreateWebsiteButton', size: 'md' }} refetchJobsList={refetch}>
							<T _str={DICTIONARY.CREATE_WEBSITE} />
						</CreateWebsiteButton>
					</HStack>
					<Box flex={1} overflowY="auto">
						{viewMode == 'tiles' && <SitesCardView sites={sites} jobs={jobs} />}
						{viewMode == 'columns' && <MillerContainer />}
					</Box>
				</Flex>
			</SiteFilterProvider>
		</LanguageProvider>
	);
};

import { useTablePageSort } from 'features/common/tableSort/useTablePageSort';
import { DataTableListOptions, DataTableSortingRule } from 'features/temporary/DataTable';
import { useGetAllPOS } from './posService';
import { PosPayload } from './types';

type PosTableData = {
	isLoading: boolean;
	currentPageData: PosPayload[];
	totalItems: number;
	sortBy: DataTableSortingRule[];
	handleChange: (opts: DataTableListOptions) => void;
	page: number;
};

export const posListPageSize = 10;

export const usePosList = (): PosTableData => {
	const { posList, isLoading } = useGetAllPOS();

	const { data, onChange, page, sortBy, totalItems } = useTablePageSort(posList, {
		pageSize: posListPageSize,
	});

	return {
		isLoading: isLoading,
		currentPageData: data,
		handleChange: onChange,
		page: page,
		sortBy: sortBy,
		totalItems: totalItems,
	};
};

import { Box, Text } from '@chakra-ui/react';
import { useCdpTenant } from 'features/common/tenants/CdpTenantProvider';
import { useTranslate } from 'features/common/utils/translateUtils';
import { DataTable, DataTableColumn } from 'features/temporary/DataTable';
import { FC, useMemo } from 'react';
import { DICTIONARY } from 'shared/constants';
import { PosPayload } from '../utils/types';
import { posListPageSize, usePosList } from '../utils/usePosList';

export const PosList: FC = () => {
	const t = useTranslate();
	const { isEnabled } = useCdpTenant();
	const { isLoading, currentPageData, handleChange, page, sortBy, totalItems } = usePosList();
	const columns: DataTableColumn<PosPayload>[] = useMemo(() => {
		return [
			{
				Header: t(DICTIONARY.IDENTIFIER_NAME),
				accessor: (pos: PosPayload) => (
					<Text title={pos.name} noOfLines={1} maxWidth="28" data-testid="posName">
						{pos.name}
					</Text>
				),
				disableSortBy: true,
			},
			{
				Header: t(DICTIONARY.LANGUAGE),
				accessor: (pos: PosPayload) => pos.language.toUpperCase(),
				disableSortBy: true,
			},
			{
				Header: t(DICTIONARY.MARKET),
				accessor: (pos: PosPayload) => (
					<Text title={pos.market} noOfLines={1} maxWidth="28">
						{pos.market}
					</Text>
				),
				disableSortBy: true,
			},
			{
				Header: t(DICTIONARY.BRAND),
				accessor: (pos: PosPayload) => (
					<Text title={pos.brand} noOfLines={1} maxWidth="28">
						{pos.brand}
					</Text>
				),
				disableSortBy: true,
			},
			{
				Header: t(DICTIONARY.TIMEOUT_IN_MINUTES),
				accessor: 'timeout',
				disableSortBy: true,
			},
		];
	}, [t]);

	return (
		<Box visibility={isEnabled ? 'visible' : 'hidden'}>
			<DataTable
				width="100%"
				isLoading={isLoading}
				data={currentPageData}
				totalItems={totalItems}
				sortBy={sortBy}
				pageSize={posListPageSize}
				onChange={handleChange}
				columns={columns}
				page={page}
				pagination="complex"
			/>
		</Box>
	);
};

import { CollectionCreationProgress } from 'entities/collection-creation-progress/ui';
import { useTranslate } from 'features/common/utils/translateUtils';
import { FC } from 'react';
import { useCheckJobStatus } from 'shared/check-job-status';
import { DICTIONARY } from 'shared/constants';

export const CollectionsCreationInProgress: FC = () => {
	const { getRunningCollectionCreations } = useCheckJobStatus();
	const t = useTranslate();

	return (
		<>
			{getRunningCollectionCreations()?.map(
				(collection) =>
					!collection.done && (
						<CollectionCreationProgress
							key={collection.siteCollection}
							text={t(DICTIONARY.CREATING_COLLECTION, { collection: collection.siteCollection })}
						/>
					)
			)}
		</>
	);
};

import { FC, createContext, useContext, useMemo } from 'react';
import { useGetTenantById } from './tenants';
import { useXMTenant } from './xmTenantContext';

export type CdpTenant = {
	apiUrl: string;
	clientKey: string;
};

export type CdpTenantContextModel = {
	state: 'loading' | 'resolved' | 'unresolved';
	tenant?: CdpTenant;
	isEnabled: boolean;
};

const defaultCdpContext: CdpTenantContextModel = {
	state: 'loading',
	isEnabled: false,
};

export const CdpTenantContext = createContext<CdpTenantContextModel>(defaultCdpContext);

export const CdpTenantProvider: FC<React.PropsWithChildren> = ({ children }) => {
	const { tenant } = useXMTenant(true);
	const cdpEnabled = tenant?.cdpId != undefined;
	const { data, isLoading, isError } = useGetTenantById(tenant?.cdpId || '', cdpEnabled);

	const cdpTenant = useMemo<CdpTenantContextModel>(() => {
		if (!cdpEnabled || isError) {
			return { state: 'unresolved', isEnabled: false };
		}

		if (isLoading) {
			return { state: 'loading', isEnabled: false };
		}

		if (!data?.annotations.apiURL) {
			return { state: 'unresolved', isEnabled: false };
		}

		return { state: 'resolved', tenant: { apiUrl: data.annotations.apiURL, clientKey: data.labels.clientKey }, isEnabled: true };
	}, [cdpEnabled, data?.annotations.apiURL, data?.labels.clientKey, isError, isLoading]);

	return <CdpTenantContext.Provider value={cdpTenant}>{children}</CdpTenantContext.Provider>;
};

export const useCdpTenant = () => {
	return useContext(CdpTenantContext);
};

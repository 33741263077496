import { Flex, Heading, Image, Stack, StackProps, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC, PropsWithChildren } from 'react';
import { SC_ICONS_SRC } from 'shared/constants';

type EmptyContentAreaProps = {
	title: string;
	description?: string;
};

export const EmptyContentArea: FC<PropsWithChildren<EmptyContentAreaProps & StackProps>> = ({ title, description, children, ...props }) => {
	return (
		<Stack gap="6" width="full" {...props}>
			<Flex justifyContent="center">
				<Image boxSize="20" src={SC_ICONS_SRC.NEUTRAL_CACTUS} />
			</Flex>
			<Stack gap="2" alignItems="center">
				<Heading size="sm">
					<T _str={title} />
				</Heading>
				{description && (
					<Text>
						<T _str={description} />
					</Text>
				)}
			</Stack>
			{children}
		</Stack>
	);
};

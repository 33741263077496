import { Button, Flex, Heading, Menu, MenuButton, MenuList, Skeleton, SkeletonText } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { CreateCollectionMenuItem } from 'features/collections/ui';
import { CreateSiteMenuItem } from 'features/create-site/ui/create-site-menu-item';
import { useCheckPermissions } from 'features/site-permissions/hooks';
import { DICTIONARY } from 'shared/constants';
import { SitesCounter } from 'widgets/sites-header/ui/sites-counter';

export const SitesHeader = () => {
	const { canCreate, isLoading: isPermissionsLoading } = useCheckPermissions();

	return (
		<Flex px="8" pt="8" pb="6" justifyContent="space-between" alignItems="flex-start">
			<Flex direction="column" alignItems="flex-start" gap="1">
				<SkeletonText size="xl" noOfLines={1} skeletonHeight="9" borderRadius="6" overflow="hidden" isLoaded={!isPermissionsLoading}>
					<Heading size="xl">
						<T _str={DICTIONARY.SITES} />
					</Heading>
				</SkeletonText>
				<SitesCounter />
			</Flex>
			<Skeleton borderRadius="full" isLoaded={!isPermissionsLoading}>
				<Menu isLazy lazyBehavior="keepMounted">
					<MenuButton as={Button} isDisabled={!canCreate} data-testid="create-dropdown-button">
						<T _str={DICTIONARY.CREATE} />
					</MenuButton>
					<MenuList>
						<CreateSiteMenuItem />
						<CreateCollectionMenuItem />
					</MenuList>
				</Menu>
			</Skeleton>
		</Flex>
	);
};

import { Flex, FlexProps, forwardRef } from '@chakra-ui/react';
import { mdiChevronDown } from '@mdi/js';
import { Icon } from 'entities/icon/ui';

export const TreeMenuSelectButton = forwardRef<
	FlexProps & { isactive: 'true' | 'false'; selectedids: string[]; label: string; sitename: string },
	'div'
>((props, ref) => {
	return (
		<Flex
			ref={ref}
			borderRadius="md"
			border="solid 1px"
			borderColor="border-color-a11y"
			_hover={{ borderColor: 'neutral.600' }}
			_active={{ borderColor: 'primary.500', boxShadow: '0 0 0 1px var(--colors-primary-500)' }}
			height={10}
			paddingY="0"
			paddingX="4"
			justifyContent="flex-end"
			align="center"
			gap="1"
			alignSelf="stretch"
			{...props}
		>
			<Flex align="center" alignSelf="stretch" flex="1 0 0" overflow="hidden" whiteSpace="nowrap">
				{props.label}
			</Flex>
			<Icon path={mdiChevronDown} boxSize="5" />
		</Flex>
	);
});

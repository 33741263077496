import { Flex, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger } from '@chakra-ui/react';
import { mdiInformationOutline } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { InfoPopoverProps } from 'entities/info-popover/models';
import { FC } from 'react';

export const InfoPopover: FC<InfoPopoverProps> = ({ header, body, placement = 'bottom-start', iconSize = '4' }) => {
	return (
		<Popover placement={placement} isLazy>
			<PopoverTrigger>
				<Flex px="1" alignItems="center" data-testid="popover-icon">
					<Icon path={mdiInformationOutline} boxSize={iconSize} cursor="pointer" color="neutral-fg" />
				</Flex>
			</PopoverTrigger>
			<PopoverContent>
				{header && (
					<PopoverHeader color="chakra-body-text" border="none" fontWeight="semibold">
						<T _str={header} />
					</PopoverHeader>
				)}
				<PopoverArrow />
				<PopoverCloseButton />
				<PopoverBody fontWeight="normal" color="chakra-body-text">
					<T _str={body} />
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
};

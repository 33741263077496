import { Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { DuplicatePageModalProps } from 'features/page-actions-menu/models';
import { DuplicatePageModalBody } from 'features/page-actions-menu/ui';
import { FC, PropsWithChildren } from 'react';
import { DICTIONARY } from 'shared/constants';
import { useModalWrapper } from 'shared/modal-wrapper/hooks';
import type { ModalWrapperProps } from 'shared/modal-wrapper/models';

export const DuplicatePageModal: FC<PropsWithChildren<DuplicatePageModalProps & ModalWrapperProps>> = (props) => {
	const { isOpen, onClose, children } = useModalWrapper(props);

	return (
		<>
			{children}
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						<T _str={DICTIONARY.DUPLICATE_PAGE} name={props.name} />
					</ModalHeader>
					<ModalCloseButton />
					<DuplicatePageModalBody onClose={onClose} {...props} />
				</ModalContent>
			</Modal>
		</>
	);
};

import { Box, BoxProps } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { getDropComponentStyle } from 'widgets/site-structure/styles/animations';
import { calculateMargin } from 'widgets/site-structure/utils/calculate-margin';

type PageDropProps = {
	isDragover: boolean;
	lvl: number;
};

export const PageDropInside: FC<PageDropProps> = ({ isDragover, lvl }) => {
	const marginLeft = calculateMargin(lvl);
	const height = isDragover ? 14 : 0;
	const pseudoHeight = isDragover ? 12 : 0;

	const styles = useMemo<BoxProps>(() => getDropComponentStyle(height, pseudoHeight, marginLeft), [height, marginLeft, pseudoHeight]);

	return <Box {...styles} />;
};

import { IconButton, Tooltip, useDisclosure } from '@chakra-ui/react';
import { mdiPlusCircleOutline } from '@mdi/js';
import { Icon } from 'entities/icon/ui';
import { useTranslate } from 'features/common/utils/translateUtils';
import { PosModalWrapperPropsEA } from 'features/point-of-sale/models';
import { CreateAssignPosModalEA } from 'features/point-of-sale/ui/create-assign-pos-modal-ea';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';

/** @deprecated */
export const CreateAssignPosIconButtonEA: FC<PosModalWrapperPropsEA> = (props) => {
	const { isOpen, onClose, onOpen } = useDisclosure();
	const t = useTranslate();

	return (
		<>
			<Tooltip label={DICTIONARY.ASSIGN}>
				<IconButton
					size="sm"
					colorScheme="primary"
					variant="ghost"
					aria-label={t(DICTIONARY.CREATE_AND_ASSIGN_ANALYTICS_IDENTIFIER)}
					icon={<Icon path={mdiPlusCircleOutline} />}
					onClick={onOpen}
				/>
			</Tooltip>
			<CreateAssignPosModalEA {...props} isOpen={isOpen} onClose={onClose} />
		</>
	);
};

import { useAuthWithClaims } from 'features/common/auth/useAuthWithClaims';
import { pagesUri } from 'features/common/config/envConfig';
import { useXMTenant } from 'features/common/tenants/xmTenantContext';
import { buildSearchParams } from 'features/common/utils/buildSearchParams';
import { useCallback } from 'react';

export const useOpenSiteEditor = (): ((siteName?: string, itemId?: string) => void) => {
	const { tenant } = useXMTenant();
	const { user } = useAuthWithClaims();
	const organizationId = user?.['https://auth.sitecorecloud.io/claims/org_id'];

	return useCallback(
		(siteName?: string, itemId?: string) => {
			const params = buildSearchParams({
				organization: organizationId,
				tenantName: tenant.name,
				sc_itemid: itemId,
				sc_site: siteName,
			});

			window.open(`${pagesUri}${params}`);
		},
		[tenant.name, organizationId]
	);
};

import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Box,
	Button,
	Skeleton,
	Switch,
	Tooltip,
	useDisclosure,
} from '@chakra-ui/react';
import { t } from '@transifex/native';
import { T } from '@transifex/react';
import { FC, useCallback, useRef } from 'react';
import { DICTIONARY } from 'shared/constants';
import { SiteResponse } from 'shared/models';
import { useUpdatePOSAnalyticsEA } from '../hooks/use-update-pos-analytics-ea';

/**
 * @deprecated Update is blocked for EA
 */
export const PosEnablementSwitcherEA: FC<{ site: SiteResponse | undefined }> = ({ site }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const cancelRef = useRef<HTMLButtonElement>(null);
	const switchRef = useRef<HTMLInputElement>(null);
	const { handlerSwitcher, isPending } = useUpdatePOSAnalyticsEA(site);
	const isEnableAnalytics = site?.settings?.uxEnableAnalytics === 'true';

	const toggleSwitcher = useCallback(() => {
		if (switchRef.current?.checked) {
			onOpen();
		} else {
			void handlerSwitcher();
		}
	}, [handlerSwitcher, onOpen]);

	const onDisableClick = useCallback(async () => {
		await handlerSwitcher();
		onClose();
	}, [handlerSwitcher, onClose]);

	return (
		<>
			<Tooltip
				isDisabled={isPending}
				label={t(!isEnableAnalytics ? DICTIONARY.ENABLE_ANALYTICS_AND_PERSONALIZATION : DICTIONARY.DISABLE_ANALYTICS_AND_PERSONALIZATION)}
			>
				<Box onMouseDown={toggleSwitcher}>
					<Skeleton borderRadius="full" isLoaded={!isPending}>
						<Switch ref={switchRef} isReadOnly isChecked={isEnableAnalytics} />
					</Skeleton>
				</Box>
			</Tooltip>
			<AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef}>
				<AlertDialogOverlay />
				<AlertDialogContent>
					<AlertDialogHeader>
						<T _str={DICTIONARY.POS_POPUP_ENABLEMENT_TITLE} />
					</AlertDialogHeader>
					<AlertDialogBody>
						<T _str={DICTIONARY.POS_POPUP_ENABLEMENT_DESCRIPTION} />
					</AlertDialogBody>
					<AlertDialogFooter gap="2">
						<Button variant="ghost" ref={cancelRef} onClick={onClose} isDisabled={isPending}>
							<T _str={DICTIONARY.CANCEL} />
						</Button>
						<Button colorScheme="danger" onClick={() => void onDisableClick()} isLoading={isPending}>
							<T _str={DICTIONARY.DISABLE} />
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</>
	);
};

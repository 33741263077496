import { Flex } from '@chakra-ui/react';
import { TemplateSelectorProps } from 'features/create-site/models';
import { TemplateDetails } from 'features/create-site/ui/template-details';
import { TemplateList } from 'features/create-site/ui/template-list';
import { FC } from 'react';

export const TemplateSelector: FC<TemplateSelectorProps> = ({ onSelectedTemplateChange, selectedTemplateId }) => {
	return (
		<Flex w="6xl" direction="row" gap="12">
			<Flex w="xl">
				<TemplateList
					onTemplateSelect={(id) => onSelectedTemplateChange(selectedTemplateId !== id ? id : '')}
					selectedTemplateId={selectedTemplateId}
				/>
			</Flex>
			<Flex w="xl">
				<TemplateDetails selectedTemplateId={selectedTemplateId} />
			</Flex>
		</Flex>
	);
};

import { IconButton, Menu, MenuButton, MenuList, Tooltip } from '@chakra-ui/react';
import { mdiPlus } from '@mdi/js';
import { Icon } from 'entities/icon/ui';
import { useTranslate } from 'features/common/utils/translateUtils';
import { CreateSitePageButtonProps } from 'features/create-site-page/models';
import { CreateSitePageMenu } from 'features/create-site-page/ui/create-site-page-menu';
import { FC, useState } from 'react';
import { DICTIONARY } from 'shared/constants';

export const CreateSitePageButton: FC<CreateSitePageButtonProps> = (props) => {
	const t = useTranslate();
	const [isOpen, setIsOpen] = useState(false);

	return (
		<Menu onOpen={() => setIsOpen(true)} isLazy lazyBehavior="keepMounted">
			<MenuButton
				variant="ghost"
				size="sm"
				as={IconButton}
				icon={
					<Tooltip label={t(DICTIONARY.PAGE_CREATE_TOOLTIP)} shouldWrapChildren>
						<Icon path={mdiPlus} data-testid="createPageButton" />
					</Tooltip>
				}
			/>
			<MenuList>{isOpen && <CreateSitePageMenu {...props} />}</MenuList>
		</Menu>
	);
};

import { useToast } from '@chakra-ui/react';
import { mdiChevronDown, mdiChevronRight, mdiFileOutline, mdiHomeVariantOutline } from '@mdi/js';
import { T } from '@transifex/react';
import { useReactQueryWrappersProvider } from 'app/react-query-wrappers-provider/hooks';
import { useFeatures } from 'features/common/featureFlags/featureFlags';
import debounce from 'lodash/debounce';
import { DragEvent, useCallback, useMemo } from 'react';
import { DICTIONARY } from 'shared/constants';
import { onPageDragStart } from 'shared/drag-n-drop';
import { ENDPOINTS } from 'shared/endpoints';
import { MoveSitePagePayload, MoveSitePageResponse, MoveSitePageUrlParams } from 'shared/models';
import { usePostWithUrlInput } from 'shared/react-query-wrappers/hooks';
import { DEFAULT_OPEN_BRANCH_TIMEOUT } from 'widgets/site-structure/constants';
import { deserializeDrag, serializeDrag } from 'widgets/site-structure/models/dragndrop';
import { PageProps } from 'widgets/site-structure/models/props';
import { blockStyles } from 'widgets/site-structure/styles';
import { calculateMargin } from 'widgets/site-structure/utils/calculate-margin';
import { movePageInsideBranch, removeMovePageFromCache, RestoreCache } from 'widgets/site-structure/utils/optimistic-update';

export const usePage = (props: PageProps) => {
	const {
		lvl,
		homePageIdsAndPath,
		isLocked,
		isOpen,
		id,
		isDeleting,
		path,
		setIsOpen,
		hasChildren,
		siteId,
		isDragOver,
		setIsDragOver,
		childrenNames,
	} = props;
	const toast = useToast();
	const { showTreeDragNDrop } = useFeatures();
	const { queryClient } = useReactQueryWrappersProvider();
	const { mutateAsync: movePage } = usePostWithUrlInput<MoveSitePageResponse, MoveSitePagePayload, MoveSitePageUrlParams>({
		queryKey: { url: ENDPOINTS.MOVE_PAGE },
		ignoreGlobalErrorHandler: true,
	});

	const result = useMemo(() => {
		const isHomePage = homePageIdsAndPath.some((homePage) => homePage.id === id);
		const homePages = homePageIdsAndPath.filter((homePath) => homePath.path.includes(path));
		const currentHomePage = homePageIdsAndPath.filter((page) => path.startsWith(page.path)).map((host) => ({ id: host.id, name: host.hostName }));
		const childOfHosts = currentHomePage.length > 0 ? currentHomePage : [{ id: homePageIdsAndPath[0].id, name: homePageIdsAndPath[0].hostName }];
		const cantBeDeleted = isHomePage || homePages.length > 0;
		const parentOfHosts = homePages.map((host) => host.hostName);
		const startItemOfHosts = homePageIdsAndPath?.filter((page) => page.id === id).map((page) => page.hostName);

		return {
			isHomePage,
			cantBeDeleted,
			parentOfHosts,
			childOfHosts,
			startItemOfHosts,
		};
	}, [homePageIdsAndPath, id, path]);

	const chevronIcon = isOpen ? mdiChevronDown : mdiChevronRight;

	const pageIcon = result.isHomePage ? mdiHomeVariantOutline : mdiFileOutline;
	const blockProps = isLocked || isDeleting ? blockStyles : { draggable: showTreeDragNDrop };
	const marginLeft = calculateMargin(lvl);
	const classNames = ['page', isDragOver ? 'drag-hover' : ''].filter((s) => s.length).join(' ');

	const onDragStart = useCallback(
		async (e: DragEvent<HTMLDivElement>) => {
			const restoreData = await removeMovePageFromCache({
				siteId,
				queryClient,
				movingPage: props,
			});

			e.dataTransfer.setData(
				'text/plain',
				serializeDrag({
					movingPage: props,
					restoreCacheData: restoreData,
				})
			);
			onPageDragStart();
		},
		[props, queryClient, siteId]
	);

	const onDrop = useCallback(
		async (e: DragEvent<HTMLDivElement>) => {
			setIsDragOver(false);
			const data = e.dataTransfer.getData('text/plain');
			const { movingPage, restoreCacheData } = deserializeDrag(data);

			let toRestore = false;

			if (movingPage.id === id || path.startsWith(movingPage.path)) {
				toRestore = true;
			}

			if (childrenNames.includes(movingPage.name)) {
				toRestore = true;

				toast({
					description: <T _str={DICTIONARY.PAGE_EXISTS_ERROR} />,
					status: 'error',
				});
			}

			if (toRestore) {
				queryClient.setQueryData(restoreCacheData.queryKey.queryKey || [], restoreCacheData.previousData);

				return;
			}

			setIsOpen(true);

			const restoreCache: RestoreCache[] = [restoreCacheData];

			const restoreRemove = await removeMovePageFromCache({ movingPage, queryClient, siteId });

			const restoreAdd = await movePageInsideBranch({
				movingPage,
				parentId: id,
				queryClient,
				siteId,
				targetId: id,
				position: 'after',
			});

			restoreCache.push(restoreAdd, restoreRemove);

			try {
				await movePage({
					input: {
						itemToMoveId: movingPage.id,
						targetId: id,
						position: 'Into',
						site: siteId,
					},
					additionalVariables: {
						pageId: movingPage.id,
					},
				});
			} catch {
				toast({
					status: 'error',
					description: <T _str={DICTIONARY.COMMON_MOVE_ERROR} />,
				});
			}

			await queryClient.invalidateQueries({
				queryKey: [ENDPOINTS.HIERARCHY_PAGE, { siteId, pageId: id }],
				exact: true,
			});
			await queryClient.invalidateQueries({
				queryKey: [ENDPOINTS.HIERARCHY_PAGE, { siteId, pageId: movingPage.parentId }],
				exact: true,
			});
		},
		[childrenNames, id, movePage, path, queryClient, setIsDragOver, setIsOpen, siteId, toast]
	);

	const debounceOpen = useMemo(
		() =>
			debounce(() => {
				if (hasChildren) {
					setIsOpen(true);
				}
			}, DEFAULT_OPEN_BRANCH_TIMEOUT),
		[hasChildren, setIsOpen]
	);

	const onDragEnter = useCallback(() => {
		debounceOpen();
		setIsDragOver(true);
	}, [debounceOpen, setIsDragOver]);

	const onDragLeave = useCallback(() => {
		debounceOpen.cancel();
		setIsDragOver(false);
	}, [debounceOpen, setIsDragOver]);

	const onDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
	}, []);

	return {
		chevronIcon,
		pageIcon,
		blockProps,
		marginLeft,
		cantBeDeleted: result.cantBeDeleted,
		isHomePage: result.isHomePage,
		/**  The list of hosts which the current item is the parent of */
		parentOfHosts: result.parentOfHosts,
		/**  The list of hosts which the current item is the child of */
		childOfHosts: result.childOfHosts,
		/**  The list of hosts separated by comma which the current item is the the start item of */
		startItemOfHosts: result.startItemOfHosts,
		onDragEnter,
		onDragLeave,
		onDragStart,
		classNames,
		onDrop,
		onDragOver,
		showTreeDragNDrop,
	};
};

import { ChakraProvider } from '@chakra-ui/react';
import sitecoreTheme, { toastOptions } from '@sitecore/blok-theme';
import { PrefetchData } from 'app/prefetch-data/ui/prefetch-data';
import { ReactQueryWrappersProvider } from 'app/react-query-wrappers-provider/ui/react-query-wrappers-provider';
import { setupApm } from 'features/common/apm/setupApm';
import { ApolloProviderTenenantWrapper } from 'features/common/apolloProvider/ApolloProviderTenenantWrapper';
import { LoginErrorHandler } from 'features/common/auth/LoginErrorHandler';
import { AzureFeaturesProvider } from 'features/common/featureFlags/azureFeatureFlags';
import GainsightMetricsRegistration from 'features/common/gainsight/GainsightMetricsRegistration';
import GainsightScriptInjector from 'features/common/gainsight/GainsightScriptInjector';
import { CdpTenantProvider } from 'features/common/tenants/CdpTenantProvider';
import { XMTenantProvider } from 'features/common/tenants/xmTenantProvider';
import { FC } from 'react';
import { CheckJobStatusProvider } from 'shared/check-job-status';
import { ObservabilityProvider } from 'shared/observability/ui';
import { Auth0ProviderWithHistory } from './features/common/auth/Auth0ProviderWithHistory';

const apm = setupApm();

const AppProviders: FC<React.PropsWithChildren> = ({ children }) => {
	return (
		<ObservabilityProvider apm={apm}>
			<Auth0ProviderWithHistory>
				<GainsightScriptInjector />
				{/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
				<ChakraProvider theme={sitecoreTheme} toastOptions={toastOptions}>
					<ReactQueryWrappersProvider>
						<LoginErrorHandler>
							<PrefetchData />
							<XMTenantProvider>
								<GainsightMetricsRegistration />
								<CdpTenantProvider>
									<ApolloProviderTenenantWrapper>
										<AzureFeaturesProvider>
											<CheckJobStatusProvider>{children}</CheckJobStatusProvider>
										</AzureFeaturesProvider>
									</ApolloProviderTenenantWrapper>
								</CdpTenantProvider>
							</XMTenantProvider>
						</LoginErrorHandler>
					</ReactQueryWrappersProvider>
				</ChakraProvider>
			</Auth0ProviderWithHistory>
		</ObservabilityProvider>
	);
};

export default AppProviders;

import { useToast } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import config from 'features/common/config/config';
import { useCdpTenant } from 'features/common/tenants/CdpTenantProvider';
import { useTranslate } from 'features/common/utils/translateUtils';
import { DetachPoSFromSitesInput, PoSDeleteResponse, RemovePoSProps } from 'features/point-of-sale/models';
import { useCallback, useMemo } from 'react';
import { DICTIONARY } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { useDelete, usePost } from 'shared/react-query-wrappers/hooks';

export const useRemovePoSAction = ({ identifier, hostsAssigned }: RemovePoSProps) => {
	const { tenant: cdpTenant } = useCdpTenant();
	const queryClient = useQueryClient();
	const deletePosUrl = useMemo(() => (cdpTenant ? config.cdp.delete_pos.url(cdpTenant?.apiUrl, identifier) : ''), [cdpTenant, identifier]);
	const getAllPosIdentifiers = useMemo(() => (cdpTenant ? config.cdp.get_all_pos.url(cdpTenant?.apiUrl) : ''), [cdpTenant]);
	const toast = useToast();

	const { mutateAsync: removeIdentifier, isPending: isRemovePending } = useDelete<PoSDeleteResponse>({
		queryKey: { url: deletePosUrl },
		ignoreGlobalErrorHandler: true,
		refetchQueriesByKey: [{ url: getAllPosIdentifiers, shouldWait: true }],
	});
	const { mutateAsync: detachPosFromSites, isPending: isDetachPending } = usePost<boolean, DetachPoSFromSitesInput>({
		queryKey: { url: ENDPOINTS.POS_DETACH, params: { identifier } },
		ignoreGlobalErrorHandler: true,
		refetchQueriesByKey: [{ url: ENDPOINTS.SITES, shouldWait: true }],
	});
	const t = useTranslate();

	const removePosIdentifier = useCallback(async () => {
		try {
			const sitesIds = hostsAssigned.map((host) => host.siteId);
			const filteredSitesIds = sitesIds.filter((siteId, index) => sitesIds.indexOf(siteId) === index);

			if (filteredSitesIds?.length > 0) {
				const { data: isPosDetached, status: posDetachStatus } = await detachPosFromSites({ sitesIdentifiers: filteredSitesIds });

				if (!isPosDetached || posDetachStatus !== 200) {
					toast({ description: t(DICTIONARY.SOMETHING_WENT_WRONG), status: 'error' });

					return;
				}

				filteredSitesIds.forEach((siteId) => {
					void queryClient.removeQueries({ queryKey: [ENDPOINTS.SITE, { id: siteId }] });
				});
			}

			const { data: removeResult, status: removePosStatus } = await removeIdentifier();

			if (removeResult?.result !== 'success' || removePosStatus !== 200) {
				toast({ description: t(DICTIONARY.SOMETHING_WENT_WRONG), status: 'error' });

				return;
			}

			toast({ description: t(DICTIONARY.ANALYTICS_IDENTIFIER_DELETE), status: 'success' });
		} catch {
			toast({ description: t(DICTIONARY.SOMETHING_WENT_WRONG), status: 'error' });
		}
	}, [hostsAssigned, toast, queryClient, t, detachPosFromSites, removeIdentifier]);

	return { removePosIdentifier, isPending: isRemovePending || isDetachPending };
};

import { Flex, useDisclosure } from '@chakra-ui/react';
import { FC, PropsWithChildren, useCallback } from 'react';
import { useParams } from 'react-router';
import { useRedirectDefinitionSite } from 'shared/open-in-pages/hooks';
import { OpenInPagesProps } from 'shared/open-in-pages/models';
import { RedirectLinksMenuModal } from 'shared/open-in-pages/ui';

export const OpenInPagesWrapper: FC<PropsWithChildren<OpenInPagesProps>> = ({ children, hosts = [], itemId = '', ...props }) => {
	const disclosure = useDisclosure();
	const { pageRedirectHandler } = useRedirectDefinitionSite({ onClose: disclosure.onClose });
	const params = useParams();

	const handleClick = useCallback(
		(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			if (params.hostId) {
				const selectedHost = hosts.find((f) => f.id === params.hostId);

				if (selectedHost) {
					pageRedirectHandler(e, selectedHost.name, itemId);
				}
			} else if (hosts.length > 1 && !disclosure.isOpen) {
				disclosure.onOpen();
			} else {
				pageRedirectHandler(e, hosts[0].name, itemId);
			}
		},
		[disclosure, hosts, itemId, pageRedirectHandler, params.hostId]
	);

	return (
		<>
			<Flex onClick={handleClick} {...props} data-testid="open-in-pages">
				{children}
			</Flex>
			{!params.hostId && hosts.length > 1 && (
				<RedirectLinksMenuModal {...disclosure} itemId={itemId} hosts={hosts} pageRedirectHandler={pageRedirectHandler} />
			)}
		</>
	);
};

import { EditHostPOSInput, EditPOSInput, SiteResponse } from 'shared/models';

/** @deprecated */
export const mapSiteResponseToInputEA = (site: SiteResponse): EditPOSInput => {
	return {
		posMappings: Object.keys(site.pointsOfSale).map((key) => ({ name: site.pointsOfSale[key], language: key })),
	};
};

export const mapSiteResponseToInput = (pointsOfSale: Record<string, string> = {}): EditHostPOSInput => {
	return {
		analyticsIdentifiers: Object.keys(pointsOfSale).map((key) => ({ name: pointsOfSale[key], language: key })),
	};
};

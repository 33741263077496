import { useCallback, useRef } from 'react';
import { SITE_SETTINGS_FORM_EVENT_NAME } from 'shared/constants';
import { useEventListener } from 'usehooks-ts';
import { GeneralSectionEvent } from 'widgets/site-settings-update-form/models';
import { SettingsSectionBodyProps } from 'widgets/site-settings-update-form/ui/settings-section-body';

/**
 * @deprecated Update is blocked for EA
 */
export const useSettingsSectionBody = ({ resetForm, submitForm }: SettingsSectionBodyProps) => {
	const documentRef = useRef<Document>(document);
	const onEventDispatch = useCallback(
		(event: CustomEvent<GeneralSectionEvent>) => {
			const { type } = event.detail;

			if (type === 'reset') {
				resetForm();
			} else if (type === 'submit') {
				void submitForm();
			}
		},
		[resetForm, submitForm]
	);

	useEventListener(SITE_SETTINGS_FORM_EVENT_NAME, onEventDispatch, documentRef);
};

import { useToast } from '@chakra-ui/react';
import { useTranslate } from 'features/common/utils/translateUtils';
import { DuplicateSiteViewModel } from 'features/duplicate-modal/models/duplicate-site';
import { useCallback, useMemo } from 'react';
import { DICTIONARY } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { SiteResponse } from 'shared/models';
import { useGet, usePost } from 'shared/react-query-wrappers/hooks';
import { isSameGuid, sanitizeSiteName } from 'shared/utils';

export type QueryData = { data: { name: string; displayName: string }[] };

export const useSiteDuplicate = (siteId: string, onClose: () => void) => {
	const toast = useToast();
	const t = useTranslate();

	const { data: sites, isLoading } = useGet<SiteResponse[]>({ queryKey: { url: ENDPOINTS.SITES } });
	const { mutateAsync } = usePost({
		queryKey: { url: ENDPOINTS.SITE_COPY, params: { siteId } },
		refetchQueriesByKey: [{ url: ENDPOINTS.JOBS }],
	});

	const collectionId = useMemo(() => sites?.find((f) => f.id === siteId)?.collectionId, [siteId, sites]);

	const siteNames = useMemo(() => {
		let names: string[] = [];
		let displayNames: string[] = [];

		if (sites && !isLoading) {
			names = sites.map(({ name: value }) => value);
			displayNames = sites.filter((f) => isSameGuid(f.collectionId, collectionId)).map(({ displayName: value }) => value);
		}

		return { names, displayNames };
	}, [collectionId, isLoading, sites]);

	const duplicate = useCallback(
		async (model: DuplicateSiteViewModel) => {
			model.name = sanitizeSiteName(model.displayName) ?? '';

			toast({
				description: t(DICTIONARY.DUPLICATE_SITE_INFO_MESSAGE),
				status: 'loading',
			});

			onClose();

			try {
				await mutateAsync(model);
			} catch (error) {
				toast({
					description: t(DICTIONARY.SOMETHING_WENT_WRONG),
					status: 'error',
				});
			}
		},
		[mutateAsync, onClose, t, toast]
	);

	return {
		duplicate,
		siteNames,
		collectionId,
	};
};

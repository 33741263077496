import { Button, Flex, Heading, Menu, MenuButton, Stack, Tooltip } from '@chakra-ui/react';
import { mdiChevronDown } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { PageBodyContent } from 'entities/page-layout/ui';
import { useHomePage } from 'pages/site-structure/hooks/use-home-pages';
import { SitePageOutlet } from 'pages/site/models';
import { FC } from 'react';
import { useOutletContext } from 'react-router';
import { DICTIONARY } from 'shared/constants';
import { HomePage } from 'widgets/site-structure/models/props';
import { SiteStructure } from 'widgets/site-structure/ui';

export const SiteStructurePage: FC = () => {
	const { site, isLoading, hosts } = useOutletContext<SitePageOutlet>();

	const homePageIdsAndPath: HomePage[] = useHomePage(site, hosts);

	return (
		<PageBodyContent>
			<Stack gap="6">
				<Flex justifyContent="space-between">
					<Heading size="lg">
						<T _str={DICTIONARY.SITE_STRUCTURE} />
					</Heading>
					<Menu>
						<Tooltip label={<T _str={DICTIONARY.MANAGING_SITE_STRUCTURE_BUTTON_TOOLTIP} />}>
							<MenuButton
								as={Button}
								variant="outline"
								rightIcon={<Icon layerStyle="menuButtonIcon" path={mdiChevronDown} />}
								size="md"
								isDisabled
							>
								<T _str={DICTIONARY.ENGLISH} />
							</MenuButton>
						</Tooltip>
					</Menu>
				</Flex>
				<Stack userSelect="none" gap="0">
					<SiteStructure
						homePageIdsAndPath={homePageIdsAndPath}
						siteName={site?.name || ''}
						siteId={site?.id || ''}
						isLoading={isLoading}
					/>
				</Stack>
			</Stack>
		</PageBodyContent>
	);
};

import { Button, ButtonGroup, FormControl, FormLabel, Input, ModalBody, ModalFooter, Stack, Text, UseDisclosureReturn } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { useRemoveCollectionData } from 'features/remove-collection/hooks';
import { RemoveCollectionProps } from 'features/remove-collection/models';
import { FC, PropsWithChildren } from 'react';
import { DICTIONARY } from 'shared/constants';

export const RemoveCollectionModalBody: FC<
	PropsWithChildren<RemoveCollectionProps> &
		Pick<UseDisclosureReturn, 'onClose' | 'isOpen'> & { setIsLoading: React.Dispatch<React.SetStateAction<boolean>> }
> = ({ isOpen, setIsLoading, onClose, ...props }) => {
	const { isPending, isRemoveDisabled, siteCount, setText, handleRemove } = useRemoveCollectionData(props, isOpen, setIsLoading);
	const { displayName } = props;

	return (
		<>
			<ModalBody>
				<Stack gap="6">
					<Text>
						<Text as="span" fontWeight="medium" pr="1">
							{displayName}
						</Text>
						<T _str={DICTIONARY.DELETE_COLLECTION_MESSAGE} count={siteCount} />
					</Text>
					<FormControl isRequired>
						<FormLabel>
							<T _str={DICTIONARY.DELETE_COLLECTION_CONFIRMATION_MESSAGE} displayName={displayName} />
						</FormLabel>
						<Input onChange={(e) => setText(e.target.value)} data-testid="remove-collection-input-name" />
					</FormControl>
				</Stack>
			</ModalBody>
			<ModalFooter>
				<ButtonGroup>
					<Button data-testid="close-btn" variant="ghost" onClick={onClose} isDisabled={isPending}>
						<T _str={DICTIONARY.CANCEL} />
					</Button>
					<Button
						data-testid="remove-btn"
						colorScheme="danger"
						isLoading={isPending}
						loadingText={DICTIONARY.DELETING}
						isDisabled={isRemoveDisabled || isPending}
						onClick={() => void handleRemove()}
					>
						<T _str={DICTIONARY.DELETE_COLLECTION_BUTTON_TEXT} count={siteCount} />
					</Button>
				</ButtonGroup>
			</ModalFooter>
		</>
	);
};

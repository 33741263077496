import { Button, ButtonGroup } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { LeftNavigationLink } from 'entities/page-layout/models';
import { FC, useCallback } from 'react';

type PageLeftNavigationProps = {
	links: LeftNavigationLink[];
	onOpen?: () => void;
	dirty?: React.MutableRefObject<boolean>;
	isSubmitting?: React.MutableRefObject<boolean>;
	actionCallback?: React.MutableRefObject<() => void>;
};

export const PageLeftNavigation: FC<PageLeftNavigationProps> = (props) => {
	const { links, onOpen, dirty, isSubmitting, actionCallback } = props;

	const onClickProxy = useCallback(
		(linkCallback: () => void) => {
			if (actionCallback) {
				if (dirty && dirty.current && isSubmitting && !isSubmitting.current) {
					actionCallback.current = linkCallback;

					if (onOpen) {
						onOpen();
					}
				} else {
					actionCallback.current = () => {};

					linkCallback();
				}
			} else {
				linkCallback();
			}
		},
		[actionCallback, dirty, isSubmitting, onOpen]
	);

	return (
		<ButtonGroup data-testid="tabs" variant="navigation" orientation="vertical" spacing="1" width="60">
			{links.map((link) => (
				<Button
					gap="2"
					key={`${link.label}_${link.active ? 'true' : 'false'}`}
					as="a"
					isActive={link.active}
					onClick={() => onClickProxy(link.onClick)}
					cursor="pointer"
					{...(link.icon ? { leftIcon: link.icon, iconSpacing: 0 } : {})}
				>
					<T _str={link.label} />
				</Button>
			))}
		</ButtonGroup>
	);
};

import { SiteDuplicateModalProps } from 'features/duplicate-modal/models';
import { SiteDuplicateModal } from 'features/duplicate-modal/ui/site-duplicate-modal';
import { FC, Fragment, PropsWithChildren } from 'react';
import { useModalWrapper } from 'shared/modal-wrapper/hooks';

export const SiteDuplicateModalWrapper: FC<
	PropsWithChildren<Pick<SiteDuplicateModalProps, 'originName' | 'siteId' | 'description' | 'siteLanguage'>>
> = (props) => {
	const modalWrapperProps = useModalWrapper(props);

	return (
		<Fragment>
			{modalWrapperProps.children}
			<SiteDuplicateModal {...modalWrapperProps} {...props} />
		</Fragment>
	);
};

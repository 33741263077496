import { useGetLanguages } from 'features/common/languages';
import _ from 'lodash';
import { useMemo } from 'react';

export type LanguageSelectItem = {
	value: string;
	label: string;
};

export const findSelectedUniqueUntlLanguage = (selectedValue: string, items: LanguageSelectItem[]): LanguageSelectItem | undefined => {
	if (!items.length) {
		return undefined;
	}

	return items.find((i) => i.value.toLowerCase() === selectedValue.toLocaleLowerCase());
};

export const useGetUniqueUntlLanguages = (predefinedLanguages?: string[]): LanguageSelectItem[] => {
	const { languages } = useGetLanguages();
	const langSelectItems = useMemo<LanguageSelectItem[]>(() => {
		const languageNames = new Intl.DisplayNames(['en'], { type: 'language' });

		return _.uniqBy(languages, (lang) => lang.iso)
			.filter((lang) => predefinedLanguages?.includes(lang.iso) ?? true)
			.map((lang) => ({
				value: lang.iso,
				label: languageNames.of(lang.iso) || lang.englishName,
			}));
	}, [languages, predefinedLanguages]);

	return langSelectItems;
};

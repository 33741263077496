import { MenuItem } from '@chakra-ui/react';
import { mdiPencilOutline } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { EditLanguageMenuItemProps } from 'features/languages/models';
import { EditLanguageModal } from 'features/languages/ui';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';

export const EditLanguageMenuItem: FC<EditLanguageMenuItemProps> = ({ language, canEdit = true }) => {
	return (
		<EditLanguageModal language={language.name} fallbackLanguage={language.fallbackLanguageIso || ''}>
			<MenuItem data-testid="edit-language-menu-item" isDisabled={!canEdit} icon={<Icon path={mdiPencilOutline} />}>
				<T _str={DICTIONARY.EDIT} />
			</MenuItem>
		</EditLanguageModal>
	);
};

import { Button, ButtonGroup, Flex, FormControl, FormLabel } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { useThumbnail } from 'features/thumbnail/hooks';
import { ThumbnailAuto } from 'features/thumbnail/ui/thumbnail-auto';
import { ThumbnailAutoWithDefinitions } from 'features/thumbnail/ui/thumbnail-auto-with-definitions';
import { ThumbnailManual } from 'features/thumbnail/ui/thumbnail-manual';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { ThumbnailProps, ThumbnailType } from '../models';
import { ThumbnailManualGeneral } from './thumbnail-manual-general';

export const Thumbnail: FC<ThumbnailProps> = ({
	site,
	siteDefinition,
	autoLabel = DICTIONARY.AUTOGENERATE,
	uploadLabel = DICTIONARY.UPLOAD,
	eventHandler,
}) => {
	const { onAutoClickHandler, onManualClickHandler, typeThumbnail, isRegenerating } = useThumbnail({ site, siteDefinition, eventHandler });

	return (
		<Flex data-testid="thumbnail-section" maxW="xl" direction="column" gap="2">
			<FormControl>
				<FormLabel>
					<T _str={DICTIONARY.THUMBNAIL.LABEL} />
				</FormLabel>
				<ButtonGroup
					borderRadius="full"
					background="chakra-body-bg"
					border="1px"
					borderColor="chakra-border-color"
					padding="1"
					variant="toggle"
					size="sm"
					spacing="1"
				>
					<Button isActive={typeThumbnail === ThumbnailType.AUTO} onClick={onAutoClickHandler} data-testid="thumbnail-automatic-tab">
						<T _str={autoLabel} />
					</Button>
					<Button isActive={typeThumbnail === ThumbnailType.MANUAL} onClick={onManualClickHandler} data-testid="thumbnail-manual-tab">
						<T _str={uploadLabel} />
					</Button>
				</ButtonGroup>
			</FormControl>
			{typeThumbnail === ThumbnailType.AUTO && site && <ThumbnailAutoWithDefinitions site={site} eventHandler={eventHandler} />}
			{typeThumbnail === ThumbnailType.AUTO && siteDefinition && (
				<ThumbnailAuto site={siteDefinition} isAutoRegenerating={isRegenerating} eventHandler={eventHandler} />
			)}
			{typeThumbnail === ThumbnailType.MANUAL && site && <ThumbnailManualGeneral site={site} eventHandler={eventHandler} />}
			{typeThumbnail === ThumbnailType.MANUAL && siteDefinition && <ThumbnailManual site={siteDefinition} eventHandler={eventHandler} />}
		</Flex>
	);
};

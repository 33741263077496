import { Alert, AlertDescription, AlertIcon, Box, CloseButton } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { useTranslate } from 'features/common/utils/translateUtils';
import { FC, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { DICTIONARY } from 'shared/constants';

export const HideAnalyticsAlertName = 'dashboard.analyticsAlert.hide';

export const SiteAnalyticsAlertContext = createContext({
	isAlertOpen: false,
	hideAlert: () => {},
});

export const SiteAnalyticsAlertProvider: FC<React.PropsWithChildren> = ({ children }) => {
	const [showAnalyticsAlert, setShowAnalyticsAlert] = useState(() => localStorage.getItem(HideAnalyticsAlertName) != 'true');
	const value = useMemo(
		() => ({
			isAlertOpen: showAnalyticsAlert,
			hideAlert: () => {
				setShowAnalyticsAlert(false);
			},
		}),
		[showAnalyticsAlert]
	);

	return <SiteAnalyticsAlertContext.Provider value={value}>{children}</SiteAnalyticsAlertContext.Provider>;
};

export const SiteAnalyticsAlert: FC = () => {
	const { isAlertOpen, hideAlert } = useContext(SiteAnalyticsAlertContext);
	const t = useTranslate();

	useEffect(() => {
		localStorage.setItem(HideAnalyticsAlertName, 'true');
	}, []);

	if (!isAlertOpen) {
		return <></>;
	}

	return (
		<Box mb={6}>
			<Alert status="info">
				<AlertIcon />
				<AlertDescription>
					<T _str={DICTIONARY.ACTIVATE_ANALYTICS_FOR_SITE_TOOLTIP} />
				</AlertDescription>
				<CloseButton aria-label={t(DICTIONARY.CLOSE_ALERT)} onClick={hideAlert} />
			</Alert>
		</Box>
	);
};

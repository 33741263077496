import { ButtonGroup, IconButton, Menu, MenuButton, MenuList, Skeleton, Tag, TagLabel, Tooltip } from '@chakra-ui/react';
import { mdiDotsHorizontal } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { Page, PageBody, PageBodyContent, PageBodyLeftBar, PageHeader } from 'entities/page-layout/ui';
import { useTranslate } from 'features/common/utils/translateUtils';
import { CommonSitePageProps } from 'pages/site/models';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';

export const SitePageLoader: FC<CommonSitePageProps> = ({ goBack, environment, environmentType }) => {
	const t = useTranslate();

	return (
		<Page data-testid="site-settings-page-loader">
			<PageHeader backAction={goBack} isLoading alignItems="center" justifyContent="space-between" w="full">
				<Tag size="sm" height="fit-content">
					<TagLabel>
						<T _str={DICTIONARY.SITE_ENVIRONMENT} envName={environment} envType={environmentType} />
					</TagLabel>
				</Tag>
				<ButtonGroup>
					<Menu isLazy lazyBehavior="keepMounted">
						<Tooltip label={t(DICTIONARY.OPTIONS)}>
							<MenuButton
								onFocus={(e) => e.preventDefault()}
								as={IconButton}
								aria-label={t(DICTIONARY.OPTIONS)}
								icon={<Icon path={mdiDotsHorizontal} />}
								variant="outline"
								data-testid="site-settings-menu"
							/>
						</Tooltip>
						<MenuList data-testid="contextMenuList" />
					</Menu>
				</ButtonGroup>
			</PageHeader>
			<PageBody>
				<PageBodyLeftBar>
					<Skeleton height="10" width="60" />
				</PageBodyLeftBar>
				<PageBodyContent>
					<Skeleton height="3xs" width="full" />
				</PageBodyContent>
			</PageBody>
		</Page>
	);
};

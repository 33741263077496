import { FC, Fragment, useState } from 'react';
import { usePageBranch } from 'widgets/site-structure/hooks';
import { PageBranchProps, pageAnimationStateDefault } from 'widgets/site-structure/models/props';
import { Page } from 'widgets/site-structure/ui';
import { CreatingPage } from 'widgets/site-structure/ui/creating-page';
import { PageDrop } from 'widgets/site-structure/ui/page-drop';
import { PageDropInside } from 'widgets/site-structure/ui/page-drop-inside';
import { PageLoading } from 'widgets/site-structure/ui/page-loading';
import { calculateMargin } from 'widgets/site-structure/utils/calculate-margin';

export const PageBranch: FC<PageBranchProps> = (props) => {
	const { siteId, lvl, isDeleting: isParentDeleting, opened, id, siteName, canCreate, parentCanCreate, displayName } = props;
	const [isOpen, setIsOpen] = useState(opened || false);
	const [isDragOver, setIsDragOver] = useState(false);

	const { page, children, creating, isDeleting, isDuplicate, isLoading, siblingNames, childrenNames } = usePageBranch({
		...props,
		isOpen,
		setIsOpen,
	});

	return (
		<>
			<Page
				{...props}
				{...page}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				{...pageAnimationStateDefault}
				isDeleting={isParentDeleting || isDeleting}
				parentCanCreate={parentCanCreate}
				parentPageName={displayName}
				isDragOver={isDragOver}
				setIsDragOver={setIsDragOver}
				childrenNames={childrenNames}
			/>
			<PageDropInside isDragover={isDragOver} lvl={lvl + 1} />
			<CreatingPage creating={creating} lvl={lvl + 1} />
			{isOpen &&
				children?.map((child, index) => (
					<Fragment key={child.id}>
						<PageDrop
							lvl={lvl + 1}
							index={index}
							position="before"
							siblingNames={siblingNames}
							siteId={siteId}
							targetId={child.id}
							parentId={id}
							siblingPath={child.path}
						/>
						{creating?.direction === 'before' && <CreatingPage creating={creating} pageId={child.id} lvl={lvl + 1} />}
						<PageBranch
							siteId={siteId}
							siteName={siteName}
							{...child}
							parentId={id}
							lvl={lvl + 1}
							siblingNames={siblingNames}
							homePageIdsAndPath={props.homePageIdsAndPath}
							{...pageAnimationStateDefault}
							isDeleting={isParentDeleting || isDeleting}
							opened={isDuplicate}
							parentCanCreate={canCreate}
							indexInBranch={index}
						/>
						{creating?.direction === 'after' && <CreatingPage creating={creating} pageId={child.id} lvl={props.lvl + 1} />}
						<PageDrop
							lvl={lvl + 1}
							index={index}
							position="after"
							siblingNames={siblingNames}
							siteId={siteId}
							targetId={child.id}
							siblingPath={child.path}
							parentId={id}
						/>
					</Fragment>
				))}
			{isLoading && <PageLoading marginLeft={calculateMargin(lvl + 1)} />}
		</>
	);
};

import { useMutation } from '@apollo/client';
import { useSaveNavigate } from 'features/common/utils/navigationUtils';
import { SITE_NAME_PARAM_NAME } from 'features/common/utils/сlearNonRelevantQueryParams';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { RemoveSitePayload, RemoveSiteInput } from '../data/createSiteTypes';
import { removeSiteQuery } from '../queries';

type UseRemoveSiteType = (
	onClose: () => void,
	onOpen: () => void
) => {
	openRemoveSiteModal: (siteName: string) => void;
	isAbleToRemove: boolean;
	handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
	removeSite: () => void;
	siteName?: string;
	checkSiteName?: string;
};

export const useRemoveSite: UseRemoveSiteType = (onClose, onOpen) => {
	const location = useLocation();
	const navigate = useSaveNavigate();
	const [searchParams, setUrlSearchParams] = useSearchParams();
	const [removeSiteMutation] = useMutation<RemoveSitePayload, RemoveSiteInput>(removeSiteQuery);
	const [siteNameState, setSiteName] = useState<string | undefined>();
	const [checkSiteNameValue, setCheckSiteNameValue] = useState<string>('');

	const isAbleToRemove = useMemo(() => siteNameState === checkSiteNameValue, [checkSiteNameValue, siteNameState]);

	const removeSite = useCallback(() => {
		void (async () => {
			if (!siteNameState || !isAbleToRemove) {
				return;
			}

			await removeSiteMutation({
				variables: {
					siteName: siteNameState,
				},
				refetchQueries: ['GetSitesAndJobsList'],
			});

			if (searchParams.has(SITE_NAME_PARAM_NAME)) {
				searchParams.delete(SITE_NAME_PARAM_NAME);
				setUrlSearchParams(searchParams, { replace: true });
			}

			const path = decodeURIComponent(location.pathname);

			if (path.includes(siteNameState)) {
				navigate('/sites');
			}

			onClose();
		})();
	}, [isAbleToRemove, location.pathname, navigate, onClose, removeSiteMutation, searchParams, setUrlSearchParams, siteNameState]);

	const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		setCheckSiteNameValue(event.target.value);
	}, []);

	const openRemoveSiteModal = useCallback(
		(siteName: string) => {
			setSiteName(siteName);
			setCheckSiteNameValue('');
			onOpen();
		},
		[onOpen]
	);

	return {
		isAbleToRemove,
		handleChange,
		openRemoveSiteModal,
		removeSite,
		siteName: siteNameState,
		checkSiteName: checkSiteNameValue,
	};
};

import {
	Box,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import { mdiInformationOutline } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';

type HeaderHintProps = {
	title: string;
	description: string;
};

const HeaderHint: FC<HeaderHintProps> = ({ title, description }) => (
	<Popover placement="bottom-start" isLazy>
		<PopoverTrigger>
			<Box data-testid="pos-table-header-hint" display="inline-block" px="1">
				<Icon path={mdiInformationOutline} boxSize="4" cursor="pointer" />
			</Box>
		</PopoverTrigger>
		<PopoverContent>
			<PopoverArrow />
			<PopoverCloseButton />
			<PopoverHeader border="none" color="chakra-body-text" fontSize="md">
				<T _str={title} />
			</PopoverHeader>
			<PopoverBody fontWeight="normal" color="chakra-body-text" fontSize="md">
				<T _str={description} />
			</PopoverBody>
		</PopoverContent>
	</Popover>
);

export const PosTableHead: FC = () => {
	return (
		<Thead>
			<Tr>
				<Th position="relative">
					<T _str={DICTIONARY.NAME} />
					<HeaderHint title={DICTIONARY.POS_TABLE_HINTS.IDENTIFIER.TITLE} description={DICTIONARY.POS_TABLE_HINTS.IDENTIFIER.DESCRIPTION} />
				</Th>
				<Th position="relative">
					<T _str={DICTIONARY.TIMEOUT_IN_MINUTES} />
					<HeaderHint title={DICTIONARY.POS_TABLE_HINTS.TIMEOUT.TITLE} description={DICTIONARY.POS_TABLE_HINTS.TIMEOUT.DESCRIPTION} />
				</Th>
				<Th colSpan={2}>
					<T _str={DICTIONARY.ASSIGNED_TO_SITE_DEFINITION_TABLE} />
					<HeaderHint
						title={DICTIONARY.POS_TABLE_HINTS.ASSIGNED_TO_SITE_DEFINITION.TITLE}
						description={DICTIONARY.POS_TABLE_HINTS.ASSIGNED_TO_SITE_DEFINITION.DESCRIPTION}
					/>
				</Th>
			</Tr>
		</Thead>
	);
};

import {
	SITE_GENERAL_SETTINGS_FIELDS_EVENT,
	SITE_IS_SUBMITTING,
	SITE_SETTINGS_FIELDS_EVENT,
	SITE_SETTINGS_FORM_EVENT_NAME,
	SITE_SETTINGS_SAVED,
	SITE_SETTINGS_TOUCHED,
} from 'shared/constants';
import { SiteSettingsViewModel } from 'widgets/site-settings-update-form/models/general-section';
import { SiteGeneralFieldsEvent } from 'widgets/site-settings-update-form/models/site-general-fields-event';

export type GeneralSectionEventType = 'submit' | 'reset';

export type GeneralSectionEvent = {
	type: GeneralSectionEventType;
};

export type SiteSettingsFieldsEvent = {
	name: keyof SiteSettingsViewModel;
	value: string;
};

export type SiteSavedEvent = {
	saved: boolean;
};

export type IsSubmittingEvent = {
	state: boolean;
};

export type SiteIsDirtyEvent = {
	isDirty: boolean;
};

declare global {
	interface DocumentEventMap {
		[SITE_SETTINGS_FORM_EVENT_NAME]: CustomEvent<GeneralSectionEvent>;
		[SITE_SETTINGS_TOUCHED]: CustomEvent<SiteIsDirtyEvent>;
		[SITE_SETTINGS_SAVED]: CustomEvent<SiteSavedEvent>;
		[SITE_SETTINGS_FIELDS_EVENT]: CustomEvent<SiteSettingsFieldsEvent>;
		[SITE_IS_SUBMITTING]: CustomEvent<IsSubmittingEvent>;
		[SITE_GENERAL_SETTINGS_FIELDS_EVENT]: CustomEvent<SiteGeneralFieldsEvent>;
	}
}

export const onSiteSaved = (saved: boolean) => {
	document.dispatchEvent(new CustomEvent<SiteSavedEvent>(SITE_SETTINGS_SAVED, { detail: { saved } }));
};

export const onIsSubmittingChange = (state: boolean) => {
	document.dispatchEvent(new CustomEvent<IsSubmittingEvent>(SITE_IS_SUBMITTING, { detail: { state } }));
};

export const onSiteTouched = (isDirty: boolean) => {
	document.dispatchEvent(new CustomEvent<SiteIsDirtyEvent>(SITE_SETTINGS_TOUCHED, { detail: { isDirty } }));
};

export const setSiteSettingsFieldValue = (field: string, value: string) => {
	document.dispatchEvent(
		new CustomEvent(SITE_SETTINGS_FIELDS_EVENT, {
			detail: {
				name: field,
				value,
			},
		})
	);
};

export const onButtonsClick = (type: GeneralSectionEventType) => {
	document.dispatchEvent(new CustomEvent<GeneralSectionEvent>(SITE_SETTINGS_FORM_EVENT_NAME, { detail: { type } }));
};

export type SiteGeneralSectionEventType = 'submit' | 'reset';

export type SiteGeneralSectionEvent = {
	type: SiteGeneralSectionEventType;
};

export const onSiteGeneralSettingsSaved = (saved: boolean) => {
	document.dispatchEvent(new CustomEvent<SiteSavedEvent>(SITE_SETTINGS_SAVED, { detail: { saved } }));
};

export const onSiteGeneralSettingsTouched = (isDirty: boolean) => {
	document.dispatchEvent(new CustomEvent<SiteIsDirtyEvent>(SITE_SETTINGS_TOUCHED, { detail: { isDirty } }));
};

export const setSiteGeneralFieldValue = (field: string, value: string) => {
	document.dispatchEvent(
		new CustomEvent(SITE_GENERAL_SETTINGS_FIELDS_EVENT, {
			detail: {
				name: field,
				value,
			},
		})
	);
};

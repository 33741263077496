import { Icon as ChakraIcon, IconProps } from '@chakra-ui/react';
import { FC } from 'react';

export const Icon: FC<IconProps> = ({ path, boxSize, ...props }: IconProps) => {
	return (
		<ChakraIcon {...props} boxSize={boxSize}>
			<path fill="currentColor" d={path} />
		</ChakraIcon>
	);
};

export const LANGUAGE_PARAM_NAME = 'sc_lang';
export const FILTER_PARAM_NAME = 'search';
export const VIEW_PARAM_NAME = 'view';
export const SITE_NAME_PARAM_NAME = 'sc_site';
export const ITEM_ID_PARAM_NAME = 'sc_itemid';
export const SITE_COLLECTION_NAME_PARAM_NAME = 'siteCollection';
export const TYPE_FILTER_NAME = 'sc_type';

export const clearNonRelevantQueryParams = (searchParams: URLSearchParams, ...params: string[]) => {
	for (const param of params) {
		searchParams.delete(param);
	}

	return searchParams.toString();
};

export const clearQueryParamsOnColumnsSwith = (searchParams: URLSearchParams) => {
	clearNonRelevantQueryParams(searchParams, FILTER_PARAM_NAME, SITE_COLLECTION_NAME_PARAM_NAME);
};

export const clearQueryParamsOnTilesSwitch = (searchParams: URLSearchParams) => {
	clearNonRelevantQueryParams(searchParams, SITE_NAME_PARAM_NAME, ITEM_ID_PARAM_NAME);
};

export const clearQueryParamsOnTabSwith = (searchParams: URLSearchParams) => {
	clearNonRelevantQueryParams(
		searchParams,
		LANGUAGE_PARAM_NAME,
		FILTER_PARAM_NAME,
		VIEW_PARAM_NAME,
		SITE_NAME_PARAM_NAME,
		ITEM_ID_PARAM_NAME,
		SITE_COLLECTION_NAME_PARAM_NAME,
		TYPE_FILTER_NAME
	);
};

export const clearQueryParamsOnFilterTypeAll = (searchParams: URLSearchParams) => {
	clearNonRelevantQueryParams(searchParams, TYPE_FILTER_NAME, SITE_NAME_PARAM_NAME, ITEM_ID_PARAM_NAME, SITE_COLLECTION_NAME_PARAM_NAME);
};

import { CloseButton, Input, InputGroup, InputLeftElement, InputRightElement, Tooltip } from '@chakra-ui/react';
import { mdiMagnify } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { useTranslate } from 'features/common/utils/translateUtils';
import { FC, useCallback, useState } from 'react';
import { DICTIONARY } from 'shared/constants';
import { SearchInputProps } from 'shared/search-input/models';

export const SearchInput: FC<SearchInputProps> = ({
	onSearch,
	onClear,
	defaultValue = '',
	inputRef,
	placeholder = DICTIONARY.SEARCH,
	containerStyle = {},
	inputStyle = {},
}) => {
	const [hasValue, setHasValue] = useState(!!defaultValue);
	const t = useTranslate();

	const onSearchChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setHasValue(!!e.target.value);
			onSearch(e.target.value);
		},
		[setHasValue, onSearch]
	);

	const onSearchClear = useCallback(() => {
		setHasValue(false);
		onClear();
	}, [setHasValue, onClear]);

	return (
		<InputGroup maxW="md" {...containerStyle}>
			<InputLeftElement pointerEvents="none">
				<Icon pointerEvents="none" path={mdiMagnify} boxSize="5" />
			</InputLeftElement>
			<Input
				data-testid="search-input"
				width="md"
				defaultValue={defaultValue}
				ref={inputRef}
				type="text"
				placeholder={t(placeholder)}
				onChange={onSearchChange}
				{...inputStyle}
			/>
			{!!hasValue && (
				<InputRightElement>
					<Tooltip label={<T _str={DICTIONARY.CLEAR_SEARCH} />}>
						<CloseButton data-testid="clear-search" size="sm" onClick={onSearchClear} />
					</Tooltip>
				</InputRightElement>
			)}
		</InputGroup>
	);
};

import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { MissingPortfolioArea } from 'entities/missing-portfolio-area/ui';
import { CreateSiteButton } from 'features/create-site/ui/create-site-button';
import { DICTIONARY } from 'shared/constants';
import { MissingPortfolioProps } from 'widgets/missing-portfolio/models';

export const MissingPortfolio = ({ canCreate, isLoading }: MissingPortfolioProps) => {
	return (
		<>
			{!canCreate && !isLoading && (
				<Alert status="info" data-testid="sc-missing-portfolio-alert">
					<AlertIcon />
					<AlertDescription>
						<T _str={DICTIONARY.WELCOME_TO_XM_CLOUD_RESTRICTION_TEXT} />
					</AlertDescription>
				</Alert>
			)}
			<MissingPortfolioArea>
				<CreateSiteButton canCreate={canCreate} />
			</MissingPortfolioArea>
		</>
	);
};

import { useEffect, useState } from 'react';

/**
 * Custom hook to check if an element's content is overflowing.
 * Note: in some cases like <p> the inner element must not be display: inline
 * 	It should have a block like property.
 */

export const useIsOverflowing = (ref: React.RefObject<HTMLElement>) => {
	const [isOverflowing, setIsOverflowing] = useState(false);

	useEffect(() => {
		const checkOverflow = () => {
			const element = ref.current;

			if (!element) return;
			const isElementOverflowing = element.offsetWidth < element.scrollWidth;

			setIsOverflowing(isElementOverflowing);
		};

		const resizeObserver = new ResizeObserver(checkOverflow);

		if (ref.current) {
			resizeObserver.observe(ref.current);
			checkOverflow(); // Call checkOverflow immediately
		}

		return () => {
			resizeObserver.disconnect();
		};
	}, [ref]);

	return isOverflowing;
};

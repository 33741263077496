import { CollectionCreationProgress } from 'entities/collection-creation-progress/ui';
import { useTranslate } from 'features/common/utils/translateUtils';
import { FC } from 'react';
import { useCheckJobStatus } from 'shared/check-job-status';
import { DICTIONARY } from 'shared/constants';

export const SiteAndCollectionCreationInProgress: FC = () => {
	const { getRunningSiteAndCollectionCreations } = useCheckJobStatus();
	const t = useTranslate();

	return (
		<>
			{getRunningSiteAndCollectionCreations() &&
				getRunningSiteAndCollectionCreations()?.map(
					(collection) =>
						!collection.done && (
							<CollectionCreationProgress
								key={collection.siteCollection}
								text={t(DICTIONARY.CREATING_COLLECTION_AND_SITE, {
									collection: collection.siteCollection,
									site: collection.site,
								})}
							/>
						)
				)}
		</>
	);
};

import { Select } from 'chakra-react-select';
import { FormFieldProps } from 'entities/form-field-control/models';
import { FormFieldControl } from 'entities/form-field-control/ui';
import { FormSelectFieldProps } from 'entities/form-fields/models';
import { useTranslate } from 'features/common/utils/translateUtils';
import { Field } from 'formik';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';

export const FormSelectField: FC<FormSelectFieldProps> = ({
	name,
	options,
	inputId,
	handleChange,
	setFieldTouched,
	placeholderText = DICTIONARY.SELECT,
	noOptionsText = DICTIONARY.NO_SEARCH_RESULT,
	isClearable = true,
	useBasicStyles = false,
	...props
}) => {
	const t = useTranslate();

	return (
		<Field name={name}>
			{({ field, form }: FormFieldProps) => (
				<FormFieldControl form={form} field={field} name={name} {...props}>
					<Select
						useBasicStyles={useBasicStyles}
						placeholder={t(placeholderText)}
						noOptionsMessage={() => t(noOptionsText)}
						selectedOptionStyle="check"
						isClearable={isClearable}
						isInvalid={!!form.errors?.[name] && !!form.touched?.[name]}
						options={options}
						onBlur={(e) => {
							setFieldTouched(name);

							if (e.target.value) {
								handleChange(name)(''); // clear search value
							}
						}}
						value={field.value ? { value: field.value, label: options.find((option) => option.value === field.value)?.label } : undefined}
						inputId={inputId}
						className="form-select-field"
						onChange={(selectedOption) => {
							handleChange(name)(selectedOption ? selectedOption?.value : '');
						}}
					/>
				</FormFieldControl>
			)}
		</Field>
	);
};

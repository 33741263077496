import { Box, BoxProps, Button, ButtonProps, Center, CircularProgress, Image, ImageProps, Text, TextProps } from '@chakra-ui/react';
import { mdiChevronRight, mdiFileOutline, mdiFolderOutline } from '@mdi/js';
import { Icon } from 'entities/icon/ui';
import { useGetThumbnail } from 'features/common/tenants/useGetThumbnail';
import { FC } from 'react';
import { MillerItemType } from '../models';
import { useGetMillerItemColor } from '../utils/useGetMillerItemColor';
import { useLanguageContext } from './LanguageProvider';
import { WorkflowBadge } from './WorkflowBadge';

export interface MillerItemProps<T> {
	data: T;
	isActiveColumn: boolean;
	isActiveItem: boolean;
	onClick: () => void;
}

const iconProps: TextProps = {
	display: 'inline-flex',
	fontSize: 22,
	height: 22,
	lineHeight: 22,
};

const buttonProps: ButtonProps = {
	variant: 'ghost',
	borderRadius: 'md',
	justifyContent: 'flex-start',
	height: 'auto',
	w: '100%',
	minHeight: '3.75rem',
	textAlign: 'left',
	p: 2,
};

const jobBoxProps: BoxProps = {
	borderRadius: 'md',
	justifyContent: 'flex-start',
	alignItems: 'center',
	height: 'auto',
	w: '100%',
	minHeight: '3.75rem',
	p: 2,
	display: 'flex',
};

const thumbnailurlProps: ImageProps = {
	borderRadius: 'base',
	w: '2.75rem',
	h: '2.75rem',
};

export const MillerItem: FC<MillerItemProps<MillerItemType>> = ({ data, isActiveColumn, isActiveItem, onClick }) => {
	const { selectedLanguage } = useLanguageContext();

	const thumbnail = useGetThumbnail(data.thumbnailUrl);
	const bgColor = useGetMillerItemColor(data, isActiveColumn, isActiveItem);

	if (data.type == 'site' && data.siteType == 'job') {
		return (
			<Box data-testid="siteJob-placeholder" data-id={data.id} bgColor={bgColor} {...jobBoxProps}>
				<Center
					w="2.75rem"
					h="2.75rem"
					mr="3"
					borderWidth="1px"
					borderStyle="dashed"
					borderColor="purple.300"
					borderRadius="base"
					bgColor="purple.100"
					data-testid="item-thumbnail"
				>
					<CircularProgress isIndeterminate size="7" capIsRound color="primary" trackColor="neutral-color.200" />
				</Center>
				<Box flex="1" alignItems="start">
					<Text variant="strong" noOfLines={2} color="primary-fg">
						Site &ldquo;{data.name}&ldquo; is building
					</Text>
				</Box>
			</Box>
		);
	}

	return (
		<Button data-testid="item-button" data-id={data.id} bgColor={bgColor} onClick={onClick} {...buttonProps}>
			<Box
				w="2.75rem"
				display="flex"
				mr="3"
				data-testid="item-thumbnail"
				borderWidth="1px"
				borderStyle="solid"
				borderColor="chakra-border-color"
				borderRadius="base"
			>
				{data.thumbnailUrl || data.type == 'site' ? (
					<Image {...thumbnailurlProps} objectFit="contain" src={thumbnail} />
				) : (
					<Center {...thumbnailurlProps} bgColor="chakra-subtle-bg">
						<Text {...iconProps}>
							{data.scItemType == 'page' && <Icon data-testid="pageIcon" path={mdiFileOutline} w="6" h="6" />}
							{data.scItemType == 'folder' && <Icon data-testid="folderIcon" path={mdiFolderOutline} w="6" h="6" />}
						</Text>
					</Center>
				)}
			</Box>
			<Box flex="1" alignItems="start" isTruncated>
				<Text isTruncated color="chakra-body-text">
					{data.name}
				</Text>
				<WorkflowBadge isFinal={data.workflowState?.isFinal} stateName={data.workflowState?.name} style="empty" />
				{data.languages && (
					<Text textTransform="uppercase" isTruncated color="chakra-subtle-text" fontSize="sm">
						{data.languages.map((language, index) => (
							<Text as="span" key={language}>
								<Text as="span" color={language === selectedLanguage?.iso ? 'Teal' : ''}>
									{language}
								</Text>
								{data.languages.length - 1 !== index && <Text as="span">, </Text>}
							</Text>
						))}
					</Text>
				)}
			</Box>
			<Box w="8" color="neutral">
				{data.hasChildren && <Icon path={mdiChevronRight} />}
			</Box>
		</Button>
	);
};

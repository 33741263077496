import { TableText, TableTextProps } from 'entities/table/ui';
import { useFeatures } from 'features/common/featureFlags/featureFlags';
import { FC } from 'react';
import { VALIDATIONS } from 'shared/constants';
import { SiteDefinitionMissingLanguageProps } from 'widgets/site-definitions-table/models';

export const SiteDefinitionMissingLanguage: FC<SiteDefinitionMissingLanguageProps> = ({
	defaultLanguage,
	siteLanguages,
	systemLanguages,
	allLanguages,
}) => {
	const isAvailableForSite = !!siteLanguages.find((lang) => lang.value === defaultLanguage);
	const { showManagedLanguages } = useFeatures();
	const systemLanguage = systemLanguages.find((lang) => lang.value === defaultLanguage);
	let props: TableTextProps = { name: '', tooltip: '', isError: false };

	if (showManagedLanguages && !isAvailableForSite && systemLanguage) {
		props = { name: systemLanguage.label, tooltip: VALIDATIONS.SITE_MISSING_LANGUAGE, isError: true };
	} else if (showManagedLanguages && !systemLanguage) {
		const language = allLanguages.find((lang) => lang.value === defaultLanguage);

		props = { name: language?.label || '', tooltip: VALIDATIONS.SYSTEM_MISSING_LANGUAGE, isError: true };
	} else {
		const language = systemLanguage ?? allLanguages.find((lang) => lang.value === defaultLanguage);

		props = { name: language?.label || defaultLanguage, tooltip: language?.label || defaultLanguage };
	}

	return <TableText {...props} />;
};

import { AlertDialogBody, AlertDialogFooter, Button, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { useDeletePage } from 'features/page-actions-menu/hooks';
import { DeletePageModalBodyProps } from 'features/page-actions-menu/models';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';

export const DeletePageAlertBody: FC<DeletePageModalBodyProps> = ({ onClose, displayName, hasChildren, name, id, cancelRef }) => {
	const { submitHandler } = useDeletePage(id, displayName || name, onClose);

	return (
		<>
			<AlertDialogBody>
				<T
					_str={hasChildren ? DICTIONARY.DELETE_PAGE_WITH_CHILDREN_HINT : DICTIONARY.DELETE_PAGE_HINT}
					displayName={
						<Text as="span" variant="strong">
							{displayName || name}
						</Text>
					}
				/>
			</AlertDialogBody>
			<AlertDialogFooter gap="2">
				<Button variant="ghost" ref={cancelRef} onClick={onClose}>
					<T _str={DICTIONARY.CANCEL} />
				</Button>
				<Button colorScheme="danger" onClick={() => void submitHandler()}>
					<T _str={DICTIONARY.DELETE} />
				</Button>
			</AlertDialogFooter>
		</>
	);
};

import { CircularProgress, Flex, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { DICTIONARY } from 'shared/constants';
import { FC } from 'react';

type SiteJobCardProps = {
	name: string;
};

export const SiteJobCard: FC<SiteJobCardProps> = ({ name }) => {
	const cardBackground = 'white';

	return (
		<Flex
			direction="column"
			textAlign="center"
			minHeight="56"
			borderRadius="md"
			gap="1"
			pt="24"
			shadow="base"
			w="18.75rem"
			align="center"
			background={cardBackground}
		>
			<CircularProgress isIndeterminate size="12" capIsRound color="primary" trackColor="neutral-color.200" pb="4" />
			<Text noOfLines={5}>
				<T _str={DICTIONARY.SITE_IS_BUILDING} name={name} />
			</Text>
			<Text>
				<T _str={DICTIONARY.THIS_CAN_TAKE_UP_TO_2_MINUTES} />
			</Text>
			<Text>
				<T _str={DICTIONARY.YOU_CAN_BROWSE_AWAY} />
			</Text>
		</Flex>
	);
};

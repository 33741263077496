import { MenuItem, Stack, Tooltip } from '@chakra-ui/react';
import { t } from '@transifex/native';
import { T } from '@transifex/react';
import { SkeletonRepeater } from 'entities/skeleton-repeater/ui';
import { useCreateSitePageMenu } from 'features/create-site-page/hooks';
import { ActionType, CreateSitePageButtonProps } from 'features/create-site-page/models';
import { CreateSitePageModalWrapper } from 'features/create-site-page/ui';
import { FC, useState } from 'react';
import { DICTIONARY } from 'shared/constants';

export const CreateSitePageMenu: FC<CreateSitePageButtonProps> = (props) => {
	const { pageId, siteName, displayName, parentCanCreate, canCreate, parentPageId, parentPageName } = props;
	const pageData = useCreateSitePageMenu(pageId, parentPageId);
	const { insertOptions, insertOptionsParent, isLoading } = pageData;
	const [actionType, setActionType] = useState<ActionType>();
	const dictionaryCurrentPageName = t(DICTIONARY.SITE_STRUCTURE_PAGE_CANNOT_BE_CREATED, { nameSiteOrPage: displayName });
	const dictionaryParentPageName = t(DICTIONARY.SITE_STRUCTURE_PAGE_CANNOT_BE_CREATED, { nameSiteOrPage: parentPageName });
	const dictionarySiteMetaName = t(DICTIONARY.SITE_STRUCTURE_PAGE_CANNOT_BE_CREATED, { nameSiteOrPage: siteName });

	if (isLoading) {
		return <SkeletonRepeater count={3} height="6" margin="2" />;
	}

	return (
		<CreateSitePageModalWrapper {...props} actionType={actionType} {...pageData}>
			<Stack gap={0}>
				<Tooltip label={dictionaryParentPageName} hidden={!!(canCreate && insertOptions.length)} shouldWrapChildren>
					<MenuItem
						isDisabled={!canCreate || !insertOptions.length}
						onClickCapture={() => setActionType('inside')}
						data-testid="site-structure-create-child-page"
					>
						<T _str={DICTIONARY.CREATE_CHILD_PAGE} />
					</MenuItem>
				</Tooltip>
				<Tooltip
					label={parentPageId ? dictionaryCurrentPageName : dictionarySiteMetaName}
					hidden={!!(parentCanCreate && insertOptionsParent.length)}
					shouldWrapChildren
				>
					<MenuItem
						isDisabled={!parentCanCreate || !insertOptionsParent.length}
						onClickCapture={() => setActionType('before')}
						data-testid="site-structure-create-page-before"
					>
						<T _str={DICTIONARY.CREATE_PAGE_BEFORE} />
					</MenuItem>
				</Tooltip>
				<Tooltip
					label={parentPageId ? dictionaryCurrentPageName : dictionarySiteMetaName}
					hidden={!!(parentCanCreate && insertOptionsParent.length)}
					shouldWrapChildren
				>
					<MenuItem
						isDisabled={!parentCanCreate || !insertOptionsParent.length}
						onClickCapture={() => setActionType('after')}
						data-testid="site-structure-create-page-after"
					>
						<T _str={DICTIONARY.CREATE_PAGE_AFTER} />
					</MenuItem>
				</Tooltip>
			</Stack>
		</CreateSitePageModalWrapper>
	);
};

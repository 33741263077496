import { ChangeEvent, useCallback, useMemo } from 'react';
import { ASTERISK_REGEX, DICTIONARY, HOSTNAME_DELIMITER, URL_VALIDATE_REGEX } from 'shared/constants';
import { HostnameFieldProps } from 'widgets/site-definition-update-form/ui/fields';

export const useHostnameField = ({ allHostNames, hostname, setHostname }: HostnameFieldProps) => {
	const names = useMemo(() => {
		return hostname.split(HOSTNAME_DELIMITER);
	}, [hostname]);

	const handleHostnameChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>, index: number) => {
			e.stopPropagation();
			const newNames = [...names];

			newNames[index] = e.target.value;
			const newHostname = newNames.join(HOSTNAME_DELIMITER);

			setHostname(newHostname);
		},
		[names, setHostname]
	);

	const removeField = useCallback(
		(index: number) => {
			const newNames = [...names];

			newNames.splice(index, 1);
			const newHostname = newNames.join(HOSTNAME_DELIMITER);

			setHostname(newHostname);
		},
		[names, setHostname]
	);

	const validateHostname = useCallback(
		(index: number) => {
			const newVal = names[index];

			if (newVal.length === 0) {
				return DICTIONARY.HOST_NAME_REQUIRED;
			}

			if (!(newVal.match(URL_VALIDATE_REGEX) || newVal.match(ASTERISK_REGEX))) {
				return DICTIONARY.HOST_NAME_INVALID_URL_TYPE;
			}

			if (allHostNames.includes(newVal)) {
				return DICTIONARY.HOST_NAME_ALREADY_USED;
			}

			return undefined;
		},
		[allHostNames, names]
	);

	return {
		names,
		handleHostnameChange,
		removeField,
		validateHostname,
	};
};

import { Flex, FlexProps } from '@chakra-ui/react';
import { FC, PropsWithChildren, useRef } from 'react';
import { ScrollToTopButton } from 'shared/scroll-top-button/ui';

type PageBodyContentProps = FlexProps & {
	scrollToTop?: boolean;
};

export const PageBodyContent: FC<PropsWithChildren<PageBodyContentProps>> = ({ children, scrollToTop, ...props }) => {
	const ref = useRef<HTMLDivElement>(null);

	return (
		<Flex direction="column" px="8" pt="8" pb="10" gap="6" boxSize="full" overflow="auto" {...props} ref={ref}>
			{children}
			{scrollToTop && <ScrollToTopButton boxRef={ref} />}
		</Flex>
	);
};

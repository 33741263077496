import { Container, Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { useGetTemplateList } from '../utils/useGetTemplateList';
import { TemplateBlock } from './TemplateBlock';

type TemplatesListProps = {
	setSelectedTemplateId: (templateId: string) => void;
};

export const TemplatesList: FC<TemplatesListProps> = ({ setSelectedTemplateId }) => {
	const templates = useGetTemplateList();

	return (
		<Container maxWidth="container.xl">
			<Flex gap="8" flexWrap="wrap" data-testid="TemplatesList">
				{templates.map((template) =>
					template.enabled ? (
						<TemplateBlock
							key={template.id}
							name={template.name}
							setSelectedTemplateId={setSelectedTemplateId}
							templateId={template.id}
							description={template.description}
							thumbnailUrl={template.thumbnailUrl}
						/>
					) : (
						<TemplateBlock
							key={template.id}
							name={template.name}
							templateId="0"
							setSelectedTemplateId={() => {}}
							description={template.description}
							thumbnailUrl={template.thumbnailUrl}
						/>
					)
				)}
			</Flex>
		</Container>
	);
};

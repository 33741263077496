import { Th, Thead, Tr } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';

export const SitePosTableHeader: FC = () => {
	return (
		<Thead>
			<Tr>
				<Th>
					<T _str={DICTIONARY.SITE_LANGUAGE} />
				</Th>
				<Th colSpan={2}>
					<T _str={DICTIONARY.ASSIGNED_ANALYTICS_IDENTIFIER} />
				</Th>
			</Tr>
		</Thead>
	);
};

import { CreateSiteDefinitionDataProps, SiteDefinitionFormValues, SiteDefinitionValidationData } from 'features/site-definitions/models';
import { useMemo } from 'react';
import { COPY_OF_PREFIX_WITH_DASH, DICTIONARY } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { HierarchyPage } from 'shared/item-tree/models';
import { useGetLanguages } from 'shared/languages/hooks';
import { RenderingHost, SiteResponse } from 'shared/models';
import { useGet, useSimpleGet } from 'shared/react-query-wrappers/hooks';

export const useCreateSiteDefinitionData = ({ siteId, host }: CreateSiteDefinitionDataProps) => {
	const { data: renderingHostsData } = useGet<RenderingHost[]>({ queryKey: { url: ENDPOINTS.RENDERING_HOSTS, params: { siteId } } });
	const { data: page } = useGet<HierarchyPage>({ queryKey: { url: ENDPOINTS.HIERARCHY, params: { siteId } }, enabled: !host });
	const { data: languages } = useGetLanguages();
	const { data: sites } = useSimpleGet<SiteResponse[]>(ENDPOINTS.SITES);

	const renderingHosts = useMemo(() => {
		const renderings = renderingHostsData?.map((item) => ({ value: item.name, label: item.name })) || [];

		return renderings;
	}, [renderingHostsData]);

	const validation = useMemo<SiteDefinitionValidationData>(() => {
		const siteDefinitions = sites?.map((site) => site.hosts || []).flat() || [];
		const names = siteDefinitions.map((site) => site.name || '') || [];
		const hostnames = siteDefinitions.flatMap((s) => s.hostnames || []).filter((s) => s !== '*') || [];

		const site = sites?.find((s) => s.id === siteId);
		const siteHasAsterisk = site?.hosts?.some((h) => h.hostnames?.includes('*'));

		if (siteHasAsterisk) {
			hostnames.push('*');
		}

		return { hostnames, names };
	}, [sites, siteId]);

	const defaultValues = useMemo<SiteDefinitionFormValues>(
		() => ({
			name: host ? `${COPY_OF_PREFIX_WITH_DASH}${host?.name || ''}` : '',
			hostname: '*',
			language: host?.languageSettings?.defaultLanguage || '',
			renderingHost: DICTIONARY.DEFAULT,
			homePageId: host?.homePageId || page?.children?.[0].id || '',
		}),
		[host, page]
	);

	return { defaultValues, languages, renderingHosts, validation };
};

import { Button, ButtonGroup, Flex, FormControl, FormLabel } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { useFeatures } from 'features/common/featureFlags/featureFlags';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { useThumbnailEA } from '../hooks/use-thumbnail-ea';
import { ThumbnailPropsEA, ThumbnailType } from '../models';
import { ThumbnailAutoEA } from './thumbnail-auto-ea';
import { ThumbnailManualGeneral } from './thumbnail-manual-general';

/**
 * @deprecated Update is blocked for EA
 */
export const ThumbnailEA: FC<ThumbnailPropsEA> = ({ site, autoLabel = DICTIONARY.AUTOGENERATE, manualLabel = DICTIONARY.UPLOAD }) => {
	const { onAutoClickHandler, onManualClickHandler, typeThumbnail, isRegenerating } = useThumbnailEA({ site });

	const { showSitesGrouping } = useFeatures();

	if (showSitesGrouping) {
		return null;
	}

	return (
		<Flex data-testid="thumbnail-section" maxW="xl" direction="column" gap="2">
			<FormControl>
				<FormLabel>
					<T _str={DICTIONARY.THUMBNAIL.LABEL} />
				</FormLabel>
				<ButtonGroup
					borderRadius="full"
					background="chakra-body-bg"
					border="1px"
					borderColor="chakra-border-color"
					padding="1"
					variant="toggle"
					size="sm"
					spacing="0"
				>
					<Button isActive={typeThumbnail === ThumbnailType.AUTO} onClick={onAutoClickHandler}>
						<T _str={autoLabel} />
					</Button>
					<Button isActive={typeThumbnail === ThumbnailType.MANUAL} onClick={onManualClickHandler}>
						<T _str={manualLabel} />
					</Button>
				</ButtonGroup>
			</FormControl>
			{typeThumbnail === ThumbnailType.AUTO ? (
				<ThumbnailAutoEA site={site} isAutoRegenerating={isRegenerating} />
			) : (
				<ThumbnailManualGeneral site={site} />
			)}
		</Flex>
	);
};

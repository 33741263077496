import { useToast } from '@chakra-ui/react';
import { useTranslate } from 'features/common/utils/translateUtils';
import { RenamePayload, UseRenameSiteProps } from 'features/site-actions-menu/models';
import { useCallback } from 'react';
import { DICTIONARY } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { SiteResponse } from 'shared/models';
import { usePatch } from 'shared/react-query-wrappers/hooks';
import { siteRenameMetaNameValidationSchema } from 'shared/validations';

export const useRenameSite = ({ siteId, collectionId }: UseRenameSiteProps) => {
	const toast = useToast();
	const t = useTranslate();

	const { mutateAsync } = usePatch<SiteResponse, RenamePayload>({
		queryKey: { url: ENDPOINTS.SITE, params: { id: siteId } },
		refetchQueriesByKey: [
			{ url: ENDPOINTS.COLLECTION_SITES, params: { collectionId }, shouldWait: true },
			{ url: ENDPOINTS.SITES },
			{ url: ENDPOINTS.SITE, params: { id: siteId } },
			{ url: ENDPOINTS.JOBS },
		],
	});

	const handleRename = useCallback(
		async (values: { siteName: string }, closeCallback: () => void) => {
			try {
				const resolve = await mutateAsync({ displayName: values.siteName });

				if (resolve) {
					toast({
						status: 'success',
						description: t(DICTIONARY.SITE_RENAME_SUCCESS),
					});
				}
			} catch (error) {
				toast({
					status: 'error',
					description: t(DICTIONARY.SITE_RENAME_FAILURE),
				});
			}

			closeCallback();
		},
		[mutateAsync, t, toast]
	);

	return {
		siteRenameMetaNameValidationSchema,
		handleRename,
	};
};

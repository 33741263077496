import { MenuItem } from '@chakra-ui/react';
import { mdiContentCopy } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { DuplicateSiteDefinitionMenuItemProps } from 'features/site-definitions/models';
import { CreateSiteDefinitionModal } from 'features/site-definitions/ui';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';

export const DuplicateSiteDefinitionMenuItem: FC<DuplicateSiteDefinitionMenuItemProps> = ({ canCreate, ...props }) => {
	return (
		<CreateSiteDefinitionModal {...props}>
			<MenuItem icon={<Icon path={mdiContentCopy} />} isDisabled={!canCreate} data-testid="duplicateSiteDefinitionMenuItem">
				<T _str={DICTIONARY.DUPLICATE} />
			</MenuItem>
		</CreateSiteDefinitionModal>
	);
};

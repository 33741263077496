import { Stack } from '@chakra-ui/react';
import { SectionDataRecord } from 'entities/page-layout/ui';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { SiteResponse } from 'shared/models';
import { useGet } from 'shared/react-query-wrappers/hooks';
import { PosInfoPopoverProps } from 'widgets/site-pos-table/models';

/** @deprecated */
export const PosInfoPopoverBodyEA: FC<PosInfoPopoverProps> = ({ identifier }) => {
	const { data: sites, isLoading } = useGet<SiteResponse[]>({
		queryKey: { url: ENDPOINTS.POS_SITES_OLD, params: { pos: encodeURIComponent(identifier?.name ?? '') } },
	});
	const sitesNames = sites?.map((site) => site.name).join(', ');

	return (
		<Stack gap="4">
			<SectionDataRecord header={DICTIONARY.NAME} value={identifier?.name ?? ''} />
			<SectionDataRecord header={DICTIONARY.ASSIGNED_TO_SITE_DEFINITION} value={sitesNames ?? ''} isLoading={isLoading} />
			<SectionDataRecord header={DICTIONARY.TIMEOUT_IN_MINUTES} value={identifier?.timeout?.toString() ?? ''} />
		</Stack>
	);
};

import { useToast } from '@chakra-ui/react';
import { useTranslate } from 'features/common/utils/translateUtils';
import { DICTIONARY } from 'shared/constants';
import { PosPayload } from 'features/pos/utils/types';
import { useGetSite } from 'features/sites/utils/useGetSite';
import { useUpdateSitePos } from 'features/sites/utils/useUpdateSitePos';
import { useCallback } from 'react';

export const useAssignPos = () => {
	const [updateSitesPos] = useUpdateSitePos();
	const { fetchSite } = useGetSite();
	const toast = useToast();
	const t = useTranslate();

	return useCallback(
		async (selectedPos: PosPayload | undefined, language?: string) => {
			const site = await fetchSite();

			if (!site || !selectedPos || !language) {
				return;
			}

			const sitesPos = site.posMappings;
			const existingPos = sitesPos.find((p) => p.language === language);

			if (existingPos) {
				existingPos.name = selectedPos.name;
			} else {
				sitesPos.push({
					language: language,
					name: selectedPos.name,
				});
			}

			await updateSitesPos({
				variables: {
					posMappingsInput: [
						{
							id: site.id,
							newValue: sitesPos,
						},
					],
				},
				onCompleted: (data) => {
					const result = data.updateSolutionSitesPos?.result;

					if (!result) {
						return;
					}

					for (const status of result) {
						if (!status.success) {
							toast({
								status: 'info',
								description: t(DICTIONARY.ASSIGN_WAS_UNSUCCESSFUL),
							});
						}
					}
				},
			});
			// refetch site to ensure new pos mappings in place
			await fetchSite();
		},
		[fetchSite, toast, updateSitesPos, t]
	);
};

import { useCallback, useRef } from 'react';
import { SITE_SETTINGS_FORM_EVENT_NAME } from 'shared/constants';
import { useEventListener } from 'usehooks-ts';
import { GeneralSectionEvent, OptionList } from 'widgets/site-settings-update-form/models';

export const useLanguageSectionBody = (resetForm: () => void) => {
	const documentRef = useRef<Document>(document);

	const onEventDispatch = useCallback(
		(event: CustomEvent<GeneralSectionEvent>) => {
			const { type } = event.detail;

			if (type === 'reset') {
				resetForm();
			}
		},
		[resetForm]
	);

	useEventListener(SITE_SETTINGS_FORM_EVENT_NAME, onEventDispatch, documentRef);

	const defaultValue = (options: OptionList, value: string) => (options ? options.find((option) => option.value === value) : '');

	return {
		defaultValue,
	};
};

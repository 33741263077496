import { useFormErrorsToast } from 'entities/form-errors-toast/hooks';
import { useTranslate } from 'features/common/utils/translateUtils';
import { OnChangeThumbnailProxyProps } from 'features/thumbnail/models';
import { FormikProps } from 'formik';
import { useCallback, useRef } from 'react';
import { DICTIONARY, SITE_DEFINITION_FIELDS_EVENT, SITE_DEFINITION_FORM_EVENT_NAME } from 'shared/constants';
import { useEventListener } from 'usehooks-ts';
import {
	GeneralSectionEvent,
	SiteDefinitionFieldsEvent,
	SiteDefinitionViewModel,
	mapToBoolean,
	onSiteTouched,
	setSiteDefinitionFieldValue,
} from 'widgets/site-definition-update-form/models';

const fieldsDictionary = {
	name: DICTIONARY.NAME,
	hostname: DICTIONARY.HOST_NAME,
	targetHostname: DICTIONARY.TARGET_HOST_NAME,
	renderingHost: DICTIONARY.RENDERING_HOST,
	language: DICTIONARY.DEFAULT_LANGUAGE,
};

export const useGeneralSectionBody = (
	props: Pick<FormikProps<SiteDefinitionViewModel>, 'handleChange' | 'submitForm' | 'validateForm' | 'resetForm' | 'initialValues' | 'values'>
) => {
	const { handleChange, submitForm, validateForm, resetForm, initialValues, values } = props;
	const { showToast } = useFormErrorsToast();
	const t = useTranslate();
	const documentRef = useRef<Document>(document);

	const onButtonsClick = useCallback(
		(event: CustomEvent<GeneralSectionEvent>) => {
			const { type } = event.detail;

			if (type === 'submit') {
				void validateForm().then((errors) =>
					showToast({
						errors,
						fieldsDictionary,
						title: t(DICTIONARY.UNABLE_TO_SAVE),
						description: t(DICTIONARY.THE_FOLLOWING_FIELDS_CONTAIN_ERRORS),
						status: 'error',
					})
				);

				void submitForm();
			} else if (type === 'reset') {
				resetForm();
			}
		},
		[resetForm, showToast, submitForm, t, validateForm]
	);

	const onChangeProxy: typeof handleChange = useCallback(
		(event: React.ChangeEvent<SiteDefinitionFieldsEvent>) => {
			handleChange(event);

			const touchedFieldName = event.target.name;
			const initialValue = initialValues[touchedFieldName];
			const newValue = event.target.value;
			const isDirty = initialValue !== newValue && initialValue !== mapToBoolean(newValue);

			if (isDirty) {
				onSiteTouched(isDirty);

				return;
			}

			const keys = Object.keys(initialValues) as Array<keyof SiteDefinitionViewModel>;

			for (const key of keys) {
				if (key === touchedFieldName) continue;

				const initialFieldValue = initialValues[key];
				const fieldValue = values[key] || '';
				const isFieldDirty = initialFieldValue !== fieldValue && initialFieldValue !== mapToBoolean(fieldValue);

				if (isFieldDirty) {
					onSiteTouched(true);

					return;
				}
			}

			onSiteTouched(false);
		},
		[handleChange, initialValues, values]
	);

	const onLanguageSectionFieldsEvent = useCallback(
		(event: CustomEvent<SiteDefinitionFieldsEvent>) => {
			onChangeProxy({
				target: {
					name: event.detail.name,
					value: event.detail.value,
				},
			} as React.ChangeEvent<any>);
		},
		[onChangeProxy]
	);

	const onChangeThumbnailProxy: OnChangeThumbnailProxyProps = (value, fieldName) => {
		setSiteDefinitionFieldValue(fieldName, typeof value === 'string' ? value : value.value);
	};

	useEventListener(SITE_DEFINITION_FORM_EVENT_NAME, onButtonsClick, documentRef);

	useEventListener(SITE_DEFINITION_FIELDS_EVENT, onLanguageSectionFieldsEvent, documentRef);

	return {
		onChangeProxy,
		onChangeThumbnailProxy,
	};
};

import { useToast } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { Page } from 'features/create-site-page/models';
import { useValidatePageNameUniqueness } from 'features/page-actions-menu/hooks/use-validate-page-name-uniqueness';
import { RenamePageModalInputProps, UseRenamePageProps } from 'features/page-actions-menu/models';
import { useCallback } from 'react';
import { DEFAULT_LANGUAGE, DICTIONARY, DISPLAY_NAME_ITEM_ID } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { useGet, usePost, usePut } from 'shared/react-query-wrappers/hooks';
import { dispatchRenamePageEndEvent, dispatchRenamePageStartEvent } from 'shared/site-structure';
import { sitePageItemValidationSchema } from 'shared/validations';

export const useRenamePage: UseRenamePageProps = (pageId, siteId, siblingNames, name, displayName, onClose) => {
	const toast = useToast();
	const validatePageNameUniqueness = useValidatePageNameUniqueness(siblingNames, name);
	const { data: versions, isLoading } = useGet<Page[]>({
		queryKey: {
			url: ENDPOINTS.PAGE_VERSIONS,
			params: { siteId, pageId, language: DEFAULT_LANGUAGE },
		},
		refetchOnMount: 'always',
	});
	const { mutateAsync: renamePageAsync } = usePost({ queryKey: { url: ENDPOINTS.RENAME_PAGE, params: { pageId } } });
	const { mutateAsync: addPageVersionAsync } = usePost({ queryKey: { url: ENDPOINTS.PAGE_VERSION, params: { pageId } } });
	const { mutateAsync: changeDisplayNameAsync } = usePut({ queryKey: { url: ENDPOINTS.PAGE, params: { pageId } } });

	const makeAsyncRequest = useCallback(
		async (values: RenamePageModalInputProps) => {
			if (values.name !== name) {
				await renamePageAsync({ newName: values.name, language: DEFAULT_LANGUAGE });
			}

			if (values.displayName === displayName) {
				return false;
			}

			if (!isLoading) {
				if (!versions?.length) {
					await addPageVersionAsync({ language: DEFAULT_LANGUAGE });
				}

				await changeDisplayNameAsync({
					language: DEFAULT_LANGUAGE,
					fields: [{ name: DISPLAY_NAME_ITEM_ID, value: values.displayName }],
				});
			}
		},
		[addPageVersionAsync, changeDisplayNameAsync, displayName, isLoading, name, renamePageAsync, versions]
	);

	const handleRename = useCallback(
		async (values: RenamePageModalInputProps, closeCallback: () => void) => {
			closeCallback();
			dispatchRenamePageStartEvent(pageId, values.displayName);

			const promise = makeAsyncRequest(values);

			toast.promise(promise, {
				loading: { description: <T _str={DICTIONARY.RENAME_PAGE_PROMISE_TOAST.LOADING} /> },
				success: { description: <T _str={DICTIONARY.RENAME_PAGE_PROMISE_TOAST.SUCCESS} /> },
				error: { description: <T _str={DICTIONARY.RENAME_PAGE_PROMISE_TOAST.ERROR} /> },
			});

			// Dispatch page events are responsible for updating the UI
			promise.catch(() => dispatchRenamePageEndEvent(pageId)).finally(() => dispatchRenamePageEndEvent(pageId));
		},
		[makeAsyncRequest, pageId, toast]
	);

	const submitHandler = (values: RenamePageModalInputProps) => {
		if (values.name === name && values.displayName === displayName) {
			return onClose();
		}

		void handleRename(values, onClose);
	};

	return { sitePageItemValidationSchema, submitHandler, validatePageNameUniqueness };
};

import { useToast } from '@chakra-ui/react';
import { useSaveNavigate } from 'features/common/utils/navigationUtils';
import { useCallback } from 'react';

type UsePosNotification = {
	onSettingsClick: () => void;
};

export const usePosNotification = (missingPosToastId: string): UsePosNotification => {
	const navigate = useSaveNavigate();
	const toast = useToast();

	const onSettingsClick = useCallback(() => {
		toast.close(missingPosToastId);
		navigate('/settings');
	}, [missingPosToastId, navigate, toast]);

	return { onSettingsClick };
};

import { MenuItem } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { DICTIONARY } from 'shared/constants';
import { useRenameSite } from './RenameSiteProvider';

export const RenameContextMenuItem = ({ siteName }: { siteName: string }) => {
	const { renameSite } = useRenameSite();

	return (
		<MenuItem onClick={() => renameSite(siteName)} data-testid="renameSiteButton">
			<T _str={DICTIONARY.RENAME} />
		</MenuItem>
	);
};

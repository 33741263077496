import { useToast } from '@chakra-ui/react';
import { useTranslate } from 'features/common/utils/translateUtils';
import { SiteDefinitionFormValues, UseCreateSiteDefinitionAction } from 'features/site-definitions/models';
import { useCallback } from 'react';
import { DICTIONARY } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { CreateHostInput } from 'shared/models';
import { usePost } from 'shared/react-query-wrappers/hooks';

export const useCreateSiteDefinitionAction = ({ siteId, host, collectionId, onStart, onEnd, onClose }: UseCreateSiteDefinitionAction) => {
	const { mutateAsync } = usePost<any, CreateHostInput>({
		queryKey: { url: ENDPOINTS.HOSTS, params: { siteId } },
		ignoreGlobalErrorHandler: true,
		refetchQueriesByKey: [
			{ url: ENDPOINTS.SITE, params: { id: siteId }, shouldWait: true },
			{ url: ENDPOINTS.COLLECTION_SITES, params: { collectionId: collectionId || '' } },
			{ url: ENDPOINTS.SITES },
			{ url: ENDPOINTS.LANGUAGES },
			{ url: ENDPOINTS.JOBS },
		],
	});
	const toast = useToast();
	const t = useTranslate();

	return useCallback(
		(values: SiteDefinitionFormValues) => {
			onStart?.();
			const input = {
				name: values.name,
				hostnames: [values.hostname],
				renderingHost: values.renderingHost,
				analyticsIdentifiers: host ? Object.entries(host?.analyticsIdentifiers || {}).map(([k, v]) => ({ language: k, name: v })) : undefined,
				homePageId: values.homePageId,
				languageSettings: { ...(host?.languageSettings || {}), defaultLanguage: values.language },
			} as CreateHostInput;

			const promise = mutateAsync(input);

			promise.finally(() => onEnd?.());
			toast.promise(promise, {
				loading: { description: host ? t(DICTIONARY.DUPLICATING_SITE_DEFINITION) : t(DICTIONARY.CREATING, { name: input.name }) },
				success: { description: host ? t(DICTIONARY.SITE_DEFINITION_DUPLICATED) : t(DICTIONARY.SITE_DEFINITION_CREATED) },
				error: { description: host ? t(DICTIONARY.SITE_DEFINITION_DUPLICATE_FAILED) : t(DICTIONARY.SITE_DEFINITION_FAILED) },
			});

			onClose();
		},
		[mutateAsync, onClose, t, onStart, onEnd, toast, host]
	);
};

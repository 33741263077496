import { Flex, Text, TextProps } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';

export const FieldDetails: FC<{ name: string; value: string | undefined; alt?: string; labelProps?: TextProps; textProps?: TextProps }> = ({
	name,
	value,
	alt,
	labelProps,
	textProps,
}) => {
	if (value === undefined && alt === undefined) {
		return <></>;
	}

	return (
		<Flex direction="column">
			<Text {...labelProps} fontSize="sm" fontWeight="medium" color="neutral-fg">
				<T _str={name} />
			</Text>
			<Text role="textbox" {...textProps} whiteSpace="pre-wrap">
				{value || <T _str={alt} />}
			</Text>
		</Flex>
	);
};

import { Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { CreateCollectionModalBody } from 'features/collections/ui';
import { FC, PropsWithChildren } from 'react';
import { DICTIONARY } from 'shared/constants';
import { useModalWrapper } from 'shared/modal-wrapper/hooks';
import { ModalWrapperProps } from 'shared/modal-wrapper/models';

export const CreateCollectionModal: FC<PropsWithChildren<ModalWrapperProps>> = (props) => {
	const { children, isOpen, onClose } = useModalWrapper({ children: props.children });

	return (
		<>
			{children}
			<Modal isOpen={isOpen} onClose={onClose} size="md">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						<T _str={DICTIONARY.CREATE_COLLECTION} />
					</ModalHeader>
					<ModalCloseButton />
					<CreateCollectionModalBody onClose={onClose} />
				</ModalContent>
			</Modal>
		</>
	);
};

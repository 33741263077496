import { Flex, IconButton, Input } from '@chakra-ui/react';
import { mdiCloseCircleOutline } from '@mdi/js';
import { FormFieldProps } from 'entities/form-field-control/models';
import { FormFieldControl } from 'entities/form-field-control/ui';
import { Icon } from 'entities/icon/ui';
import { Field } from 'formik';
import { FC } from 'react';
import { useHostnameField } from 'widgets/site-definition-update-form/hooks/use-hostname-field';

export type HostnameFieldProps = {
	hostname: string;
	setHostname: (hostname: string) => void;
	allHostNames: string[];
};

export const HostnameField: FC<HostnameFieldProps> = (props) => {
	const { handleHostnameChange, names, removeField, validateHostname } = useHostnameField(props);

	return (
		<>
			{names.map((hostnamePiece, index) => (
				<Field name={`hostname-${index}`} key={`hostname-${index}`} validate={() => validateHostname(index)}>
					{({ field, form }: FormFieldProps) => (
						<FormFieldControl
							form={form}
							field={field}
							name={field.name}
							label=""
							minW="xs"
							maxW="xl"
							isInvalid={!!form.errors[field.name] && (!!form.touched.hostname || !!form.touched[field.name])}
						>
							<Flex direction="row" gap="2" alignItems="center">
								<Input type="url" {...field} value={hostnamePiece} onChange={(e) => handleHostnameChange(e, index)} />
								{names.length > 1 && (
									<IconButton
										aria-label={field.name}
										variant="ghost"
										colorScheme="neutral"
										size="md"
										icon={<Icon path={mdiCloseCircleOutline} />}
										onClick={() => removeField(index)}
									/>
								)}
							</Flex>
						</FormFieldControl>
					)}
				</Field>
			))}
		</>
	);
};

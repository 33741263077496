import { MenuItem, Tooltip } from '@chakra-ui/react';
import { mdiTrashCanOutline } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { useTranslate } from 'features/common/utils/translateUtils';
import { DeletePageMenuItemProps } from 'features/page-actions-menu/models';
import { DeletePageAlert } from 'features/page-actions-menu/ui/delete-page-alert';
import { FC, useMemo } from 'react';
import { DICTIONARY } from 'shared/constants';

export const DeletePageMenuItem: FC<DeletePageMenuItemProps> = (props) => {
	const { canDelete, startItemOfHosts, parentOfHosts } = props;
	const t = useTranslate();

	const label = useMemo(() => {
		if (!canDelete) {
			if (startItemOfHosts && startItemOfHosts.length > 0) {
				return t(DICTIONARY.THIS_PAGE_IS_HOMEPAGE_TOOLTIP, { hosts: startItemOfHosts.join(', ') });
			}

			if (parentOfHosts && parentOfHosts.length > 0) {
				return t(DICTIONARY.THIS_PAGE_IS_PARENT_FOR_HOMEPAGE_TOOLTIP, { hosts: parentOfHosts.join(', ') });
			}

			return t(DICTIONARY.YOU_DO_NOT_HAVE_PERMISSION_TO_DELETE_PAGE);
		}
	}, [canDelete, startItemOfHosts, parentOfHosts, t]);

	return (
		<Tooltip label={label} shouldWrapChildren>
			<DeletePageAlert {...props}>
				<MenuItem
					color="danger"
					icon={<Icon path={mdiTrashCanOutline} color="danger" />}
					isDisabled={!canDelete}
					data-testid="delete-page-menu-item"
				>
					<T _str={DICTIONARY.DELETE} />
				</MenuItem>
			</DeletePageAlert>
		</Tooltip>
	);
};

import { Box, Link, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { DashboardLoadingScreen } from 'features/common/components/DashboardLoadingScreen';
import { useIsFeatureAvailable } from 'features/common/tenantVersionPrivider/components/TenantVersionProvider';
import { FC } from 'react';
import { DICTIONARY, SC_ICONS_SRC, SUPPORT_PAGE_LINK } from 'shared/constants';
import { GenericErrorTemplate } from 'shared/generic-error/ui';

export const XMTenantVersionHandler: FC<React.PropsWithChildren> = ({ children }) => {
	const { loading, isAvaialable } = useIsFeatureAvailable('XmTenant');

	if (loading) {
		return (
			<Box marginTop={48}>
				<DashboardLoadingScreen text="&nbsp;" />
			</Box>
		);
	}

	if (!isAvaialable) {
		return (
			<GenericErrorTemplate
				imageSrc={SC_ICONS_SRC.GENERIC_ERROR}
				title={DICTIONARY.GENERIC_ERROR.TITLE}
				text={
					<Text>
						<T
							_str={DICTIONARY.GENERIC_ERROR.DESCRIPTION}
							link={
								<Link href={SUPPORT_PAGE_LINK} target="_blank">
									<T _str={DICTIONARY.GENERIC_ERROR.LINK_TEXT} />
								</Link>
							}
						/>
					</Text>
				}
			/>
		);
	}

	return <>{children}</>;
};

import _ from 'lodash';
import { useCallback, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { REMOVE_QUERY_STRING } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { LanguageResponse } from 'shared/languages/models/languages';
import { useSimpleGet } from 'shared/react-query-wrappers/hooks';
import { LANGUAGES_SEARCH_PARAM, LanguageData } from 'widgets/languages-table/models/languages';

export const useLanguagesTable = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const ref = useRef<HTMLInputElement>(null);
	const { data: systemLanguages = [], isLoading } = useSimpleGet<LanguageResponse[]>(ENDPOINTS.LANGUAGES);
	const { data: supportedLanguages = [], isLoading: isSupportedLanguagesLoading } = useSimpleGet<LanguageResponse[]>(ENDPOINTS.LANGUAGES_SUPPORTED);

	const search = useMemo(() => {
		return searchParams.get(LANGUAGES_SEARCH_PARAM) || '';
	}, [searchParams]);

	const searchFor = useCallback(
		(searchInput = '') =>
			setSearchParams(
				(params) => {
					if (searchInput === '') {
						params.delete(LANGUAGES_SEARCH_PARAM);

						if (ref.current) {
							ref.current.value = '';
						}
					} else {
						params.set(LANGUAGES_SEARCH_PARAM, searchInput);
					}

					return params;
				},
				{ replace: true, state: { [REMOVE_QUERY_STRING]: [LANGUAGES_SEARCH_PARAM] } }
			),
		[setSearchParams]
	);

	const languagesData = useMemo(() => {
		let result: LanguageResponse[];

		if (search && search.length >= 2) {
			result =
				systemLanguages?.filter((language) => {
					const hasEnglishNameMatch = language.englishName.toLowerCase().includes(search.toLowerCase());
					const hasNameMatch = language.name.toLowerCase().includes(search.toLowerCase());

					return hasEnglishNameMatch || hasNameMatch;
				}) || [];
		} else {
			result = systemLanguages || [];
		}

		result = _.orderBy(result, ['englishName'], ['asc']);

		return result.map<LanguageData>((language) => {
			let isFallbackRemoved = false;
			let fallbackLanguage: LanguageResponse | undefined;

			if (language.fallbackLanguageIso) {
				fallbackLanguage = systemLanguages.find((lang) => lang.name === language.fallbackLanguageIso);
				isFallbackRemoved = !fallbackLanguage;

				if (isFallbackRemoved) {
					fallbackLanguage = supportedLanguages.find((lang) => lang.name === language.fallbackLanguageIso);
				}
			}

			return {
				...language,
				fallbackLanguage,
				isFallbackRemoved,
			};
		});
	}, [search, supportedLanguages, systemLanguages]);

	return {
		systemLanguages,
		isLoading: isLoading || isSupportedLanguagesLoading,
		languagesData,
		search,
		searchFor,
		ref,
	};
};

import { useMemo } from 'react';
import { ENDPOINTS } from 'shared/endpoints';
import type { GetTreeItemData, HierarchyPage, ItemViewModel } from 'shared/item-tree/models';
import { useGet } from 'shared/react-query-wrappers/hooks';

export const useGetTreeItemWrapper = (siteId: string, pageId: string | undefined, enabled: boolean): GetTreeItemData => {
	const { data, isLoading } = useGet<HierarchyPage>({
		queryKey: { url: pageId ? ENDPOINTS.HIERARCHY_PAGE : ENDPOINTS.HIERARCHY, params: { siteId, pageId: pageId || '' } },
		enabled,
	});

	const item = useMemo(() => {
		return data && ({ ...data?.page, children: data.children, siblings: data?.siblings, ancestors: data?.ancestors } as ItemViewModel);
	}, [data]);

	return { data: item, isLoading };
};

import { QueryClient, QueryFilters } from '@tanstack/react-query';
import { ENDPOINTS } from 'shared/endpoints';
import { mapPageToResponse, PageDataResponse, PageResponse, PageViewModel } from 'shared/models';
import { PageDropProps } from 'widgets/site-structure/ui/page-drop';

export type RestoreCache = {
	queryKey: QueryFilters;
	previousData:
		| {
				data: PageResponse;
		  }
		| undefined;
};

type RemoveProps = {
	siteId: string;
	movingPage: PageViewModel;
	queryClient: QueryClient;
};

type AddProps = RemoveProps & Pick<PageDropProps, 'position' | 'parentId' | 'targetId'>;

const getChildrenFromCache = async (queryClient: QueryClient, pageId: string, siteId: string) => {
	const queryKey = { queryKey: [ENDPOINTS.HIERARCHY_PAGE, { siteId, pageId }], exact: true } as QueryFilters;

	await queryClient.cancelQueries(queryKey);
	const previousData = queryClient.getQueryData<{ data: PageResponse }>(queryKey.queryKey || []);
	const children = [...(previousData?.data.children || [])];

	return { queryKey, previousData, children };
};

const updateCacheWithNewChildren = (
	queryClient: QueryClient,
	queryKey: QueryFilters,
	children: PageDataResponse[],
	previousData: { data: PageResponse } | undefined
) => {
	if (!previousData) {
		return;
	}

	const hasChildren = children.length > 0;
	const newData = { ...previousData, data: { ...previousData?.data, children, page: { ...previousData?.data.page, hasChildren } } };

	queryClient.setQueryData(queryKey.queryKey || [], newData);
};

export const removeMovePageFromCache = async ({ movingPage, queryClient, siteId }: RemoveProps): Promise<RestoreCache> => {
	const { children, previousData, queryKey } = await getChildrenFromCache(queryClient, movingPage.parentId, siteId);
	const index = children.findIndex((child: any) => child.id === movingPage.id);

	if (index === -1) {
		return { queryKey, previousData: undefined };
	}

	children.splice(index, 1);

	updateCacheWithNewChildren(queryClient, queryKey, children, previousData);

	return { queryKey, previousData };
};

export const addMovePageToCache = async ({ parentId, position, queryClient, siteId, targetId, movingPage }: AddProps): Promise<RestoreCache> => {
	const { children, previousData, queryKey } = await getChildrenFromCache(queryClient, parentId, siteId);

	const index = children.findIndex((child: any) => child.id === targetId);

	const page = mapPageToResponse(movingPage);

	page.parentId = parentId;

	if (position === 'before') {
		children.splice(index, 0, page);
	} else {
		children.splice(index + 1, 0, page);
	}

	updateCacheWithNewChildren(queryClient, queryKey, children, previousData);

	return { queryKey, previousData };
};

export const movePageInBranch = async ({ parentId, position, queryClient, siteId, targetId, movingPage }: AddProps): Promise<RestoreCache> => {
	const { children, previousData, queryKey } = await getChildrenFromCache(queryClient, parentId, siteId);

	let index = children.findIndex((child: any) => child.id === movingPage.id);

	if (index >= 0) {
		children.splice(index, 1);
	}

	index = children.findIndex((child: any) => child.id === targetId);

	if (position === 'before') {
		children.splice(index, 0, mapPageToResponse(movingPage));
	} else {
		children.splice(index + 1, 0, mapPageToResponse(movingPage));
	}

	updateCacheWithNewChildren(queryClient, queryKey, children, previousData);

	return { queryKey, previousData };
};

export const movePageInsideBranch = async ({ parentId, queryClient, siteId, movingPage, targetId }: AddProps): Promise<RestoreCache> => {
	const { children, previousData, queryKey } = await getChildrenFromCache(queryClient, parentId, siteId);

	const page = mapPageToResponse(movingPage);

	page.parentId = targetId;
	children.splice(0, 0, page);

	updateCacheWithNewChildren(queryClient, queryKey, children, previousData);

	return { queryKey, previousData };
};

import { MenuItem, Tooltip } from '@chakra-ui/react';
import { mdiContentCopy } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { useTranslate } from 'features/common/utils/translateUtils';
import { DuplicatePageMenuItemProps } from 'features/page-actions-menu/models';
import { DuplicatePageModal } from 'features/page-actions-menu/ui/duplicate-page-modal';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';

export const DuplicatePageMenuItem: FC<DuplicatePageMenuItemProps> = ({ canDuplicate, ...props }) => {
	const t = useTranslate();

	return (
		<Tooltip label={!canDuplicate ? t(DICTIONARY.ACCESS_DENIED.PAGE_DUPLICATE) : ''} shouldWrapChildren>
			<DuplicatePageModal {...props}>
				<MenuItem icon={<Icon path={mdiContentCopy} />} isDisabled={!canDuplicate} data-testid="duplicate-page-menu-item">
					<T _str={DICTIONARY.DUPLICATE} />
				</MenuItem>
			</DuplicatePageModal>
		</Tooltip>
	);
};

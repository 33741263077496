import { useIsFeatureAvailable } from 'features/common/tenantVersionPrivider/components/TenantVersionProvider';
import { TypeFilter } from 'features/sites/components/TypeFilter';
import { FC } from 'react';

export const TypeFilterBCWrapper: FC = () => {
	const { isAvaialable } = useIsFeatureAvailable('NonSXASites');

	if (isAvaialable) {
		return <TypeFilter />;
	}

	return <></>;
};

import { Flex, Spacer } from '@chakra-ui/react';
import { DetailedItemBase } from 'features/millerColumns/utils';
import { FC } from 'react';
import { ButtonGroup } from './ButtonGroup';

export const ActionsPanel: FC<DetailedItemBase> = ({ currentItem, itemType, currentSite }) => {
	const noActions = !currentItem?.hasPresentation && itemType != 'site';

	return (
		<Flex direction="row" gap="3">
			<Spacer />
			{noActions ? <></> : <ButtonGroup currentItem={currentItem} itemType={itemType} currentSite={currentSite} />}
		</Flex>
	);
};

import App from 'App';
import React from 'react';
import { createRoot } from 'react-dom/client';

const renderApp = () => {
	const root = createRoot(document.getElementById('root') || document.getElementsByTagName('body')[0]);

	root.render(
		<React.StrictMode>
			<App />
		</React.StrictMode>
	);
};

if (process.env.REACT_APP_MOCK === 'enabled') {
	void import('./mocks/browser').then((value) => {
		void value.worker.start().then(() => renderApp());
	});
} else {
	renderApp();
}

import { Link, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { PosInfoPopoverProps } from 'widgets/site-pos-table/models';
import { PosInfoPopoverBody } from 'widgets/site-pos-table/ui/pos-info-popover-body';

export const PosInfoPopover: FC<PosInfoPopoverProps> = ({ identifier }) => {
	return (
		<Popover placement="bottom-start" isLazy>
			<PopoverTrigger>
				<Link data-testid={`pos-link-${identifier?.name ?? ''}`} color="chakra-body-text">
					{identifier?.name ?? ''}
				</Link>
			</PopoverTrigger>
			<PopoverContent>
				<PopoverArrow />
				<PopoverCloseButton />
				<PopoverHeader alignContent="center" justifyContent="center" border="none">
					<Text variant="strong">
						<T _str={DICTIONARY.ANALYTICS_IDENTIFIER_DETAILS} />
					</Text>
				</PopoverHeader>
				<PopoverBody color="chakra-body-text" fontWeight="normal">
					<PosInfoPopoverBody identifier={identifier} />
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
};

import { useTranslate } from 'features/common/utils/translateUtils';
import { UseValidatePageNameUniqueness } from 'features/page-actions-menu/models';
import { useCallback } from 'react';
import { DICTIONARY } from 'shared/constants';

export const useValidatePageNameUniqueness: UseValidatePageNameUniqueness = (siblingNames, name) => {
	const t = useTranslate();

	return useCallback(
		(pageName: string) => {
			return siblingNames.some((siblingName) => siblingName.toLowerCase() === pageName.toLowerCase()) &&
				pageName.toLowerCase() !== name?.toLowerCase()
				? { name: t(DICTIONARY.PAGE_ITEM_EXISTS_ERROR) }
				: true;
		},
		[name, siblingNames, t]
	);
};

import { NORMALIZE_ID_WITHOUT_DASH_REGEX } from 'features/millerColumns/constants';
import { ITEM_SYSTEM_NAME_REGEX_SANITIZER } from 'shared/constants';

export const normalizeIdWithoutDash = (id: string) => {
	return id.replace(NORMALIZE_ID_WITHOUT_DASH_REGEX, '').toUpperCase();
};

export const isSameGuid = (id1: string | undefined, id2: string | undefined): boolean => {
	return normalizeIdWithoutDash(id1 || '') === normalizeIdWithoutDash(id2 || '');
};

export const sanitizeSiteName = (displayName: string | undefined) => {
	return displayName
		? displayName.replace(ITEM_SYSTEM_NAME_REGEX_SANITIZER, '').trim().replace(/\s+/g, '-').toLowerCase().replace(/^-+/, '')
		: undefined;
};

import { useCallback, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { REMOVE_QUERY_STRING } from 'shared/constants';
import { POS_PAGE_SEARCH_PARAM, POS_SEARCH_PARAM } from 'widgets/pos-table/pos-constants';

export const usePoSActions = () => {
	const [, setSearchParams] = useSearchParams();
	const ref = useRef<HTMLInputElement>(null);

	const searchFor = useCallback(
		(searchInput = '') =>
			setSearchParams(
				(params) => {
					if (searchInput === '') {
						params.delete(POS_SEARCH_PARAM);

						if (ref.current) {
							ref.current.value = '';
						}
					} else {
						params.set(POS_SEARCH_PARAM, searchInput);
					}

					params.delete(POS_PAGE_SEARCH_PARAM);

					return params;
				},
				{ replace: true, state: { [REMOVE_QUERY_STRING]: [POS_SEARCH_PARAM, POS_PAGE_SEARCH_PARAM] } }
			),
		[setSearchParams]
	);

	const activatePage = useCallback(
		(page: number) => {
			setSearchParams((params) => {
				if (page >= 2) {
					params.set(POS_PAGE_SEARCH_PARAM, page.toString());
				} else {
					params.delete(POS_PAGE_SEARCH_PARAM);
				}

				return params;
			});
		},
		[setSearchParams]
	);

	return { ref, searchFor, activatePage };
};

import { BoxProps, useToast } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { useReactQueryWrappersProvider } from 'app/react-query-wrappers-provider/hooks';
import { DragEvent, useCallback, useMemo, useState } from 'react';
import { DICTIONARY } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { MoveSitePagePayload, MoveSitePageResponse, MoveSitePageUrlParams } from 'shared/models';
import { usePostWithUrlInput } from 'shared/react-query-wrappers/hooks';
import { deserializeDrag } from 'widgets/site-structure/models/dragndrop';
import { defaultDropHeight, defaultDropHeightClosed, defaultPseudoHeight, getDropComponentStyle } from 'widgets/site-structure/styles/animations';
import { PageDropProps } from 'widgets/site-structure/ui/page-drop';
import { calculateMargin } from 'widgets/site-structure/utils/calculate-margin';
import { addMovePageToCache, movePageInBranch, removeMovePageFromCache, RestoreCache } from 'widgets/site-structure/utils/optimistic-update';

export const usePageDrop = ({ lvl, position, siblingNames, targetId, parentId, siteId, siblingPath, opened = false }: PageDropProps) => {
	const toast = useToast();
	const [isOpen, setIsOpen] = useState(opened);
	const marginLeft = calculateMargin(lvl);
	const height = isOpen ? defaultDropHeight : defaultDropHeightClosed;
	const pseudoHeight = isOpen ? defaultPseudoHeight : 0;

	const styles = useMemo<BoxProps>(() => getDropComponentStyle(height, pseudoHeight, marginLeft), [height, marginLeft, pseudoHeight]);

	const { queryClient } = useReactQueryWrappersProvider();
	const { mutateAsync: movePage } = usePostWithUrlInput<MoveSitePageResponse, MoveSitePagePayload, MoveSitePageUrlParams>({
		queryKey: { url: ENDPOINTS.MOVE_PAGE },
		ignoreGlobalErrorHandler: true,
	});

	const onDrop = useCallback(
		async (e: DragEvent<HTMLDivElement>) => {
			setIsOpen(false);
			const data = e.dataTransfer.getData('text/plain');
			const { movingPage, restoreCacheData } = deserializeDrag(data);
			let toRestore = false;

			if (siblingPath.startsWith(movingPage.path)) {
				toRestore = true;
			}

			const siblingFolder = siblingPath.split('/').slice(0, -1).join('/');
			const movePageFolder = movingPage.path.split('/').slice(0, -1).join('/');

			if (siblingNames.includes(movingPage.name) && siblingFolder !== movePageFolder) {
				queryClient.setQueryData(restoreCacheData.queryKey.queryKey || [], restoreCacheData.previousData);
				toast({
					description: <T _str={DICTIONARY.PAGE_EXISTS_ERROR} />,
					status: 'error',
				});

				toRestore = true;
			}

			if (toRestore) {
				queryClient.setQueryData(restoreCacheData.queryKey.queryKey || [], restoreCacheData.previousData);

				return;
			}

			const restoreCache: RestoreCache[] = [restoreCacheData];

			if (parentId === movingPage.parentId) {
				const restore = await movePageInBranch({ movingPage, parentId, queryClient, siteId, targetId, position });

				restoreCache.push(restore);
			} else {
				const restoreRemove = await removeMovePageFromCache({ movingPage, queryClient, siteId });
				const restoreAdd = await addMovePageToCache({
					movingPage,
					parentId,
					queryClient,
					siteId,
					targetId,
					position,
				});

				restoreCache.push(restoreAdd, restoreRemove);
			}

			try {
				await movePage({
					input: {
						itemToMoveId: movingPage.id,
						targetId,
						position: position === 'after' ? 'After' : 'Before',
						site: siteId,
					},
					additionalVariables: {
						pageId: movingPage.id,
					},
				});
			} catch {
				for (const restore of restoreCache) {
					queryClient.setQueryData(restore.queryKey.queryKey || [], restore.previousData);
				}

				toast({
					status: 'error',
					description: <T _str={DICTIONARY.COMMON_MOVE_ERROR} />,
				});
			}

			await queryClient.invalidateQueries({
				queryKey: [ENDPOINTS.HIERARCHY_PAGE, { siteId, pageId: parentId }],
				exact: true,
			});

			if (parentId !== movingPage.parentId) {
				await queryClient.invalidateQueries({
					queryKey: [ENDPOINTS.HIERARCHY_PAGE, { siteId, pageId: movingPage.parentId }],
					exact: true,
				});
			}
		},
		[movePage, parentId, position, queryClient, siblingNames, siblingPath, siteId, targetId, toast]
	);

	const onDragEnter = useCallback(() => setIsOpen(true), []);

	const onDragLeave = useCallback(() => setIsOpen(false), []);

	const onDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
	}, []);

	return {
		styles,
		onDrop,
		onDragOver,
		onDragEnter,
		onDragLeave,
	};
};

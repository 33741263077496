import { Button } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { behindFeature } from 'features/common/featureFlags/featureFlags';
import { useTranslate } from 'features/common/utils/translateUtils';
import { Dispatch, FC, SetStateAction } from 'react';
import { DICTIONARY } from 'shared/constants';
import { useSubmitResetButtons } from 'widgets/site-definition-update-form/hooks/use-buttons';
import { DiscardUnsavedAlert } from 'widgets/site-definition-update-form/ui/discard-unsaved-alert';

export const SubmitAndResetButtons: FC<{ onIsDirtyChange?: Dispatch<SetStateAction<boolean>> }> = ({ onIsDirtyChange }) => {
	const { isDirty, isSubmitting, onResetClick, onSubmitClick } = useSubmitResetButtons(onIsDirtyChange);
	const t = useTranslate();

	if (!isDirty) {
		return <></>;
	}

	return (
		<>
			<Button type="reset" variant="outline" onClick={onResetClick} isDisabled={isSubmitting} data-testid="edit-site-reset">
				<T _str={DICTIONARY.DISCARD} />
			</Button>
			<Button type="submit" onClick={onSubmitClick} isLoading={isSubmitting} loadingText={t(DICTIONARY.SAVING)} data-testid="edit-site-submit">
				<T _str={DICTIONARY.SAVE} />
			</Button>
			<DiscardUnsavedAlert isDirty={isDirty} isSubmitting={isSubmitting} onResetClick={onResetClick} />
		</>
	);
};

export const SubmitAndResetButtonsFeature = behindFeature('showSitesGrouping')(SubmitAndResetButtons);

import { useCompatibleQuery } from 'features/common/apolloProvider/useCompatibleQuery';
import { useIsFeatureAvailable } from 'features/common/tenantVersionPrivider/components/TenantVersionProvider';
import { defaultSitesInput } from 'shared/constants';
import { collectLanguagesIso } from 'features/millerColumns/utils/utils';
import _ from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { DuplicateKey, useDuplicateSiteProvider } from '../components/duplicate/DuplicateSiteProvider';
import { SiteInfo, SiteJob, SiteJobViewModel, SitesAndJobsPayload, SolutionSiteType } from '../data/createSiteTypes';
import { getSiteCollectionsList, getSitesAndJobsQuery } from '../queries';

export const createSiteJobWildcard = 'Scaffold site [';
export const removeSiteJobWildcard = 'Remove site [';
export const duplicateSiteJobWildcard = 'Clone site [';
const refetchTimeout = 60000;

const filterJobs = (jobs: SiteJob[], duplicateSitesInProgress: DuplicateKey[]): SiteJobViewModel[] => {
	return jobs
		.filter((job) => !job.done)
		.map<SiteJobViewModel>((job) => {
			const name = job.name;
			const lastSymbolIndex = name.indexOf(']');

			if (name.includes(createSiteJobWildcard)) {
				return {
					...job,
					siteName: name.substring(createSiteJobWildcard.length, lastSymbolIndex),
					operation: 'create',
				};
			} else if (name.includes(removeSiteJobWildcard)) {
				return {
					...job,
					siteName: name.substring(removeSiteJobWildcard.length, lastSymbolIndex),
					operation: 'remove',
				};
			} else if (name.includes(duplicateSiteJobWildcard)) {
				const siteName = name.substring(duplicateSiteJobWildcard.length, lastSymbolIndex);
				const duplicateName = duplicateSitesInProgress.find((site) => site.siteName === siteName);

				return {
					...job,
					siteName: duplicateName?.cloneName || siteName,
					operation: 'duplicate',
				};
			} else {
				return {
					...job,
					siteName: '',
					operation: 'other',
				};
			}
		});
};

const sortSites = (sites: SolutionSiteType[], sortBy: 'created_date' | 'name') => {
	return sortBy == 'name' ? _.orderBy(sites, [(site) => site.displayName.toLowerCase()], 'asc') : _.orderBy(sites, (site) => site.created, 'desc');
};

export type SitesAndJobsOptions = {
	sortSitesBy: 'created_date' | 'name';
};

export const useSitesAndJobsList = (
	options: SitesAndJobsOptions = { sortSitesBy: 'name' }
): {
	jobs: SiteJobViewModel[];
	sites: SiteInfo[];
	refetch: () => void;
	loading: boolean;
} => {
	const { isAvaialable } = useIsFeatureAvailable('NonSXASites');
	const { sitesInProgress, removeSitesInProgress } = useDuplicateSiteProvider();
	const { data, refetch, loading, client } = useCompatibleQuery<SitesAndJobsPayload>(getSitesAndJobsQuery, {
		variables: {
			includeSite: true,
			inludeJobs: true,
			includeLanguage: false,
			includePos: false,
			includeSettings: false,
			includeRootItem: true,
			includeSiteCollections: true,
			solutionInput: isAvaialable ? { database: 'master', includeNonSxaSites: true } : defaultSitesInput,
		},
	});

	const jobsInProgress = useMemo(() => {
		if (!data) {
			return {
				createJobs: [],
				jobs: [],
			};
		}

		const jobs = filterJobs(data.jobs.nodes, sitesInProgress);
		let createJobs = jobs.filter((j) => j.operation === 'create' || j.operation === 'duplicate');

		createJobs = _.orderBy(createJobs, [(job) => job.queueTime], 'desc');

		return {
			createJobs,
			jobs,
		};
	}, [data, sitesInProgress]);

	const sites: SiteInfo[] = useMemo(() => {
		if (!data?.solutionSites) {
			return [];
		}

		return sortSites(data.solutionSites, options.sortSitesBy)
			.filter((site) => !jobsInProgress.jobs.find((job) => job.siteName === site.name))
			.map<SiteInfo>((site) => {
				return {
					id: site.id,
					name: site.name,
					imageSrc: site.thumbnailUrl,
					languages: collectLanguagesIso(site.startItem?.versions),
					rootItemId: site.rootItem?.itemId,
					siteCollectionName: site.siteCollection?.displayName,
					siteCollectionId: site.siteCollection?.id,
					siteTypeByCollection: site.siteCollection ? 'sxa' : 'nonsxa',
				};
			});
	}, [data?.solutionSites, jobsInProgress, options.sortSitesBy]);

	const refetchJobsAndSitesList = useCallback(() => {
		void refetch();
		const timerId = setInterval(() => {
			void refetch().then(({ data: refetchData, error }) => {
				const jobs = filterJobs(refetchData.jobs.nodes, sitesInProgress);

				removeSitesInProgress(jobs.filter((j) => j.operation === 'duplicate').map((j) => j.siteName));

				if (!jobs.length || error) {
					clearInterval(timerId);
					void client.refetchQueries({
						include: [getSiteCollectionsList],
					});
				}
			});
		}, refetchTimeout);

		return timerId;
	}, [client, refetch, removeSitesInProgress, sitesInProgress]);

	useEffect(() => {
		let timerId: NodeJS.Timeout;
		const timeoutId = setTimeout(() => {
			timerId = refetchJobsAndSitesList();
		}, refetchTimeout);

		return () => {
			clearTimeout(timeoutId);
			clearInterval(timerId);
		};
	}, [refetchJobsAndSitesList]);

	return {
		jobs: jobsInProgress.createJobs,
		sites: sites,
		refetch: refetchJobsAndSitesList,
		loading: loading,
	};
};

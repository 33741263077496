import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';

export const SiteCollectionName: FC<{ name: string | undefined }> = ({ name }) => {
	if (name) {
		return <>{name}</>;
	}

	return <>{DICTIONARY.NOT_AVAILABLE}</>;
};

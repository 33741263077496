import { useAuth0 } from '@auth0/auth0-react';
import { useState, useEffect } from 'react';
import jwt, { JwtPayload } from 'jwt-decode';

export type JwtWithScopes = JwtPayload & {
	scope: string;
};

export const useHasScope = (...targetedScopes: string[]) => {
	const [hasScopes, setHasScopes] = useState<boolean>(false);
	const { getAccessTokenSilently } = useAuth0();

	useEffect(() => {
		void (async () => {
			const token = await getAccessTokenSilently();

			if (token) {
				const parsedJwt = jwt<JwtWithScopes>(token);

				for (const targetedScope of targetedScopes) {
					if (parsedJwt.scope.includes(targetedScope)) {
						setHasScopes(true);

						return;
					}
				}

				setHasScopes(false);
			}
		})();
	}, [getAccessTokenSilently, setHasScopes, targetedScopes]);

	return hasScopes;
};

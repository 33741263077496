import { useFeatures } from 'features/common/featureFlags/featureFlags';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * @deprecated This hook is deprecated and should not be used. It should be removed together with MainContainer component.
 */
export const useSyncLocationState = () => {
	const { showNewSitesPage } = useFeatures();
	const windowLocation = window.location;
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if ((windowLocation.search !== location.search || windowLocation.pathname !== location.pathname) && showNewSitesPage) {
			navigate({ pathname: window.location.pathname, search: windowLocation.search });
		}
	}, [location, location.search, navigate, showNewSitesPage, windowLocation.pathname, windowLocation.search]);
};

import { useToast } from '@chakra-ui/react';
import { useTranslate } from 'features/common/utils/translateUtils';
import { useBlinkOnDuplicate } from 'features/page-actions-menu/hooks';
import { useValidatePageNameUniqueness } from 'features/page-actions-menu/hooks/use-validate-page-name-uniqueness';
import { DuplicatePageModalInputProps } from 'features/page-actions-menu/models/duplicate-page-modal';
import { UseDuplicatePageProps } from 'features/page-actions-menu/models/use-duplicate-page-props';
import { useCallback } from 'react';
import { COPY_OF_PREFIX, DEFAULT_LANGUAGE, DICTIONARY } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { MoveSitePagePayload, MoveSitePageResponse, MoveSitePageUrlParams } from 'shared/models';
import { usePost, usePostWithUrlInput } from 'shared/react-query-wrappers/hooks';
import { dispatchDuplicatePageErrorEvent, dispatchDuplicatePageStartEvent, dispatchDuplicatePageSuccessEvent } from 'shared/site-structure';
import { sitePageItemValidationSchema } from 'shared/validations';

export const useDuplicatePage: UseDuplicatePageProps = (id, siblingNames, name, siteId, onClose) => {
	const toast = useToast();
	const t = useTranslate();
	const blinkPage = useBlinkOnDuplicate(siteId);
	const validatePageNameUniqueness = useValidatePageNameUniqueness(siblingNames);
	const { mutateAsync } = usePost<{ pageId: string }, any>({ queryKey: { url: ENDPOINTS.DUPLICATE_PAGE, params: { id } } });
	const { mutateAsync: movePage } = usePostWithUrlInput<MoveSitePageResponse, MoveSitePagePayload, MoveSitePageUrlParams>({
		queryKey: { url: ENDPOINTS.MOVE_PAGE },
	});

	const handleRename = useCallback(
		async (values: { name: string; displayName: string }, closeCallback: () => void) => {
			closeCallback();

			try {
				dispatchDuplicatePageStartEvent(id, values.name);
				const result = await mutateAsync({ language: DEFAULT_LANGUAGE, newName: values.name, site: siteId, displayName: values.displayName });
				const moveResult = await movePage({
					input: { site: siteId, itemToMoveId: result.data.pageId, targetId: id, position: 'After' },
					additionalVariables: { pageId: result.data.pageId },
				});

				if (moveResult) {
					dispatchDuplicatePageSuccessEvent(id);
					await blinkPage(result.data.pageId);
				}
			} catch (error) {
				dispatchDuplicatePageErrorEvent(id);
				toast({ description: t(DICTIONARY.PAGE_DUPLICATION_FAILED), status: 'error' });
			}
		},
		[blinkPage, id, movePage, mutateAsync, siteId, t, toast]
	);

	const submitHandler = (values: DuplicatePageModalInputProps) => {
		if (values.name.toLowerCase() === name.toLowerCase()) {
			return onClose();
		}

		void handleRename(values, onClose);
	};

	const initialName = `${COPY_OF_PREFIX} ${name}` || '';

	return { sitePageItemValidationSchema, submitHandler, validatePageNameUniqueness, initialName };
};

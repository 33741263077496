import { Box, Tbody, Td, Tooltip, Tr } from '@chakra-ui/react';
import { RemovePoSButton } from 'features/point-of-sale/ui';
import { FC } from 'react';
import { PosTableBodyProps } from 'widgets/pos-table/models';

export const PosTableBody: FC<PosTableBodyProps> = ({ data }) => (
	<Tbody>
		{data.map((entity, index) => {
			const hostsString = entity.assignedHosts.map((host) => host.name).join(', ');

			return (
				<Tr key={`${entity.name}-${index}`}>
					<Td>{entity.name}</Td>
					<Td>{entity.timeout}</Td>
					<Td maxW="0">
						<Tooltip label={hostsString}>
							<Box isTruncated maxWidth="max-content">
								{hostsString}
							</Box>
						</Tooltip>
					</Td>
					<Td width="8">
						<RemovePoSButton identifier={entity.name} hostsAssigned={entity.assignedHosts} />
					</Td>
				</Tr>
			);
		})}
	</Tbody>
);

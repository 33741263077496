import { CreatePOSFormModel } from 'features/point-of-sale/models/create-assign-pos';
import { FieldInputProps, FormikHelpers } from 'formik';
import { useCallback, useMemo } from 'react';
import { useCreatePoSAction, useGetAllPoS } from 'shared/api';
import { DICTIONARY, POS_DEFAULT_MARKET, POS_NAME_ALLOWED_CHARACTERS_REGEX } from 'shared/constants';
import { createPosName } from 'shared/point-of-sale';

export const onNameChange = (e: React.ChangeEvent<HTMLInputElement>, field: FieldInputProps<any>) => {
	e.target.value = e.target.value.toLocaleLowerCase().replace(POS_NAME_ALLOWED_CHARACTERS_REGEX, '');
	field.onChange(e);
};

export const useCreatePosForm = (siteName: string, assign: (name: string) => Promise<void>, onClose: () => void) => {
	const { posList } = useGetAllPoS();
	const { createPosIdentifiers, isPending } = useCreatePoSAction();
	const initialPosName = useMemo(() => createPosName(siteName, posList), [posList, siteName]);

	const submit = useCallback(
		async (values: CreatePOSFormModel, formikHelpers: FormikHelpers<CreatePOSFormModel>) => {
			if (posList.some((pos) => pos.name === values.name)) {
				formikHelpers.setFieldError('name', DICTIONARY.POS_EXISTS_ERROR);

				return;
			}

			await createPosIdentifiers([{ name: values.name, timeout: values.timeout, market: POS_DEFAULT_MARKET }]);
			await assign(values.name);
			onClose();
		},
		[assign, createPosIdentifiers, onClose, posList]
	);

	return {
		initialPosName,
		submit,
		onNameChange,
		isReady: !!posList,
		isPending,
	};
};

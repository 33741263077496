import { Dispatch } from 'react';
import { PageViewModel } from 'shared/models';
import { DirectionProps } from 'shared/site-structure';

export type HomePage = {
	id: string;
	path: string;
	hostName: string;
};

export type CommonProps = {
	lvl: number;
	homePageIdsAndPath: HomePage[];
};

export type Site = {
	siteId: string;
	siteName: string;
};

export type CreatingState = {
	id?: string;
	name: string;
} & DirectionProps;

export type PageAnimationState = {
	isDeleting: boolean;
	creating?: CreatingState;
	isRenaming: boolean;
	isDuplicate?: boolean;
};

export type UpdateParentOpenState = {
	indexInBranch: number;
};

export type PageProps = CommonProps &
	PageViewModel &
	Site &
	PageAnimationState &
	UpdateParentOpenState & {
		isOpen: boolean;
		setIsOpen: (isOpen: boolean) => void;
		parentId: string;
		siblingNames: string[];
		parentCanCreate: boolean;
		parentPageName: string;
		childrenNames: string[];
		isDragOver: boolean;
		setIsDragOver: Dispatch<React.SetStateAction<boolean>>;
	};

export type PageBranchProps = CommonProps &
	PageViewModel &
	Site &
	PageAnimationState &
	UpdateParentOpenState & {
		siblingNames: string[];
		opened?: boolean;
		parentCanCreate: boolean;
	};

export type SiteStructureProps = Omit<CommonProps, 'lvl'> & Site & { isLoading?: boolean };

export type ActionMenuProps = CommonProps & PageViewModel;

export const pageAnimationStateDefault: PageAnimationState = {
	isDeleting: false,
	creating: undefined,
	isRenaming: false,
	isDuplicate: false,
};

import { CollectionResponse } from 'features/collections/models';
import { useMemo } from 'react';
import { ENDPOINTS } from 'shared/endpoints';
import { SiteResponse } from 'shared/models';
import { useGet } from 'shared/react-query-wrappers/hooks';

export type QueryData = { data: { id: string }[] };

export const useSitesCounter = () => {
	const { data: collections, isLoading: isCollectionsLoading } = useGet<CollectionResponse[]>({ queryKey: { url: ENDPOINTS.COLLECTIONS } });
	const { data: sites, isLoading: isSitesLoading } = useGet<SiteResponse[]>({ queryKey: { url: ENDPOINTS.SITES } });

	const isLoading = useMemo(() => isCollectionsLoading || isSitesLoading, [isCollectionsLoading, isSitesLoading]);
	const collectionsCount = useMemo(() => collections?.length ?? 0, [collections?.length]);
	const sitesCount = useMemo(() => sites?.length ?? 0, [sites?.length]);

	return { collectionsCount, sitesCount, isLoading };
};

import { SiteWithPoS, SiteWithPoSResponse } from 'features/point-of-sale/models';
import { useMemo } from 'react';
import { ENDPOINTS } from 'shared/endpoints';
import { useSimpleGet } from 'shared/react-query-wrappers/hooks';

export const useGetSitesWithPoS = () => {
	const { data, isLoading } = useSimpleGet<SiteWithPoSResponse[]>(ENDPOINTS.SITES);

	const sites = useMemo<SiteWithPoS[]>(() => {
		if (!data) {
			return [];
		}

		const sitesWithPoSs = data?.map<SiteWithPoS>((site) => ({
			id: site.id,
			name: site.displayName ?? site.name,
			posIdentifiers: Object.values(site.pointsOfSale ?? {}),
		}));

		return sitesWithPoSs ?? [];
	}, [data]);

	return {
		sites,
		isLoading,
	};
};

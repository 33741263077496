import { Button } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { RemoveCollectionProps } from 'features/remove-collection/models';
import { RemoveCollectionModal } from 'features/remove-collection/ui';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';

export const RemoveCollectionButton: FC<RemoveCollectionProps> = (props) => {
	return (
		<RemoveCollectionModal {...props}>
			<Button width="fit-content" colorScheme="danger" data-testid="remove-collection-button">
				<T _str={DICTIONARY.DELETE_COLLECTION} />
			</Button>
		</RemoveCollectionModal>
	);
};

import { Flex, Heading, Stack, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { PageBodyContent } from 'entities/page-layout/ui';
import { CreateSiteDefinitionButton } from 'features/site-definitions/ui';
import { SitePageOutlet } from 'pages/site/models';
import { FC, useState } from 'react';
import { useOutletContext } from 'react-router';
import { DICTIONARY } from 'shared/constants';
import { SiteDefinitionsTable } from 'widgets/site-definitions-table/ui';

export const SiteDefinitionsPage: FC = () => {
	const { site, languages } = useOutletContext<SitePageOutlet>();
	const [hostsToCreateCount, setHostsToCreateCount] = useState(0);

	return (
		<PageBodyContent>
			<Flex justifyContent="space-between" alignItems="flex-start">
				<Stack gap="1">
					<Heading size="lg">
						<T _str={DICTIONARY.SITE_DEFINITIONS} />
					</Heading>
					<Text variant="subtle">
						<T _str={DICTIONARY.SITE_DEFINITIONS_DESCRIPTION} />
					</Text>
				</Stack>
				<CreateSiteDefinitionButton
					siteId={site?.id || ''}
					collectionId={site?.collectionId || ''}
					siteName={site?.displayName || site?.name || ''}
					languages={languages || []}
					canCreate={site?.access?.canCreate || false}
					onStart={() => setHostsToCreateCount((s) => ++s)}
					onEnd={() => setHostsToCreateCount((s) => --s)}
				/>
			</Flex>
			<Flex>
				<SiteDefinitionsTable hostsToCreateCount={hostsToCreateCount} />
			</Flex>
		</PageBodyContent>
	);
};

import { Formik } from 'formik';
import { SiteDefinitionPageOutlet } from 'pages/site-definition/models/site-definition-props';
import { FC, useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import { SiteDefinitionResponseViewModel } from 'shared/models';
import { siteDefinitionValidationSchema } from 'shared/validations';
import { SiteDefinitionViewModel } from 'widgets/site-definition-update-form/models';
import { SettingsSectionBody } from 'widgets/site-definition-update-form/ui/settings-section-body';

type SettingsSectionFormProps = SiteDefinitionResponseViewModel & { siteId: string };

export const SettingsSectionForm: FC<SettingsSectionFormProps> = ({
	hostname,
	targetHostname,
	homePageId,
	id,
	siteId,
	name,
	linkableSite,
	renderingHost,
	// linkProviderName,
}) => {
	const { allHostnames, allDefinitionNames, allTargetHostnames, allRenderingHosts } = useOutletContext<SiteDefinitionPageOutlet>();
	const initialValues = useMemo(() => {
		return {
			homePageId,
			linkableSite: linkableSite ?? false,
			hostname,
			targetHostname,
			renderingHost: renderingHost.name,
			// linkProviderName: linkProviderName ?? '',
		};
	}, [homePageId, hostname, linkableSite, targetHostname, renderingHost]);

	return (
		<Formik<
			Pick<SiteDefinitionViewModel, 'homePageId' | 'hostname' | 'targetHostname' | 'linkableSite' | 'renderingHost' | 'validForEnvironment'>
		>
			initialValues={initialValues}
			onSubmit={() => {}}
			validationSchema={siteDefinitionValidationSchema(allDefinitionNames, allHostnames)}
			enableReinitialize
		>
			{({ resetForm, submitForm, values }) => (
				<SettingsSectionBody
					resetForm={resetForm}
					submitForm={submitForm}
					name={name}
					id={id}
					homePageId={values.homePageId}
					existingTargetHostnames={allTargetHostnames}
					allHostnames={allHostnames}
					siteId={siteId}
					allRenderingHosts={allRenderingHosts}
				/>
			)}
		</Formik>
	);
};

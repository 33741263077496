import { Button, ButtonGroup, Flex, FormControl, FormLabel, Stack, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
// eslint-disable-next-line import/named
import { Select as ReactSelect, createFilter } from 'chakra-react-select';
import { VirtualizedMenuList } from 'entities/virtualized-menu-list/ui';
import { useAssignPosFormEA } from 'features/point-of-sale/hooks/use-assign-pos-form-ea';
import { AssignPosProps } from 'features/point-of-sale/models';
import { PosSitesListEA } from 'features/point-of-sale/ui/pos-sites-list-ea';
import { FC } from 'react';
import { DICTIONARY, EMPTY_VALUE } from 'shared/constants';

/** @deprecated */
export const AssignPOSFormEA: FC<AssignPosProps> = (props) => {
	const { onClose, assign, isPending } = props;
	const { sitesList, option, posOptions, setOption } = useAssignPosFormEA();

	return (
		<Flex direction="column" flexGrow="1">
			<Stack flexGrow="1" gap="6">
				<ReactSelect
					value={option}
					options={posOptions}
					onChange={(e) => setOption(e)}
					filterOption={createFilter({ ignoreAccents: false })}
					components={{ MenuList: VirtualizedMenuList }}
				/>
				{option && (
					<>
						<Text variant="strong">{DICTIONARY.DETAILS}</Text>
						<Stack gap="4">
							<FormControl>
								<FormLabel variant="small">
									<T _str={DICTIONARY.NAME} />
								</FormLabel>
								<Text>{option.label}</Text>
							</FormControl>
							<FormControl>
								<FormLabel variant="small">
									<T _str={DICTIONARY.ASSIGNED_TO_SITE_DEFINITION} />
								</FormLabel>
								<Text>{sitesList ? <PosSitesListEA sitesList={sitesList} /> : EMPTY_VALUE}</Text>
							</FormControl>
							<FormControl>
								<FormLabel variant="small">
									<T _str={DICTIONARY.TIMEOUT_IN_MINUTES} />
								</FormLabel>
								<Text>{option.timeout}</Text>
							</FormControl>
						</Stack>
					</>
				)}
			</Stack>
			<ButtonGroup justifyContent="end" paddingY="3">
				<Button variant="ghost" onClick={onClose}>
					<T _str={DICTIONARY.CANCEL} />
				</Button>
				<Button
					type="submit"
					colorScheme="primary"
					isDisabled={!option || isPending}
					isLoading={isPending}
					loadingText={<T _str={DICTIONARY.ASSIGNING} />}
					onClick={() => void assign(option?.label || '')}
				>
					<T _str={DICTIONARY.ASSIGN} />
				</Button>
			</ButtonGroup>
		</Flex>
	);
};

import { Box } from '@chakra-ui/react';
import { FC } from 'react';
import { usePageDrop } from 'widgets/site-structure/hooks/use-page-drop';
import '../styles/page.scss';

export type PageDropProps = {
	opened?: boolean;
	lvl: number;
	index: number;
	position: 'before' | 'after';
	siblingNames: string[];
	siteId: string;
	targetId: string;
	parentId: string;
	siblingPath: string;
};

export const PageDrop: FC<PageDropProps> = (props) => {
	const { index, position } = props;
	const { onDragEnter, onDragLeave, onDragOver, onDrop, styles } = usePageDrop(props);

	if (index && position === 'before') {
		return <></>;
	}

	return <Box onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDrop={(e) => void onDrop(e)} onDragOver={onDragOver} {...styles} />;
};

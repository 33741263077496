import { Button, Heading, Stack } from '@chakra-ui/react';
import { mdiArrowLeft } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { PageBodyContent } from 'entities/page-layout/ui';
import { useSiteDefinitionPage } from 'pages/site-definition/hooks/use-site-definition-page';
import { SiteDefinitionTabs } from 'pages/site-definition/ui/site-definition-tabs';
import { FC, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { DICTIONARY } from 'shared/constants';

export const SiteDefinitionPage: FC = () => {
	const { goBack, outletContext } = useSiteDefinitionPage();
	const hasMissingPoSs = useMemo(() => !!outletContext.hostWithMissingPoSs, [outletContext.hostWithMissingPoSs]);

	return (
		<PageBodyContent scrollToTop>
			<Stack gap="6">
				<Stack gap="3" width="fit-content">
					<Button
						variant="link"
						size="xs"
						colorScheme="neutral"
						width="fit-content"
						leftIcon={<Icon path={mdiArrowLeft} />}
						onClick={goBack}
					>
						<T _str={DICTIONARY.BACK_TO_ALL_SITE_DEFINITIONS} />
					</Button>
					<Heading size="lg">{outletContext.siteDefinition?.name}</Heading>
				</Stack>
				<SiteDefinitionTabs hasMissingPoSs={hasMissingPoSs} />
				<Outlet context={outletContext} />
			</Stack>
		</PageBodyContent>
	);
};

import * as Yup from 'yup';

export const posPayloadValidationSchema = Yup.object({
	name: Yup.string()
		.required('This field can not be empty.')
		.trim('The site name cannot include leading or trailing spaces.')
		.strict()
		.matches(
			// eslint-disable-next-line no-useless-escape
			/^[\w?\/\-._~:?#@!$&'()*+,;=\[\]]*$/,
			'Invalid characters. Characters allowed: A-Z, a-z, 0-9 and "/-._~:?#@!$&\'()*+,;=[]". Spaces are not allowed.'
		)
		.max(50, 'Site name cannot be longer than 50 characters.'),
	brand: Yup.string()
		.required('This field can not be empty.')
		.strict()
		.matches(
			/^[a-zA-Z0-9_.\-& áéíóú]*$/,
			'Invalid characters. Characters allowed: A-Z, a-z, 0-9, -, , &, _, á, é, í, ó, ú. Spaces are also allowed.'
		)
		.max(50, 'Site identifier cannot be longer than 50 characters.'),
	market: Yup.string()
		.required('This field can not be empty.')
		.strict()
		.matches(
			/^[a-zA-Z0-9_.\-& áéíóú]*$/,
			'Invalid characters. Characters allowed: A-Z, a-z, 0-9, -, , &, _, á, é, í, ó, ú. Spaces are also allowed.'
		)
		.max(50, 'Site identifier cannot be longer than 50 characters.'),
	timeout: Yup.number().required('This field can not be empty.').integer('Number should be integer.').min(1, 'Timeout can`t be negative or 0.'),
});

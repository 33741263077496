import { useCallback, useMemo } from 'react';
import { LanguagePayload, LanguageSelectItem } from './types';
import { useGetLanguages } from './useGetLanguages';

const mapLanguages = (languages: LanguagePayload[]): LanguageSelectItem[] => {
	return languages.map((lang) => ({
		value: lang,
		label: lang.englishName,
	}));
};

export type GetLanguagesListData = {
	languages: LanguageSelectItem[];
	loading: boolean;
	findSelectedLanguage: (selectedValue: LanguagePayload, items: LanguageSelectItem[]) => LanguageSelectItem | undefined;
};
export type GetLanguagesListCallback = (languages: LanguageSelectItem[]) => void;

export const useGetlanguagesSelect = (onLanguagesLoaded?: GetLanguagesListCallback): GetLanguagesListData => {
	const { languages, loading } = useGetLanguages((langs) => onLanguagesLoaded?.(mapLanguages(langs)));
	const langSelectItems = useMemo<LanguageSelectItem[]>(() => {
		if (!languages || loading) {
			return [];
		}

		return mapLanguages(languages);
	}, [languages, loading]);

	const findSelectedLanguage = useCallback((selectedValue: LanguagePayload, items: LanguageSelectItem[]): LanguageSelectItem | undefined => {
		if (!items.length) {
			return undefined;
		}

		return items.find((i) => i.value.name.toLowerCase() === selectedValue.name.toLocaleLowerCase());
	}, []);

	return {
		languages: langSelectItems,
		loading: loading,
		findSelectedLanguage: findSelectedLanguage,
	};
};

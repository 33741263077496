import { useAuth0 } from '@auth0/auth0-react';
import { FC, useEffect } from 'react';
import { Loading } from '../components/Loading';

export const LoginErrorHandler: FC<React.PropsWithChildren> = ({ children }) => {
	const { isAuthenticated, error, loginWithRedirect } = useAuth0();

	useEffect(() => {
		if (error && !isAuthenticated) {
			void loginWithRedirect({
				authorizationParams: {
					tenant_name: undefined,
					organization_id: undefined,
				},
			});
		}
	}, [error, isAuthenticated, loginWithRedirect]);

	if (error && !isAuthenticated) {
		return <Loading />;
	}

	return <>{children}</>;
};

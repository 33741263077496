import { FormControl, FormErrorIcon, FormErrorMessage, FormHelperText, FormLabel, Input, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { RenameForm } from 'features/sites/components/rename/RenameSiteModal';
import { useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';
import { DICTIONARY } from 'shared/constants';

export const SiteNameField = ({
	showHelper,
	label = DICTIONARY.SITE_NAME,
	autoSelect = false,
}: {
	showHelper?: boolean;
	inputWidth?: string;
	label?: string;
	autoSelect?: boolean;
}) => {
	const { isSubmitting, values, errors, touched, handleChange } = useFormikContext<RenameForm>();

	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (!autoSelect || !inputRef.current) {
			return;
		}

		inputRef.current.select();
	}, [autoSelect]);

	return (
		<>
			<FormControl isDisabled={isSubmitting} isInvalid={!!(errors.newName && touched.newName)} minW="xs" maxW="2xl">
				<FormLabel htmlFor="newName">
					<T _str={label} />
				</FormLabel>
				<Input type="text" name="newName" id="newName" value={values.newName} onChange={handleChange} maxLength={50} ref={inputRef} />
				{showHelper && (
					<FormHelperText>
						<Text>
							<T _str={DICTIONARY.NAME_VALID_CHARACTERS_MESSAGE} />
						</Text>
					</FormHelperText>
				)}
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{errors.newName}</Text>
				</FormErrorMessage>
			</FormControl>
		</>
	);
};

import { mdiCommentAlertOutline } from '@mdi/js';
import { MediaCard } from 'entities/media-card/ui';
import { TokenCustomClaimKeysEnum } from 'features/common/auth/SitecoreUser';
import { useAuthWithClaims } from 'features/common/auth/useAuthWithClaims';
import { useUserInOrganization } from 'features/common/auth/useUserInOrganization';
import { environment } from 'features/common/config/envConfig';
import { useTenantVersionFeaturesProvider } from 'features/common/tenantVersionPrivider/components/TenantVersionProvider';
import { useTranslate } from 'features/common/utils/translateUtils';
import { ClientContext, CreateSupportInterface } from 'features/create-support-case/models';
import { FC, useEffect } from 'react';
import { CREATE_SUPPORT_CASE_BUTTON_SELECTOR, CREATE_SUPPORT_CASE_COMPONENT_NAME, DICTIONARY } from 'shared/constants';
import { environmentUpdater } from 'shared/utils/environment-updater';

export const CreateSupportCase: FC<{ onCreateTicketClick: () => void }> = ({ onCreateTicketClick }) => {
	const { loginWithRedirect, getAccessTokenSilently, user } = useAuthWithClaims();
	const userInOrganization = useUserInOrganization();
	const { version } = useTenantVersionFeaturesProvider();
	const t = useTranslate();

	const organizationId = user?.[TokenCustomClaimKeysEnum.ORG_ID] || '';

	useEffect(() => {
		const initialize = async () => {
			const createSupportCase = document.querySelector<Element & CreateSupportInterface>(CREATE_SUPPORT_CASE_COMPONENT_NAME);

			if (!userInOrganization || createSupportCase === null) {
				return;
			}

			createSupportCase.componentName = CREATE_SUPPORT_CASE_COMPONENT_NAME;
			createSupportCase.buttonSelector = CREATE_SUPPORT_CASE_BUTTON_SELECTOR;

			createSupportCase.clientContext = {
				getAccessToken: getAccessTokenSilently,
				organizationId,
				tenantId: user?.[TokenCustomClaimKeysEnum.TENANT_ID],
				email: user?.email ?? '',
				userAgent: { 'X-Sitecore-User-Agent': version ?? '' },
				environment: environmentUpdater(environment),
			} as ClientContext;
		};

		void initialize();
	}, [getAccessTokenSilently, loginWithRedirect, userInOrganization, user, organizationId, version]);

	return (
		<MediaCard
			data-support-case={CREATE_SUPPORT_CASE_COMPONENT_NAME}
			title={t(DICTIONARY.CREATE_SUPPORT_TICKET)}
			icon={mdiCommentAlertOutline}
			showOpenInNewIcon={false}
			onClick={onCreateTicketClick}
		/>
	);
};

import { Flex } from '@chakra-ui/react';
import { EmptyPortfolioArea } from 'entities/empty-portfolio-area/ui';
import { CreateSiteButton } from 'features/create-site/ui';
import { useCheckPermissions } from 'features/site-permissions/hooks';
import { FC } from 'react';
import { PortfolioBodyProps } from 'widgets/portfolio/models';

export const PortfolioNoSites: FC<Pick<PortfolioBodyProps, 'collectionId'>> = ({ collectionId }) => {
	const { canCreate } = useCheckPermissions();

	return (
		<Flex direction="column" alignItems="center" data-testid="portfolio-no-site-list" py="8">
			<EmptyPortfolioArea>
				<CreateSiteButton collectionId={collectionId} canCreate={canCreate} />
			</EmptyPortfolioArea>
		</Flex>
	);
};

import { useToast } from '@chakra-ui/react';
import { useTranslate } from 'features/common/utils/translateUtils';
import { FormikProps } from 'formik';
import { useCallback } from 'react';
import { DICTIONARY } from 'shared/constants';
import { GeneralFormRenameInputs } from 'widgets/collection-settings/models';

export const useUpdateCollection = (formiks: FormikProps<unknown>[]) => {
	const toast = useToast();
	const t = useTranslate();

	const formikRename = formiks[0] as FormikProps<GeneralFormRenameInputs>;

	const handleSubmit = useCallback(async () => {
		try {
			const renameResult = (await formikRename.submitForm()) as unknown as GeneralFormRenameInputs;

			if (renameResult) {
				toast({
					status: 'success',
					description: t(DICTIONARY.COLLECTION_UPDATED),
				});
			}
		} catch (error) {
			toast({
				status: 'error',
				description: t(DICTIONARY.COLLECTION_UPDATED_FAILED),
			});
		}
	}, [formikRename, t, toast]);

	const handleDiscard = useCallback(() => {
		formiks.map((formik) => formik.resetForm());
	}, [formiks]);

	return { handleSubmit, handleDiscard };
};

import { useCallback } from 'react';
import { ENDPOINTS } from 'shared/endpoints';
import { usePatch } from 'shared/react-query-wrappers/hooks/use-patch';
import { ThumbnailHookModel } from '../models';

export const useRemoveThumbnail = ({ siteId, hostId }: ThumbnailHookModel) => {
	const { mutateAsync } = usePatch({
		queryKey: {
			url: ENDPOINTS.SITE_HOST,
			params: { siteId: siteId ?? '', hostId: hostId ?? '' },
		},
	});

	return useCallback(async () => {
		await mutateAsync({ thumbnail: '' });
	}, [mutateAsync]);
};

import { useToast } from '@chakra-ui/react';
import { useTranslate } from 'features/common/utils/translateUtils';
import { useRemoveThumbnail, useUploadThumbnail } from 'features/thumbnail/hooks';
import { ThumbnailType } from 'features/thumbnail/models';
import { useCallback, useMemo } from 'react';
import { DICTIONARY } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { Host } from 'shared/models';
import { usePatch } from 'shared/react-query-wrappers/hooks';
import { base64ToFile } from 'shared/utils';
import { useCalculateDefaultLanguage } from 'widgets/site-definition-update-form/hooks/use-calculate-default-language';
import {
	EditSiteDefinitionInput,
	SiteDefinitionViewModel,
	mapSiteSettingsViewModelToEditSiteInput,
	onIsSubmittingChange,
	onSiteSaved,
} from 'widgets/site-definition-update-form/models';
import { SiteSettingsFormProps } from 'widgets/site-definition-update-form/ui';

export const useGeneralSectionForm = (
	{
		id,
		name,
		defaultLanguage,
		languageEmbedding,
		fieldLanguageFallback,
		itemLanguageFallback,
		homePageId,
		xmAppsLanguages,
		hostname,
		targetHostname,
		renderingHost,
		linkableSite,
		thumbnailAutogenerated,
	}: // linkProviderName,
	SiteSettingsFormProps,
	siteId: string
) => {
	const toast = useToast();
	const t = useTranslate();
	const xmAppsDefaultLanguage = useCalculateDefaultLanguage(xmAppsLanguages, defaultLanguage);
	const initialValues: SiteDefinitionViewModel = useMemo(() => {
		return {
			name,
			homePageId: homePageId.replaceAll('-', ''),
			language: xmAppsDefaultLanguage?.value,
			languageEmbedding: languageEmbedding ?? false,
			itemLanguageFallback: itemLanguageFallback ?? false,
			fieldLanguageFallback: fieldLanguageFallback ?? false,
			hostname,
			targetHostname,
			renderingHost: renderingHost.name,
			linkableSite: linkableSite ?? false,
			// linkProviderName: linkProviderName ?? '',
			mode: thumbnailAutogenerated ? ThumbnailType.AUTO : ThumbnailType.MANUAL,
			thumbnailBase64Url: '',
			toBeDeleted: 'false',
		};
	}, [
		name,
		homePageId,
		xmAppsDefaultLanguage?.value,
		languageEmbedding,
		itemLanguageFallback,
		fieldLanguageFallback,
		hostname,
		targetHostname,
		renderingHost,
		linkableSite,
		// linkProviderName,
		thumbnailAutogenerated,
	]);

	const { mutateAsync } = usePatch<Host, EditSiteDefinitionInput>({
		queryKey: { url: ENDPOINTS.HOST, params: { siteId, hostId: id } },
		refetchQueriesByKey: [{ url: ENDPOINTS.SITE, params: { id: siteId }, shouldWait: true }, { url: ENDPOINTS.LANGUAGES }],
	});

	const handleUploadThumbnail = useUploadThumbnail({ siteId, hostId: id });
	const handleRemoveThumbnail = useRemoveThumbnail({ siteId, hostId: id });

	const submit = useCallback(
		async (values: SiteDefinitionViewModel) => {
			const input: EditSiteDefinitionInput = { ...mapSiteSettingsViewModelToEditSiteInput(values) };

			try {
				onIsSubmittingChange(true);

				const promises = [];

				if (values.thumbnailBase64Url) {
					const formData = new FormData();

					const file = base64ToFile(values.thumbnailBase64Url);

					formData.append('file', file);
					promises.push(handleUploadThumbnail(formData));
				}

				if (values.toBeDeleted === 'true') {
					promises.push(handleRemoveThumbnail());
				}

				await Promise.all(promises);

				await mutateAsync(input);

				onSiteSaved(true);
				onIsSubmittingChange(false);
				toast({ description: t(DICTIONARY.SITE_UPDATED_MESSAGE), status: 'success' });
			} catch (error) {
				onSiteSaved(false);
				onIsSubmittingChange(false);
			}
		},
		[handleRemoveThumbnail, handleUploadThumbnail, mutateAsync, t, toast]
	);

	return {
		initialValues,
		submit,
	};
};

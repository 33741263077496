import { useDisclosure } from '@chakra-ui/react';
import { createContext, FC, useContext, useMemo, useState } from 'react';
import { RenameSiteModal } from './RenameSiteModal';

export type RenameModalContextType = {
	renameSite: (siteName: string) => void;
};

const RenameSiteContext = createContext<RenameModalContextType>({
	renameSite: () => {},
});

export const RenameSiteProvider: FC<React.PropsWithChildren> = ({ children }) => {
	const { isOpen, onClose, onOpen } = useDisclosure();
	const [siteName, setSiteName] = useState('');

	const contextValue = useMemo(
		() => ({
			renameSite: (siteToRename: string) => {
				setSiteName(siteToRename);
				onOpen();
			},
		}),
		[onOpen]
	);

	return (
		<>
			<RenameSiteModal siteName={siteName} isOpen={isOpen} onClose={onClose}></RenameSiteModal>
			<RenameSiteContext.Provider value={contextValue}>{children}</RenameSiteContext.Provider>
		</>
	);
};

export const useRenameSite = () => {
	return useContext(RenameSiteContext);
};

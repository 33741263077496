import { Box, Flex, Heading, Spacer, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { PosList } from './PosList';
import { SynchronizationBox } from './SynchronizationBox';
import { CreateButton } from './createPOS/CreateButton';

export const PosLayout: FC = () => {
	return (
		<Flex direction="column" width="full" maxHeight="fit-content" gap="6" overflow="auto">
			<Flex direction="row">
				<Flex direction="column" gap="2" pr="8">
					<Heading size="lg">
						<T _str={DICTIONARY.ANALYTICS_IDENTIFIER} />
					</Heading>
					<Text noOfLines={5} overflowWrap="break-word">
						<T _str={DICTIONARY.POC_LAYOUT_DESCRIPTION} />
					</Text>
				</Flex>
				<Spacer />
				<CreateButton />
			</Flex>
			<SynchronizationBox />
			<Box overflow="auto">
				<PosList />
			</Box>
		</Flex>
	);
};

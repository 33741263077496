import {
	Alert,
	AlertDescription,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertIcon,
	Button,
	ButtonGroup,
	Text,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { useRemovePoSAction } from 'features/point-of-sale/hooks';
import { RemovePoSProps } from 'features/point-of-sale/models';
import { FC, useCallback } from 'react';
import { DICTIONARY } from 'shared/constants';

type RemovePoSModalBodyProps = RemovePoSProps & {
	onClose: () => void;
};

export const RemovePoSAlertBody: FC<RemovePoSModalBodyProps> = ({ identifier, hostsAssigned, onClose }) => {
	const { removePosIdentifier, isPending } = useRemovePoSAction({ identifier, hostsAssigned });

	const handleClick = useCallback(async () => {
		await removePosIdentifier();
		onClose();
	}, [removePosIdentifier, onClose]);

	return (
		<>
			<AlertDialogHeader>
				<T _str={DICTIONARY.DELETE_ANALYTICS_IDENTIFIER} />
			</AlertDialogHeader>
			<AlertDialogBody display="flex" flexDirection="column" gap="4">
				<Alert status="info">
					<AlertIcon />
					<AlertDescription>
						<T _str={DICTIONARY.DELETE_ANALYTICS_IDENTIFIER_ALERT} />
					</AlertDescription>
				</Alert>
				<Text>
					<T
						_str={DICTIONARY.DELETE_ANALYTICS_IDENTIFIER_CONFIRMATION}
						identifier={
							<Text as="span" variant="strong">
								{identifier}
							</Text>
						}
						numberOfHosts={hostsAssigned.length}
					/>
				</Text>
			</AlertDialogBody>
			<AlertDialogFooter>
				<ButtonGroup>
					<Button data-testid="close-modal" variant="ghost" onClick={onClose}>
						<T _str={DICTIONARY.CANCEL} />
					</Button>
					<Button
						data-testid="remove-pos-identifier-button"
						colorScheme="danger"
						isDisabled={isPending}
						isLoading={isPending}
						loadingText={<T _str={DICTIONARY.DELETING} />}
						onClick={() => void handleClick()}
					>
						<T _str={DICTIONARY.DELETE} />
					</Button>
				</ButtonGroup>
			</AlertDialogFooter>
		</>
	);
};

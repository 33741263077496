import { Box, Flex } from '@chakra-ui/react';
import { MenuIconButton } from 'entities/menu-icon-button/ui';
import { SiteCardProps } from 'entities/site-card/models';
import { SiteCard } from 'entities/site-card/ui';
import { useClickableSiteCard } from 'features/clickable-site-card/hooks';
import { useFeatures } from 'features/common/featureFlags/featureFlags';
import { FC, PropsWithChildren } from 'react';
import { OpenInPagesWrapper } from 'shared/open-in-pages/ui';
import { SiteViewModel } from 'widgets/portfolio/models';

export type ClickableSiteCardProps = SiteCardProps & { id: string; badge?: JSX.Element } & Partial<Pick<SiteViewModel, 'hosts'>>;

export const ClickableSiteCard: FC<PropsWithChildren<ClickableSiteCardProps>> = ({
	hosts,
	id,
	name,
	description,
	thumbnail,
	displayName,
	badge,
	children,
}) => {
	const { isDisabled } = useClickableSiteCard(id);
	const { showSitesGrouping } = useFeatures();

	return (
		<OpenInPagesWrapper
			hosts={hosts}
			cursor="pointer"
			opacity={isDisabled ? 0.4 : 1}
			pointerEvents={isDisabled ? 'none' : undefined}
			transition="0.2s opacity"
			position="relative"
		>
			{badge && (
				<Box position="absolute" zIndex="1" right="3" top="3">
					{badge}
				</Box>
			)}
			<SiteCard
				name={name}
				displayName={displayName}
				description={description}
				thumbnail={thumbnail}
				isStacked={showSitesGrouping && hosts && hosts.length > 1}
			>
				<Flex onClick={(e) => e.stopPropagation()}>
					<MenuIconButton>{children}</MenuIconButton>
				</Flex>
			</SiteCard>
		</OpenInPagesWrapper>
	);
};

import { Box, Button, Tooltip } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { RemoveSiteDefinitionModal } from 'features/remove-site-definition/ui/remove-site-definition-modal';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { Host } from 'shared/models';

export type RemoveSiteDefinitionProps = {
	hosts?: Host[] | undefined;
	hostId: string;
	hostName: string;
	siteId: string;
	siteName: string;
	collectionId: string;
};

export const RemoveSiteDefinitionButton: FC<RemoveSiteDefinitionProps> = (props) => {
	const { hosts } = props;
	const isDisabled = !!hosts && hosts?.length === 1;

	return (
		<RemoveSiteDefinitionModal {...props}>
			<Box width="fit-content">
				<Tooltip label={<T _str={DICTIONARY.DELETE_SITE_DEFINITION_TOOLTIP} />} isDisabled={!isDisabled}>
					<Button width="fit-content" colorScheme="danger" isDisabled={isDisabled} data-testid="remove-site-definition-button">
						<T _str={DICTIONARY.DELETE_SITE_DEFINITION} />
					</Button>
				</Tooltip>
			</Box>
		</RemoveSiteDefinitionModal>
	);
};

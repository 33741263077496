import { useToast } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { CreateLanguageInput, LanguageValue, UseCreateLanguageProps } from 'features/languages/models';
import { useCallback } from 'react';
import { DICTIONARY } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { LanguageResponse } from 'shared/languages/models';
import { usePost } from 'shared/react-query-wrappers/hooks';

export const useCreateLanguage = ({ onClose }: UseCreateLanguageProps) => {
	const { mutateAsync } = usePost<LanguageResponse, CreateLanguageInput>({
		queryKey: { url: ENDPOINTS.LANGUAGES },
		refetchQueriesByKey: [{ url: ENDPOINTS.LANGUAGES, shouldWait: true }],
	});
	const toast = useToast();

	return useCallback(
		async ({ language, fallbackLanguage }: LanguageValue) => {
			onClose();

			if (!language) {
				return;
			}

			const [languageCode, regionCode] = language.split('-');
			const input: CreateLanguageInput = {
				languageCode,
				...(regionCode ? { regionCode } : {}),
				...(fallbackLanguage ? { fallbackLanguage } : {}),
			};
			const promise = mutateAsync(input);

			toast.promise(promise, {
				loading: { description: <T _str={DICTIONARY.LANGUAGE_CREATE_PROMISE_TOAST.LOADING} /> },
				error: { description: <T _str={DICTIONARY.LANGUAGE_CREATE_PROMISE_TOAST.ERROR} /> },
				success: { description: <T _str={DICTIONARY.LANGUAGE_CREATE_PROMISE_TOAST.SUCCESS} /> },
			});
		},
		[mutateAsync, onClose, toast]
	);
};

import { Stack, Text } from '@chakra-ui/react';
import { MediaBodyProps } from 'entities/media-card/models';
import { FC } from 'react';

export const MediaBody: FC<MediaBodyProps> = ({ title, description }) => {
	return (
		<Stack alignSelf="center" flexDirection="column" gap="0" lineHeight="shorter" flexGrow="1">
			<Text noOfLines={1} color="unset">
				{title}
			</Text>
			{description && <Text variant="subtle">{description}</Text>}
		</Stack>
	);
};

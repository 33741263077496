import { Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { FormikProps } from 'formik';
import { FC } from 'react';
import { useGetTreeItemWrapper } from 'shared/item-tree/hooks';
import { ItemView, TreeView } from 'shared/item-tree/ui';
import { setSiteDefinitionFieldValue } from 'widgets/site-definition-update-form/models';
import { setSiteSettingsFieldValue } from 'widgets/site-settings-update-form/models';
import { useTreeMenu } from '../hooks';
import { TreeMenuProps } from '../models';
import { TreeMenuSelectButton } from './tree-menu-button';

export const TreeMenu: FC<TreeMenuProps & FormikProps<any>> = (props) => {
	const { siteId, homePageId, setFieldValue } = props;
	const { label, ancestors, isLoading } = useTreeMenu(props);

	return (
		<Menu flip={false} matchWidth>
			{({ isOpen }) => (
				<>
					<MenuButton
						tabIndex={0}
						isactive={isOpen.toString() as 'true' | 'false'}
						as={TreeMenuSelectButton}
						selectedids={Array.isArray(homePageId) ? homePageId : [homePageId]}
						label={label}
						_focusVisible={{
							outline: 'var(--colors-primary-500)',
							borderColor: 'primary.500',
							boxShadow: '0 0 0 1px var(--colors-primary-500)',
						}}
					/>
					<MenuList maxH="xs" overflowY="auto">
						{!isLoading && (
							<TreeView
								isOpened
								expandOnSelect
								selectionVariant="single"
								siteId={siteId}
								ancestors={ancestors}
								selectedIds={Array.isArray(homePageId) ? homePageId : [homePageId]}
								setSelectedIds={(value) => {
									setFieldValue('homePageId', value[0]);
									setSiteSettingsFieldValue('homePageId', value[0]);
									setSiteDefinitionFieldValue('homePageId', value[0]);
								}}
								getItemView={(prop) => <ItemView {...prop} />}
								useGetItem={useGetTreeItemWrapper}
							/>
						)}
					</MenuList>
				</>
			)}
		</Menu>
	);
};

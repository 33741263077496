import { Image, VStack } from '@chakra-ui/react';
import { mdiFileOutline, mdiFolderOutline } from '@mdi/js';
import { Icon } from 'entities/icon/ui';
import { useGetThumbnail } from 'features/common/tenants/useGetThumbnail';
import { ItemViewModel } from 'features/millerColumns/models';
import { ItemType } from 'features/millerColumns/utils';
import { FC } from 'react';

type ThumbnailProps = {
	item: ItemViewModel;
	type: ItemType;
	siteAlternativeImageUrl: string;
};

export const Thumbnail: FC<ThumbnailProps> = ({ item }) => {
	const thumbnail = useGetThumbnail(item.thumbnailUrl, false);

	if (thumbnail) {
		return (
			<Image src={thumbnail} width="52" borderWidth="1px" borderStyle="solid" borderColor="chakra-border-color" borderRadius="base" my="4" />
		);
	}

	if (item.hasPresentation) {
		return (
			<VStack bg="neutral.50" borderRadius="md" p="16" width="full" my="4">
				<Icon data-testid="pageIcon" path={mdiFileOutline} boxSize="20" color="neutral.500" />;
			</VStack>
		);
	}

	return (
		<VStack bg="neutral.50" borderRadius="md" p="16" width="full" my="4">
			<Icon data-testid="folderIcon" path={mdiFolderOutline} boxSize="20" color="neutral.500" />;
		</VStack>
	);
};

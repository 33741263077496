import { useCallback } from 'react';
import { useCreatePOS, useGetAllPOS } from './posService';
import { PosPayload } from './types';

type CreateModalCallback = {
	onSubmit: (newPOS: PosPayload) => void;
	validate: (values: PosPayload) => {
		name?: string;
	};
};

export const useCreateModal = (onCreated: (newPOS: PosPayload) => void): CreateModalCallback => {
	const createPOS = useCreatePOS();
	const { refetch, posList } = useGetAllPOS();
	const onSubmit = useCallback(
		(newPOS: PosPayload) => {
			void createPOS(newPOS.name, newPOS.language, newPOS.market.trim(), newPOS.brand.trim(), newPOS.timeout).then(() => {
				void refetch();
				onCreated(newPOS);
			});
		},
		[createPOS, onCreated, refetch]
	);

	const validate = useCallback(
		(values: PosPayload) => {
			const errors: { name?: string } = {};

			if (posList.findIndex((x) => x.name.toLowerCase() == values.name.toLowerCase()) !== -1) {
				errors.name = 'Site identifier name should be unique.';
			}

			return errors;
		},
		[posList]
	);

	return {
		onSubmit: onSubmit,
		validate: validate,
	};
};

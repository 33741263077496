import { ExternalNavigationLinks } from 'entities/page-layout/models';
import { useXMTenant } from 'features/common/tenants/xmTenantContext';
import { useMemo } from 'react';
import { DICTIONARY } from 'shared/constants';

export const useExternalLinks = () => {
	const { tenant } = useXMTenant();

	const utilities = useMemo<ExternalNavigationLinks>(
		() => ({
			title: DICTIONARY.UTILITIES,
			links: [{ label: DICTIONARY.DESKTOP, href: `${tenant.url}/sitecore/shell/default.aspx` }],
		}),
		[tenant.url]
	);

	const accessManagement = useMemo<ExternalNavigationLinks>(
		() => ({
			title: DICTIONARY.ACCESS_MANAGEMENT,
			links: [
				{ label: DICTIONARY.USER_MANAGER, href: `${tenant.url}/sitecore/shell/Applications/Security/User%20Manager.aspx?sc_bw=1` },
				{ label: DICTIONARY.ROLE_MANAGER, href: `${tenant.url}/sitecore/shell/Applications/Security/Role%20Manager.aspx?sc_bw=1` },
				{ label: DICTIONARY.SECURITY_EDITOR, href: `${tenant.url}/sitecore/shell/Applications/Security/Security%20Editor.aspx?sc_bw=1` },
				{ label: DICTIONARY.ACCESS_VIEWER, href: `${tenant.url}/sitecore/shell/Applications/Security/Access%20Viewer.aspx?sc_bw=1` },
			],
		}),
		[tenant.url]
	);

	return { utilities, accessManagement };
};

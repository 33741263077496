import { FormikConfig, FormikProps, FormikProvider, FormikValues, isFunction, useFormik } from 'formik';
import React, { ChangeEvent, useCallback } from 'react';

export type FormCallbacks = {
	onHandleChange?: (e: ChangeEvent) => void;
};

export function FormikWithCallbacks<Values extends FormikValues = FormikValues>(props: FormikConfig<Values> & FormCallbacks) {
	const formikbag = useFormik<Values>(props);
	const { children, onHandleChange } = props;
	const { handleChange } = formikbag;

	const handleChangeWithCallback = useCallback(
		(e: ChangeEvent) => {
			handleChange(e);

			if (onHandleChange) {
				onHandleChange(e);
			}
		},
		[onHandleChange, handleChange]
	);

	const updatedCtx = {
		...formikbag,
		handleChange: handleChangeWithCallback,
	};

	return (
		<FormikProvider value={updatedCtx}>
			{isFunction(children) ? (children as (bag: FormikProps<Values>) => React.ReactNode)(formikbag) : children}
		</FormikProvider>
	);
}

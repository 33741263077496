import {
	Box,
	Button,
	Image as ChakraImage,
	Flex,
	FormControl,
	FormErrorIcon,
	FormErrorMessage,
	IconButton,
	Input,
	Skeleton,
	Text,
	Tooltip,
} from '@chakra-ui/react';
import { mdiMinusCircleOutline } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import fallbackSrc from 'entities/site-card/images/thumbnail-fallback.svg';
import { ThumbnailCropModal } from 'entities/thumbnail/ui';
import { useTranslate } from 'features/common/utils/translateUtils';
import { ChangeEvent, FC, useRef, useState } from 'react';
import { DICTIONARY } from 'shared/constants';
import { useThumbnailDropzone } from '../hooks';
import { ThumbnailItemProps, ThumbnailUploadStatus } from '../models';
import { ThumbnailUploading } from './thumbnail-uploading';

export const ThumbnailItem: FC<ThumbnailItemProps> = ({
	thumbnail,
	buttonTitle,
	buttonSpinner,
	buttonCallback,
	loadingText,
	unsavedUrl,
	setUnsavedUrl,
	eventHandler,
	setToBeDeleted,
}) => {
	const fileRef = useRef<HTMLInputElement>(null);
	const [isFallback, setIsFallback] = useState(false);
	const t = useTranslate();
	const { errorMessage, uploadStatus, onSelectFile, onUploadFile, isOpen, onClose, imgBase64, setCroppedAreaPixels, uploadCroppedImage } =
		useThumbnailDropzone({ fileRef, eventHandler, setUnsavedUrl, setToBeDeleted });

	if (uploadStatus === ThumbnailUploadStatus.UPLOAD) {
		return <ThumbnailUploading />;
	}

	return (
		<>
			<Input type="file" accept="image/*" ref={fileRef} hidden onChange={(event: ChangeEvent<HTMLInputElement>) => onUploadFile(event)} />
			<Flex
				alignItems="flex-start"
				backgroundColor="chakra-body-bg"
				border="1px"
				borderColor="chakra-border-color"
				borderRadius="md"
				borderStyle="solid"
				gap="2"
				justifyContent="center"
				minW="sm"
				maxW="xl"
				minH="28"
				p="3"
			>
				<Skeleton isLoaded={!buttonSpinner}>
					<Box w="44" overflow="hidden" h="6.08438rem">
						<ChakraImage
							alignItems="flex-start"
							w="44"
							h="auto"
							src={unsavedUrl || thumbnail?.url}
							onError={() => {
								setIsFallback(true);
							}}
							onLoad={() => {
								setIsFallback(false);
							}}
							{...(isFallback || !thumbnail.url ? { fallbackSrc } : {})}
						/>
					</Box>
				</Skeleton>
				<Flex alignItems="flex-start" gap="1" flex="1 0 0" alignSelf="stretch">
					<Flex flexDirection="column" justifyContent="space-between" alignItems="flex-start" flex="1 0 0" alignSelf="stretch">
						<Flex flexDirection="column" gap="0.5" alignSelf="stretch">
							<Flex>
								<Skeleton width="100%" minH="6" isLoaded={!buttonSpinner}>
									<Text isTruncated noOfLines={2} whiteSpace="normal" wordBreak="break-all">
										{thumbnail.name}
									</Text>
								</Skeleton>
							</Flex>
							<Skeleton width="10" h="6" isLoaded={!buttonSpinner}>
								<Text variant="small" textTransform="uppercase">
									{thumbnail.extension}
								</Text>
							</Skeleton>
						</Flex>
						{buttonTitle && (
							<Button
								variant="ghost"
								colorScheme="primary"
								size="xs"
								onClick={buttonCallback ?? onSelectFile}
								isLoading={buttonSpinner}
								loadingText={loadingText && t(loadingText)}
								data-testid={buttonCallback ? 'thumbnail-item-button-regenerated' : 'thumbnail-item-button-replace'}
							>
								<T _str={buttonTitle} />
							</Button>
						)}
					</Flex>
					{buttonSpinner === undefined && eventHandler && (
						<Tooltip label={t(DICTIONARY.UNASSIGN)}>
							<IconButton
								data-testid="thumbnail-remove-button"
								display="flex"
								_hover={{
									bgColor: 'danger.100',
								}}
								bgColor="white"
								color="danger.600"
								alignItems="center"
								justifyContent="center"
								aria-label={t(DICTIONARY.UNASSIGN)}
								icon={<Icon path={mdiMinusCircleOutline} />}
								boxSize="6"
								minW="6"
								p="1"
								onClick={() => {
									eventHandler('', 'thumbnailBase64Url');
									eventHandler('true', 'toBeDeleted');

									if (setUnsavedUrl) {
										setUnsavedUrl('');
									}

									if (setToBeDeleted) {
										setToBeDeleted(true);
									}
								}}
							/>
						</Tooltip>
					)}
				</Flex>
			</Flex>
			{errorMessage && (
				<FormControl isInvalid>
					<FormErrorMessage>
						<FormErrorIcon />
						<T _str={errorMessage} />
					</FormErrorMessage>
				</FormControl>
			)}
			<ThumbnailCropModal
				isOpen={isOpen}
				onClose={onClose}
				imgBase64={imgBase64}
				setCroppedAreaPixels={setCroppedAreaPixels}
				uploadCroppedImage={uploadCroppedImage}
			/>
		</>
	);
};

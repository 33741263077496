import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Button,
	ButtonGroup,
	FormControl,
	FormLabel,
	Input,
	ListItem,
	ModalBody,
	ModalFooter,
	Stack,
	Text,
	UnorderedList,
	UseDisclosureReturn,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { useFeatures } from 'features/common/featureFlags/featureFlags';
import { useRemoveSiteData } from 'features/remove-site/hooks';
import { RemoveSiteProps } from 'features/remove-site/models';
import { FC, PropsWithChildren, useMemo } from 'react';
import { DICTIONARY } from 'shared/constants';

export const RemoveSiteModalBody: FC<PropsWithChildren<RemoveSiteProps> & Pick<UseDisclosureReturn, 'onClose'>> = ({ onClose, ...props }) => {
	const { isRemoveDisabled, setText, handleRemove, environment } = useRemoveSiteData(props);
	const { showSharedSite } = useFeatures();
	const { name, hosts, collectionName, isShared } = props;
	const siteDefNames = useMemo(() => hosts.join(', '), [hosts]);

	return (
		<>
			<ModalBody>
				<Stack gap="6">
					<Text>
						<T
							_str={DICTIONARY.DELETE_SITE_MAIN_TEXT}
							sitename={
								<Text as="span" variant="strong">
									{name}
								</Text>
							}
							env={
								<Text as="span" variant="strong">
									{environment}
								</Text>
							}
						/>
					</Text>
					<Stack gap={1}>
						<Text variant="strong">
							<T _str={DICTIONARY.DELETE_SITE_LIST_TEXT.TITLE} />
						</Text>
						<UnorderedList pl={3}>
							{showSharedSite && isShared && (
								<ListItem>
									<Text>
										<T
											_str={DICTIONARY.DELETE_SITE_LIST_TEXT.DELETE_SHARED_SITE}
											collectionName={collectionName}
											siteName={name}
										/>
									</Text>
								</ListItem>
							)}
							<ListItem>
								<Text>
									<T _str={DICTIONARY.DELETE_SITE_LIST_TEXT.DELETE_EVERYTHING} siteName={name} />
								</Text>
							</ListItem>
							<ListItem>
								<Text>
									<T _str={DICTIONARY.DELETE_SITE_LIST_TEXT.DELETE_DEFINITION} siteDefNames={siteDefNames} />
								</Text>
							</ListItem>
						</UnorderedList>
					</Stack>
					<FormControl isRequired>
						<FormLabel>
							<T _str={DICTIONARY.DELETE_SITE_CONFIRMATION_MESSAGE} name={name} />
						</FormLabel>
						<Input onChange={(e) => setText(e.target.value)} data-testid="remove-site-input-name" />
					</FormControl>
					<Alert>
						<AlertIcon />
						<Stack gap="2">
							<AlertTitle>
								<T _str={DICTIONARY.DELETE_SITE_ALERT_TITLE} />
							</AlertTitle>
							<AlertDescription>{DICTIONARY.DELETE_SITE_ALERT_DESCRIPTION}</AlertDescription>
						</Stack>
					</Alert>
				</Stack>
			</ModalBody>
			<ModalFooter>
				<ButtonGroup>
					<Button data-testid="close-btn" variant="ghost" onClick={onClose}>
						<T _str={DICTIONARY.CANCEL} />
					</Button>
					<Button
						data-testid="remove-btn"
						colorScheme="danger"
						loadingText={<T _str={DICTIONARY.DELETING} />}
						isDisabled={isRemoveDisabled}
						onClick={() => void handleRemove()}
					>
						<T _str={DICTIONARY.DELETE} />
					</Button>
				</ButtonGroup>
			</ModalFooter>
		</>
	);
};

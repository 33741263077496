import { FormControl, FormLabel, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { InfoPopover } from 'entities/info-popover/ui';
import { Toggle } from 'entities/toggle-switcher/ui';
import { useAssignPosModalEA } from 'features/point-of-sale/hooks/use-assign-pos-modal-ea';
import { PosModalPropsEA } from 'features/point-of-sale/models';
import { AssignPOSFormEA, CreatePOSForm } from 'features/point-of-sale/ui';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';

/** @deprecated */
export const CreateAssignPosModalEA: FC<PosModalPropsEA> = (props) => {
	const { isOpen, name } = props;
	const { assign, side, setSide, isPending, onCloseModal } = useAssignPosModalEA(props);

	return (
		<Modal isOpen={isOpen} onClose={onCloseModal} size="lg" blockScrollOnMount={false}>
			<ModalOverlay />
			<ModalContent minHeight="2xl">
				<ModalHeader>
					<T _str={DICTIONARY.ASSIGN_ANALYTICS_IDENTIFIER} />
				</ModalHeader>
				<ModalBody display="flex" flexDirection="column" height="full" gap="6">
					<FormControl>
						<FormLabel display="flex" flexDirection="row" alignItems="center">
							<T _str={DICTIONARY.ANALYTICS_IDENTIFIER} />
							<InfoPopover
								header={DICTIONARY.POS_TABLE_HINTS.IDENTIFIER.TITLE}
								body={DICTIONARY.POS_TABLE_HINTS.IDENTIFIER.DESCRIPTION}
							/>
						</FormLabel>
						<Toggle side={side} setSide={setSide} leftText={DICTIONARY.CREATE_NEW} rightText={DICTIONARY.ASSIGN_EXISTING} />
					</FormControl>
					{side === 'left' && <CreatePOSForm {...props} assign={assign} isPending={isPending} siteName={name} />}
					{side === 'right' && <AssignPOSFormEA {...props} assign={assign} isPending={isPending} siteName={name} />}
				</ModalBody>
				<ModalCloseButton />
			</ModalContent>
		</Modal>
	);
};

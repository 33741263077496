import { Button, ButtonGroup, Heading, Stack, Text } from '@chakra-ui/react';
import { mdiOpenInNew } from '@mdi/js';
import { T } from '@transifex/react';
import { Icon } from 'entities/icon/ui';
import { ExternalNavigationLinks } from 'entities/page-layout/models';
import { FC } from 'react';

export const PageLeftExternalNavigation: FC<ExternalNavigationLinks> = ({ links, title }) => {
	return (
		<Stack gap="0">
			{title && (
				<Heading variant="section" paddingLeft="2.5" marginY="2">
					<T _str={title} />
				</Heading>
			)}
			<ButtonGroup data-testid="tabs" variant="navigation" orientation="vertical" spacing="1" width="60">
				{links.map((link, index) => (
					<Button
						data-testid={`external-link-${link.label}`}
						gap="2"
						key={`${link.label}_${index}`}
						as="a"
						cursor="pointer"
						rightIcon={<Icon path={mdiOpenInNew} boxSize="4" />}
						iconSpacing="0"
						{...(link.href ? { href: link.href, target: '_blank' } : {})}
					>
						<Text width="full">
							<T _str={link.label} />
						</Text>
					</Button>
				))}
			</ButtonGroup>
		</Stack>
	);
};

import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Alert,
	AlertDescription,
	AlertIcon,
	Box,
	Button,
	ButtonGroup,
	Container,
	FormControl,
	FormErrorIcon,
	FormErrorMessage,
	FormHelperText,
	FormLabel,
	Input,
	ModalBody,
	Stack,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { Select } from 'chakra-react-select';
import { LanguageSelectItem, useGetlanguagesSelect } from 'features/common/languages';
import { useCdpTenant } from 'features/common/tenants/CdpTenantProvider';
import { SelectOrCreatePos } from 'features/pos/components/SelectOrCreatePos';
import { PosMapping } from 'features/pos/utils/types';
import { Formik } from 'formik';
import { FC, Fragment, useCallback, useState } from 'react';
import { DICTIONARY } from 'shared/constants';
import { CreateSiteFodmData, siteInputDefaultValues } from '../data/createSiteTypes';
import { siteValidationSchema } from '../utils/siteValidationSchema';
import { useCreateSite } from '../utils/useCreateSite';
import { TemplatesList } from './TemplatesList';

type CreateWebsiteFormProps = {
	closeModal: () => void;
};

export const CreateWebsiteForm: FC<CreateWebsiteFormProps> = ({ closeModal }) => {
	const [currentTab, setCurrentTab] = useState(0);
	const { languages, findSelectedLanguage } = useGetlanguagesSelect();
	const [createSite] = useCreateSite();
	const { isEnabled: cdpEnabled } = useCdpTenant();
	const handleSiteCreation = useCallback(
		async (values: CreateSiteFodmData) => {
			try {
				let posMappings: PosMapping[] = [];

				if (values.pos) {
					posMappings = [
						{
							language: values.language.iso,
							name: values.pos.name,
						},
					];
				}

				await createSite({
					variables: {
						...values,
						language: values.language.name,
						posMappings: posMappings,
					},
				});
				closeModal();
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (e: any) {
				// ignore
			}
		},
		[closeModal, createSite]
	);

	return (
		<Formik<CreateSiteFodmData>
			initialValues={siteInputDefaultValues}
			onSubmit={handleSiteCreation}
			validateOnChange={false}
			validateOnBlur={false}
			validateOnMount={false}
			validationSchema={siteValidationSchema}
		>
			{({ values, handleChange, errors, touched, setFieldValue, submitForm, isSubmitting }) => (
				<>
					<ModalBody>
						<Tabs index={currentTab} align="center">
							<TabList borderColor="transparent">
								<Tab paddingX={4} borderBottom="2px solid" borderColor="chakra-border-color">
									<T _str={DICTIONARY.FIRST_TAB_CHOOSE_TEMPLATE} />
								</Tab>
								<Tab paddingX={4} borderBottom="2px solid" borderColor="chakra-border-color">
									<T _str={DICTIONARY.SECOND_TAB_CONFIGURE} />
								</Tab>
							</TabList>
							<TabPanels textAlign="left">
								<TabPanel paddingTop="12">
									<TemplatesList
										setSelectedTemplateId={(templateId) => {
											setFieldValue('templateId', templateId);
											setCurrentTab(1);
										}}
									/>
								</TabPanel>
								<TabPanel paddingTop="12">
									<Container maxWidth="lg">
										<Stack direction="column" alignItems="center" spacing={6}>
											<FormControl
												isDisabled={isSubmitting}
												isRequired={true}
												isInvalid={!!(errors.siteName && touched.siteName)}
											>
												<FormLabel htmlFor="siteName">
													<T _str={DICTIONARY.SITE_NAME} />
												</FormLabel>
												<Input
													type="url"
													name="siteName"
													id="siteName"
													value={values.siteName}
													onChange={handleChange}
													maxLength={50}
												/>
												<FormHelperText>
													<T _str={DICTIONARY.SITE_NAME_TOOLTIP} />
												</FormHelperText>
												<FormErrorMessage>
													<FormErrorIcon />
													<Text>{errors.siteName}</Text>
												</FormErrorMessage>
											</FormControl>
											<FormControl
												isDisabled={isSubmitting}
												isRequired={true}
												isInvalid={!!(errors.language && touched.language)}
											>
												<FormLabel htmlFor="language">
													<T _str={DICTIONARY.LANGUAGE} />
												</FormLabel>
												<Select
													id="language"
													options={languages}
													value={findSelectedLanguage(values.language, languages)}
													onChange={(newValue: LanguageSelectItem | null) => setFieldValue('language', newValue?.value)}
												/>
												<FormErrorMessage>
													<FormErrorIcon />
													<>{errors.language}</>
												</FormErrorMessage>
											</FormControl>
											{cdpEnabled && (
												<Accordion allowToggle reduceMotion w="full">
													<AccordionItem>
														<h2>
															<AccordionButton backgroundColor="chakra-subtle-bg">
																<Box flex="1" textAlign="left">
																	<Text>
																		<T _str={DICTIONARY.ANALYTICS_IDENTIFIER} />
																		&nbsp;-
																		<Text as="i" variant="subtle">
																			&nbsp;
																			<T _str={DICTIONARY.OPTIONAL} />
																		</Text>
																	</Text>
																</Box>
																<AccordionIcon />
															</AccordionButton>
														</h2>
														<AccordionPanel backgroundColor="chakra-subtle-bg">
															<Text noOfLines={2} variant="subtle" mb={6}>
																<T _str={DICTIONARY.ANALYTICS_IDENTIFIER_HELPER} />
															</Text>
															<FormControl isDisabled={isSubmitting} isRequired={false} isInvalid={!!errors.pos}>
																<SelectOrCreatePos
																	intialLanguageIso={values.language.iso}
																	value={values.pos}
																	onChange={(pos) => setFieldValue('pos', pos)}
																/>
																<FormErrorMessage>
																	<FormErrorIcon />
																	<>{errors.language}</>
																</FormErrorMessage>
															</FormControl>
														</AccordionPanel>
													</AccordionItem>
												</Accordion>
											)}
											{values.siteName && (
												<Alert>
													<Fragment key=".0">
														<AlertIcon />
														<AlertDescription>
															<span>
																<T _str={DICTIONARY.SITE_IS_PART_OF_SITE_COLLECTION} />
															</span>
															&nbsp;
															<span style={{ fontWeight: 'bold' }}>{values.siteName}</span>
														</AlertDescription>
													</Fragment>
												</Alert>
											)}
											<ButtonGroup paddingTop={3}>
												<Button
													variant="outline"
													onClick={() => {
														setFieldValue('templateId', '');
														setCurrentTab(0);
													}}
												>
													<T _str={DICTIONARY.PREVIOUS_STEP} />
												</Button>
												<Button onClick={() => void submitForm()} isLoading={isSubmitting} data-testid="createSite">
													<T _str={DICTIONARY.CREATE_WEBSITE} />
												</Button>
											</ButtonGroup>
										</Stack>
									</Container>
								</TabPanel>
							</TabPanels>
						</Tabs>
					</ModalBody>
				</>
			)}
		</Formik>
	);
};

import { Link } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { DICTIONARY } from 'shared/constants';
import React from 'react';

export type ResponseErrorMapping = {
	scope?: string;
	path?: string;
	method?: string;
	code?: number;
	message: React.ReactNode;
};

export type ErrorIdentifier = Pick<ResponseErrorMapping, 'scope' | 'method' | 'code' | 'path'>;

const sitecoreSupportUrl = 'https://sitecore.com';
const SitecoreSupport = (
	<Link
		href={sitecoreSupportUrl}
		target="_blank"
		css={{
			whiteSpace: 'nowrap',
			fontSize: 16,
			textDecoration: 'underline',
			lineHeight: 1.5,
		}}
	>
		Sitecore Support
	</Link>
);

/**
 * Response paths with parameters can be matched with :param.
 * Search params (?key=value) and hash (#header-text) are ignored on paths.
 * Order matters, first match is used.
 */
export const defaultResponseErrorMap: ResponseErrorMapping[] = [
	{
		code: 401,
		message: <T _str={DICTIONARY.INVALID_ACCESS_TOKEN} sitecoreSupport={SitecoreSupport} />,
	},
	// SAMPLE for future:
	// {
	// 	method: 'get',
	// 	path: config.projects.get_projects_list.url,
	// 	message: t('Failed to load projects'),
	// },
];

function compareApiPaths(genericPath?: string, realPath?: string) {
	if (!genericPath || !realPath) {
		return false;
	}

	const url1 = new URL(genericPath, 'https://localhost');
	const url2 = new URL(realPath, 'https://localhost');

	if (url1.pathname.includes('/:')) {
		const paths1 = url1.pathname.split('/');
		const paths2 = url2.pathname.split('/');

		// Replace all tokens with :generic tokens.
		url2.pathname = paths1.map((path, index) => (path.startsWith(':') ? path : paths2[index])).join('/');
	}

	return url1.origin === url2.origin && url1.pathname === url2.pathname;
}

/**
 * Returns first matching error message from the errors map.
 * Compares defined props from the errors map with provided arguments.
 */
export function matchError(errorsMap: ResponseErrorMapping[], errorIdentifier: ErrorIdentifier): React.ReactNode {
	const { method, code, path } = errorIdentifier;

	const customError = errorsMap.find((errorMap) => {
		const codeMatch = errorMap.code ? errorMap.code === code : true;
		const methodMatch = errorMap.method ? errorMap.method === method : true;
		const pathMatch = errorMap.path ? compareApiPaths(errorMap.path, path) : true;

		return codeMatch && methodMatch && pathMatch;
	});

	return customError?.message;
}

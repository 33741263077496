import { Box, CircularProgress, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FC } from 'react';

type DashboardLoadingScreenProps = {
	bgColor?: string;
	text: string;
};

export const DashboardLoadingScreen: FC<DashboardLoadingScreenProps> = ({ bgColor, text }) => {
	return (
		<Box
			alignItems="center"
			display="flex"
			height="full"
			justifyContent="center"
			width="full"
			bgColor={bgColor || 'transparent'}
			data-testid="LoadingScreen"
		>
			<Box textAlign="center">
				<CircularProgress isIndeterminate capIsRound color="primary" trackColor="neutral-color.200" />
				<Text mt={6}>
					<T _str={text} />
				</Text>
			</Box>
		</Box>
	);
};

import { useDisclosure } from '@chakra-ui/react';
import { CollectionResponse } from 'features/collections/models';
import { useCallback, useDeferredValue, useMemo, useRef } from 'react';
import { useParams } from 'react-router';
import { ENDPOINTS } from 'shared/endpoints';
import { useNavigateToRoute } from 'shared/navigation/hooks';
import { useGet } from 'shared/react-query-wrappers/hooks';

export const usePortfolioPageData = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const dirty = useRef(false);
	const isSubmitting = useRef(false);
	const actionCallback = useRef(() => {});

	const params = useParams();
	const navigate = useNavigateToRoute();
	const { data } = useGet<CollectionResponse>({
		queryKey: { url: ENDPOINTS.COLLECTION, params: { collectionId: params.id ?? '' } },
	});

	const deferredData = useDeferredValue(data);
	const collectionData = useMemo(
		() => ({
			displayName: deferredData?.displayName || deferredData?.name || '',
			description: deferredData?.description || '',
		}),
		[deferredData]
	);
	const goBack = useCallback(() => {
		if (dirty.current && !isSubmitting.current) {
			actionCallback.current = () => {
				navigate('/');
			};

			onOpen();
		} else {
			navigate('/');
		}
	}, [navigate, onOpen]);

	return {
		data,
		goBack,
		dialog: {
			dirty,
			isSubmitting,
			actionCallback,
			isOpen,
			onOpen,
			onClose,
		},
		...collectionData,
	};
};

import { Flex } from '@chakra-ui/react';
import { useTranslate } from 'features/common/utils/translateUtils';
import { useShowMore } from 'features/show-more/hooks';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { JssPortfolioProps } from 'widgets/portfolio/models';
import { JssPortfolioBody } from 'widgets/portfolio/ui/jss-portfolio-body';
import { PortfolioFooter } from 'widgets/portfolio/ui/portfolio-footer';
import { PortfolioHeader } from 'widgets/portfolio/ui/portfolio-header';

export const JssPortfolio: FC<JssPortfolioProps> = ({ sites, isLoading }) => {
	const { showMoreItem, toggleMore } = useShowMore(sites);
	const t = useTranslate();

	if (!sites?.length && !isLoading) {
		return <></>;
	}

	return (
		<Flex direction="column" padding="5" background="chakra-body-bg" borderRadius="md" gap="4" data-testid="portfolio">
			<PortfolioHeader
				sitesCount={1}
				isLoading={false}
				isRecentlyCreated={false}
				id="jss-sites"
				description={t(DICTIONARY.JSS_PORTFOLIO_DESCRIPTION)}
				displayName=""
				name={t(DICTIONARY.JSS_PORTFOLIO_NAME)}
				showAction={false}
			/>
			<JssPortfolioBody items={sites} isLoading={isLoading} />
			<PortfolioFooter isShowLess={showMoreItem.isShowLess} isShowMore={showMoreItem.isShowMore} onToggleMoreClick={toggleMore} />
		</Flex>
	);
};

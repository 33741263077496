import { Button, Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { PortfolioFooterProps } from '../models';
import { T } from '@transifex/react';

export const PortfolioFooter: FC<PortfolioFooterProps> = ({ isShowLess, isShowMore, onToggleMoreClick }) => {
	if (!isShowMore && !isShowLess) {
		return <></>;
	}

	return (
		<Flex direction="column" alignItems="center" gap="2.5">
			<Button variant="ghost" size="sm" onClick={onToggleMoreClick}>
				<T _str={isShowLess ? DICTIONARY.SEE_LESS : DICTIONARY.SEE_MORE} />
			</Button>
		</Flex>
	);
};

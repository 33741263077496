import { Box, Text } from '@chakra-ui/react';
import { mdiAlertOutline } from '@mdi/js';
import { Icon } from 'entities/icon/ui';
import { FC } from 'react';
import { MissingPosLabelProps } from 'widgets/site-pos-table/models';

export const MissingPosLabel: FC<MissingPosLabelProps> = ({ identifier }) => {
	const posName = identifier ?? '';

	return (
		<Text as={Box} noOfLines={2} color="warning" verticalAlign="middle" data-testid={`missing-pos-${posName}`}>
			<Icon path={mdiAlertOutline} />
			<Text as="span" verticalAlign="middle">
				{posName}
			</Text>
		</Text>
	);
};

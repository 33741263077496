import { Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Stack } from '@chakra-ui/react';
import { mdiAccountGroupOutline, mdiBookOpenPageVariantOutline, mdiForumOutline, mdiLifebuoy } from '@mdi/js';
import { T } from '@transifex/react';
import { MediaCard } from 'entities/media-card/ui';
import { useFeatures } from 'features/common/featureFlags/featureFlags';
import { useTranslate } from 'features/common/utils/translateUtils';
import { CreateSupportCase } from 'features/create-support-case/ui';
import { HelpDrawerProps } from 'features/help-drawer/models';
import { FC } from 'react';
import {
	DICTIONARY,
	JOIN_SITECORE_COLLABORATIVE_PROGRAM_LINK,
	SITECORE_SUPPORT_PORTAL,
	XM_CLOUD_DEVELOPER_PORTAL,
	XM_CLOUD_DOC,
	XM_CLOUD_SITES_DOC,
} from 'shared/constants';

export const HelpDrawer: FC<HelpDrawerProps> = ({ isOpen, onClose, onCreateTicketClick }) => {
	const t = useTranslate();
	const { showNewSitesPage } = useFeatures();

	return (
		<Drawer isOpen={isOpen} size="md" onClose={onClose}>
			<DrawerOverlay />
			<DrawerContent data-testid="help-drawer">
				<DrawerCloseButton />
				<DrawerHeader display="flex" alignItems="center">
					<T _str={DICTIONARY.HELP} />
				</DrawerHeader>
				<DrawerBody>
					<Stack gap="2">
						<MediaCard
							title={t(DICTIONARY.XM_CLOUD_DOCUMENTATION)}
							icon={mdiBookOpenPageVariantOutline}
							onClick={() => window.open(XM_CLOUD_DOC, '_blank')}
						/>
						{showNewSitesPage && (
							<MediaCard
								title={t(DICTIONARY.XM_CLOUD_SITES_DOCUMENTATION)}
								icon={mdiBookOpenPageVariantOutline}
								onClick={() => window.open(XM_CLOUD_SITES_DOC, '_blank')}
							/>
						)}
						<MediaCard
							title={t(DICTIONARY.SITECORE_SUPPORT_PORTAL)}
							description={t(DICTIONARY.REPORT_ISSUE_AND_MANAGE)}
							icon={mdiLifebuoy}
							onClick={() => window.open(SITECORE_SUPPORT_PORTAL, '_blank')}
						/>
						<MediaCard
							title={t(DICTIONARY.XM_CLOUD_DEVELOPER_PORTAL)}
							description={t(DICTIONARY.RESOURCES_FOR_DEVELOPER)}
							icon={mdiForumOutline}
							onClick={() => window.open(XM_CLOUD_DEVELOPER_PORTAL, '_blank')}
						/>
						<Divider marginY="4" />
						<MediaCard
							title={t(DICTIONARY.JOIN_SITECORE)}
							description={t(DICTIONARY.HELP_US)}
							icon={mdiAccountGroupOutline}
							onClick={() => window.open(JOIN_SITECORE_COLLABORATIVE_PROGRAM_LINK, '_blank')}
						/>
						<CreateSupportCase onCreateTicketClick={onCreateTicketClick} />
					</Stack>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};

import { Button, Text, useToast } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { useTranslate } from 'features/common/utils/translateUtils';
import { usePosSynchronization } from 'features/pos/utils/usePosSynchronization';
import { FC, useEffect, useRef } from 'react';
import { DICTIONARY } from 'shared/constants';
import { usePosNotification } from '../utils/usePosNotification';

const missingPosToastId = 'missing-pos-toast';

export const MissingPosNotification: FC = () => {
	const toast = useToast();
	const translate = useTranslate();
	const alertWasShown = useRef(false);
	const { isSynchronized, isVerifying } = usePosSynchronization();
	const { onSettingsClick } = usePosNotification(missingPosToastId);

	useEffect(() => {
		if (alertWasShown.current || isVerifying) {
			return;
		}

		alertWasShown.current = true;

		if (isSynchronized) {
			return;
		}

		toast({
			id: missingPosToastId,
			status: 'info',
			title: translate(DICTIONARY.RESTORE_PERSONALIZE_AND_ANALYZE),
			description: (
				<>
					<Text wordBreak="break-word">
						<T _str={DICTIONARY.PERSONALIZE_AND_ANALYZE_CONNECTION_IS_BROKEN} />
					</Text>
					<Button variant="link" data-testid="settings" onClick={onSettingsClick}>
						<T _str={DICTIONARY.GO_TO_DASHBOARD_SETTINGS} />
					</Button>
				</>
			),
		});
	}, [isVerifying, isSynchronized, translate, toast, onSettingsClick]);

	return <></>;
};

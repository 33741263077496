import { Flex, Heading, Stack, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { CreateLanguageButton } from 'features/languages/ui';
import { FC } from 'react';
import { DICTIONARY } from 'shared/constants';
import { LanguagesTable } from 'widgets/languages-table/ui';

export const SystemLanguagesPage: FC = () => {
	return (
		<Stack alignItems="flex-start" gap="6">
			<Flex alignItems="flex-start" gap="3">
				<Stack gap="1">
					<Heading size="xl">
						<T _str={DICTIONARY.LANGUAGES} />
					</Heading>
					<Text variant="subtle">
						<T _str={DICTIONARY.SYSTEM_LANGUAGE_PAGE_SUBTITLE} />
					</Text>
				</Stack>
				<Stack alignItems="flex-start">
					<CreateLanguageButton />
				</Stack>
			</Flex>
			<LanguagesTable boxSize="full" />
		</Stack>
	);
};

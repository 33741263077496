import { CreateSitePageModalProps } from 'features/create-site-page/models';
import { CreateSitePageModal } from 'features/create-site-page/ui';
import { FC, Fragment, PropsWithChildren } from 'react';
import { useModalWrapper } from 'shared/modal-wrapper/hooks';
import { ModalWrapperProps } from 'shared/modal-wrapper/models';

export const CreateSitePageModalWrapper: FC<PropsWithChildren<CreateSitePageModalProps> & ModalWrapperProps> = (props) => {
	const modalWrapperProps = useModalWrapper(props);

	return (
		<Fragment>
			{modalWrapperProps.children}
			<CreateSitePageModal {...modalWrapperProps} {...props} />
		</Fragment>
	);
};

import { SiteWithPos } from 'features/sites/data/createSiteTypes';
import { useGetSitesWithPos } from 'features/sites/utils/useGetSitesWithPos';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

export const useGetSite = (): { site: SiteWithPos | undefined; loading: boolean; fetchSite: () => Promise<SiteWithPos | undefined> } => {
	const { sitename } = useParams<'sitename'>();
	const { sites, loading, fetchSites } = useGetSitesWithPos();

	const site = useMemo(() => {
		if (loading) {
			return undefined;
		}

		return sites.find((s) => s.name === sitename);
	}, [loading, sitename, sites]);

	const fetchSite = useCallback(async () => {
		const fetchedSites = await fetchSites();

		return fetchedSites.find((s) => s.name === sitename);
	}, [fetchSites, sitename]);

	return {
		site: site,
		loading: loading,
		fetchSite,
	};
};

import { UseThumbnailAutoEA } from 'features/thumbnail/models';
import { useState } from 'react';
import { THUMBNAIL_REGENERATE_TIMEOUT } from 'shared/constants';
import { useRegenerateThumbnailEA } from './use-regenerate-thumbnail-ea';

/**
 * @deprecated Update is blocked for EA
 */
export const useThumbnailAutoEA: UseThumbnailAutoEA = (site) => {
	const [isRegenerating, setIsRegenerating] = useState<boolean>(false);
	const handleRegenerateThumbnail = useRegenerateThumbnailEA({
		siteId: site?.id,
		collectionId: site?.collectionId,
	});

	const onClickRegenerate = async () => {
		setIsRegenerating(true);
		await handleRegenerateThumbnail();
		setTimeout(() => {
			setIsRegenerating(false);
		}, THUMBNAIL_REGENERATE_TIMEOUT);
	};

	return { isRegenerating, onClickRegenerate };
};

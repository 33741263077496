import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useToast } from '@chakra-ui/react';
import { DICTIONARY } from 'shared/constants';
import { FC, useRef, useState } from 'react';
import { useAuthWithClaims } from '../auth/useAuthWithClaims';
import { TenantVersionFeaturesProvider } from '../tenantVersionPrivider/components/TenantVersionProvider';
import { XMTenantModel } from '../tenants/xmTenantContext';
import { useTranslate } from '../utils/translateUtils';

export const DashboardApolloProvider: FC<React.PropsWithChildren<XMTenantModel>> = ({ children, url }) => {
	const { getAccessTokenSilently } = useAuthWithClaims();
	const toast = useToast();
	const t = useTranslate();

	const [errorLink] = useState(
		onError(({ graphQLErrors, networkError, operation, forward }) => {
			if (graphQLErrors)
				graphQLErrors.forEach(({ message }) =>
					toast({
						status: 'error',
						description: message,
					})
				);

			if (networkError) {
				toast({
					title: t(DICTIONARY.NETWORK_ERROR),
					status: 'error',
					description: networkError.message,
				});
			}

			forward(operation);
		})
	);

	const [asyncAuthLink] = useState(
		setContext(() => {
			return new Promise((success, fail) => {
				getAccessTokenSilently()
					.then((token) => {
						success({
							headers: { authorization: `Bearer ${token}` },
						});
					})
					.catch((error) => fail(error));
			});
		})
	);

	const [httpLink] = useState(
		new HttpLink({
			uri: url + '/sitecore/api/authoring/graphql/v1',
		})
	);

	const client = useRef(
		new ApolloClient({
			cache: new InMemoryCache(),
			link: from([errorLink, asyncAuthLink, httpLink]),
			connectToDevTools: true,
			defaultOptions: {
				query: {
					errorPolicy: 'all',
				},
				mutate: {
					errorPolicy: 'all',
				},
				watchQuery: {
					errorPolicy: 'all',
				},
			},
		})
	);

	return (
		<ApolloProvider client={client.current}>
			<TenantVersionFeaturesProvider>{children}</TenantVersionFeaturesProvider>
		</ApolloProvider>
	);
};

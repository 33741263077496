import { useCallback, useMemo } from 'react';
import { CalculateReactQueryKey, CommonQueryKey, CommonQueryKeyParams, ReactQueryKey } from 'shared/react-query-wrappers/models';

export const useQueryKey = (
	queryKey: CommonQueryKey
): {
	key: ReactQueryKey;
	calculateKey: CalculateReactQueryKey;
} => {
	const calculateKey = useCallback((url: string, params: CommonQueryKeyParams | undefined) => {
		const hasParams = params && Object.keys(params).length > 0;

		const leftBracketsLength = url.match('{')?.length;
		const rightBracketsLength = url.match('}')?.length;
		const includesParams = leftBracketsLength && rightBracketsLength && leftBracketsLength === rightBracketsLength;

		if (hasParams && includesParams) {
			let updatedUrl = url;

			Object.keys(params).forEach((key) => {
				updatedUrl = updatedUrl.replace(`{${key}}`, params[key]);
			});

			return {
				url: updatedUrl,
				queryKey: [url, params],
			};
		} else if (!hasParams && !includesParams) {
			return {
				url: url,
				queryKey: [url],
			};
		} else {
			throw new Error('Invalid query key');
		}
	}, []);

	const calculatedKey = useMemo(() => {
		return calculateKey(queryKey.url, queryKey.params);
	}, [calculateKey, queryKey.params, queryKey.url]);

	return {
		key: calculatedKey,
		calculateKey: calculateKey,
	};
};

import { useGetSitesWithPoS } from 'features/point-of-sale/hooks/get-sites-with-pos';
import { MissingPos, SiteWithMissingPos } from 'features/point-of-sale/models';
import { useMemo } from 'react';
import { useGetAllPoS } from 'shared/api';

export const useGetMissingPosList = () => {
	const { sites, isLoading: isSitesLoading } = useGetSitesWithPoS();
	const { posList, isLoading: isPosLoading } = useGetAllPoS();

	const missingPosList = useMemo<MissingPos>(() => {
		const sitesWithMissingPos: SiteWithMissingPos[] = [];

		if (isPosLoading || isSitesLoading) {
			return { sites: sitesWithMissingPos };
		}

		sites.forEach((site) => {
			const missingPos = site?.posIdentifiers?.filter((pos) => !posList.some((p) => p.name === pos)) ?? [];
			const filteredMissingPos = missingPos.filter((pos, index) => missingPos.indexOf(pos) === index);

			if (filteredMissingPos.length) {
				sitesWithMissingPos.push({
					id: site.id,
					name: site.name,
					missingPos: filteredMissingPos,
				});
			}
		});

		return { sites: sitesWithMissingPos };
	}, [isPosLoading, isSitesLoading, sites, posList]);

	return { missingPosList, isLoading: isPosLoading || isSitesLoading };
};

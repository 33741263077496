import { Box, Flex, FlexProps, Heading, Text } from '@chakra-ui/react';
import { T } from '@transifex/react';
import React, { FC, PropsWithChildren } from 'react';

type PageBodyContentSectionProps = {
	title?: string;
	description?: React.ReactNode;
	headerNode?: React.ReactNode;
} & PropsWithChildren<FlexProps>;

export const PageBodyContentSection: FC<PageBodyContentSectionProps> = ({ title, description, headerNode, children, ...props }) => {
	return (
		<Flex
			gap="6"
			{...props}
			direction="column"
			p="5"
			bgColor="chakra-body-bg"
			borderRadius="md"
			data-testid={`content-section-${title || 'empty'}`}
		>
			{(title || description || headerNode) && (
				<Flex justifyContent="space-between" gap="3">
					<Flex direction="column" gap="1">
						{title && (
							<Heading size="sm">
								<T _str={title} />
							</Heading>
						)}
						<Text as={Box} variant="subtle">
							{description}
						</Text>
					</Flex>
					<Flex>{headerNode}</Flex>
				</Flex>
			)}
			{children}
		</Flex>
	);
};

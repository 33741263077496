import { useCheckCollectionMetaNameUniqueness } from 'features/collections/hooks';
import { useFormik } from 'formik';
import { ENDPOINTS } from 'shared/endpoints';
import { usePatch } from 'shared/react-query-wrappers/hooks';
import { collectionValidationSchema } from 'shared/validations';
import { GeneralFormRenameInputs } from '../models';

export const useGeneralSection = (collection: GeneralFormRenameInputs & { id: string }) => {
	const { mutateAsync } = usePatch({
		queryKey: { url: ENDPOINTS.COLLECTION, params: { collectionId: collection.id } },
		ignoreGlobalErrorHandler: true,
		refetchQueriesByKey: [
			{ url: ENDPOINTS.COLLECTION, params: { collectionId: collection.id }, shouldWait: true },
			{ url: ENDPOINTS.COLLECTIONS },
			{ url: ENDPOINTS.JOBS },
		],
	});
	const validateCollectionMetaNameUniqueness = useCheckCollectionMetaNameUniqueness({
		collectionName: collection?.displayName || '',
		formKey: 'displayName',
	});

	const formik = useFormik<GeneralFormRenameInputs>({
		initialValues: {
			displayName: collection.displayName,
			description: collection.description,
		},
		validationSchema: collectionValidationSchema,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		validate: (values) => validateCollectionMetaNameUniqueness(values.displayName),
		enableReinitialize: true,
		onSubmit: async (values) => {
			const variables: Partial<GeneralFormRenameInputs> = {};

			if (values.displayName !== collection.displayName) {
				variables.displayName = values.displayName;
			}

			if (values.description !== collection.description) {
				variables.description = values.description;
			}

			const resolve = await mutateAsync(variables);

			return resolve;
		},
	});

	return formik;
};

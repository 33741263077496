import { useTranslate } from 'features/common/utils/translateUtils';
import { ActionType } from 'features/create-site-page/models';
import { useCallback } from 'react';
import { DICTIONARY } from 'shared/constants';
import { ENDPOINTS } from 'shared/endpoints';
import { PageDataResponse, PageResponse } from 'shared/models';
import { useGet } from 'shared/react-query-wrappers/hooks';

type InputProps = {
	siteId: string;
	pageId: string;
	actionType: ActionType;
};

export const useCheckPageCreationUniqueness = ({ siteId, pageId, actionType }: InputProps) => {
	const t = useTranslate();
	const { data, isLoading } = useGet<PageResponse>({ queryKey: { url: ENDPOINTS.HIERARCHY_PAGE, params: { pageId, siteId } } });

	return useCallback(
		async (values: { name: string; displayName: string }) => {
			const errors: Record<string, string> = {};
			let pages: PageDataResponse[] = [];

			if (!isLoading && data) {
				if (actionType !== 'inside') {
					pages = data.siblings;
					pages.push({ name: data?.page.name || '' } as PageDataResponse);
				} else {
					pages = data.children;
				}

				if (pages.length && pages.findIndex((x: PageDataResponse) => x.name.toLowerCase() === values.name.toLowerCase()) !== -1) {
					errors.name = t(DICTIONARY.PAGE_ITEM_EXISTS_ERROR);
				}
			}

			return errors;
		},
		[actionType, data, isLoading, t]
	);
};

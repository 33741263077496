import {
	Button,
	Card,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	VStack,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { DICTIONARY } from 'shared/constants';
import { RedirectLinksMenuModalProps } from 'shared/open-in-pages/models';

export const RedirectLinksMenuModal = ({ isOpen, onClose, hosts = [], pageRedirectHandler, itemId }: RedirectLinksMenuModalProps) => {
	return (
		<Modal scrollBehavior="inside" isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					<T _str={DICTIONARY.OPEN_IN_PAGES_MODAL} />
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<VStack>
						{hosts.map((host) => (
							<Card
								data-testid="site-definition-name"
								key={`${host.name}-${host.id}`}
								layerStyle="interactive.raise"
								size="sm"
								variant="outline"
								p="3"
								justify="center"
								alignSelf="stretch"
								onClick={(e) => pageRedirectHandler(e, host.name, itemId)}
							>
								<Text>{host.name}</Text>
							</Card>
						))}
					</VStack>
				</ModalBody>
				<ModalFooter>
					<Button variant="outline" onClick={onClose}>
						<T _str={DICTIONARY.CANCEL} />
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

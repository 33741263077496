import { AxiosInstance } from 'axios';
import { FC, createContext, useMemo } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useQueryClient } from 'app/react-query-wrappers-provider/hooks/use-query-client';
import { useAxios } from 'app/react-query-wrappers-provider/hooks/use-axios';

export type ReactQueryWrappersContextProps = {
	axios: AxiosInstance;
	queryClient: QueryClient;
};

export const ReactQueryWrappersContext = createContext<ReactQueryWrappersContextProps>({} as ReactQueryWrappersContextProps);

export const ReactQueryWrappersProvider: FC<React.PropsWithChildren> = ({ children }) => {
	const { axiosInstance, defaultQueryFn } = useAxios();
	const queryClient = useQueryClient(defaultQueryFn);

	const value = useMemo<ReactQueryWrappersContextProps>(
		() => ({
			axios: axiosInstance,
			queryClient,
		}),
		[axiosInstance, queryClient]
	);

	return (
		<ReactQueryWrappersContext.Provider value={value}>
			<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
		</ReactQueryWrappersContext.Provider>
	);
};

import { useToast } from '@chakra-ui/react';
import { mdiAlert } from '@mdi/js';
import { Icon } from 'entities/icon/ui';
import { useMissingPosNotificationData } from 'features/point-of-sale/hooks/missing-pos-notification-data';
import { MissingPosNotificationToast } from 'features/point-of-sale/ui/missing-pos-notification-toast';
import { FC, useEffect } from 'react';

const DEFAULT_TOAST_ID = 'missing-pos-notification';

export const MissingPosNotification: FC = () => {
	const { missingPosList, handleSiteClick, handleCreateAllPos } = useMissingPosNotificationData();
	const toast = useToast();

	useEffect(() => {
		if (!missingPosList.hosts.length) {
			return;
		}

		toast({
			status: 'warning',
			isClosable: true,
			icon: <Icon path={mdiAlert} />,
			id: DEFAULT_TOAST_ID,
			description: (
				<MissingPosNotificationToast
					hosts={missingPosList.hosts}
					onSiteClick={handleSiteClick}
					onPosCreate={handleCreateAllPos}
					onCloseToast={() => toast.close(DEFAULT_TOAST_ID)}
				/>
			),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [missingPosList.hosts]);

	return <></>;
};

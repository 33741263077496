import { Skeleton, Tbody, Td, Tr } from '@chakra-ui/react';
import { FC } from 'react';

type TableLoadingProps = {
	columns: number;
	rows: number;
	skeletonHeight?: number;
};

export const TableLoading: FC<TableLoadingProps> = ({ columns, rows, skeletonHeight = 5 }) => (
	<Tbody data-testid="table-loading">
		{Array.from({ length: rows }, (_, i) => i + 1).map((_, columnIndex) => (
			<Tr key={`table-loading-${columnIndex}`}>
				{Array.from({ length: columns }, (__, i) => i + 1).map((__, rowIndex) => (
					<Td key={`table-loading-${columnIndex}-${rowIndex}`}>
						<Skeleton height={skeletonHeight} />
					</Td>
				))}
			</Tr>
		))}
	</Tbody>
);

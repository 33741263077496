import { LeftNavigationItem, LeftNavigationLink } from 'entities/page-layout/models';
import { useLocation } from 'react-router';
import { useNavigateToRoute } from 'shared/navigation/hooks';

export const useNavigationItems = (items: LeftNavigationItem[]): LeftNavigationLink[] => {
	const location = useLocation();
	const navigate = useNavigateToRoute();

	return items.map((item: LeftNavigationItem) => ({
		label: item.label,
		onClick: () => navigate(item.route, { state: { origin: location.state?.origin ?? null } }),
		active: location?.pathname?.includes(item.route),
		icon: item.icon,
	}));
};

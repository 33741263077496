import { IGetTenantsResponse, IGetTenantsRequestPayload, ITenant } from './tenantsModel';
import config from '../config/config';
import { useSimpleGet } from 'shared/react-query-wrappers/hooks';

export const useGetTenantOrDefault = ({ organizationId, systemId, tenantName }: IGetTenantsRequestPayload, enabled = true) => {
	return useSimpleGet<IGetTenantsResponse>(config.inventory.get_tenants.url(organizationId, systemId, tenantName, 1, 1), enabled);
};

export const useGetTenantById = (tenantId: string, enabled = true) => {
	return useSimpleGet<ITenant>(config.inventory.get_tenantById.url(tenantId), enabled);
};

import { Formik } from 'formik';
import { FC, useMemo } from 'react';
import { LanguageItem } from 'shared/languages/models';
import { SiteResponse } from 'shared/models';
import { useCalculateDefaultLanguage } from 'widgets/site-settings-update-form/hooks/use-calculate-default-language';
import { SiteLanguageSection } from 'widgets/site-settings-update-form/ui/language-section-body';

/**
 * @deprecated Update is blocked for EA
 */
export type LanguageFormProps = {
	languages: LanguageItem[];
	site: SiteResponse;
};

/**
 * @deprecated Update is blocked for EA
 */
export const SiteLanguageForm: FC<LanguageFormProps> = (props) => {
	const { languages, site } = props;
	const defaultLanguage = useCalculateDefaultLanguage(languages, site.defaultLanguage);

	const initialValues = useMemo(() => {
		return {
			language: defaultLanguage,
			languageEmbedding: site.languageEmbedding || false,
			itemLanguageFallback: site.itemLanguageFallback || false,
			fieldLanguageFallback: site.fieldLanguageFallback || false,
		};
	}, [defaultLanguage, site.fieldLanguageFallback, site.itemLanguageFallback, site.languageEmbedding]);

	return (
		<Formik initialValues={initialValues} onSubmit={() => {}} enableReinitialize>
			{({ resetForm }) => <SiteLanguageSection languages={languages} resetForm={resetForm} />}
		</Formik>
	);
};

import { PosModalProps, mapSiteResponseToInput } from 'features/point-of-sale/models';
import { useCallback } from 'react';
import { ENDPOINTS } from 'shared/endpoints';
import { EditHostPOSInput, SiteResponse } from 'shared/models';
import { usePatch } from 'shared/react-query-wrappers/hooks';

export const useUnassignPosModal = (props: PosModalProps) => {
	const { onClose, id: hostId, siteId, siteLanguage, analyticsIdentifiers } = props;
	const { mutateAsync, isPending } = usePatch<SiteResponse, EditHostPOSInput>({
		queryKey: { url: ENDPOINTS.HOST, params: { siteId, hostId } },
		refetchQueriesByKey: [{ url: ENDPOINTS.SITE, params: { id: siteId }, shouldWait: true }, { url: ENDPOINTS.SITES }],
	});

	const unassignPos = useCallback(async () => {
		const input = mapSiteResponseToInput(analyticsIdentifiers);
		const index = input.analyticsIdentifiers.findIndex((pos) => pos.language === siteLanguage);

		if (index !== -1) {
			input.analyticsIdentifiers.splice(index, 1);
		}

		await mutateAsync(input);
		onClose();
	}, [mutateAsync, onClose, siteLanguage, analyticsIdentifiers]);

	return { unassignPos, isPending };
};
